import { SettingsOutlined } from "@mui/icons-material";
import { Badge, Box, Button, Skeleton, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import EventSettingsDialog from "../createEvent/EventSettingsDialog";
import { useState } from "react";

const EditEventHeader = ({
  loading,
  event,
  ticketInformation,
  setTicketInformation,
}) => {
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  let shownBadgeContent = ticketInformation ? `$${ticketInformation.price}` : 0;

  return (
    <>
      <EventSettingsDialog
        dialogOpen={settingsDialogOpen}
        ticketInformation={ticketInformation}
        setTicketInformation={setTicketInformation}
        editingEvent={true}
        alreadyHasPayments={event?.ticketInformation}
        setDialogOpen={setSettingsDialogOpen}
      />
      {!loading ? (
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h1" style={{ fontSize: "1.5em" }}>
              Edit Event
            </Typography>
            <Typography variant="body2">{event.name}</Typography>
          </Box>
          <Badge badgeContent={shownBadgeContent} color="primary">
            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.backgroundHighlight,
                ":hover": {
                  backgroundColor: `${colors.backgroundHighlight}80`,
                },
                fontSize: { xs: "12px", lg: ".875rem" },
              }}
              startIcon={
                <SettingsOutlined
                  sx={{
                    color: colors.primaryText,
                    fontSize: { xs: "12px", lg: ".875rem" },
                  }}
                />
              }
              onClick={() => {
                setSettingsDialogOpen(true);
              }}
            >
              Payments Settings
            </Button>
          </Badge>
        </Box>
      ) : (
        <Skeleton variant="rounded" height="2em" sx={{ mb: 2 }} />
      )}
    </>
  );
};

export default EditEventHeader;
