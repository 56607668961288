import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { useState } from "react";

const PlansAdjustMemberModalVisible = ({
  adjustMemberModalVisible,
  setAdjustMemberModalVisible,
  memberQuantity,
  setMemberQuantity,
  planType,
  setPlanType,
}) => {
  return (
    <Dialog
      open={adjustMemberModalVisible}
      onClose={() => setAdjustMemberModalVisible(false)}
      sx={{ maxHeight: "70%", marginTop: "auto", marginBottom: "auto" }}
    >
      <DialogTitle>
        Adjust Members
        <br />
        <Typography
          variant="body2"
          style={{ fontSize: ".8rem", color: colors.secondaryText }}
        >
          Your organization's number of members affects your member subscription
          bulk discount. You can always change your number of members once you
          have a subscription.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Slider
            value={memberQuantity ? memberQuantity : 0}
            onChange={(event, newValue) => setMemberQuantity(newValue)}
            valueLabelDisplay="auto"
            step={1}
            max={400}
            min={0}
            sx={{
              "& .MuiSlider-valueLabel": {
                fontSize: "1em",
                fontWeight: "normal",
                top: -6,
                backgroundColor: "unset",
                color: colors.primaryText,
                fontFamily: "Poppins",
                "&:before": {
                  display: "none",
                },
                "& *": {
                  background: "transparent",
                  color: colors.primaryText,
                },
              },
            }}
          />
          <TextField
            value={memberQuantity}
            size="small"
            variant="outlined"
            type="number"
            sx={{
              bgcolor: colors.backgroundHighlight,
              borderRadius: 1,
              ml: 2,
            }}
            onChange={(event) => {
              const newValue = event.target.value;

              setMemberQuantity(newValue ? parseInt(newValue) : "");
            }}
            onBlur={() => {
              if (!memberQuantity || memberQuantity < 0) {
                setMemberQuantity(0);
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setPlanType((old) => (old === "monthly" ? "yearly" : "monthly"));
            setAdjustMemberModalVisible(false)
          }}
        >
          Switch to {planType === "monthly" ? "Yearly (15% off)" : "Monthly"}
        </Button>
        <Button onClick={() => setAdjustMemberModalVisible(false)}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlansAdjustMemberModalVisible;
