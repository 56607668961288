import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Grid, Typography, ButtonBase, Box } from "@mui/material";
import { getModuleTime } from "../../../methods/getFormattedTime";

const JobScheduleGraph = ({
  displayedMemberJobs,
  containerStyle,
  eventStartTime,
  isPersonal,
  surpressPopUp,
  isImport,
}) => {
  const [visibleMemberJobTooltip, setVisibleMemberJobTooltip] = useState(null);
  const [initialTooltipVisible, setInitialToolTipVisible] = useState(false);
  const pxPerMinute = isPersonal ? 1 : 5;
  const majorIntervalMinutes = isPersonal ? 30 : 15;
  const majorIntervalWidth = majorIntervalMinutes * pxPerMinute;
  let maxEndTime = 0;
  displayedMemberJobs.forEach((job) =>
    job.jobEnd > maxEndTime ? (maxEndTime = job.jobEnd) : null
  );

  const groupedJobs = displayedMemberJobs.reduce((grouped, job) => {
    (grouped[job.jobName] = grouped[job.jobName] || []).push(job);
    return grouped;
  }, {});

  useEffect(() => {
    if (!surpressPopUp) {
      setInitialToolTipVisible(true);
    }
  }, []);

  const hexToRGB = (hex) => {
    let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  function darkenRGB(rgb, factor) {
    return [
      Math.max(0, rgb[0] - 255 * (1 - factor)),
      Math.max(0, rgb[1] - 255 * (1 - factor)),
      Math.max(0, rgb[2] - 255 * (1 - factor)),
    ];
  }

  function getColumnColor(columnStep, numSteps) {
    if (columnStep % 4 === 0) {
      return "#520e7d";
    } else if (columnStep % 4 === 1) {
      return "#105075";
    } else if (columnStep % 4 === 2) {
      return "#064a17";
    } else {
      return "#7d4b0e";
    }
  }

  const getColor = (rowStep, columnStep, numSteps) => {
    // Calculate the gradient steps for RGB values
    const color = getColumnColor(columnStep, Object.keys(groupedJobs).length);
    const startRGB = hexToRGB(color);
    const endRGB = darkenRGB(startRGB, isPersonal ? 0.9 : 0.75);
    const steps = numSteps === 1 ? 2 : numSteps;
    const stepChange = startRGB.map(
      (start, i) => (endRGB[i] - start) / (steps - 1)
    );

    // Function to get color for a given step
    const [r, g, b] = startRGB.map((start, i) =>
      Math.round(start + rowStep * stepChange[i])
    );
    return `rgb(${r}, ${g}, ${b})`;
  };

  const formatTimeLabel = (offset) => {
    return getModuleTime(parseInt(eventStartTime) + offset).split(" ")[0];
  };

  const renderTimeLabels = () => {
    let labels = [];
    for (let i = 0; i <= maxEndTime + 5; i += majorIntervalMinutes) {
      let formattedDate = formatTimeLabel(i * 60000);

      labels.push(
        <Box
          key={i}
          sx={{
            width: majorIntervalWidth,
            py: isPersonal ? 1 : 0,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: ".8em",
              transform: `rotateZ(${isPersonal ? -45 : 0}deg)`,
            }}
          >
            {formattedDate}
          </Typography>
        </Box>
      );
    }
    return labels;
  };
  return (
    <Grid
      container
      direction="row"
      sx={{ overflowX: "auto", ...containerStyle }}
      className="scroll-container-x"
    >
      <Grid item className="scroll-content" sx={{pt: 2}}>
        <Box container sx={{ pl: "89px", mb: 1, display: "flex" }}>
          {renderTimeLabels()}
        </Box>
        <Grid container sx={{ mt: 0, mb: 1 }}>
          {Object.entries(groupedJobs).map(([jobName, jobs], i) => (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              key={i}
              sx={{ mb: 1 }}
            >
              <Grid container direction="row" alignItems="center" noWrap>
                <Typography variant="body1" noWrap sx={{ width: "100px" }}>
                  {jobName}
                </Typography>
                {jobs
                  .sort((a, b) => a.jobStart - b.jobStart)
                  .map((memberJob, j) => {
                    const duration = memberJob.jobEnd - memberJob.jobStart;
                    let durationOffset = memberJob.jobStart - duration * j;
                    if (j > 0) {
                      durationOffset = memberJob.jobStart - jobs[j - 1].jobEnd;
                    }
                    return (
                      <Tooltip
                        key={j}
                        title={
                          <Grid>
                            <Typography variant="body1">
                              {visibleMemberJobTooltip
                                ? memberJob.name
                                : isPersonal
                                ? "View your jobs"
                                : "View member details"}
                            </Typography>
                            {visibleMemberJobTooltip ? (
                              displayedMemberJobs
                                .filter(
                                  (job) => job.userID === memberJob.userID
                                )
                                .sort((a, b) => a.jobStart - b.jobStart)
                                .map((job, index) => (
                                  <Typography key={index}>
                                    {`${job.jobName}: ${formatTimeLabel(
                                      job.jobStart * 60000
                                    )} → ${formatTimeLabel(
                                      job.jobEnd * 60000
                                    )}`}
                                  </Typography>
                                ))
                            ) : (
                              <Typography>
                                {`Press any job module to view what other jobs ${
                                  isPersonal ? "you are" : "that member is"
                                } working`}
                              </Typography>
                            )}
                          </Grid>
                        }
                        open={
                          (visibleMemberJobTooltip &&
                            visibleMemberJobTooltip.userID ===
                              memberJob.userID &&
                            visibleMemberJobTooltip.jobStart ===
                              memberJob.jobStart) ||
                          (initialTooltipVisible && i === 0 && j === 0)
                        }
                        onClose={() => {
                          if (visibleMemberJobTooltip) {
                            setVisibleMemberJobTooltip(false);
                          } else setInitialToolTipVisible(false);
                        }}
                      >
                        <ButtonBase
                          onClick={() =>
                            setVisibleMemberJobTooltip({
                              userID: memberJob.userID,
                              jobStart: memberJob.jobStart,
                            })
                          }
                          sx={{
                            width: `calc(${duration * pxPerMinute}px)`,
                            ml: `${durationOffset * pxPerMinute}px`,
                            bgcolor: getColor(j, i, jobs.length),
                            alignItems: "center",
                            justifyContent: "center",
                            p: "8px 4px",
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            noWrap
                            variant="body1"
                            sx={{ fontSize: ".8rem" }}
                          >
                            {isPersonal ? "" : memberJob.name}
                          </Typography>
                        </ButtonBase>
                      </Tooltip>
                    );
                  })}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobScheduleGraph;
