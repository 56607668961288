import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import RushTestimonialCard from "./RushTestimonialCard";
import React from "react";

const RushTestimonialSection = () => {
  const testimonial = {
    author: "Aaron Singletary",
    authorDescription: "Head Rush Chair at UNC Interfraternity Council",
    quote:
      "Nothing sucks more than constantly texting hundreds of rushees. CLIQ made it so easy to send out invites and track engagement. Now, I can't imagine rush without CLIQ.",
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <RushTestimonialCard testimonial={testimonial} />
    </Box>
  );
};

export default RushTestimonialSection;
