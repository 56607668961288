import {
  AttachMoneyOutlined,
  GppGoodOutlined,
  PeopleOutlineOutlined,
  SecurityUpdateGoodRounded,
} from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import FeatureModule from "./FeatureModule";
import { colors } from "../../theme/colors";

const PlansFeaturesSection = () => {
  return (
    <Box
      sx={{
        bgcolor: colors.eggshell,
        py: "6em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box className="default-page-size">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2rem", lg: "2.5rem" },
            fontWeight: 600,
            letterSpacing: "-1px",
            maxWidth: "50%",
            marginBottom: "1.5em",
            color: colors.defaultBackground,
          }}
        >
          Enjoy these features on your CLIQInvite subscription
        </Typography>

        <Grid container sx={{ mb: 10 }} columnSpacing={4} rowSpacing={4}>
          {[
            {
              title: "QR security",
              icon: (
                <GppGoodOutlined
                  sx={{ fontSize: "2rem", color: colors.primaryText }}
                />
              ),
              description:
                "Easily deliver unique QR codes for your events to guests without them ever needing to download the app",
            },
            {
              title: "Easy invites",
              icon: (
                <SecurityUpdateGoodRounded
                  sx={{ fontSize: "2rem", color: colors.primaryText }}
                />
              ),
              description:
                "Streamline the mass distribution process with tailored management tools",
            },
            {
              title: "Paid events",
              icon: (
                <AttachMoneyOutlined
                  sx={{ fontSize: "2rem", color: colors.primaryText }}
                />
              ),
              description:
                "Host paid events and distribute member-specific payment links to potential guests",
            },
            {
              title: "Rush suite",
              icon: (
                <PeopleOutlineOutlined
                  sx={{ fontSize: "2rem", color: colors.primaryText }}
                />
              ),
              description:
                "Leverage our rush features to automate communications, monitor engagement, and streamline your rush workflow",
            },
          ].map((feature) => (
            <Grid item xs={6} lg={3}>
              <FeatureModule feature={feature} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
export default PlansFeaturesSection;
