import PublicHeader from "../navigation/PublicHeader";
import { colors } from "../../theme/colors";
import "./Landing.css";
import { GiPartyFlags } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import React, { useEffect, useState } from "react";
import HeroSection from "./HeroSection";
import { BsFillSuitHeartFill, BsPersonPlusFill } from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import LandingCapabilitiesOverviewSection from "./CapabilitiesOverview/LandingCapabilitiesOverviewSection";
import LandingActionSection from "./LandingActionSection";
const Landing = () => {
  const [url, setUrl] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setUrl(
        "https://play.google.com/store/apps/details?id=com.skdraughn.CLIQ"
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setUrl("https://apps.apple.com/us/app/cli%CF%89/id1664767480");
    } 
    // else {
    //   // for desktop browsers, redirect to signup page
    //   setUrl("https://www.cliqinvite.com/signup");
    // }
  }, []);

  const pageTitle = `CLIQInvite`;
  const pageDescription = `Revolutionize event management with CLIQInvite. Streamline social events, philanthropy, and recruitment. Secure invites, real-time tracking, and detailed analytics. Elevate your event experience today.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <div
        style={{ ...styles.container, overflowX: "hidden" }}
      >
        <div className="bodyContainer" style={styles.bodyContainer}>
          <PublicHeader variant="resizable" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <HeroSection url={url} />
            <LandingCapabilitiesOverviewSection />
            {/* <LandingNoAppSection /> */}{" "}
            {/* coming soon, maybe like a news section so we can easily display jobs, stuff like that */}
            <LandingActionSection />
            {/* <FadeIn>
                <IconModuleGroup
                  iconData1={iconData1}
                  iconData2={iconData2}
                  iconData3={iconData3}
                />
              </FadeIn> */}
            {/* <FadeIn>
                <div style={{ position: "relative" }}>
                  <div
                    id="social-scroll-anchor"
                    style={{ position: "absolute", top: 0 }}
                  />
                  <LandingCapabilitiesOverviewSection
                    inverted
                    title={
                      <>
                        All of the control.
                        <br />
                        None of the headache.
                      </>
                    }
                    sectionColor={"#A020f0"}
                    sectionBGColor={"#a020f008"}
                    imageModule={{
                      listItems: [
                        {
                          text: "Distribute invites instantaneously",
                          subText:
                            "Send tickets to your guests with just a few taps",
                        },
                        {
                          text: "Track invite status",
                          subText:
                            "Monitor your invites throughout the event lifecycle and derive meaningful insights",
                        },
                        {
                          text: "Enforce custom rules",
                          subText:
                            "Ensure that your members are following internal invite limits",
                        },
                      ],
                      popUpImage: require("../../assets/heroAnim/HeroAnimImage2PopUp1.png"),
                      popUpBackgroundColor: "#000000",
                      title: "",
                    }}
                  />
                </div>
              </FadeIn>
              <FadeIn>
                <div style={{ position: "relative" }}>
                  <div
                    id="social-scroll-anchor"
                    style={{ position: "absolute", top: 0 }}
                  />
                  <Section
                    title={
                      <>
                        Enterprise-level security.
                        <br />
                        At a fraction of the cost.
                      </>
                    }
                    sectionColor={"rgb(0, 51, 102)"}
                    sectionBGColor={"#000000"}
                    imageModule={{
                      listItems: [
                        {
                          text: "Enable rapid decision making",
                          subText:
                            "Validate tickets with all the information you need at your fingertips",
                        },
                        {
                          text: "Mitigate long queues",
                          subText:
                            "Process guests or groups without slowing down the line",
                        },
                        {
                          text: "Integrate with private security",
                          subText:
                            "Provide any non-member scanners a unique event code to validate guests at the door",
                        },
                      ],
                      image: require("../../assets/CybersecurityPhoto.jpg"),
                      title: "",
                      popUpImage: require("../../assets/QRText.png"),
                      popUpBackgroundColor: colors.defaultBackground,
                    }}
                  />
                </div>
              </FadeIn>
              <FadeIn>
                <div style={{ position: "relative" }}>
                  <div
                    id="social-scroll-anchor"
                    style={{ position: "absolute", top: 0 }}
                  />
                  <Section
                    inverted
                    title={
                      <>
                        High-fidelity analytics.
                        <br />
                        Iterate and improve.
                      </>
                    }
                    sectionColor={"#502aaa"}
                    sectionBGColor={"#502aaa15"}
                    imageModule={{
                      listItems: [
                        {
                          text: "Identify gatecrashers and no-shows",
                          subText:
                            "Determine problem sources and adjust invite practices",
                        },
                        {
                          text: "Track performance",
                          subText:
                            "Access detailed breakdowns for every event and every member",
                        },
                        {
                          text: "Monitor arrival times",
                          subText: "Optimize event start and end times",
                        },
                      ],
                      image: require("../../assets/AnalyticsPhoto.jpg"),
                      title: "",
                    }}
                  />
                </div>
              </FadeIn>

              <FadeIn>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    backgroundColor: colors.defaultBackground,
                    padding: "9em 0em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    id="recruitment-scroll-anchor"
                    style={{ position: "absolute", top: 0 }}
                  />
                  <Grid
                    container
                    className={"image-module-container"}
                    columnSpacing={7}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        sx={{
                          color: colors.primaryText,
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: { md: "4em", xs: "3em" },
                          textAlign: "center",
                        }}
                      >
                        Automate your rush process
                      </Typography>
                    </Grid>
                    <Grid item lg={6}>
                      <img src={rush_flow} style={{ width: "100%" }} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {[
                        "Create a common database",
                        "Automate mass invitation",
                        "Track engagement and score prospective members ",
                      ].map((text) => (
                        <p
                          style={{
                            color: "white",
                            fontFamily: "Poppins",
                            fontSize: "2em",
                          }}
                        >
                          {text}
                        </p>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              </FadeIn>
              <FadeIn>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    padding: "0em 0em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "7em",
                    position: "relative",
                  }}
                >
                  <div
                    id="philanthropy-scroll-anchor"
                    style={{ position: "absolute", top: 0 }}
                  />
                  <Grid
                    container
                    className={"image-module-container"}
                    columnSpacing={7}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        sx={{
                          color: colors.primaryText,
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: { md: "4em", xs: "3em" },
                          textAlign: "center",
                          mb: 4,
                        }}
                      >
                        Philanthropy management redefined
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <img src={philanthropy_flow} style={{ width: "100%" }} />
                    </Grid>
                  </Grid>
                </div>
              </FadeIn>
              <FadeIn>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    padding: "6em 0em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "3em",
                    boxShadow: "0px 0px 6px #502AAA",
                    position: "relative",
                    background:
                      "linear-gradient(75deg, #502AAA, rgb(27, 11, 99))",
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      color: "white",
                      fontFamily: "Poppins",
                      fontWeight: "800",
                      fontSize: "3.3em",
                      color: colors.primaryText,
                      textAlign: "center",
                      width: {
                        xl: "38%",
                        lg: "42%",
                        md: "45%",
                      },
                    }}
                  >
                    Revolutionize your events today
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      width: { xl: "50%", lg: "50%", md: "50%" },
                      fontSize: "1.2em",
                      textAlign: "center",
                      fontWeight: "400",
                      mt: 4,
                    }}
                  >
                    Whether you host small get-togethers or full blown concerts,
                    eliminate your administerial headache and make sure only
                    invited guests get through the door.
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: { xs: "center", md: "center" },
                      alignItems: "center",
                      mt: 4,
                    }}
                  >
                    <button
                      className="slide-button black"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1.5rem",
                        fontWeight: "500",
                      }}
                      onClick={() => window.open(url, "_blank", "noreferrer")}
                    >
                      Get CLIQInvite
                    </button>
                  </Box>
                </div>
              </FadeIn> */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

const styles = {
  container: {
    backgroundColor: colors.defaultBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    overflowX: "none",
  },
  header: {
    color: "white",
    fontSize: "2em",
    alignSelf: "center",
    fontFamily: "Poppins",
    fontWeight: "600",
    textAlign: "center",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
  body: {},
  subHeader: {
    color: "white",
    fontSize: 20,
    alignSelf: "center",
    fontFamily: "Poppins",
    fontWeight: "500",
    textAlign: "center",
  },
  sectionColumn: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "70%",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "18px",

    alignItems: "center",
    width: 300,
    backgroundColor: colors.backgroundHighlight,
  },
  listItem: {
    color: "white",
    fontSize: 20,
    fontFamily: "Poppins",
  },
  imageSectionContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "18px",
    // margin: "10px 0px",
    // padding: "20px 20px",
    alignItems: "flex-start",
    width: 840,
  },
  logo: {
    color: "#ffffff",
    fontSize: 255,
    letterSpacing: 3,
    fontWeight: "800",
  },
};

export default Landing;
