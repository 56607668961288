import React, { useReducer, useState } from "react";
import { DataStore } from "aws-amplify";
import {
  Announcement,
  Event,
  OrganizationMember,
  User,
} from "../../../../src/models";
import { useAuthContext } from "../../../../src/contexts/AuthContext";
import { TextField, Button, Typography, Box, Grid } from "@mui/material";
import {
  PushPinOutlined,
  PushPin,
  SendOutlined,
  CancelOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import { colors } from "../../../theme/colors";

function reducer(state, action) {
  switch (action.type) {
    case "changed_title": {
      return {
        ...state,
        title: action.nextTitle,
      };
    }
    case "toggle_pin": {
      return {
        ...state,
        isPinned: !state.isPinned,
      };
    }
    case "changed_body": {
      return {
        ...state,
        announcement: action.nextBody,
      };
    }
    case "changed_roles": {
      return {
        ...state,
        rolesVisible: action.nextRoles,
      };
    }
    case "changed_link": {
      return { ...state, link: action.nextLink };
    }
  }

  throw Error("Unknown action.");
}

const LocalAnnouncementModule = ({
  announcement,
  setSnackbarOpen,
  submitAnnouncementEdits,
  clearLocalAnnouncement,
  handleDeleteAnnouncement,
}) => {
  const { dbUser, createNotif } = useAuthContext();
  const [localAnnouncement, setLocalAnnouncement] = useReducer(
    reducer,
    announcement
  );

  const sendNotifs = () => {
    DataStore.query(Event, localAnnouncement.eventID).then((event) => {
      DataStore.query(OrganizationMember, (m) =>
        m.organizationID.eq(dbUser.id)
      ).then((members) => {
        members.map((member) => {
          if (localAnnouncement.rolesVisible.includes(member.role)) {
            DataStore.query(User, member.userID).then((user) => {
              createNotif(
                User,
                "announcement",
                user,
                "New Announcement",
                localAnnouncement.announcement || localAnnouncement.title,
                event.name,
                event.id,
                dbUser.profilePic
              );
            });
          }
        });
      });
    });
  };

  const sendAnnouncement = () => {
    //only when send button is pressed do we save a new announcement
    let errorMessage;

    if (!localAnnouncement.title) {
      setSnackbarOpen({
        type: "error",
        message: "Please fill in a title to create your announcement",
      });

      return;
    } else if (!localAnnouncement.announcement) {
      errorMessage = "Your announcement is missing a body message.";
    } else if (!localAnnouncement.rolesVisible.length) {
      errorMessage =
        "This announcement will only be visible to yourself. Customize by pressing the Settings button.";
    }
    if (errorMessage) {
      setSnackbarOpen({
        type: "warning",
        message: errorMessage,
        action: (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              if (submitAnnouncementEdits) {
                submitAnnouncementEdits();
                clearLocalAnnouncement();
              } else {
                DataStore.save(
                  new Announcement({
                    eventID: localAnnouncement.eventID,
                    visibleRoles: localAnnouncement.rolesVisible,

                    title: localAnnouncement.title,
                    announcement: localAnnouncement.announcement,
                    link: localAnnouncement.link,
                    isPinned: localAnnouncement.isPinned,
                  })
                );
                sendNotifs();
              }
              setSnackbarOpen(null);
            }}
          >
            Submit Anyways
          </Button>
        ),
      });
    } else {
      if (submitAnnouncementEdits) {
        submitAnnouncementEdits(localAnnouncement);
      } else {
        DataStore.save(
          new Announcement({
            eventID: localAnnouncement.eventID,
            visibleRoles: localAnnouncement.rolesVisible,

            title: localAnnouncement.title,
            announcement: localAnnouncement.announcement,
            link: localAnnouncement.link,
            isPinned: localAnnouncement.isPinned,
          })
        );
        sendNotifs();
        clearLocalAnnouncement();
      }
    }
  };
  // same functions as before...

  return (
    <Box
      className="unstyled-card"
      sx={{ px: 2, py: 1, display: "flex", flexDirection: "column", mb: 2 }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography variant="h2" sx={{ fontSize: "1em", flex: 1 }}>
          New Announcement
        </Typography>
        <Box
          sx={{
            cursor: "pointer",
            transition: "opacity 150ms ease-in-out",
            ":hover": { opacity: 0.7 },
          }}
        >
          {localAnnouncement.isPinned ? (
            <PushPin
              sx={{ transform: "rotateZ(45deg)" }}
              color="primary"
              onClick={() => setLocalAnnouncement({ type: "toggle_pin" })}
            />
          ) : (
            <PushPinOutlined
              sx={{ color: colors.secondaryText, transform: "rotateZ(45deg)" }}
              onClick={() => setLocalAnnouncement({ type: "toggle_pin" })}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 1.5 }}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="body1" sx={{ fontSize: ".9em" }}>
            Announcement Basics
          </Typography>
          <Typography variant="body2" sx={{ fontSize: ".8em", mb: 1 }}>
            You are required to provide an announcement title, but the body and
            link are entirely optional.
          </Typography>
          <TextField
            variant="outlined"
            sx={{ bgcolor: colors.backgroundHighlight, borderRadius: 1 }}
            color="primary"
            label="Announcement title"
            size="small"
            maxLength={32}
            value={localAnnouncement.title}
            onChange={(e) => {
              setLocalAnnouncement({
                type: "changed_title",
                nextTitle: e.target.value,
              });
            }}
            required
            fullWidth
          />
        </Box>

        <TextField
          variant="outlined"
          sx={{ bgcolor: colors.backgroundHighlight, borderRadius: 1 }}
          size="small"
          color="primary"
          autoCapitalize="none"
          label="Announcement link"
          onChange={(e) =>
            setLocalAnnouncement({
              type: "changed_link",
              nextLink: e.target.value,
            })
          }
          value={localAnnouncement.link}
        />
        <Box sx={{ position: "relative", width: "100%" }}>
          <TextField
            variant="outlined"
            size="small"
            color="primary"
            sx={{ bgcolor: colors.backgroundHighlight, borderRadius: 1 }}
            label="Announcement body"
            inputProps={{ maxLength: 800 }}
            minRows={3}
            onChange={(e) => {
              setLocalAnnouncement({
                type: "changed_body",
                nextBody: e.target.value,
              });
            }}
            value={localAnnouncement.announcement}
            fullWidth
            multiline
          />
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              bottom: ".25em",
              fontSize: ".75em",
              right: ".5em",
            }}
          >
            {localAnnouncement.announcement.length} / 800
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" sx={{ fontSize: ".9em", mt: 1 }}>
            Accessing Groups
          </Typography>
          <Typography variant="body2" sx={{ fontSize: ".8em", mb: 1 }}>
            Groups highlighted in{" "}
            <b style={{ color: `${colors.primaryColor}` }}>purple</b> will be
            notified for and be able to view this announcement
          </Typography>
          <Grid container spacing={0.5}>
            {[...dbUser.createdRoles, "No Group"].map((role) => {
              const hasGroup = localAnnouncement.rolesVisible.includes(role);
              return (
                <Grid item xs={4} key={role}>
                  <Box
                    sx={{
                      bgcolor: hasGroup
                        ? `${colors.primaryColor}80`
                        : colors.backgroundHighlight,
                      borderRadius: 1,
                      px: 1,
                      py: 0.5,
                      transition:
                        "opacity 150ms ease-in-out, bgcolor 150ms ease-in-out",
                      cursor: "pointer",
                      ":hover": {
                        opacity: 0.7,
                      },
                    }}
                    onClick={() =>
                      setLocalAnnouncement({
                        type: "changed_roles",
                        nextRoles: hasGroup
                          ? localAnnouncement.rolesVisible.filter(
                              (visibleRole) => visibleRole !== role
                            )
                          : [...localAnnouncement.rolesVisible, role],
                      })
                    }
                  >
                    <Typography variant="body1">{role}</Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, width: '100%' }}>
        {handleDeleteAnnouncement ? (
          <Button
            variant="contained"
            color="error"
            startIcon={
              <DeleteOutlineOutlined sx={{ color: colors.primaryText }} />
            }
            onClick={() => handleDeleteAnnouncement()}
            sx={{mr: 'auto'}}
          >
            Delete Announcement
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="error"
          startIcon={<CancelOutlined sx={{ color: colors.primaryText }} />}
          onClick={() => clearLocalAnnouncement()}
          sx={{ml: 'auto'}}
        >
          Stop Editing
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SendOutlined sx={{ color: colors.primaryText }} />}
          onClick={() => sendAnnouncement()}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default LocalAnnouncementModule;
