import Footer from "../../components/Footer";
import PublicHeader from "../../public/navigation/PublicHeader";
import { colors } from "../../theme/colors";
import { Helmet } from "react-helmet-async";
import ContactForm from "./ContactForm";

const Contact = () => {
  const pageTitle = `Contact Us • CLIQInvite`;
  const pageDescription = `Need assistance or have a query about CLIQInvite? Reach out to us on our Contact page. We're here to help you streamline your events and elevate your management experience.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/contact`;
  return (
    <div
      className="page-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100dvh",
        backgroundColor: colors.defaultBackground,
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta
          name="keywords"
          content="Event Management, Fraternity Management"
        />
      </Helmet>
      <PublicHeader />
      <div
        className="page-size-medium"
        style={{ paddingTop: "7em", marginBottom: "auto" }}
      >
        <ContactForm
          titleShown={true}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
