import { Box, Typography } from "@mui/material";
import { colors } from "../../theme/colors";

const FeatureModule = ({ feature }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          bgcolor: colors.primaryColor,
          borderRadius: "1.625rem",
          height: "3.25rem",
          width: "3.25rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: `radial-gradient(#a020f0, ${colors.nemoBlue}18)`,
          marginBottom: "1rem",
        }}
      >
        {feature.icon}
      </Box>
      <Typography
        variant="h3"
        style={{
          fontWeight: 500,
          fontSize: "1.5rem",
          marginBottom: "1rem",
          color: colors.defaultBackground,
          letterSpacing: "-.5px",
        }}
      >
        {feature.title}
      </Typography>
      <Typography
        style={{ color: colors.backgroundHighlight, fontSize: "1rem" }}
      >
        {feature.description}
      </Typography>
    </Box>
  );
};

export default FeatureModule;
