import { Box, Button, Grid, Skeleton } from "@mui/material";
import { colors } from "../../../theme/colors";
import { HowToVoteOutlined } from "@mui/icons-material";
import ReportInfoModule from "./ReportInfoModule";

const ReportInfoDisplay = ({ setHelpDialogOpen, loading }) => {
  const reportInfo = [
    {
      title: "Event Data",
      description:
        "On this sheet, you'll find an overview for your event totals as well as a breakdown for each event you hosted during the report's time period, including:",
      bullets: [
        "Event information",
        "Invite categories",
        "Invites sent and scans (valid and invalid)",
        "Total scan rate (valid and invalid)",
        "Member with the most invites and most scans (valid and invalid)",
      ],
    },
    {
      title: "Member Event Overview",
      description:
        "On this sheet, you'll find a summary of each member's aggregate participation in the events you hosted during this time period, including:",
      bullets: [
        "Member join request date",
        "Member group",
        "Total events participated in",
        "Total invites given and scans (valid and invalid)",
        "Total scan rate (valid and invalid)",
        "Average invites given and scans (valid and invalid)",
      ],
    },
    {
      title: "Member Event Details",
      description:
        "On this sheet, you'll find a detailed breakdown of each member's participation in each events you hosted during this time period, including:",
      bullets: [
        "Invites given and scans (valid and invalid)",
        "Scan rate (valid and invalid)",
        "Invite percent increase/decrease over time",
        "Scan percent increase/decrease over time (valid/invalid)",
      ],
    },
    {
      title: "Paid Events",
      description:
        "On this sheet, you'll find a high-level overview of each paid event you hosted during the report's time period, including:",
      bullets: [
        "Event information",
        "Total tickets sold and revenue generated",
        "Top and second top selling members",
        "Revenue contributed by the top and second top selling members",
        "Number of tickets sold by the top and second top selling members",
      ],
    },
    {
      title: "Payouts",
      description:
        "On this sheet, you'll find a concise log of all payout requests made during the report's time period. Each entry captures essential information about the payout request including:",
      bullets: [
        "Requested payout amount",
        "Date of the payout request",
        "Time of the payout request",
        "Current status of the payout request",
      ],
    },
    {
      title: "Event Guest List Details",
      description:
        "On these sheets, you'll find comprehensive breakdowns of your events' guest lists, including individual invites and member totals. The detailed overview captures critical insights about each member's participation in the event, as follows:",
      bullets: [
        "Category limits for groups and co-hosts",
        "Individual members' information, including total invites sent, the category of invites, scans (valid and invalid), and the respective scan rates",
        "Revenue generated from each member",
        "Subtotals per group and overall totals",
      ],
    },
  ];
  return (
    <Box
      className="unstyled-card"
      sx={{ p: ".5em 1em", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        {loading ? (
          <Skeleton sx={{ fontSize: "1.2em" }} />
        ) : (
          <h1
            style={{
              margin: 0,
              fontSize: "1.2em",
            }}
          >
            What your reports contain
          </h1>
        )}
        {loading ? (
          <Skeleton sx={{ fontSize: ".85em", mb: "1em" }} />
        ) : (
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: ".85em",
              marginBottom: "1em",
            }}
          >
            Reports span far beyond just your events, spanning every data point
            that your organization has interacted with during the time frame.
          </p>
        )}
      </div>
      <Grid container columnSpacing={1} rowSpacing={1} sx={{ mb: 2 }}>
        {reportInfo.map((info, index) => (
          <Grid item xs={12}>
            {loading ? (
              <Skeleton
                variant="rounded"
                sx={{ fontSize: "8em" }}
                key={index.toString()}
              />
            ) : (
              <ReportInfoModule info={info} key={index.toString()} />
            )}
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", gap: "1em", mb: 1 }}>
        {loading ? (
          <Skeleton sx={{ flex: 1, fontSize: "2em" }} variant="rounded" />
        ) : (
          <Button
            variant="contained"
            sx={{
              flex: 1,
            }}
            color="primary"
            startIcon={<HowToVoteOutlined sx={{ color: "white" }} />}
            onClick={() => setHelpDialogOpen(true)}
          >
            Help Decide What's Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ReportInfoDisplay;
