import { Box, Grid, Typography } from "@mui/material";
import EventHighlightModule from "./EventHighlightModule";
import { colors } from "../../theme/colors";

const EventInfo = ({ highlightedMembers }) => {
  return (
    <>
      <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
        <Typography variant="h2" sx={{ fontSize: "1em", mb: 1 }}>
          Member Highlights
        </Typography>
        <Grid
          container
          sx={{
            bgcolor: colors.backgroundHighlight,
            px: 1,
            py: 0.35,
            borderRadius: 1,
            mb: 0.5,
          }}
        >
          <Grid item xs={5}>
            <Typography
              variant="body2"
              sx={{ fontSize: ".8em", textAlign: "center" }}
            >
              Category
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography
              variant="body2"
              sx={{ fontSize: ".8em", textAlign: "center" }}
            >
              Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              sx={{ fontSize: ".8em", textAlign: "center" }}
            >
              #
            </Typography>
          </Grid>
        </Grid>
        <EventHighlightModule
          num={highlightedMembers.mostInvitesMember?.invitesGiven || 0}
          name={highlightedMembers.mostInvitesMember?.name || "N/A"}
          description="Most Invites Sent"
        />
        <EventHighlightModule
          num={highlightedMembers.mostQrScannedMember?.validScans || 0}
          name={highlightedMembers.mostQrScannedMember?.name || "N/A"}
          description="Most Valid Scans"
        />
        <EventHighlightModule
          num={highlightedMembers.bestQrScanRateMember?.percent || 0}
          name={highlightedMembers.bestQrScanRateMember?.name || "N/A"}
          description="Best Accept Rate"
        />
        <EventHighlightModule
          num={highlightedMembers.mostInvalidScansMember?.invalidScans || 0}
          name={highlightedMembers.mostInvalidScansMember?.name || "N/A"}
          description="Most Invalid Scans"
          numberColor={colors.errorColor}
        />
      </Box>
    </>
  );
};

export default EventInfo;
