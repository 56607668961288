import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import DefineJobs from "./DefineJobs";
import DefineJobTimes from "./DefineJobTimes";
import DefineJobWeights from "./DefineJobWeights";
import ReviewJobs from "./ReviewJobs";
import JobsOutput from "./JobsOutput";

const JobsDialogHub = ({
  jobsVisible,
  setJobsVisible,
  setSnackbarOpen,
  event,
}) => {
  const [phase, setPhase] = useState("NAMES"); //NAMES, TIMES, WEIGHTS, REVIEW
  const [animationType, setAnimationType] = useState("FORWARD"); //FORWARDS, BACKWARDS
  const [jobs, setJobs] = useState([
    { name: "", quantity: "1" },
    { name: "", quantity: "1" },
  ]);

  useEffect(() => {
    if (phase === "DONE") {
      setJobsVisible(false);
      setPhase("NAMES");
    }
  }, [phase]);

  return jobsVisible ? (
    <Box
      sx={{
        position: "absolute",
        bgcolor: "#00000080",
        height: "100dvh",
        zIndex: 999,
        width: "100dvw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => setJobsVisible(false)}
    >
      {phase === "NAMES" || phase === "TIMES" ? (
        <DefineJobs
          jobs={jobs}
          setJobs={setJobs}
          phase={phase}
          animationType={animationType}
          setSnackbarOpen={setSnackbarOpen}
          setPhase={setPhase}
          setAnimationType={setAnimationType}
        />
      ) : null}
      {phase === "NAMES" || phase === "TIMES" || phase === "WEIGHTS" ? (
        <DefineJobTimes
          jobs={jobs}
          setJobs={setJobs}
          phase={phase}
          animationType={animationType}
          setSnackbarOpen={setSnackbarOpen}
          setPhase={setPhase}
          event={event}
          setAnimationType={setAnimationType}
        />
      ) : null}
      {phase === "TIMES" || phase === "WEIGHTS" || phase === "REVIEW" ? (
        <DefineJobWeights
          jobs={jobs}
          setJobs={setJobs}
          phase={phase}
          animationType={animationType}
          setSnackbarOpen={setSnackbarOpen}
          setPhase={setPhase}
          setAnimationType={setAnimationType}
        />
      ) : null}
      {phase === "WEIGHTS" || phase === "REVIEW" || phase === "OUTPUT" ? (
        <ReviewJobs
          jobs={jobs}
          phase={phase}
          animationType={animationType}
          setPhase={setPhase}
          setAnimationType={setAnimationType}
        />
      ) : null}
      {phase === "REVIEW" || phase === "OUTPUT" ? (
        <JobsOutput
          jobs={jobs}
          setJobs={setJobs}
          phase={phase}
          animationType={animationType}
          setSnackbarOpen={setSnackbarOpen}
          setPhase={setPhase}
          event={event}
          setAnimationType={setAnimationType}
        />
      ) : null}
    </Box>
  ) : null;
};

export default JobsDialogHub;
