import { Box, Grid } from "@mui/material";
import LandingCapabilitiesModuleTextSection from "./LandingCapabilitiesModuleTextSection";

const LandingCapabilitiesModule = ({
  inverted,
  title,
  subTitle,
  linkText,
  image,
  linkRef,
  color,
}) => {
  return (
    <Box className="default-page-size">
      <Grid container columns={10} direction={inverted ? "row-reverse" : "row"}>
        <Grid
          item
          sm={10}
          md={4.4}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            mb: { xs: 2 },
          }}
        >
          <Box
            position={"relative"}
            sx={{
              boxShadow: `${color}30 0px 7px 29px 0px`,
              background: `linear-gradient(${
                !inverted ? "45deg" : "225deg"
              },${color}60, #fff)`,
              borderRadius: 3,
              overflow: "hidden",
              padding: 2,
              px: { md: 2, sm: "18vw", xs: "15vw" },
            }}
          >
            <img src={image} style={{ width: "100%", height: "100%" }} />
          </Box>
        </Grid>
        <Grid item xs={1} />
        <LandingCapabilitiesModuleTextSection
          inverted={inverted}
          title={title}
          subTitle={subTitle}
          linkText={linkText}
          linkRef={linkRef}
          color={color}
        />
      </Grid>
    </Box>
  );
};

export default LandingCapabilitiesModule;
