import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  ListItemText,
  Grid,
} from "@mui/material";
import { colors } from "../../theme/colors";
import ContactForm from "../contact/ContactForm";
import RequestNewFeature from "./RequestNewFeature";
import { useAuthContext } from "../../contexts/AuthContext";
import { BugReport } from "@mui/icons-material";
import ReportBug from "./ReportBug";

const HelpDialog = ({
  helpDialogOpen,
  setHelpDialogOpen,
  preferredActiveScreen,
}) => {
  const [selectedForm, setSelectedForm] = useState("Contact Support");
  const { dbUser } = useAuthContext();

  const buttons = ["Contact Support", "Request New Feature", "Report Bug"];

  useEffect(() => {
    if (preferredActiveScreen) {
      setSelectedForm(preferredActiveScreen);
    }
  }, [preferredActiveScreen]);

  const handleHelpItemClick = (button) => {
    setSelectedForm(button);
  };

  return (
    <Dialog onClose={() => setHelpDialogOpen(false)} open={helpDialogOpen}>
      <DialogTitle>
        Help
        <br />
        <p
          style={{ margin: 0, color: colors.secondaryText, fontSize: "0.8rem" }}
        >
          We try to respond to all of your inquiries within 3-5 business days.
          If your matter is urgent, please reach out to us directly at
          info@cliqinvite.com.
        </p>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          {buttons.map((button) => (
            <Grid
              item
              xs={4}
              key={button}
              onClick={() => handleHelpItemClick(button)}
              sx={{
                backgroundColor:
                  selectedForm === button
                    ? `${colors.primaryColor}22`
                    : "transparent",
                mb: 1,
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5,
              }}
            >
              <ListItemText
                sx={{
                  fontFamily: "Poppins",
                  cursor: "pointer",
                  transition: "opacity 200ms ease-in-out",
                  ":hover": {
                    opacity: 0.4,
                  },
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: button === "Cancel" ? 500 : 400,
                    fontSize: "1em",
                    textAlign: "center",
                    color: colors.secondaryText,
                  }}
                >
                  {button}
                </p>
              </ListItemText>
            </Grid>
          ))}
        </Grid>
        {selectedForm === "Contact Support" ? (
          <ContactForm
            buttonSize={"small"}
            name={dbUser?.name}
            email={dbUser?.email}
            close={() => setHelpDialogOpen(false)}
          />
        ) : selectedForm === "Request New Feature" ? (
          <RequestNewFeature
            name={dbUser?.name}
            email={dbUser?.email}
            close={() => setHelpDialogOpen(false)}
          />
        ) : (
          <ReportBug
            name={dbUser?.name}
            email={dbUser?.email}
            close={() => setHelpDialogOpen(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default HelpDialog;
