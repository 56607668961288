import React from "react";

const Logo = ({ height, style, onClick, darkMode }) => {
  return (
    <img
      src={require(`../assets/CLIQLogoTransparent${
        darkMode ? "Dark" : ""
      }.png`)}
      style={{ height: height, ...style }}
      onClick={onClick}
    />
  );
};

export default Logo;
