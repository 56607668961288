import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { DataStore } from "aws-amplify";
import { Event, Organization } from "../../models";
import { colors } from "../../theme/colors";
import RoleModule from "./RoleModule";

const GroupsDisplay = ({
  members,
  setSelectedFilters,
  setSnackbarOpen,
  setSelecting,
  selecting,
  handleAssignMembers,
  selectedMembersQueue,
}) => {
  const [open, setOpen] = useState(false);
  const { dbUser } = useAuthContext();

  const createNewRole = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterMembers = (role) => {
    setSelectedFilters((old) => {
      if (old.some((o) => o.type === "ROLE" && o.filter === role)) {
        return old;
      } else {
        return [...old, { type: "ROLE", filter: role }];
      }
    });
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const roleName = formData.get("name");
    if (roleName === "Edit Groups" || roleName === "No Group") {
      alert(`Group name cannot be '${roleName}'`);
      return;
    } else if (dbUser.createdRoles.includes(roleName)) {
      alert("Cannot have duplicate role names");
      return;
    } else if (dbUser.createdRoles.length === 10) {
      alert("Exceeded maximum 10 groups");
    } else if (roleName.includes("-")) {
      alert("Group name cannot contain character '-'");
    }
    let tempArray = dbUser.createdRoles ? [...dbUser.createdRoles] : [];
    tempArray.push(roleName);
    DataStore.query(Event, (e) =>
      e.and((e) => [
        e.organizationID.eq(dbUser.id),
        e.endDate.gt(Math.floor(new Date()).toString()),
      ])
    ).then((events) => {
      events.forEach(async (event) => {
        //[ {category: "girl", limits: [{role: "pledge", limit: "-1"}, {role: "exec", limit: "2"}]} ]
        let tempCategories = [];
        event.categories.forEach((category) => {
          tempCategories.push({
            category: category.category,
            limits: [...category.limits, { role: roleName, limit: "-1" }],
          });
        });

        await DataStore.save(
          Event.copyOf(event, (updated) => {
            updated.categories = tempCategories;
          })
        );
      });
    });

    //update existing event limits to accomodate for the new role
    await DataStore.save(
      Organization.copyOf(dbUser, (updated) => {
        updated.createdRoles = tempArray;
      })
    );
    setOpen(false);
  };

  const loading = Boolean(!members);

  return (
    <Grid
      item
      xs={12}
      className="unstyled-card"
      sx={{ p: 1, pb: 2, borderRadius: 2, mb: ".5em" }}
    >
      {loading ? (
        <Skeleton
          sx={{ fontSize: "1.6em", marginBottom: ".5em" }}
          variant="rounded"
        />
      ) : (
        <Box
          style={{
            display: "flex",
            marginBottom: ".5em",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              margin: 0,
              marginBottom: 0,
              fontSize: "1.4em",
              color: colors.primaryText,
            }}
          >
            Groups
          </h1>
          <Button size="small" onClick={createNewRole} variant="contained">
            Add Group
          </Button>
        </Box>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "1em", mb: 1 }} />
      ) : (
        <Grid
          container
          alignItems="center"
          columns={8}
          sx={{
            px: 1,
            py: 0.25,
            mb: 1,
            borderRadius: 1,
            backgroundColor: colors.backgroundHighlight,
          }}
        >
          <Grid item xs={3}>
            <p
              style={{
                fontSize: ".85em",
                color: colors.secondaryText,
                margin: 0,
                textAlign: "center",
              }}
            >
              Group
            </p>
          </Grid>
          <Grid item xs={3}>
            <p
              style={{
                fontSize: ".85em",
                color: colors.secondaryText,
                margin: 0,
                textAlign: "center",
              }}
            >
              # Members
            </p>
          </Grid>
          <Grid item xs={2}>
            <p
              style={{
                fontSize: ".85em",
                color: colors.secondaryText,
                margin: 0,
                textAlign: "center",
              }}
            >
              More
            </p>
          </Grid>
        </Grid>
      )}
      {loading
        ? Array.from({ length: 4 }, (_, i) => (
            <Skeleton
              key={i.toString()}
              variant="rounded"
              sx={{ height: "3em", mb: 1 }}
            />
          ))
        : dbUser?.createdRoles?.map((role, index) => {
            return (
              <Grid item xs={12} key={index.toString()}>
                <RoleModule
                  role={role}
                  roleMembers={members?.filter((m) => m.role === role)}
                  filterMembers={(role) => filterMembers(role)}
                  setSnackbarOpen={setSnackbarOpen}
                  setSelecting={setSelecting}
                  selecting={selecting}
                  handleAssignMembers={handleAssignMembers}
                  selectedMembersQueue={selectedMembersQueue}
                />
              </Grid>
            );
          })}
      {loading ? null : (
        <Grid item xs={12}>
          <RoleModule
            role={"No Group"}
            roleMembers={members?.filter((m) => m.role === "No Group")}
            filterMembers={(role) => filterMembers(role)}
            disabled
            setSelecting={setSelecting}
            selecting={selecting}
            handleAssignMembers={handleAssignMembers}
            selectedMembersQueue={selectedMembersQueue}
          />
        </Grid>
      )}

      <Dialog
        component="form"
        onSubmit={handleConfirm}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Create new group</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            margin="dense"
            label="Group name"
            fullWidth
            name="name"
            variant="standard"
            InputLabelProps={{
              style: {
                color: colors.secondaryText,
                fontFamily: "Poppins",
              },
            }}
            InputProps={{
              style: {
                color: colors.primaryText,
                fontFamily: "Poppins",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: "Poppins",
              textTransform: "none",
              color: colors.secondaryText,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ fontFamily: "Poppins", textTransform: "none" }}
            type="submit"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default GroupsDisplay;
