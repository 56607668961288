import { API } from "aws-amplify";
import { createInvite } from "../../graphql/mutations";

function createQrCode(eventId) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
  let code = eventId;
  let index;
  for (let i = 0; i < 36; i++) {
    index = Math.floor(Math.random() * characters.length);
    code += characters.charAt(index);
  }
  return code;
}

export const initializeOrgMemberInvites = async ({
  //for SendMemberInvites
  orgID,
  eventInfo,
  eventID,
  MemberModules,
  invites,
  setSnackbarOpen,
  navigate,
  setSent,
  event,
  orgInfo,
}) => {
  let shouldSetSnackbar = false;
  //interate through membermodules, create corrsponding invites if needed
  let updatePromises = MemberModules.map((MemberModule) => {
    const matchingInvite = invites.find(
      (invite) => invite.userId === MemberModule.id
    );

    if (!matchingInvite && MemberModule.num === "INVITED") {
      //if the invite does not already exist and the member is invited, create invite with no extra invites
      shouldSetSnackbar = true;
      return API.graphql({
        query: createInvite,
        variables: {
          input: {
            id: window.crypto.randomUUID(),
            invitesGiven: 0,
            invitesUsed: 0,
            hasResponded: false,
            response: false,
            userId: MemberModule.id,
            organizationID: orgID,
            organizationInfo: orgInfo,
            guestOrgID: null,
            eventID: eventID,
            eventInfo,
            qrCode: createQrCode(eventID),
            isOrg: false,
            membersOnly: false,
            _version: 0,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } else if (!matchingInvite && MemberModule.num === "0") {
      //the member is not invited and has no extra invites to distribute, do not create an invite
      return null;
    } else if (matchingInvite && MemberModule.num === "INVITED") {
      //nothing happens, already invited, should pass
      return null;
    } else {
      return null;
    }
  });

  updatePromises = updatePromises.filter(Boolean);
  try {
    await Promise.all(updatePromises);
    shouldSetSnackbar &&
      setSnackbarOpen({ message: "Successfully sent invites" });
    setSent(true);
    navigate("/dashboard");
  } catch (err) {
    setSent(true);
    setSnackbarOpen({
      message:
        "Unanticipated error while initializing invites. Please try again later",
    });
  }
};
