import { DataStore } from "aws-amplify";
import { Event, GuestInvite, Scan } from "../models";
import { calculateFees } from "./calculateTakeHome";

export const calculateEventData = async (event) => {
  let tempCategory = {};

  let tempScans = [];
  let tempValidScans = 0;
  let tempInvalidScans = [];
  let tempDeniedScans = 0;
  let tempTotalRevenue = 0;
  let tempTotalInvites = 0;
  let tempTotalTickets = 0;

  const result = await DataStore.query(GuestInvite, (g) =>
    g.eventID.eq(event.id)
  );

  result.forEach((invite) => {
    event.categories.forEach((category) => {
      if (invite.category === category.category) {
        const filteredInvites = result.filter(
          (i) => i.category === category.category
        );

        let { revenue, invitesGiven, ticketsSold } = filteredInvites.reduce(
          (acc, num) => {
            acc.revenue += calculateFees(num.cost);
            acc.invitesGiven += num.maxUses;
            acc.ticketsSold += num.cost && num.cost > 0 ? num.maxUses : 0;
            return acc; // Add the return statement here
          },
          { revenue: 0, invitesGiven: 0, ticketsSold: 0 } // Set initial accumulator values
        );

        tempCategory = {
          ...tempCategory,
          [category.category]: {
            ...tempCategory[category.category],
            category: category.category,
            invitesGiven: invitesGiven,
            revenue: revenue,
            ticketsSold: ticketsSold,
          },
        };
      }
    });
  });

  let tempResult = result.reduce(
    (acc, num) => {
      acc.totalTickets += num.cost && num.cost > 0 ? num.maxUses : 0;
      acc.totalRevenue += calculateFees(num.cost);
      acc.totalInvites += num.maxUses;
      return acc; // Add the return statement here
    },
    { totalInvites: 0, totalRevenue: 0, totalTickets: 0 } // Set initial accumulator values
  );
  tempTotalInvites += tempResult.totalInvites;
  tempTotalRevenue += tempResult.totalRevenue;
  tempTotalTickets += tempResult.totalTickets;

  const eventScans = await DataStore.query(Scan, (s) => s.eventID.eq(event.id));
  let fullValidScans = eventScans.filter((s) => s.isValid);
  tempValidScans = fullValidScans.length;
  tempDeniedScans = eventScans.length - tempValidScans;

  event.categories.forEach((category) => {
    tempCategory = {
      ...tempCategory,
      [category.category]: {
        ...tempCategory[category.category],
        validScans: fullValidScans.filter(
          (s) => s.category === category.category
        ).length,
        invalidScans: eventScans.filter(
          (s) => !s.isValid && s.category === category.category
        ).length,
      },
    };

    result.forEach((scan) => {
      let time = parseInt(
        (parseInt(scan.date) - parseInt(event.startDate)) / 60000
      );
      if (
        time > -30 &&
        time <
          parseInt(parseInt(event.endDate) - parseInt(event.startDate)) / 60000
      ) {
        scan.isValid ? tempScans.push(time) : tempInvalidScans.push(time);
      }
    });
  });

  tempScans.sort((a, b) => a - b);
  tempInvalidScans.sort((a, b) => a - b);

  let minLabel = tempScans[0] < 0 ? tempScans[0] : 0;
  let maxLabel =
    tempScans[tempScans.length - 1] > 0 ? tempScans[tempScans.length - 1] : 0;
  let tempFormattedScans = [];
  let tempFormattedInvalidScans = [];
  let tempLabels = [];

  for (let i = 0; i < 10; i++) {
    tempLabels.push(parseInt(minLabel + ((maxLabel - minLabel) / 9) * i));
  }

  for (let i = 0; i < tempLabels.length; i++) {
    let count = 0;
    let invalidCount = 0;

    while (tempScans.length > 0 && tempScans[0] <= tempLabels[i]) {
      count++;
      tempScans.shift();
    }
    while (
      tempInvalidScans.length > 0 &&
      tempInvalidScans[0] <= tempLabels[i]
    ) {
      invalidCount++;
      tempInvalidScans.shift();
    }

    tempFormattedScans[i] = count;
    tempFormattedInvalidScans[i] = invalidCount;
  }
  let remainingInvalid = tempDeniedScans;
  let remainingValid = tempValidScans;
  let remainingGiven = tempTotalInvites;
  let remainingRevenue = tempTotalRevenue;
  let remainingTicketsSold = tempTotalTickets;

  let finalCategories = event.categories.map((category) => {
    const { category: categoryName } = category;
    const { validScans, invalidScans, invitesGiven, revenue, ticketsSold } =
      tempCategory?.[categoryName] || {};
    remainingInvalid -= invalidScans;
    remainingValid -= validScans;
    remainingGiven -= invitesGiven;
    remainingRevenue -= revenue;
    remainingTicketsSold -= ticketsSold;
    return {
      categoryName,
      validScans,
      invalidScans,
      invitesGiven,
      revenue,
      ticketsSold,
    };
  });
  finalCategories.push({
    categoryName: "No Category",
    invalidScans: remainingInvalid,
    validScans: remainingValid,
    invitesGiven: remainingGiven,
    revenue: remainingRevenue,
    ticketsSold: remainingTicketsSold,
  });

  const tempEvent = await DataStore.query(Event, event.id);

  console.log(finalCategories);

  const res = await DataStore.save(
    Event.copyOf(tempEvent, (updated) => {
      updated.analytics = {
        graphData: {
          validScans: tempFormattedScans,
          invalidScans: tempFormattedInvalidScans,
          labels: tempLabels,
        },
        categoryData: finalCategories,
      };
    })
  );
  return res;
};
