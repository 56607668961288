import { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { calculateSubscriptionCosts } from "../../methods/calculateSubscriptionCosts";
import axios from "axios";
import { useAuthContext } from "../../contexts/AuthContext";

const ChangeBillingCycle = ({ cost, plan, setSnackbarOpen, close }) => {
  const { authUser } = useAuthContext();
  const [billingCycle, setBillingCycle] = useState(plan.billingCycle);

  const initialBillingCycle = plan.billingCycle;

  useEffect(() => {
    setBillingCycle(initialBillingCycle);
  }, [initialBillingCycle]);

  const { totalCost } = calculateSubscriptionCosts({
    billingCycle: billingCycle.charAt(0).toUpperCase() + billingCycle.slice(1),
    totalMembers: plan.totalMembers,
    planType: plan.planType,
  });

  const handleUpdateSubscription = async () => {
    if (billingCycle === initialBillingCycle) {
      setSnackbarOpen({
        type: "error",
        message: "You must change the number of members to update your plan.",
      });
      return;
    } else {
      const token = authUser?.signInUserSession.idToken.jwtToken;
      try {
        setSnackbarOpen({
          type: "info",
          message: "Updating subscription plan",
        });
        const res = await axios.put(
          process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
          {
            type: "updateSubscription",
            subscriptionID: plan.stripeSubscriptionID,
            newSubscriptionParams: {
              totalMembers: plan.totalMembers,
              planType: plan.planType,
              billingCycle: billingCycle,
            },
            test: process.env.NODE_ENV !== "production",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSnackbarOpen({
          type: "success",
          message: "Successfully updated subscription plan",
        });
        close();
      } catch (err) {
        console.log(err);
        setSnackbarOpen({
          type: "error",
          message:
            "Failed to create payment method. Please try again, and if the issue persists, please contact support.",
        });
      }
    }
  };

  const handleConfirmPress = () => {
    setSnackbarOpen({
      type: "info",
      message:
        "Are you sure you want to update your organization's subscription plan?",
      action: (
        <Button size="small" color="inherit" onClick={handleUpdateSubscription}>
          Confirm
        </Button>
      ),
    });
  };

  return (
    <Box sx={{ pt: 1 }}>
      <ButtonGroup sx={{ width: "100%" }}>
        <Button
          sx={{ flex: 1 }}
          variant={billingCycle === "month" ? "contained" : "outlined"}
          onClick={() => setBillingCycle("month")}
        >
          Monthly
        </Button>
        <Button
          sx={{ flex: 1 }}
          variant={billingCycle === "year" ? "contained" : "outlined"}
          onClick={() => setBillingCycle("year")}
        >
          Yearly (10% off)
        </Button>
      </ButtonGroup>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mt: 2 }}>
        <Typography variant="body2" sx={{ fontSize: "1em" }}>
          Current subscription price:
        </Typography>
        <Typography variant="h3" sx={{ fontSize: "1em" }}>
          ${cost / 100}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: ".6em" }}>
          / {initialBillingCycle}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        <Typography variant="body2" sx={{ fontSize: "1em" }}>
          New subscription cost:
        </Typography>
        <Typography variant="h3" sx={{ fontSize: "1em" }}>
          ${parseInt(totalCost * plan.promoFactor) / 100}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: ".6em" }}>
          / {billingCycle}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ fontSize: ".7em", mt: 2 }}>
        Changes in billing cycle will take effect at the end of the current
        billing cycle.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button sx={{ color: colors.secondaryText }} onClick={close}>
          Close
        </Button>
        <Button onClick={handleConfirmPress}>Confirm</Button>
      </Box>
    </Box>
  );
};

export default ChangeBillingCycle;
