import { useNavigate } from "react-router-dom";
import { colors } from "../../theme/colors";
import { getModuleDate, getModuleTime } from "../../methods/getFormattedTime";
import { sum } from "../../methods/sum";
import { addCommasToNumbers } from "../../methods/formatNumbers";
import { Box, Typography } from "@mui/material";

const InsightsEventModule = ({ event }) => {
  const navigate = useNavigate();
  const validScans = sum(event.analytics.categoryData, "validScans");
  const invitesGiven = sum(event.analytics.categoryData, "invitesGiven");

  return (
    <Box
      className="event-module-hover"
      sx={{
        maxWidth: "33%",
        bgcolor: colors.backgroundHighlight,
        pl: 2,
        pr: 5,
        py: 1,
        borderRadius: 1,
      }}
      onClick={() => navigate(`/insights/event/${event.id}`)}
    >
      <Typography variant="h3">{event.name}</Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: ".8em",
          mb: 1,
        }}
      >
        {getModuleDate(event.startDate) + ", " + getModuleTime(event.startDate)}
      </Typography>
      <Typography variant="body1" style={{ margin: 0 }}>
        <b>{addCommasToNumbers(validScans)}</b> valid scans
      </Typography>
      <Typography variant="body1" style={{ margin: 0 }}>
        <b>{addCommasToNumbers(invitesGiven)}</b> invites given
      </Typography>
      <Typography variant="body1" style={{ margin: 0 }}>
        <b>{((validScans / invitesGiven) * 100 || 0).toFixed(1)}%</b> attended
      </Typography>
    </Box>
  );
};

const styles = {
  container: {
    padding: "1em 2em",
    borderRadius: 8,
    alignItems: "center",
  },
  eventName: {
    fontSize: "1.3em",
    margin: 0,
    color: colors.primaryText,
  },
};
export default InsightsEventModule;
