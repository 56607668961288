import { Box, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";

const RushStatisticsCard = ({ content }) => {
  const { statistic, tag, description, image } = content;

  return (
    <Box
      sx={{
        bgcolor: colors.eggshell,
        px: {md: 6, xs: 3},
        py: 6,
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 4,
        flex: 1,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "auto",
          position: "absolute",
          top: 0,
        }}
        src={image}
      />
      <Typography
        variant="h1"
        sx={{
          color: colors.defaultBackground,
          letterSpacing: "-.05rem",
          fontSize: "5rem",
          fontWeight: 600,
          zIndex: 1,
        }}
      >
        {statistic}
        {statistic !== 0 ? (
          <span
            style={{
              fontSize: "2.5rem",
              verticalAlign: "super",
              fontWeight: 400,
            }}
          >
            %
          </span>
        ) : (
          ""
        )}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: colors.defaultBackground,
          fontWeight: 600,
          fontSize: "1.4rem",
          marginTop: "-.5rem",
          marginBottom: 3,
          zIndex: 1,
        }}
      >
        {tag}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: colors.defaultBackground,
          fontSize: "1rem",
          letterSpacing: "-.05rem",
          lineHeight: "1.5rem",
          zIndex: 1,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default RushStatisticsCard;
