import React from "react";

import emailjs from "@emailjs/browser";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { colors } from "../../theme/colors";
import { useAuthContext } from "../../contexts/AuthContext";

const RequestNewFeature = ({ name, email, close }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    emailjs.send(
      "service_q5jnjf9",
      "template_hiqke3s",
      {
        name: name,
        email: email,
        subject: `Feature Request: ${data.get("feature")}`,
        message: data.get("description"),
      },
      "lkXy20z68OpeLFbkW"
    );

    e.currentTarget.reset();
    close();
  };
  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextField
        id="feature"
        label="Feature Name"
        name="feature"
        variant="outlined"
        required
        InputLabelProps={{ style: { color: colors.primaryColor } }}
        InputProps={{
          style: {
            color: "white",
            backgroundColor: `${colors.primaryColor}20`,
          },
        }}
        sx={{ caretColor: "white", mb: "1em" }}
        fullWidth
      />
      <TextField
        inputProps={{ borderColor: "white" }}
        id="description"
        label="Feature Description"
        name="description"
        multiline
        variant="outlined"
        minRows={3}
        required
        InputLabelProps={{ style: { color: colors.primaryColor } }}
        InputProps={{
          style: {
            color: "white",
            backgroundColor: `${colors.primaryColor}20`,
            fontFamily: "Poppins",
          },
        }}
        sx={{ caretColor: "white", mb: "1em" }}
        fullWidth
      />

      <Button
        type="submit"
        sx={{
          fontSize: "1em",
          width: "80%",
          mt: 2,
        }}
        size={"small"}
        variant="contained"
      >
        Request
      </Button>
    </Box>
  );
};

export default RequestNewFeature;
