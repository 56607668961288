import React, { useState, useEffect } from "react";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { colors } from "../../theme/colors";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const EventsTimeLine = ({ filteredEvents }) => {
  const [data, setData] = useState({});
  useEffect(() => {
    let invites = [],
      validScans = [],
      invalidScans = [],
      labels = [];

    if (filteredEvents.length === 0) {
      invites = Array.from(
        { length: 10 },
        () => Math.floor(Math.random() * 1000) + 300
      );
      validScans = invites.map((inviteCount) =>
        Math.floor(inviteCount * (Math.random() * 0.4 + 0.5))
      );
      invalidScans = validScans.map((validScansCount) =>
        Math.floor(validScansCount * (Math.random() * 0.1 + 0.1))
      );
      labels = Array.from({ length: 10 }, (_, i) => `Event ${i + 1}`);
    } else {
      filteredEvents?.reverse().forEach((event) => {
        let totalInvitesGiven = 0;
        let totalValidScans = 0;
        let totalInvalidScans = 0;
        event.analytics.categoryData.forEach((data) => {
          totalInvitesGiven += data.invitesGiven;
          totalValidScans += data.validScans;
          totalInvalidScans += data.invalidScans;
        });
        invites.push(totalInvitesGiven);
        validScans.push(totalValidScans);
        invalidScans.push(totalInvalidScans);
        labels.push(event.name);
      });

      if (invites.length === 1) {
        invites = [...invites, ...invites];
      }
      if (validScans.length === 1) {
        validScans = [...validScans, ...validScans];
      }
      if (invalidScans.length === 1) {
        invalidScans = [...invalidScans, ...invalidScans];
      }
      if (labels.length === 1) {
        labels = [...labels, "..."];
      }
    }

    setData({
      labels,
      datasets: [
        {
          label: "Invites",
          showLabel: false,
          data: invites,
          fill: true,
          backgroundColor: `${colors.primaryColor}22`,
          borderColor: colors.primaryColor,
          lineTension: 0.2,
        },
        {
          label: "Valid Scans",
          showLabel: false,
          data: validScans,
          fill: true,
          backgroundColor: `${colors.secondaryColor}22`,
          borderColor: colors.secondaryColor,
          lineTension: 0.2,
        },
        {
          label: "Invalid Scans",
          showLabel: false,
          data: invalidScans,
          fill: true,
          backgroundColor: `${colors.errorColor}22`,
          borderColor: colors.errorColor,
          lineTension: 0.2,
        },
      ],
    });
  }, [filteredEvents]);

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Events",
          font: {
            family: "Poppins",
          },
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
        maxRotation: 90,
        minRotation: 90,
      },
      y: {
        title: {
          display: true,

          text: "Statistic",
          font: {
            family: "Poppins",
          },
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
    plugins: {
      tooltip: {
        bodyFont: {
          family: "Poppins",
        },
        titleFont: {
          family: "Poppins",
        },
      },
      legend: {
        display: true,
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
  };

  if (data.labels) {
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <Line
          data={data}
          options={options}
          style={{ width: "100%", maxHeight: "40vh" }}
        />
        {filteredEvents.length === 0 && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              backgroundColor: `${colors.defaultBackground}BB`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "1.5em",
            }}
          >
            No events yet
          </div>
        )}
      </div>
    );
  }
};

export default EventsTimeLine;
