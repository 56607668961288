import {
  Box,
  Button,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { ArrowDropDownOutlined, EditOutlined } from "@mui/icons-material";
import { formatMoney } from "../../methods/calculateTakeHome";
import BalanceTimeLine from "./BalanceTimeLine";
import { useState } from "react";

const BalanceModule = ({
  loading,
  account,
  balance,
  payIns,
  payouts,
  setVenmoDialogVisible,
}) => {
  const [timeFrame, setTimeFrame] = useState("past month");

  const formatDate = (date, compareDate) => {
    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year =
        date.getFullYear() === compareDate.getFullYear()
          ? ""
          : " " + `'${date.getFullYear().toString().substring(2)}`;

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return [monthNames[date.getMonth()], day, year].join(" ").trim();
  };

  const today = new Date();
  let date;

  // Past Week
  date = new Date();
  date.setDate(today.getDate() - 7);
  const lastWeekStart = formatDate(date, today);
  date = new Date();
  date.setDate(today.getDate() - 1);
  const lastWeekEnd = formatDate(date, today);

  // Past Month
  date = new Date();
  date.setMonth(today.getMonth() - 1);
  const lastMonthStart = formatDate(date, today);
  const lastMonthEnd = formatDate(today, today);

  // Past Year
  date = new Date();
  date.setFullYear(today.getFullYear() - 1);
  const lastYearStart = formatDate(date, today);
  const lastYearEnd = formatDate(today, today);

  return (
    <Box
      className="unstyled-card"
      style={{ flex: 1, backgroundColor: colors.secondaryBackground }}
      sx={{
        borderRadius: 2,
        mb: ".5em",
        p: ".5em 1em",
      }}
    >
      <Box sx={{ display: "flex", flex: 1, alignItems: "flex-start", gap: 2 }}>
        <Box sx={{ flex: 1 }}>
          {loading ? (
            <Skeleton sx={{ fontSize: "1.4em" }} />
          ) : (
            <h1 style={{ fontSize: "1.4em", margin: 0 }}>Balance</h1>
          )}
          {loading ? (
            <Skeleton sx={{ fontSize: ".85em" }} />
          ) : (
            <p
              style={{
                margin: 0,
                color: colors.secondaryText,
                fontSize: ".85em",
                fontWeight: 500,
              }}
            >
              Payout venmo:{" "}
              <b style={{ color: colors.primaryText, fontWeight: 600 }}>
                {account.venmo}
              </b>
            </p>
          )}
        </Box>
        {loading ? null : (
          <Button
            variant="contained"
            startIcon={<EditOutlined sx={{ color: "white" }} />}
            sx={{
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
            }}
            onClick={() => setVenmoDialogVisible(true)}
          >
            Change Venmo
          </Button>
        )}
      </Box>
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "20em", mt: 2 }} />
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                margin: 0,
                marginLeft: ".5em",
                fontSize: "3em",
                fontWeight: 600,
                letterSpacing: "-1px",
                marginBottom: ".2em",
              }}
            >
              <b
                style={{
                  fontWeight: 400,
                  fontSize: ".6em",
                  verticalAlign: "top",
                }}
              >
                $
              </b>
              {formatMoney(balance * 100, "balance") !== "NaN"
                ? formatMoney(balance * 100, "balance")
                : "0.00"}
            </p>
            <Select
              value={timeFrame}
              onChange={(event) => {
                setTimeFrame(event.target.value);
              }}
              IconComponent={() => (
                <ArrowDropDownOutlined
                  sx={{
                    color: colors.secondaryText,
                    cursor: "pointer",

                    ":hover": {
                      opacity: 0.7,
                    },
                  }}
                />
              )}
              SelectDisplayProps={{
                style: {
                  padding: 0,
                  paddingLeft: ".5em",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: { backgroundColor: colors.secondaryBackground },
                },
              }}
              sx={{ marginLeft: "auto", mr: 1 }}
            >
              <MenuItem value={"past week"}>
                {lastWeekStart} - {lastWeekEnd}
              </MenuItem>
              <MenuItem value={"past month"}>
                {lastMonthStart} - {lastMonthEnd}
              </MenuItem>
              <MenuItem value={"past year"}>
                {lastYearStart} - {lastYearEnd}
              </MenuItem>
            </Select>
          </Box>
          <BalanceTimeLine
            payIns={payIns}
            payouts={payouts}
            initialBalance={balance}
            timeframe={timeFrame}
          />
        </Box>
      )}
    </Box>
  );
};

export default BalanceModule;
