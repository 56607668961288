import { Box, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import { useState } from "react";
import "./RushCapabilityCard.css";

const RushCapabilityCard = ({ capability }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { title, subTitle, detail, icon } = capability;

  if (!title) return null;

  return (
    <Box
      className={`capability-card${isOpen ? "-flipped" : ""}`}
      sx={{
        bgcolor: colors.eggshell,
        p: 2,
        borderRadius: 4,
        boxShadow: "3px 10px 10px rgba(0, 0, 0, 0.15)",
        height: { md: 250, sm: 200, xs: 225 },
        position: "relative" /* Needed for absolute positioning of children */,
        transformStyle: "preserve-3d" /* Necessary for 3D transformations */,
        transition: "transform 1s" /* Animation for flipping */,
        fontSize: { md: ".875em", lg: "1em", xs: "1.2em" },
      }}
    >
      <Box
        className="card-front"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { lg: "90%", md: "85%", sm: "85%", xs: "94%" },
          height: { md: "90%", xs: "85%" },
          gap: 2,
        }}
      >
        {icon}
        <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h3"
            sx={{
              color: colors.defaultBackground,
              letterSpacing: "-.05em",
              fontSize: "1.2rem",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: colors.backgroundHighlight,
              fontWeight: 300,
              fontSize: "1rem",
            }}
          >
            {subTitle}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            textDecoration: "underline",
            color: colors.nemoBlue,
            fotnSize: ".9em",
            transition: "color 500ms ease-in-out",
            cursor: "pointer",
            mt: "auto",
          }}
          onClick={() => setIsOpen((old) => !old)}
        >
          Learn More ▼
        </Typography>
      </Box>
      <Box
        className="card-back"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          right: { xs: 16 },
          width: { lg: "90%", md: "85%", sm: "85%", xs: "94%" },
          height: { md: "90%", xs: "85%" },
        }}
      >
        <Box sx={{ flexDirection: "column", display: "flex" }}>
          <Typography
            variant="h3"
            sx={{
              color: colors.defaultBackground,
              letterSpacing: "-.05em",
              mb: 2,
            }}
          >
            {title}
          </Typography>

          {detail.map((detail, index) => (
            <Typography
              key={index.toString()}
              variant="body2"
              sx={{
                color: colors.backgroundHighlight,
                fontWeight: 300,
                fontSize: ".8em",
              }}
            >
              • {detail}
            </Typography>
          ))}
        </Box>
        <Typography
          variant="body2"
          sx={{
            textDecoration: "underline",
            color: colors.nemoBlue,
            fotnSize: ".9em",
            transition: "color 500ms ease-in-out",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen((old) => !old)}
        >
          Done
        </Typography>
      </Box>
    </Box>
  );
};

export default RushCapabilityCard;
