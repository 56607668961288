import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import React from "react";
import PhilanthropyCapabilityCard from "./PhilanthropyCapabilityCard";
import {
  CategoryOutlined,
  MailOutlined,
  QrCodeScannerOutlined,
  ListOutlined,
  LockPersonOutlined,
  AnalyticsOutlined,
  WorkHistoryOutlined,
  LinkOutlined,
  GavelOutlined,
  Apple,
} from "@mui/icons-material";

const capabilityData = [
  {
    title: "Ticket Management",
    capabilities: [
      {
        title: "Ticket Links",
        icon: (
          <LinkOutlined sx={{ fontSize: "4rem", color: colors.softGreen }} />
        ),
        subTitle:
          "Easily send unique, limited-use ticket links to your guests via any app",
        detail: [
          "Enable your guests to purchase tickets in seconds",
          "Assign ticket links a category and purchase limit upon creation",
          "Set purchase limits at the event-wide and link-by-link level",
          "Adjust ticket link limits in real-time",
        ],
      },
      {
        title: "Purchase Limits",
        icon: (
          <GavelOutlined sx={{ fontSize: "4rem", color: colors.softGreen }} />
        ),
        subTitle: "Set ticket ceilings on an event-wide and link-by-link level",
        detail: [
          "Prevent overselling or overbooking",
          "Filter your attendance breakdown with link categories and limits",
          "Change your limits in real-time to adjust for real-world circumstances",
        ],
      },
      {
        title: "Free Tickets",
        icon: (
          <ListOutlined sx={{ fontSize: "4rem", color: colors.softGreen }} />
        ),
        subTitle: "Optionally distribute free tickets to special guests",
        detail: [
          "Allow individual brothers to send free tickets",
          "Assign brothers a category-specific free ticket ceiling",
          "Monitor brother free ticket usage",
          "Repeat previous patterns with Shortcuts",
        ],
      },
    ],
  },
  {
    title: "Guest Management",
    capabilities: [
      {
        title: "Ticket Scanning",
        icon: (
          <QrCodeScannerOutlined
            sx={{ fontSize: "4rem", color: colors.softGreen }}
          />
        ),
        subTitle: "Streamline guest admission with instant ticket scanning",
        detail: [
          "Quickly scan tickets for fast entry",
          "Minimize queues and wait times at the entrance",
          "Enhance security with validated ticket checks",
          "Real-time guest admission tracking",
          "View all ticket information upon scan",
        ],
      },
      {
        title: "Unique and Limited-Use",
        icon: (
          <LockPersonOutlined
            sx={{ fontSize: "4rem", color: colors.softGreen }}
          />
        ),
        subTitle:
          "Guests receive unique limited-use tickets straight to their phone",
        detail: [
          "Guests are texted or emailed tickets after purchase",
          "View guest name and originating brother upon scan",
          "Track ticket uses and view all ticket activity upon scan",
        ],
      },
      {
        title: "Payment Options",
        icon: (
          <Apple
            sx={{ fontSize: "4rem", color: colors.softGreen }}
          />
        ),
        subTitle:
          "Guests can purchase a ticket with many methods, including Apple Pay, Google Pay, and more",
        detail: [
          "Enable guests to use any payment method",
          "Allow guests to save payment methods with third-party services",
          "Maintain enterprise-grade security with our Stripe integration",
        ],
      },
    ],
  },
  {
    title: "Advanced Event Management",
    capabilities: [
      {
        title: "Event Analytics",
        icon: (
          <AnalyticsOutlined
            sx={{ fontSize: "4rem", color: colors.softGreen }}
          />
        ),
        subTitle: "Unlock valuable insights with comprehensive event analytics",
        detail: [
          "Access live data on guest attendance",
          "Generate custom reports for post-event analysis",
          "Gain insights into peak times and guest flow",
          "Analyze member performance and revenue over time",
          "Make data-driven decisions for future events",
        ],
      },
      {
        title: "Event Shifts",
        icon: (
          <WorkHistoryOutlined
            sx={{ fontSize: "4rem", color: colors.softGreen }}
          />
        ),
        subTitle:
          "Schedule shifts for your brothers with reminder notifications",
        detail: [
          "Automatically generate a schedule",
          "Notify brothers for their shift during events",
          "Easily view your shifts",
          "COMING SOON: Host a shift sign up sheet",
          "COMING SOON: Check in at your shift",
        ],
      },
    ],
  },
];

const PhilanthropyCapabilitiesSection = () => {
  return (
    <Box
      sx={{
        py: { sm: 12, xs: 8 },
        pb: { sm: 28 },
        gap: 8,
        bgcolor: colors.eggshell,
        position: "relative",
      }}
      width="100dvw"
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <Box
        className="default-page-size"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h2"
          sx={{
            color: colors.defaultBackground,
            lineHeight: 1.12,
            letterSpacing: "-.8px",
            textAlign: "center",
            alignSelf: "center",
            fontSize: {
              lg: "2rem",
              md: "2rem",
              sm: "2rem",
              xs: "1.6rem",
            },
            width: {
              md: "70%",
            },
            mb: "3rem",
          }}
        >
          Learn how CLIQInvite's philanthropy features can increase revenue and
          reduce friction
        </Typography>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={{ md: 0, lg: 10, xs: 1 }}
          sx={{ zIndex: 1 }}
        >
          {capabilityData.map(({ title, capabilities, icon }, index) => {
            return (
              <React.Fragment key={index.toString()}>
                <Grid item lg={2.5} md={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      py: { md: 3, xs: 0 },
                      mt: { xs: 5, md: index === 0 ? 0 : 2, lg: 0 },
                      mb: { xs: 2, md: 0 },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        color: colors.defaultBackground,
                        fontSize: {
                          xs: "1.5rem",
                        },

                        textAlign: { md: "left", xs: "center" },
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                </Grid>

                {capabilities.map((capability) => (
                  <Grid item md={4} lg={3.166} xs={12}>
                    <PhilanthropyCapabilityCard capability={capability} />
                  </Grid>
                ))}
              </React.Fragment>
            );
          })}
        </Grid>
      </Box>
      <img
        src={require("../../../assets/Rush_Capabilities_Landscape.png")}
        style={{
          width: "100%",
          height: "auto",
          position: "absolute",
          bottom: 0,
        }}
      />
    </Box>
  );
};

export default PhilanthropyCapabilitiesSection;
