import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import Logo from "../../components/Logo";
import { Close, Pages } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { colors } from "../../theme/colors";

const NavigationDrawer = ({ drawerOpen, toggleDrawer, pages, url }) => {
  const navigate = useNavigate();
  const handleLinkClick = (path) => {
    toggleDrawer();
    navigate(path);
  };
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer}
      elevation={24}
      transitionDuration={400}
    >
      <div
        style={{
          height: "100dvh",
          backgroundColor: "black",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Toolbar
          className="default-page-size"
          style={{
            height: 80,
          }}
          disableGutters={true}
        >
          <Logo height="2rem" />
          <IconButton
            sx={{
              marginLeft: "auto",
            }}
            onClick={toggleDrawer}
          >
            <Close color="primary" sx={{ fontSize: "1.5rem" }} />
          </IconButton>
        </Toolbar>

        <List
          className="default-page-size"
          sx={{ alignSelf: "center" }}
          disablePadding={true}
        >
          {pages.map(({ text, route, onClick }) => (
            <ListItemButton
              disableGutters={true}
              onClick={(e) => (onClick ? onClick(e) : handleLinkClick(route))}
              sx={{
                "&:hover": {
                  color: "white", // Change color as needed
                },
              }}
            >
              <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                {text}
              </Typography>
            </ListItemButton>
          ))}
        </List>
        <Box
          sx={{
            mt: "auto",
            flexDirection: { sm: "row", xs: "column" },
            display: "flex",
            px: "2rem",
            py: "1.5rem",
            gap: { xs: 1 },
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              window.open(
                "https://calendly.com/cliqinvite/intro",
                "_blank",
                "noreferrer"
              )
            }
            sx={{ flex: { md: "none", sm: 1 } }}
          >
            Request a Demo
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.open(url, "_blank", "noreferrer")}
            sx={{ flex: { md: "none", sm: 1 } }}
          >
            Get CLIQInvite
          </Button>
        </Box>
      </div>
    </Drawer>
  );
};

export default NavigationDrawer;
