import { ListItem, ListItemButton } from "@mui/material";
import ProfileImage from "../../components/ProfileImage";
import { colors } from "../../theme/colors";

const ShareModalSearchResultModule = ({
  result,
  selectedUsers,
  setSelectedUsers,
  setAccessingUsers,
  setSearchQuery,
}) => {
  return (
    <ListItem sx={{}} dense disableGutters>
      <ListItemButton
        onClick={() => {
          let tempSelectedUsers = selectedUsers;
          tempSelectedUsers.add(result.id);
          setSelectedUsers(tempSelectedUsers);
          setAccessingUsers((old) => [...old, result]);
          setSearchQuery("");
        }}
        sx={{
          ":hover": { backgroundColor: "#030303" },
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          gap: 1.5,
          py: 0.5,
        }}
      >
        <ProfileImage pic={result?.profilePic} height="2.5em" />
        <div style={{flex: 1 }}>
          <p
            style={{
              color: colors.primaryText,
              margin: 0,
              fontWeight: 600,
              fontSize: "1em",
            }}
          >
            {result.name}
          </p>
          <p
            style={{
              color: colors.secondaryText,
              margin: 0,
              fontWeight: 500,
              fontSize: ".8em",
            }}
          >
            @ {result.shortSchool}
          </p>
        </div>
      </ListItemButton>
    </ListItem>
  );
};

export default ShareModalSearchResultModule;
