import { Box, Typography } from "@mui/material";
import {
  addCommasToNumbers,
  shortenNumberForModule,
} from "../../methods/formatNumbers";
import { colors } from "../../theme/colors";

const InsightModule = ({ header, subheader, statistic, numberFormat }) => {
  let formattedNumber =
    numberFormat === "long"
      ? addCommasToNumbers(statistic)
      : !isNaN(statistic)
      ? shortenNumberForModule(statistic)
      : statistic;
  return (
    <Box
      sx={{
        bgcolor: colors.backgroundHighlight,
        borderRadius: 1,
        px: 2,
        py: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="body2">{header}</Typography>
      {subheader ? (
        <Typography
          variant="body1"
          sx={{
            fontSize: ".85em",
            mb: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {subheader}
        </Typography>
      ) : null}
      <Typography
        variant="h1"
        style={{
          color: colors.primaryColor,
          fontSize: "2.2em",
          textAlign: "center",
        }}
      >
        {formattedNumber || 0}
      </Typography>
    </Box>
  );
};

export default InsightModule;
