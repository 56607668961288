import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../../theme/colors";
import {
  Add,
  ArrowForwardOutlined,
  DeleteForeverOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";

let fratPartyJobs = [
  "Door Security",
  "Bartender",
  "Clean-up Crew",
  "Assistant to the Bartender",
  "Set-up Crew",
  "Videographer",
  "Safety Officer",
  "Crowd Control",
  "DJ",
  "Bouncer",
];

const DefineJobs = ({
  jobs,
  setJobs,
  phase,
  animationType,
  setPhase,
  setSnackbarOpen,
  setAnimationType,
}) => {
  const [randomFratPartyJobs, setRandomFratPartyJobs] = useState([]);

  useEffect(() => {
    setRandomFratPartyJobs(scramble(fratPartyJobs));
  }, []);

  function scramble(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
  const handleAddJob = () => {
    setJobs((old) => {
      return [...old, { name: "", quantity: "1" }];
    });
  };

  const handleDeleteJob = (index) => {
    setJobs((old) => {
      let newJobs = old.map((old, oldIndex) => {
        if (oldIndex !== index) {
          return old;
        } else return null;
      });
      return newJobs.filter(Boolean);
    });
  };

  const handleNextPress = () => {
    setAnimationType("FORWARD");

    //check to see if there are any duplicate jobs
    let duplicate = false;

    let nullName = false;

    let jobNames = jobs.map((job) => job.name);

    jobNames.forEach((name) => {
      if (jobNames.indexOf(name) !== jobNames.lastIndexOf(name)) {
        duplicate = true;
      } else if (name === "") {
        nullName = true;
      }
    });

    if (duplicate) {
      setSnackbarOpen({
        type: "error",
        message: "You cannot have duplicate job names",
      });
      return;
    }

    if (nullName) {
      setSnackbarOpen({
        type: "error",
        message: "You cannot have an empty job name",
      });
      return;
    }
    setAnimationType("FORWARD");
    setPhase("TIMES");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 2,
        py: 2,
        borderRadius: 2,
        width: {
          sm: "60vw",
          xl: "45vw",
        },
        position: "absolute",
        alignSelf: "center",
        bgcolor: colors.secondaryBackground,
      }}
      onClick={(e) => e.stopPropagation()}
      className={
        phase === "NAMES"
          ? animationType === "FORWARD"
            ? "subscription-slide-in"
            : "subscription-slide-back-in"
          : phase === "TIMES" && animationType === "FORWARD"
          ? "subscription-slide-out"
          : "subscription-off-screen"
      }
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h1"
          sx={{ fontWeight: 500, flex: 1, fontSize: "1.3em" }}
        >
          Define Jobs
        </Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add sx={{ color: colors.primaryText }} />}
          size="small"
          onClick={handleAddJob}
        >
          Add Job
        </Button>
      </Box>
      <Box
        className="scroll-container"
        sx={{ mt: 2, maxHeight: "70dvh", overflow: "auto" }}
      >
        <Grid
          container
          sx={{
            bgcolor: colors.backgroundHighlight,
            borderRadius: 1,
            px: 1,
            py: 0.5,
            mb: 0.75,
          }}
        >
          <Grid item xs={9}>
            <Typography variant="body2" sx={{ fontSize: ".8em" }}>
              Job Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" sx={{ fontSize: ".8em" }}>
              Shift Size
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2" sx={{ fontSize: ".8em" }}>
              Delete
            </Typography>
          </Grid>
        </Grid>
        {jobs.map((job, index) => {
          const randomJobName =
            randomFratPartyJobs[index % randomFratPartyJobs.length];
          return (
            <Grid
              container
              key={index.toString()}
              sx={{
                bgcolor: colors.backgroundHighlight,
                borderRadius: 1,
                px: 1,
                py: 0.5,
                mb: 0.5,
              }}
            >
              <Grid item xs={9} sx={{ pr: 2 }}>
                <TextField
                  value={job.name}
                  placeholder={randomJobName}
                  onChange={(e) =>
                    setJobs((oldJobs) => {
                      let newJobs = oldJobs.map((job, oldIndex) => {
                        if (oldIndex === index) {
                          return { ...job, name: e.target.value };
                        } else {
                          return job;
                        }
                      });
                      return newJobs;
                    })
                  }
                  inputProps={{ maxLength: 26 }}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    bgcolor: colors.secondaryBackground,
                    borderRadius: 1,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={2} sx={{ pr: 2 }}>
                <TextField
                  type="number"
                  value={job.quantity}
                  onChange={(e) =>
                    setJobs((oldJobs) => {
                      let newJobs = oldJobs.map((job, oldIndex) => {
                        if (oldIndex === index) {
                          return { ...job, quantity: e.target.value };
                        } else {
                          return job;
                        }
                      });
                      return newJobs;
                    })
                  }
                  inputProps={{ maxLength: 3 }}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    bgcolor: colors.secondaryBackground,
                    borderRadius: 1,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={1} display="flex" justifyContent={"center"}>
                <IconButton size="small">
                  <DeleteForeverOutlined
                    color="error"
                    onClick={() => handleDeleteJob(index)}
                  ></DeleteForeverOutlined>
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        startIcon={<ArrowForwardOutlined sx={{ color: colors.primaryText }} />}
        onClick={handleNextPress}
      >
        Next
      </Button>
    </Box>
  );
};

export default DefineJobs;
