import React, { useState } from "react";
import { colors } from "../../theme/colors";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { getModuleDate, getModuleTime } from "../../methods/getFormattedTime";

const ContactEventModule = ({
  event,
  relevantInvites,
  setSelectedFilters,
  setSelectedEvent,
  isSelected,
  contacts,
  upcoming,
}) => {
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
  let moreOptionsButtons = ["Filter by invited", "Label by invite status"];
  if (!upcoming) {
    moreOptionsButtons.unshift("Filter by who came");
  } else {
    moreOptionsButtons.unshift("Filter by not invited");
  }

  const toggleMoreOptionsDropdown = (event) => {
    setMoreOptionsAnchorEl((old) => (!old ? event.currentTarget : null));
  };

  const handleMoreOptionsItemClick = (buttonIndex) => {
    if (buttonIndex === 1) {
      setSelectedFilters((old) => {
        if (old.find((o) => o.type === "INVITED" && o.filter === event.id)) {
          return;
        } else {
          return [...old, { type: "INVITED", filter: event.id }];
        }
      });
    } else if (buttonIndex === 0) {
      if (!upcoming) {
        setSelectedFilters((old) => {
          if (old.find((o) => o.type === "ATTENDED" && o.filter === event.id)) {
            return;
          } else {
            return [...old, { type: "ATTENDED", filter: event.id }];
          }
        });
      } else {
        setSelectedFilters((old) => {
          if (
            old.find((o) => o.type === "NOTINVITED" && o.filter === event.id)
          ) {
            return;
          } else {
            return [...old, { type: "NOTINVITED", filter: event.id }];
          }
        });
      }
    } else if (buttonIndex === 2) {
      setSelectedEvent(event);
    }
  };

  let uniqueRelevantInvites = new Set([]);
  let uniqueRelevantAttendees = new Set([]);
  relevantInvites.forEach((invite) => {
    uniqueRelevantInvites.add(invite.contactID);
    if (invite.numUsed) {
      uniqueRelevantAttendees.add(invite.contactID);
    }
  });

  return (
    <Grid
      container
      columns={10}
      alignItems="center"
      sx={{
        backgroundColor: isSelected
          ? `${colors.primaryColor}22`
          : colors.backgroundHighlight,
        p: 1,
        borderRadius: 1,
        mb: ".3em",
      }}
    >
      <Grid item xs={4}>
        <h4
          style={{
            margin: 0,
            color: colors.primaryText,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {event.name}
        </h4>
        <p
          style={{ margin: 0, color: colors.secondaryText, fontSize: ".85em" }}
        >
          {getModuleDate(event.startDate).substring(
            0,
            getModuleDate(event.startDate).indexOf(",")
          )}
          , {getModuleTime(event.startDate)}
        </p>
      </Grid>
      <Grid item xs={2}>
        <h3
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: "1em",
            textAlign: "center",
          }}
        >
          {uniqueRelevantInvites?.size || 0}
        </h3>
      </Grid>
      <Grid item xs={2}>
        <h3
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: "1em",
            textAlign: "center",
          }}
        >
          {!upcoming
            ? uniqueRelevantAttendees?.size || 0
            : contacts.size - uniqueRelevantInvites?.size}
        </h3>
      </Grid>
      <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton onClick={toggleMoreOptionsDropdown}>
          <MoreHoriz sx={{ color: colors.secondaryText, fontSize: 24 }} />
        </IconButton>
        <Menu
          anchorEl={moreOptionsAnchorEl}
          open={Boolean(moreOptionsAnchorEl)}
          onClose={toggleMoreOptionsDropdown}
        >
          {moreOptionsButtons.map((button, index) => (
            <MenuItem
              key={index.toString()}
              onClick={() => {
                handleMoreOptionsItemClick(index);
                toggleMoreOptionsDropdown();
              }}
              sx={{
                px: 3,
                ":hover": {
                  backgroundColor: "#030303",
                },
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: colors.secondaryText,
                  fontSize: ".85em",
                  fontWeight: 400,
                }}
              >
                {button}
              </p>
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
};

export default ContactEventModule;
