import React, { useState, useEffect } from "react";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Filler,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { colors } from "../../theme/colors";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Filler,
  Title,
  Tooltip,
  Legend
);

const BalanceTimeLine = ({ payIns, payouts, initialBalance, timeframe }) => {
  const [data, setData] = useState([]);

  function toLocalISO(date) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString();
  }

  useEffect(() => {
    const transactions = [
      ...payIns?.map((payIn) => ({
        type: "PayIn",
        date: new Date(parseInt(payIn.endDate)),
        amount: payIn.revenue,
      })),
      ...payouts?.map((payout) => ({
        type: "PayOut",
        date: new Date(payout.createdAt),
        amount: payout.amount,
      })),
    ];

    // Sort transactions by date
    transactions.sort((a, b) => a.date - b.date);

    let startDate = new Date();
    let endDate = new Date();

    if (timeframe === "past week") {
      startDate.setDate(endDate.getDate() - 7);
    } else if (timeframe === "past month") {
      startDate.setMonth(endDate.getMonth() - 1);
    } else if (timeframe === "past year") {
      startDate.setFullYear(endDate.getFullYear() - 1);
    }

    // Calculate net transaction amount during the period
    let netTransactionAmount = transactions.reduce((total, transaction) => {
      if (transaction.date >= startDate && transaction.date <= endDate) {
        return (
          total +
          (transaction.type === "PayIn"
            ? transaction.amount
            : -transaction.amount)
        );
      } else {
        return total;
      }
    }, 0);

    // Calculate the balance at the beginning of the period
    let startBalance = initialBalance - netTransactionAmount;

    let balance = startBalance;
    let balanceOverTime = transactions.map((transaction) => {
      if (transaction.type === "PayIn") {
        balance += transaction.amount;
      } else if (transaction.type === "PayOut") {
        balance -= transaction.amount;
      }

      const date = transaction.date;

      return { t: date, y: balance };
    });

    // Filter based on the timeframe selected
    let labels = [];

    // Convert to ISO format (yyyy-mm-dd)
    let startLabel = toLocalISO(startDate);
    let endLabel = toLocalISO(endDate);

    // Add transaction dates
    balanceOverTime.forEach((point) => {
      let transactionDate = toLocalISO(point.t);
      if (
        transactionDate >= startLabel &&
        transactionDate <= endLabel &&
        !labels.includes(transactionDate)
      ) {
        labels.push(transactionDate);
      }
    });

    // Sort labels
    labels.sort();

    // As labels are sorted and they all fall between startLabel and endLabel,
    // we are sure that startLabel and endLabel are the smallest and largest among them
    labels = [startLabel, ...labels, endLabel];

    const now = new Date();
    startDate = new Date();
    if (timeframe === "past week") {
      startDate.setDate(now.getDate() - 7);
      balanceOverTime = balanceOverTime.filter(
        (point) => (now - point.t) / (1000 * 60 * 60 * 24) <= 7
      );
    } else if (timeframe === "past month") {
      startDate.setMonth(now.getMonth() - 1);
      balanceOverTime = balanceOverTime.filter(
        (point) => (now - point.t) / (1000 * 60 * 60 * 24) <= 30
      );
    } else if (timeframe === "past year") {
      startDate.setFullYear(now.getFullYear() - 1);
      balanceOverTime = balanceOverTime.filter(
        (point) => (now - point.t) / (1000 * 60 * 60 * 24) <= 365
      );
    }

    balanceOverTime = [
      { t: startLabel, y: startBalance },
      ...balanceOverTime,
      { t: endLabel, y: initialBalance },
    ].map((b) => b.y);

    // If there are no transactions in the timeframe, add a flat line
    if (balanceOverTime.length === 0) {
      balanceOverTime = [initialBalance, initialBalance];
    }

    setData({
      labels,
      datasets: [
        {
          label: "Balance",
          showLabel: false,
          data: balanceOverTime,
          fill: true,
          backgroundColor: `${colors.primaryColor}22`,
          borderColor: colors.primaryColor,
          lineTension: 0.1,
        },
      ],
    });
  }, [payIns, payouts, timeframe, initialBalance]);

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit:
            timeframe === "past week"
              ? "day"
              : timeframe === "past month"
              ? "week"
              : "month",
          displayFormats: {
            quarter: "MMM YYYY",
          },
          font: {
            family: "Poppins",
          },
        },
        title: {
          display: true,
          text: "Date",
          font: {
            family: "Poppins",
          },
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        title: {
          display: true,

          text: "Balance",
          font: {
            family: "Poppins",
          },
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
    plugins: {
      tooltip: {
        bodyFont: {
          family: "Poppins",
        },
        titleFont: {
          family: "Poppins",
        },
      },
      legend: {
        display: false,
      },
    },
  };
  if (data.datasets) {
    return <Line data={data} options={options} style={{maxHeight: '40dvh'}}/>;
  }
};

export default BalanceTimeLine;
