import { Box, Skeleton, TextField, Typography } from "@mui/material";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { colors } from "../../theme/colors";

const EditEventBasicsSection = ({
  loading,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setTitle,
  title,
}) => {
  return (
    <>
      {loading ? null : <Typography variant="body2">Event Basics</Typography>}
      {loading ? (
        <Skeleton variant="rounded" height="6em" sx={{ my: 1 }} />
      ) : (
        <>
          <TextField
            label="Title"
            name="name"
            fullWidth
            inputProps={{
              maxLength: 40,
            }}
            onChange={(e) => setTitle(e.target.value)}
            size="small"
            sx={{
              caretColor: colors.primaryColor,
              mb: 1.5,
              mt: 0.75,
              bgcolor: colors.backgroundHighlight,
              borderRadius: 1,
            }}
            value={title}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: 1,
                mb: 2,
              }}
            >
              <DateTimeField
                name="startDate"
                label="Start Date"
                sx={{
                  flex: 1,
                  border: "none",
                  color: "white",
                  borderRadius: 1,
                  bgcolor: colors.backgroundHighlight,
                  caretColor: colors.primaryColor,
                }}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                onChange={setStartDate}
                value={startDate}
              />
              <DateTimeField
                name="endDate"
                label="End Date"
                sx={{
                  flex: 1,
                  border: "none",
                  color: "white",
                  borderRadius: 1,
                  bgcolor: colors.backgroundHighlight,
                  caretColor: colors.primaryColor,
                }}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                onChange={setEndDate}
                value={endDate}
              />
            </Box>
          </LocalizationProvider>
        </>
      )}
    </>
  );
};

export default EditEventBasicsSection;
