import { Auth } from "aws-amplify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PublicHeader from "../../public/navigation/PublicHeader";
import "./Auth.css";
import { colors } from "../../theme/colors";
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useAuthContext } from "../../contexts/AuthContext";
import { EmailOutlined, LoginOutlined } from "@mui/icons-material";
import { cleanPhoneNumber, formatPhoneNumber } from "./formatPhoneNumber";
import EnterPhoneNumber from "./EnterPhoneNumber";
import EnterCode from "./EnterCode";

function generatePassword() {
  // Define the characters to choose from
  var letters = "abcdefghijklmnopqrstuvwxyz";
  var numbers = "0123456789";
  var specialChars = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

  // Initialize the password with one character of each type
  var password = [
    getRandomCharacter(letters),
    getRandomCharacter(numbers),
    getRandomCharacter(letters.toUpperCase()),
    getRandomCharacter(specialChars),
  ];

  // Generate the remaining characters
  for (var i = 0; i < 12; i++) {
    // Choose a random type of character
    var charType = getRandomItem([
      letters,
      numbers,
      letters.toUpperCase(),
      specialChars,
    ]);
    // Add a random character of the chosen type
    password.push(getRandomCharacter(charType));
  }

  // Shuffle the characters to make the password more random
  password.sort(function () {
    return 0.5 - Math.random();
  });

  // Convert the array to a string
  password = password.join("");

  return password;
}

// Helper function to get a random character from a given string
function getRandomCharacter(characters) {
  return characters.charAt(Math.floor(Math.random() * characters.length));
}

// Helper function to get a random item from an array
function getRandomItem(array) {
  return array[Math.floor(Math.random() * array.length)];
}

const Login = () => {
  const [stage, setStage] = useState("PHONE"); //PHONE, VERIFY, CREATE, DONE
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [phone, setPhone] = useState("+1 ");
  const [code, setCode] = useState("");
  const { dbUser, authUser } = useAuthContext();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  useEffect(() => {
    const hasNotFinishedAccountSetup = authUser && dbUser === null;
    const fullySetup = authUser && dbUser;

    if (hasNotFinishedAccountSetup) {
      setLoading(false);
      setStage("CREATE");
    }
  }, [dbUser, authUser]);

  const handleResendCode = async () => {
    try {
      setSnackbarOpen({ type: "info", message: "Code resending..." });
      await Auth.signIn(`+1${cleanPhoneNumber(phone)}`);
      setSnackbarOpen({ type: "success", message: "Code successfully resent" });
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Unknown error while resending code. Please try again later",
      });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    } else if (stage === "PHONE") {
      const filteredPhoneNumber = `+1${cleanPhoneNumber(phone)}`;
      try {
        setLoading(true);
        const tempCognitoUser = await Auth.signIn(filteredPhoneNumber);
        setCognitoUser(tempCognitoUser);
        setLoading(false);
        setStage("VERIFY");
      } catch (err) {
        if (err.message === "User does not exist.") {
          const password = generatePassword();
          try {
            await Auth.signUp({
              username: filteredPhoneNumber,
              password,
              attributes: {
                "custom:type": "user" /* Similar to user type */,
                phone_number: filteredPhoneNumber,
                name: "Unauthed User",
              },
            });
            const tempCognitoUser = await Auth.signIn(filteredPhoneNumber);
            setCognitoUser(tempCognitoUser);
            setLoading(false);
            setStage("VERIFY");
          } catch (err) {
            setSnackbarOpen({ type: "error", message: err.message });
            setLoading(false);
          }
        } else {
          setSnackbarOpen({ type: "error", message: err.message });
        }
      }
    } else if (stage === "VERIFY") {
      try {
        setLoading(true);
        const challengeResult = await Auth.sendCustomChallengeAnswer(
          cognitoUser,
          code
        );
        if (challengeResult.challengeName) {
          let attemptsLeft = parseInt(
            challengeResult.challengeParam.attemptsLeft
          );

          alert(
            `The code you entered is incorrect. ${attemptsLeft} attempts left.`
          );
        }
        // navigate("/dashboard");
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleChangePhone = (text) => {
    const AREA_CODE = "+1";
    setPhone(`${AREA_CODE} ${formatPhoneNumber(text)}`);
  };
  const pageTitle = `Login • CLIQInvite`;
  const pageDescription = `Access the revolution in event management. Log in to CLIQInvite to streamline your social events, philanthropy, and recruitment. Secure invites, real-time tracking, and detailed analytics await.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/login`;

  return (
    <Box
      sx={{
        height: "100svh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "radial-gradient(ellipse at 0% 0%, #a020f030, black 80%)",
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Snackbar open={!!snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: colors.eggshell,
            width: { sm: "32rem", xs: "calc(92dvw - 2.5rem)" },
            borderRadius: 1.5,
            py: { xs: "2.25rem", sm: "2.5rem", md: "2.5rem", lg: "2.5rem" },
            px: { xs: "1.25rem", sm: "1.75rem", md: "2.5rem", lg: "2.5rem" },
            boxShadow: `${colors.eggshell}22 0px 5px 15px`,
          }}
        >
          <Box className={stage === "VERIFY" || stage === "CREATE" ? "transition-to-code" : ""}>
            <EnterPhoneNumber
              handleLogin={handleLogin}
              handleChangePhone={handleChangePhone}
              phone={phone}
              loading={loading}
              stage={stage}
            />
            <EnterCode
              handleLogin={handleLogin}
              phone={phone}
              loading={loading}
              stage={stage}
              code={code}
              setCode={setCode}
              handleResendCode={handleResendCode}
            />
          </Box>
        </Box>
        {stage === "VERIFY2" ? (
          <Box
            component="form"
            onSubmit={handleLogin}
            className={``}
            sx={{
              fontFamily: "Poppins",
              fontWeight: 600,
              pb: 2,
              position: "absolute",
              px: { xs: 2, md: 2.5, lg: 3, xl: 3 },
              width: {
                xs: "calc(94dvw - 32px)",
                sm: "70dvw",
                md: "60dvw",
                lg: "45dvw",
                xl: "35dvw",
              },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { xl: "3em", xs: "2.5em" },
                marginTop: "1.5rem",
                marginBottom: "1rem",
                textAlign: "center",
                lineHeight: "115%",
              }}
            >
              Organization Login
            </Typography>
            <Typography
              variant="body2"
              style={{
                marginTop: 0,
                marginBottom: "1.5em",
                textAlign: "center",
              }}
            >
              Enter the verification code sent to your email to finish logging
              in. Don't see your code?{" "}
              <b
                className="link"
                style={{ margin: 0 }}
                onClick={async () =>
                  await Auth.signIn(document.getElementById("email").value)
                }
              >
                Resend
              </b>
            </Typography>
            <TextField
              id="password"
              type="code"
              label="code"
              name="code"
              variant="outlined"
              required
              InputLabelProps={{ style: { color: colors.primaryColor } }}
              InputProps={{
                style: {
                  color: "white",
                  backgroundColor: `${colors.primaryColor}20`,
                },
              }}
              sx={{ caretColor: "white", mb: "1em", fontSize: "14px" }}
              fullWidth
            />
            <Button
              variant="contained"
              fullWidth
              id={"login"}
              type="submit"
              startIcon={<LoginOutlined sx={{ color: "white" }} />}
              sx={{ mt: 1 }}
            >
              {loading ? "Loading..." : "Login"}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Login;
