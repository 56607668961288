import { Box, Button, Modal, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const ChangePhotoIDModal = ({
  idModalVisible,
  setIDModalVisible,
  setPhotoID,
  guestInvite,
  photoID,
  event,
  qrCode,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={idModalVisible}
      onClose={() => {
        setIDModalVisible(false);
        setPhotoID(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          maxWidth: "95dvw",
          width: { xs: "95dvw", lg: undefined, xl: "30dvw" },
          backgroundColor: colors.secondaryBackground,
          transform: "translate(-50%, -50%)",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            style={{
              fontSize: "16px",
              fontFamily: "Poppins",
              color: "white",
              margin: 0,
            }}
          >
            Name on file: {guestInvite.label}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: ".8em",
              textAlign: "center",
              mb: 2,
            }}
          >
            Please be aware that both your name and photo will be visible upon
            scan at the door.
          </Typography>
          {photoID ? (
            <Box sx={{ width: "100%" }}>
              <img
                src={photoID}
                style={{
                  width: "100%",
                  maxHeight: "60dvh",
                  objectFit: "contain",
                }}
                alt="ID"
              />
            </Box>
          ) : (
            <BeatLoader
              color={colors.primaryColor}
              speedMultiplier={0.5}
              size="2em"
            />
          )}
          <Button
            onClick={async () => {
              navigate(`/upload_id/${event.id}/${qrCode}/true`);
            }}
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              mt: 2,
              fontSize: "14px",
            }}
            size="small"
          >
            Change Photo ID
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangePhotoIDModal;
