import PublicHeader from "../../navigation/PublicHeader";
import Footer from "../../../components/Footer";
import SocialOverviewHeroSection from "./SocialOverviewHeroSection";
import SocialCapabilitiesSection from "./SocialCapabilitiesSection";
import SocialActionSection from "./SocialActionSection";
import SocialFAQSection from "./SocialFAQSection";

const SocialOverview = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        overflowX: "none",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
        }}
      >
        <PublicHeader />

        <SocialOverviewHeroSection />
        <SocialCapabilitiesSection />
        {/* <SocialTestimonialSection /> */}
        {/* <SocialStatisticsSection /> */}
        <SocialActionSection />
        <SocialFAQSection />
        <Footer paddingTop={"2em"} />
      </div>
    </div>
  );
};
export default SocialOverview;
