import { useAuthContext } from "../../contexts/AuthContext";
import ProfileHeader from "./ProfileHeader";
import "./Profile.css";
import PublicHeader from "../../public/navigation/PublicHeader";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect, useState } from "react";
import EventDisplay from "./EventDisplay";
import { DataStore, SortDirection } from "aws-amplify";
import { Event } from "../../models";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Alert, Box, Button, Grid, Skeleton, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import AllEventsDisplay from "./AllEventsDisplay";
import EventsGraphModule from "./EventsGraphModule";
import NewsDisplay from "../../components/NewsDisplay";
import { contactsNews } from "../../assets/contactsNews";
import HelpDialog from "../../pages/help/HelpDialog";
import SubscriptionConfirmationDialog from "../../pages/plans/SubscriptionConfirmationDialog";
import useWindowSize from "../../hooks/useWindowSize";

const Profile = () => {
  const { dbUser } = useAuthContext();
  const [events, setEvents] = useState(null);
  const [allEventsDialogOpen, setAllEventsDialogOpen] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState(null);
  const [markedDates, setMarkedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [
    subscriptionConfirmationDialogOpen,
    setSubscriptionConfirmationDialogOpen,
  ] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const { width } = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    if (urlParams.get("payment_intent")) {
      setSubscriptionConfirmationDialogOpen(true);
    }
  }, []);

  useEffect(() => {
    if (dbUser?.id) {
      markDates();
      const eventObserver = DataStore.observe(Event, (e) =>
        e.organizationID.eq(dbUser.id)
      ).subscribe((msg) => markDates());

      return () => eventObserver.unsubscribe();
    }
  }, [dbUser?.id]);

  useEffect(() => {
    if (events) {
      changeDay("");
    }
  }, [events]);

  useEffect(() => {
    if (!selectedDate && events) {
      markDates();
    }
  }, [selectedDate]);

  const markDates = async () => {
    const allOrgEvents = await DataStore.query(
      Event,
      (e) => e.organizationID.eq(dbUser.id),
      {
        sort: (e) => e.startDate(SortDirection.DESCENDING),
      }
    );
    const eventDates = allOrgEvents.map((event) => {
      return new Date(parseInt(event.startDate)).toLocaleDateString("en-us");
    });
    setMarkedDates(eventDates);
    setEvents(allOrgEvents);
    setReRender(!reRender);
  };

  const changeDay = (day) => {
    if (day) {
      setSelectedDate(new Date(day));
      setSelectedEvents(
        events.filter(
          (event) =>
            parseInt(event.startDate) >= Math.floor(day) &&
            parseInt(event.startDate) < Math.floor(day) + 86400000
        )
      );
    } else {
      setSelectedEvents(
        events.filter((event) => parseInt(event.startDate) > Date.now())
      );
    }
    setReRender(!reRender);
  };

  const pageTitle = `${
    dbUser?.name ? dbUser.name : "Account"
  } Dashboard • CLIQInvite`;
  const pageDescription = `Welcome to your CLIQInvite dashboard - the ultimate control panel for event management. Create, organize, and track your events in real-time. With advanced analytics and seamless account management, event planning has never been easier or more efficient.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/dashboard`;

  if (dbUser === undefined) {
    return (
      <div className="page-container profile">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={pageImageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <PublicHeader />
        <div
          style={{
            height: "100dvh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="profile-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 0,
            }}
          >
            <p style={{ marginTop: 0 }}>
              Oops, you cannot access your profile page until you complete your
              account setup.
            </p>
            <Button
              variant="contained"
              color="primary"
              sx={{ fontFamily: "Poppins", textTransform: "none" }}
              onClick={() => navigate("/signup")}
            >
              Complete Setup
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const loading = !dbUser || !events;

  return (
    <div className="profile">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Snackbar open={!!snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <SubscriptionConfirmationDialog
        isVisible={subscriptionConfirmationDialogOpen}
        close={() => setSubscriptionConfirmationDialogOpen(false)}
        setHelpDialogOpen={setHelpDialogOpen}
      />
      <WebPortalNavigation
        activeScreen="/dashboard"
        shouldNotCollapse={width > 800}
      />
      <HelpDialog
        preferredActiveScreen={"Request New Feature"}
        helpDialogOpen={helpDialogOpen}
        setHelpDialogOpen={setHelpDialogOpen}
      />
      <AllEventsDisplay
        events={events}
        open={allEventsDialogOpen}
        setOpen={setAllEventsDialogOpen}
      />
      <Box
        className={`page-size-medium ${
          width > 800 ? "should-not-collapse" : ""
        }`}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          pt: { xs: 2, lg: 4 },
        }}
      >
        <ProfileHeader setSnackbarOpen={setSnackbarOpen} />
        <Grid
          container
          sx={{ mt: 1.5, display: "flex", mb: "2em" }}
          spacing={2}
        >
          <Grid item xs={12} lg={5.5}>
            {loading ? (
              <Skeleton sx={{ fontSize: "1.2em" }} />
            ) : (
              <h1
                style={{
                  marginTop: 0,
                  fontSize: "1.2em",
                  marginBottom: ".3em",
                }}
              >
                Event Calendar
              </h1>
            )}
            {loading ? (
              <Skeleton sx={{ fontSize: "15em" }} variant="rounded" />
            ) : (
              <Calendar
                view="month"
                defaultValue={selectedDate}
                onChange={changeDay}
                tileClassName={({ date, view }) => {
                  if (
                    markedDates.find(
                      (x) => x === date.toLocaleDateString("en-us")
                    )
                  ) {
                    return "highlight";
                  }
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={6.5}
            sx={{
              display: "flex",
            }}
          >
            <EventDisplay
              allEvents={events}
              setSnackbarOpen={setSnackbarOpen}
              events={selectedEvents}
              selectedDate={selectedDate}
              loading={loading}
              setSelectedDate={setSelectedDate}
              setAllEventsDialogOpen={setAllEventsDialogOpen}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <EventsGraphModule
              loading={loading}
              events={events?.filter((e) => e.analytics)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <NewsDisplay
              title="What's new with CLIQInvite"
              description={
                "Check out our latest updates, feature releases, and other news"
              }
              news={contactsNews}
              loading={loading}
              setHelpDialogOpen={setHelpDialogOpen}
              newsDisplayTitle="CLIQInvite News"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Profile;
