import { useScrollTrigger } from "@mui/material";
import React from "react";

const ElevationScroll = (props) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  const transition = "all .8s ease"; // Adjust the transition duration and easing as desired

  return React.cloneElement(children, {
    elevation: trigger ? 10 : 0,
    style: {
      ...children.style,
      backgroundColor: !trigger ? "transparent" : "#000",
      marginTop: !trigger ? 0 : ".6rem",
      left: "auto",
      right: "auto",
      transition,
      alignSelf: "center",
      borderRadius: "8rem",
      padding: "0 !important",
    },
  });
};

export default ElevationScroll;
