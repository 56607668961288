/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://qm7wgrxx5rat3hihckbf2b5wnu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6eiei4i7ejeghhpj3ibkhylkne",
    "aws_cognito_identity_pool_id": "us-east-1:c1545bc2-5188-4d63-8608-78761ad4dec3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oiG7n3PNv",
    "aws_user_pools_web_client_id": "25cstmntd9ir6budro8ok6hnf3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "cliqprodimages183117-cliqprod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
