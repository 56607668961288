import { VisibilityOutlined } from "@mui/icons-material";
import { calculateTakeHome } from "../../methods/calculateTakeHome";
import { sum } from "../../methods/sum";
import { colors } from "../../theme/colors";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Typography,
  Badge,
} from "@mui/material";

const GuestListModule = ({
  guest,
  format,
  isPaid,
  isPostEvent,
  orgWithPhotoID,
  noUpload,
  isGuestOrg,
}) => {
  const formattedMoneyRaised = guest.amount
    ? `$${guest.amount.toFixed(2)}`
    : "$0";
  const displayedCount =
    orgWithPhotoID && !noUpload
      ? guest.invitesLength
      : guest.count && guest.count;
  let innerModuleFormat = [];
  innerModuleFormat = [{ text: "Guest name", size: 6 }];
  if (isPaid) {
    innerModuleFormat = [
      { text: "Guest name", size: 6 },
      { text: "# Purchased", size: 3 },
      { text: "Revenue", size: 3 },
    ];
  }
  if (orgWithPhotoID) {
    innerModuleFormat.push(
      ...[
        {
          text: "Uploaded",
          size: 3,
        },
        { text: "Status", size: 5 },
        { text: "Review", size: 2 },
      ]
    );
  }
  if (isPostEvent) {
    innerModuleFormat.push(
      { text: "# Valid", size: 3 },
      { text: "# Invalid", size: 3 }
    );
  }

  return (
    <Accordion
      sx={{
        padding: 0,
        marginTop: ".3em",
        borderRadius: 2,
        backgroundColor: colors.backgroundHighlight,
        ".Mui-disabled": {
          opacity: "1 !important",
          color: "inherit",
        },
      }}
      disabled={
        noUpload ||
        (!(isPaid && guest.amount) && !(orgWithPhotoID && !noUpload))
      }
      disableGutters
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          bgcolor: colors.backgroundHighlight,
          px: 1,
          borderRadius: 1,
          my: 0,
          mx: 0,
          mt: 0,
          ".MuiAccordionSummary-content": {
            my: 0,
          },
          ".Mui-expanded": {
            my: 0,
          },
          position: "relative",
        }}
      >
        {isGuestOrg ? (
          <Box
            sx={{
              height: ".5em",
              width: ".5em",
              borderRadius: ".5em",
              backgroundColor: guest.color,
              position: "absolute",
              right: ".25em",
              top: ".25em",
            }}
          ></Box>
        ) : null}
        <Grid container columns={sum(format, "size")}>
          <Grid
            item
            xs={format.find((f) => f.text === "Inviter").size}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: ".9em",
                textAlign: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {guest.member || guest.originMember || "You"}
            </Typography>
            {/* <h1 style={{ margin: 0, fontSize: "1.6em" }}>
                {displayedCount} {guest.name || guest.category}
              </h1>
              */}
          </Grid>
          <Grid
            item
            xs={format.find((f) => f.text === "Category").size}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: ".9em",
                textAlign: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {guest.category ||
                (guest.contactListID ? "Contact List" : "No Category")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={format.find((f) => f.text === "# Given").size}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: ".9em",
                textAlign: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {displayedCount}
            </Typography>
            {guest.isOrganizationMembers ? (
              <Typography
                variant="body2"
                sx={{ fontSize: ".6em", textAlign: "center" }}
              >
                RSVPs
              </Typography>
            ) : null}
          </Grid>
          {isPaid ? (
            <Grid
              item
              xs={format.find((f) => f.text === "Revenue").size}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: ".9em",
                  textAlign: "center",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {formattedMoneyRaised}
              </Typography>
            </Grid>
          ) : null}
          {orgWithPhotoID ? (
            <>
              <Grid
                item
                xs={format.find((f) => f.text === "Uploaded").size}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".9em",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {noUpload ? "N/A" : guest.count}
                </Typography>
              </Grid>
              <Grid
                item
                xs={format.find((f) => f.text === "Flagged").size}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".9em",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: guest.numFlagged
                      ? colors.sunsetOrange
                      : colors.primaryText,
                  }}
                >
                  {noUpload ? "N/A" : guest.numFlagged}
                </Typography>
              </Grid>
              <Grid
                item
                xs={format.find((f) => f.text === "Rejected").size}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".9em",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: guest.numRejected
                      ? colors.errorColor
                      : colors.primaryText,
                  }}
                >
                  {noUpload ? "N/A" : guest.numRejected}
                </Typography>
              </Grid>
            </>
          ) : null}
          {isPostEvent ? (
            <>
              <Grid
                item
                xs={format.find((f) => f.text === "# Valid").size}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  {guest.used}
                </Typography>
              </Grid>
              <Grid
                item
                xs={format.find((f) => f.text === "# Invalid").size}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  {guest.numInvalid}
                </Typography>
              </Grid>
            </>
          ) : null}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {guest?.invites?.length ? (
            <Grid
              container
              sx={{
                bgcolor: colors.secondaryBackground,
                px: 1,
                py: 0.5,
                mb: 0.6,
                borderRadius: 1,
              }}
              columns={sum(innerModuleFormat, "size")}
            >
              {innerModuleFormat.map((innerFormat) => (
                <Grid
                  item
                  xs={innerFormat.size}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  key={innerFormat.text}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: ".8em",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    {innerFormat.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : null}
          {guest?.invites?.length &&
            guest.invites.map((guest, index) => {
              const displayedCount =
                orgWithPhotoID && !noUpload ? 1 : guest.maxUses;
              return (
                <Grid
                  container
                  key={index.toString()}
                  sx={{
                    bgcolor: colors.secondaryBackground,
                    px: 1,
                    py: 0.5,
                    marginBottom: 0.3,
                    borderRadius: 1,
                  }}
                  columns={sum(innerModuleFormat, "size")}
                >
                  <Grid
                    item
                    xs={
                      innerModuleFormat.find(
                        (innerFormat) => innerFormat.text === "Guest name"
                      ).size
                    }
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: ".9em",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {guest.name || guest.label}
                    </Typography>
                  </Grid>
                  {isPaid ? (
                    <>
                      <Grid
                        item
                        xs={
                          innerModuleFormat.find(
                            (innerFormat) => innerFormat.text === "# Purchased"
                          ).size
                        }
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: ".9em",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {displayedCount}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={
                          innerModuleFormat.find(
                            (innerFormat) => innerFormat.text === "Revenue"
                          ).size
                        }
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: ".9em",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {calculateTakeHome(guest.cost)}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                  {orgWithPhotoID ? (
                    <>
                      <Grid
                        item
                        xs={
                          innerModuleFormat.find(
                            (innerFormat) => innerFormat.text === "Uploaded"
                          ).size
                        }
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: ".9em",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {guest.maxUses ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={
                          innerModuleFormat.find(
                            (innerFormat) => innerFormat.text === "Status"
                          ).size
                        }
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: ".9em",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            color:
                              guest.photoStatus === "PENDING"
                                ? colors.secondaryColor
                                : guest.photoStatus === "APPROVED"
                                ? colors.softGreen
                                : guest.photoStatus === "FLAGGED"
                                ? colors.sunsetOrange
                                : colors.errorColor,
                          }}
                        >
                          {guest.photoStatus}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={
                          innerModuleFormat.find(
                            (innerFormat) => innerFormat.text === "Review"
                          ).size
                        }
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <VisibilityOutlined
                          sx={{
                            fontSize: ".9em",
                            margin: 0,
                            padding: 0,
                            color: colors.primaryText,
                            cursor: "pointer",
                            transition: "opacity 300ms ease-in-out",
                            ":hover": {
                              opacity: 0.6,
                            },
                          }}
                          onClick={() =>
                            window.open(
                              `/view_id/${guest.id}`,
                              `_blank`,
                              `noreferrer`
                            )
                          }
                        />
                      </Grid>
                    </>
                  ) : null}
                  {isPostEvent ? (
                    <>
                      <Grid
                        item
                        xs={
                          innerModuleFormat.find(
                            (innerFormat) => innerFormat.text === "# Valid"
                          ).size
                        }
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: ".9em",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {guest.numUsed}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={
                          innerModuleFormat.find(
                            (innerFormat) => innerFormat.text === "# Invalid"
                          ).size
                        }
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: ".9em",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {guest.numInvalid}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                  {/* <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {guest.photoStatus === "FLAGGED" && (
                        <FlagOutlined
                          color="error"
                          sx={{ fontSize: "1.6em", mr: ".5em" }}
                        />
                      )}
                      <h1 style={{ margin: 0, fontSize: "1.6em" }}>
                        {displayedCount} {guest.name || guest.category}
                      </h1>
                      {orgWithPhotoID && !noUpload && (
                        <>
                          <h3
                            style={{
                              margin: 0,
                              color: "white",
                              opacity: 0.7,
                              marginLeft: ".2em",
                              fontWeight: 500,
                              fontSize: "1.1em",
                            }}
                          >
                            • {guest.maxUses ? "Uploaded" : "Pending upload"}
                          </h3>
                          <Button
                            style={{
                              margin: 0,
                              color: "white",
                              marginLeft: "1em",
                              textTransform: "none",
                              fontFamily: "Poppins",
                            }}
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                              window.open(
                                `/view_id/${guest.id}`,
                                `_blank`,
                                `noreferrer`
                              );
                            }}
                          >
                            View ID
                          </Button>
                        </>
                      )}
                    </Box>
                    <h3
                      style={{
                        color: "white",
                        margin: 0,
                        fontWeight: 600,
                        fontSize: "1em",
                      }}
                    >
                      {guest.label}
                    </h3>
                  </Box>

                  <Box
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box style={{ display: "flex", gap: "1em" }}>
                      {isPostEvent ? (
                        <>
                          {guest.numInvalid ? (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <h1
                                style={{
                                  margin: 0,
                                  color: colors.errorColor,
                                  fontSize: "1.8em",
                                }}
                              >
                                {guest.numInvalid}
                              </h1>
                              <p
                                style={{
                                  color: "white",
                                  margin: 0,
                                  fontSize: ".8em",
                                }}
                              >
                                invalid
                              </p>
                            </Box>
                          ) : null}
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h1
                              style={{
                                margin: 0,
                                color: "gold",
                                fontSize: "1.8em",
                              }}
                            >
                              {guest.used || 0}
                            </h1>
                            <p
                              style={{
                                color: "white",
                                margin: 0,
                                fontSize: ".8em",
                              }}
                            >
                              valid
                            </p>
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  </Box> */}
                </Grid>
              );
            })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default GuestListModule;
