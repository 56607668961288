import { Box, Typography } from "@mui/material";
import SubscriptionPlans from "./SubscriptionPlans";
import { colors } from "../../theme/colors";
import { useState } from "react";

const PlansDisplaySection = () => {
  const [planType, setPlanType] = useState("yearly");
  const [memberQuantity, setMemberQuantity] = useState(120);

  return (
    <Box
      sx={{
        bgcolor: colors.beige,
        pb: "5rem",
        pt: "2rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        className="default-page-size"
        display="flex"
        flexDirection={"column"}
      >
        <SubscriptionPlans
          planType={planType}
          memberQuantity={memberQuantity}
          setMemberQuantity={setMemberQuantity}
          setPlanType={setPlanType}
        />
      </Box>
    </Box>
  );
};

export default PlansDisplaySection;
