import {
  Add,
  Insights,
  GroupOutlined,
  EventOutlined,
  PeopleOutlined,
} from "@mui/icons-material";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProfileImage from "../../components/ProfileImage";
import { useAuthContext } from "../../contexts/AuthContext";
import { colors } from "../../theme/colors";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { OrganizationMember, Event } from "../../models";
import { sum } from "../../methods/sum";
import { addCommasToNumbers } from "../../methods/formatNumbers";

const ProfileHeader = ({ setSnackbarOpen }) => {
  const { dbUser, monthEvents, planLimits } = useAuthContext();
  const navigate = useNavigate();
  const [memberNumber, setMemberNumber] = useState(0);
  const [eventNumber, setEventNumber] = useState(0);
  const [scanNumber, setScanNumber] = useState(0);

  useEffect(() => {
    const getMemberNumbers = async () => {
      if (dbUser) {
        const members = await DataStore.query(OrganizationMember, (m) =>
          m.and((m) => [m.organizationID.eq(dbUser.id), m.isConfirmed.eq(true)])
        );
        setMemberNumber(members.length);
        const events = await DataStore.query(Event, (e) =>
          e.organizationID.eq(dbUser.id)
        );
        setEventNumber(events.length);
        let scanAggregator = 0;
        for (const event of events) {
          if (event.analytics) {
            scanAggregator += sum(event.analytics.categoryData, "validScans");
          }
        }
        setScanNumber(scanAggregator);
      }
    };

    getMemberNumbers();
  }, [dbUser?.id]);

  const loading = !dbUser;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={7.8} sx={{ mb: { xs: 1, lg: 0 } }}>
        <div
          className="unstyled-card"
          style={{
            padding: ".5em 1em .25em 1em",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "1em", marginBottom: ".8em" }}>
            {loading ? (
              <Skeleton
                variant="circular"
                sx={{ fontSize: "3em", width: "1.25em" }}
              />
            ) : (
              <ProfileImage
                height="3.5em"
                size="large"
                pic={dbUser.profilePic}
              />
            )}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Skeleton sx={{ fontSize: "1.2em" }} />
              ) : (
                <h1 style={{ margin: 0, fontSize: "1.2em" }}>{dbUser.name}</h1>
              )}
              {loading ? (
                <Skeleton sx={{ fontSize: ".8em" }} />
              ) : (
                <p
                  style={{
                    color: colors.secondaryText,
                    fontSize: ".8em",
                    margin: 0,
                    fontWeight: 500,
                  }}
                >
                  @ {dbUser?.school}
                </p>
              )}
            </div>
          </div>

          {loading ? (
            <Skeleton variant="rounded" sx={{ fontSize: "1.9em" }} />
          ) : (
            <Box sx={{ display: "flex", gap: "1em" }}>
              <Button
                variant="contained"
                sx={{ flex: 1, fontSize: { xs: "12px", lg: ".875rem" } }}
                startIcon={
                  <Add
                    sx={{
                      color: "white",
                      fontSize: { xs: "12px", lg: ".875rem" },
                    }}
                  />
                }
                color="primary"
                onClick={() => {
                  if (planLimits.maxEvents <= monthEvents) {
                    setSnackbarOpen({
                      type: "error",
                      message:
                        "You have exceeded your alloted monthly maximimum events for your current subscription plan.",
                      action: (
                        <Button
                          color="inherit"
                          size="small"
                          onClick={() =>
                            navigate("/dashboard/billing/upgrade_plan")
                          }
                        >
                          Upgrade Now
                        </Button>
                      ),
                    });
                  } else {
                    navigate("/create");
                  }
                }}
              >
                Create Event
              </Button>
              <Button
                variant="contained"
                sx={{
                  flex: { xs: 1, lg: 0.5 },
                  fontSize: { xs: "12px", lg: ".875rem" },
                  backgroundColor: colors.backgroundHighlight,
                  ":hover": {
                    backgroundColor: `${colors.backgroundHighlight}80`,
                  },
                }}
                startIcon={
                  <Insights
                    sx={{
                      color: "white",
                      fontSize: { xs: "12px", lg: ".875rem" },
                    }}
                  />
                }
                onClick={() => navigate("/insights")}
              >
                View Insights
              </Button>
            </Box>
          )}
        </div>
      </Grid>
      <Grid item xs={12} lg={4.2}>
        <div
          className="unstyled-card"
          style={{
            padding: ".5em 1em .25em 1em",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {loading ? (
            <Skeleton sx={{ fontSize: "1em" }} />
          ) : (
            <h1
              style={{
                margin: 0,
                fontSize: "1em",
                color: colors.secondaryText,
              }}
            >
              All-Time Statistics
            </h1>
          )}
          {loading ? (
            <Skeleton
              variant="rounded"
              sx={{ fontSize: "3.5em", mt: "auto" }}
            />
          ) : (
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
              <p
                style={{
                  color: colors.primaryText,
                  fontSize: ".8em",
                  margin: 0,
                  fontWeight: 500,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <GroupOutlined color="primary" />
                  <span style={{ marginLeft: "0.5em" }}>
                    {memberNumber} member{memberNumber === 1 ? "" : "s"}
                  </span>
                </div>
              </p>
              <p
                style={{
                  color: colors.primaryText,
                  fontSize: ".8em",
                  margin: 0,
                  fontWeight: 500,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EventOutlined color="primary" />
                  <span style={{ marginLeft: "0.5em" }}>
                    {eventNumber} event{eventNumber === 1 ? "" : "s"}
                  </span>
                </div>
              </p>
              <p
                style={{
                  color: colors.primaryText,
                  fontSize: ".8em",
                  margin: 0,
                  fontWeight: 500,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PeopleOutlined color="primary" />
                  <span style={{ marginLeft: "0.5em" }}>
                    {addCommasToNumbers(scanNumber)} guest
                    {scanNumber === 1 ? "" : "s"}
                  </span>
                </div>
              </p>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ProfileHeader;
