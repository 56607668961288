export const getModuleTime = (seconds) => {
  return new Date(parseInt(seconds)).toLocaleTimeString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const getModuleDate = (seconds) => {
  return new Date(parseInt(seconds)).toLocaleDateString("en-us", {
    month: "short",
    year: "numeric",
    day: "numeric",
  });
};

export const getTimeBetween = (date) => {
  const seconds = Math.floor((Date.now() - date) / 1000);
  let interval = seconds / 31536000;
  let description = "";
  if (interval > 1) {
    description = Math.floor(interval) == 1 ? "year ago" : "years ago";
    return Math.floor(interval) + " " + description;
  } else {
    interval = seconds / 2592000;
    if (interval > 1) {
      description = Math.floor(interval) == 1 ? "month ago" : "months ago";
      return Math.floor(interval) + " " + description;
    } else {
      interval = seconds / 86400;
      if (interval > 1) {
        description = Math.floor(interval) == 1 ? "day ago" : "days ago";
        return Math.floor(interval) + " " + description;
      } else {
        interval = seconds / 3600;
        if (interval > 1) {
          description = Math.floor(interval) == 1 ? "hour ago" : "hours ago";
          return Math.floor(interval) + " " + description;
        } else {
          interval = seconds / 60;
          if (interval > 1) {
            description =
              Math.floor(interval) == 1 ? "minute ago" : "minutes ago";
            return Math.floor(interval) + " " + description;
          } else {
            return "Seconds ago";
          }
        }
      }
    }
  }
};
