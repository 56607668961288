import { QRCodeSVG } from "qrcode.react";
import useWindowSize from "../hooks/useWindowSize";
import { getModuleDate, getModuleTime } from "../methods/getFormattedTime";
import { colors } from "../theme/colors";
import { Box, Button } from "@mui/material";
import { DownloadOutlined, VisibilityOutlined } from "@mui/icons-material";

const Ticket = ({
  event,
  guestInvite,
  qrCode,
  downloadTicket,
  handleChangeIDPress,
}) => {
  const size = useWindowSize();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: colors.defaultBackground,
        position: "relative",
        width: size.width > 800 ? "410px" : "265px",
      }}
    >
      <div
        style={{
          ...styles.qrContainer,
          position: "relative",
          marginTop: "3em",
          padding: `${size.width > 800 ? "15px" : "8px"} ${
            size.width > 800 ? "30px" : "16px"
          }`,
          width: size.width > 800 ? "350px" : "233px",
          borderRadius: 15,
        }}
        id="ticketRef"
      >
        <div style={{ marginBottom: size.width > 800 ? 8 : (8 * 2) / 3 }}>
          <h2
            style={{
              ...styles.qrTitle,
              fontSize: size.width > 800 ? "24px" : "16px",
            }}
          >
            {event?.name}
          </h2>

          <p
            style={{
              ...styles.numInvites,
              fontSize: size.width > 800 ? "18px" : "12px",
            }}
          >
            {guestInvite.maxUses} scan
            {guestInvite.maxUses > 1 ? "s" : ""}
          </p>
        </div>
        <QRCodeSVG size={size.width > 800 ? "300px" : "200px"} value={qrCode} />
        <p
          style={{
            ...styles.qrMessage,
            fontSize: size.width > 800 ? "16px" : `${(16 * 2) / 3}px`,
            marginTop: size.width > 800 ? 10 : (10 * 2) / 3,
          }}
        >
          {getModuleDate(event?.startDate).split(",")[0] +
            " " +
            getModuleTime(event?.startDate) +
            " " +
            event?.address}
        </p>
        <p style={styles.footer}>www.cliqinvite.com</p>
      </div>
      <Box
        sx={{
          mt: 2,
          mb: 5,
          display: "flex",
          width: "100%",
          gap: 0.5,
        }}
      >
        {downloadTicket ? (
          <Button
            variant="contained"
            size="small"
            onClick={downloadTicket}
            startIcon={<DownloadOutlined sx={{ color: colors.primaryText }} />}
            sx={{ flex: 1 }}
          >
            Download
          </Button>
        ) : null}
        {handleChangeIDPress ? (
          <Button
            onClick={handleChangeIDPress}
            variant="contained"
            size="small"
            sx={{ bgcolor: colors.backgroundHighlight , ":hover": {
              bgcolor: colors.backgroundHighlight
            }}}
            startIcon={
              <VisibilityOutlined sx={{ color: colors.primaryText }} />
            }
          >
            View Photo ID
          </Button>
        ) : null}
      </Box>
    </div>
  );
};

export default Ticket;

const styles = {
  qrContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 15,
    backgroundColor: colors.primaryText,
  },

  qrTitle: {
    alignSelf: "center",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
    margin: 0,
  },
  numInvites: {
    alignSelf: "center",
    textAlign: "center",
    fontStyle: "italic",
    color: "black",
    margin: 0,
  },
  qrMessage: {
    margin: 0,
    color: "black",
    alignSelf: "center",

    textAlign: "center",
  },
  footer: {
    margin: 0,
    alignSelf: "flex-end",
    fontSize: 8,
    color: "black",
  },
};
