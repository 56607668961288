import { useNavigate, useParams } from "react-router-dom";
import SubscriptionPaymentsModule from "./SubscriptionPaymentsModule";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet-async";
import { Elements } from "@stripe/react-stripe-js";
import { Alert, Button, Skeleton, Snackbar } from "@mui/material";
import { DataStore } from "aws-amplify";
import { SubscriptionPlan } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";

const stripePublishableKey =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_LIVE_KEY
    : process.env.REACT_APP_STRIPE_TEST_KEY;

const stripePromise = loadStripe(stripePublishableKey);

const SubscriptionPayments = () => {
  const { planType } = useParams();
  const { dbUser } = useAuthContext();
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (dbUser) {
      (async () => {
        const subscriptions = await DataStore.query(SubscriptionPlan, (s) =>
          s.organizationID.eq(dbUser.id)
        );
        if (subscriptions?.some((s) => s.isActive)) {
          setSnackbarOpen({
            type: "info",
            message: "An active subscription already exists for this account",
            action: (
              <Button
                size="small"
                color="inherit"
                onClick={() => navigate("/dashboard/billing")}
              >
                Manage Plan
              </Button>
            ),
          });
        }
      })();
    }
  }, [dbUser]);

  const pageTitle = `Purchase Subscription • CLIQInvite`;
  const pageDescription = `Purchase a subscription plan to gain access to premium CLIQInvite features, helping secure your organization's events and streamline your workflow.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/subscribe`;

  const startingPrice = (50 * 3.6 + 20) * 12 * 1.06 * 100;

  const loading = dbUser === undefined;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "subscription",
        currency: "usd",
        amount: startingPrice,
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>

      <SubscriptionPaymentsModule
        initialPlanType={planType}
        setSnackbarOpen={setSnackbarOpen}
        loading={loading}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
    </Elements>
  );
};

export default SubscriptionPayments;
