import {
  Alert,
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { API, DataStore } from "aws-amplify";
import { useAuthContext } from "../../contexts/AuthContext";
import { colors } from "../../theme/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./CreateEvent.css";
import React, { useEffect, useState } from "react";
import { Event, SubscriptionPlan } from "../../models";
import { Add, Delete, Info, SettingsOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import CreateEventInformationDialog from "./CreateEventInformationDialog";
import EventSettingsDialog from "./EventSettingsDialog";
import { BeatLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";
const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      address
      name
      description
      startDate
      endDate
      organizationInfo
      organizationID
      Invites {
        nextToken
        startedAt
      }
      GuestInvites {
        nextToken
        startedAt
      }
      Scans {
        nextToken
        startedAt
      }
      Jobs {
        nextToken
        startedAt
      }
      isGuestListPublic
      categories {
        category
        limits {
          role
          limit
        }
      }
      Announcements {
        nextToken
        startedAt
      }
      eventCode
      ticketInformation {
        price
      }
      maxTickets
      ticketsSold
      numGuests
      requirePhotoID
      jobsNotificationSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

const CreateEvent = () => {
  const { dbUser, planLimits, monthEvents } = useAuthContext();

  const [reRender, setReRender] = useState();
  const [categories, setCategories] = React.useState(["", ""]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [ticketInformation, setTicketInformation] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [requireID, setRequireID] = useState(false);
  const [dialogInformation, setDialogInformation] = useState(false);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    if (planLimits && monthEvents) {
      (async () => {
        if (monthEvents >= planLimits.maxEvents) {
          setSnackbarOpen({
            type: "error",
            message:
              "You have exceeded your alloted monthly maximimum events for your current subscription plan.",
            action: (
              <Button
                color="inherit"
                size="small"
                onClick={() => navigate("/dashboard/billing/upgrade_plan")}
              >
                Upgrade Now
              </Button>
            ),
          });
        }
      })();
    }
  }, [planLimits, monthEvents]);

  const createCategory = () => {
    if (categories.length < 5) setCategories((old) => [...old, ""]);
    else
      setSnackbarOpen({
        type: "error",
        message: "Maximum categories exceeded.",
      });
  };

  const generateRandomString = async () => {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    let uniqueCodeGenerated = false;
    let queryResult;

    while (!uniqueCodeGenerated) {
      result = "";
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      queryResult = await DataStore.query(Event, (e) =>
        e.and((e) => [
          e.eventCode.eq(result),
          e.endDate.ge(Math.floor(new Date()).toString()),
        ])
      );
      if (!queryResult.length) {
        uniqueCodeGenerated = true;
      }
    }
    return result;
  };

  const navigate = useNavigate();

  const deleteCategory = (index) => {
    setCategories((old) => {
      let tempCategories = old;
      tempCategories.splice(index, 1);

      return tempCategories;
    });
    setReRender((old) => !old);
  };
  const handleSubmit = async () => {
    if (monthEvents >= planLimits.maxEvents) {
      setSnackbarOpen({
        type: "error",
        message:
          "You have exceeded your alloted monthly maximimum events for your current subscription plan.",
        action: (
          <Button
            color="inherit"
            size="small"
            onClick={() => navigate("/dashboard/billing/upgrade_plan")}
          >
            Upgrade Now
          </Button>
        ),
      });
      return;
    }
    let duplicate = false;

    let nullName = false;
    let finalCategories = [];

    let limits = [];
    dbUser.createdRoles.forEach((role) => {
      limits.push({ role: role, limit: "-1" }); //handle limiting of roles, -1 = infinite
    });
    limits.push({ role: "No Role", limit: "-1" });

    categories.forEach((category) => {
      if (categories.indexOf(category) !== categories.lastIndexOf(category)) {
        duplicate = true;
      } else if (category === "") {
        nullName = true;
      } else finalCategories.push({ category, limits });
    });

    //finalCategories = [{category: "test", limits: [{role: "test", limit: "-1"}]}]

    if (nullName) {
      setSnackbarOpen({
        type: "error",
        message: "You cannot have an empty category name",
      });
      return;
    }
    if (duplicate) {
      setSnackbarOpen({
        type: "error",
        message: "Two or more categories have the same name",
      });
      return;
    }
    if (categories.indexOf("Paid") !== -1) {
      setSnackbarOpen({
        type: "error",
        message: "Category name cannot be reserved keyword 'Paid' for events.",
      });
      return;
    }

    if (!title || !description || !location || !startDate || !endDate) {
      setSnackbarOpen({
        type: "error",
        message: "Please fill out all fields to create an event",
      });
      return;
    } else if (
      Math.floor(new Date(startDate)) > Math.floor(new Date(endDate))
    ) {
      setSnackbarOpen({
        type: "error",
        message: "End date cannot be before start date",
      });
      return;
    } else if (Math.floor(new Date(startDate)) < Date.now()) {
      setSnackbarOpen({
        type: "error",
        message: "Event cannot be in the past",
      });
      return;
    }
    setCreating(true);
    const code = await generateRandomString();

    try {
      const eventID = window.crypto.randomUUID();

      await API.graphql({
        query: createEvent,
        variables: {
          input: {
            id: eventID,
            name: title,
            description: description,
            address: location,
            startDate: Math.floor(new Date(startDate)).toString(),
            endDate: Math.floor(new Date(endDate)).toString(),
            organizationInfo: [dbUser.name, dbUser.profilePic],
            organizationID: dbUser.id,
            isGuestListPublic: false,
            categories: finalCategories,
            ticketInformation: ticketInformation
              ? { price: ticketInformation.price }
              : null,
            maxTickets: ticketInformation ? ticketInformation.maxTickets : -1,
            eventCode: code,
            numGuests: 0,
            ticketsSold: 0,
            requirePhotoID: requireID,
            _version: 0,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      navigate("/dashboard");
    } catch (err) {
      console.log(err)
      setSnackbarOpen({
        type: "error",
        message: "Unexpected error saving event. Please try again.",
      });
    }
  };

  const loading = !dbUser || !planLimits || monthEvents === null;

  let shownBadgeContent = ``;
  if (ticketInformation) {
    shownBadgeContent += `$${ticketInformation.price}`;
    if (requireID) {
      shownBadgeContent += `; ID`;
    }
  } else if (requireID) {
    shownBadgeContent = `ID`;
  } else {
    shownBadgeContent = 0;
  }

  const pageTitle = `Create Event • CLIQInvite`;
  const pageDescription = `Easily create an event for your organization, whether you need to sell tickets, tightly control invite flow, or just ease your planning and management headache.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/create`;
  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta
          name="description"
          property="og:description"
          content={pageDescription}
        />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta
          name="keywords"
          content="Event Management, Fraternity Management"
        />
      </Helmet>
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <EventSettingsDialog
        dialogOpen={settingsDialogOpen}
        ticketInformation={ticketInformation}
        setTicketInformation={setTicketInformation}
        requireID={requireID}
        setRequireID={setRequireID}
        setDialogOpen={setSettingsDialogOpen}
      />
      <CreateEventInformationDialog
        dialogOpen={dialogOpen}
        dialogInformation={dialogInformation}
        setDialogOpen={setDialogOpen}
      />

      <WebPortalNavigation />
      <Box
        className="page-size-large"
        sx={{ alignSelf: "center", pt: 2, position: "relative" }}
      >
        <Box
          component="form"
          className="unstyled-card"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          sx={{ bgcolor: colors.secondaryBackground, px: 3, py: 2 }}
        >
          {!loading ? (
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography variant="h1" style={{ fontSize: "1.5em", flex: 1 }}>
                New Event
              </Typography>
              <Badge badgeContent={shownBadgeContent} color="primary">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.backgroundHighlight,
                    ":hover": {
                      backgroundColor: `${colors.backgroundHighlight}80`,
                    },
                    fontSize: { xs: "12px", lg: ".875rem" },
                  }}
                  startIcon={
                    <SettingsOutlined
                      sx={{
                        color: colors.primaryText,
                        fontSize: { xs: "12px", lg: ".875rem" },
                      }}
                    />
                  }
                  onClick={() => {
                    setSettingsDialogOpen(true);
                  }}
                >
                  Event Settings
                </Button>
              </Badge>
            </Box>
          ) : (
            <Skeleton variant="rounded" height="2em" sx={{ mb: 2 }} />
          )}
          {loading ? null : (
            <Typography variant="body2">Event Basics</Typography>
          )}
          {loading ? (
            <Skeleton variant="rounded" height="6em" sx={{ my: 1 }} />
          ) : (
            <>
              <TextField
                label="Title"
                name="name"
                fullWidth
                inputProps={{
                  maxLength: 40,
                }}
                onChange={(e) => setTitle(e.target.value)}
                size="small"
                sx={{
                  caretColor: colors.primaryColor,
                  mb: 1,
                  mt: 0.5,
                  bgcolor: colors.backgroundHighlight,
                  borderRadius: 1,
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 1,
                  }}
                >
                  <DateTimeField
                    name="startDate"
                    label="Start Date"
                    sx={{
                      mb: "1em",
                      flex: 1,
                      border: "none",
                      color: "white",
                      borderRadius: 1,
                      bgcolor: colors.backgroundHighlight,
                      caretColor: colors.primaryColor,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                    onChange={setStartDate}
                  />
                  <DateTimeField
                    name="endDate"
                    label="End Date"
                    sx={{
                      mb: "1em",
                      flex: 1,
                      border: "none",
                      color: "white",
                      borderRadius: 1,
                      bgcolor: colors.backgroundHighlight,
                      caretColor: colors.primaryColor,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                    onChange={setEndDate}
                  />
                </Box>
              </LocalizationProvider>
            </>
          )}
          {loading ? null : (
            <Typography variant="body2">Event Details</Typography>
          )}
          {loading ? (
            <Skeleton variant="rounded" height="12em" sx={{ my: 1 }} />
          ) : (
            <>
              <TextField
                label="Description"
                name="description"
                fullWidth
                multiline
                onChange={(e) => setDescription(e.target.value)}
                size="small"
                sx={{
                  caretColor: colors.primaryColor,
                  mb: 1,
                  mt: 0.5,
                  borderRadius: 1,
                  backgroundColor: `${colors.backgroundHighlight}`,
                }}
                inputProps={{
                  maxLength: 100,
                }}
              />
              <TextField
                label="Location"
                name="location"
                onChange={(e) => setLocation(e.target.value)}
                fullWidth
                size="small"
                sx={{
                  caretColor: colors.primaryColor,
                  mb: 2,
                  borderRadius: 1,

                  backgroundColor: `${colors.backgroundHighlight}`,
                }}
                inputProps={{
                  maxLength: 44,
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: ".5em",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
                    <Typography variant="body2">
                      External Invite Categories
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setDialogOpen(true);
                        setDialogInformation("CATEGORIES");
                      }}
                    >
                      <Info
                        size="small"
                        sx={{ color: colors.secondaryText, fontSize: ".7em" }}
                      />
                    </IconButton>
                  </Box>
                  <Button
                    onClick={() => createCategory()}
                    color="primary"
                    variant="contained"
                    size="small"
                    startIcon={<Add sx={{ color: colors.primaryText }} />}
                  >
                    Add Category
                  </Button>
                </Box>
              </div>
              {categories.map((category, index) => {
                return (
                  <TextField
                    key={index}
                    label={`Category ${index + 1}`}
                    name={`Category ${index + 1}`}
                    inputProps={{ maxLength: 12 }}
                    size="small"
                    onChange={(e) => {
                      setCategories((old) => {
                        let tempCategories = old;
                        tempCategories[index] = e.target.value;

                        return tempCategories;
                      });
                      setReRender((old) => !old);
                    }}
                    value={category}
                    fullWidth
                    InputProps={{
                      style: {
                        color: "white",
                        backgroundColor: colors.backgroundHighlight,
                        fontFamily: "Poppins",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => deleteCategory(index)}>
                            <Delete color="error" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ caretColor: colors.primaryColor, mb: 1 }}
                  ></TextField>
                );
              })}
            </>
          )}
          {loading ? (
            <Skeleton variant="rounded" sx={{ mt: 4 }} height="2em" />
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 4 }}
              type="submit"
              startIcon={
                creating ? (
                  <BeatLoader color="white" />
                ) : (
                  <Add sx={{ color: colors.primaryText }} />
                )
              }
            >
              {creating ? "" : "Create"}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateEvent;
