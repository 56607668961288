import { Button, Dialog, Grid, Paper, Typography, Box } from "@mui/material";
import { useRef } from "react";
import { colors } from "../../../theme/colors";
import {
  CheckCircleOutlineOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ImportExportOutlined,
} from "@mui/icons-material";

import { getModuleTime } from "../../../methods/getFormattedTime";
import { handleExportJobsTemplate } from "./handleExportJobs";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { Event, Job, OrganizationMember } from "../../../models";
import { useAuthContext } from "../../../contexts/AuthContext";
import { importAndInterpretJobsSpreadsheet } from "./jobMethods";
import JobScheduleGraph from "./JobScheduleGraph";
import axios from "axios";

const ImportJobsModal = ({
  open,
  onClose,
  event,
  setSnackbarOpen,
  hasJobs,
}) => {
  const [organizationMembers, setOrganizationMembers] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [jobsToBeConfirmed, setJobsToBeConfirmed] = useState(null);
  const { dbUser, authUser } = useAuthContext();
  const fileInputRef = useRef();

  useEffect(() => {
    (async () => {
      if (open) {
        const organizationMembers = await DataStore.query(
          OrganizationMember,
          (m) =>
            m.and((m) => [
              m.organizationID.eq(dbUser.id),
              m.isConfirmed.eq(true),
            ])
        );
        setOrganizationMembers(
          organizationMembers
            .map((orgMember) => {
              return {
                memberName: orgMember.memberInfo?.[0] || "",
                id: orgMember.userID,
              };
            })
            .sort((a, b) => a.memberName.length - b.memberName.length)
        );
      }
    })();
  }, [open]);
  if (!event || !organizationMembers) {
    return null;
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setProcessing(true);
    importAndInterpretJobsSpreadsheet(
      file,
      event.startDate,
      organizationMembers
    )
      .then((jobs) => {
        setJobsToBeConfirmed(jobs);
        setProcessing(false);
      })
      .catch((error) => {
        setSnackbarOpen({
          type: "error",
          message: `Error reading file: ${error}`,
        });
        setProcessing(false);
      });
  };

  const handleConfirmPress = async () => {
    setProcessing(true);
    let deletedJobs = [];

    try {
      //delete old jobs
      if (hasJobs) {
        const eventJobs = await DataStore.query(Job, (j) =>
          j.eventID.eq(event.id)
        );
        await Promise.all(
          eventJobs.map(async (job) => {
            DataStore.delete(Job, job.id);
            deletedJobs.push(job);
          })
        );
      }

      try {
        //confirm new jobs
        await Promise.all(
          jobsToBeConfirmed.map(async (job) => {
            DataStore.save(
              new Job({
                startDate: job.jobStart.toString(),
                endDate: job.jobEnd.toString(),
                jobName: job.jobName,
                memberName: job.name,
                userID: job.userID,
                organizationID: dbUser.id,
                eventID: event.id,
              })
            );
          })
        );
      } catch (err) {
        //rollback deleted jobs
        await Promise.all(
          deletedJobs.map(async (deletedJob) => {
            DataStore.save(new Job(deletedJob));
          })
        );
      }

      if (event.jobsNotificationSettings?.length) {
        const token = authUser?.signInUserSession.idToken.jwtToken;
        const url = process.env.REACT_APP_JOB_NOTIFICATIONS_ENDPOINT;

        const body = {
          notificationTimes: event.jobsNotificationSettings || [],
          eventID: event.id,
          eventStartDate: event.startDate,
          jobs: jobsToBeConfirmed.map((memberJob) => {
            return {
              eventID: event.id,
              jobName: memberJob.jobName,
              startDate: memberJob.jobStart.toString(),
              endDate: memberJob.jobEnd.toString(),
              memberName: memberJob.name,
              organizationID: dbUser.id,
              userID: memberJob.userID,
            };
          }),
        };
        await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      }
      onClose();
      setJobsToBeConfirmed(null);
      setProcessing(false);
    } catch (err) {
        console.error(err)
      setSnackbarOpen({
        type: "error",
        message: "Error confirming and replacing jobs. Please try again.",
      });
      setProcessing(false);
    }
  };

  const handleImport = () => {
    fileInputRef.current.click();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          px: 2,
          py: 2,
          bgcolor: colors.secondaryBackground,
          minWidth: "60dvw",
        },
      }}
    >
      {jobsToBeConfirmed ? (
        <Box>
          <Typography variant="h1" sx={{ fontWeight: 600, fontSize: "1.2em" }}>
            Review New Jobs
          </Typography>
          <Typography variant="body2">
            Integrate with previous workflows with all of CLIQInvite's Jobs
            benefits
          </Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="body2">Event Jobs</Typography>
            <JobScheduleGraph
              displayedMemberJobs={jobsToBeConfirmed}
              eventStartTime={event.startDate}
              surpressPopUp={true}
              isImport
            />
          </Box>
          <Button
            variant="contained"
            sx={{ mt: "auto", py: 1, borderRadius: 2, width: "100%" }}
            startIcon={
              <CheckCircleOutlineOutlined sx={{ color: colors.primaryText }} />
            }
            onClick={() => handleConfirmPress()}
          >
            <Typography variant="body1">
              {processing
                ? "Saving..."
                : hasJobs
                ? "Confirm and Replace"
                : "Confirm"}
            </Typography>
          </Button>
        </Box>
      ) : (
        <>
          <Typography variant="h1" sx={{ fontWeight: 600, fontSize: "1.2em" }}>
            Import Jobs
          </Typography>
          <Typography variant="body2" sx={{ fontSize: ".9em" }}>
            Integrate with previous workflows with all of CLIQInvite's Jobs
            benefits
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: 1.5 }}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <Grid item>
                <Typography variant="body2">Spreadsheet template</Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    handleExportJobsTemplate(organizationMembers, event);
                  }}
                  startIcon={
                    <CloudDownloadOutlined sx={{ color: colors.primaryText }} />
                  }
                  size="small"
                  variant="contained"
                >
                  Download
                </Button>
              </Grid>
            </Grid>
            {Array.from({ length: 2 }, (_, i) => (
              <Box key={i.toString()}>
                <Paper
                  elevation={0}
                  square
                  sx={{
                    padding: 1,
                    backgroundColor:
                      i === 0
                        ? colors.backgroundHighlight
                        : colors.secondaryBackground,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body2">Times</Typography>
                    </Grid>
                    {Array.from({ length: 3 }, (_, i) => (
                      <Grid item xs={3} key={i.toString()}>
                        <Typography variant={"body2"}>
                          {getModuleTime(
                            parseInt(event.startDate) + i * 30 * 60 * 1000
                          )}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
                <Paper
                  elevation={0}
                  square
                  sx={{
                    padding: 1,
                    backgroundColor:
                      i === 1
                        ? colors.backgroundHighlight
                        : colors.secondaryBackground,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body1">Job Name</Typography>
                    </Grid>
                    {Array.from({ length: 3 }, (_, i) => (
                      <Grid item xs={3} key={i.toString()}>
                        <Typography variant="body1">
                          {organizationMembers[i].memberName}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
                {i === 0 && (
                  <Box
                    sx={{
                      backgroundColor: colors.backgroundHighlight,
                      height: "28px",
                    }}
                  />
                )}
              </Box>
            ))}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".xlsx,.xls,.csv" // accept Excel and CSV files
            />

            <Button
              onClick={handleImport}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 4 }}
              startIcon={
                <CloudUploadOutlined sx={{ color: colors.primaryText }} />
              }
            >
              {processing ? "Importing..." : "Import"}
            </Button>
            <Typography variant="body2" sx={{ fontSize: ".75em", mt: 0.5 }}>
              *We accept .xlsx, .xls, and .csv files
            </Typography>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default ImportJobsModal;
