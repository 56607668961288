import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Grid,
} from "@mui/material";
import { colors } from "../../theme/colors";

const CustomizeContactPowerPointDialog = ({
  open,
  setOpen,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const basicOptions = [
    { name: "Name", optionTitle: "name" },
    { name: "Number", optionTitle: "number" },
    { name: "Photo", optionTitle: "photo" },
    { name: "Notes", optionTitle: "notes" },
    { name: "Avg Member Score", optionTitle: "avgScore" },
    { name: "# Invited", optionTitle: "numInvited" },
    { name: "# Attended", optionTitle: "numAttended" },
  ];

  const detailedOptions = [
    { name: "CLIQInvite Score", optionTitle: "cliqScore" },
    { name: "Event Attendance", optionTitle: "eventAttendance" },
    { name: "Member Scores", optionTitle: "memberScores" },
    { name: "Scans Given", optionTitle: "scansGiven" },
    { name: "Valid Scans", optionTitle: "validScans" },
    { name: "Invalid Scans", optionTitle: "invalidScans" },
  ];

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Customize Presentation</DialogTitle>
        <DialogContent>
          <h3
            style={{
              color: colors.secondaryText,
              margin: 0,
              fontWeight: 500,
              fontSize: "1em",
            }}
          >
            Basic Details
          </h3>
          <Grid container spacing={0}>
            {basicOptions.map((option, index) => (
              <Grid item xs={4} key={index}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        onChange={handleCheckboxChange}
                        checked={selectedOptions[option.optionTitle]}
                        name={option.optionTitle}
                      />
                    }
                    sx={{ color: colors.secondaryText, fontFamily: "Poppins" }}
                    label={option.name}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
          <h3
            style={{
              color: colors.secondaryText,
              margin: 0,
              fontWeight: 500,
              fontSize: "1em",
              marginTop: "1em",
            }}
          >
            In-Depth Analysis
          </h3>
          <Grid container spacing={0}>
            {detailedOptions.map((option, index) => (
              <Grid item xs={4} key={index}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        onChange={handleCheckboxChange}
                        checked={selectedOptions[option.optionTitle]}
                        name={option.optionTitle}
                      />
                    }
                    sx={{ color: colors.secondaryText, fontFamily: "Poppins" }}
                    label={option.name}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomizeContactPowerPointDialog;
