import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import {
  ArrowBackOutlined,
  Casino,
  DownloadOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  getModuleDate,
  getModuleTime,
} from "../../../methods/getFormattedTime";
import { useAuthContext } from "../../../contexts/AuthContext";
import { Job, OrganizationMember } from "../../../models";
import { DataStore } from "aws-amplify";
import axios from "axios";
import JobScheduleGraph from "./JobScheduleGraph";
import { BeatLoader } from "react-spinners";
import { handleExportJobs } from "./handleExportJobs";
import { deleteOldJobs, saveNewNotificationSchedules } from "./jobMethods";

const JobsOutput = ({
  jobs,
  phase,
  animationType,
  setAnimationType,
  setPhase,
  event,
  setSnackbarOpen,
}) => {
  const { dbUser, authUser } = useAuthContext();
  const eventID = event.id;
  const [groupsMemberQuantity, setGroupsMemberQuantity] = useState(null);
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [displayedMemberJobs, setDisplayedMemberJobs] = useState(null);
  const [saving, setSaving] = useState(false);
  const [jobsExist, setJobsExist] = useState(false);

  useEffect(() => {
    if (dbUser) {
      (async () => {
        const currentJobs = await DataStore.query(Job, (j) =>
          j.eventID.eq(eventID)
        );
        if (currentJobs.length) {
          setJobsExist(true);
        }

        const orgMembers = await DataStore.query(OrganizationMember, (o) =>
          o.and((o) => [o.organizationID.eq(dbUser.id), o.isConfirmed.eq(true)])
        );

        setOrganizationMembers(orgMembers);

        let groupsMemberBreakdown = [...dbUser.createdRoles, "No Group"].map(
          (group) => {
            let groupOrgMembers = orgMembers.filter(
              (orgMember) => orgMember.role === group
            );
            return { group: group, members: groupOrgMembers.length };
          }
        );

        setGroupsMemberQuantity(groupsMemberBreakdown);
      })();
    }
  }, [dbUser?.id]);

  const generateJobChart = () => {
    const newJobs = handleSplitJobs(jobs);
    let memberJobs = [];
    let jobErrors = [];

    newJobs.forEach((job) => {
      //convert minutes to ms
      const intervalIncrement = parseInt(job.interval);
      let jobIntervals = [];
      let timeTracker = 0 || job.jobStart;
      do {
        jobIntervals.push(timeTracker);
        timeTracker += intervalIncrement;
      } while (
        timeTracker <
        parseInt((job.endDate - job.startDate) / 60000) + job.jobStart
      );

      jobIntervals.forEach((jobInterval, index) => {
        const member = getRandomMemberWithWeights(job, jobInterval, memberJobs);

        if (member === "insufficient") {
          jobErrors.push(
            `${job.name} at ${getModuleTime(
              parseFloat(event.startDate) + jobInterval * 60000
            )}`
          );
        }
        memberJobs.push({
          userID: member === "insufficient" ? index.toString() : member,
          name: organizationMembers.find(
            (orgMember) => orgMember.userID === member
          )?.memberInfo?.[0],
          jobStart: jobInterval,
          jobEnd:
            jobIntervals[index + 1] ||
            parseInt((job.endDate - job.startDate) / 60000) + job.jobStart,
          jobName: job.name,
        });
      });
    });
    if (jobErrors.length) {
      setSnackbarOpen({
        type: "error",
        message: `Insufficient members to fill jobs.
      You did not have enough members to fill ${jobErrors.join(
        ", "
      )}. Please adjust your configuration and try again.`,
      });
    }
    setDisplayedMemberJobs(memberJobs);
  };
  useEffect(() => {
    if (organizationMembers && groupsMemberQuantity) {
      generateJobChart();
    }
  }, [organizationMembers, groupsMemberQuantity]);

  //split jobs with more than one person per shift into seperate jobs
  const handleSplitJobs = (jobs) => {
    let splitJobs = [];
    jobs.forEach((job) => {
      if (job.quantity === 1) {
        splitJobs.push(job);
      } else {
        for (let i = 0; i < job.quantity; i++) {
          splitJobs.push({ ...job, quantity: 1, name: `${job.name} ${i + 1}` });
        }
      }
    });

    return splitJobs;
  };

  const getAvailableMemberIDsAfterMaxJobsEliminated = (
    memberJobs,
    availableMemberIDs
  ) => {
    if (memberJobs.length === 0) {
      return availableMemberIDs;
    }

    let currentAvailableMembers = new Map();

    availableMemberIDs.forEach((memberUserID) => {
      let numJobsWorked = memberJobs.filter(
        (memberJob) => memberJob.userID === memberUserID
      ).length;
      currentAvailableMembers.set(memberUserID, numJobsWorked);
    });

    let jobsWorked = [...currentAvailableMembers.values()];

    let maxJobsWorked = Math.max(...jobsWorked);
    let minJobsWorked = Math.min(...jobsWorked);

    if (maxJobsWorked === minJobsWorked) {
      return availableMemberIDs;
    }

    let updatedAvailableMemberIDs = new Set();

    availableMemberIDs.forEach((memberUserID) => {
      if (currentAvailableMembers.get(memberUserID) !== maxJobsWorked) {
        updatedAvailableMemberIDs.add(memberUserID);
      }
    });

    return updatedAvailableMemberIDs;
  };

  const getAvailableGroupsAfterTimeConflictsEliminated = (
    job,
    jobInterval,
    memberJobs
  ) => {
    let availableMemberGroups = new Map();

    [...dbUser.createdRoles, "No Group"].forEach((group) => {
      let availableMemberIDs = new Set(
        organizationMembers
          .filter((member) => member.role === group)
          .map((member) => member.userID)
      );
      //only org members in group
      organizationMembers
        .filter((member) => member.role === group)
        .forEach((organizationMember) => {
          let organizationMemberMemberJobs = memberJobs.filter(
            (job) => job.userID === organizationMember.userID
          );
          const minRelevantTimeFrame = jobInterval;
          const maxRelevantTimeFrame = jobInterval + parseInt(job.interval);
          organizationMemberMemberJobs
            .filter(
              (memberJob) =>
                memberJob.userID === organizationMember.userID &&
                ((memberJob.jobStart >= minRelevantTimeFrame &&
                  memberJob.jobStart < maxRelevantTimeFrame) ||
                  (memberJob.jobEnd > minRelevantTimeFrame &&
                    memberJob.jobEnd <= maxRelevantTimeFrame) ||
                  (memberJob.jobStart <= minRelevantTimeFrame &&
                    memberJob.jobEnd >= maxRelevantTimeFrame))
            )
            .forEach((memberJob) => {
              availableMemberIDs.delete(memberJob.userID);
            });
        });
      if (availableMemberIDs.size) {
        availableMemberGroups.set(group, availableMemberIDs);
      }
    });

    return availableMemberGroups;
  };

  const selectRandomMember = (membersWithWeights) => {
    // calculate the total weight
    const totalWeight = membersWithWeights.reduce(
      (total, member) => total + member.weight,
      0
    );
    // get a random value between 0 and total weight
    let randomNum = Math.random() * totalWeight;
    // find the member where the cumulative weight is greater than the random number
    for (let i = 0; i < membersWithWeights.length; i++) {
      randomNum -= membersWithWeights[i].weight;
      if (randomNum < 0) {
        return membersWithWeights[i].userID;
      }
    }
  };

  const selectRandomGroup = (groupsWithWeights) => {
    const totalWeight = groupsWithWeights.reduce(
      (total, group) => total + group.weightProportion,
      0
    );

    let randomNum = Math.random() * totalWeight;

    for (let i = 0; i < groupsWithWeights.length; i++) {
      randomNum -= groupsWithWeights[i].weightProportion;
      if (randomNum < 0) {
        return groupsWithWeights[i].group;
      }
    }
  };

  const getRandomMemberWithWeights = (job, jobInterval, memberJobs) => {
    const availableGroups = getAvailableGroupsAfterTimeConflictsEliminated(
      job,
      jobInterval,
      memberJobs
    );
    const jobWeights = job.weightValues;

    const groupMemberProbabilities = jobWeights.map((jobWeight) => {
      const relevantWeightValue = jobWeight.weight;
      //get group
      const totalWeight = jobWeights.reduce((prev, curr) => {
        return (
          prev +
          parseFloat(curr.weight) *
            groupsMemberQuantity.find((group) => group.group === curr.group)
              .members
        ); //depends
      }, 0);
      return {
        group: jobWeight.group,
        weightProportion: availableGroups.get(jobWeight.group)
          ? relevantWeightValue / totalWeight
          : 0,
      };
    });

    const selectedGroup = selectRandomGroup(
      groupMemberProbabilities.filter((group) => group.weightProportion)
    );

    if (!selectedGroup) {
      return "insufficient";
    }
    const availableMemberIDs = getAvailableMemberIDsAfterMaxJobsEliminated(
      memberJobs,
      availableGroups.get(selectedGroup)
    );

    const orgMembersWithWeights = organizationMembers
      .filter((organizationMember) =>
        availableMemberIDs.has(organizationMember.userID)
      )
      .map((organizationMember) => {
        return {
          userID: organizationMember.userID,
          weight: groupMemberProbabilities.find(
            (groupMemberProbability) =>
              groupMemberProbability.group === organizationMember.role //due to a prveious naming convention in our schema
          ).weightProportion,
        };
      });

    const randomMember = selectRandomMember(orgMembersWithWeights);
    return randomMember;
  };

  const saveNewJobs = async () => {
    try {
      await Promise.all(
        displayedMemberJobs.map(async (memberJob) => {
          await DataStore.save(
            new Job({
              eventID,
              jobName: memberJob.jobName,
              startDate: memberJob.jobStart.toString(),
              endDate: memberJob.jobEnd.toString(),
              memberName: memberJob.name,
              organizationID: dbUser.id,
              userID: memberJob.userID,
            })
          );
        })
      );
      setSnackbarOpen({
        type: "success",
        message: "Successfully saved new jobs.",
      });
      setPhase("DONE");
    } catch (err) {
      setSaving(false);
      setSnackbarOpen({
        type: "error",
        message:
          "Error saving jobs. Please export your list to import or try again later.",
      });
    }
  };

  const handleNextPress = async () => {
    setAnimationType("FORWARD");

    if (!displayedMemberJobs) {
      return;
    }

    const currentJobs = await DataStore.query(Job, (job) =>
      job.eventID.eq(eventID)
    );
    if (currentJobs.length) {
      setSnackbarOpen({
        type: "error",
        message:
          "This action is irreversible and will delete all existing scheduled notifications for your old jobsheet",
        action: (
          <ButtonGroup>
            <Button
              size="small"
              color="inherit"
              onClick={() => {
                setSnackbarOpen(null);
              }}
            >
              Cancel
            </Button>
            <Button
              color="inherit"
              size="small"
              onClick={async () => {
                setSaving(true);
                try {
                  const token = authUser?.signInUserSession.idToken.jwtToken;

                  await deleteOldJobs(currentJobs);
                  await saveNewNotificationSchedules(
                    event,
                    token,
                    displayedMemberJobs,
                    dbUser.id
                  );
                  await saveNewJobs();
                } catch (err) {
                  setSnackbarOpen({
                    type: "error",
                    message:
                      "There was an error saving jobs. Please try again later.",
                  });
                  setSaving(false);
                }
              }}
            >
              Replace
            </Button>
          </ButtonGroup>
        ),
      });
    } else {
      await saveNewJobs();
    }
  };

  const handleBackPress = () => {
    setAnimationType("BACKWARD");
    setPhase("REVIEW");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 2,
        py: 2,
        borderRadius: 2,
        width: {
          sm: "60vw",
          xl: "45vw",
        },
        position: "absolute",
        alignSelf: "center",
        bgcolor: colors.secondaryBackground,
      }}
      className={
        phase === "OUTPUT"
          ? animationType === "FORWARD"
            ? "subscription-slide-in"
            : "subscription-slide-back-in"
          : phase !== "REVIEW"
          ? "subscription-slide-out"
          : animationType !== "FORWARD"
          ? "subscription-slide-back-out"
          : "subscription-off-screen"
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h1"
          sx={{ fontWeight: 500, flex: 1, fontSize: "1.3em" }}
        >
          Output
        </Typography>
        <Button
          variant={"contained"}
          sx={{
            bgcolor: colors.backgroundHighlight,
            ":hover": {
              bgcolor: `${colors.backgroundHighlight}D6`,
            },
          }}
          size="small"
          color="primary"
          endIcon={
            <Casino
              sx={{
                color: colors.primaryText,
              }}
            />
          }
          onClick={generateJobChart}
        >
          {"Re-Generate"}
        </Button>
      </Box>

      <Box
        className="scroll-container"
        sx={{ mt: 2, maxHeight: "70dvh", overflow: "auto" }}
      >
        {displayedMemberJobs ? (
          <JobScheduleGraph
            displayedMemberJobs={displayedMemberJobs}
            eventStartTime={event.startDate}
            containerStyle={{ mb: 1 }}
          />
        ) : (
          <BeatLoader color={colors.primaryColor} />
        )}
      </Box>
      <Box sx={{ display: "flex", mt: 3, width: "100%", gap: 1 }}>
        <Button
          sx={{
            bgcolor: colors.backgroundHighlight,
            ":hover": {
              bgcolor: `${colors.backgroundHighlight}D6`,
            },
          }}
          variant={"contained"}
          startIcon={<ArrowBackOutlined sx={{ color: colors.primaryText }} />}
          onClick={handleBackPress}
        >
          {"Go Back"}
        </Button>

        <Button
          sx={{
            bgcolor: colors.backgroundHighlight,
            ":hover": {
              bgcolor: `${colors.backgroundHighlight}D6`,
            },
          }}
          variant={"contained"}
          startIcon={<DownloadOutlined sx={{ color: colors.primaryText }} />}
          onClick={async () =>
            await handleExportJobs(displayedMemberJobs, event, dbUser.name)
          }
        >
          {"Download"}
        </Button>

        <Button
          variant={"contained"}
          sx={{ flex: 1 }}
          color="primary"
          endIcon={
            <SaveOutlined
              sx={{
                color: colors.primaryText,
              }}
            />
          }
          onClick={handleNextPress}
        >
          {"Save"}
        </Button>
      </Box>
    </Box>
  );
};

export default JobsOutput;
