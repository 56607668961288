import React from "react";

import emailjs from "@emailjs/browser";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { colors } from "../../theme/colors";

const ReportBug = ({ name, email, close }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    emailjs
      .send(
        "service_q5jnjf9",
        "template_hiqke3s",
        {
          name,
          email,
          subject: `${data.get("severity")} bug`,
          message: `Description: ${data.get(
            "description"
          )}\n\nSteps to reproduce: ${data.get("steps")}`,
        },
        "lkXy20z68OpeLFbkW"
      )
      .then(console.log);

    e.currentTarget.reset();
    if (close) {
      close();
    }
  };
  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextField
        inputProps={{ borderColor: "white" }}
        id="description"
        label="Bug Description"
        name="description"
        multiline
        variant="outlined"
        minRows={3}
        maxRows={5}
        required
        InputLabelProps={{ style: { color: colors.primaryColor } }}
        InputProps={{
          style: {
            color: "white",
            backgroundColor: `${colors.primaryColor}20`,
            fontFamily: "Poppins",
          },
        }}
        sx={{ caretColor: "white", mb: "1em" }}
        maxLength={1000}
        fullWidth
      />
      <TextField
        id="steps"
        label="Steps to Reproduce"
        name="steps"
        variant="outlined"
        required
        multiline
        minRows={3}
        maxRows={5}
        InputLabelProps={{ style: { color: colors.primaryColor } }}
        InputProps={{
          style: {
            color: "white",
            backgroundColor: `${colors.primaryColor}20`,
            fontFamily: "Poppins",
          },
        }}
        sx={{ caretColor: "white", mb: "1em" }}
        maxLength={1000}
        fullWidth
      />
      <TextField
        id="severity"
        label="Severity"
        name="severity"
        multiline
        variant="outlined"
        minRows={3}
        required
        InputLabelProps={{ style: { color: colors.primaryColor } }}
        InputProps={{
          style: {
            color: "white",
            backgroundColor: `${colors.primaryColor}20`,
          },
        }}
        sx={{ caretColor: "white", mb: "1em" }}
        fullWidth
        select
        SelectProps={{
          MenuProps: {
            PaperProps: { sx: { backgroundColor: colors.secondaryBackground } },
          },
          sx: {
            fontFamily: "Poppins",
          },
        }}
      >
        <MenuItem value={"minor"}>Minor inconvenience</MenuItem>
        <MenuItem value={"moderate"}>Moderate - affects usability</MenuItem>
        <MenuItem value={"major"}>Major - affects key functionality</MenuItem>
        <MenuItem value={"critical"}>
          Critical - makes the app unusable
        </MenuItem>
      </TextField>

      <Button
        type="submit"
        sx={{
          fontSize: "1em",
          width: "80%",
          mt: 2,
        }}
        size={"small"}
        variant="contained"
      >
        Report
      </Button>
    </Box>
  );
};

export default ReportBug;
