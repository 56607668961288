import { Box, Button, Grid, Skeleton } from "@mui/material";
import { colors } from "../theme/colors";
import { ArrowForwardOutlined, HowToVoteOutlined } from "@mui/icons-material";
import AllNewsDisplay from "./AllNewsDisplay";
import { useState } from "react";
import NewsDisplayModule from "./NewsDisplayModule";

const NewsDisplay = ({
  setHelpDialogOpen,
  loading,
  title,
  description,
  news,
  newsDisplayTitle,
}) => {
  const [allNewsDisplayOpen, setAllNewsDisplayOpen] = useState(false);
  return (
    <Box
      className="unstyled-card"
      sx={{ p: ".5em 1em", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        {loading ? (
          <Skeleton sx={{ fontSize: "1.2em" }} />
        ) : (
          <h1
            style={{
              margin: 0,
              fontSize: "1.2em",
            }}
          >
            {title}
          </h1>
        )}
        {loading ? (
          <Skeleton sx={{ fontSize: ".85em", mb: "1em" }} />
        ) : (
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: ".85em",
              marginBottom: "1em",
            }}
          >
            {description}
          </p>
        )}
      </div>
      <Grid container columnSpacing={1} sx={{ mb: 2 }}>
        {news
          .filter((news, index) => index < 3)
          .map((news, index) => (
            <Grid item xs={4}>
              {loading ? (
                <Skeleton
                  variant="rounded"
                  sx={{ fontSize: "8em" }}
                  key={index.toString()}
                />
              ) : (
                <NewsDisplayModule news={news} key={index.toString()} />
              )}
            </Grid>
          ))}
      </Grid>
      <Grid container sx={{ mb: 1 }} spacing={{ xs: 1, lg: 2 }}>
        <Grid item xs={12} lg={6} sx={{ display: "flex" }}>
          {loading ? (
            <Skeleton sx={{ flex: 1, fontSize: "2em" }} variant="rounded" />
          ) : (
            <Button
              variant="contained"
              sx={{
                flex: 1,
              }}
              color="primary"
              startIcon={<HowToVoteOutlined sx={{ color: "white" }} />}
              onClick={() => setHelpDialogOpen(true)}
            >
              Help Decide What's Next
            </Button>
          )}
        </Grid>
        <Grid item xs={12} lg={6} sx={{ display: "flex" }}>
          {loading ? (
            <Skeleton sx={{ flex: 1, fontSize: "2em" }} variant="rounded" />
          ) : (
            <>
              <Button
                variant="contained"
                sx={{
                  flex: 1,
                  backgroundColor: colors.backgroundHighlight,
                  ":hover": {
                    backgroundColor: `${colors.backgroundHighlight}80`,
                  },
                }}
                startIcon={<ArrowForwardOutlined sx={{ color: "white" }} />}
                onClick={() => setAllNewsDisplayOpen(true)}
              >
                See All
              </Button>
              <AllNewsDisplay
                title={newsDisplayTitle}
                open={allNewsDisplayOpen}
                setOpen={setAllNewsDisplayOpen}
                news={news}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsDisplay;
