import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { Event, Invite, Organization } from "../../models";
import PublicHeader from "../../public/navigation/PublicHeader";
import {
  Alert,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import "./SendInvites.css";
import { ClipLoader } from "react-spinners";
import InvitedOrgModule from "./InvitedOrgModule";
import { InputBase } from "@mui/material";
import { Search as SearchIcon, SearchOutlined } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import useDynamicDelay from "../../hooks/useDynamicDelay";
import ProfileImage from "../../components/ProfileImage";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import { Helmet } from "react-helmet-async";

const OrgInvites = () => {
  const { dbUser, createNotif, createQrCode } = useAuthContext();
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [invitedOrgs, setInvitedOrgs] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [event, setEvent] = useState();
  const navigate = useNavigate();

  let { eventID } = useParams();

  useEffect(() => {
    if (dbUser) {
      (async () => {
        const invites = await DataStore.query(Invite, (i) =>
          i.and((i) => [i.eventID.eq(eventID), i.isOrg.eq(true)])
        );
        let orgQueries = invites.map((invite) =>
          DataStore.query(Organization, (o) => o.id.eq(invite.userId)).then(
            (org) => {
              return {
                id: org[0].id,
                name: org[0].name,
                numInvites: invite.invitesGiven,
                lowerInviteLimit: invite.invitesGiven,
                profilePic: org[0].profilePic,
                school: org[0].school,
                shortSchool: org[0].shortSchool,
                message: invite.message,
                isAlreadyInvited: true,
                membersOnly: invite.membersOnly,
                changed: false,
              };
            }
          )
        );

        // Wait for all queries to complete
        Promise.all(orgQueries).then((orgs) => {
          // Update the state with the new list of organizations
          setInvitedOrgs(orgs);
        });

        await DataStore.query(Event, eventID).then((res) => {
          setEvent(res);
        });
      })();
    }
  }, [dbUser, eventID]);

  useEffect(() => {
    handleSearch("");
  }, []);

  useDynamicDelay(
    () => {
      handleSearch(searchQuery);
    },
    300,
    [searchQuery]
  );

  const handleSearch = (search) => {
    if (!search) setSearchResults([]);
    else {
      let searches = [",", ",", ",", ",", ","];
      const tempArray = search.split(" ");
      for (let i = 0; i < tempArray.length; i++) {
        searches[i] = tempArray[i];
      }

      DataStore.query(Organization, (s) =>
        s.and((s) => [
          s.searchName.contains(searches[0].toLowerCase()),
          s.searchName.contains(searches[1].toLowerCase()),
          s.searchName.contains(searches[2].toLowerCase()),
          s.searchName.contains(searches[3].toLowerCase()),
          s.searchName.contains(searches[4].toLowerCase()),
          s.id.ne(dbUser.id),
        ])
      ).then((orgs) => {
        setSearchResults(
          orgs
            .sort((a, b) => {
              let c = a.school === dbUser.school ? 1 : 0;
              let d = b.school === dbUser.school ? 1 : 0;
              return d - c;
            })
            .filter(
              (org) => !invitedOrgs.find((invOrg) => invOrg.id === org.id)
            )
            .filter((o, index) => index < 5)
        );
      });
    }
  };

  async function handleAlreadyInvited(org) {
    const invites = await DataStore.query(Invite, (i) =>
      i.and((i) => [i.eventID.eq(event.id), i.isOrg.eq(true)])
    );

    for (let invite of invites) {
      if (
        invite.userId == org.id &&
        (invite.invitesGiven != parseInt(org.numInvites) ||
          invite.membersOnly != org.membersOnly)
      ) {
        DataStore.save(
          Invite.copyOf(invite, (updated) => {
            updated.invitesGiven = parseInt(org.numInvites);
            updated.membersOnly = org.membersOnly;
          })
        );
      }
    }
  }

  async function handleNotInvited(org) {
    return await DataStore.save(
      new Invite({
        invitesGiven: parseInt(org.numInvites)
          ? parseInt(org.numInvites)
          : 1000,
        invitesUsed: 0,
        hasResponded: false,
        message: org.message,
        userId: org.id,
        eventID: event.id,
        organizationInfo: event.organizationInfo,
        eventInfo: [
          event.id,
          event.name,
          event.startDate,
          event.endDate,
          event.address,
          event.description,
        ],
        expired: false,
        qrCode: createQrCode(event.id),
        isOrg: true,
        eventImages: event.images,
        membersOnly: org.membersOnly,
      })
    );
  }

  async function onSharePress() {
    for (let org of invitedOrgs) {
      let inviteId = null;

      if (org.isAlreadyInvited) {
        await handleAlreadyInvited(org);
      } else {
        const result = await handleNotInvited(org);
        inviteId = result.id;
      }

      if (inviteId) {
        const result = await DataStore.query(Organization, org.id);
        createNotif(
          Organization,
          "inviteSent",
          result,
          dbUser.name,
          "",
          event.name,
          inviteId,
          dbUser.profilePic
        );
      } else {
        console.log("nothing changed");
      }
    }
    navigate("/dashboard");
  }

  const loading = !event;

  const pageTitle = `Invite Organizations • CLIQInvite`;
  const pageDescription = `Easily distribute invites to other organizations for paid or free events, creating a cohesive co-hosting environment that ensures everyone involved follows internal rules and stays up to date.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/event/invite_orgs`;

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <WebPortalNavigation activeScreen="/dashboard" />
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta
          name="description"
          property="og:description"
          content={pageDescription}
        />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Box className="page-size-large" sx={{ pt: 4, alignSelf: "center" }}>
        <Box className="unstyled-card" sx={{ px: 3, py: 2 }}>
          {loading ? (
            <Skeleton variant="rounded" height="4em" sx={{ mb: 2 }} />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h1" style={{ fontSize: "1.5em" }}>
                  Share Organization Invites
                </Typography>
                <Typography variant="body2">for {event.name}</Typography>
              </Box>
              {invitedOrgs.find((m) => m.changed) ? (
                <Button
                  onClick={() => onSharePress()}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    fontFamily: "Poppins",
                    alignSelf: "center",
                  }}
                >
                  Send Invites
                </Button>
              ) : (
                <Button
                  onClick={() => onSharePress()}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    fontFamily: "Poppins",
                    alignSelf: "center",
                  }}
                >
                  Done
                </Button>
              )}
            </Box>
          )}
          {!loading ? (
            <Box sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                size="small"
                sx={{
                  bgcolor: colors.backgroundHighlight,
                  borderRadius: 1,
                }}
                onBlur={() => setSearchQuery("")}
                fullWidth
                placeholder="Find Organizations"
                InputProps={{
                  startAdornment: (
                    <SearchOutlined
                      sx={{ color: "white", fontSize: "1.2em", mr: 1 }}
                    />
                  ),
                  sx: { color: "white" },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {!searchResults.length ? null : (
                  <List
                    sx={{
                      width: "100%",
                      backgroundColor: `#15041f`,
                      position: "absolute",
                      zIndex: 1,
                    }}
                  >
                    {searchResults.map((result, index) => (
                      <ListItem
                        key={index.toString()}
                        sx={{}}
                        dense
                        disableGutters
                      >
                        <ListItemButton
                          onClick={() => {
                            let tempSelectedUsers = invitedOrgs;
                            if (
                              tempSelectedUsers.find(
                                (invOrg) => invOrg.id === result.id
                              )
                            ) {
                              return;
                            }
                            tempSelectedUsers.push({
                              id: result.id,
                              name: result.name,
                              numInvites: 1000,
                              profilePic: result.profilePic,
                              school: result.school,
                              shortSchool: result.shortSchool,
                              message: "",
                              isAlreadyInvited: false,
                              membersOnly: true,
                              changed: true,
                            });
                            setInvitedOrgs(tempSelectedUsers);
                            setSearchQuery("");
                          }}
                          sx={{
                            ":hover": { backgroundColor: "#FFFFFF22" },
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            py: 1,
                          }}
                        >
                          <ProfileImage pic={result.profilePic} height="3em" />
                          <Box style={{ marginLeft: ".5em" }}>
                            <p
                              style={{
                                color: "white",
                                margin: 0,
                                fontWeight: 600,
                                fontSize: "1em",
                              }}
                            >
                              {result.name}
                            </p>
                            <p
                              style={{
                                color: "white",
                                margin: 0,
                                fontWeight: 500,
                                fontSize: ".8em",
                                opacity: 0.7,
                              }}
                            >
                              @ {result.shortSchool}
                            </p>
                          </Box>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
              <Box>
                {invitedOrgs.map((organization, index) => {
                  return (
                    <InvitedOrgModule
                      key={index.toString()}
                      organization={organization}
                      event={event}
                      setInvitedOrgs={setInvitedOrgs}
                      setSnackbarOpen={setSnackbarOpen}
                    />
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Skeleton variant="rounded" height="10em" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  title: {
    color: "white",
  },
  container: {
    display: "flex",
    alignItems: "flex-start",
  },
  labels: {},

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  button: {
    alignSelf: "flex-start",
    width: "50%",
    padding: 10,
    marginTop: "5%",
  },
};

export default OrgInvites;
