import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const TutorialFilters = ({ tags, selectedTags, handleTagChange }) => {
  return Object.entries(tags).map(([accordionTitle, accordionTags]) => (
    <Accordion sx={{ backgroundColor: "#090909" }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <p style={{ margin: 0 }}>{accordionTitle}</p>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
        {typeof accordionTags === "object" && !Array.isArray(accordionTags)
          ? Object.entries(accordionTags).map(
              ([subAccordionTitle, subAccordionTags]) => (
                <Accordion sx={{ backgroundColor: "#090909" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1b-content"
                    id="panel1b-header"
                    sx={{padding: 0}}
                  >
                    <p style={{ margin: 0 }}>{subAccordionTitle}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    {typeof subAccordionTags === "object" &&
                    !Array.isArray(subAccordionTags)
                      ? Object.entries(subAccordionTags).map(
                          ([title, nestedTags]) => (
                            <Accordion sx={{ backgroundColor: "#090909" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1b-content"
                                id="panel1b-header"
                                sx={{padding: 0}}
                              >
                                <p style={{ margin: 0 }}>{title}</p>
                              </AccordionSummary>
                              <AccordionDetails>
                                {nestedTags.map((tag) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={selectedTags.includes(tag)}
                                        onChange={() => handleTagChange(tag)}
                                      />
                                    }
                                    label={tag}
                                    sx={{
                                      color: "white",
                                      "& .MuiFormControlLabel-label": {
                                        fontFamily: "Poppins",
                                      },
                                    }}
                                  />
                                ))}
                              </AccordionDetails>
                            </Accordion>
                          )
                        )
                      : subAccordionTags.map((tag) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedTags.includes(tag)}
                                onChange={() => handleTagChange(tag)}
                              />
                            }
                            label={tag}
                            sx={{
                              color: "white",
                              "& .MuiFormControlLabel-label": {
                                fontFamily: "Poppins",
                              },
                            }}
                          />
                        ))}
                  </AccordionDetails>
                </Accordion>
              )
            )
          : accordionTags.map((tag) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedTags.includes(tag)}
                    onChange={() => handleTagChange(tag)}
                  />
                }
                label={tag}
                sx={{
                  color: "white",
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "Poppins",
                  },
                }}
              />
            ))}
      </AccordionDetails>
    </Accordion>
  ));
};

export default TutorialFilters;
