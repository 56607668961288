import { createContext, useContext, useEffect, useState, useRef } from "react";
import { API, Auth, DataStore, Hub, Storage } from "aws-amplify";
import {
  Event,
  Invite,
  Notification,
  Organization,
  SubscriptionPlan,
  User,
} from "../models";
import { subscriptionPlans } from "../assets/subscriptionPlans";
import {
  getOrganization,
  getUser,
  organizationMemberByUser,
} from "../graphql/queries";
import {
  onCreateOrganization,
  onCreateOrganizationMember,
  onCreateUser,
  onDeleteOrganization,
  onDeleteOrganizationMember,
  onDeleteUser,
  onUpdateOrganization,
  onUpdateOrganizationMember,
  onUpdateUser,
} from "../graphql/subscriptions";

const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [dbUser, setDbUser] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [monthEvents, setMonthEvents] = useState(null);
  const [planLimits, setPlanLimits] = useState(null);
  const [activeOrganization, setActiveOrganization] = useState(null);
  const [dbOrganizationMembers, setDbOrganizationMembers] = useState(null);

  let outboxListener = useRef();

  const checkUser = async () => {
    try {
      const tempAuthUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      setAuthUser(tempAuthUser || null);
    } catch (e) {
      setAuthUser(null);
    }
  };

  useEffect(() => {
    (async () => {
      if (dbUser) {
        const subscriptionPlan = await DataStore.query(SubscriptionPlan, (s) =>
          s.and((s) => [s.organizationID.eq(dbUser.id), s.isActive.eq(true)])
        );

        const events = await DataStore.query(Event, (e) =>
          e.organizationID.eq(dbUser.id)
        );

        if (!subscriptionPlan.length) {
          setPlanLimits(subscriptionPlans.find((s) => s.planType === "Free"));
          setMonthEvents(events.length);
          return;
        }
        const monthEvents = events.filter(
          (e) =>
            Math.floor(new Date(e.createdAt)) >
            Math.floor(new Date(subscriptionPlan[0].dateCycleStarted))
        );
        setMonthEvents(monthEvents.length);
        setPlanLimits(
          subscriptionPlans.find(
            (s) => s.planType === subscriptionPlan[0].planType
          )
        );
      }
    })();
  }, [dbUser?.id]);

  useEffect(() => {
    checkUser();
  }, []);

  const timeoutRef = useRef(null);
  useEffect(() => {
    if (dbUser) {
      clearTimeout(timeoutRef.current);
      getProfilePic();
    }
  }, [dbUser]);

  const getProfilePic = async () => {
    if (dbUser)
      Storage.get(`350x350/${dbUser.profilePic}`, { expires: 3600 }).then(
        (res) => {
          setProfilePic(res);
          timeoutRef.current = setTimeout(() => {
            getProfilePic();
          }, [3600000]);
        }
      );
  };

  const sub = authUser?.attributes?.sub;
  const name = authUser?.attributes?.name;
  const username = authUser?.attributes?.preferred_username;
  const type = authUser?.attributes["custom:type"];

  useEffect(() => {
    if (sub && !dbUser) {
      const fetchUser = async () => {
        const userResponse = await API.graphql({
          query: getUser,
          variables: {
            id: sub,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const user = userResponse.data.getUser;

        setDbUser((old) => {
          if (user && old) {
            return { ...old, ...user };
          } else if (user) {
            return user;
          } else return null;
        });
      };

      const fetchOrganizationMembers = async () => {
        const orgMemberResponse = await API.graphql({
          query: organizationMemberByUser,
          variables: {
            userID: sub,
            filter: {
              _deleted: { ne: true },
            },
          },

          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        let organizationMembers =
          orgMemberResponse.data.organizationMemberByUser.items;

        setDbOrganizationMembers(organizationMembers);
      };

      fetchUser();
      fetchOrganizationMembers();

      //user subscriptions
      const userCreateSubscription = API.graphql({
        query: onCreateUser,
        variables: {
          id: sub,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({ next: (eventData) => fetchUser() });

      const userUpdateSubscription = API.graphql({
        query: onUpdateUser,
        variables: {
          id: sub,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: (eventData) => {
          fetchUser();
        },
      });
      const userDeleteSubscription = API.graphql({
        query: onDeleteUser,
        variables: {
          id: sub,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({ next: (eventData) => fetchUser() });

      //organization member subscriptions
      const organizationMemberCreateSubscription = API.graphql({
        query: onCreateOrganizationMember,
        variables: {
          userID: sub,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: (eventData) => {
          fetchOrganizationMembers();
        },
      });
      const organizationMemberUpdateSubscription = API.graphql({
        query: onUpdateOrganizationMember,
        variables: {
          userID: sub,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: (eventData) => {
          fetchOrganizationMembers();
        },
      });
      const organizationMemberDeleteSubscription = API.graphql({
        query: onDeleteOrganizationMember,
        variables: {
          userID: sub,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({ next: (eventData) => fetchOrganizationMembers() });

      return () => {
        userCreateSubscription.unsubscribe();
        userUpdateSubscription.unsubscribe();
        userDeleteSubscription.unsubscribe();
        organizationMemberCreateSubscription.unsubscribe();
        organizationMemberUpdateSubscription.unsubscribe();
        organizationMemberDeleteSubscription.unsubscribe();
      };
    } else if (authUser === null) {
      //authUser is null when user signs out
      setDbUser(null);
    }
  }, [authUser]);

  useEffect(() => {
    if (activeOrganization) {
      const fetchOrganization = async () => {
        const organizationResponse = await API.graphql({
          query: getOrganization,
          variables: {
            id: activeOrganization.id,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const tempDbOrganization = organizationResponse.data.getOrganization;

        setActiveOrganization({ ...tempDbOrganization });
      };

      fetchOrganization();
      //organization subscriptions
      const organizationCreateSubscription = API.graphql({
        query: onCreateOrganization,
        variables: {
          id: activeOrganization.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({ next: (eventData) => fetchOrganization() });

      const organizationUpdateSubscription = API.graphql({
        query: onUpdateOrganization,
        variables: {
          id: activeOrganization.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({ next: (eventData) => fetchOrganization() });

      const organizationDeleteSubscription = API.graphql({
        query: onDeleteOrganization,
        variables: {
          id: activeOrganization.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({ next: (eventData) => fetchOrganization() });

      return () => {
        organizationCreateSubscription.unsubscribe();
        organizationUpdateSubscription.unsubscribe();
        organizationDeleteSubscription.unsubscribe();
      };
    }
  }, [activeOrganization?.id]);
  useEffect(() => {
    const listener = async (data) => {
      if (data?.payload.event === "signIn") {
        await checkUser();
      } else if (data?.payload.event === "signOut") {
        await checkUser();
        setDbUser(null);
      }
    };

    Hub.listen("auth", listener);

    return () => {
      listener();
    };
  }, []);

  const createQrCode = (eventId) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
    let code = eventId;
    let index;
    for (let i = 0; i < 36; i++) {
      index = Math.floor(Math.random() * characters.length);
      code += characters.charAt(index);
    }
    return code;
  };

  const sendRoleInvites = async (
    eventInfo,
    orgInfo,
    MemberModules,
    invites,
    invitesRemaining,
    originatingOrgID,
    origin,
    setSnackbarOpen
  ) => {
    const overridenMemberNames = [];
    for (let i = 0; i < MemberModules.length; i++) {
      const matchingInvite = invites.find(
        (invite) => invite.userId === MemberModules[i].id
      );

      if (matchingInvite && MemberModules[i].num !== "INVITED") {
        let tempInvitesGiven = parseInt(MemberModules[i].num);
        let shouldPass = false;
        if (matchingInvite.invitesUsed > parseInt(MemberModules[i].num)) {
          tempInvitesGiven = matchingInvite.invitesUsed;
          overridenMemberNames.push({
            name: MemberModules[i].name,
            type: "ALREADYGIVEN",
          });
        } else if (
          matchingInvite.invitesGiven === 0 &&
          parseInt(MemberModules[i].num) === 0
        ) {
          shouldPass = true;
          overridenMemberNames.push({
            name: MemberModules[i].name,
            type: "UNINVITE",
          });
        }

        let newInvitesGiven;
        if (dbUser.id === originatingOrgID) {
          newInvitesGiven = tempInvitesGiven;
        } else {
          newInvitesGiven =
            matchingInvite.invitesGiven === 1000 ? 1000 : tempInvitesGiven;
        }
        if (!shouldPass) {
          const updatedInvite = await DataStore.save(
            Invite.copyOf(matchingInvite, (updated) => {
              updated.invitesGiven = newInvitesGiven;
              updated.categories = MemberModules[i].categories;
            })
          );

          const user = await DataStore.query(User, (u) =>
            u.id.eq(updatedInvite.userId)
          );

          createNotif(
            User,
            "inviteUpdated",
            user[0],
            dbUser.name,
            "",
            updatedInvite.eventInfo[1],
            updatedInvite.id,
            dbUser.profilePic
          );
        }

        MemberModules[i] = {
          ...MemberModules[i],
          num: 0,
        };
      } else if (MemberModules[i].num !== "INVITED") {
        MemberModules[i] = {
          ...MemberModules[i],
          num: 0,
        };
      }

      const numInvites = MemberModules[i].num;

      if (
        !matchingInvite &&
        (parseInt(numInvites) !== 0 || numInvites === "INVITED")
      ) {
        const guestOrgID = dbUser.id !== originatingOrgID ? dbUser.id : null;
        const invitesGiven =
          numInvites === "INVITED" ? 0 : parseInt(numInvites);

        const invite = new Invite({
          invitesGiven,
          invitesUsed: 0,
          hasResponded: false,
          userId: MemberModules[i].id,
          organizationInfo: orgInfo,
          eventInfo,
          organizationID: originatingOrgID,
          eventID: eventInfo[0],
          responseTime: null,
          isOrg: false,
          qrCode: createQrCode(eventInfo[0]),
          categories: MemberModules[i].categories,
          guestOrgID: guestOrgID,
        });

        await DataStore.save(invite).then(async (result) => {
          await DataStore.query(User, (u) => u.id.eq(result.userId)).then(
            (user) => {
              createNotif(
                User,
                "inviteSent",
                user[0],
                dbUser.name,
                "",
                result.eventInfo[1],
                result.id,
                dbUser.profilePic
              );
            }
          );
        });
      }
    }
    if (origin !== "guestMember" && dbUser.id !== originatingOrgID) {
      //if it's a guest org and invites used is a positive number
      await DataStore.query(Invite, (i) =>
        i.and((i) => [i.userId.eq(dbUser.id), i.eventID.eq(eventInfo[0])])
      ).then(async (orgInvite) => {
        await DataStore.save(
          Invite.copyOf(orgInvite[0], (updated) => {
            updated.invitesUsed = orgInvite[0].invitesGiven - invitesRemaining;
          })
        );
      });
    }
    if (overridenMemberNames?.length) {
      let multipleTypes = new Set([]);
      overridenMemberNames.forEach((error) => multipleTypes.add(error.type));
      let displayMessage = `${overridenMemberNames.length} of your invite updates were modified due to constraints.`;
      if (multipleTypes.has("UNINVITE")) {
        let uninviteMemberNames = overridenMemberNames
          .filter((m) => m.type === "UNINVITE")
          .map((error) => error.name);
        let displayedNames =
          uninviteMemberNames.length > 3
            ? `${uninviteMemberNames.slice(0, 3).join(", ")} and ${
                uninviteMemberNames.length - 3
              } more`
            : overridenMemberNames.join(", ");
        displayMessage += ` Invite updates for ${displayedNames} failed to apply as these members have already been invited and cannot be uninvited.`;
        if (multipleTypes.has("ALREADYGIVEN")) {
          displayMessage += `\n\n`;
        }
      } else if (multipleTypes.has("ALREADYGIVEN")) {
        let invalidMemberNames = overridenMemberNames
          .filter((m) => m.type === "ALREADYGIVEN")
          .map((error) => error.name);
        let displayedNames =
          invalidMemberNames.length > 3
            ? `${invalidMemberNames.slice(0, 3).join(", ")} and ${
                invalidMemberNames.length - 3
              } more`
            : invalidMemberNames.join(", ");
        displayMessage += `Invite updates for ${displayedNames} failed to apply as these members have already given out more invites than you attempted to reduce their limit to. We reduced their limit to the amount of invites they have already given to ensure they cannot send any more out.`;
      }
      setSnackbarOpen({ type: "error", message: displayMessage });
    }
  };

  const createNotif = async (
    recipientType,
    notifType,
    recipient,
    senderName,
    message,
    eventName,
    id,
    image
  ) => {
    let body = "";

    switch (notifType) {
      case "inviteSent": {
        body = "Sent you an invite to " + eventName;
        break;
      }
      case "friendAdded": {
        body = "Added you as a friend";

        break;
      }
      case "message": {
        body = message;

        break;
      }
      case "inviteUpdated": {
        body = "Updated your invite to " + eventName;

        break;
      }
      case "inviteAccepted": {
        body = "Accepted your invite to " + eventName;

        break;
      }
      case "inviteDeclined": {
        body = "Declined your invite to " + eventName;

        break;
      }
      case "memberRequested": {
        body = "Requested to join your organization";

        break;
      }
      case "memberAccepted": {
        body = "Accepted your member application";

        break;
      }
      case "memberAdded": {
        body = "Added you to their organization";

        break;
      }
      case "announcement": {
        body = eventName + ": " + message;
        break;
      }
      case "sharedList": {
        body = "Shared " + message + " with you";
        break;
      }
      case "flaggedInvite": {
        body = "Flagged your invite to " + message + " for " + eventName;
        break;
      }
      default:
        break;
    }

    DataStore.save(
      new Notification({
        type: notifType,
        senderName: senderName,
        createdAt: Math.floor(new Date()).toString(),
        objectID: id,
        message: body ? body : message,
        eventName: eventName,
        userID: recipient.id,
        organizationID: recipient.id,
        image: image,
        senderID: dbUser.id,
        senderType: dbUser.createdRoles ? "Organization" : "User",
        read: false,
      })
    ).catch((err) => console.log("Error uploading to DataStore", err));

    // Construct a message (see https://docs.expo.io/push-notifications/sending-notifications/)
  };

  useEffect(() => {
    if (dbUser) {
      outboxListener.current = Hub.listen("datastore", async (hubData) => {
        const { event, data } = hubData?.payload;
        if (event === "outboxMutationProcessed" && !data.element.deleted) {
          if (
            data.model === Notification &&
            !data.element.read &&
            !data.element.deleted &&
            data.element.senderID === dbUser.id //TODO fix this hard workaround
          ) {
            DataStore.query(User, data.element.userID).then(
              async (recipient) => {
                if (recipient) {
                  if (recipient.pushTokens) {
                    let finalMessage;
                    for (let i = 0; i < recipient.pushTokens.length; i++) {
                      finalMessage = {
                        to: recipient.pushTokens[i],
                        sound: "default",
                        title: data.element.senderName,
                        body: data.element.message,
                        data: {
                          type: data.element.type,
                          id: data.element.objectID,
                          senderType: dbUser.createdRoles
                            ? "Organization"
                            : "User",
                          senderID: dbUser.id,
                        },
                      };

                      await fetch("https://exp.host/--/api/v2/push/send", {
                        method: "POST",
                        mode: "no-cors",
                        headers: {
                          Accept: "application/json",
                          "Accept-encoding": "gzip, deflate",
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(finalMessage),
                      });
                    }
                  }
                } else {
                  DataStore.query(Organization, data.element.userID).then(
                    async (recipient) => {
                      if (recipient.pushTokens) {
                        let finalMessage;
                        for (let i = 0; i < recipient.pushTokens.length; i++) {
                          finalMessage = {
                            to: recipient.pushTokens[i],
                            sound: "default",
                            title: dbUser.name,
                            body: data.element.message,
                            data: {
                              type: data.element.type,
                              id: data.element.objectID,
                              senderType: dbUser.createdRoles
                                ? "Organization"
                                : "User",
                              senderID: dbUser.id,
                            },
                          };

                          await fetch("https://exp.host/--/api/v2/push/send", {
                            method: "POST",
                            headers: {
                              Accept: "application/json",
                              "Accept-encoding": "gzip, deflate",
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify(finalMessage),
                          });
                        }
                      }
                    }
                  );
                }
              }
            );
          }
        }
      });

      return () => {
        outboxListener.current();
      };
    }
  }, [dbUser?.id]); //now this creation is only conditional on dbUser.id changing, not dbUser changing

  return (
    <AuthContext.Provider
      value={{
        authUser,
        dbUser,
        sub,
        setDbUser,
        name,
        username,
        type,
        profilePic,
        sendRoleInvites,
        createNotif,
        createQrCode,
        planLimits,
        monthEvents,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

export const useAuthContext = () => useContext(AuthContext);
