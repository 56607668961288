import React, { useEffect, useRef, useState } from "react";
import PublicHeader from "../navigation/PublicHeader";
import {
  TextField,
  Grid,
  Chip,
  IconButton,
  List,
  Popover,
  Button,
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import TutorialModule from "./TutorialModule";
import TutorialFilters from "./TutorialFilters";
import Footer from "../../components/Footer";
import { Navigation } from "react-calendar";
import { Helmet } from "react-helmet-async";

const tutorialVideos = [
  {
    id: "PoxyB_0Obss",
    title: "Organization Owner Tutorial | Account Creation",
    description: "Learn how to setup your organization account",
    length: "1 minute, 33 seconds",
    tags: ["Organization Owner", "General"],
  },
  {
    id: "gOlE7s_caYs",
    title: "Organization Owner Tutorial | Member Management",
    description:
      "Learn how to accept, deny, or remove members and create groups for your organization account",
    length: "1 minute, 11 seconds",
    tags: ["Organization Owner", "General"],
  },
  {
    id: "dbUWVd9rWuk",
    title: "Organization Owner Tutorial | Free Event Creation",
    description:
      "Learn how to create an event and assign event categories for your organization",
    length: "1 minute, 20 seconds",
    tags: ["Organization Owner", "Social", "Event Management"],
  },
  {
    id: "J517wenHZXU",
    title: "Organization Owner Tutorial | Invite Distribution",
    description:
      "Learn how to distribute guest invites to your members and create invite limits for your organization's event",
    length: "1 minute, 16 seconds",
    tags: ["Organization Owner", "Social", "Invite Management"],
  },
  {
    id: "okSJlWvAxeE",
    title: "Organization Owner Tutorial | Event Management",
    description:
      "Learn how to manage your organization's free events, including editing and updating details, viewing the guest list, and creating announcement",
    length: "1 minute, 43 seconds",
    tags: ["Organization Owner", "Social", "Event Management"],
  },
  {
    id: "oZMrmdocsfM",
    title: "Organization Owner Tutorial | Ticket Scanning",
    description:
      "Learn how to scan tickets at the door of your free events, including group scanning, alerts, overrides, and private security/non-member scanning",
    length: "1 minute, 32 seconds",
    tags: ["Organization Owner", "Social", "Invite Management"],
  },
  {
    id: "tVH6bDzyri4",
    title: "Organization Owner Tutorial | Analytics",
    description:
      "Learn how to view and manage your analytics, including filtering by time frame, interpreting your graphs, analyzing member performance, and viewing event summaries",
    length: "1 minute, 19 seconds",
    tags: ["Organization Owner", "Social", "Analytics"],
  },
  {
    id: "okSJlWvAxeE",
    title: "Organization Owner Tutorial | Co-hosting Setup",
    description:
      "Learn how to setup co-hosting on your events, including inviting organization members, giving them guest invites, and setting category limits on their guest invites for your organization's event",
    length: "1 minute, 25 seconds",
    tags: ["Organization Owner", "Social", "Co-Host Events"],
  },
  {
    id: "nhnIC8zc6Kc",
    title: "Organization Owner Tutorial | Co-Hosting Lead Management",
    description:
      "Learn how to manage your co-hosted events as the lead organization, including interpreting the guest list, accepting invite requests, and modifying organization invites",
    length: "1 minute, 26 seconds",
    tags: ["Organization Owner", "Social", "Co-Host Events"],
  },
  {
    id: "kRT31DSyRyc",
    title: "Organization Owner Tutorial | Co-Hosting Guest Management",
    description:
      "Learn how to manage your co-hosted events as the guest organization, including interpreting your permissions, accepting your invite, requesting more invites, and accessing your guest list",
    length: "1 minute, 31 seconds",
    tags: ["Organization Owner", "Social", "Co-Host Events"],
  },
  {
    id: "vd-Qv8rwsyM",
    title: "Organization Owner Tutorial | ID-Verified Events",
    description:
      "Learn how to create and manage an ID-verified event, including creating the event, viewing uploaded IDs, flagging IDs for review, and scanning at the door",
    length: "1 minute, 27 seconds",
    tags: ["Organization Owner", "Social", "ID-Verified Events"],
  },
  {
    id: "nxqoSCgaoNs",
    title: "Organization Owner Tutorial | Paid Events",
    description:
      "Learn how to create and manage a paid (or philanthropy) event, including creating the event, distributing free invites, sending payment links, requesting payouts, and viewing payment metrics",
    length: "1 minute, 33 seconds",
    tags: ["Organization Owner", "Philanthropy"],
  },
  {
    id: "mNqqjg32RVs",
    title: "Organization Owner Tutorial | Contact Lists",
    description:
      "Learn how to create and manage a contact list, including creating the list, adding contacts, importing lists, managing and sharing lists, viewing list contacts, deleting list contacts, adding scores and notes, and viewing contact metrics",
    length: "2 minutes, 43 seconds",
    tags: ["Organization Owner", "Rush"],
  },
  {
    id: "Wu7W1AWVC4k",
    title: "Organization Member Tutorial | Invite Distribution",
    description:
      "Learn how to distribute invites, including accepting your invite, setting up ticket information, and sending invites externally",
    length: "1 minute, 53 seconds",
    tags: ["Organization Member", "Social", "Invite Management"],
  },
  {
    id: "4XChHpyNVAY",
    title: "Organization Member Tutorial | Event Management",
    description:
      "Learn how to manage your invites and event, including viewing your invite history, deleting invites, expanding your personal QR code, viewing announcements, and interpreting analytics",
    length: "1 minute, 53 seconds",
    tags: ["Organization Member", "Social", "Event Management"],
  },
  {
    id: "QlEcKg66qRE",
    title: "Organization Member Tutorial | Ticket Scanning",
    description:
      "Learn how to scan tickets at the door of your organization's free events, including group scanning, alerts, overrides, and private security/non-member scanning",
    length: "1 minute, 12 seconds",
    tags: ["Organization Member", "Social", "Invite Management"],
  },
  {
    id: "xgzhrVhb7tA",
    title: "Organization Member Tutorial | ID-Verified Events",
    description:
      "Learn how to manage invites for your ID-verified events, including sending invite links, viewing uploaded IDs, rejecting or approving IDs, and scanning at the door",
    length: "1 minute, 41 seconds",
    tags: ["Organization Member", "Social", "ID-Verified Events"],
  },

  {
    id: "LSDU4mj6hzY",
    title: "Organization Member Tutorial | Paid Events",
    description:
      "Learn how to manage invites for your paid (or philanthropy) event, including distributing payment portal links, sharing free invites, and viewing your invite history",
    length: "1 minute, 5 seconds",
    tags: ["Organization Member", "Philanthropy"],
  },
  {
    id: "rNJsFLYTQbU",
    title: "Organization Member Tutorial | Inviting Contact Lists",
    description:
      "Learn how to send invites to your contact lists, including choosing a list, selecting contacts from the list, and creating custom messages",
    length: "1 minute, 27 seconds",
    tags: ["Organization Member", "Rush"],
  },
];

const tags = {
  "Account Type": ["Organization Owner", "Organization Member"],
  Category: ["Social", "Philanthropy", "Rush", "General"],
  Function: [
    "Event Management",
    "Invite Management",
    "Co-Host Events",
    "ID-Verified Events",
    "Analytics",
  ],
};

const Tutorials = () => {
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  let filterButtonRef = useRef();

  useEffect(() => {
    setFilteredVideos(
      tutorialVideos.filter((video) =>
        selectedTags.length === 0
          ? true
          : video.tags.some((tag) => selectedTags.includes(tag))
      )
    );
  }, [selectedTags]);

  const handleTagChange = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const pageTitle = `Tutorials • CLIQInvite`;
  const pageDescription = `"Master event management with CLIQInvite tutorials. Learn to streamline social events, philanthropy, and recruitment. Discover secure invites, real-time tracking, and detailed analytics. Elevate your event skills today.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/tutorials`;

  return (
    <div
      className="page-container"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <PublicHeader />
      <Grid container className="profile-container">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 style={{ fontSize: "3em", margin: 0, marginBottom: "-.3em" }}>
            Tutorial Videos
          </h1>
          <p style={{ opacity: 0.6, marginTop: 0 }}>
            Click the buttons below to watch the full tutorial flow or search
            the videos to find exactly what you're looking for
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "1em",
            marginBottom: "1em",
          }}
        >
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontFamily: "Poppins" }}
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=PoxyB_0Obss&list=PLCoNOX4-Q2eWCZLF666Z6vgFZ_dMtAGIA&index=1",
                "_blank",
                "noreferrer"
              )
            }
          >
            Organization Owner
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontFamily: "Poppins" }}
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=Wu7W1AWVC4k&list=PLCoNOX4-Q2eWNYvNRgjVmSRcx_PP-6DFs",
                "_blank",
                "noreferrer"
              )
            }
          >
            Organization Member
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ mb: "1em" }}>
          <div style={{ display: "flex" }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search tutorials..."
              onChange={(event) => {
                const searchTerm = event.target.value;
                setFilteredVideos(
                  tutorialVideos.filter((video) => {
                    return (video.title + video.description)
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase());
                  })
                );
                /* Implement search logic */
              }}
            />
            <IconButton
              color="primary"
              aria-label="filter list"
              onClick={() => setShowFilters((old) => !old)}
              ref={filterButtonRef}
            >
              <FilterList />
            </IconButton>
            <Popover
              open={showFilters}
              anchorEl={filterButtonRef.current}
              onClose={() => setShowFilters(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "#090909",
                  overflow: "auto",
                },
              }}
            >
              <List
                className="scroll-container"
                sx={{
                  backgroundColor: "#090909",
                  maxHeight: "50vh",
                  overflow: "auto",
                }}
              >
                <TutorialFilters
                  tags={tags}
                  selectedTags={selectedTags}
                  handleTagChange={handleTagChange}
                />
              </List>
            </Popover>
          </div>
          {selectedTags.map((tag) => (
            <Chip
              label={tag}
              onDelete={() => {
                setSelectedTags((tags) => tags.filter((t) => t !== tag));
              }}
              sx={{
                marginRight: "0.5em",
                marginBottom: "0.5em",
                fontFamily: "Poppins",
                color: "white",
              }}
              color="primary"
            />
          ))}
        </Grid>
        <Grid item xs={12} sx={{ pb: "3em" }}>
          {filteredVideos.map((video) => (
            <TutorialModule video={video} />
          ))}
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Tutorials;
