import { DataStore } from "aws-amplify";
import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { cleanPhoneNumber } from "../../methods/cleanPhoneNumber";
import { Contact } from "../../models";
import { colors } from "../../theme/colors";

const ManuallyAddContacts = ({ open, close, list, contacts, loading }) => {
  const handleCreate = () => {
    const name = document.getElementById("name").value;
    const number = document.getElementById("number").value;
    const notes = document.getElementById("notes").value;

    if (!name || !number) {
      alert("Oops, contacts must have a valid name and number");
    } else if (name.length > 60) {
      alert("Oops, Names must be less than 40 characters");
    } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(cleanPhoneNumber(number))) {
      alert("Oops, Please enter a valid phone number");
    } else if (
      Array.from(contacts.values()).find(
        (c) => c.number === cleanPhoneNumber(number)
      )
    ) {
      alert("Oops", "Your contact list already has an instance of this number");
    } else {
      DataStore.save(
        new Contact({
          name: name,
          number: cleanPhoneNumber(number),
          notes: notes || "",
          contactListID: list.id,
          avgScore: 0,
          accessingUsers: list.accessingUsers,
          coOwners: list.coOwners,
          creatorID: list.creatorID,
        })
      );
      close();
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={close} onClick={(e) => e.stopPropagation()}>
      <DialogTitle>{`New Contact for ${list.name}`}</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Name"
          fullWidth
          variant="standard"
          sx={{ mt: 3 }}
          InputProps={{
            style: { color: colors.primaryText, fontFamily: "Poppins" },
          }}
          InputLabelProps={{
            style: { color: colors.secondaryText, fontFamily: "Poppins" },
          }}
          inputProps={{ maxLength: 20 }}
        />

        <TextField
          autoFocus
          margin="dense"
          id="number"
          name="number"
          inputMode="tel"
          label="Number"
          fullWidth
          variant="standard"
          sx={{ mt: 3 }}
          InputProps={{
            style: { color: colors.primaryText, fontFamily: "Poppins" },
          }}
          InputLabelProps={{
            style: { color: colors.secondaryText, fontFamily: "Poppins" },
          }}
          inputProps={{ maxLength: 20 }}
        />

        <TextField
          autoFocus
          margin="dense"
          id="notes"
          name="notes"
          label="Notes"
          fullWidth
          variant="standard"
          sx={{ mt: 3 }}
          InputProps={{
            style: { color: colors.primaryText, fontFamily: "Poppins" },
          }}
          InputLabelProps={{
            style: { color: colors.secondaryText, fontFamily: "Poppins" },
          }}
          inputProps={{ maxLength: 600 }}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} sx={{ color: colors.secondaryText }}>
          Cancel
        </Button>
        <Button onClick={handleCreate}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManuallyAddContacts;
