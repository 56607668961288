import { DataStore, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { Event, Invite, OrganizationMember } from "../../models";
import PublicHeader from "../../public/navigation/PublicHeader";
import { colors } from "../../theme/colors";
import { GiMagnifyingGlass } from "react-icons/gi";
import InviteRoleModule from "./InviteRoleModule";
import InviteMemberModule from "./InviteMemberModule";
import { SearchOutlined, Send } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import "./SendInvites.css";
import { ClipLoader, BeatLoader } from "react-spinners";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import useDynamicDelay from "../../hooks/useDynamicDelay";
import { Helmet } from "react-helmet-async";
import { fetchOrgMemberInvites } from "./fetchOrgMemberInvites";
import { fetchOrgMembers } from "./fetchOrgMembers";
import { distributeMemberGuestInvites } from "./distributeMemberGuestInvites";

function RoleModule(role, num) {
  this.role = role;
  this.num = num;
  this.type = "RoleModule";
}

function MemberModule(name, id, num, role, profilePic, changed) {
  this.name = name;
  this.id = id;
  this.num = num;
  this.role = role;
  this.type = "MemberModule";
  this.profilePic = profilePic;
  this.changed = changed;
}

const SendInvites = () => {
  const navigate = useNavigate();
  const { dbUser, sendRoleInvites } = useAuthContext();
  const [sent, setSent] = useState(true);
  const [modules, setModules] = useState(null);
  const [reRender, setReRender] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [event, setEvent] = useState();
  const [searchText, setSearchText] = useState("");

  let { eventID } = useParams();

  useEffect(() => {
    (async () => {
      await DataStore.query(Event, eventID).then(setEvent);
    })();
    const listener = Hub.listen("datastore", async (hubData) => {
      const { event, data } = hubData?.payload;

      if (event === "outboxMutationEnqueued" && data.model === Invite) {
        setSent(false);
      }
      if (event === "outboxStatus" && data.isEmpty === true) {
        setSent(true);
      }
    });
    return () => listener();
  }, []);

  useEffect(() => {
    if (!dbUser || !event) {
      return;
    }
    (async () => {
      // Concurrently fetch orgInvites and orgMembersInvited
      const orgMemberInvites = await fetchOrgMemberInvites(event.id);
      const orgMembers = await fetchOrgMembers(dbUser.id);
      const invitedOrgMembers = orgMembers.filter(
        (member) =>
          orgMemberInvites.find(
            (memberInvite) => memberInvite.userId === member.userID
          ) || {
            userID: member.userID,
          }
      );
      // Define initial values for tempArray and tempCategories
      const tempArray = invitedOrgMembers.length
        ? ["By Group"]
        : ["Invite members to distribute guest invites"];

      if (invitedOrgMembers.length) {
        // Add RoleModules for each created role in the organization
        dbUser.createdRoles.forEach((role) => {
          tempArray.push(new RoleModule(role, "0"));
        });
        tempArray.push(new RoleModule("No Group", "0"), "By Member");
      }

      // Iterate over invitedOrgMembers, adding a MemberModule for each
      invitedOrgMembers.forEach((orgMember, i) => {
        const matchingInviteIndex = orgMemberInvites.findIndex(
          (invite) => invite.userId === orgMember.userID
        );

        if (orgMember.memberInfo) {
          tempArray.push(
            new MemberModule(
              orgMember.memberInfo[0],
              orgMember.userID,
              "0",
              orgMember.role,
              orgMember.memberInfo[1],
              false
            )
          );
        } else {
          tempArray.push(
            new MemberModule(
              "Unknown or Deleted Member",
              orgMember.userID,
              "0",
              "Deleted",
              "default-profile.jpg",
              false
            )
          );
        }

        if (matchingInviteIndex !== -1) {
          const addDbUserRoles = dbUser.createdRoles.length + 2;
          tempArray[i + addDbUserRoles + 1].num =
            orgMemberInvites[matchingInviteIndex].invitesGiven.toString();
        }
      });

      // Update state
      setModules(tempArray);
    })();
  }, [dbUser, event]);

  useDynamicDelay(
    () => {
      if (modules) {
        setModules((old) => {
          let tempModules = old;
          let newModules = sortModules(tempModules);
          return newModules;
        });
      }
    },
    300,
    [searchText]
  );

  function sortModules(tempModules) {
    let memberModules = tempModules.filter((m) => m.type === "MemberModule");
    let otherModules = tempModules.filter((m) => m.type !== "MemberModule");
    memberModules.sort((a, b) => {
      const aIndex = a.name.toLowerCase().indexOf(searchText.toLowerCase());
      const bIndex = b.name.toLowerCase().indexOf(searchText.toLowerCase());

      if (aIndex === -1) return 1; // If a does not contain searchText, put it to the end
      if (bIndex === -1) return -1; // If b does not contain searchText, put it to the start

      // If both contains searchText, sort according to position of searchText
      return aIndex - bIndex;
    });
    return [...otherModules, ...memberModules];
  }

  const updateModules = (Module) => {
    let tempArray = modules;
    let tempNumUsed = 0;
    //updating individual member modules, find the module by name and set to passed module
    if (Module.type === "MemberModule") {
      for (let i = 0; i < modules.length; i++) {
        if (modules[i].name === Module.name) {
          //change the total invite limit on the module
          tempArray[i].num = Module.num;
          tempArray[i].changed = true;
          tempArray[i].categories = Module.categories;
        }
        tempNumUsed = parseInt(modules[i].num)
          ? parseInt(modules[i].num) + tempNumUsed
          : 0 + tempNumUsed;
      }
    } else {
      //updating all role modules
      for (let i = 0; i < modules.length; i++) {
        if (
          modules[i].type === "MemberModule" &&
          modules[i].role === Module.role //for each MemberModule that reflects the role that you just updated
        ) {
          tempArray[i].num = Module.num;
          tempArray[i].changed = true;
          tempArray[i].categories = Module.categories;
        } else if (
          modules[i].type === "RoleModule" &&
          modules[i].role === Module.role
        ) {
          tempArray[i].num = Module.num;
          tempArray[i].changed = true;
          tempArray[i].categories = Module.categories;
        }
        if (modules[i].type === "MemberModule") {
          tempNumUsed = parseInt(modules[i].num)
            ? parseInt(modules[i].num) + tempNumUsed
            : 0 + tempNumUsed;
        }
      }
    }

    let newModules = sortModules(tempArray);
    setModules(newModules);
    setReRender((old) => old + 1);
  };

  const onSharePress = async () => {
    setSent(false);
    let tempArray = [];
    for (let i = 0; i < modules.length; i++) {
      if (modules[i].type === "MemberModule" && modules[i].changed === true) {
        tempArray.push(modules[i]);
      }
    }
    const invites = await fetchOrgMemberInvites(event.id);

    await distributeMemberGuestInvites({
      MemberModules: tempArray,
      invites,
      setSnackbarOpen,
      navigate,
      setSent,
    });
  };

  const loading = !event || !modules;

  const pageTitle = `Distribute Guest Invites • CLIQInvite`;
  const pageDescription = `Distribute guest invites to your invited members, groups of members, or individual members to your organization's event with just a few clicks.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/event/invite_members`;

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <WebPortalNavigation activeScreen="/dashboard" />
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta
          name="description"
          property="og:description"
          content={pageDescription}
        />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Box className="page-size-large" sx={{ py: 4, alignSelf: "center" }}>
        <Box className="unstyled-card" sx={{ px: 3, py: 2 }}>
          {loading ? (
            <Skeleton variant="rounded" height="4em" sx={{ mb: 2 }} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h1" style={{ fontSize: "1.5em" }}>
                  Distribute Guest Invites
                </Typography>
                <Typography variant="body2">for {event.name}</Typography>
              </Box>

              {sent ? (
                <Button
                  onClick={() => onSharePress()}
                  variant="contained"
                  sx={{
                    transition: "all 300ms ease-in-out",
                  }}
                >
                  {modules.find((m) => m.changed) ? "Send Invites" : "Done"}
                </Button>
              ) : (
                <BeatLoader
                  color={colors.primaryColor}
                  style={{ fontSize: "1.2em" }}
                />
              )}
            </Box>
          )}

          <Box>
            {!loading ? (
              modules.map((item) => {
                return item.type === "RoleModule" ? (
                  <InviteRoleModule
                    RoleModule={item}
                    updateModules={(Module) => updateModules(Module)}
                    event={event}
                    role={item.role}
                  />
                ) : item.type === "MemberModule" ? (
                  <InviteMemberModule
                    MemberModule={item}
                    updateModules={(Module) => updateModules(Module)}
                    categories={event.categories}
                    event={event}
                  ></InviteMemberModule>
                ) : item === "Invite members to share invites" ? (
                  <Box style={{ width: "100%", alignItems: "center" }}>
                    <p
                      style={{
                        ...styles.labels,
                        marginBottom: 5,
                        fontSize: "1.2em",
                        color: colors.errorColor,
                        fontWeight: 50,
                      }}
                    >
                      Invite members to share invites
                    </p>
                  </Box>
                ) : item === "By Member" ? (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "10%",
                      mt: 2,
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography variant="body2" sx={{}}>
                      {item}
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      sx={{
                        bgcolor: colors.backgroundHighlight,
                        borderRadius: 1,
                        flex: 1,
                        fontSize: ".8em",
                      }}
                      placeholder="Search members"
                      InputProps={{
                        startAdornment: (
                          <SearchOutlined
                            sx={{ color: "white", fontSize: "1.2em", mr: 1 }}
                          />
                        ),
                        sx: { color: "white" },
                      }}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Box>
                ) : (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {item}
                  </Typography>
                );
              })
            ) : (
              <Skeleton variant="rounded" height="40em"></Skeleton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  title: {
    color: "white",
  },
  container: {
    display: "flex",
    alignItems: "flex-start",
  },
  labels: {},

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  button: {
    alignSelf: "flex-start",
    width: "50%",
    padding: 10,
    marginTop: "5%",
  },
};

export default SendInvites;
