import { useState } from "react";
import { Button, IconButton, Menu, MenuItem, Skeleton } from "@mui/material";
import GroupSelectModal from "./GroupSelectModal";
import { colors } from "../../theme/colors";
import { Add, MoreHoriz, Remove, ArrowDropDown } from "@mui/icons-material";
import { Contact } from "../../models";
import { DataStore } from "aws-amplify";
import CustomizeContactPowerPointDialog from "./CustomizeContactPowerPointDialog";
import { useAuthContext } from "../../contexts/AuthContext";

const EditContactsHeader = ({
  contactList,
  deleting,
  setDeleting,
  contacts,
  deleteContactsQueue,
  setDeleteContactsQueue,
  setSelectedSortOption,
  selectedSortOption,
  setManuallyAddContactModalVisible,
  customizeOptions,
  setCustomizeOptions,
  loading,
}) => {
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [customizeDialogOpen, setCustomizeDialogOpen] = useState(false);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);

  const [groupSelectModalVisible, setGroupSelectModalVisible] = useState(false);
  const { dbUser } = useAuthContext();

  const toggleSortDropdown = (event) => {
    setSortAnchorEl((old) => (!old ? event.currentTarget : null));
  };

  const toggleMoreOptionsDropdown = (event) => {
    setMoreOptionsAnchorEl((old) => (!old ? event.currentTarget : null));
  };

  const sortButtons = [
    "By Member Score",
    "By CliqScore",
    "By Event Attendance",
  ];

  const moreOptionsButtons = ["Select Contacts", "PowerPoint Settings"];

  const handleListItemClick = (buttonText) => {
    const buttonIndex = sortButtons.indexOf(buttonText);
    setSelectedSortOption((old) =>
      buttonIndex === 0
        ? "avgScore"
        : buttonIndex === 1
        ? "cliqScore"
        : buttonIndex === 2
        ? "events"
        : old
    );
  };

  const handleMoreOptionsItemClick = async (buttonText) => {
    const buttonIndex = moreOptionsButtons.indexOf(buttonText);
    if (buttonIndex === 0) {
      setDeleting(true);
    } else if (buttonIndex === 1) {
      setCustomizeDialogOpen(true);
    }
  };

  const handleGroupSelect = () => {
    setGroupSelectModalVisible(true);
  };

  const displayedSortOption =
    selectedSortOption === "avgScore"
      ? "Member Score"
      : selectedSortOption === "cliqScore"
      ? "CLIQInvite Score"
      : "Event Attendance";
  const status =
    dbUser?.id === contactList?.creatorID
      ? "OWNER"
      : contactList?.coOwners?.includes(dbUser.id)
      ? "COOWNER"
      : "EDITOR";

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1.5%",
      }}
    >
      <CustomizeContactPowerPointDialog
        open={customizeDialogOpen}
        setOpen={setCustomizeDialogOpen}
        selectedOptions={customizeOptions}
        setSelectedOptions={setCustomizeOptions}
      />
      {loading ? (
        <Skeleton sx={{ fontSize: "1.6em", flex: 1, mr: 2 }} />
      ) : (
        <h1
          style={{
            fontSize: "1.6em",
            textAlign: "left",
            margin: 0,
          }}
        >
          {contactList.name}
        </h1>
      )}
      {deleting ? (
        <>
          <Button
            onClick={handleGroupSelect}
            color="secondary"
            sx={{ marginLeft: "1em" }}
          >
            Select Group
          </Button>
          <GroupSelectModal
            isVisible={groupSelectModalVisible}
            close={() => setGroupSelectModalVisible(false)}
            contacts={contacts}
            deleting={deleting}
            deleteContactsQueue={deleteContactsQueue}
            setDeleteContactsQueue={setDeleteContactsQueue}
            setSelectedSortOption={setSelectedSortOption}
            contactListID={contactList.id}
          />
        </>
      ) : null}

      {loading ? (
        <Skeleton sx={{ fontSize: "1.6em", flex: 0.15, mr: 1 }} />
      ) : (
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <p style={{ color: colors.secondaryText, fontSize: ".875rem" }}>
            Sort by:
          </p>
          <Button
            onClick={toggleSortDropdown}
            endIcon={<ArrowDropDown sx={{ color: "white" }} />}
            variant="text"
            sx={{ background: "none !important", color: "white" }}
          >
            {displayedSortOption}
          </Button>
          <Menu
            anchorEl={sortAnchorEl}
            open={Boolean(sortAnchorEl)}
            onClose={toggleSortDropdown}
          >
            {sortButtons.map((button, index) => (
              <MenuItem
                key={index.toString()}
                onClick={() => {
                  handleListItemClick(button);
                  toggleSortDropdown();
                }}
                sx={{
                  px: 3,
                  ":hover": {
                    backgroundColor: "#030303",
                  },
                }}
              >
                {button}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
      {loading ? (
        <Skeleton sx={{ fontSize: "1.6em", flex: 0.15, mr: 1 }} />
      ) : !deleting ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ color: colors.secondaryText, fontSize: ".875rem" }}>
            {`Total:`}
          </p>
          <p
            style={{
              fontSize: ".875em",
              marginLeft: ".5em",
              fontWeight: 500,
              color: deleting ? colors.errorColor : colors.primaryText,
            }}
          >
            {`${contacts?.size}`} contacts
          </p>
        </div>
      ) : null}
      {loading ? (
        <Skeleton sx={{ fontSize: "1.6em", flex: 0.3 }} />
      ) : (
        <div
          style={{
            display: "flex",
            gap: ".5em",
            alignItems: "center",
            marginLeft: "3em",
          }}
        >
          <Button
            variant="contained"
            color={deleting ? "error" : "primary"}
            onClick={async () => {
              if (deleting) {
                deleteContactsQueue.forEach(async (id) => {
                  await DataStore.delete(Contact, (c) => c.id.eq(id));
                });
                setDeleteContactsQueue(new Set([]));
                setDeleting(false);
              } else {
                setManuallyAddContactModalVisible(true);
              }
            }}
            startIcon={
              deleting ? (
                <Remove sx={{ color: "white" }} />
              ) : (
                <Add sx={{ color: "white" }} />
              )
            }
          >
            {deleting
              ? `Remove ${deleteContactsQueue.size} Contacts`
              : "Add Contact"}
          </Button>
          {deleting ? (
            <Button
              onClick={() => {
                if (deleting) {
                  setDeleteContactsQueue(new Set([]));
                  setDeleting(false);
                }
              }}
              sx={{ fontFamily: "Poppins", color: colors.secondaryText }}
            >
              Cancel
            </Button>
          ) : status === "OWNER" || status === "COOWNER" ? (
            <>
              <IconButton onClick={toggleMoreOptionsDropdown}>
                <MoreHoriz sx={{ color: colors.secondaryText, fontSize: 24 }} />
              </IconButton>

              <Menu
                anchorEl={moreOptionsAnchorEl}
                open={Boolean(moreOptionsAnchorEl)}
                onClose={toggleMoreOptionsDropdown}
              >
                {moreOptionsButtons.map((button, index) => (
                  <MenuItem
                    key={index.toString()}
                    onClick={() => {
                      handleMoreOptionsItemClick(button);
                      toggleMoreOptionsDropdown();
                    }}
                    sx={{
                      px: 3,
                      ":hover": {
                        backgroundColor: "#030303",
                      },
                    }}
                  >
                    {button}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default EditContactsHeader;
