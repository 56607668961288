import {
  Button,
  Dialog,
  Typography,
  Box,
  Checkbox,
  Switch,
} from "@mui/material";
import { colors } from "../../../theme/colors";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { Event } from "../../../models";
import { useAuthContext } from "../../../contexts/AuthContext";
import axios from "axios";

const JobNotificationsModal = ({
  open,
  onClose,
  event,
  setSnackbarOpen,
  jobs,
}) => {
  const [notificationOptions, setNotificationOptions] = useState(null);
  const { authUser } = useAuthContext();
  const [saving, setSaving] = useState(false);
  const notificationTimeOptions = [
    { displayedText: "At the time of their shift", value: 0 },
    { displayedText: "5 minutes before their shift", value: 5 },
    { displayedText: "15 minutes before their shift", value: 15 },
  ];

  useEffect(() => {
    (async () => {
      if (open) {
        setNotificationOptions(event.jobsNotificationSettings);
      }
    })();
  }, [open]);

  const areNotificationsOn = !!notificationOptions;

  const handleSaveSettings = async () => {
    if (!event) {
      return;
    }
    if (!event.jobsNotificationSettings?.length && !notificationOptions) {
      return;
    }
    if (event.jobsNotificationSettings?.length && notificationOptions) {
      const sortedCurrentSettings = [...event.jobsNotificationSettings].sort();
      const sortedNewSettings = [...notificationOptions].sort();
      if (
        JSON.stringify(sortedCurrentSettings) ===
        JSON.stringify(sortedNewSettings)
      ) {
        return;
      }
    }
    const token = authUser?.signInUserSession.idToken.jwtToken;
    const url = process.env.REACT_APP_JOB_NOTIFICATIONS_ENDPOINT;

    const body = {
      notificationTimes: notificationOptions || [],
      eventID: event.id,
      eventStartDate: event.startDate,
      jobs: jobs,
    };

    try {
      setSaving(true);
      await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const eventToBeCopied = await DataStore.query(Event, event.id);
      await DataStore.save(
        Event.copyOf(eventToBeCopied, (updated) => {
          updated.jobsNotificationSettings = notificationOptions;
        })
      );
      setSaving(false);
      setSnackbarOpen({
        type: "success",
        message: "Successfully scheduled job notifications",
      });
      onClose();
    } catch (err) {
      console.error(err);
      setSaving(false);

      setSnackbarOpen({
        type: "error",
        message:
          "Could not schedule job notifications. Please try again later.",
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          px: 2,
          py: 2,
          bgcolor: colors.secondaryBackground,
          minWidth: "60dvw",
        },
      }}
    >
      <Box>
        <Typography variant="h1" sx={{ fontWeight: 600, fontSize: "1.2em" }}>
          Job Notifications
        </Typography>
        <Typography variant="body2">
          Easily set up notifications to alert your members when its time for
          their shifts
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", pt: 1 }}>
          <Switch
            checked={areNotificationsOn}
            sx={{
              fontSize: "2em",
              "& .MuiSwitch-track": {
                backgroundColor: `${colors.primaryColor}80`,
              },
            }}
            edge="start"
            color="primary"
            onClick={() => {
              if (areNotificationsOn) {
                setNotificationOptions(null);
              } else setNotificationOptions([5]);
            }}
          />
          <Typography variant="body2">
            Notifications {areNotificationsOn ? "On" : "Off"}
          </Typography>
        </Box>
        {areNotificationsOn ? (
          <Box
            sx={{
              mt: 2,
              mb: 3,
              display: "flex",
              flexDirection: "column",
              gap: 0.75,
            }}
          >
            {notificationTimeOptions.map((option) => {
              const isSelected = notificationOptions?.includes(option.value);

              return (
                <Box sx={{ alignItems: "center", display: "flex" }}>
                  <Checkbox
                    color="primary"
                    sx={{
                      color: `${colors.primaryColor} !important`,
                      p: 0,
                      pr: 1,
                    }}
                    size="small"
                    checked={isSelected}
                    onClick={() =>
                      setNotificationOptions((old) => {
                        if (!old.includes(option.value)) {
                          return [...old, option.value];
                        } else {
                          let tempArray = [...old];
                          tempArray.splice(tempArray.indexOf(option.value), 1);
                          return tempArray;
                        }
                      })
                    }
                  />
                  <Typography variant="body2">
                    {option.displayedText}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ) : null}
        <Button
          variant="contained"
          sx={{ mt: "auto", py: 1, borderRadius: 2, width: "100%" }}
          startIcon={
            <CheckCircleOutlineOutlined sx={{ color: colors.primaryText }} />
          }
          onClick={() => handleSaveSettings()}
        >
          <Typography variant="body1">
            {saving ? "Saving..." : "Confirm"}
          </Typography>
        </Button>
      </Box>
    </Dialog>
  );
};

export default JobNotificationsModal;
