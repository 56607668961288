import { Box, Grid, Skeleton, Tooltip } from "@mui/material";
import { colors } from "../../theme/colors";
import ContactEventModule from "./ContactEventModule";
import { Help } from "@mui/icons-material";

const ContactEventsDisplay = ({
  listEvents,
  listInvites,
  setSelectedFilters,
  setSelectedEvent,
  selectedEvent,
  contacts,
  loading,
}) => {
  return (
    <>
      <Box
        style={{ flex: 1, backgroundColor: colors.secondaryBackground }}
        sx={{ p: 1, pb: 2, borderRadius: 2, mb: "0.5em" }}
      >
        {loading ? (
          <Skeleton sx={{ fontSize: "1.4em", mb: "8px" }} />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".5em",
              marginBottom: ".5em",
            }}
          >
            <h1 style={{ margin: 0, fontSize: "1.4em", textAlign: "left" }}>
              Upcoming List Events
            </h1>
            <Tooltip
              title="Upcoming List Events are upcoming or ongoing events where at least one invite has been sent to a contact using that list."
              sx={{ fontFamily: "Poppins" }}
            >
              <Help sx={{ color: colors.secondaryText }} fontSize="small" />
            </Tooltip>
          </div>
        )}
        {loading ? (
          <Skeleton variant="rounded" sx={{ fontSize: "8em" }} />
        ) : (
          <Grid
            container
            alignItems="center"
            columns={10}
            sx={{
              px: 1,
              py: 0.25,
              mb: 1,
              borderRadius: 1,
              backgroundColor: colors.backgroundHighlight,
            }}
          >
            <Grid item xs={4}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Event
              </p>
            </Grid>
            <Grid item xs={2}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Invited
              </p>
            </Grid>
            <Grid item xs={2}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Not Invited
              </p>
            </Grid>
            <Grid item xs={2}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                More
              </p>
            </Grid>
          </Grid>
        )}
        {loading ? null : listEvents.filter(
            (l) => parseInt(l.endDate) >= Date.now()
          ).length ? (
          listEvents
            .filter((l) => parseInt(l.endDate) >= Date.now())
            .map((event) => (
              <ContactEventModule
                key={event.id}
                event={event}
                relevantInvites={listInvites.filter(
                  (i) => i.eventID === event.id
                )}
                setSelectedFilters={setSelectedFilters}
                setSelectedEvent={setSelectedEvent}
                isSelected={selectedEvent?.id === event.id}
                upcoming
                contacts={contacts}
              />
            ))
        ) : (
          <div>
            <p
              style={{
                color: colors.secondaryText,
                margin: 0,
                fontSize: "0.85em",
              }}
            >
              Your upcoming and ongoing events with invites to this list will
              show up here
            </p>
          </div>
        )}
      </Box>
      <Box
        style={{ flex: 1, backgroundColor: colors.secondaryBackground }}
        sx={{ p: 1, pb: 2, borderRadius: 2 }}
      >
        {loading ? (
          <Skeleton sx={{ fontSize: "1.4em", mb: "8px" }} />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".5em",
              marginBottom: ".5em",
            }}
          >
            <h1 style={{ margin: 0, fontSize: "1.4em", textAlign: "left" }}>
              Past List Events
            </h1>
            <Tooltip
              title="Past List Events are events held in the past where at least one invite was sent to a contact using that list."
              sx={{ fontFamily: "Poppins" }}
            >
              <Help sx={{ color: colors.secondaryText }} fontSize="small" />
            </Tooltip>
          </div>
        )}
        {loading ? (
          <Skeleton variant="rounded" sx={{ fontSize: "8em" }} />
        ) : (
          <Grid
            container
            alignItems="center"
            columns={10}
            sx={{
              px: 1,
              py: 0.25,
              mb: 1,
              borderRadius: 1,
              backgroundColor: colors.backgroundHighlight,
            }}
          >
            <Grid item xs={4}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Event
              </p>
            </Grid>
            <Grid item xs={2}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Invited
              </p>
            </Grid>
            <Grid item xs={2}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Attended
              </p>
            </Grid>
            <Grid item xs={2}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                More
              </p>
            </Grid>
          </Grid>
        )}
        {loading ? null : listEvents.filter(
            (l) => parseInt(l.endDate) < Date.now()
          ).length ? (
          listEvents
            .filter((l) => parseInt(l.endDate) < Date.now())
            .map((event) => (
              <ContactEventModule
                key={event.id}
                event={event}
                relevantInvites={listInvites.filter(
                  (i) => i.eventID === event.id
                )}
                setSelectedFilters={setSelectedFilters}
                setSelectedEvent={setSelectedEvent}
                isSelected={selectedEvent?.id === event.id}
              />
            ))
        ) : (
          <div>
            <p
              style={{
                color: colors.secondaryText,
                margin: 0,
                fontSize: "0.85em",
              }}
            >
              Your past events with invites to this list will show up here
            </p>
          </div>
        )}
      </Box>
    </>
  );
};

export default ContactEventsDisplay;
