import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import React, { useEffect, useState } from "react";
import RushActionCard from "./RushActionCard";
import { SupportAgent, Download } from "@mui/icons-material";

const RushActionSection = () => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setUrl(
        "https://play.google.com/store/apps/details?id=com.skdraughn.CLIQ"
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setUrl("https://apps.apple.com/us/app/cli%CF%89/id1664767480");
    }
    // else {
    //   // for desktop browsers, redirect to signup page
    //   setUrl("https://www.cliqinvite.com/signup");
    // }
  }, []);

  const actions = [
    {
      header: "Download CLIQInvite.",
      icon: <Download sx={{ color: colors.nemoBlue }} fontSize="large" />,
      subHeader:
        "Jump right into CLIQ by downloading our app on the App Store or Google Play",
      buttonText: "Download now",
      onClick: () => window.open(url, "_blank", "noreferrer"),
    },
    {
      header: "Schedule a demo",
      icon: <SupportAgent sx={{ color: colors.nemoBlue }} fontSize="large" />,
      subHeader:
        "Meet with our team to learn more about CLIQ and how we can help your rush",
      buttonText: "Schedule",
      onClick: () =>
        window.open(
          "https://calendly.com/cliqinvite/intro",
          "_blank",
          "noreferrer"
        ),
    },
  ];

  return (
    <Box
      sx={{
        pt: "8rem",
        pb: "16em",
        background: "linear-gradient(75deg, #001527, #064579)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: colors.primaryText,
          lineHeight: 1.12,
          letterSpacing: "-.8px",
          textAlign: "center",
          alignSelf: "center",
          fontSize: {
            lg: "2rem",
            md: "2rem",
            sm: "2rem",
            xs: "1.6rem",
          },
          width: {
            lg: "70%",
            xs: "92dvw",
          },
          mb: "3rem",
        }}
      >
        Ready to take the next step?
      </Typography>
      <Grid
        container
        className="default-page-size"
        rowSpacing={{ xs: 2, md: 0 }}
        sx={{ zIndex: 1 }}
      >
        <Grid item xs={2} />
        {actions.map((action, index) => (
          <React.Fragment>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                pr: { md: index === 0 ? 2 : 0, xs: 0 },
                pl: { md: index !== 0 ? 2 : 0, xs: 0 },
              }}
            >
              <RushActionCard content={action} />
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={2} />
      </Grid>
      <img
        src={require("../../../assets/Rush_Landscape.png")}
        style={{
          width: "100%",
          height: "auto",
          position: "absolute",
          bottom: 0,
          opacity: 1,
        }}
      ></img>
    </Box>
  );
};

export default RushActionSection;
