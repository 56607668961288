import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { colors } from "../../theme/colors";

const EventCategoryTable = ({ categories }) => {
  return (
    <Box className="unstyled-card" sx={{ px: 2, py: 1 }}>
      <Typography variant="h2" sx={{ fontSize: "1em", mb: 1 }}>
        Statistics by Category
      </Typography>
      <Grid
        container
        sx={{
          bgcolor: colors.backgroundHighlight,
          px: 1,
          py: 0.35,
          borderRadius: 1,
          mb: .5
        }}
      >
        <Grid item xs={3}>
          <Typography variant="body2" sx={{ fontSize: ".8em" }}>
            Category
          </Typography>
        </Grid>
        <Grid item xs={2.25}>
          <Typography variant="body2" sx={{ fontSize: ".8em" }}>
            Sent
          </Typography>
        </Grid>
        <Grid item xs={2.25}>
          <Typography variant="body2" sx={{ fontSize: ".8em" }}>
            Valid
          </Typography>
        </Grid>
        <Grid item xs={2.25}>
          <Typography variant="body2" sx={{ fontSize: ".8em" }}>
            Invalid
          </Typography>
        </Grid>
        <Grid item xs={2.25}>
          <Typography variant="body2" sx={{ fontSize: ".8em" }}>
            % Scanned
          </Typography>
        </Grid>
      </Grid>
      {categories
        .filter((c) => c.categoryName !== "No Category")
        .map((category, index) => (
          <Grid
            container
            sx={{
              bgcolor: colors.backgroundHighlight,
              px: 1,
              py: 0.35,
              borderRadius: 1,
              mb: .5
            }}
          >
            <Grid item xs={3}>
              <Typography variant="body1" sx={{ fontSize: ".9em" }}>
                {category.categoryName}
              </Typography>
            </Grid>
            <Grid item xs={2.25}>
              <Typography variant="body1" sx={{ fontSize: ".9em" }}>
                {category.invitesGiven}
              </Typography>
            </Grid>
            <Grid item xs={2.25}>
              <Typography variant="body1" sx={{ fontSize: ".9em" }}>
                {category.validScans}
              </Typography>
            </Grid>
            <Grid item xs={2.25}>
              <Typography variant="body1" sx={{ fontSize: ".9em" }}>
                {category.invalidScans}
              </Typography>
            </Grid>
            <Grid item xs={2.25}>
              <Typography variant="body1" sx={{ fontSize: ".9em" }}>
                {`${
                  category.invitesGiven
                    ? (
                        (category.validScans / category.invitesGiven) *
                        100
                      ).toFixed(0)
                    : "0"
                }%`}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Box>
  );
};

export default EventCategoryTable;
