import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import { Event, OrganizationMember } from "../../../models";
import PublicHeader from "../../../public/navigation/PublicHeader";
import { colors } from "../../../theme/colors";
import EventCategoryTable from "../EventCategoryTable";
import EventInfo from "../EventInfo";
import ScanTimeGraph from "../ScanTimeGraph";
import { Box, Grid, Typography } from "@mui/material";
import {
  getModuleDate,
  getModuleTime,
} from "../../../methods/getFormattedTime";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import WebPortalNavigation from "../../../components/WebPortalNavigation";
import EventPageHeader from "./EventPageHeader";
import { Helmet } from "react-helmet-async";

const EventPage = () => {
  const { eventID } = useParams();
  const [event, setEvent] = useState();
  const [highlightedMembers, setHighlightedMembers] = useState(null);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const { dbUser } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (dbUser) {
        const loadEventData = async () => {
          const tempEvent = await DataStore.query(Event, eventID);
          return tempEvent;
        };

        const waitForAnalytics = async () => {
          let tempEvent = await loadEventData();
          while (!tempEvent.analytics) {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second
            tempEvent = await loadEventData();
          }
          return tempEvent;
        };

        (async () => {
          const event = await loadEventData();
          if (parseInt(event.endDate) > Date.now()) {
            // If event has not expired, go back
            navigate("/Profile");
          } else if (!event.analytics) {
            setLoadingAnalytics(true);
            // If event has expired but analytics is not ready, wait for analytics
            const eventWithAnalytics = await waitForAnalytics();
            setLoadingAnalytics(false);
            setEvent(eventWithAnalytics);
          } else {
            // If event has expired and analytics is ready, set event
            setEvent(event);
          }
        })();
        const organizationMembers = await DataStore.query(
          OrganizationMember,
          (m) =>
            m.and((m) => [
              m.organizationID.eq(dbUser.id),
              m.isConfirmed.eq(true),
            ])
        );
        const updatedMembers = organizationMembers;

        const highlights = {
          mostInvitesMember: null,
          mostQrScannedMember: null,
          bestQrScanRateMember: null,
          mostInvalidScansMember: null,
        };

        // Variable to check if any member has totalInvites > 0

        // Iterate through members and set the highlighted members based on their stats
        updatedMembers.forEach((member) => {
          let eventAnalytics = member.analytics.find(
            (a) => a.eventID === eventID
          );
          if (!eventAnalytics) {
            return;
          }
          const {
            invitesGiven,
            validScans,

            invalidScans,
          } = eventAnalytics;

          const updateHighlight = (key, condition) => {
            if (condition)
              highlights[key] = {
                name: member.memberInfo[0],
                invitesGiven: invitesGiven,
                validScans: validScans,
                invalidScans: invalidScans,
                percent: parseFloat(validScans / invitesGiven),
              };
          };

          updateHighlight(
            "mostInvitesMember",
            !highlights.mostInvitesMember ||
              invitesGiven > highlights.mostInvitesMember.invitesGiven
          );

          updateHighlight(
            "mostQrScannedMember",
            !highlights.mostQrScannedMember ||
              validScans > highlights.mostQrScannedMember.qrScanned
          );

          updateHighlight(
            "bestQrScanRateMember",
            !highlights.bestQrScanRateMember ||
              parseFloat(validScans / invitesGiven) >
                highlights.bestQrScanRateMember.percent
          );

          updateHighlight(
            "mostInvalidScansMember",
            !highlights.mostInvalidScansMember ||
              invalidScans > highlights.mostInvalidScansMember.invalidScans
          );
        });

        console.log(highlights);

        setHighlightedMembers(highlights);
      }
    })();
  }, [dbUser, eventID]);
  // Helper function to parse time.
  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    return [parseInt(hours, 10), parseInt(minutes, 10)];
  };

  // Helper function to format time.
  const formatTime = (hours, minutes) => {
    hours = hours !== 12 ? hours % 12 : hours;
    minutes = minutes > 9 ? minutes : `0${minutes}`;
    return `${hours}:${minutes}`;
  };

  if (!event || loadingAnalytics) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          className="profile-container"
          style={{
            height: "100dvh",
            width: "100dvw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
          }}
        >
          <p>Loading event statistics</p>
          <BeatLoader
            loading={true}
            color={colors.primaryColor}
            size={"2em"}
            speedMultiplier={0.7}
          />
        </div>
      </div>
    );
  } else if (!event.analytics) {
    navigate("/dashboard");
  }

  const pageTitle = `${event?.name || "Event"} Summary • CLIQInvite`;
  const pageDescription = `Easily view your event summary, including major takeaways like who showed up, when they showed up, and what members performed especially well or poorly.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/insights/event`;

  const moduleDate = getModuleDate(event.startDate);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <WebPortalNavigation activeScreen={"/dashboard"} />
      <Box className="page-size-large" sx={{ py: 4, alignSelf: "center" }}>
        <Grid container spacing={1}>
          <EventPageHeader event={event} moduleDate={moduleDate} />
          <Grid item xs={5.5}>
            {!highlightedMembers ? null : (
              <EventInfo highlightedMembers={highlightedMembers} />
            )}
          </Grid>
          <Grid item xs={6.5}>
            <EventCategoryTable categories={event.analytics.categoryData} />
          </Grid>
          <Grid item xs={12}>
            <Box className="unstyled-card" sx={{ px: 2, py: 1 }}>
              <Typography variant="h2" sx={{ fontSize: "1em" }}>
                When Guests Showed Up
              </Typography>
              <ScanTimeGraph
                data={{
                  datasets: [
                    {
                      label: "Valid scans",
                      data: event.analytics.graphData.validScans,
                      borderColor: colors.primaryColor,
                      backgroundColor: colors.primaryColor,
                      lineTension: 0.2,
                      fill: true,
                    },
                    {
                      label: "Denied scans",
                      data: event.analytics.graphData.invalidScans,
                      borderColor: colors.errorColor,
                      backgroundColor: colors.errorColor,
                      lineTension: 0.2,
                      fill: true,
                    },
                  ],
                  labels: event.analytics.graphData.labels.map((label) => {
                    const [moduleHours, moduleMinutes] = parseTime(
                      getModuleTime(event.startDate)
                    );

                    let moduleHoursIncrement = Math.floor(label / 60);
                    let moduleMinutesIncrement = label % 60;

                    let finalHours = moduleHours + moduleHoursIncrement;
                    let finalMinutes = moduleMinutes + moduleMinutesIncrement;

                    if (finalMinutes >= 60) {
                      finalMinutes -= 60;
                      finalHours += 1;
                    }

                    finalHours = finalHours % 24;

                    return formatTime(finalHours, finalMinutes);
                  }),
                }}
                xAxisLabel={"Time"}
                maxHeight="50dvh"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EventPage;
