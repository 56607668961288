import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import ProfileImage from "../../components/ProfileImage";
import { CancelOutlined, CheckOutlined, Edit, ExpandMore, SaveOutlined } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import { useEffect, useState } from "react";
import { Event } from "../../models";
import { DataStore } from "aws-amplify";
import { BeatLoader } from "react-spinners";

const InvitedOrgModule = ({
  organization,
  event,
  setInvitedOrgs,
  setSnackbarOpen,
}) => {
  const [membersOnly, setMembersOnly] = useState(organization.membersOnly);
  const [numInvites, setNumInvites] = useState(organization.numInvites);
  const [renderedCategories, setRenderedCategories] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    let tempInitialLimits = [];

    event.categories.map((category) => {
      let roleIncluded = false;
      category.limits.map((limit) => {
        if (limit.role == organization.id) {
          //if the limit corresponds with the role
          tempInitialLimits.push({
            role: limit.role,
            category: category.category,
            limit: limit.limit,
          }); //initialize based on the already existing limits
          roleIncluded = true; //if the role is included in the category
        }
      });
      if (!roleIncluded) {
        tempInitialLimits.push({
          role: organization.id,
          category: category.category,
          limit: "-1", //global limit if not recognized
        });
      }
    });

    setRenderedCategories(tempInitialLimits);
  }, []);

  const handleSave = async () => {
    setProcessing(true);
    if (organization.isAlreadyInvited && numInvites < organization.lowerInviteLimit) {
      setSnackbarOpen({
        type: "error",
        message:
          "Once you have already invited an organization, you cannot decrease their invites given.",
      });
      setProcessing(false);
      return;
    }
    let tempCategories = [];
    //for each of the categories in the event

    const queriedEvent = await DataStore.query(Event, event.id);
    if (!membersOnly) {
      queriedEvent.categories.map((category) => {
        let tempLimits = [];
        let newLimit;
        //find updated limit for this role in renderedCategories
        let tempIndex = renderedCategories.findIndex(
          (limit) => limit.category === category.category
        );
        newLimit = renderedCategories[tempIndex].limit;
        //for each of the limits in the categories
        let roleIncluded = false;
        category.limits.map((limit) => {
          //if the limit is for the role we are editing, set to new limit
          if (limit.role === organization.id) {
            roleIncluded = true;
          }
          if (limit.role === organization.id && limit.limit !== newLimit) {
            tempLimits.push({
              role: organization.id,
              limit: newLimit == "" ? "-1" : newLimit,
            });
          } else {
            tempLimits.push(limit);
          }
        });
        if (!roleIncluded) {
          tempLimits.push({
            role: organization.id,
            limit: newLimit == "" ? "-1" : newLimit,
          });
        }
        //push the new limits for that category
        tempCategories.push({
          category: category.category,
          limits: tempLimits,
        });
      });

      await DataStore.save(
        Event.copyOf(queriedEvent, (updated) => {
          updated.categories = tempCategories;
        })
      );
    }
    setInvitedOrgs((old) => {
      let thisOrgIndex = old.findIndex((o) => o.id === organization.id);
      let tempNewOrgs = old;
      tempNewOrgs[thisOrgIndex] = {
        ...tempNewOrgs[thisOrgIndex],
        numInvites: numInvites,
        changed: true,
        membersOnly: membersOnly,
      };

      return tempNewOrgs;
    });
    setSnackbarOpen({
      type: "success",
      message:
        "Successfully prepared new invite. Please press 'Send Invites' to complete.",
    });
    setProcessing(false);

    setExpanded(false);
  };

  return (
    <Accordion
      expanded={expanded}
      sx={{
        mt: 1,
        bgcolor: colors.backgroundHighlight,
        borderRadius: 2,
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMore fontSize="large" sx={{ color: colors.primaryText }} />
        }
        onClick={() => setExpanded(true)}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          px: 2,
          py: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            <ProfileImage height="3em" pic={organization.profilePic} />
            <Box>
              <Typography
                variant="h1"
                style={{ fontSize: "1.1em", fontWeight: 600 }}
              >
                {organization.name}
              </Typography>

              <Typography variant="body2">
                @ {organization.shortSchool} •{" "}
                {`${
                  organization.membersOnly
                    ? organization.numInvites === 1000
                      ? "All members"
                      : `${organization.numInvites} members`
                    : organization.numInvites === 1000
                    ? "Infinite invites"
                    : `${organization.numInvites} guest invites`
                }`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Grid container columnSpacing={5}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="body2" sx={{ fontSize: ".9em" }}>
                Invitation Structure
              </Typography>
              <RadioGroup sx={{ mb: "auto", mt: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={membersOnly}
                    onClick={() => setMembersOnly((old) => !old)}
                    sx={{ color: colors.primaryColor, pl: 0, py: 0 }}
                    color="primary"
                    size="small"
                  />
                  <p style={{ margin: 0 }}>Invite only members</p>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "flex-start", mt: 0.5 }}
                >
                  <Radio
                    checked={!membersOnly}
                    onClick={() => setMembersOnly((old) => !old)}
                    sx={{ color: colors.primaryColor, pl: 0, py: 0 }}
                    color="primary"
                    size="small"
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ margin: 0 }}>Invite members + guest invites</p>
                    <p style={{ margin: 0, opacity: 0.7, fontSize: "0.8em" }}>
                      Guest organizations are able to invite as many of their
                      members as they want, but you can set limits on the number
                      and categories of guests invited.
                    </p>
                  </Box>
                </Box>
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ fontSize: ".9em" }}>
                Invitation Details
              </Typography>
              <RadioGroup sx={{ mt: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={numInvites === 1000}
                    onClick={() => setNumInvites(1000)}
                    sx={{ color: colors.primaryColor, py: 0, pl: 0 }}
                    color="primary"
                    size="small"
                  />
                  <p style={{ margin: 0 }}>
                    {membersOnly ? "All members" : "Infinite"}
                  </p>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", pt: 0.5 }}>
                  <Radio
                    checked={numInvites !== 1000}
                    onClick={() => setNumInvites(0)}
                    sx={{ color: colors.primaryColor, py: 0, pl: 0 }}
                    size="small"
                    color="primary"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ margin: 0 }}>Custom</p>
                    {numInvites !== 1000 ? (
                      <TextField
                        size="small"
                        onChange={(e) => {
                          let newNum = parseInt(e.target.value);
                          let newValue =
                            newNum < 0 ? 0 : newNum > 999 ? 999 : newNum;
                          setNumInvites(newValue);
                        }}
                        type="number"
                        value={numInvites}
                        sx={{
                          marginLeft: ".5em",
                          width: "6em",
                          fontWeight: 600,
                          bgcolor: colors.secondaryBackground,
                          borderRadius: 1,
                        }}
                        InputProps={{
                          style: {
                            height: "2em",
                            color: "white",
                          },
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
              </RadioGroup>
              {!membersOnly ? (
                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                  <Typography variant="body2" style={{ fontSize: "0.8em" }}>
                    Limits will be visible to the guest organization, including
                    category names
                  </Typography>
                  {renderedCategories.map((cat, index) => (
                    <Box
                      key={index.toString()}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        px: 1,
                        py: 0.5,
                        bgcolor: colors.secondaryBackground,
                        borderRadius: 2,
                        mt: 0.5,
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ margin: 0 }}>{cat.category}</p>
                      <TextField
                        variant="outlined"
                        type="number"
                        value={cat.limit !== "-1" ? cat.limit : ""}
                        placeholder="No Limit"
                        sx={{
                          width: "8em",
                          fontWeight: 600,
                        }}
                        size="small"
                        InputProps={{
                          style: {
                            padding: 0,
                            height: "2em",
                            backgroundColor: `${colors.backgroundHighlight}`,
                            color: "white",
                            fontFamily: "Poppins",
                          },
                        }}
                        onChange={(e) => {
                          let text =
                            parseInt(e.target.value) > 999
                              ? "999"
                              : parseInt(e.target.value) < 1
                              ? "0"
                              : e.target.value;

                          setRenderedCategories((old) => {
                            let tempArray = old;
                            let tempIndex = old.findIndex(
                              (limit) => limit.category === cat.category
                            );

                            tempArray[tempIndex] = {
                              ...tempArray[tempIndex],
                              limit: text,
                            };
                            return tempArray;
                          });
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            {!processing ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    flex: 0.5,
                    bgcolor: colors.secondaryBackground,
                    ":hover": { bgcolor: colors.secondaryBackground },
                  }}
                  startIcon={
                    <CancelOutlined sx={{ color: colors.primaryText }} />
                  }
                  onClick={() => setExpanded(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ flex: 1 }}
                  onClick={handleSave}
                  startIcon={
                    <SaveOutlined sx={{ color: colors.primaryText }} />
                  }
                >
                  Save
                </Button>
              </>
            ) : (
              <BeatLoader color={colors.primaryColor} />
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default InvitedOrgModule;
