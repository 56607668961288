import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { DataStore, SortDirection } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Announcement, Event, Job } from "../../../models";
import { useParams } from "react-router-dom";
import WebPortalNavigation from "../../../components/WebPortalNavigation";
import { Helmet } from "react-helmet-async";
import AnnouncementsHeader from "./AnnouncementsHeader";
import AnnouncementsDisplay from "./AnnouncementsDisplay";
import { useAuthContext } from "../../../contexts/AuthContext";
import JobsDialogHub from "../jobs/JobsDialogHub";
import AnnouncementsJobDisplay from "./AnnouncementsJobDisplay";
import ImportJobsModal from "../jobs/ImportJobsModal";
import JobNotificationsModal from "../jobs/JobNotificationsModal";

const EventAnnouncements = () => {
  const { eventID } = useParams();
  const { dbUser } = useAuthContext();
  const [event, setEvent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(null);

  const [announcements, setAnnouncements] = useState(null);
  const [localAnnouncement, setLocalAnnouncement] = useState(null);
  const [jobsVisible, setJobsVisible] = useState(false);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [jobNotificationsModalVisible, setJobNotificationsModalVisible] =
    useState(false);
  const [eventJobs, setEventJobs] = useState(null);

  const getAnnouncement = async () => {
    let tempAnnouncements = [];
    const queriedAnnouncements = await DataStore.query(
      Announcement,
      (a) => a.eventID.eq(eventID),
      {
        sort: (a) => a.isPinned(SortDirection.DESCENDING),
      }
    );

    queriedAnnouncements
      .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
      .forEach((announcement) => {
        tempAnnouncements.push({
          ...announcement,
          isOwner: true,
        });
      });

    setAnnouncements(tempAnnouncements);
  };

  const createAnnouncement = () => {
    setLocalAnnouncement({
      isPinned: false,
      announcement: "",
      eventID: eventID,
      title: "",
      rolesVisible: ["No Group", ...dbUser.createdRoles],
      link: undefined,
      isLocal: true,
    });
  };

  const getJobs = async () => {
    const queriedEventJobs = await DataStore.query(Job, (j) =>
      j.eventID.eq(eventID)
    );
    setEventJobs(queriedEventJobs);
  };

  const getEvent = async () => {
    const queriedEvent = await DataStore.query(Event, eventID);

    setEvent(queriedEvent);
  };

  useEffect(() => {
    getAnnouncement();
    getJobs();
    getEvent();
    const announcementSubscription = DataStore.observe(Announcement, (n) =>
      n.eventID.eq(eventID)
    ).subscribe(async (msg) => {
      await getAnnouncement();
    });

    const jobsSubscription = DataStore.observe(Job, (j) =>
      j.eventID.eq(eventID)
    ).subscribe(async (msg) => {
      await getJobs();
    });

    return () => {
      announcementSubscription.unsubscribe();
      jobsSubscription.unsubscribe();
    };
  }, [eventID]);

  const openImportModal = () => {
    setImportModalVisible(true);
  };

  const openNotifsModal = () => {
    setJobNotificationsModalVisible(true);
  };

  const loading = !event;

  const pageTitle = `${event?.name || "Event"} Announcements • CLIQInvite`;
  const pageDescription = `Add announcements with fine-tuned viewing controls or create a job schedule for your event with automated notifications to ensure everyone stays up to date.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/event/edit`;
  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <ImportJobsModal
        setSnackbarOpen={setSnackbarOpen}
        event={event}
        open={importModalVisible}
        onClose={() => setImportModalVisible(false)}
        hasJobs={!!eventJobs}
      />
      <JobsDialogHub
        jobsVisible={jobsVisible}
        setJobsVisible={setJobsVisible}
        setSnackbarOpen={setSnackbarOpen}
        event={event}
      />
      <JobNotificationsModal
        open={jobNotificationsModalVisible}
        onClose={() => setJobNotificationsModalVisible(false)}
        event={event}
        setSnackbarOpen={setSnackbarOpen}
        jobs={eventJobs}
      />
      <WebPortalNavigation activeScreen={"/dashboard"} />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta
          name="description"
          property="og:description"
          content={pageDescription}
        />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta
          name="keywords"
          content="Event Management, Fraternity Management"
        />
      </Helmet>
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>

      <Box
        className="page-size-large"
        sx={{ alignSelf: "center", pt: 2, position: "relative" }}
      >
        {!loading ? (
          <AnnouncementsHeader
            alreadyHasJobs={eventJobs?.length}
            event={event}
            createAnnouncement={createAnnouncement}
            setJobsVisible={setJobsVisible}
          />
        ) : (
          <Skeleton variant="rounded" height="2em" sx={{ mb: 2 }} />
        )}
        {eventJobs?.length ? (
          !loading ? (
            <AnnouncementsJobDisplay
              openImportModal={openImportModal}
              setSnackbarOpen={setSnackbarOpen}
              fullScreen={false}
              setJobsVisible={setJobsVisible}
              eventJobs={eventJobs}
              event={event}
              openNotifsModal={openNotifsModal}
            />
          ) : (
            <Skeleton variant="rounded" height="10em" sx={{ mb: 2 }} />
          )
        ) : null}
        {!loading ? (
          <AnnouncementsDisplay
            localAnnouncement={localAnnouncement}
            announcements={announcements}
            event={event}
            setLocalAnnouncement={setLocalAnnouncement}
            setSnackbarOpen={setSnackbarOpen}
            createAnnouncement={createAnnouncement}
          />
        ) : (
          <Skeleton variant="rounded" height="5em" sx={{ mb: 2 }} />
        )}
      </Box>
    </Box>
  );
};

export default EventAnnouncements;
