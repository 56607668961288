import { useAuthContext } from "../../contexts/AuthContext";
import "./Profile.css";
import PublicHeader from "../../public/navigation/PublicHeader";
import "react-calendar/dist/Calendar.css";
import { useEffect, useState } from "react";
import { Auth, DataStore, Storage } from "aws-amplify";
import { Organization } from "../../models";
import { ClipLoader } from "react-spinners";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProfileImage from "../../components/ProfileImage";
import { Edit } from "@mui/icons-material";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import Compressor from "compressorjs";

const EditProfile = () => {
  const { dbUser } = useAuthContext();
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();

  const formatName = (word) => {
    const sanitizedWord = word.replace(/[^a-zA-Z\s-]/g, "");
    const capitalizedWord =
      sanitizedWord.charAt(0).toUpperCase() + sanitizedWord.slice(1);
    return capitalizedWord;
  };
  useEffect(() => {
    setName(dbUser?.name);
    setBio(dbUser?.bio);
  }, [dbUser?.name]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updating) {
      return;
    }
    if (dbUser.name === name && dbUser.bio === bio) {
      navigate("/dashboard");
    } else if (dbUser.name !== name) {
      try {
        setUpdating(true);
        await DataStore.save(
          Organization.copyOf(dbUser, (updated) => {
            updated.name = name;
            updated.bio = bio;
          })
        );

        setUpdating(false);
        setSnackbarOpen({
          type: "success",
          message: "Account information successfully modified.",
        });
      } catch (e) {
        setUpdating(false);
        setSnackbarOpen({
          type: "error",
          message:
            "Error while processing account changes. Please try again later.",
        });
      }
    } else {
      try {
        setUpdating(true);
        await DataStore.save(
          Organization.copyOf(dbUser, (updated) => {
            updated.bio = bio;
          })
        );
        setUpdating(false);
        setSnackbarOpen({
          type: "success",
          message: "Account information successfully modified.",
        });
      } catch (e) {
        setUpdating(false);
        setSnackbarOpen({
          type: "error",
          message:
            "Error while processing account changes. Please try again later.",
        });
      }
    }
  };

  if (!dbUser) {
    return (
      <div className="page-container profile">
        <PublicHeader />
        <div
          style={{
            height: "100dvh",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {dbUser !== undefined ? (
            <ClipLoader color="gold" size={"8em"} speedMultiplier={0.5} />
          ) : (
            <div
              className="profile-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 0,
              }}
            >
              <p style={{ marginTop: 0 }}>
                Oops, you cannot access your profile page until you complete
                your account setup.
              </p>
              <Button
                variant="contained"
                color="primary"
                sx={{ fontFamily: "Poppins", textTransform: "none" }}
                onClick={() => navigate("/signup")}
              >
                Complete Setup
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  async function compressImage(file, maxWidth, quality) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: quality,
        maxWidth: maxWidth,
        success(result) {
          resolve(result);
        },
        error(err) {
          throw new Error("Error compressing file:", err.message);
        },
      });
    });
  }

  async function chooseImageAndUpload() {
    return new Promise((resolve, reject) => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
          reject("No file selected.");
        }
        try {
          setSnackbarOpen({ type: "info", message: "Uploading new photo" });
          const small = await compressImage(file, 150, 0.8);
          const medium = await compressImage(file, 250, 0.8);
          const large = await compressImage(file, 350, 0.8);

          await Storage.put(`150x150/${dbUser.id}.jpg`, small);
          await Storage.put(`250x250/${dbUser.id}.jpg`, medium);
          await Storage.put(`350x350/${dbUser.id}.jpg`, large);

          resolve("SUCCESS");
        } catch (err) {
          reject("Error choosing and uploading file");
        }
      };
      fileInput.click();
    });
  }

  const handleChangePhoto = async (event) => {
    event.stopPropagation();
    try {
      const result = await chooseImageAndUpload();
      if (result === "SUCCESS") {
        if (dbUser.profilePic !== `${dbUser.id}.jpg`) {
          await DataStore.save(
            Organization.copyOf(dbUser, (updated) => {
              updated.profilePic = `${dbUser.id}.jpg`;
            })
          );
        }
        setSnackbarOpen({
          type: "success",
          message: "Successfully uploaded new photo",
        });
      }
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error uploading file. Please try again later.",
      });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <WebPortalNavigation activeScreen="profile" shouldNotCollapse={true} />
      <Box
        className="page-size-large should-not-collapse"
        sx={{
          alignSelf: "center",
          py: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            transition: "all 400ms ease-in-out",
            width: "8em",
            borderRadius: "4em",
            ":hover": {
              opacity: 0.7,
            },
          }}
          onClick={handleChangePhoto}
        >
          <ProfileImage height="8em" size="large" pic={dbUser.profilePic} />
        </Box>
        <div style={{ display: "flex" }}>
          <p>{dbUser.email}</p>
          <IconButton
            onClick={async () => {
              try {
                await Auth.verifyCurrentUserAttribute("email");
                navigate("/change_email");
              } catch (err) {
                alert(err.message | err);
              }
            }}
          >
            <Edit color="primary" />
          </IconButton>
        </div>
        <Box
          sx={{ width: "100%", marginTop: "2em" }}
          component="form"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Organization name"
            name="orgName"
            variant="outlined"
            value={name}
            fullWidth
            onChange={(e) => setName(formatName(e.target.value))}
            inputProps={{ maxLength: 60 }}
            InputProps={{
              style: {
                backgroundColor: "#090909",
                color: "white",
                fontFamily: "Poppins",
              },
            }}
            sx={{ marginBottom: "1em" }}
          ></TextField>
          <TextField
            label="About us"
            name="bio"
            variant="outlined"
            value={bio}
            fullWidth
            onChange={(e) => setBio(e.target.value)}
            inputProps={{ maxLength: 150 }}
            InputProps={{
              style: {
                backgroundColor: "#090909",
                color: "white",
                fontFamily: "Poppins",
              },
            }}
            multiline
          ></TextField>
          <Button
            variant="contained"
            sx={{ mt: "3em", fontFamily: "Poppins", textTransform: "none" }}
            type="submit"
          >
            {updating ? "Updating..." : "Save"}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default EditProfile;
