import { Auth, DataStore } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import PublicHeader from "../../public/navigation/PublicHeader";
import "./Auth.css";
import { colors } from "../../theme/colors";
import { gstyles } from "../../theme/gstyles";
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../contexts/AuthContext";
import { ClipLoader } from "react-spinners";
import { Organization } from "../../models";
import { Helmet } from "react-helmet-async";
import { Code, EmailOutlined, VerifiedOutlined } from "@mui/icons-material";

const ChangeEmail = () => {
  const [stage, setStage] = useState("VERIFY_OLD_EMAIL"); //VERIFY_OLD_EMAIL, EMAIL, VERIFY, DONE
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const { dbUser } = useAuthContext();
  const navigate = useNavigate();

  const handleChangeEmail = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    if (loading) {
      return;
    } else if (stage === "VERIFY_OLD_EMAIL") {
      try {
        setLoading(true);
        await Auth.verifyCurrentUserAttributeSubmit("email", data.get("code"));
        setLoading(false);
        setStage("EMAIL");
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    } else if (stage === "EMAIL") {
      try {
        setLoading(true);
        const currentUser = await Auth.currentAuthenticatedUser();

        await Auth.updateUserAttributes(currentUser, {
          email: data.get("email"),
        });
        await DataStore.save(
          Organization.copyOf(dbUser, (updated) => {
            updated.email = data.get("email");
          })
        );
        await Auth.verifyCurrentUserAttribute("email");
        setLoading(false);
        setStage("VERIFY");
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    } else if (stage === "VERIFY") {
      try {
        setLoading(true);
        await Auth.verifyCurrentUserAttributeSubmit(
          "email",
          data.get("newCode")
        );
        setLoading(false);
        navigate("/dashboard");
      } catch (error) {
        setLoading(false);
        alert(error.message);
      }
    }
  };

  const pageTitle = `Change Email • CLIQInvite`;
  const pageDescription = `Keep your CLIQInvite account up to date. Change your email to ensure you're receiving all the latest on our event management revolution. Stay connected as you streamline your events and gain access to real-time tracking and detailed analytics.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/change_email`;
  if (!dbUser) {
    return (
      <div className="page-container profile">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={pageImageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <PublicHeader />
        <div
          style={{
            height: "100dvh",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {dbUser !== undefined ? (
            <ClipLoader color="gold" size={"8em"} speedMultiplier={0.5} />
          ) : (
            <div
              className="profile-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 0,
              }}
            >
              <p style={{ marginTop: 0 }}>
                Oops, you cannot change your email until you complete your
                account setup.
              </p>
              <Button
                variant="contained"
                color="primary"
                sx={{ fontFamily: "Poppins", textTransform: "none" }}
                onClick={() => navigate("/signup")}
              >
                Complete Setup
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className="container page-container"
      style={{ backgroundColor: colors.defaultBackground }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <PublicHeader />
      <Box
        component="form"
        onSubmit={handleChangeEmail}
        className={`card ${
          stage === "EMAIL" || stage === "VERIFY" ? "slide-out" : ""
        }`}
        sx={{
          fontFamily: "Poppins",
          fontWeight: 600,
          pb: 2,
          px: { xs: 2, md: 2.5, lg: 3, xl: 3 },
          width: {
            xs: "calc(94dvw - 32px)",
            sm: "70dvw",
            md: "60dvw",
            lg: "45dvw",
            xl: "35dvw",
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xl: "3em", xs: "2.5em" },
            marginTop: "1.5rem",
            marginBottom: "1rem",
            textAlign: "center",
            lineHeight: "115%",
          }}
        >
          Verify Email
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginTop: 0,
            marginBottom: "1.5em",
            textAlign: "center",
          }}
        >
          Verify the code just sent to your organization's email: {dbUser.email}
        </Typography>

        <TextField
          id="code"
          label="code"
          name="code"
          variant="outlined"
          inputMode="numeric"
          required
          InputLabelProps={{ style: { color: colors.primaryColor } }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: `${colors.primaryColor}20`,
            },
          }}
          sx={{ caretColor: "white", mb: "1em" }}
          fullWidth
        />

        <Button
          variant="contained"
          fullWidth
          id={"login"}
          type="submit"
          sx={{ mt: 1 }}
          startIcon={<VerifiedOutlined sx={{ color: "white" }} />}
        >
          {loading ? "Loading..." : "Verify"}
        </Button>
        <p style={{ color: colors.secondaryText, marginBottom: 0 }}>
          Didn't get a code?&nbsp;
          <b
            onClick={async () => {
              try {
                await Auth.verifyCurrentUserAttribute("email");
                setSnackbarOpen({
                  type: "success",
                  message: "Successfully resent a code to your email",
                });
              } catch (err) {
                await Auth.verifyCurrentUserAttribute("email");
                setSnackbarOpen({
                  type: "error",
                  message: err?.message || err,
                });
              }
            }}
            className="link"
            style={{ margin: 0 }}
          >
            Resend
          </b>
        </p>
      </Box>
      {stage === "EMAIL" ? (
        <Box
          component="form"
          onSubmit={handleChangeEmail}
          className={`card slide-in`}
          sx={{
            fontFamily: "Poppins",
            fontWeight: 600,
            pb: 2,
            px: { xs: 2, md: 2.5, lg: 3, xl: 3 },
            width: {
              xs: "calc(94dvw - 32px)",
              sm: "70dvw",
              md: "60dvw",
              lg: "45dvw",
              xl: "35dvw",
            },
            position: "absolute",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xl: "3em", xs: "2.5em" },
              marginTop: "1.5rem",
              marginBottom: "1rem",
              textAlign: "center",
              lineHeight: "115%",
            }}
          >
            New Email
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginTop: 0,
              marginBottom: "1.5em",
              textAlign: "center",
            }}
          >
            Enter your account's new email to receive a one-time password
          </Typography>

          <TextField
            id="email"
            type="email"
            label="email"
            name="email"
            inputMode="email"
            variant="outlined"
            required
            InputLabelProps={{ style: { color: colors.primaryColor } }}
            InputProps={{
              style: {
                color: "white",
                backgroundColor: `${colors.primaryColor}20`,
              },
            }}
            sx={{ caretColor: "white", mb: "1em" }}
            fullWidth
          />
          <Button
            variant="contained"
            fullWidth
            id={"login"}
            type="submit"
            sx={{ mt: 1 }}
            startIcon={<EmailOutlined sx={{ color: "white" }} />}
          >
            {loading ? "Loading..." : "Get OTP"}
          </Button>
        </Box>
      ) : null}
      {stage === "VERIFY" ? (
        <Box
          component="form"
          onSubmit={handleChangeEmail}
          className={`card slide-in`}
          sx={{
            fontFamily: "Poppins",
            fontWeight: 600,
            pb: 2,
            px: { xs: 2, md: 2.5, lg: 3, xl: 3 },
            width: {
              xs: "calc(94dvw - 32px)",
              sm: "70dvw",
              md: "60dvw",
              lg: "45dvw",
              xl: "35dvw",
            },
            position: "absolute",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xl: "3em", xs: "2.5em" },
              marginTop: "1.5rem",
              marginBottom: "1rem",
              textAlign: "center",
              lineHeight: "115%",
            }}
          >
            Change Email
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginTop: 0,
              marginBottom: "1.5em",
              textAlign: "center",
            }}
          >
            Enter the verification code sent to your email to finish changing
            your email.
          </Typography>

          <TextField
            id="password"
            type="code"
            label="code"
            name="newCode"
            inputMode="numeric"
            variant="outlined"
            required
            InputLabelProps={{ style: { color: colors.primaryColor } }}
            InputProps={{
              style: {
                color: "white",
                backgroundColor: `${colors.primaryColor}20`,
              },
            }}
            sx={{ caretColor: "white", mb: "1em" }}
            fullWidth
          />
          <Button
            variant="contained"
            fullWidth
            id={"login"}
            type="submit"
            sx={{ mt: 1 }}
            startIcon={<VerifiedOutlined sx={{ color: "white" }} />}
          >
            {loading ? "Loading..." : "Verify"}
          </Button>
        </Box>
      ) : null}
    </div>
  );
};

export default ChangeEmail;
