import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import {
  AccountBalanceOutlined,
  CancelOutlined,
  GroupOutlined,
  InsightsOutlined,
  MailOutlined,
} from "@mui/icons-material";
import EventSummaryModule from "./EventSummaryModule";
import { sum } from "../../../methods/sum";
import { useNavigate } from "react-router-dom";
import { shortenNumberForModule } from "../../../methods/formatNumbers";

const EventPageHeader = ({ moduleDate, event }) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid item xs={6.5}>
        <Box
          className="unstyled-card"
          sx={{
            display: "flex",
            px: 2,
            py: 1,
            gap: "1em",
            height: "calc(100% - 1em)",
          }}
        >
          <Box
            style={{
              backgroundColor: colors.backgroundHighlight,
              borderRadius: 8,
              alignItems: "center",

              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              padding: ".5em 1.5em",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 500,
                fontSize: "1.8em",
              }}
            >
              {moduleDate.substring(
                moduleDate.indexOf(" ") + 1,
                moduleDate.indexOf(",")
              )}
            </Typography>
            <Typography variant="body2">
              {moduleDate.substring(0, moduleDate.indexOf(" "))}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h1" sx={{fontSize: "1.3em"}}>{event.name}</Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Hosted by you
            </Typography>
            <Box sx={{ width: "100%", display: "flex", gap: 1, mt: 'auto' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ flex: 1 }}
                startIcon={<GroupOutlined sx={{ color: colors.primaryText }} />}
              >
                Guest List
              </Button>
              <Button
                variant="contained"
                sx={{
                  flex: { xs: 1, lg: 0.5 },
                  fontSize: { xs: "12px", lg: ".875rem" },
                  backgroundColor: colors.backgroundHighlight,
                  ":hover": {
                    backgroundColor: `${colors.backgroundHighlight}80`,
                  },
                }}
                startIcon={
                  <InsightsOutlined
                    sx={{
                      color: "white",
                      fontSize: { xs: "12px", lg: ".875rem" },
                    }}
                  />
                }
                onClick={() => navigate("/insights")}
              >
                View Insights
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={5.5}>
        <Box
          className="unstyled-card"
          sx={{ px: 2, py: 1, height: "calc(100% - 1em)" }}
        >
          <Typography variant="h2" sx={{ fontSize: "1em", mb: 1 }}>
            Event Statistics
          </Typography>
          <Box sx={{ mt: "auto", mb: "auto" }}>
            <EventSummaryModule
              icon={<GroupOutlined color="primary" />}
              num={sum(event.analytics.categoryData, "validScans")}
              description="guests showed up"
            />
            {!!event.ticketInformation && (
              <EventSummaryModule
                icon={<AccountBalanceOutlined color="primary" />}
                num={`$${shortenNumberForModule(
                  sum(event.analytics.categoryData, "revenue")
                )}`}
                description="total income"
              />
            )}
            <EventSummaryModule
              icon={<MailOutlined color="primary" />}
              num={sum(event.analytics.categoryData, "invitesGiven")}
              description="guests invited"
            />
            <EventSummaryModule
              icon={<CancelOutlined color="error" />}
              num={sum(event.analytics.categoryData, "invalidScans")}
              description="gatecrashers rejected"
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
};
export default EventPageHeader;
