import { Box, IconButton, Radio, TextField, Typography } from "@mui/material";
import ProfileImage from "../../components/ProfileImage";
import { CgInfinity } from "react-icons/cg";
import { useAuthContext } from "../../contexts/AuthContext";
import { colors } from "../../theme/colors";
const InviteMemberModule = ({ MemberModule, updateModules, event, radio }) => {
  const name = MemberModule?.name;
  const role = MemberModule?.role;
  const numInvites = MemberModule?.num;
  const profilePic = MemberModule?.profilePic;

  const { dbUser } = useAuthContext();

  const onUnlimitedPress = () => {
    MemberModule && MemberModule.num === "1000"
      ? (MemberModule.num = "0")
      : (MemberModule.num = "1000");
    updateModules(MemberModule);
  };

  return (
    <Box
      sx={{
        bgcolor: colors.backgroundHighlight,
        display: "flex",
        alignItems: "center",
        borderRadius: 2,
        mt: 0.5,
        pl: 1.5,
        py: 0.5,
        gap: 1.5,
      }}
    >
      <ProfileImage pic={profilePic} height="3em" />
      <Box style={{ flex: 1 }}>
        <Typography variant="body1">{name}</Typography>
        <Typography variant="body2" sx={{ fontSize: ".8em" }}>
          {role}
        </Typography>
      </Box>
      <Box
        style={{
          marginLeft: "auto",

          display: "flex",
          flexDirection: "row",

          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1em",
        }}
      >
        {dbUser.id === event.organizationID && !radio ? (
          <IconButton
            style={{
              borderWidth: 2,
              borderColor: colors.primaryColor,
              borderStyle: "solid",
              borderRadius: 100,
              backgroundColor:
                MemberModule.num === "1000" ? colors.primaryColor : "transparent",
            }}
            size="small"
            onClick={onUnlimitedPress}
          >
            <CgInfinity fontSize="medium" style={{ color: "white" }} />
          </IconButton>
        ) : null}
        {!radio ? (
          <TextField
            name="numInvites"
            label="# invites"
            inputProps={{ maxLength: 3 }}
            inputMode="numeric"
            size="small"
            onChange={(event) => {
              MemberModule.num = event.target.value;
              updateModules(MemberModule);
            }}
            value={
              numInvites &&
              (numInvites !== "1000" && numInvites !== "0" ? numInvites : "")
            }
            InputLabelProps={{
              shrink: numInvites && numInvites !== "1000" && numInvites !== "0",
            }}
            InputProps={{
              style: {
                color: "white",
                fontFamily: "Poppins",
              },
            }}
            sx={{
              caretColor: "white",
              maxWidth: "6em",
              mr: 1,
              borderRadius: 1,
              bgcolor: colors.secondaryBackground,
            }}
            disabled={MemberModule?.num === "1000"}
          ></TextField>
        ) : (
          <Radio
            checked={numInvites === "INVITED"}
            color="secondary"
            onClick={() => {
              if (numInvites === "INVITED") {
                MemberModule.num = "0";
                updateModules(MemberModule);
              } else {
                MemberModule.num = "INVITED";

                updateModules(MemberModule);
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default InviteMemberModule;
