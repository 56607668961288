import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { colors } from "../../theme/colors";

const CreateEventInformationDialog = ({
  dialogOpen,
  dialogInformation,
  setDialogOpen,
}) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      PaperProps={{
        style: { backgroundColor: colors.backgroundHighlight },
      }}
    >
      <DialogTitle style={{ fontFamily: "Poppins", color: "white" }}>
        {dialogInformation === "ID"
          ? "Require Guest Photo IDs"
          : "External Invite Categories"}
      </DialogTitle>
      {dialogInformation === "ID" ? (
        <DialogContent>
          <DialogContentText
            style={{ fontFamily: "Poppins", color: colors.secondaryText }}
          >
            Enabling our photo ID requirement will necessitate all non-member
            guests to upload a photo ID to access their invitations. This
            applies to any guests not directly associated with your organization
            or any co-host organizations. When they scan their invite, their
            uploaded photo ID will also be displayed to further confirm their
            identity.
          </DialogContentText>
          <br />
          <DialogContentText
            style={{ fontFamily: "Poppins", color: colors.secondaryText }}
          >
            Please note, because of this new process, members will no longer be
            able to directly send QR codes to guests. Instead, they will be
            sending a member-specific link to our website. Guests can use this
            link to upload their photo ID, after which they will receive a QR
            code for their invite.
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText
            style={{ fontFamily: "Poppins", color: colors.secondaryText }}
          >
            External invite categories serve as labels or tags to organize your
            guests into groups, to be shown upon scan.
          </DialogContentText>
          <br />

          <DialogContentText
            style={{
              fontFamily: "Poppins",

              color: colors.secondaryText
            }}
          >
            For instance, consider you're hosting a music concert with attendees
            with different levels of access: VIPs and Standard.
          </DialogContentText>
          <br />
          <DialogContentText
            style={{
              fontFamily: "Poppins",

              color: colors.secondaryText
            }}
          >
            To achieve this, you should create the categories of "VIP" and
            "Standard." Your organization members can send invites with these
            categories so that when these guests scan in at the door, they can
            enter with the proper level of admission.
          </DialogContentText>
          <br />
          <DialogContentText
            style={{
              fontFamily: "Poppins",

              color: colors.secondaryText
            }}
          >
            You can also assign limits to each category. In the concert case, we
            might want to limit the number of VIP invites that each organization
            member has to retain exclusivity.
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          style={{ fontFamily: "Poppins" }}
          onClick={() => setDialogOpen(false)}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateEventInformationDialog;
