import {
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getModuleDate, getModuleTime } from "../../methods/getFormattedTime";
import { MoreHoriz } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { sum } from "../../methods/sum";
import { getEvent } from "../../graphql/queries";

const EventModule = ({ event, isSelected, setSelectedEvent }) => {
  const navigate = useNavigate();
  const expired = Date.now() > parseInt(event.endDate);
  const [anchorEl, setAnchorEl] = useState(null);
  const [totalGuests, setTotalGuests] = useState(null);

  useEffect(() => {
    const loadEventData = async () => {
      const tempEvent = await API.graphql({
        query: getEvent,
        variables: {
          id: event.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return tempEvent.data.getEvent;
    };

    const waitForAnalytics = async () => {
      let tempEvent = await loadEventData();
      let attempts = 0;
      while (!tempEvent.analytics && attempts < 12) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second
        tempEvent = await loadEventData();
        attempts++;
      }
      return tempEvent;
    };
    (async () => {
      if (expired) {
        if (event.analytics) {
          setTotalGuests({
            attendees: sum(event.analytics.categoryData, "validScans"),
          });
        } else {
          const eventWithAnalytics = await waitForAnalytics();
          if (!event.analytics) {
            setTotalGuests({ attendees: "N/A" });
          } else {
            setTotalGuests({
              attendees: sum(
                eventWithAnalytics.analytics.categoryData,
                "validScans"
              ),
            });
          }
        }
      }
    })();
  }, [event.id, event.startDate]);

  const buttonOptions = ["View Guest List"];

  if (!expired) {
    buttonOptions.unshift(
      ...[
        "Edit Event",
        "Event Announcements and Jobs (beta)",
        "Invite Members",
        "Distribute Guest Invites",
        "Invite Organizations",
      ]
    );
  } else {
    buttonOptions.unshift("View Event Page");
  }

  const loading = !totalGuests && expired;

  const handleListItemClick = (button) => {
    if (button === "View Guest List") {
      navigate(`/event/guest_list/${event.id}`);
    } else if (button === "Edit Event") {
      navigate(`/event/edit/${event.id}`);
    } else if (button === "Invite Members") {
      navigate(`/event/invite_members/${event.id}`);
    } else if (button === "Distribute Guest Invites") {
      navigate(`/event/invite/${event.id}`);
    } else if (button === "Invite Organizations") {
      navigate(`/event/invite_orgs/${event.id}`);
    } else if (button === "Event Announcements and Jobs (beta)") {
      navigate(`/event/announcements/${event.id}`);
    } else {
      navigate(`/insights/event/${event.id}`);
    }
  };

  const toggleMoreOptionsDropdown = (e) => {
    e.stopPropagation();
    setAnchorEl((old) => (!old ? e.currentTarget : null));
  };

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        backgroundColor: isSelected
          ? `${colors.primaryColor}22`
          : colors.backgroundHighlight,
        p: 1,
        borderRadius: 1,
        mb: ".3em",
      }}
      columns={10}
      onClick={() => setSelectedEvent(event)}
    >
      <Grid item xs={3}>
        <Tooltip title={event.name} arrow={true}>
          <h4
            style={{
              margin: 0,
              color: colors.primaryText,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {event.name}
          </h4>
        </Tooltip>
        <Tooltip
          title={`${getModuleDate(event.startDate).substring(
            0,
            getModuleDate(event.startDate).indexOf(",")
          )}
            , ${getModuleTime(event.startDate)}`}
          arrow={true}
        >
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: ".85em",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {getModuleDate(event.startDate).substring(
              0,
              getModuleDate(event.startDate).indexOf(",")
            )}
            , {getModuleTime(event.startDate)}
          </p>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip title={event.address} arrow={true}>
          <h4
            style={{
              margin: 0,
              color: colors.primaryText,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textAlign: "center",
              fontSize: ".9em",
              textOverflow: "ellipsis",
            }}
          >
            {event.address}
          </h4>
        </Tooltip>
      </Grid>
      {loading ? (
        <Skeleton variant="rounded" height="3em" />
      ) : (
        <Grid item xs={2.5}>
          <Tooltip
            title={
              !expired
                ? `${event.numGuests} invites sent externally`
                : totalGuests.attendees === "N/A"
                ? "Please allow some time after your event ends for analytics to process"
                : `${totalGuests.attendees} total guests attended`
            }
            arrow={true}
          >
            <div>
              <h4
                style={{
                  color: colors.primaryText,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textAlign: "center",
                  fontSize: "1em",
                  textOverflow: "ellipsis",
                  margin: 0,
                }}
              >
                {!expired ? event.numGuests : totalGuests.attendees}
              </h4>
              <p
                style={{
                  color: colors.secondaryText,
                  fontSize: ".85em",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  margin: 0,
                }}
              >
                {!expired
                  ? `invites sent`
                  : totalGuests.attendees === "N/A"
                  ? "Calculating..."
                  : `total guests`}
              </p>
            </div>
          </Tooltip>
        </Grid>
      )}
      <Grid item xs={1.5} sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton onClick={toggleMoreOptionsDropdown}>
          <MoreHoriz sx={{ color: colors.secondaryText, fontSize: 24 }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={toggleMoreOptionsDropdown}
        >
          {buttonOptions.map((button, index) => (
            <MenuItem
              key={index.toString()}
              onClick={(e) => {
                e.stopPropagation();
                handleListItemClick(button);
                toggleMoreOptionsDropdown();
              }}
              sx={{
                px: 3,
                ":hover": {
                  backgroundColor: "#030303",
                },
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: colors.secondaryText,
                  fontSize: ".85em",
                  fontWeight: 400,
                }}
              >
                {button}
              </p>
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
};

export default EventModule;
