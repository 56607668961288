import { Delete, MoreHoriz, Settings } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { colors } from "../../theme/colors";
import { DataStore } from "aws-amplify";
import { Event, Organization, OrganizationMember } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";

const RoleModule = ({
  role,
  roleMembers,
  filterMembers,
  disabled,
  setSnackbarOpen,
  setSelecting,
  selecting,
  handleAssignMembers,
  selectedMembersQueue
}) => {
  //disabled indicates whether module is editable
  const [open, setOpen] = useState(false);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
  const { dbUser } = useAuthContext();
  const buttons = [
    "Change Group Name",
    "Filter Members by Group",
    "Assign Members to Group",
    "Delete Group",
  ];

  const handleListItemClick = (button) => {
    switch (button) {
      case "Change Group Name": {
        setOpen(true);
        setMoreOptionsAnchorEl(null);
        break;
      }
      case "Filter Members by Group": {
        filterMembers(role);
        setMoreOptionsAnchorEl(null);
        break;
      }
      case "Assign Members to Group": {
        setSelecting(role);
        setMoreOptionsAnchorEl(null);
        break;
      }
      case "Delete Group": {
        setSnackbarOpen({
          type: "error",
          message: `Click confirm to delete group ${role}.`,
          action: (
            <Button size="small" color="inherit" onClick={handleDeleteRole}>
              Confirm
            </Button>
          ),
        });
        setMoreOptionsAnchorEl(null);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleClose = () => {
    setMoreOptionsAnchorEl(null);
  };
  const handleEdit = (e) => {
    setMoreOptionsAnchorEl(e.currentTarget);
  };
  const handleChangeGroupName = async (e) => {
    e?.preventDefault();
    const formData = new FormData(e.target);
    const roleName = formData.get("roleName");
    let snackbarAction = (
      <Button
        size="small"
        color="inherit"
        onClick={() => setSnackbarOpen(null)}
      >
        Ok
      </Button>
    );
    if (roleName === "Edit Groups" || roleName === "No Group") {
      setSnackbarOpen({
        type: "error",
        message: "Groups cannot have name 'Edit Groups' or 'No Group.'",
        action: snackbarAction,
      });
      return;
    } else if (dbUser.createdRoles.includes(roleName)) {
      setSnackbarOpen({
        type: "error",
        message: "Cannot have duplicate group names",
        action: snackbarAction,
      });
      return;
    } else if (roleName.includes("-")) {
      setSnackbarOpen({
        type: "error",
        message: "Group name cannot contain character '-'",
        action: snackbarAction,
      });
      return;
    }

    let tempArray = dbUser.createdRoles ? [...dbUser.createdRoles] : [];
    let index = tempArray.indexOf(role);
    if (index > -1) {
      tempArray.splice(index, 1, roleName);
    }
    try {
      const upcomingEvents = await DataStore.query(Event, (e) =>
        e.and((e) => [
          e.organizationID.eq(dbUser.id),
          e.endDate.gt(Math.floor(new Date()).toString()),
        ])
      );
      const updatePromises = upcomingEvents.map(async (event) => {
        let tempCategories = [];
        event.categories.forEach((category) => {
          let tempLimits = [];
          category.limits.forEach((limit) => {
            if (limit.role !== role) {
              tempLimits.push(limit);
            } else tempLimits.push({ role: roleName, limit: limit.limit });
          });
          tempCategories.push({
            category: category.category,
            limits: tempLimits,
          });
        });

        return DataStore.save(
          Event.copyOf(event, (updated) => {
            updated.categories = tempCategories;
          })
        );
      });

      await Promise.all(updatePromises);
    } catch (error) {
      setSnackbarOpen({
        type: "error",
        message: "Error updating groups. Please try again later.",
        action: snackbarAction,
      });
    }

    try {
      await DataStore.save(
        Organization.copyOf(dbUser, (updated) => {
          updated.createdRoles = tempArray;
        })
      );
      const orgMembers = await DataStore.query(OrganizationMember, (o) =>
        o.and((o) => [
          o.organizationID.eq(dbUser.id),
          o.isConfirmed.ne(false),
          o.role.eq(role),
        ])
      );

      const updateOrgMemberPromises = orgMembers.map((orgMember) =>
        DataStore.save(
          OrganizationMember.copyOf(orgMember, (updated) => {
            updated.role = roleName;
          })
        )
      );

      await Promise.all(updateOrgMemberPromises);
      setSnackbarOpen({
        type: "success",
        message: "Successfully updated group name.",
        action: snackbarAction,
      });
      setOpen(false);
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error updating group name. Please try again later.",
        action: snackbarAction,
      });
    }
  };
  const handleDeleteRole = async () => {
    let snackbarAction = (
      <Button
        size="small"
        color="inherit"
        onClick={() => setSnackbarOpen(null)}
      >
        Ok
      </Button>
    );

    let tempArray = [...dbUser.createdRoles];
    let index = tempArray.indexOf(role);

    if (index > -1) {
      tempArray.splice(index, 1);
    }

    try {
      const updateOrgMemberPromises = roleMembers.map((orgMember) =>
        DataStore.save(
          OrganizationMember.copyOf(orgMember, (updated) => {
            updated.role = "No Group";
          })
        )
      );
      await Promise.all(updateOrgMemberPromises);

      const upcomingEvents = await DataStore.query(Event, (e) =>
        e.and((e) => [
          e.organizationID.eq(dbUser.id),
          e.endDate.gt(Math.floor(new Date()).toString()),
        ])
      );
      const updatePromises = upcomingEvents.map(async (event) => {
        let tempCategories = [];
        event.categories.forEach((category) => {
          let tempLimits = [];
          category.limits.forEach((limit) => {
            if (limit.role !== role) {
              tempLimits.push(limit);
            }
          });
          tempCategories.push({
            category: category.category,
            limits: tempLimits,
          });
        });

        return DataStore.save(
          Event.copyOf(event, (updated) => {
            updated.categories = tempCategories;
          })
        );
      });

      await Promise.all(updatePromises);

      await DataStore.save(
        Organization.copyOf(dbUser, (updated) => {
          updated.createdRoles = tempArray;
        })
      );

      setSnackbarOpen({
        type: "success",
        message: "Group successfully deleted.",
        action: snackbarAction,
      });

      setOpen(false);
      roleMembers = [];
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error deleting group. Please try again later.",
        action: snackbarAction,
      });
    }
  };

  return (
    <Grid
      container
      columns={8}
      sx={{
        alignItems: "center",
        display: "flex",
        p: 1,
        py: 0,
        borderRadius: 1,
        mb: ".3em",
        bgcolor: colors.backgroundHighlight,
      }}
    >
      <Menu
        id="simple-menu"
        anchorEl={moreOptionsAnchorEl}
        keepMounted
        open={Boolean(moreOptionsAnchorEl)}
        onClose={handleClose}
      >
        {buttons
          .filter(
            (b) =>
              !disabled ||
              b === "Filter Members by Group" ||
              b === "Assign Members to Group"
          )
          .map((button, index) => (
            <MenuItem
              onClick={() => handleListItemClick(button)}
              key={index.toString()}
              sx={{ ":hover": { backgroundColor: "#030303" } }}
            >
              {button}
            </MenuItem>
          ))}
      </Menu>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={handleChangeGroupName}>
          <DialogTitle>Change Group Name</DialogTitle>
          <DialogContent>
            <p
              style={{
                margin: 0,
                color: colors.secondaryText,
                marginBottom: "0.5em",
              }}
            >
              Current name: {role}
            </p>
            <TextField
              fullWidth
              name="roleName"
              label="New Name"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: colors.secondaryText }}>Cancel</Button>
            <Button color="primary" type="submit">
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Grid item xs={3}>
        <h1
          style={{
            color: colors.primaryText,
            margin: 0,
            fontSize: "1em",
            textAlign: "center",
          }}
        >
          {role}
        </h1>
      </Grid>
      <Grid item xs={3}>
        <p
          style={{
            margin: 0,
            color: colors.primaryText,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {roleMembers?.length || 0}
        </p>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
      >
        {!selecting ? (
          <IconButton onClick={handleEdit}>
            <MoreHoriz sx={{ color: colors.secondaryText, fontSize: 24 }} />
          </IconButton>
        ) : (
          <Button
            color="primary"
            onClick={() => {
              setSnackbarOpen({
                type: "success",
                message: `Click confirm to assign ${
                  selectedMembersQueue.size
                } member${
                  selectedMembersQueue.size === 1 ? "" : "s"
                } to group ${role}.`,
                action: (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => handleAssignMembers(role)}
                  >
                    Confirm
                  </Button>
                ),
              });
            }}
          >
            Assign
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default RoleModule;
