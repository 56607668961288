import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import React from "react";
import SocialCapabilityCard from "./SocialCapabilityCard";
import {
  CalendarMonthOutlined,
  CategoryOutlined,
  MailOutlined,
  QrCodeScannerOutlined,
  ListOutlined,
  LockOutlined,
  LockPersonOutlined,
  AnalyticsOutlined,
  WorkHistoryOutlined,
  AccountBalanceOutlined,
} from "@mui/icons-material";

const capabilityData = [
  {
    title: "Invite Management",
    capabilities: [
      {
        title: "External Sharing",
        icon: (
          <MailOutlined sx={{ fontSize: "4rem", color: colors.primaryColor }} />
        ),
        subTitle: "Easily send invites to your guests via any app",
        detail: [
          "Quickly invite your brothers and give them guest invites",
          "Repeat previous patterns with Shortcuts",
          "Enable your brothers to send invites in seconds",
          "Track invites at the chapter-wide or brother-by-brother level",
        ],
      },
      {
        title: "Category Limits",
        icon: (
          <CategoryOutlined
            sx={{ fontSize: "4rem", color: colors.primaryColor }}
          />
        ),
        subTitle:
          "Restrict brothers from sending too many invites on a guest-type level",
        detail: [
          "Create categories to group your guests",
          "Assign brothers a category-specific invite ceiling",
          "Adjust limits in real time",
        ],
      },
      {
        title: "Digital Guest List",
        icon: (
          <ListOutlined sx={{ fontSize: "4rem", color: colors.primaryColor }} />
        ),
        subTitle: "Efficiently view your invites with a digitized guest list",
        detail: [
          "Easily access and manage invite information",
          "View invites and forecast attendance in real time",
          "Quickly check brother performance",
          "Efficiently crowd control",
          "Track invites at the chapter-wide or brother-by-brother level",
        ],
      },
    ],
  },
  {
    title: "Guest Admission",
    capabilities: [
      {
        title: "Invite Scanning",
        icon: (
          <QrCodeScannerOutlined
            sx={{ fontSize: "4rem", color: colors.primaryColor }}
          />
        ),
        subTitle: "Streamline guest admission with instant ticket scanning",
        detail: [
          "Quickly scan tickets for fast entry",
          "Minimize queues and wait times at the entrance",
          "Enhance security with validated ticket checks",
          "Real-time guest admission tracking",
          "View all ticket information upon scan",
        ],
      },
      {
        title: "Unique and Limited-Use",
        icon: (
          <LockPersonOutlined
            sx={{ fontSize: "4rem", color: colors.primaryColor }}
          />
        ),
        subTitle:
          "Generate secure, limited-use tickets that are unique to each guest",
        detail: [
          "Automatically generate unique QR codes",
          "View guest name and originating brother upon scan",
          "Track ticket uses and view all ticket activity upon scan",
        ],
      },
      {},
    ],
  },
  {
    title: "Advanced Event Management",
    capabilities: [
      {
        title: "Event Analytics",
        icon: (
          <AnalyticsOutlined
            sx={{ fontSize: "4rem", color: colors.primaryColor }}
          />
        ),
        subTitle: "Unlock valuable insights with comprehensive event analytics",
        detail: [
          "Access live data on guest attendance",
          "Generate custom reports for post-event analysis",
          "Gain insights into peak times and guest flow",
          "Analyze member performance over time",
          "Make data-driven decisions for future events",
        ],
      },
      {
        title: "Event Shifts",
        icon: (
          <WorkHistoryOutlined
            sx={{ fontSize: "4rem", color: colors.primaryColor }}
          />
        ),
        subTitle:
          "Schedule shifts for your brothers with reminder notifications",
        detail: [
          "Automatically generate a schedule",
          "Notify brothers for their shift during events",
          "Easily view your shifts",
          "COMING SOON: Host a shift sign up sheet",
          "COMING SOON: Check in at your shift",
        ],
      },
      {
        title: "Sell Tickets",
        icon: (
          <AccountBalanceOutlined
            sx={{ fontSize: "4rem", color: colors.primaryColor }}
          />
        ),
        subTitle:
          "Schedule shifts for your brothers with reminder notifications",
        navigationScreen: "/philanthropy",
      },
    ],
  },
];

const SocialCapabilitiesSection = () => {
  return (
    <Box
      sx={{
        py: { sm: 12, xs: 8 },
        pb: { sm: 28 },
        gap: 8,
        bgcolor: colors.eggshell,
        position: "relative",
      }}
      width="100dvw"
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <Box
        className="default-page-size"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h2"
          sx={{
            color: colors.defaultBackground,
            lineHeight: 1.12,
            letterSpacing: "-.8px",
            textAlign: "center",
            alignSelf: "center",
            fontSize: {
              lg: "2rem",
              md: "2rem",
              sm: "2rem",
              xs: "1.6rem",
            },
            width: {
              md: "70%",
            },
            mb: "3rem",
          }}
        >
          Learn how CLIQInvite's social features can save time and lower risk
        </Typography>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={{ md: 0, lg: 10, xs: 1 }}
          sx={{ zIndex: 1 }}
        >
          {capabilityData.map(({ title, capabilities, icon }, index) => {
            return (
              <React.Fragment key={index.toString()}>
                <Grid item lg={2.5} md={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      py: { md: 3, xs: 0 },
                      mt: { xs: 5, md: index === 0 ? 0 : 2, lg: 0 },
                      mb: { xs: 2, md: 0 },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        color: colors.defaultBackground,
                        fontSize: {
                          xs: "1.5rem",
                        },

                        textAlign: { md: "left", xs: "center" },
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                </Grid>

                {capabilities.map((capability) => (
                  <Grid item md={4} lg={3.166} xs={12}>
                    <SocialCapabilityCard capability={capability} />
                  </Grid>
                ))}
              </React.Fragment>
            );
          })}
        </Grid>
      </Box>
      <img
        src={require("../../../assets/Rush_Capabilities_Landscape.png")}
        style={{
          width: "100%",
          height: "auto",
          position: "absolute",
          bottom: 0,
        }}
      />
    </Box>
  );
};

export default SocialCapabilitiesSection;
