import React from "react";
import { colors } from "../../theme/colors";
import { Grid } from "@mui/material";
import { getModuleDate, getModuleTime } from "../../methods/getFormattedTime";

const ListEventModule = ({ event, isSelected }) => {
  return (
    <Grid
      container
      columns={10}
      alignItems="center"
      sx={{
        backgroundColor: isSelected
          ? `${colors.primaryColor}22`
          : colors.backgroundHighlight,
        p: 1,
        borderRadius: 1,
        mb: ".3em",
      }}
    >
      <Grid item xs={4}>
        <h4
          style={{
            margin: 0,
            color: colors.primaryText,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {event.name}
        </h4>
        <p
          style={{ margin: 0, color: colors.secondaryText, fontSize: ".85em" }}
        >
          {getModuleDate(event.startDate).substring(
            0,
            getModuleDate(event.startDate).indexOf(",")
          )}
          , {getModuleTime(event.startDate)}
        </p>
      </Grid>
      <Grid item xs={4}>
        <p
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: "1em",
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          {!event?.relevantLists
            ? "Loading"
            : event.relevantLists.length > 1
            ? "Multiple"
            : event.relevantLists[0]}
        </p>
      </Grid>
      <Grid item xs={2}>
        <h3
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: "1em",
            textAlign: "center",
          }}
        >
          {event?.inviteCount || 0}
        </h3>
      </Grid>
    </Grid>
  );
};

export default ListEventModule;
