import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { Cancel, SearchOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import useDynamicDelay from "../../hooks/useDynamicDelay";
import { sum } from "../../methods/sum";

const GuestListFilterDisplay = ({
  loading,
  organizationMembers,
  setSelectedFilters,
  selectedFilters,
  categoriesWithStatistics,
  organizationsWithStatistics,
  sortConfig,
  setSortConfig,
  isPaid,
  isPostEvent,
  guestInviteCount,
}) => {
  const [orgsWithStatistics, setOrgsWithStatistics] = useState([]);

  useEffect(() => {
    const finalOrganizationsWithStatistics = organizationsWithStatistics.map(
      (org) => {
        const relevantInvites = guestInviteCount.filter(
          (g) => g.guestOrgID === org.id
        );
        return {
          ...org,
          guestsDistributed: sum(relevantInvites, "count"),
          used: sum(relevantInvites, "used"),
          numInvalid: sum(relevantInvites, "numInvalid"),
        };
      }
    );
    setOrgsWithStatistics(finalOrganizationsWithStatistics);
  }, [guestInviteCount, organizationsWithStatistics]);

  let dropdownOptions = ["Alphabetical", "Invites Given"];
  if (isPaid) {
    dropdownOptions.push("Revenue");
  }
  if (isPostEvent) {
    dropdownOptions.push(...["Valid Scans", "Invalid Scans"]);
  }

  let organizationModuleFormat = [
    { text: "Organization", size: 6 },
    { text: "# Members", size: 3 },
    { text: "# Guests", size: 3 },
  ];
  if (isPostEvent) {
    organizationModuleFormat.push(
      ...[
        { text: "Valid", size: 3 },
        { text: "Invalid", size: 3 },
      ]
    );
  }
  organizationModuleFormat.push({ text: "Filter", size: 1.5 });

  let categoryModuleFormat = [
    { text: "Category", size: 6 },
    { text: "# Invites", size: 3 },
  ];
  if (isPostEvent) {
    categoryModuleFormat.push(
      ...[
        { text: "Valid", size: 3 },
        { text: "Invalid", size: 3 },
      ]
    );
  }
  categoryModuleFormat.push({ text: "Filter", size: 1.5 });

  const [organizationMemberSearchText, setOrganizationMemberSearchText] =
    useState("");
  const [searchedOrganizationMembers, setSearchedOrganizationMembers] =
    useState([]);

  useDynamicDelay(
    () => {
      setSearchedOrganizationMembers(
        organizationMembers.filter((member) =>
          member.memberInfo[0]
            .toLowerCase()
            .includes(organizationMemberSearchText.toLowerCase())
        )
      );
    },
    300,
    [organizationMemberSearchText]
  );

  const changeMemberFilters = (selectedValues) => {
    setSelectedFilters((old) => {
      let newFilters = [];
      old.forEach((filter) => {
        if (filter.type !== "Member") {
          newFilters.push(filter);
        } else {
          newFilters.push({
            ...filter,
            filter: selectedValues.map((value) => value.userID),
          });
        }
      });
      return newFilters;
    });
  };

  const selectedMembers = selectedFilters
    .find((filter) => filter.type === "Member")
    .filter.map((memberID) =>
      organizationMembers.find((member) => member.userID === memberID)
    );

  const selectedCategories = selectedFilters.find(
    (filter) => filter.type === "Category"
  ).filter;

  const selectedOrganizations = selectedFilters.find(
    (filter) => filter.type === "Organization"
  ).filter;

  return (
    <Box
      className="unstyled-card"
      sx={{
        p: 1,
        pb: 2,
        borderRadius: 2,
        mb: ".5em",
      }}
    >
      {loading ? (
        <Skeleton sx={{ fontSize: "1.4em", mb: "8px" }} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <h1 style={{ margin: 0, fontSize: "1.4em", textAlign: "left" }}>
            Filters
          </h1>
          <Button
            color="error"
            onClick={() => {
              setSelectedFilters([
                { type: "Member", filter: [] },
                { type: "Category", filter: [] },
              ]);
            }}
          >
            Clear
          </Button>
        </div>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "2.5em", mb: "8px" }} />
      ) : (
        <Box style={{ marginBottom: 8 }}>
          <Box style={{ display: "flex", gap: "0.3em", alignItems: "center" }}>
            <p
              style={{
                margin: 0,
                color: colors.secondaryText,
                fontSize: "0.85em",
              }}
            >
              Members
            </p>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              mb: 1,
              flexWrap: "wrap",
            }}
          >
            {selectedMembers.map((member) => {
              return (
                <Chip
                  key={member.userID}
                  label={member.memberInfo[0]}
                  size="small"
                  variant="contained"
                  sx={{
                    color: colors.secondaryText,
                    backgroundColor: colors.backgroundHighlight,
                  }}
                  onDelete={() =>
                    setSelectedFilters((old) => {
                      let newFilters = [];
                      old.forEach((filter) => {
                        if (filter.type !== "Member") {
                          newFilters.push(filter);
                        } else {
                          newFilters.push({
                            ...filter,
                            filter: filter.filter.filter(
                              (filter) => filter !== member.userID
                            ), //LMAO BRO THIS IS SO FUNNY
                          });
                        }
                      });
                      return newFilters;
                    })
                  }
                  deleteIcon={<Cancel color="primary" />}
                />
              );
            })}
          </Box>
          <Autocomplete
            id="member-search"
            disableClearable={true}
            options={searchedOrganizationMembers}
            getOptionLabel={(option) => option.memberInfo[0]}
            ListboxProps={{
              sx: {
                bgcolor: colors.secondaryBackground,
                border: "none",
                outline: "none",
                fontSize: ".8em",
                color: colors.secondaryText,
              },
            }}
            onChange={(event, selectedValues) =>
              changeMemberFilters(selectedValues)
            }
            value={selectedMembers}
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                size="small"
                sx={{ bgcolor: colors.backgroundHighlight, borderRadius: 1 }}
                placeholder="Search Members"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SearchOutlined
                      sx={{ color: "white", fontSize: "1.2em", mr: 1 }}
                    />
                  ),
                  sx: { color: "white" },
                }}
                onChange={(e) =>
                  setOrganizationMemberSearchText(e.target.value)
                }
              />
            )}
          />
        </Box>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "2.5em", my: 1 }} />
      ) : (
        <Box sx={{ mt: 2 }}>
          <Box style={{ display: "flex", gap: "0.3em", alignItems: "center" }}>
            <p
              style={{
                margin: 0,
                color: colors.secondaryText,
                fontSize: "0.85em",
              }}
            >
              Categories
            </p>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Grid
              columns={sum(categoryModuleFormat, "size")}
              container
              sx={{
                bgcolor: colors.backgroundHighlight,
                borderRadius: 1,
                py: 0.25,
                px: 1,
                mb: 0.75,
              }}
            >
              {categoryModuleFormat.map((moduleFormat) => (
                <Grid item xs={moduleFormat.size}>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "center", fontSize: ".8em" }}
                  >
                    {moduleFormat.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            {categoriesWithStatistics.map((category, index) => {
              return (
                <Grid
                  columns={sum(categoryModuleFormat, "size")}
                  container
                  key={index.toString()}
                  sx={{
                    bgcolor: colors.backgroundHighlight,
                    borderRadius: 1,
                    py: 0.5,
                    px: 1,
                    mb: 0.5,
                  }}
                >
                  {categoryModuleFormat.map((moduleFormat) => {
                    let displayedText;
                    switch (moduleFormat.text) {
                      case "Category":
                        displayedText = category.category;
                        break;
                      case "# Invites":
                        displayedText = category.numInvites;
                        break;

                      case "Valid":
                        displayedText = parseInt(category.used);
                        break;
                      case "Invalid":
                        displayedText = category.numInvalid;
                        break;
                      case "Filter":
                        displayedText = null;
                        break;
                    }
                    return (
                      <Grid
                        item
                        xs={moduleFormat.size}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {displayedText === null ? (
                          <Checkbox
                            size="small"
                            sx={{ padding: 0 }}
                            checked={selectedCategories.includes(
                              category.category
                            )}
                            onClick={() =>
                              setSelectedFilters((old) => {
                                let newFilters = [];
                                old.forEach((filter) => {
                                  if (filter.type !== "Category") {
                                    newFilters.push(filter);
                                  } else {
                                    newFilters.push({
                                      ...filter,
                                      filter: filter.filter.includes(
                                        category.category
                                      )
                                        ? filter.filter.filter(
                                            (filter) =>
                                              filter !== category.category
                                          )
                                        : [...filter.filter, category.category],
                                    });
                                  }
                                });
                                return newFilters;
                              })
                            }
                          />
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{ textAlign: "center" }}
                          >
                            {displayedText}
                          </Typography>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Box>
        </Box>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "2.5em", my: 1 }} />
      ) : (
        <Box sx={{ mt: 2 }}>
          <Box style={{ display: "flex", gap: "0.3em", alignItems: "center" }}>
            <p
              style={{
                margin: 0,
                color: colors.secondaryText,
                fontSize: "0.85em",
              }}
            >
              Organization
            </p>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Grid
              container
              sx={{
                bgcolor: colors.backgroundHighlight,
                borderRadius: 1,
                py: 0.25,
                px: 1,
                mb: 0.75,
              }}
              columns={sum(organizationModuleFormat, "size")}
            >
              {organizationModuleFormat.map((moduleFormat) => (
                <Grid item xs={moduleFormat.size} key={moduleFormat.text}>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "center", fontSize: ".8em" }}
                  >
                    {moduleFormat.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            {orgsWithStatistics.length ? (
              orgsWithStatistics.map((organization, index) => {
                return (
                  <Grid
                    container
                    key={index.toString()}
                    sx={{
                      bgcolor: colors.backgroundHighlight,
                      borderRadius: 1,
                      py: 1,
                      px: 1,
                      mb: 0.5,
                      position: "relative",
                    }}
                    columns={sum(organizationModuleFormat, "size")}
                  >
                    <Box
                      sx={{
                        height: ".5em",
                        width: ".5em",
                        bgcolor: organization.color,
                        top: ".25em",
                        left: ".25em",
                        position: "absolute",
                        borderRadius: 2,
                      }}
                    ></Box>
                    {organizationModuleFormat.map((moduleFormat) => {
                      let displayedText;
                      switch (moduleFormat.text) {
                        case "Organization":
                          displayedText = organization.category;
                          break;
                        case "# Members":
                          displayedText = organization.count;
                          break;
                        case "# Guests":
                          displayedText = organization.guestsDistributed;
                          break;
                        case "Valid":
                          displayedText = organization.used;
                          break;
                        case "Invalid":
                          displayedText = organization.numInvalid;
                          break;
                        case "Filter":
                          displayedText = null;
                          break;
                      }
                      return (
                        <Grid
                          item
                          xs={moduleFormat.size}
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {displayedText !== null ? (
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "center", fontSize: ".9em" }}
                            >
                              {displayedText}
                            </Typography>
                          ) : (
                            <Checkbox
                              size="small"
                              sx={{ padding: 0 }}
                              checked={selectedOrganizations.includes(
                                organization.id
                              )}
                              onClick={() =>
                                setSelectedFilters((old) => {
                                  let newFilters = [];
                                  old.forEach((filter) => {
                                    if (filter.type !== "Organization") {
                                      newFilters.push(filter);
                                    } else {
                                      newFilters.push({
                                        ...filter,
                                        filter: filter.filter.includes(
                                          organization.id
                                        )
                                          ? filter.filter.filter(
                                              (filter) =>
                                                filter !== organization.id
                                            )
                                          : [...filter.filter, organization.id],
                                      });
                                    }
                                  });
                                  return newFilters;
                                })
                              }
                            />
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })
            ) : (
              <Typography variant="body2">
                No organizations invited yet
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "2.5em", my: 1 }} />
      ) : (
        <Box sx={{ mt: 2 }}>
          <Box style={{ display: "flex", gap: "0.3em", alignItems: "center" }}>
            <p
              style={{
                margin: 0,
                color: colors.secondaryText,
                fontSize: "0.85em",
              }}
            >
              Sort
            </p>
          </Box>
          <Box sx={{ mt: 1, display: "flex", gap: "1em" }}>
            <TextField
              variant="outlined"
              size="small"
              sx={{ bgcolor: colors.backgroundHighlight, borderRadius: 1 }}
              fullWidth
              label="Order"
              select
              SelectProps={{
                MenuProps: {
                  PaperProps: { sx: { bgcolor: colors.secondaryBackground } },
                },
              }}
              value={sortConfig.order}
              onChange={(e) =>
                setSortConfig((old) => {
                  return { ...old, order: e.target.value };
                })
              }
            >
              <MenuItem value={"ASCENDING"}>Ascending</MenuItem>
              <MenuItem value={"DESCENDING"}>Descending</MenuItem>
            </TextField>

            <TextField
              variant="outlined"
              fullWidth
              size="small"
              sx={{ bgcolor: colors.backgroundHighlight, borderRadius: 1 }}
              label="Option"
              select
              SelectProps={{
                MenuProps: {
                  PaperProps: { sx: { bgcolor: colors.secondaryBackground } },
                },
              }}
              value={sortConfig.option}
              onChange={(e) =>
                setSortConfig((old) => {
                  return { ...old, option: e.target.value };
                })
              }
            >
              {dropdownOptions.map((option, index) => (
                <MenuItem value={option} key={index}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GuestListFilterDisplay;
