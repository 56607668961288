import { Alert, Box, Button, MenuItem, Skeleton, Snackbar, Typography } from "@mui/material";
import { Verified } from "@mui/icons-material";
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { Event, OrganizationMember } from "../../models";
import HotEventDisplay from "./HotEventDisplay";
import InsightsEventDataDisplay from "./InsightsEventDataDisplay";
import "./Insights.css";
import InsightsMemberHighlightDisplay from "./InsightsMemberHighlightDisplay";
import { useParams } from "react-router-dom";
import { sum } from "../../methods/sum";
import { colors } from "../../theme/colors";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import { Helmet } from "react-helmet-async";

const Insights = () => {
  const { dbUser } = useAuthContext();
  const [events, setEvents] = useState(null);
  const [organizationMembers, setOrganizationMembers] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(null);

  const { memberID } = useParams();
  const [selectedMemberID, setSelectedMemberID] = useState();

  useEffect(() => {
    if (organizationMembers && memberID) {
      setSelectedMemberID(memberID);
      const memberHighlights = document.getElementById("member-highlights");
      memberHighlights.scrollIntoView({ behavior: "smooth" });
    }
  }, [organizationMembers, memberID]);

  const [dataTimeFrame, setDataTimeFrame] = useState({
    name: "All Time",
    date: 0,
  });

  const handleChange = (value) => {
    switch (value) {
      case "All Time":
        setDataTimeFrame({ name: "All Time", date: 0 });
        break;
      case "Last Year":
        setDataTimeFrame({
          name: "Last Year",
          date: Math.floor(new Date()) - 31536000000,
        });
        break;
      case "Last Month":
        setDataTimeFrame({
          name: "Last Month",
          date: Math.floor(new Date()) - 2629800000,
        });
        break;
      case "Last Week":
        setDataTimeFrame({
          name: "Last Week",
          date: Math.floor(new Date()) - 604800000,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const loadEventData = async (eventID) => {
      const tempEvent = await DataStore.query(Event, eventID);
      return tempEvent;
    };

    const waitForAnalytics = async (eventID) => {
      let tempEvent = await loadEventData(eventID);
      while (!tempEvent.analytics) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second
        tempEvent = await loadEventData(eventID);
      }
      return tempEvent;
    };

    const queryEventData = () => {
      return DataStore.query(Event, (e) =>
        e.and((e) => [
          e.organizationID.eq(dbUser.id),
          e.endDate.le(Math.floor(new Date()).toString()),
        ])
      );
    };

    const calculateAndReplaceEvents = async (events) => {
      const updatedEvents = [...events];
      for (const [index, event] of updatedEvents.entries()) {
        if (!event.analytics) {
          let newEvent = await waitForAnalytics(event.id);
          updatedEvents.splice(index, 1, newEvent);
        }
      }
      return updatedEvents;
    };

    const sortEventsByValidScans = (events) => {
      return events.sort(
        (a, b) =>
          sum(b.analytics.categoryData, "validScans") -
          sum(a.analytics.categoryData, "validScans")
      );
    };

    (async () => {
      if (dbUser) {
        const events = await queryEventData();

        if (events.filter((event) => !event.analytics).length > 0) {
          const updatedEvents = await calculateAndReplaceEvents(events);
          const sortedEvents = sortEventsByValidScans(updatedEvents);
          setEvents(sortedEvents);
        } else {
          const sortedEvents = sortEventsByValidScans(events);
          setEvents(sortedEvents);
        }
      }
    })();
  }, [dbUser?.id]);

  useEffect(() => {
    const processOrgMembers = async () => {
      let latestEvent = await DataStore.query(Event, (e) =>
        e.and((e) => [
          e.startDate.le(Math.floor(new Date()).toString()),
          e.organizationID.eq(dbUser.id),
        ])
      );
      latestEvent = latestEvent?.length ? latestEvent[0] : {};

      DataStore.query(OrganizationMember, (o) =>
        o.and((o) => [o.organizationID.eq(dbUser.id), o.isConfirmed.eq(true)])
      )
        .then(async (organizationMembers) => {
          // Update member analytics if necessary
          const updatedMembers = organizationMembers;

          // Process the member data for display
          const processedMembers = updatedMembers.map((member) => {
            const {
              totalInvites,
              validScans,
              invalidScans,
              revenue,
              ticketsSold,
            } = member.analytics.reduce(
              (acc, num) => {
                acc.totalInvites += num.invitesGiven || 0;
                acc.validScans += num.validScans || 0;
                acc.invalidScans += num.invalidScans || 0;
                acc.revenue += num.revenue || 0;
                acc.ticketsSold += num.ticketsSold || 0;
                return acc;
              },
              {
                totalInvites: 0,
                validScans: 0,
                invalidScans: 0,
                revenue: 0,
                ticketsSold: 0,
              }
            );
            const timeFrameEventsLength = member.analytics.length;
            let labels = Array.from(
              { length: timeFrameEventsLength },
              (_, i) => i + 1
            );
            if (timeFrameEventsLength === 1) {
              labels = [1, 1];
            }

            const graphInvalidScans = [];
            const graphValidScans = [];
            const invitesGiven = [];

            member.analytics.forEach((analytic) => {
              graphInvalidScans.push(analytic.invalidScans);
              graphValidScans.push(analytic.validScans);
              invitesGiven.push(analytic.invitesGiven);
            });
            if (timeFrameEventsLength === 1) {
              member.analytics.forEach((analytic) => {
                graphInvalidScans.push(analytic.invalidScans);
                graphValidScans.push(analytic.validScans);
                invitesGiven.push(analytic.invitesGiven);
              });
            }

            return {
              name: member.memberInfo[0],
              totalInvites,
              avgInvites: timeFrameEventsLength
                ? (totalInvites / timeFrameEventsLength).toFixed(0)
                : 0,
              qrScanned: validScans,
              qrScanRate: totalInvites
                ? ((validScans * 100) / totalInvites).toFixed(0)
                : 0,
              invalidScans,
              avgValidScans: timeFrameEventsLength
                ? (validScans / timeFrameEventsLength).toFixed(0)
                : 0,
              avgInvalidScans: timeFrameEventsLength
                ? (invalidScans / timeFrameEventsLength).toFixed(0)
                : 0,
              id: member.userID,
              graphData: {
                labels: labels,
                invalidScans: graphInvalidScans,
                validScans: graphValidScans,
                invitesGiven: invitesGiven,
              },
              revenue,
              ticketsSold,
            };
          });

          setOrganizationMembers(processedMembers);
        })
        .catch((err) => alert(`Oops, ${err.message}`));
    };

    if (events) processOrgMembers();
  }, [events]);

  const loading = !events;

  const pageTitle = `${
    dbUser?.name ? dbUser.name : "Account"
  } Insights • CLIQInvite`;
  const pageDescription = `Welcome to your CLIQInvite insights - the one stop shop for all of your organization's data. Analyze event trends over time, view when guests show up, track revenue, or inspect member performance, all from one dashboard.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/insights`;

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <WebPortalNavigation activeScreen="/dashboard" />
      <Box
        className="page-size-large"
        sx={{
          alignSelf: "center",
          pt: 4,
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        {loading ? (
          <Skeleton variant="rounded" height="2em" sx={{ mb: 1 }} />
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "2em",
              alignItems: "flex-start",

              mb: 1,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h1">
                Insights
                <Verified
                  color="secondary"
                  sx={{ position: "absolute" }}
                  fontSize="small"
                />
              </Typography>
              <Typography variant="body2" sx={{ fontSize: ".8em" }}>
                Access all of your event data from one page.
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: ".8em" }}>
                Time Frame
              </Typography>
              <Button
                id="standard-select-currency"
                sx={{
                  fontFamily: "Poppins",
                  bgcolor: colors.secondaryBackground,
                  ":hover": {
                    bgcolor: colors.secondaryBackground,
                  },
                }}
                size="small"
              >
                {["All Time", "Last Year", "Last Month", "Last Week"].map(
                  (option) => (
                    <MenuItem
                      onClick={() => handleChange(option)}
                      key={option}
                      value={option}
                      sx={{
                        fontFamily: "Poppins",
                        color:
                          dataTimeFrame.name === option
                            ? `${colors.primaryColor} !important`
                            : undefined,
                        fontWeight:
                          dataTimeFrame.name === option ? 600 : undefined,
                        transition: "background-color 300ms ease-in-out",
                        ":hover": {
                          bgcolor: "#070707",
                        },
                      }}
                    >
                      {option}
                    </MenuItem>
                  )
                )}
              </Button>
            </Box>
          </Box>
        )}
        <HotEventDisplay events={events} timeFrame={dataTimeFrame.date} setSnackbarOpen={setSnackbarOpen}/>
        <InsightsEventDataDisplay
          events={events}
          timeFrame={dataTimeFrame.date}
        />
        <InsightsMemberHighlightDisplay
          id="member-highlights"
          selectedMemberID={selectedMemberID}
          events={events}
          timeFrame={dataTimeFrame.date}
          members={organizationMembers}
        />
      </Box>
    </Box>
  );
};

export default Insights;
