import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ContactListModule from "./ContactListModule";
import { colors } from "../../theme/colors";

const AllListsDisplay = ({ contactLists, open, setOpen, setSnackbarOpen }) => {
  const renderItem = (item) => (
    <ContactListModule list={item} key={item.id + "123"} setSnackbarOpen={setSnackbarOpen} />
  );

  if (!contactLists) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{ maxHeight: "70%", marginTop: "auto", marginBottom: "auto" }}
    >
      <DialogTitle>
        All Contact Lists
        <br />
        <p
          style={{ fontSize: ".8rem", color: colors.secondaryText, margin: 0 }}
        >
          Modules marked with an icon are owned by another user. Hover over the
          icon to determine who owns it and your permissions status.
        </p>
      </DialogTitle>
      <DialogContent className="scroll-container">
        {contactLists.map(renderItem)}
      </DialogContent>
    </Dialog>
  );
};

export default AllListsDisplay;
