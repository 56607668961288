import * as ExcelJS from "exceljs";
import { sum } from "./sum";
import { DataStore, SortDirection } from "aws-amplify";
import { getModuleDate, getModuleTime } from "./getFormattedTime";
import {
  Event,
  OrganizationMember,
  GuestInvite,
  Invite,
  Organization,
  PayIn,
  Account,
  Payout,
  ContactList,
} from "../models";
import { calculateFees, formatMoney } from "./calculateTakeHome";
import { getCliqScore } from "./getCliqScore";

async function getAllEvents(orgID, period) {
  const allEvents = await DataStore.query(
    Event,
    (e) =>
      e.and((e) => [
        e.organizationID.eq(orgID),
        e.startDate.ge(period.startDate),
        e.endDate.le(period.endDate),
      ]),
    { sort: (e) => e.startDate(SortDirection.DESCENDING) }
  );
  return allEvents;
}

async function getAllMembers(orgID) {
  const allMembers = await DataStore.query(OrganizationMember, (m) =>
    m.and((m) => [m.isConfirmed.eq(true), m.organizationID.eq(orgID)])
  );
  return allMembers;
}

async function getAllContactLists(orgID, period) {
  let allContactLists = await DataStore.query(ContactList, (c) =>
    c.or((c) => [
      c.accessingUsers.contains(orgID),
      c.creatorID.eq(orgID),
      c.coOwners.contains(orgID),
    ])
  );
  allContactLists = allContactLists.filter(
    (cl) =>
      Math.floor(new Date(cl.updatedAt)).toString() >= period.startDate &&
      Math.floor(new Date(cl.updatedAt)).toString() <= period.endDate
  );
  return allContactLists;
}

export const downloadInsightsData = async (
  orgID,
  orgName,
  organization,
  period
) => {
  try {
    const wb = new ExcelJS.Workbook();
    const allEvents = await getAllEvents(orgID, period);
    const account = await organization.Account;
    const allMembers = await getAllMembers(orgID);
    const allContactLists = await getAllContactLists(orgID, period);
    console.log("PAST RETRIEVING");
    await getEventsSheet(wb, allEvents, allMembers, orgName);
    console.log("PAST EVENTS");
    await getMembersSheet(wb, allMembers, orgName);
    console.log("PAST MEMBERS");

    await getMembersDetailedSheet(wb, allEvents, allMembers, orgName);
    console.log("PAST DETAILEDss");

    await getPaymentsSheet(wb, account, allMembers, allEvents, orgName, orgID);
    await Promise.all(
      allEvents.map(async (event, index) => {
        await getGuestListSheet(wb, event, allMembers, orgName, orgID, index);
      })
    );
    await Promise.all(
      allContactLists.map(async (contactList, index) => {
        const guestInvites = await DataStore.query(GuestInvite, (g) =>
          g.contactListID.eq(contactList.id)
        );
        const uniqueEventIDs = new Set(
          guestInvites.map((invite) => invite.eventID)
        );
        const uniqueExpiredEventIDs = (
          await Promise.all(
            Array.from(uniqueEventIDs).map((eventID) => {
              const event = allEvents.find((e) => e.id === eventID);
              if (event.endDate < Date.now().toString()) return eventID;
            })
          )
        ).filter((eventID) => eventID !== undefined);
        const contacts = await contactList.Contacts.toArray();
        await getContactListSheet(
          wb,
          contactList?.name,
          guestInvites,
          contacts,
          uniqueExpiredEventIDs,
          orgName,
          index
        );
      })
    );
    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // Create a download link
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Report.xlsx`;
    link.click();
  } catch (err) {
    alert(err.message || err);
  }
};

export const downloadPaymentsSheet = async (
  orgID,
  orgName,
  organization,
  period,
  setSnackbarOpen
) => {
  try {
    const wb = new ExcelJS.Workbook();
    const allEvents = await getAllEvents(orgID, period);
    const allMembers = await getAllMembers(orgID);
    const account = await organization.Account;
    await getPaymentsSheet(wb, account, allMembers, allEvents, orgName, orgID);
    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // Create a download link
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Report.xlsx`;
    link.click();
  } catch (err) {
    setSnackbarOpen({
      type: "error",
      message:
        "Failed to download your payments report. Please try again later.",
    });
  }
};

export async function getContactListSheet(
  wb,
  contactListName,
  guestInvites,
  contacts,
  uniqueExpiredEventIDs,
  orgName,
  index
) {
  const contactsSheet = wb.addWorksheet(contactListName);

  addHeader(contactsSheet, `${contactListName} data`, orgName);
  const scoredContacts = contacts.map((contact) => {
    let {
      cliqScore,
      eventScore,
      validScans,
      invitedTo,
      attended,
      invalidScans,
      scansGiven,
    } = getCliqScore(contact.id, guestInvites, uniqueExpiredEventIDs);

    return Object.values({
      name: contact?.name,
      number: contact.number,
      notes: contact.notes,
      member_ratings: contact.avgScore || 0,
      cliq_score: cliqScore || 0,
      events_invited_to: invitedTo,
      events_attended: attended,
      events_attended_percent: eventScore / 100,

      max_scans: scansGiven || 0,
      valid_scans: validScans || 0,
      invalid_scans: invalidScans || 0,
    });
  });

  contactsSheet.addTable({
    name: `ContactListTable${index ? index : Math.round(Math.random() * 10)}`,
    ref: "A5",
    headerRow: true,
    style: {
      theme: "TableStyleMedium5",
      showRowStripes: true,
    },
    columns: [
      { name: "Name" },
      { name: "Number" },
      { name: "Notes" },
      { name: "Avg Member Rating" },
      { name: "CLIQInvite Score" },
      { name: "Events Invited To" },
      { name: "Events Attended" },
      { name: "% Events Attended" },
      { name: "Max Scans Given" },
      { name: "Valid Scans" },
      { name: "Invalid Scans" },
    ],
    rows: scoredContacts,
  });
  contactsSheet.views = [
    {
      showGridLines: false,
    },
  ];

  for (let i = 6; i < scoredContacts.length + 7; i++) {
    let rowH = contactsSheet.getCell(`H${i}`);
    rowH.numFmt = "0.0%";
  }

  contactsSheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => (v ? v.toString().length : 0));

    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength > 7 ? maxLength : 7;
  });
}

async function getPaymentsSheet(
  wb,
  account,
  orgMembers,
  events,
  orgName,
  orgID
) {
  if (events.filter((e) => e.ticketInformation?.price)?.length) {
    const payouts = await DataStore.query(Payout, (p) =>
      p.accountID.eq(account.id)
    );
    const tempFormattedPayouts = payouts.map((payout) => ({
      amount: formatMoney(payout.amount, "spreadsheet"),
      date_requested: getModuleDate(Math.floor(new Date(payout.createdAt))),
      time_requested: getModuleTime(Math.floor(new Date(payout.createdAt))),
      status: payout.isFulfilled ? "Processed" : "Pending",
    }));

    const payIns = await DataStore.query(PayIn, (p) =>
      p.accountID.eq(account.id)
    );

    let allMemberGuestInvites = [];
    for (const member of orgMembers) {
      const guestInvites = await DataStore.query(GuestInvite, (g) =>
        g.and((g) => [g.cost.gt(0), g.orgMemberID.eq(member.userID)])
      );
      allMemberGuestInvites.push(...guestInvites);
    }

    orgMembers.map((member) => {
      const memberGuestInvites =
        allMemberGuestInvites.filter((g) => g.orgMemberId === member.id) || [];
      let totalTickets = 0;
      let totalRevenue = 0;

      memberGuestInvites.forEach((g) => {
        totalTickets += g.maxUses;
        totalRevenue += g.cost;
      });

      return {
        name: member?.name,
        num_tickets_sold: totalTickets,
        revenue: formatMoney(totalRevenue, "spreadsheet"),
      };
    });

    const payInMap = new Map(payIns.map((p) => [p.payInEventId, p]));

    const tempFormattedPaidEvents = events
      .filter((e) => e.ticketInformation?.price)
      .map((event) => {
        const payIn = payInMap.get(event.id) || { numTickets: 0 };
        const eventGuestInvites = allMemberGuestInvites.filter(
          (g) => g.eventID === event.id && g.cost > 0
        );

        function getTopTwoMembersWithMostInvites(eventGuestInvites) {
          // Create a map where the key is a member's ID and the value is the total number of their invite uses
          const memberInviteCounts = eventGuestInvites.reduce((acc, invite) => {
            acc[invite.orgMemberID] =
              (acc[invite.orgMemberID] || 0) + invite.maxUses;
            return acc;
          }, {});

          // Initialize top two members
          let firstMaxInviteCount = 0;
          let firstMaxInviteMemberID = null;
          let secondMaxInviteCount = 0;
          let secondMaxInviteMemberID = null;

          for (const [orgMemberID, inviteCount] of Object.entries(
            memberInviteCounts
          )) {
            if (inviteCount >= firstMaxInviteCount) {
              // Current member has more invites than the current first member, demote the current first member to second place
              secondMaxInviteCount = firstMaxInviteCount;
              secondMaxInviteMemberID = firstMaxInviteMemberID;

              // Promote the current member to first place
              firstMaxInviteCount = inviteCount;
              firstMaxInviteMemberID = orgMemberID;
            } else if (inviteCount >= secondMaxInviteCount) {
              // Current member has more invites than the current second member, but less than the current first member
              secondMaxInviteCount = inviteCount;
              secondMaxInviteMemberID = orgMemberID;
            }
          }

          // Return the members' IDs and the total numbers of their invite uses
          return {
            firstPlace: {
              memberID: firstMaxInviteMemberID,
              inviteCount: firstMaxInviteCount,
            },
            secondPlace: {
              memberID: secondMaxInviteMemberID,
              inviteCount: secondMaxInviteCount,
            },
          };
        }

        const topTwoMembers =
          getTopTwoMembersWithMostInvites(eventGuestInvites);

        return {
          name: event.name,
          start_date: getModuleDate(event.startDate),
          num_tickets_sold: payIn.numTickets,
          revenue: formatMoney(payIn.amount * 100, "spreadsheet"),
          top_member_name:
            topTwoMembers.firstPlace.memberID === orgID
              ? "Organization Account"
              : orgMembers.find(
                  (m) => m.userID === topTwoMembers.firstPlace.memberID
                )?.memberInfo[0] || "none",
          top_tickets_sold: topTwoMembers.firstPlace.inviteCount,
          top_revenue:
            topTwoMembers.firstPlace.inviteCount *
            calculateFees(event?.ticketInformation?.price * 100),
          second_member_name:
            topTwoMembers.firstPlace.memberID === orgID
              ? "Organization Account"
              : orgMembers.find(
                  (m) => m.userID === topTwoMembers.secondPlace.memberID
                )?.memberInfo[0] || "none",
          second_tickets_sold: topTwoMembers.secondPlace.inviteCount,
          second_revenue:
            topTwoMembers.secondPlace.inviteCount *
            calculateFees(event?.ticketInformation?.price * 100),
        };
      });

    // Create "Payouts" sheet

    const paidEventsSheet = wb.addWorksheet("Paid Events");
    const payoutSheet = wb.addWorksheet("Payouts");

    payoutSheet.addTable({
      name: "PayoutsTable",
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium5",
        showRowStripes: true,
      },
      columns: [
        { name: "Amount" },
        { name: "Date Requested" },
        { name: "Time Requested" },
        { name: "Status" },
      ],
      rows: tempFormattedPayouts.map((payout) => [
        payout.amount,
        payout.date_requested,
        payout.time_requested,
        payout.status,
      ]),
    });

    for (let i = 8; i < tempFormattedPayouts.length + 4; i++) {
      payoutSheet.getCell(`A${i}`).numFmt = "$#,##0.00";
    }

    addHeader(payoutSheet, "Payouts spreadsheet", orgName);

    payoutSheet.columns.forEach((column) => {
      const lengths = column.values.map((v) => (v ? v.toString().length : 0));

      const maxLength = Math.max(
        ...lengths.filter((v) => typeof v === "number")
      );
      column.width = maxLength;
    });

    // Create "Paid Events" sheet

    paidEventsSheet.addTable({
      name: "PaidEventsTable",
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium5",
        showRowStripes: true,
      },
      columns: [
        { name: "Name" },
        { name: "Start Date" },
        { name: "Tickets Sold" },
        { name: "Revenue" },
        { name: "Top Selling Member" },
        { name: "Top Selling Member Revenue" },
        { name: "Top Selling Member Tickets Sold" },
        { name: "Second Selling Member" },
        { name: "Second Selling Member Revenue" },
        { name: "Second Selling Member Tickets Sold" },
      ],
      rows: [
        ...tempFormattedPaidEvents.map((event) => [
          event.name,
          event.start_date,
          event.num_tickets_sold,
          event.revenue,
          event.top_member_name,
          event.top_revenue,
          event.top_tickets_sold,
          event.second_member_name,
          event.second_revenue,
          event.second_tickets_sold,
        ]),
      ],
    });

    for (let i = 4; i < tempFormattedPayouts.length + 4; i++) {
      paidEventsSheet.getCell(`A${i}`).numFmt = "$#,##0.00";
    }

    addHeader(paidEventsSheet, "Paid events spreadsheet", orgName);

    paidEventsSheet.columns.forEach((column) => {
      const lengths = column.values.map((v) => (v ? v.toString().length : 0));
      const maxLength = Math.max(
        ...lengths.filter((v) => typeof v === "number")
      );
      column.width = maxLength;
    });

    payoutSheet.views = [
      {
        showGridLines: false,
      },
    ];
    paidEventsSheet.views = [
      {
        showGridLines: false,
      },
    ];
  }
}

async function getEventsSheet(wb, allEvents, allMembers, orgName) {
  const eventsSheet = wb.addWorksheet("Events Data");

  addHeader(eventsSheet, `Events data`, orgName);

  eventsSheet.addTable({
    name: "EventsTable",
    ref: "A5",
    headerRow: true,
    totalsRow: true,
    style: {
      theme: "TableStyleMedium5",
      showRowStripes: true,
    },
    columns: [
      { name: "Name" },
      { name: "Start Date" },
      { name: "End Date" },
      { name: "Description" },
      { name: "Location" },
      { name: "Categories" },
      { name: "Ticket Price" },
      { name: "Invites Sent", totalsRowFunction: "sum" },
      { name: "Total Scans", totalsRowFunction: "sum" },
      { name: "Valid Scans", totalsRowFunction: "sum" },
      { name: "Invalid Scans", totalsRowFunction: "sum" },
      { name: "Valid Scan Rate", totalsRowFunction: "average" },
      { name: "Invalid Scan Rate", totalsRowFunction: "average" },
      { name: "Most Invites" },
      { name: "Invite #" },
      { name: "Most Valid Scans" },
      { name: "Valid #" },
      { name: "Most Invalid Scans" },
      { name: "Invalid #" },
    ],
    rows: allEvents.map((event) => {
      const invitesGiven = sum(event.analytics.categoryData, "invitesGiven");
      const validScans = sum(event.analytics.categoryData, "validScans");
      const invalidScans = sum(event.analytics.categoryData, "invalidScans");

      let mostInvitesMember = { name: "", invitesGiven: 0 };
      let mostValidMember = { name: "", validScans: 0 };
      let mostInvalidMember = { name: "", invalidScans: 0 };

      allMembers.forEach((member) => {
        let eventAnalytics = member.analytics.find(
          (a) => a.eventID === event.id
        );
        if (!eventAnalytics) return;
        if (eventAnalytics.invitesGiven >= mostInvitesMember.invitesGiven) {
          mostInvitesMember = {
            name: member.memberInfo[0],
            invitesGiven: eventAnalytics.invitesGiven,
          };
        }
        if (eventAnalytics.validScans >= mostValidMember.validScans) {
          mostValidMember = {
            name: member.memberInfo[0],
            validScans: eventAnalytics.validScans,
          };
        }
        if (eventAnalytics.invalidScans >= mostInvalidMember.invalidScans) {
          mostInvalidMember = {
            name: member.memberInfo[0],
            invalidScans: eventAnalytics.invalidScans,
          };
        }
      });

      return [
        event?.name,
        getModuleDate(event.startDate) + ", " + getModuleTime(event.startDate),
        getModuleDate(event.endDate) + ", " + getModuleTime(event.endDate),
        event.description,
        event.address,
        event.categories.map((category) => category.category).join(", "),
        event.ticketInformation?.price || "N/A",
        invitesGiven || 0,
        validScans + invalidScans || 0,
        validScans || 0,
        invalidScans || 0,
        invitesGiven ? validScans / invitesGiven : 0,
        invitesGiven ? invalidScans / invitesGiven : 0,
        mostInvitesMember?.name || "",
        mostInvitesMember?.invitesGiven || 0,
        mostValidMember?.name || "",
        mostValidMember?.validScans || 0,
        mostInvalidMember?.name || "",
        mostInvalidMember?.invalidScans || 0,
      ];
    }),
  });
  eventsSheet.views = [
    {
      showGridLines: false,
    },
  ];

  for (let i = 6; i < allEvents.length + 7; i++) {
    let rowJ = eventsSheet.getCell(`L${i}`);
    rowJ.numFmt = "0.0%";
    let rowK = eventsSheet.getCell(`M${i}`);
    rowK.numFmt = "0.0%";
  }

  eventsSheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => (v ? v.toString().length : 0));

    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength > 7 ? maxLength : 7;
  });
}

async function getMembersSheet(wb, orgMembers, orgName) {
  const memberSheet = wb.addWorksheet("Member Event Overview");

  addHeader(memberSheet, `Member event data`, orgName);
  memberSheet.addTable({
    name: "MembersTable",
    ref: "A5",
    headerRow: true,
    totalsRow: true,
    style: {
      theme: "TableStyleMedium5",
      showRowStripes: true,
    },
    columns: [
      { name: "Name" },
      { name: "Date Join Requested" },
      { name: "Group" },
      { name: "Total Events", totalsRowFunction: "average" },
      { name: "Total Invites Given", totalsRowFunction: "sum" },
      { name: "Total Valid Scans", totalsRowFunction: "sum" },
      { name: "Total Invalid Scans", totalsRowFunction: "sum" },
      { name: "Valid Scan Rate" },
      { name: "Invalid Scan Rate" },
      { name: "Average Invites Given", totalsRowFunction: "average" },
      { name: "Average Valid Scans", totalsRowFunction: "average" },
      { name: "Average Invalid Scans", totalsRowFunction: "average" },
    ],
    rows: orgMembers.map((member) => {
      let invitesGiven = member.analytics
        ? sum(member.analytics, "invitesGiven")
        : 0;
      let validScans = member.analytics
        ? sum(member.analytics, "validScans")
        : 0;
      let invalidScans = member.analytics
        ? sum(member.analytics, "invalidScans")
        : 0;

      return [
        member.memberInfo[0],
        getModuleDate(Math.floor(new Date(member.createdAt))) +
          ", " +
          getModuleTime(Math.floor(new Date(member.createdAt))),

        member.role,
        member.analytics ? member.analytics.length : 0,
        invitesGiven,
        validScans,
        invalidScans,
        invitesGiven ? parseFloat((validScans / invitesGiven).toFixed(3)) : 0,
        invitesGiven ? parseFloat((invalidScans / invitesGiven).toFixed(3)) : 0,
        member.analytics?.length ? invitesGiven / member.analytics.length : 0,
        member.analytics?.length ? validScans / member.analytics.length : 0,
        member.analytics?.length ? invalidScans / member.analytics.length : 0,
      ];
    }),
  });
  memberSheet.views = [
    {
      showGridLines: false,
    },
  ];
  for (let i = 6; i < orgMembers.length + 6; i++) {
    let rowH = memberSheet.getCell(`H${i}`);
    rowH.numFmt = "0.0%";
    let rowI = memberSheet.getCell(`I${i}`);
    rowI.numFmt = "0.0%";
    let rowJ = memberSheet.getCell(`J${i}`);
    rowJ.numFmt = "0.0";
    let rowK = memberSheet.getCell(`K${i}`);
    rowK.numFmt = "0.0";
    let rowL = memberSheet.getCell(`L${i}`);
    rowL.numFmt = "0.0";
  }
  memberSheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => (v ? v.toString().length : 0));

    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength > 7 ? maxLength : 7;
  });
}

async function getMembersDetailedSheet(wb, allEvents, orgMembers, orgName) {
  const memberSheet = wb.addWorksheet("Member Event Details");

  addHeader(memberSheet, `Member event data`, orgName);

  const rows = orgMembers
    .filter((m) => m.analytics?.length)
    .map((member) => {
      let invitesGiven = member.analytics
        ? sum(member.analytics, "invitesGiven")
        : 0;
      let validScans = member.analytics
        ? sum(member.analytics, "validScans")
        : 0;
      let invalidScans = member.analytics
        ? sum(member.analytics, "invalidScans")
        : 0;
      let givenChangeOverTime = 0;
      let validChangeOverTime = 0;
      let invalidChangeOverTime = 0;
      return [
        ...member.analytics.map((eventAnalytic, index) => {
          const event = allEvents.find((e) => e.id === eventAnalytic.eventID);
          if (!event) {
            return;
          }
          const prevEventAnalytic = member.analytics[index - 1];

          let invitesGivenChange = 0;
          let validScansChange = 0;
          let invalidScansChange = 0;

          if (prevEventAnalytic) {
            invitesGivenChange = prevEventAnalytic.invitesGiven
              ? parseFloat(
                  (
                    (eventAnalytic.invitesGiven -
                      prevEventAnalytic.invitesGiven) /
                    prevEventAnalytic.invitesGiven
                  ).toFixed(3)
                )
              : eventAnalytic.invitesGiven;
            validScansChange = prevEventAnalytic.validScans
              ? parseFloat(
                  (
                    (eventAnalytic.validScans - prevEventAnalytic.validScans) /
                    prevEventAnalytic.validScans
                  ).toFixed(3)
                )
              : eventAnalytic.validScans;
            invalidScansChange = prevEventAnalytic.invalidScans
              ? parseFloat(
                  (
                    (eventAnalytic.invalidScans -
                      prevEventAnalytic.invalidScans) /
                    prevEventAnalytic.invalidScans
                  ).toFixed(3)
                )
              : eventAnalytic.invalidScans;
            givenChangeOverTime += invitesGivenChange;
            validChangeOverTime += validScansChange;

            invalidChangeOverTime += invalidScansChange;
          }
          return [
            member.memberInfo[0],
            event?.name,
            getModuleDate(event.startDate),
            eventAnalytic.invitesGiven,
            eventAnalytic.validScans,
            eventAnalytic.invalidScans,
            eventAnalytic.invitesGiven
              ? parseFloat(
                  (
                    eventAnalytic.validScans / eventAnalytic.invitesGiven
                  ).toFixed(3)
                )
              : 0,
            eventAnalytic.invitesGiven
              ? parseFloat(
                  (
                    eventAnalytic.invalidScans / eventAnalytic.invitesGiven
                  ).toFixed(3)
                )
              : 0,
            isNaN(invitesGivenChange) ? 0 : invitesGivenChange,
            isNaN(validScansChange) ? 0 : validScansChange,
            isNaN(invalidScansChange) ? 0 : invalidScansChange,
          ];
        }),
        [
          "SubTotal",
          "",
          "",
          invitesGiven,
          validScans,
          invalidScans,
          invitesGiven ? parseFloat((validScans / invitesGiven).toFixed(3)) : 0,
          invitesGiven
            ? parseFloat((invalidScans / invitesGiven).toFixed(3))
            : 0,
          isNaN(givenChangeOverTime) || !(member.analytics.length - 1)
            ? 0
            : givenChangeOverTime / (member.analytics.length - 1),
          isNaN(validChangeOverTime) || !(member.analytics.length - 1)
            ? 0
            : validChangeOverTime / (member.analytics.length - 1),

          isNaN(invalidChangeOverTime) || !(member.analytics.length - 1)
            ? 0
            : invalidChangeOverTime / (member.analytics.length - 1),
        ],
      ];
    })
    .flat()
    .filter(Boolean);
  memberSheet.addTable({
    name: "MemberDetailsTable",
    ref: "A5",
    headerRow: true,
    style: {
      theme: "TableStyleMedium5",
      showRowStripes: true,
    },
    columns: [
      { name: "Name" },
      { name: "Event Name" },
      { name: "Event Date" },
      { name: "Total Invites Given" },
      { name: "Total Valid Scans" },
      { name: "Total Invalid Scans" },
      { name: "Valid Scan Rate" },
      { name: "Invalid Scan Rate" },
      { name: "Given % Increase Over Time" },
      { name: "Valid % Increase Over Time" },
      { name: "Invalid % Increase Over Time" },
    ],
    rows: rows,
  });

  memberSheet.views = [
    {
      showGridLines: false,
    },
  ];

  for (let i = 6; i < rows.length + 6; i++) {
    let rowG = memberSheet.getCell(`G${i}`);
    rowG.numFmt = "0.0%";
    let rowH = memberSheet.getCell(`H${i}`);
    rowH.numFmt = "0.0%";
    let rowI = memberSheet.getCell(`I${i}`);
    rowI.numFmt = "0.0%";
    let rowJ = memberSheet.getCell(`J${i}`);
    rowJ.numFmt = "0.0%";
    let rowK = memberSheet.getCell(`K${i}`);
    rowK.numFmt = "0.0%";

    if (memberSheet.getCell(`A${i}`).value === "SubTotal") {
      let tableRow = memberSheet.getRow(i); // +1 because Excel rows are 1-indexed.

      // Apply bold and grey color to all cells in the row.
      tableRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FF808080" } }; // Grey color in ARGB format.
        cell.border = {
          top: { style: "thin" },
        };
      });
    }
  }
  memberSheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => (v ? v.toString().length : 0));

    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength > 7 ? maxLength : 7;
  });
}

async function getGuestListSheet(wb, event, orgMembers, orgName, orgID, index) {
  let newSheetName = `Guest List ${
    event.name.length <= 19 ? event.name : event.name.substring(0, 18)
  }`;
  //check if worksheet name is already in the wb
  let count = 1;
  
  while (wb._worksheets.some((ws) => ws && ws.name === newSheetName)) {
    count++;
    newSheetName = `Guest List ${
      event.name.length <= 17 ? event.name : event.name.substring(0, 16)
    } ${count}`;
  }

  const guestListSheet = wb.addWorksheet(newSheetName);
  const guestInvites = await DataStore.query(GuestInvite, (g) =>
    g.eventID.eq(event.id)
  );
  //   const invites = await DataStore.query(Invite, (i) => i.eventID.eq(event.id));

  addHeader(guestListSheet, `Guest List for ${event.name}`, orgName);

  const totalInvited = sum(event.analytics.categoryData, "invitesGiven");
  const validScans = sum(event.analytics.categoryData, "validScans");
  const invalidScans = sum(event.analytics.categoryData, "invalidScans");
  const unaccountedData = {
    member: "Data Inconsistencies",
    maxUses: totalInvited - sum(guestInvites, "maxUses"),
    category: "N/A",
    numUsed: validScans - sum(guestInvites, "numUsed"),
    numInvalid: invalidScans - sum(guestInvites, "numInvalid"),
  };
  let subTotalsMap = new Map();

  for (let i = 0; i < guestInvites.length; i++) {
    //TODO flesh this out a bit more
    if (guestInvites[i].orgMemberID) {
      let tempKey =
        guestInvites[i].orgMemberID + "/" + guestInvites[i].category;

      if (subTotalsMap.has(tempKey)) {
        subTotalsMap.set(tempKey, {
          ...subTotalsMap.get(tempKey),
          maxUses: subTotalsMap.get(tempKey).maxUses + guestInvites[i].maxUses,
          cost: subTotalsMap.get(tempKey).cost + guestInvites[i].cost,
          numUsed: subTotalsMap.get(tempKey).numUsed + guestInvites[i].numUsed,
          numInvalid:
            subTotalsMap.get(tempKey).numInvalid + guestInvites[i].numInvalid,
        });
      } else {
        if (guestInvites[i].orgMemberID === orgID) {
          subTotalsMap.set(tempKey, {
            maxUses: guestInvites[i].maxUses,
            cost: guestInvites[i].cost,
            member: "SubTotal",
            category: guestInvites[i].category,
            numUsed: guestInvites[i].numUsed,
            numInvalid: guestInvites[i].numInvalid,
            orgMemberID: guestInvites[i].orgMemberID,
          });
        } else {
          subTotalsMap.set(tempKey, {
            maxUses: guestInvites[i].maxUses,
            cost: guestInvites[i].cost,
            member: "SubTotal",
            category: guestInvites[i].category,
            numUsed: guestInvites[i].numUsed,
            numInvalid: guestInvites[i].numInvalid,
            orgMemberID: guestInvites[i].orgMemberID,
          });
        }
      }
    }
  }

  let tempGuests = Array.from(subTotalsMap.values());
  const finalData = [...guestInvites, ...tempGuests].sort((a, b) => {
    // Compare by orgMemberID
    const memberCompare = a.orgMemberID.localeCompare(b.orgMemberID);

    if (memberCompare !== 0) {
      // If orgMemberIDs are not equal, use this comparison result
      return memberCompare;
    } else {
      // If orgMemberIDs are equal, compare by category
      return a.category.localeCompare(b.category);
    }
  });

  const totalRow = [
    "Total",
    totalInvited,
    "",
    validScans + invalidScans,
    validScans,
    invalidScans,
    parseFloat(
      totalInvited !== 0 ? (validScans / totalInvited).toFixed(3) : 0
    ) || 0,
    parseFloat(
      totalInvited !== 0 ? (invalidScans / totalInvited).toFixed(3) : 0
    ) || 0,
    calculateFees(sum(guestInvites, "cost") * 100),
  ];

  const rows =
    unaccountedData.count || unaccountedData.used || unaccountedData.numInvalid
      ? [...finalData, unaccountedData]
      : [...finalData];

  const categoryRows = (
    await Promise.all(
      event.categories.map(async (category) => {
        return Promise.all(
          category.limits.map(async (limit) => {
            const role = limit.role;
            // Check if the role parameter is a UUID
            const isUUID =
              /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/.test(
                role
              );
            let organization;
            if (isUUID) {
              organization = await DataStore.query(Organization, role);
            }

            // If the role is a UUID, omit it from the row
            if (isUUID) {
              return [
                category.category,
                organization.name,
                limit.limit === "-1" ? "No Limit" : parseInt(limit.limit),
              ];
            } else {
              return [
                category.category,
                role,
                limit.limit === "-1" ? "No Limit" : parseInt(limit.limit),
              ];
            }
          })
        );
      })
    )
  ).flat();

  if (categoryRows.length)
    guestListSheet.addTable({
      name: `LimitsTable${index}`,
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium5",
        showRowStripes: true,
      },
      columns: [
        { name: "Category Name" },
        { name: "Group Name" },
        { name: "Limit" },
      ],
      rows: categoryRows,
    });

  guestListSheet.addTable({
    name: `GuestListTable${index}`,
    ref: `A${5 + categoryRows.length + 2}`,
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium5",
      showRowStripes: true,
    },
    columns: [
      { name: "Member" },
      { name: "Number Invited" },
      { name: "Category" },
      { name: "Total Scans" },
      { name: "Valid Scans" },
      { name: "Invalid Scans" },
      { name: "Valid Scan Rate" },
      { name: "Invalid Scan Rate" },
      { name: "Revenue", totalsRowFunction: "sum" },
    ],
    rows: rows.map((guest) => [
      guest.member ||
        orgMembers.find((m) => m.userID === guest.orgMemberID)?.memberInfo[0] ||
        orgName,
      guest.maxUses,
      guest.category,
      guest.numUsed + guest.numInvalid || 0,
      guest.numUsed || 0,
      guest.numInvalid || 0,
      parseFloat(
        guest.maxUses !== 0 ? (guest.numUsed / guest.maxUses).toFixed(3) : 0
      ) || 0,
      parseFloat(
        guest.maxUses !== 0 ? (guest.numInvalid / guest.maxUses).toFixed(3) : 0
      ) || 0,
      calculateFees(guest.cost * 100) || 0,
    ]),
  });
  guestListSheet.addRow(totalRow);
  guestListSheet.views = [
    {
      showGridLines: false,
    },
  ];

  for (
    let i = 5 + categoryRows.length + 3;
    i < rows.length + 5 + categoryRows.length + 3;
    i++
  ) {
    let rowG = guestListSheet.getCell(`G${i}`);
    rowG.numFmt = "0.0%";
    let rowH = guestListSheet.getCell(`H${i}`);
    rowH.numFmt = "0.0%";
    let rowI = guestListSheet.getCell(`I${i}`);
    rowI.numFmt = "$#,##0.00";
    // Check if this is a subtotal row.
    if (guestListSheet.getCell(`A${i}`).value === "SubTotal") {
      let tableRow = guestListSheet.getRow(i); // +1 because Excel rows are 1-indexed.

      // Apply bold and grey color to all cells in the row.
      tableRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FF808080" } }; // Grey color in ARGB format.
        cell.border = {
          top: { style: "thin" },
        };
      });
    }
    if (guestListSheet.getCell(`A${i}`).value === "Total") {
      let tableRow = guestListSheet.getRow(i); // +1 because Excel rows are 1-indexed.

      // Apply bold and grey color to all cells in the row.
      tableRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.border = {
          top: { style: "thick" },
        };
      });
    }
  }
  guestListSheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => (v ? v.toString().length : 0));

    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength > 7 ? maxLength : 7;
  });
}

export function addHeader(ws, headerText, orgName) {
  ws.mergeCells("A1:B3");
  let cellA1 = ws.getCell("A1");
  cellA1.value = {
    richText: [
      { text: "CLI", font: { name: "Poppins", size: 25, bold: true } },
      {
        text: "Ω",
        font: {
          name: "Arial",
          size: 25,
          color: { argb: "FFA020F0" },
          bold: true,
        },
      },
    ],
  };
  cellA1.alignment = { vertical: "bottom", horizontal: "center" };

  ws.mergeCells("C1:E2");
  let cellC1 = ws.getCell("C1");
  cellC1.value = orgName;
  cellC1.font = { size: 20, bold: true };
  cellC1.alignment = { vertical: "middle", horizontal: "left" };

  ws.mergeCells("C3:E3");
  let cellC3 = ws.getCell("C3");
  cellC3.value = headerText;
  cellC3.font = { size: 16, italic: true };
}
