import { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { calculateSubscriptionCosts } from "../../methods/calculateSubscriptionCosts";
import axios from "axios";
import { useAuthContext } from "../../contexts/AuthContext";
import { ExpandMore } from "@mui/icons-material";

const ChangePlanType = ({
  cost,
  plan,
  setSnackbarOpen,
  close,
  modalOptions,
}) => {
  const { authUser } = useAuthContext();
  const [selectedPlan, setSelectedPlan] = useState("");

  const initialPlan = plan?.planType;

  useEffect(() => {
    setSelectedPlan(
      modalOptions === "upgrade_plan"
        ? initialPlan === "Pro"
          ? "Premium"
          : "VIP"
        : initialPlan
    );
  }, [initialPlan]);

  const { totalCost } = calculateSubscriptionCosts({
    billingCycle:
      plan.billingCycle.charAt(0).toUpperCase() + plan.billingCycle.slice(1),
    totalMembers: plan.totalMembers,
    planType: selectedPlan || plan.planType,
  });

  const prorationFactor =
    (plan.dateRenewalRequired - Date.now()) /
    (plan.dateRenewalRequired - Math.floor(new Date(plan.createdAt)));

  const handleUpdateSubscription = async () => {
    if (selectedPlan === initialPlan) {
      setSnackbarOpen({
        type: "error",
        message: "You must change the number of members to update your plan.",
      });
      return;
    } else {
      const token = authUser?.signInUserSession.idToken.jwtToken;
      try {
        setSnackbarOpen({
          type: "info",
          message: "Updating subscription plan",
        });
        const res = await axios.put(
          process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
          {
            type: "updateSubscription",
            subscriptionID: plan.stripeSubscriptionID,
            newSubscriptionParams: {
              totalMembers: plan.totalMembers,
              planType: selectedPlan,
              billingCycle: plan.billingCycle,
            },
            test: process.env.NODE_ENV !== "production",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSnackbarOpen({
          type: "success",
          message: "Successfully updated subscription plan",
        });
        close();
      } catch (err) {
        console.log(err);
        setSnackbarOpen({
          type: "error",
          message:
            "Failed to create payment method. Please try again, and if the issue persists, please contact support.",
        });
      }
    }
  };

  const handleConfirmPress = () => {
    setSnackbarOpen({
      type: "info",
      message:
        "Are you sure you want to update your organization's subscription plan?",
      action: (
        <Button size="small" color="inherit" onClick={handleUpdateSubscription}>
          Confirm
        </Button>
      ),
    });
  };

  const planData = [
    {
      name: "Pro",
      features: [
        "3 events per month",
        "Paid events",
        "3 contact lists per organization",
        "Event analytics",
        "Co-hosting",
      ],
      boxStyle: { border: "2px #003366 solid" },
      checkColor: "#003366",
    },
    {
      name: "Premium",
      popular: true,
      features: [
        "All Pro features",
        "Unlimited events",
        "Unlimited contact lists",
        "Comprehensive member analytics",
        "Semesterly and custom reports",
        "Custom contact lists presentations",
        "ID-verified events",
      ],
      boxStyle: { border: "2px #502aaa solid" },
      checkColor: "#502aaa",
    },
    {
      name: "VIP",
      features: [
        "All premium features",
        "Downloadable event reports",
        "Search prioritization",
        "Support prioritization",
        "VIP-Verified badge",
        "Custom themes",
      ],
      boxStyle: { border: `2px ${colors.primaryColor} solid` },
      checkColor: colors.primaryColor,
    },
  ];

  const isUpgrade =
    planData.findIndex((plan) => selectedPlan === plan.name) >
    planData.findIndex((data) => plan.planType === data.name);

  return (
    <Box sx={{ pt: 1 }}>
      <Grid container rowSpacing={1}>
        {planData.map((data) => (
          <Grid item xs={12}>
            <Box
              sx={{
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                ...data.boxStyle,
              }}
            >
              <Accordion sx={{ bgcolor: "transparent", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMore sx={{ color: data.checkColor }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "left",
                        flex: 1,
                        ...data.headerStyle,
                      }}
                    >
                      {data.name}
                    </Typography>
                    <Radio
                      checked={selectedPlan === data.name}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPlan(data.name);
                      }}
                    ></Radio>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {data.features.map((text) => (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <p style={{ margin: 0, color: colors.primaryText }}>
                        <b style={{ color: data.checkColor }}>✓</b>&nbsp;&nbsp;
                        {text}
                      </p>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        ))}
      </Grid>
      {selectedPlan !== plan.planType ? (
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mt: 2 }}>
            <Typography variant="body2" sx={{ fontSize: "1em" }}>
              Current subscription price:
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1em" }}>
              ${cost / 100}
            </Typography>
          </Box>
          {isUpgrade ? (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 0.5, mt: 2 }}
            >
              <Typography variant="body2" sx={{ fontSize: "1em" }}>
                Change in price this period:
              </Typography>
              <Typography variant="h3" sx={{ fontSize: "1em" }}>
                $
                {parseInt(
                  (totalCost - cost) * prorationFactor * plan.promoFactor
                ) / 100}
                *
              </Typography>
            </Box>
          ) : null}
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography variant="body2" sx={{ fontSize: "1em" }}>
              New subscription cost:
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1em" }}>
              ${parseInt(totalCost * plan.promoFactor) / 100}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontSize: ".7em", mt: 2 }}>
            {isUpgrade
              ? "Plan changes will take effect immediately and additional charges will be prorated."
              : "Plan changes will take effect at the start of the next billing cycle."}
          </Typography>
        </>
      ) : null}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button sx={{ color: colors.secondaryText }} onClick={close}>
          Close
        </Button>
        <Button onClick={handleConfirmPress}>Confirm</Button>
      </Box>
    </Box>
  );
};

export default ChangePlanType;
