import { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import { Box, Button, Slider, TextField, Typography } from "@mui/material";
import { calculateSubscriptionCosts } from "../../methods/calculateSubscriptionCosts";
import axios from "axios";
import { useAuthContext } from "../../contexts/AuthContext";
import { DataStore } from "aws-amplify";
import { OrganizationMember } from "../../models";

const AdjustNumMembers = ({
  initialMembers,
  cost,
  plan,
  setSnackbarOpen,
  close,
}) => {
  const { authUser, dbUser } = useAuthContext();
  const [totalMembers, setTotalMembers] = useState(0);

  useEffect(() => {
    setTotalMembers(initialMembers);
  }, [initialMembers]);

  const { totalCost } = calculateSubscriptionCosts({
    billingCycle:
      plan.billingCycle.charAt(0).toUpperCase() + plan.billingCycle.slice(1),
    totalMembers,
    planType: plan.planType,
  });

  const prorationFactor =
    (plan.dateRenewalRequired - Date.now()) /
    (plan.dateRenewalRequired - Math.floor(new Date(plan.createdAt)));

  const handleUpdateSubscription = async () => {
    if (totalMembers === initialMembers) {
      setSnackbarOpen({
        type: "error",
        message: "You must change the number of members to update your plan.",
      });
      return;
    } else {
      if (totalMembers < initialMembers) {
        const confirmedOrganizationMembers = await DataStore.query(
          OrganizationMember,
          (o) =>
            o.and((o) => [
              o.isConfirmed.eq(true),
              o.organizationID.eq(dbUser.id),
            ])
        );
        if (confirmedOrganizationMembers.length > totalMembers) {
          setSnackbarOpen({
            type: "error",
            message:
              "You cannot reduce the number of member subscriptions below the number of members in your organization.",
          });
          return;
        }
      }
      const token = authUser?.signInUserSession.idToken.jwtToken;
      try {
        setSnackbarOpen({
          type: "info",
          message: "Updating subscription plan",
        });
        const res = await axios.put(
          process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
          {
            type: "updateSubscription",
            subscriptionID: plan.stripeSubscriptionID,
            newSubscriptionParams: {
              totalMembers,
              planType: plan.planType,
              billingCycle: plan.billingCycle,
            },
            test: process.env.NODE_ENV !== "production",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSnackbarOpen({
          type: "success",
          message: "Successfully updated subscription plan",
        });
        close();
      } catch (err) {
        console.log(err);
        setSnackbarOpen({
          type: "error",
          message:
            "Failed to create payment method. Please try again, and if the issue persists, please contact support.",
        });
      }
    }
  };

  const handleConfirmPress = () => {
    setSnackbarOpen({
      type: "info",
      message:
        "Are you sure you want to update your organization's subscription plan?",
      action: (
        <Button size="small" color="inherit" onClick={handleUpdateSubscription}>
          Confirm
        </Button>
      ),
    });
  };

  return (
    <Box sx={{ pt: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        <Typography variant="body2" sx={{ fontSize: "1em" }}>
          Current member subscriptions allocated:
        </Typography>
        <Typography variant="h3" sx={{ fontSize: "1em" }}>
          {initialMembers}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Slider
          value={typeof totalMembers === "number" ? totalMembers : 1}
          onChange={(event, newValue) => setTotalMembers(newValue)}
          valueLabelDisplay="auto"
          step={1}
          max={500}
          min={1}
          sx={{
            "& .MuiSlider-valueLabel": {
              fontSize: "1em",
              fontWeight: "normal",
              top: -6,
              backgroundColor: "unset",
              color: colors.primaryText,
              fontFamily: "Poppins",
              "&:before": {
                display: "none",
              },
              "& *": {
                background: "transparent",
                color: colors.primaryText,
              },
            },
            flex: 2.5,
          }}
        />
        <TextField
          value={totalMembers}
          size="small"
          onChange={(event) => setTotalMembers(parseInt(event.target.value))}
          onBlur={() => {
            if (totalMembers < 0) {
              setTotalMembers(0);
            }
          }}
          inputProps={{
            step: 1,
            min: 0,
            max: 10000,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
          sx={{
            bgcolor: colors.backgroundHighlight,
            borderRadius: 1,
            flex: 1,
          }}
          fullWidth
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mt: 2 }}>
        <Typography variant="body2" sx={{ fontSize: "1em" }}>
          Current subscription price:
        </Typography>
        <Typography variant="h3" sx={{ fontSize: "1em" }}>
          ${cost / 100}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mt: 2 }}>
        <Typography variant="body2" sx={{ fontSize: "1em" }}>
          Change in price this period:
        </Typography>
        <Typography variant="h3" sx={{ fontSize: "1em" }}>
          $
          {parseInt((totalCost - cost) * prorationFactor * plan.promoFactor) /
            100}
          *
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        <Typography variant="body2" sx={{ fontSize: "1em" }}>
          New subscription cost:
        </Typography>
        <Typography variant="h3" sx={{ fontSize: "1em" }}>
          ${parseInt(totalCost * plan.promoFactor) / 100}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ fontSize: ".7em", mt: 2 }}>
        *If you adjust the number of supported members, the charges will be
        prorated based on the number of days left in the cycle at the moment
        when the adjustment is made. You will be billed the proration adjustment
        with your payment next billing cycle.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button sx={{ color: colors.secondaryText }} onClick={close}>
          Close
        </Button>
        <Button onClick={handleConfirmPress}>Confirm</Button>
      </Box>
    </Box>
  );
};

export default AdjustNumMembers;
