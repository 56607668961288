import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  HomeOutlined,
  GroupOutlined,
  ListAltOutlined,
  AccountBalanceOutlined,
  AssessmentOutlined,
  SettingsOutlined,
  HelpOutline,
  Circle,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { colors } from "../theme/colors";
import { useAuthContext } from "../contexts/AuthContext";
import ProfileImage from "./ProfileImage";
import { Auth, DataStore } from "aws-amplify";
import HelpDialog from "../pages/help/HelpDialog";
import { OrganizationMember } from "../models";

const WebPortalNavigation = ({ activeScreen, shouldNotCollapse }) => {
  const navigate = useNavigate();
  const { dbUser } = useAuthContext();
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [membersNotificationVisible, setMembersNotificationVisible] =
    useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!shouldNotCollapse) {
        setCollapsed(true);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const fetchNumPendingMembers = async () => {
      const pendingMembers = await DataStore.query(OrganizationMember, (m) =>
        m.and((m) => [m.organizationID.eq(dbUser?.id), m.isConfirmed.eq(false)])
      );
      setMembersNotificationVisible(pendingMembers.length > 0);
    };
    if (dbUser?.id) {
      fetchNumPendingMembers();
      const pendingMemberSubscription = DataStore.observe(
        OrganizationMember,
        (m) =>
          m.and((m) => [
            m.organizationID.eq(dbUser?.id),
            m.isConfirmed.eq(false),
          ])
      ).subscribe((msg) => {
        fetchNumPendingMembers();
      });
      return () => pendingMemberSubscription.unsubscribe();
    }
  }, [dbUser?.id]);

  const buttons = [
    "Edit Profile",
    "Change Email",
    "Billing",
    "Sign Out",
    "Delete Account",
    "Cancel",
  ];
  const handleSettingsItemClick = async (button) => {
    switch (button) {
      case "Sign Out": {
        Auth.signOut();
        navigate("/");
        break;
      }
      case "Change Email": {
        try {
          await Auth.verifyCurrentUserAttribute("email");
          navigate("/change_email");
        } catch (err) {
          alert(err.message || err);
        }
        break;
      }

      case "Delete Account": {
        Auth.verifyCurrentUserAttribute("email");
        navigate("/dashboard/delete");
        break;
      }
      case "Billing": {
        navigate("/dashboard/billing");
        break;
      }
      case "Edit Profile": {
        navigate("/dashboard/edit");
        break;
      }
      case "Cancel": {
        setSettingsDialogOpen(false);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleLinkClick = (path) => {
    navigate(path);
  };

  return (
    <Box
      className={`web-portal-navigation ${collapsed ? "collapsed" : ""}`}
      sx={{
        position: "fixed",
        height: "100svh", // make the sidebar span the entire height of the page
        backgroundColor: colors.secondaryBackground, // set the background color of the sidebar
        padding: "1em",
        display: "flex",
        flexDirection: "column",
        alignItems: collapsed ? "center" : undefined,
        transition: "all 500ms ease-in-out",
      }}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => {
        if (!shouldNotCollapse) setCollapsed(true);
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
          transition: "opacity 500ms ease-in-out",
          ":hover": {
            opacity: 0.6,
          },
          marginBottom: "1em",
        }}
        onClick={() => handleLinkClick("/dashboard")}
      >
        {collapsed ? (
          <>
            <style>
              @import
              url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
            </style>
            <span
              style={{
                color: "#ffffff",
                fontSize: "3em",
                fontWeight: "600",
                fontFamily: "Inter",
                color: colors.primaryColor,
                textAlign: "center",
              }}
            >
              Ω
            </span>
            <p
              style={{
                margin: 0,
                color: "transparent",
                marginTop: "0em",
              }}
            >
              W
            </p>
          </>
        ) : (
          <>
            <img
              className="web-portal-navigation-logo"
              src={require("../assets/CLIQLogoTransparent.png")}
              style={{ height: "3em" }}
            />
            <p
              className="web-portal-navigation-text"
              style={{
                margin: 0,
                color: colors.secondaryText,
                marginTop: "-.75em",
                marginLeft: "2.5em",
              }}
            >
              Web Portal
            </p>
          </>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: collapsed
            ? "transparent"
            : `${colors.primaryColor}22`,
          display: "flex",
          alignItems: "center",
          borderRadius: 1,
          padding: collapsed ? "0em" : ".25em .5em",
          gap: ".5em",
          cursor: "pointer",
          transition: "opacity 500ms ease-in-out",
          ":hover": {
            opacity: 0.6,
          },
          mb: 1,
        }}
        onClick={() => {
          dbUser ? handleLinkClick("/dashboard") : handleLinkClick("/login");
        }}
      >
        {!dbUser ? (
          <AccountCircleIcon color="primary" style={{ fontSize: "2em" }} />
        ) : (
          <ProfileImage height="2em" pic={dbUser.profilePic} />
        )}
        {collapsed ? null : (
          <p
            style={{ margin: 0, fontSize: ".75em" }}
            className="web-portal-navigation-text"
          >
            {!dbUser ? "Login" : dbUser.name.substring(0, 20)}
          </p>
        )}
      </Box>
      <List>
        {[
          {
            text: "Dashboard",
            icon: <HomeOutlined color="primary" />,
            path: "/dashboard",
          },
          {
            text: "Members",
            icon: <GroupOutlined color="primary" />,
            notification: membersNotificationVisible,
            path: "/members",
          },
          {
            text: "Contacts",
            icon: <ListAltOutlined color="primary" />,
            path: "/lists",
          },
          {
            text: "Wallet",
            icon: <AccountBalanceOutlined color="primary" />,
            path: "/wallet",
          },
          {
            text: "Reports",
            icon: <AssessmentOutlined color="primary" />,
            path: "/reports",
          },
        ].map((item, index) => (
          <ListItem
            disableGutters
            key={index}
            onClick={() => handleLinkClick(item.path)}
            sx={{
              color: colors.primaryText,
              backgroundColor:
                item.path === activeScreen && !collapsed
                  ? colors.defaultBackground
                  : "transparent",
              borderRadius: 1,
              px: collapsed ? 0 : ".5em",
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#030303",
              },
            }}
          >
            {item.icon}
            {collapsed ? null : (
              <>
                <p
                  style={{ margin: 0, marginLeft: ".5em", flex: 1 }}
                  className="web-portal-navigation-text"
                >
                  {item.text}
                </p>
                {item.notification ? (
                  <Circle color="secondary" sx={{ fontSize: ".5em" }} />
                ) : null}
              </>
            )}
          </ListItem>
        ))}
      </List>
      <List sx={{ mt: "auto", mb: "2em" }}>
        {[
          {
            text: "Settings",
            icon: <SettingsOutlined color="primary" />,
          },
          {
            text: "Help",
            icon: <HelpOutline color="primary" />,
          },
        ].map((item, index) => (
          <ListItem
            disableGutters
            key={index}
            onClick={() => {
              item.text === "Settings"
                ? setSettingsDialogOpen(true)
                : setHelpDialogOpen(true);
            }}
            sx={{
              color: colors.primaryText,

              borderRadius: 1,
              px: collapsed ? 0 : ".5em",
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#030303",
              },
            }}
          >
            {item.icon}
            {collapsed ? null : (
              <p
                style={{ margin: 0, marginLeft: ".5em" }}
                className="web-portal-navigation-text"
              >
                {item.text}
              </p>
            )}
          </ListItem>
        ))}
      </List>
      <Dialog
        onClose={() => setSettingsDialogOpen(false)}
        open={settingsDialogOpen}
      >
        <DialogTitle>Account Settings</DialogTitle>
        <DialogContent>
          <List sx={{ pt: 0 }} dense>
            {buttons.map((button) => (
              <ListItem
                disableGutters
                key={button}
                onClick={() => handleSettingsItemClick(button)}
              >
                <ListItemText
                  sx={{
                    fontFamily: "Poppins",
                    cursor: "pointer",
                    transition: "opacity 200ms ease-in-out",
                    ":hover": {
                      opacity: 0.4,
                    },
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      color:
                        button === "Delete Account"
                          ? colors.errorColor
                          : "white",
                      fontWeight: button === `Cancel` ? 500 : 400,
                      fontSize: "1.1em",
                    }}
                  >
                    {button}
                  </p>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <HelpDialog
        helpDialogOpen={helpDialogOpen}
        setHelpDialogOpen={setHelpDialogOpen}
      />
    </Box>
  );
};

export default WebPortalNavigation;
