import { Box, Grid, Skeleton } from "@mui/material";
import { colors } from "../../theme/colors";
import PayoutModule from "./PayoutModule";

const PayoutsModule = ({ loading, payouts }) => {
  const renderItem = (item) => <PayoutModule payout={item} key={item.id} />;

  return (
    <Box
      className="unstyled-card"
      style={{ flex: 1, backgroundColor: colors.secondaryBackground }}
      sx={{
        borderRadius: 2,
        mb: ".5em",
        p: ".5em 1em",
      }}
    >
      <Box sx={{ display: "flex", flex: 1, alignItems: "flex-start", gap: 2 }}>
        <Box sx={{ flex: 1 }}>
          {loading ? (
            <Skeleton sx={{ fontSize: "1.2em" }} />
          ) : (
            <h1 style={{ fontSize: "1.2em", margin: 0 }}>Payouts</h1>
          )}
          {loading ? (
            <Skeleton sx={{ fontSize: ".8em", mb: 1 }} />
          ) : (
            <p
              style={{
                margin: 0,
                color: colors.secondaryText,
                fontSize: ".8em",
                fontWeight: 500,
              }}
            >
              Payouts are processed within 3-5 business days.
              <b
                className="link"
                style={{ color: colors.primaryText, fontWeight: 600 }}
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=nxqoSCgaoNs&list=PLCoNOX4-Q2eWCZLF666Z6vgFZ_dMtAGIA&index=12",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Learn More
              </b>
            </p>
          )}
        </Box>
      </Box>
      {loading ? null : (
        <Grid
          container
          alignItems="center"
          columns={10}
          sx={{
            px: 1,
            py: 0.25,
            mb: 1,
            mt: 2,
            borderRadius: 1,
            backgroundColor: colors.backgroundHighlight,
          }}
        >
          <Grid item xs={4}>
            <p
              style={{
                fontSize: ".85em",
                color: colors.secondaryText,
                margin: 0,
                textAlign: "center",
              }}
            >
              Date
            </p>
          </Grid>
          <Grid item xs={3}>
            <p
              style={{
                fontSize: ".85em",
                color: colors.secondaryText,
                margin: 0,
                textAlign: "center",
              }}
            >
              Amount
            </p>
          </Grid>
          <Grid item xs={3}>
            <p
              style={{
                fontSize: ".85em",
                color: colors.secondaryText,
                margin: 0,
                textAlign: "center",
              }}
            >
              Fulfilled
            </p>
          </Grid>
        </Grid>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "6em", mb: 1 }} />
      ) : payouts?.length ? (
        payouts?.map(renderItem)
      ) : (
        <p
          style={{
            alignSelf: "flex-start",
            fontSize: ".8em",
            margin: 0,
            fontWeight: 500,
            textAlign: "left",
            color: colors.secondaryText,
          }}
        >
          You must have requested a payout for you to view and interact with
          them.
        </p>
      )}
    </Box>
  );
};

export default PayoutsModule;
