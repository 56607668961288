import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Modal,
  Typography,
} from "@mui/material";
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { Event } from "../../models";
import LimitModule from "./LimitModule";
import { Check } from "@mui/icons-material";

const CategoryLimitModal = ({ open, close, role, event }) => {
  const [renderedCategories, setRenderedCategories] = useState([]); //specific to role
  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    let tempInitialLimits = [];

    event.categories.forEach((category) => {
      let roleIncluded = false;
      category.limits.forEach((limit) => {
        if (limit.role === role) {
          //if the limit corresponds with the role
          tempInitialLimits.push({
            role: limit.role,
            category: category.category,
            limit: limit.limit,
          }); //initialize based on the already existing limits
          roleIncluded = true; //if the role is included in the category
        }
      });
      if (!roleIncluded) {
        tempInitialLimits.push({
          role: role,
          category: category.category,
          limit: "-1", //global limit if not recognized
        });
      }
    });

    setRenderedCategories(tempInitialLimits);
  }, [event.categories, role]);

  const handleDonePress = () => {
    let tempCategories = [];
    //for each of the categories in the event

    DataStore.query(Event, event.id).then(async (event) => {
      event.categories.forEach((category) => {
        let tempLimits = [];
        let newLimit;
        //find updated limit for this role in renderedCategories
        let tempIndex = renderedCategories.findIndex(
          (limit) => limit.category === category.category
        );
        newLimit = renderedCategories[tempIndex].limit;
        //for each of the limits in the categories
        let roleIncluded = false;
        category.limits.forEach((limit) => {
          //if the limit is for the role we are editing, set to new limit
          if (limit.role === role) {
            roleIncluded = true;
          }
          if (limit.role === role && limit.limit !== newLimit) {
            tempLimits.push({
              role: role,
              limit: newLimit === "" ? "-1" : newLimit,
            });
          } else {
            tempLimits.push(limit);
          }
        });
        if (!roleIncluded) {
          tempLimits.push({
            role: role,
            limit: newLimit === "" ? "-1" : newLimit,
          });
        }
        //push the new limits for that category
        tempCategories.push({
          category: category.category,
          limits: tempLimits,
        });
      });

      await DataStore.save(
        Event.copyOf(event, (updated) => {
          updated.categories = tempCategories;
        })
      );

      close();
    });
  };

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box>
            <Typography variant="h1" sx={{ fontSize: "1.2em" }}>
              Category Limits
            </Typography>
            <Typography variant="body2">for {role}</Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{ fontFamily: "Poppins" }}
            startIcon={<Check sx={{ color: "white" }} />}
            onClick={handleDonePress}
          >
            Save
          </Button>
        </Box>
        <Box>
          {renderedCategories.map((category, index) => {
            return (
              <LimitModule
                key={index.toString()}
                item={category}
                index={index}
                setReRender={setReRender}
                setRenderedCategories={setRenderedCategories}
              />
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryLimitModal;
