import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  IconButton,
  Button,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { getModuleDate } from "../../../methods/getFormattedTime";
import { downloadInsightsData } from "../../../methods/downloadInsightsData";
import WebPortalNavigation from "../../../components/WebPortalNavigation";
import ReportInfoDisplay from "./ReportInfoDisplay";
import { colors } from "../../../theme/colors";
import HelpDialog from "../../../pages/help/HelpDialog";
import { Helmet } from "react-helmet-async";

const Reports = () => {
  const [reportPeriods, setReportPeriods] = useState(null);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const { dbUser } = useAuthContext();
  const navigate = useNavigate();

  const downloadReport = (period) => {
    const formattedUnixPeriod = {
      startDate: Math.floor(period.start).toString(),
      endDate: Math.floor(period.end).toString(),
    };
    downloadInsightsData(dbUser.id, dbUser.name, dbUser, formattedUnixPeriod);
  };

  useEffect(() => {
    if (dbUser) {
      const accountCreationDate = new Date(dbUser.createdAt);
      const currentDate = new Date();
      const tempReportPeriods = [];

      const accountCreationYear = accountCreationDate.getFullYear();
      const currentYear = currentDate.getFullYear();

      for (let year = accountCreationYear; year <= currentYear; year++) {
        const sem1StartDate = new Date(year, 0, 1); // Jan 1
        const sem1EndDate = new Date(year, 5, 30); // June 30
        const sem2StartDate = new Date(year, 6, 1); // July 1
        const sem2EndDate = new Date(year, 11, 31); // Dec 31

        // Considering only periods after the account creation and before the current date
        if (accountCreationDate < sem1EndDate && sem1EndDate < currentDate) {
          tempReportPeriods.push({
            start:
              sem1StartDate > accountCreationDate
                ? sem1StartDate
                : accountCreationDate,
            end: sem1EndDate,
            title: `Spring Report ${year}`,
          });
        }

        if (accountCreationDate < sem2EndDate && sem2EndDate < currentDate) {
          tempReportPeriods.push({
            start:
              sem2StartDate > accountCreationDate
                ? sem2StartDate
                : accountCreationDate,
            end: sem2EndDate,
            title: `Fall Report ${year}`,
          });
        }

        // Adding the yearly report only if the year is completed
        if (year < currentYear) {
          tempReportPeriods.push({
            start:
              sem1StartDate > accountCreationDate
                ? sem1StartDate
                : accountCreationDate,
            end: sem2EndDate,
            title: `Yearly Report ${year}`,
          });
        }
      }
      setReportPeriods(tempReportPeriods.reverse() || []);
    }
  }, [dbUser?.id]);

  const loading = !dbUser || !reportPeriods;

  const pageTitle = `Reports • CLIQInvite`;
  const pageDescription = `View and download your organization's available reports, which span all of your CLIQInvite events, including guest lists, revenue, payouts, and even member progress over time.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/reports`;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <WebPortalNavigation activeScreen="/reports" shouldNotCollapse />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta
          name="description"
          property="og:description"
          content={pageDescription}
        />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta
          name="keywords"
          content="Event Management, Fraternity Management"
        />
      </Helmet>
      <Grid
        container
        className="page-size-medium should-not-collapse"
        sx={{ alignSelf: "center" }}
        columnSpacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            pb: 2,
            pt: 4,
          }}
        >
          {loading ? (
            <Skeleton sx={{ fontSize: "2em", width: "100%" }} />
          ) : (
            <h1 style={{ fontSize: "2em", margin: 0 }}>Organization Reports</h1>
          )}
          {loading ? (
            <Skeleton sx={{ fontSize: "5em", width: "100%" }} />
          ) : (
            <p
              style={{
                marginTop: 0,
                textAlign: "center",
                color: "#CCCCCC",
                width: "75%",
                fontSize: ".9em",
              }}
            >
              Three reports are released per year, one for the period between
              July and December, one for the period between January and June,
              and one for the entire year. If you need a report earlier,{" "}
              <b
                className="link"
                style={{ transition: "all 500ms", margin: 0 }}
                onClick={() => navigate("/contact")}
              >
                contact us
              </b>
              .
            </p>
          )}
        </Grid>

        <Grid item xs={12} lg={5} sx={{ pb: "3em" }}>
          {loading ? (
            <Skeleton variant="rounded" sx={{ fontSize: "12em" }} />
          ) : !reportPeriods.length ? (
            <Box>
              <Typography variant="body2">No reports yet</Typography>
            </Box>
          ) : (
            reportPeriods.map((period) => (
              <Box
                sx={{
                  backgroundColor: colors.secondaryBackground,
                  boxShadow: `0px 0px 3px ${colors.secondaryBackground}`,
                  mb: 2,
                  color: "#fff",
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <h1 style={{ margin: 0, fontSize: "1.2em" }}>
                    {period.title}
                  </h1>
                  <p style={{ margin: 0, opacity: 0.6, fontSize: ".9em" }}>
                    {getModuleDate(Math.floor(period.start))} -{" "}
                    {getModuleDate(Math.floor(period.end))}
                  </p>
                </div>
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  onClick={() => downloadReport(period)}
                >
                  <Download sx={{ fontSize: "1.8em" }} color="primary" />
                </IconButton>
              </Box>
            ))
          )}
        </Grid>
        <Grid item xs={12} lg={7}>
          <HelpDialog
            preferredActiveScreen={"Request New Feature"}
            helpDialogOpen={helpDialogOpen}
            setHelpDialogOpen={setHelpDialogOpen}
          />
          <ReportInfoDisplay setHelpDialogOpen={setHelpDialogOpen} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports;
