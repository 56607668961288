import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { getModuleTime } from "../../../methods/getFormattedTime";
import { addHeader } from "../../../methods/downloadInsightsData";

const formatTimeLabel = (offset, event) => {
  return getModuleTime(parseInt(event.startDate) + offset);
};

export const handleExportJobs = async (displayedMemberJobs, event, orgName) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(event.name.split(" ").join(""));
  worksheet.views = [
    {
      showGridLines: false,
    },
  ];
  addHeader(worksheet, `${event.name} Job Schedule`, orgName);

  const groupedJobs = displayedMemberJobs.reduce((grouped, job) => {
    (grouped[job.jobName] = grouped[job.jobName] || []).push(job);
    return grouped;
  }, {});

  let jobs = [];

  Object.entries(groupedJobs).forEach(([jobName, jobArray]) => {
    let timeRow = [""];
    let memberRow = [jobName];
    jobArray
      .sort((a, b) => a.jobStart - b.jobStart)
      .forEach((job) => {
        const displayedTime = `${formatTimeLabel(job.jobStart * 60000, event)}`;
        timeRow.push(displayedTime);
        memberRow.push(job.name);
      });

    jobs.push(timeRow, memberRow, []); // Empty row between each job type
  });

  jobs.forEach((job) => {
    worksheet.addRow(job);
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${event.name.split(" ").join("")}Jobs.xlsx`);
  });
};

export const handleExportJobsTemplate = async (organizationMembers, event) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(event.name.split(" ").join(""));

  const timeInterval = 30 * 60 * 1000; // 30 minutes in ms
  const totalTime = event.endDate - event.startDate;
  const totalSlots = totalTime / timeInterval;

  let jobs = [];

  for (let i = 0; i < 5; i++) {
    let timeRow = [""];
    let memberRow = [`Job ${i + 1}`];

    for (let j = 0; j < totalSlots; j++) {
      const displayedTime = formatTimeLabel(j * timeInterval, event);

      timeRow.push(displayedTime);
      memberRow.push(
        organizationMembers[((i + 1) * (j + 1)) % organizationMembers.length]
          .memberName
      );
    }
    jobs.push(timeRow, memberRow, []); // Empty row between each job type
  }

  jobs.forEach((job) => {
    worksheet.addRow(job);
  });

  // Write the file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${event.name.split(" ").join("")}JobsTemplate.xlsx`);
  });
};
