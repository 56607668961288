import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { colors } from "../../theme/colors";
import {
  ArrowDropDown,
  Assignment,
  Remove,
  SelectAll,
} from "@mui/icons-material";
import { DataStore } from "aws-amplify";
import { OrganizationMember } from "../../models";
import useWindowSize from "../../hooks/useWindowSize";

const MemberDisplayHeader = ({
  members,
  selecting,
  setSelecting,
  selectedMembersQueue,
  setSnackbarOpen,
  setSelectedMembersQueue,
  selectedSortOption,
  setSelectedSortOption,
  handleAssignMembers,
}) => {
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const { width } = useWindowSize();

  const toggleSortDropdown = (event) => {
    setSortAnchorEl((old) => (!old ? event.currentTarget : null));
  };

  const sortButtons = ["First Name", "Last Name", "Group"];

  const handleListItemClick = (buttonIndex) => {
    setSelectedSortOption((old) =>
      buttonIndex === 0 ? "first" : buttonIndex === 1 ? "last" : "group"
    );
  };

  const handleRemoveMembers = async () => {
    setSnackbarOpen(null);
    let errorCount = 0;
    let toBeRemoved = selectedMembersQueue.size;
    let newQueue = new Set(selectedMembersQueue);
    for (const member of selectedMembersQueue) {
      try {
        await DataStore.delete(OrganizationMember, member);
        newQueue.delete(member);
      } catch (err) {
        errorCount++;
      }
    }
    setSelectedMembersQueue(newQueue);
    if (errorCount > 0) {
      setSnackbarOpen({
        type: "error",
        message: `Failed to delete ${errorCount} members. Please try again.`,
        action: (
          <Button
            onClick={() => setSnackbarOpen(null)}
            size="small"
            color="inherit"
          >
            Ok
          </Button>
        ),
      });
    } else {
      setSelecting(false);

      setSnackbarOpen({
        type: "success",
        message: `Successfully deleted ${toBeRemoved} member${
          toBeRemoved === 1 ? "" : "s"
        }.`,
        action: (
          <Button
            onClick={() => {
              setSnackbarOpen(null);
            }}
            size="small"
            color="inherit"
          >
            Ok
          </Button>
        ),
      });
    }
  };

  const displayedSortOption =
    selectedSortOption === "first"
      ? "First Name"
      : selectedSortOption === "last"
      ? "Last Name"
      : "Group";

  let loading = Boolean(!members);

  const ButtonsDisplay = () => (
    <>
      {!loading ? (
        <Box
          style={{
            display: "flex",
            gap: ".5em",
            alignItems: "center",
            ml: { xs: 0, lg: 3 },
          }}
        >
          <Button
            variant="contained"
            color={
              typeof selecting === "string"
                ? "primary"
                : selecting
                ? "error"
                : "primary"
            }
            onClick={async () => {
              if (!selecting) {
                setSelecting(true);
              } else {
                if (selectedMembersQueue.size === 0) {
                  setSelecting(false);
                } else if (typeof selecting === "string") {
                  setSnackbarOpen({
                    type: "success",
                    message: `Click confirm to assign ${
                      selectedMembersQueue.size
                    } member${
                      selectedMembersQueue.size === 1 ? "" : "s"
                    } to group ${selecting}.`,
                    action: (
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => handleAssignMembers(selecting)}
                      >
                        Confirm
                      </Button>
                    ),
                  });
                } else {
                  setSnackbarOpen({
                    type: "error",
                    message: `Click confirm to remove ${
                      selectedMembersQueue.size
                    } member${
                      selectedMembersQueue.size === 1 ? "" : "s"
                    }. This action is permanent and cannot be undone.`,
                    action: (
                      <Button
                        color="inherit"
                        size="small"
                        onClick={handleRemoveMembers}
                      >
                        Confirm
                      </Button>
                    ),
                  });
                }
              }
            }}
            size={width < 800 ? "small" : "medium"}
            startIcon={
              typeof selecting === "string" ? (
                <Assignment
                  sx={{
                    color: "white",
                  }}
                />
              ) : selecting ? (
                <Remove
                  sx={{
                    color: "white",
                  }}
                />
              ) : (
                <SelectAll
                  sx={{
                    color: "white",
                  }}
                />
              )
            }
            sx={{ fontSize: ".875em" }}
          >
            {typeof selecting === "string"
              ? `Assign ${selectedMembersQueue.size}`
              : selecting
              ? `Remove ${selectedMembersQueue.size}`
              : "Select"}{" "}
            Member{selecting && selectedMembersQueue.size === 1 ? "" : "s"}
          </Button>
          {selecting ? (
            <Button
              onClick={() => {
                if (selecting) {
                  setSelectedMembersQueue(new Set([]));
                  setSelecting(false);
                }
              }}
              sx={{
                fontFamily: "Poppins",
                color: colors.secondaryText,
                fontSize: ".875em",
              }}
              size={width < 800 ? "small" : "medium"}
            >
              Cancel
            </Button>
          ) : null}
        </Box>
      ) : (
        <Skeleton sx={{ flex: 0.3, fontSize: "1.6em" }} />
      )}
    </>
  );

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: 2,
      }}
    >
      <Grid
        item
        xs={4}
        lg={selecting ? 4 : 5}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {!loading ? (
          <Typography
            variant="h1"
            style={{
              fontSize: "1.6em",
              textAlign: "left",
              margin: 0,
            }}
          >
            Members
          </Typography>
        ) : (
          <Skeleton sx={{ flex: 1, marginRight: 2, fontSize: "1.6em" }} />
        )}
      </Grid>
      {width < 1200 ? (
        <Grid item xs={8}>
          <ButtonsDisplay />
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "flex-start", xl: "flex-end" },
          gap: 1,
        }}
      >
        {!loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: colors.secondaryText,
                fontSize: ".875em",
                margin: 0,
              }}
            >
              Sort by:
            </Typography>
            <Button
              onClick={toggleSortDropdown}
              endIcon={
                <ArrowDropDown sx={{ color: "white", fontSize: ".875em" }} />
              }
              sx={{
                background: "none !important",
                color: "white",
                margin: 0,
                ml: { xs: 0, lg: 1 },
                fontSize: ".875em",
                mt: { xs: 0.25, lg: 0 },
              }}
              size="small"
            >
              {displayedSortOption}
            </Button>
            <Menu
              anchorEl={sortAnchorEl}
              open={Boolean(sortAnchorEl)}
              onClose={toggleSortDropdown}
            >
              {sortButtons.map((button, index) => (
                <MenuItem
                  key={index.toString()}
                  onClick={() => {
                    handleListItemClick(index);
                    toggleSortDropdown();
                  }}
                  sx={{
                    px: 3,
                    ":hover": {
                      backgroundColor: "#030303",
                    },
                  }}
                >
                  {button}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ) : (
          <Skeleton sx={{ flex: 0.15, mr: 1, fontSize: "1.6em" }} />
        )}
        {loading ? (
          <Skeleton sx={{ flex: 0.15, mr: 1, fontSize: "1.6em" }} />
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              ml: { xs: "auto", lg: 0 },
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: colors.secondaryText,
                fontSize: ".875em",
                margin: 0,
              }}
            >
              {`Total:`}{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: ".875em",
                fontWeight: 500,
                ml: 1,
                color: colors.primaryText,
              }}
            >
              {`${members?.length}`} members
            </Typography>
          </Box>
        )}
      </Grid>
      {width >= 1200 ? (
        <Grid
          item
          xs={12}
          lg={selecting ? 4 : 3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ButtonsDisplay />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default MemberDisplayHeader;
