import { useNavigate, useParams } from "react-router-dom";
import PublicHeader from "../../public/navigation/PublicHeader";
import { DataStore } from "aws-amplify";
import { Event, GuestInvite } from "../../models";
import { useEffect, useState } from "react";
import { BeatLoader, ClipLoader } from "react-spinners";
import Ticket from "../../components/Ticket";
import { ErrorOutline } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import { Box, Button, Modal, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer";
import ChangePhotoIDModal from "./ChangePhotoIDModal";

const TicketPage = () => {
  const { eventID, qrCode } = useParams();
  const [event, setEvent] = useState(null);
  const [guestInvite, setGuestInvite] = useState(null);
  const [idModalVisible, setIDModalVisible] = useState(false);
  const [photoID, setPhotoID] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (eventID) {
      const eventSubscription = DataStore.observeQuery(Event, (e) =>
        e.id.eq(eventID)
      ).subscribe((event) => {
        if (event.isSynced) {
          setEvent(event.items?.[0] || undefined);
        }
      });

      return () => eventSubscription.unsubscribe();
    }
  }, [eventID]);

  useEffect(() => {
    if (qrCode) {
      const guestInviteSubscription = DataStore.observeQuery(GuestInvite, (g) =>
        g.qrCode.eq(qrCode)
      ).subscribe((guestInvite) => {
        if (guestInvite.isSynced) {
          setGuestInvite(guestInvite.items?.[0] || undefined);
        }
      });

      return () => guestInviteSubscription.unsubscribe();
    }
  }, [qrCode]);

  async function downloadTicket() {
    const ticketElement = document.getElementById("ticketRef");
    const canvas = await html2canvas(ticketElement);

    // Convert canvas to image data URL
    const imageDataUrl = canvas.toDataURL("image/png");

    // Create a temporary anchor element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = imageDataUrl;
    downloadLink.download = "ticket.png"; // Specify the desired filename with extension

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  async function handleChangeIDPress() {
    setIDModalVisible(true);

    if (!photoID) {
      const response = await axios.get(
        `https://xwpg4g0xmj.execute-api.us-east-1.amazonaws.com/prod?id=${guestInvite.photoID}`
      );
      const photoURL = response.data.url;
      setPhotoID(photoURL);
    }
  }

  const pageTitle = `Your Ticket`;
  const pageDescription = `Easily view your unique, limited-use ticket to the event you were invited to.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/ticket`;
  return (
    <div
      className="page-container"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <PublicHeader />
      <Box
        className="profile-container"
        style={{
          height: "calc(100svh - 9em)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
          paddingTop: "9em",
        }}
      >
        {guestInvite && event ? (
          <Box sx={{ display: "flex", flexDirection: "column", mb: "auto" }}>
            <Typography
              variant="h1"
              style={{
                textAlign: "center",
                fontSize: "2em",
                fontWeight: 600,
                width: "80%",
                alignSelf: "center",
              }}
            >
              Your ticket to {event?.name}
            </Typography>
            <Ticket
              event={event}
              qrCode={qrCode}
              guestInvite={guestInvite}
              downloadTicket={downloadTicket}
              handleChangeIDPress={
                guestInvite.photoID ? handleChangeIDPress : null
              }
            />
            <Box sx={{ display: "flex" }}>
              {guestInvite.photoID ? (
                <ChangePhotoIDModal
                  idModalVisible={idModalVisible}
                  setIDModalVisible={setIDModalVisible}
                  photoID={photoID}
                  setPhotoID={setPhotoID}
                  guestInvite={guestInvite}
                  event={event}
                  qrCode={qrCode}
                />
              ) : null}
            </Box>
          </Box>
        ) : guestInvite === null || event === null ? (
          <Box
            sx={{
              mb: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h2" sx={{ my: 3 }}>
              Retrieving ticket data...
            </Typography>
            <BeatLoader
              loading={true}
              color={colors.primaryColor}
              size={"1.5em"}
            />
          </Box>
        ) : (
          <Box
            sx={{
              mb: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ErrorOutline color="error" sx={{ fontSize: "8em" }} />
            <h1
              style={{
                alignSelf: "center",
                fontSize: "2.5em",
                marginBottom: 0,
                textAlign: "center",
              }}
            >
              Oops! Ticket does not exist
            </h1>
            <p style={{ fontSize: "1.5em" }}>
              Need help?{" "}
              <b
                style={{ color: colors.primaryColor, cursor: "pointer" }}
                onClick={() => navigate("/contact")}
              >
                Contact support.
              </b>
            </p>
          </Box>
        )}
        <Footer />
      </Box>
    </div>
  );
};

export default TicketPage;
