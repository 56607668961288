import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CreateEvent from "../../private/createEvent/CreateEvent";
import Profile from "../../private/profile/Profile";

import Landing from "../../public/landing/Landing";
import Legal from "../../public/legal/Legal";
import { colors } from "../../theme/colors";
import About from "../aboutUs/About";
import Login from "../auth/Login";
import SignUp from "../auth/SignUp";
import Contact from "../contact/Contact";
import PageNotFound from "../PageNotFound";
import Plans from "../plans/Plans";
import SendInvites from "../../private/createEvent/SendInvites";
import EventPage from "../../private/insights/eventPage/EventPage";
import Insights from "../../private/insights/Insights";
import GuestList from "../../private/createEvent/GuestList";
import DeleteAccount from "../../private/profile/DeleteAccount";
import MemberDisplay from "../../private/members/MemberDisplay";
import PaymentsWithStripe from "../../private/payments/PaymentsWithStripe";
import TicketPage from "../../private/payments/TicketPage";
import EditEvent from "../../private/editEvent/EditEvent";
import OrgMemberInvites from "../../private/createEvent/OrgMemberInvites";
import ContactsListDisplay from "../../private/contacts/ListDisplay";
import EditContacts from "../../private/contacts/EditContacts";
import Wallet from "../../private/payments/Wallet";
import OrgInvites from "../../private/createEvent/OrgInvites";
import UploadPhotoID from "../../private/verification/UploadPhotoID";
import ViewPhotoID from "../../private/verification/ViewPhotoID";
import EditProfile from "../../private/profile/EditProfile";
import ChangeEmail from "../auth/ChangeEmail";
import Tutorials from "../../public/tutorials/Tutorials";
import Reports from "../../private/insights/reports/Reports";
import SubscriptionPayments from "../plans/SubscriptionPayments";
import Billing from "../../private/profile/Billing";
import EventAnnouncements from "../../private/createEvent/announcements/EventAnnouncements";
import { useAuthContext } from "../../contexts/AuthContext";
import { Box, Typography } from "@mui/material";
import { BeatLoader } from "react-spinners";
import RushOverview from "../../public/suitePages/rush/RushOverview";
import SocialOverview from "../../public/suitePages/social/SocialOverview";
import PhilanthropyOverview from "../../public/suitePages/philanthropy/PhilanthropyOverview";
import ScrollToTop from "./ScrollToTop";
// import ContactInsights from "../../private/contacts/ContactInsights";

const Navigation = () => {
  const { authUser, dbUser } = useAuthContext();

  console.log(authUser, dbUser)

  if (authUser === undefined || dbUser === undefined) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100dvh",
        }}
      >
        <Typography variant="body2" sx={{ mb: 1, fontSize: "1.2em" }}>
          Loading resources
        </Typography>
        <BeatLoader
          color={colors.primaryColor}
          speedMultiplier={0.75}
          size="1.2em"
        />
      </Box>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
      </style>

      <div style={{ backgroundColor: colors.defaultBackground }}>
        <Routes>
          {/* public */}
          <Route path="/" element={<Landing />} />
          <Route path="/plans" element={<Plans />} />
          <Route
            path="/subscribe/:planType"
            element={<SubscriptionPayments />}
          />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/tutorials" element={<Tutorials />} />

          {/* auth */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/dashboard/delete" element={<DeleteAccount />} />
          <Route path="/change_email" element={<ChangeEmail />} />

          {/* web portal */}
          <Route path="/dashboard" element={<Profile />} />
          <Route path="/dashboard/edit" element={<EditProfile />} />
          <Route path="/create" element={<CreateEvent />} />
          <Route
            path="/event/invite_members/:eventID"
            element={<OrgMemberInvites />}
          />
          <Route path="/event/invite_orgs/:eventID" element={<OrgInvites />} />
          <Route path="/event/invite/:eventID" element={<SendInvites />} />
          <Route
            path="/event/announcements/:eventID"
            element={<EventAnnouncements />}
          />
          <Route path="/insights/event/:eventID" element={<EventPage />} />
          <Route path="event/guest_list/:eventID" element={<GuestList />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/insights/:memberID" element={<Insights />} />
          <Route path="/members" element={<MemberDisplay />} />
          <Route path="/lists" element={<ContactsListDisplay />} />
          <Route path="/lists/edit/:listID" element={<EditContacts />} />
          <Route path="/ticket/:eventID/:qrCode" element={<TicketPage />} />
          <Route path="/event/edit/:eventID" element={<EditEvent />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/view_id/:guestInviteID" element={<ViewPhotoID />} />
          <Route path="/dashboard/billing" element={<Billing />} />
          <Route
            path="/dashboard/billing/:modalOptions"
            element={<Billing />}
          />

          {/* unauthed/authed interactions */}
          <Route
            path="/payments/:ticketLinkID"
            element={<PaymentsWithStripe />}
          />

          <Route
            path="/upload_id/:eventID/:qrPrefix"
            element={<UploadPhotoID />}
          />
          <Route
            path="/upload_id/:eventID/:qrPrefix/:noRedirect"
            element={<UploadPhotoID />}
          />
          <Route path="/rush" element={<RushOverview />} />
          <Route path="/social" element={<SocialOverview />} />
          <Route path="/philanthropy" element={<PhilanthropyOverview />} />
        </Routes>
      </div>
    </Router>
  );
};

export default Navigation;
