import { getModuleDate } from "../../methods/getFormattedTime";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import { colors } from "../../theme/colors";
import EventModule from "./EventModule";
import { Add, EventOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";

const EventDisplay = ({
  events,
  allEvents,
  selectedDate,
  loading,
  setSelectedDate,
  setAllEventsDialogOpen,
  setSnackbarOpen,
}) => {
  const { planLimits, monthEvents } = useAuthContext();
  const navigate = useNavigate();
  const renderItem = (item, index) => (
    <EventModule event={item} key={index.toString()} />
  );

  return (
    <Box
      className="unstyled-card"
      sx={{
        p: ".5em 1em",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        pb: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: ".5em",
        }}
      >
        {loading ? (
          <Skeleton sx={{ fontSize: "1.2em", width: "100%" }} />
        ) : (
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <h1
              style={{
                margin: 0,
                fontSize: "1.2em",
                flex: 1,
              }}
            >
              {selectedDate
                ? `Events on ${getModuleDate(Math.floor(selectedDate))}`
                : `Upcoming Events`}
            </h1>
            {selectedDate ? (
              <Button
                size="small"
                color="error"
                onClick={() => setSelectedDate(null)}
              >
                Clear Date
              </Button>
            ) : null}
          </Box>
        )}

        {loading ? (
          <Skeleton sx={{ fontSize: ".8em", width: "100%" }} />
        ) : (
          <p
            style={{
              margin: 0,
              fontSize: ".8em",
              color: colors.secondaryText,
            }}
          >
            To view a field's full text, hover over it on the event's module
          </p>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading ? (
          <Skeleton variant="rounded" sx={{ fontSize: "1em", mb: 1 }} />
        ) : (
          <Grid
            container
            alignItems="center"
            columns={10}
            sx={{
              px: 1,
              py: 0.25,
              mb: 1,
              borderRadius: 1,
              backgroundColor: colors.backgroundHighlight,
            }}
          >
            <Grid item xs={3}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Event
              </p>
            </Grid>
            <Grid item xs={3}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Location
              </p>
            </Grid>

            <Grid item xs={2.5}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Engagement
              </p>
            </Grid>
            <Grid item xs={1.5}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                More
              </p>
            </Grid>
          </Grid>
        )}
      </div>
      {loading ? (
        Array.from({ length: 3 }, (_, i) => (
          <Skeleton
            variant="rounded"
            sx={{ fontSize: "2.5em", mb: 1 }}
            key={i.toString()}
          />
        ))
      ) : events?.length ? (
        events.map(renderItem)
      ) : (
        <p
          style={{
            alignSelf: "flex-start",
            fontSize: ".8em",
            margin: 0,
            fontWeight: 500,
            textAlign: "left",
            color: colors.secondaryText,
          }}
        >
          You must have hosted an event for you to view and interact with your
          events.
        </p>
      )}
      {!loading ? (
        <Box sx={{ display: "flex", gap: "1em", mt: { xs: 2, lg: "auto" } }}>
          <Button
            variant="contained"
            sx={{ flex: 1, fontSize: { xs: "12px", lg: ".875rem" } }}
            startIcon={
              <Add
                sx={{ color: "white", fontSize: { xs: "12px", lg: ".875rem" } }}
              />
            }
            color="primary"
            onClick={() => {
              if (planLimits.maxEvents <= monthEvents) {
                setSnackbarOpen({
                  type: "error",
                  message:
                    "You have exceeded your alloted monthly maximimum events for your current subscription plan.",
                  action: (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() =>
                        navigate("/dashboard/billing/upgrade_plan")
                      }
                    >
                      Upgrade Now
                    </Button>
                  ),
                });
              }
              // monthEvents.length >= subscriptionPlan.maxEvents;
              else {
                navigate("/create");
              }
            }}
          >
            Create Event
          </Button>
          <Button
            variant="contained"
            sx={{
              flex: { xs: 1, lg: 0.6 },
              fontSize: { xs: "12px", lg: ".875rem" },

              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
            }}
            startIcon={
              <EventOutlined
                sx={{ color: "white", fontSize: { xs: "12px", lg: ".875rem" } }}
              />
            }
            onClick={() => setAllEventsDialogOpen(true)}
          >
            All Events
          </Button>
        </Box>
      ) : (
        <Skeleton variant="rounded" sx={{ height: "2em" }} />
      )}
    </Box>
  );
};

export default EventDisplay;
