import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import InsightModule from "./InsightModule";
import { useEffect, useState } from "react";
import { CalendarToday, Sell } from "@mui/icons-material";
import ScanTimeGraph from "./ScanTimeGraph";
import { colors } from "../../theme/colors";
import { formatMoney } from "../../methods/calculateTakeHome";
import HelpDialog from "../../pages/help/HelpDialog";

const InsightsEventDataDisplay = ({ timeFrame, events, loading }) => {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [timeFrameEvents, setTimeFrameEvents] = useState();
  const [formattedScans, setFormattedScans] = useState([]);
  const [formattedInvalidScans, setFormattedInvalidScans] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (events) {
      let tempLabels = [];
      let tempTimeFrameEvents = events.filter(
        (e) => parseInt(e.endDate) >= timeFrame
      );
      tempTimeFrameEvents.forEach((event) => {
        tempLabels.push(...event.analytics?.graphData?.labels);
      });

      const minLabel = Math.min(...tempLabels);
      const maxLabel = Math.max(...tempLabels);
      const interval = (maxLabel - minLabel) / 9;

      let newLabels = Array(10)
        .fill()
        .map((_, i) => parseInt(minLabel + i * interval));
      let aggregateValidScans = new Array(10).fill(0);
      let aggregateInvalidScans = new Array(10).fill(0);
      if (events.length) {
        for (let i = 0; i < events.length; i++) {
          const originalLabels = events[i].analytics.graphData.labels;
          const originalValidScans = events[i].analytics.graphData.validScans;
          const originalInvalidScans =
            events[i].analytics.graphData.invalidScans;

          // Map each valid scan value to its corresponding label on the new label array
          const mappedValidScans = originalValidScans.map((scan, index) => {
            const label = originalLabels[index];
            let mappedLabelIndex = -1;
            for (let j = 0; j < newLabels.length - 1; j++) {
              if (label >= newLabels[j] && label < newLabels[j + 1]) {
                mappedLabelIndex = j;
                break;
              }
            }
            return mappedLabelIndex !== -1 ? scan : 0;
          });

          // Map each invalid scan value to its corresponding label on the new label array
          const mappedInvalidScans = originalInvalidScans.map((scan, index) => {
            const label = originalLabels[index];
            let mappedLabelIndex = -1;
            for (let j = 0; j < newLabels.length - 1; j++) {
              if (label >= newLabels[j] && label < newLabels[j + 1]) {
                mappedLabelIndex = j;
                break;
              }
            }
            return mappedLabelIndex !== -1 ? scan : 0;
          });

          // Sum up the mapped valid and invalid scan values for each new label
          for (let j = 0; j < aggregateValidScans.length; j++) {
            const validScansInRange = mappedValidScans.reduce(
              (acc, scan, index) => {
                if (index > 0 && index < originalValidScans.length) {
                  const label = originalLabels[index];
                  if (label >= newLabels[j] && label < newLabels[j + 1]) {
                    return acc + scan;
                  }
                }
                return acc;
              },
              0
            );
            aggregateValidScans[j] += validScansInRange;

            const invalidScansInRange = mappedInvalidScans.reduce(
              (acc, scan, index) => {
                if (index > 0 && index < originalInvalidScans.length) {
                  const label = originalLabels[index];
                  if (label >= newLabels[j] && label < newLabels[j + 1]) {
                    return acc + scan;
                  }
                }
                return acc;
              },
              0
            );
            aggregateInvalidScans[j] += invalidScansInRange;
          }
        }
      } else {
        newLabels = ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90"];

        aggregateValidScans = Array.from(
          { length: 10 },
          () => Math.floor(Math.random() * 1000) + 300
        );
        aggregateInvalidScans = aggregateValidScans.map((validScansCount) =>
          Math.floor(validScansCount * (Math.random() * 0.1 + 0.1))
        );
      }

      setFormattedScans(aggregateValidScans);
      setFormattedInvalidScans(aggregateInvalidScans);
      setLabels(newLabels);
      setTimeFrameEvents(events.filter((e) => e.endDate >= timeFrame));
    }
  }, [timeFrame, events]);

  if (!timeFrameEvents)
    return (
      <Grid container spacing={1.5}>
        <Grid item xs={6.5}>
          <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
            <Skeleton height="2em" />
            <Skeleton height="10em" variant="rounded" />
          </Box>
        </Grid>
        <Grid item xs={5.5}>
          <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
            <Skeleton height="2em" />
            <Skeleton height="6em" variant="rounded" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
            <Skeleton height="2em" />
            <Skeleton height="45dvh" variant="rounded" />
          </Box>
        </Grid>
      </Grid>
    );

  let timeFrameValidScans = 0;
  let timeFrameInvites = 0;
  let timeFrameInvalidScans = 0;
  let timeFrameRevenue = 0;
  let timeFrameTickets = 0;

  for (const event of timeFrameEvents) {
    for (const categoryData of event.analytics.categoryData) {
      timeFrameValidScans += categoryData.validScans || 0;
      timeFrameInvites += categoryData.invitesGiven || 0;
      timeFrameInvalidScans += categoryData.invalidScans || 0;
      timeFrameRevenue += categoryData.revenue || 0;
      timeFrameTickets += categoryData.ticketsSold;
    }
  }

  let timeFramePaidEventsLength = timeFrameEvents.filter(
    (e) => e.ticketInformation
  ).length;
  return (
    <Grid container spacing={1.5}>
      <HelpDialog
        preferredActiveScreen={"Request New Feature"}
        helpDialogOpen={helpDialogOpen}
        setHelpDialogOpen={setHelpDialogOpen}
      />
      <Grid item xs={6.5}>
        <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: "1.2em",
            }}
          >
            Total Event Data
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 1.5 }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: ".8em",
              }}
            >
              Think we should add more data?
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: ".8em",
                fontWeight: 600,
                cursor: "pointer",
                transition: "all 300ms ease-in-out",
                ":hover": {
                  opacity: 0.7,
                },
              }}
              onClick={() => setHelpDialogOpen(true)}
            >
              Request an addition
            </Typography>
          </Box>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={4}>
              <InsightModule
                header={
                  timeFrameEvents.length === 1
                    ? "Event Hosted"
                    : "Events Hosted"
                }
                statistic={timeFrameEvents.length}
              />
            </Grid>
            <Grid item xs={8}>
              <InsightModule
                header={
                  timeFrameValidScans === 1
                    ? "Event Attendee"
                    : "Event Attendees"
                }
                statistic={timeFrameValidScans}
                numberFormat={"long"}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={
                  (timeFrameValidScans / timeFrameEvents.length).toFixed(1) ===
                  1.0
                    ? "Avg Event Attendee"
                    : "Avg Event Attendees"
                }
                statistic={
                  timeFrameEvents.length
                    ? (timeFrameValidScans / timeFrameEvents.length).toFixed(1)
                    : "0"
                }
              />
            </Grid>

            <Grid item xs={4}>
              <InsightModule
                header={"% QRs Scanned"}
                statistic={`${
                  timeFrameInvites
                    ? ((timeFrameValidScans * 100) / timeFrameInvites).toFixed(
                        1
                      )
                    : "0.0"
                }%`}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={"Invalid Scanned"}
                statistic={timeFrameInvites ? timeFrameInvalidScans : "0"}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={"Avg Invalid Scanned"}
                statistic={
                  timeFrameEvents.length
                    ? (timeFrameInvalidScans / timeFrameEvents.length).toFixed(
                        1
                      )
                    : "0"
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={
                  (timeFrameInvites / timeFrameEvents.length).toFixed(1) === 1.0
                    ? "Avg Invite Given"
                    : "Avg Invites Given"
                }
                statistic={
                  timeFrameEvents.length
                    ? (timeFrameInvites / timeFrameEvents.length).toFixed(1)
                    : "0"
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={
                  timeFrameInvites.toFixed(1) === 1.0
                    ? "Total Invite Given"
                    : "Total Invites Given"
                }
                statistic={timeFrameInvites}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={5.5}>
        <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: "1.2em",
              mb: 2,
            }}
          >
            Paid Event Data
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InsightModule
                header={`Paid Event${
                  timeFramePaidEventsLength !== 1 ? "s" : ""
                }`}
                statistic={timeFramePaidEventsLength}
              />
            </Grid>

            <Grid item xs={4}>
              <InsightModule
                header={`Total Ticket${timeFrameTickets !== 1 ? "s" : ""}`}
                statistic={timeFrameTickets}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={`Avg Revenue`}
                statistic={`$${
                  timeFramePaidEventsLength
                    ? formatMoney(
                        timeFrameRevenue / timeFramePaidEventsLength,
                        "dollars"
                      )
                    : 0.0
                }`}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={`Avg Ticket${
                  timeFrameTickets / timeFramePaidEventsLength !== 1 ? "s" : ""
                }`}
                statistic={
                  timeFramePaidEventsLength
                    ? timeFrameTickets / timeFramePaidEventsLength
                    : 0.0
                }
              />
            </Grid>
            <Grid item xs={8}>
              <InsightModule
                header={`Total Revenue`}
                statistic={`$${formatMoney(timeFrameRevenue * 100, "balance")}`}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
          <Typography variant="h1" sx={{ fontSize: "1.2em" }}>
            When Guests Showed Up
          </Typography>
          <Typography variant="body2" sx={{ fontSize: ".8em", mb: 2 }}>
            Hover over any data point to inspect it closer and click on an item
            in the legend to remove it from the graph
          </Typography>
          <ScanTimeGraph
          isSpoofed={!events.length}
            data={{
              datasets: [
                {
                  label: "Valid Scans",
                  showLabel: false,
                  data: formattedScans,
                  fill: true,
                  backgroundColor: `${colors.primaryColor}22`,
                  borderColor: colors.primaryColor,
                  lineTension: 0.2,
                },
                {
                  label: "Invalid Scans",
                  showLabel: false,
                  data: formattedInvalidScans,
                  fill: true,
                  backgroundColor: `${colors.errorColor}22`,
                  borderColor: colors.errorColor,
                  lineTension: 0.2,
                },
              ],
              labels: labels,
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default InsightsEventDataDisplay;
