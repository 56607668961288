import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import RushCapabilityCard from "./RushCapabilityCard";
import React from "react";
import {
  CoPresentOutlined,
  DataUsageOutlined,
  GroupWorkOutlined,
  QueryStatsOutlined,
  QuickreplyOutlined,
} from "@mui/icons-material";

const capabilityData = [
  {
    title: "Workflow automation",
    capabilities: [
      {
        title: "Mass texting",
        icon: (
          <QuickreplyOutlined
            sx={{ color: colors.nemoBlue, fontSize: "4rem" }}
          />
        ),
        subTitle:
          "Automate mass-sending of personalized texts to your guests, all from your own phone number",
        detail: [
          "Decrease time-to-invite for your guests",
          "Maintain a personal feel",
          "Allow guests to respond directly to you",
          "View your texts in your Messages",
          "Individually review your texts to ensure validity",
        ],
      },
      {
        title: "Slideshow integration",
        subTitle:
          "Generate customizable excel sheets and data-rich, PNM-specific presentations on the fly",
        icon: (
          <CoPresentOutlined
            sx={{ color: colors.nemoBlue, fontSize: "4rem" }}
          />
        ),
        detail: [
          "Automate the creation of personalized presentations",
          "Save time with instant data import from Excel to Rush Suite",
          "Customize slides with PNM-specific information and analytics",
          "Enhance presentations with visual data representations",
        ],
      },
      {
        title: "Shareable database",
        subTitle:
          "Maintain a shared database of PNMs, complete with custom scoring, photos, notes, and more",
        icon: (
          <GroupWorkOutlined
            sx={{ color: colors.nemoBlue, fontSize: "4rem" }}
          />
        ),
        detail: [
          "Centralized access to PNM data for all team members",
          "Customizable scoring system to evaluate PNMs",
          "Photo and note integration for each PNM profile",
          "Real-time updates to ensure data accuracy",
          "Controlled access to sensitive permissions",
        ],
      },
    ],
  },
  {
    title: "Enterprise-level analytics",
    capabilities: [
      {
        title: "Track engagement",
        icon: (
          <QueryStatsOutlined
            sx={{ color: colors.nemoBlue, fontSize: "4rem" }}
          />
        ),
        subTitle:
          "Access detailed event attendance breakdowns, including whether a PNM was invited, arrival time, and more",
        detail: [
          "Monitor PNM attendance and participation",
          "Analyze historical attendance data",
          "Track invite status and event presence",
          "Identify and address patterns in PNM engagement",
        ],
      },
      {
        title: "Data-driven decisions",
        icon: (
          <DataUsageOutlined
            sx={{ color: colors.nemoBlue, fontSize: "4rem" }}
          />
        ),
        subTitle:
          "Aggregate brother scores, notes, and engagement data to inform your decisions",
        detail: [
          "Compile comprehensive scorecards for each PNM",
          "Utilize advanced analytics to inform recruitment strategy",
          "Integrate qualitative and quantitative data for decision making",
          "Leverage engagement metrics for targeted follow-ups",
        ],
      },
    ],
  },
];

const RushCapabilitiesSection = () => {
  return (
    <Box
      sx={{
        py: { sm: 12, xs: 8 },
        pb: { sm: 28 },
        gap: 8,
        bgcolor: colors.eggshell,
        position: "relative",
      }}
      width="100dvw"
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <Box
        className="default-page-size"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h2"
          sx={{
            color: colors.defaultBackground,
            lineHeight: 1.12,
            letterSpacing: "-.8px",
            textAlign: "center",
            alignSelf: "center",
            fontSize: {
              lg: "2rem",
              md: "2rem",
              sm: "2rem",
              xs: "1.6rem",
            },
            width: {
              md: "70%",
            },
            mb: "3rem",
          }}
        >
          Learn how CLIQInvite's Rush Suite can benefit your organization
        </Typography>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={{ md: 0, lg: 10, xs: 1 }}
          sx={{ zIndex: 1 }}
        >
          {capabilityData.map(({ title, capabilities, icon }, index) => {
            return (
              <React.Fragment key={index.toString()}>
                <Grid item lg={2.5} md={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      py: { md: 3, xs: 0 },
                      mt: { xs: 5, md: index === 0 ? 0 : 2, lg: 0 },
                      mb: { xs: 2, md: 0 },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        color: colors.defaultBackground,
                        fontSize: {
                          xs: "1.5rem",
                        },

                        textAlign: { md: "left", xs: "center" },
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                </Grid>

                {capabilities.map((capability) => (
                  <Grid item md={4} lg={3.166} xs={12}>
                    <RushCapabilityCard capability={capability} />
                  </Grid>
                ))}
              </React.Fragment>
            );
          })}
        </Grid>
      </Box>
      <img
        src={require("../../../assets/Rush_Capabilities_Landscape.png")}
        style={{
          width: "100%",
          height: "auto",
          position: "absolute",
          bottom: 0,
        }}
      />
    </Box>
  );
};

export default RushCapabilitiesSection;
