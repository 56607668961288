import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";

const PhilanthropyOverviewHeroSection = ({ url }) => {
  return (
    <Box
      sx={{
        width: "100%",
        background: `radial-gradient(ellipse at 0% 0%, ${colors.softGreen}30, black 80%)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "6.5rem",
        paddingBottom: "5rem",
      }}
    >
      <Grid
        className="default-page-size"
        container
        sx={{
          flexWrap: "wrap-reverse",
          position: "relative",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            py: { md: 3 },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="body1" sx={{ mb: ".5rem" }}>
              PHILANTHROPY
            </Typography>
            <Typography
              variant="h1"
              sx={{
                lineHeight: 1.0625,
                letterSpacing: "-.03rem",
                textAlign: { xs: "left" },
                fontSize: {
                  xs: "2.75rem",
                  sm: "5.4vw",
                  md: "4.6vw",
                  lg: "4rem",
                  xl: "3.125rem",
                },
                mb: { xs: "0.5rem" },
                maxWidth: { sm: "90%" },
                fontWeight: 600,
                alignSelf: { xs: "flex-start" },
              }}
            >
              Sell and verify tickets without friction.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                lineHeight: "130%",
                letterSpacing: "-.03rem",
                textAlign: { xs: "left" },
                fontSize: {
                  xs: "1.15rem",
                  sm: "2.5vw",
                  md: "1.4vw",
                  lg: "1.25rem",
                  xl: "1.15rem",
                },
                maxWidth: { lg: "35rem", sm: "45rem" },
                mt: { xs: 1.4 },
                alignSelf: { xs: "flex-start" },
                fontWeight: 400,
                lineHeight: 1.33,
                mb: 4,
              }}
            >
              Control your crowd with limited-use purchase links. Remove
              friction with automatic e-ticket phone delivery. Maximize revenue
              with real-time ticket price adjustment.
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: { xs: "space-between" },
                alignItems: "center",
                lineHeight: "100%",
                mt: "auto",
                fontSize: { xs: ".8rem", md: ".8rem", lg: "1rem" },
              }}
            >
              <button
                style={{ fontFamily: "Poppins", fontSize: "1.35em" }}
                className="slide-button soft-green"
                onClick={() => window.open(url, "_blank", "noreferrer")}
              >
                Get CLIQInvite
              </button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            pl: { xl: "8rem", md: "4em" },
            mb: { xs: 5, md: 0 },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "clamp(370px, 74dvw, 74dvw)",
                sm: "25rem",
                md: "100%",
              },
              alignSelf: { xs: "center", sm: "flex-start", md: "center" },
            }}
          >
            <img
              src={require("../../../assets/philanthropy/PhilanthropyHeroImage.png")}
              style={{ width: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PhilanthropyOverviewHeroSection;
