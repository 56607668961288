import { ArrowBackOutlined, CheckCircleOutline } from "@mui/icons-material";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import { BeatLoader } from "react-spinners";
import { colors } from "../../theme/colors";
import { useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";

const ListItem = ({ item }) => (
  <div
    style={{
      display: "flex",
      width: "100%",
      marginBottom: ".2em",
    }}
  >
    <p style={{ margin: 0, color: colors.primaryText }}>
      <b style={{ color: item.checkColor }}>✓</b>&nbsp;&nbsp;{item.text}
    </p>
  </div>
);

const SubscriptionConfirmPayment = ({
  stripe,
  elements,
  planType,
  setPhase,
  totalCost,
  billingCycle,
  setAnimationType,
  totalMembers,
  setSnackbarOpen,
  clientSecret,
  memberCost,
  chapterCost,
}) => {
  const [promotionCode, setPromotionCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [activePromotionDiscount, setActivePromotionDiscount] = useState(0);
  const { dbUser } = useAuthContext();
  const numEvents = planType === "Pro" ? 3 : "Unlimited";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!stripe) {
      return;
    }

    // Trigger form validation and wallet collection
    let isError = false;
    let alertString = "";

    const { error: submitError } = await elements.submit();
    if (submitError) {
      alertString += submitError.message;
      isError = true;
    }

    if (isError) {
      setSnackbarOpen({ type: "error", message: alertString });
      setSubmitting(false);
      return;
    }

    let return_url = "";
    if (process.env.NODE_ENV === "production") {
      return_url += `https://www.cliqinvite.com/`;
    } else {
      return_url += `http://localhost:3000/`;
    }
    return_url += `dashboard`;

    // Use the clientSecret and Elements instance to confirm the setup
    try {
      const result = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: return_url,
        },
      });

      if (result.error) {
        setSnackbarOpen({ type: "error", message: result.error.message });
        setSubmitting(false);
      }
    } catch (err) {
      setSnackbarOpen({ type: "error", message: err.message });
    }
  };

  const displayedDetails = [
    `${totalMembers} member accounts + 1 chapter account`,
    `${numEvents} events${typeof numEvents === "number" ? " per month" : ""}`,
    `${numEvents} contact lists${
      typeof numEvents === "number" ? " per month" : ""
    }`,
    `Event ${planType !== "Pro" ? "and member " : ""}analytics`,
    `Much, much more`,
  ];

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      className="unstyled-card"
      sx={{
        mt: 5,
        bgcolor: colors.secondaryBackground,
        display: "flex",
        flexDirection: "column",
        px: 5,
        py: 2,
        alignSelf: "center",
      }}
    >
      <Grid container spacing={2} columnSpacing={4}>
        <Grid item xs={12}>
          <h1
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: "1.5em",
              fontWeight: 500,
            }}
          >
            Confirm Subscription
          </h1>
        </Grid>
        <Grid item xs={8} xl={6.5}>
          <p
            style={{
              margin: 0,
              fontSize: "1em",
              color: colors.secondaryText,
              marginBottom: ".25em",
            }}
          >
            CLIQInvite {planType} Plan for {dbUser?.name}
          </p>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                margin: 0,
                color: colors.primaryText,
                fontSize: "1.5em",
              }}
            >
              ${parseInt(memberCost) / 100}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: ".8em",
                color: colors.secondaryText,
              }}
            >
              / member
            </p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 0, color: "white", fontSize: "1em" }}>
              x {totalMembers}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: ".8em",
                color: colors.secondaryText,
              }}
            >
              members
            </p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 0, color: "white", fontSize: "1em" }}>
              + ${parseInt(chapterCost) / 100}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: ".8em",
                color: colors.secondaryText,
              }}
            >
              chapter account
            </p>
          </Box>
          <Divider sx={{ bgcolor: colors.secondaryText, my: 1 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 0, color: "white", fontSize: "1.8em" }}>
              ${(parseInt(totalCost) * (1 - activePromotionDiscount)) / 100}
            </p>
            <Box>
              <p
                style={{
                  margin: 0,
                  textAlign: "right",
                  fontSize: ".8em",
                  color: colors.secondaryText,
                }}
              >
                Total Cost
              </p>
              <p
                style={{
                  color: colors.secondaryText,
                  fontSize: ".7rem",
                  textAlign: "right",
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                / {billingCycle}
              </p>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={0} xl={0.5} />
        <Grid item xs={4} xl={5}>
          <p
            style={{
              marginTop: 0,
              fontSize: "1em",
              color: colors.secondaryText,
            }}
          >
            Plan Details
          </p>
          {displayedDetails.map((detail) => (
            <ListItem item={{ text: detail, checkColor: "#a020f0" }} />
          ))}
        </Grid>
        <Grid
          item
          xs={5}
          xl={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: 1,
            paddingBottom: "1em",
          }}
        >
          <TextField
            value={promotionCode}
            onChange={(e) => setPromotionCode(e.target.value)}
            sx={{
              bgcolor: colors.backgroundHighlight,
              borderRadius: 1,
              flex: 1,
            }}
            label="Promotion Code"
            size="medium"
          ></TextField>
          {promotionCode.length > 6 ? (
            <Button
              variant="contained"
              size="large"
              sx={{ mb: ".2em" }}
              onClick={() =>
                setSnackbarOpen({
                  type: "error",
                  message: "Invalid promotion code. Please try again.",
                })
              }
            >
              Apply
            </Button>
          ) : null}
        </Grid>
      </Grid>
      {!submitting ? (
        <Box sx={{ display: "flex", gap: 2, mt: 5 }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!stripe || submitting}
            sx={{
              flex: 0.5,
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
            }}
            startIcon={<ArrowBackOutlined sx={{ color: "white" }} />}
            onClick={() => {
              setPhase("INFORMATION");
              setAnimationType("BACKWARD");
            }}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ flex: 1 }}
            startIcon={<CheckCircleOutline sx={{ color: "white" }} />}
            disabled={!stripe || submitting}
          >
            Confirm
          </Button>
        </Box>
      ) : (
        <BeatLoader
          style={{ marginLeft: "auto" }}
          loading={true}
          color={colors.primaryColor}
          size={"1.5em"}
        />
      )}
    </Box>
  );
};
export default SubscriptionConfirmPayment;
