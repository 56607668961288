import { colors } from "../../theme/colors";
import "./About.css";
import FadeIn from "../../components/FadeIn";
import { Slide } from "react-slideshow-image";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import PublicHeader from "../../public/navigation/PublicHeader";
import { BsChevronDoubleDown } from "react-icons/bs";
import { Box, Button, Grid } from "@mui/material";
import { Star } from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
import AboutWhoWeAreSection from "./AboutWhoWeAreSection";
import AboutStorySection from "./AboutStorySection";

const About = () => {
  const navigate = useNavigate();
  const testimonials = [
    {
      text: `I can't imagine planning our parties without CLIQ now. The QR codes are more secure and efficient than wristbands, and the platform makes giving out thousands of invites simple.`,
      author: "Rider Hack",
      role: "Social Chair at UNC SAE",
    },
    {
      text: "Honestly this app is actually is good, it will eliminate unnecessary bands from parties. The app can also work for private parties or events. Exited to see where this app goes!!!",
      author: "Esuarez1",
      role: "App Store",
    },
    {
      text: "Tired of wearing frat bands... this is the app for you. This app eliminates the use of stupid bands and allows you to show you invite all from your phone!",
      author: "Madi Boeck",
      role: "UNC AXO",
    },
  ];

  const pageTitle = `About Us • CLIQInvite`;
  const pageDescription = `Meet the team revolutionizing event management with CLIQInvite. Learn about our journey and commitment to streamlining social events, philanthropy, and recruitment. Discover our mission to bring secure invites, real-time tracking, and detailed analytics to everyone.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/about`;
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta
          name="description"
          property="og:description"
          content={pageDescription}
        />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta
          name="keywords"
          content="Event Management, Fraternity Management"
        />
      </Helmet>
      <PublicHeader />
      <Box
        sx={{
          bgcolor: colors.eggshell,
        }}
      >
        <AboutWhoWeAreSection />
        <AboutStorySection />
        {/* <FadeIn>
          <Box
            className="section-two"
            style={{
              width: "100%",
              boxShadow: "0px 0px 6px #502AAA",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid container className="body-about">
              <Grid item lg={8} mr="auto">
                <span
                  style={{
                    color: "white",
                    fontSize: "2.6em",
                    fontWeight: 600,
                    fontFamily: "Poppins, sans-serif",
                    textAlign: "center",
                  }}
                >
                  Secure Events, Seamless Planning
                </span>
                <br />
                <span style={{ ...styles.text, color: "#DDDDDD" }}>
                  Our mission at CliqInvite is to{" "}
                  <b style={{ color: "white" }}>
                    revolutionize the event management industry
                  </b>{" "}
                  by offering a cutting-edge platform that combines ease of use
                  with state-of-the-art security features to deliver a seamless
                  and secure experience for event
                  <b style={{ color: "white" }}>
                    {" "}
                    organizers and attendees
                  </b>{" "}
                  alike.
                </span>
              </Grid>

              <Grid
                item
                lg={3.5}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: {
                    xs: "6em",
                    sm: "5.5em",
                    md: "6em",
                    lg: "5em",
                    xl: "5em",
                  },
                  paddingTop: {
                    xs: ".5em",
                    sm: ".5em",
                    md: ".5em",
                    lg: "0em",
                    xl: "0em",
                  },
                  paddingBottom: {
                    xs: ".5em",
                    sm: ".5em",
                    md: ".5em",
                    lg: "0em",
                    xl: "0em",
                  },
                }}
              >
                <h1
                  style={{
                    color: "white",
                    lineHeight: "110%",
                    margin: 0,
                    fontWeight: 700,
                    fontSize: "1em",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Our Mission
                </h1>
              </Grid>
              <Grid
                item
                lg={12}
                sx={{
                  pt: {
                    xs: "0em",
                    sm: "0em",
                    md: "0em",
                    lg: "2em",
                    xl: "2em",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    ...styles.text,
                    marginTop: 0,
                    color: "#DDDDDD",
                  }}
                >
                  At CliqInvite, we strive to constantly innovate by always
                  listening to our customers. If you have any feedback or would
                  like to chat directly with us, do not hesitate to contact us
                  directly at{" "}
                  <b style={{ fontWeight: 700, color: "white" }}>
                    steve@cliqinvite.com
                  </b>{" "}
                  and{" "}
                  <b style={{ fontWeight: 700, color: "white" }}>
                    mark@cliqinvite.com
                  </b>
                  {", "}
                  on Instagram{" "}
                  <b style={{ fontWeight: 700, color: "gold" }}>
                    <b
                      className="link about-link"
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/cliqinvite/",
                          "_blank",
                          "noreferrer"
                        )
                      }
                      style={{ color: "gold" }}
                    >
                      @cliqinvite
                    </b>
                  </b>
                  {", "} or via our
                  <b
                    className="link about-link"
                    onClick={() => navigate("/contact")}
                    style={{ color: "gold" }}
                  >
                    contact form
                  </b>
                  .
                </span>
              </Grid>
            </Grid>
          </Box>
        </FadeIn>
        <FadeIn>
          <Box className="body-about" style={{ padding: "5em 0em" }}>
            <h1
              style={{
                color: "white",
                fontSize: "4em",
                fontWeight: 700,
                fontFamily: "Poppins, sans-serif",
                margin: 0,
                lineHeight: "110%",
                textAlign: "center",
              }}
            >
              Testimonials
            </h1>

            <Slide arrows={false} transitionDuration={2000} duration={4000}>
              {testimonials.map((header) => (
                <Box
                  style={{
                    width: "94%",
                    paddingRight: "3%",
                    paddingLeft: "3%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "3dvh",
                  }}
                  key={header}
                >
                  <span
                    style={{
                      ...styles.header,
                      fontWeight: 600,
                      color: "white",
                      fontSize: "1.5em",
                    }}
                  >
                    {header.text}
                  </span>
                  <span
                    style={{
                      ...styles.header,
                      fontWeight: 500,
                      color: "white",
                      fontSize: "1.2em",
                      marginTop: ".8em",
                    }}
                  >
                    {header.author}{" "}
                    <i style={{ fontSize: ".8em", opacity: 0.7 }}>
                      {header.role}
                    </i>
                  </span>
                </Box>
              ))}
            </Slide>
          </Box>
        </FadeIn> */}
      </Box>
      <Footer />
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: colors.defaultBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    overflow: "hidden",
  },

  bodyContainer: {
    padding: "90px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
  text: {
    color: "white",
    fontSize: "1.5em",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 400,
    marginTop: 40,
  },

  header: {
    color: "white",
    fontSize: 12,
    alignSelf: "center",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
  },
};

export default About;
