import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import JobScheduleGraph from "../jobs/JobScheduleGraph";
import {
  CancelOutlined,
  NotificationsOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { colors } from "../../../theme/colors";
import { useState } from "react";
import { handleExportJobs } from "../jobs/handleExportJobs";
import { useAuthContext } from "../../../contexts/AuthContext";
import {
  deleteOldJobs,
  saveNewNotificationSchedules,
} from "../jobs/jobMethods";

const AnnouncementsJobDisplay = ({
  fullScreen,
  eventJobs,
  event,
  setJobsVisible,
  setSnackbarOpen,
  openImportModal,
  openNotifsModal,
}) => {
  const { dbUser, authUser } = useAuthContext();
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);
  const jobOptions = [
    "Download Schedule",
    "Regenerate (beta)",
    "Import and Replace (beta)",
    "Delete (beta)",
    "Cancel",
  ];

  const groupedJobs = eventJobs
    .map((eventJob) => {
      return {
        ...eventJob,
        jobStart: parseInt(eventJob.startDate),
        jobEnd: parseInt(eventJob.endDate),
        name: eventJob.memberName,
      };
    })
    .reduce((grouped, job) => {
      (grouped[job.jobName] = grouped[job.jobName] || []).push(job);
      return grouped;
    }, {});

  // Close the menu
  const handleClose = () => {
    setSettingsMenuAnchorEl(null);
  };

  // Handle menu option click
  const handleMenuOptionClick = async (option) => {
    if (option === "Download Schedule") {
      handleExportJobs(
        eventJobs.map((eventJob) => {
          return {
            ...eventJob,
            jobStart: parseInt(eventJob.startDate),
            jobEnd: parseInt(eventJob.endDate),
            name: eventJob.memberName,
          };
        }),
        event,
        dbUser.name
      );
    } else if (option === "Regenerate (beta)") {
      setJobsVisible(true);
    } else if (option === "Delete (beta)") {
      try {
        setSnackbarOpen({});
        await deleteOldJobs(eventJobs);
        await saveNewNotificationSchedules(
          event,
          authUser?.signInUserSession.idToken.jwtToken,
          [],
          dbUser.id
        );
        setSnackbarOpen({
          type: "success",
          message: "Successfully deleted jobs.",
        });
      } catch (err) {
        setSnackbarOpen({
          type: "error",
          message: "Error deleting jobs. Please try again later.",
        });
      }
    } else if (option === "Import and Replace (beta)") {
      openImportModal();
    }
    handleClose();
  };

  return (
    <Box className="unstyled-card" sx={{ px: 2, py: 1,  }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "1.1em" }}>
          Event Jobsheet
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 3,
            alignItems: "center",
          }}
        >
          {!fullScreen ? (
            <Button
              variant="contained"
              startIcon={
                <NotificationsOutlined sx={{ color: colors.primaryText }} />
              }
              size="small"
              onClick={() => {
                openNotifsModal();
              }}
            >
              Notifications
            </Button>
          ) : null}
          <Button
            variant="contained"
            startIcon={<SettingsOutlined sx={{ color: colors.primaryText }} />}
            sx={{
              bgcolor: colors.backgroundHighlight,
              ":hover": { bgcolor: `${colors.backgroundHighlight}D6` },
            }}
            size="small"
            onClick={(e) => {
              setSettingsMenuAnchorEl(e.currentTarget);
            }}
          >
            Settings
          </Button>
          <Menu
            id="settings-menu"
            anchorEl={settingsMenuAnchorEl}
            open={Boolean(settingsMenuAnchorEl)}
            onClose={handleClose}
          >
            {jobOptions.map((option) => (
              <MenuItem
                onClick={() => handleMenuOptionClick(option)}
                key={option}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Box
        sx={{ maxHeight: "50dvh", overflow: "auto" }}
        className="scroll-container"
      >
        <JobScheduleGraph
          displayedMemberJobs={eventJobs.map((eventJob) => {
            return {
              ...eventJob,
              jobStart: parseInt(eventJob.startDate),
              jobEnd: parseInt(eventJob.endDate),
              name: eventJob.memberName,
            };
          })}
          eventStartTime={event.startDate}
          containerStyle={{}}
          surpressPopUp={fullScreen}
        />
      </Box>
    </Box>
  );
};
export default AnnouncementsJobDisplay;
