import { Box, Modal } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { cleanPhoneNumber } from "../../methods/cleanPhoneNumber";
import { DataStore } from "aws-amplify";
import { Contact } from "../../models";
import { CloudUpload } from "@mui/icons-material";
import { colors } from "../../theme/colors";

const ExcelDropzone = ({ open, close, selectedContacts, list }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const reader = new FileReader();

        reader.onload = async (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            let successCount = 0;
            let errorNames = [];
            let duplicateNumbers = [];
            let contactsPromises = []; // Store promises

            for (let i = 0; i < rows.length; i++) {
              const r = rows[i];
              if (r && r[0] && r[1]) {
                let number = cleanPhoneNumber(r[1].toString());

                // Ensure not duplicate number
                if (selectedContacts.find((c) => c.number === number)) {
                  duplicateNumbers.push(r[0]);
                  continue;
                }

                if (/^\(\d{3}\) \d{3}-\d{4}$/.test(number)) {
                  let tempNotes = "";
                  let hasMoreColumns = !!r[2];
                  let count = 2;
                  while (hasMoreColumns) {
                    tempNotes += `${r[count]}`;
                    count++;
                    hasMoreColumns = !!r[count];
                    if (hasMoreColumns) {
                      tempNotes += `, `;
                    }

                    if (count === 7) {
                      tempNotes += "...";
                      hasMoreColumns = false;
                    }
                  }

                  // Collect promises
                  contactsPromises.push(
                    DataStore.save(
                      new Contact({
                        name: r[0],
                        number: number,
                        notes: tempNotes,
                        contactListID: list.id,
                        avgScore: 0,
                        accessingUsers: list.accessingUsers,
                        coOwners: list.coOwners,
                        creatorID: list.creatorID,
                      })
                    )
                  );
                  successCount++;
                } else {
                  errorNames.push(r[0]);
                }
              }
            }

            // Resolve promises
            await Promise.all(contactsPromises);
            window.alert(
              `Saved ${successCount} contacts from spreadsheet to list. \n\nInvalid: ${
                errorNames.join(", ") || "None"
              } \nDuplicate: ${duplicateNumbers.join(", ") || "None"}`
            );

            // You can handle the successCount, errorNames, and duplicateNumbers here.
          } catch (error) {
            // Handle error here.
            console.error("An error occurred:", error);
          }
        };

        reader.readAsArrayBuffer(file);
        if (index === acceptedFiles.length - 1) {
          close();
        }
      });
    },
    [close, list?.id, selectedContacts]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".xlsx,.xls",
  });

  return (
    <Modal
      open={open}
      onClose={close}
      onClick={(e) => e.stopPropagation()}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent backdrop
      }}
    >
      <Box
        {...getRootProps()}
        sx={{
          height: "50%",
          width: "50%",
          backgroundColor: colors.secondaryBackground,
          borderRadius: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: 3,
          boxSizing: "border-box",
          border: isDragActive
            ? `2px dashed ${colors.primaryColor}`
            : `2px solid ${colors.secondaryBackground}`, // Border changes on drag active
        }}
      >
        <input {...getInputProps()} />
        <Box sx={{ mb: 2 }}>
          <CloudUpload style={{ fontSize: "10em" }} color="primary" />
        </Box>
        <h6 style={{ margin: 0, fontSize: "1.2em", color: colors.primaryText }}>
          {isDragActive ? "Drop the files here..." : "Drag files here"}
        </h6>
        <p style={{ margin: 0 }}>
          {isDragActive ? "" : "or click to select files"}
        </p>
      </Box>
    </Modal>
  );
};

export default ExcelDropzone;
