import React from "react";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ButtonBase from "@mui/material/ButtonBase";
import { colors } from "../../theme/colors";

const ContactStatisticModule = ({ title, statistic, style }) => {
  const handleClick = () => {
    if (style === "destructive") {
      alert(
        "Invalid Scans",
        "Invalid scans are likely the result of the attendee sharing his/her QR with an unauthorized party. These scans are tracked in one of two ways: attendee attempting to exceed QR scans or through manual decline at the door."
      );
    }
  };

  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        marginRight: ".5em",
        mt: ".3em",
        padding: ".25em .5em",
        position: "relative",
        display: "flex",
        flexDirection: "column",

        borderRadius: 1,
        backgroundColor: colors.defaultBackground,
        borderColor:
          style === "destructive"
            ? colors.errorColor
            : colors.defaultBackground,
        borderWidth: 1,
        textAlign: "center",
        cursor: "default !important"
      }}
    >
      {style === "destructive" && (
        <IconButton
          color="error"
          sx={{
            position: "absolute",
            right: 1,
            top: 1,
          }}
        >
          <InfoIcon />
        </IconButton>
      )}
      <h5
        style={{
          color: style === "destructive" ? colors.errorColor : colors.secondaryColor,
          margin: 0,
        }}
      >
        {statistic}
      </h5>
      <p style={{ fontSize: ".5em", color: colors.primaryText, margin: 0 }}>{title}</p>
    </ButtonBase>
  );
};

export default ContactStatisticModule;
