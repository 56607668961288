export const calculateTakeHome = (gross) => {
  //stripe fees
  let centsAfterStripe = gross * 0.971 - 30;
  let finalCents = centsAfterStripe * 0.9;
  let dollars = finalCents / 100;
  if (dollars < 0) {
    return "$0.00";
  }
  if (dollars < 100) {
    // Keep the decimal places if the amount is less than $100
    return "$" + dollars.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    // Round to the nearest dollar if the amount is $100 or more
    return "$" + dollars.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const calculateFees = (gross) => {
  let centsAfterStripe = gross * 0.971 - 30;
  let finalCents = centsAfterStripe * 0.9;
  let dollars = finalCents / 100 > 0 ? finalCents / 100 : 0;

  return parseFloat(dollars.toFixed(2));
};

export const calculateBalance = (gross) => {
  let centsAfterStripe = gross * 0.971 - 30;
  let finalCents = centsAfterStripe * 0.9;
  let dollars = finalCents / 100;

  if (dollars < 0) {
    return "$0.00";
  }
  return "$" + dollars.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatMoney = (money, type) => {
  //stripe fees
  let dollars = money / 100;
  if (type === "dollars") {
    dollars = money;
  }
  if (type === "spreadsheet") {
    if (dollars < 0) {
      return 0.0;
    } else {
      return dollars;
    }
  }
  if (dollars < 0) {
    return "0.00";
  }
  if (dollars < 100 || type === "balance") {
    // Keep the decimal places if the amount is less than $100
    return dollars.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    // Round to the nearest dollar if the amount is $100 or more
    return Math.round(dollars)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
