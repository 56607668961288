import { Box, Typography } from "@mui/material";
import { colors } from "../../theme/colors";

const AboutStorySection = () => {
  return (
    <Box
      sx={{
        bgcolor: colors.eggshell,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: { lg: "5rem", xs: "3rem" },
      }}
    >
      <Box
        className="default-page-size"
        sx={{
          display: "flex",
          gap: 6,
          flexDirection: "row-reverse",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ width: { md: "60%", sm: "100%" } }}>
          <Typography
            variant="h1"
            sx={{
              color: colors.defaultBackground,
              fontSize: { lg: "2.25rem", md: "3vw", sm: "2rem", xs: "1.5rem" },
              mb: "2rem",
            }}
          >
            Our Story
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: colors.defaultBackground,
              fontSize: { lg: "1.05rem", xs: ".9rem" },
              mb: ".75rem",
            }}
          >
            In Spring of 2021, Steve Draughn joined Sigma Alpha Epsilon at the
            University of North Carolina at Chapel Hill. During the semester, he
            was thrown headfirst into the Greek Life event process at UNC, which
            used a wristband system seen often across Greek Life in the United
            States. His lofty expectations were immediately shattered, as he
            proceeded to spend hours every week distributing wristbands just for
            gatecrashers to easily sneak in.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: colors.defaultBackground,
              fontSize: { lg: "1.05rem", xs: ".9rem" },
              mb: ".75rem",
            }}
          >
            The following summer, Steve told his best friend from high school,
            Mark Alink, about the broken process during a late night poker game.
            In a typical startup lightbulb moment, Mark and Steve came up with a
            solution: a digital system to replace old event processes. After
            many discussions with friends in Greek Life and a year of
            development, CLIQInvite was born.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: colors.defaultBackground,
              fontSize: { lg: "1.05rem", xs: ".9rem" },
              mb: ".75rem",
            }}
          >
            After only one semester of operation, CLIQInvite was helping
            fraternities run events smoothly and securely nationwide. We work
            hard for you and with you, to help you solve whatever your most
            pressing problem may be. We strive to always put our customers first
            and encourage you to give us a text or call at Steve's personal
            number, 703-424-8009, or email, skdraughn@gmail.com.
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            alignItems: { lg: "center", md: "flex-start" },
            display: { xs: "none", md: "flex" },
            overflow: "hidden",
          }}
        >
          <img
            src={require("../../assets/aboutUs/SteveDraughn.jpg")}
            style={{ width: "100%", borderRadius: 24 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AboutStorySection;
