import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Container, Paper, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Event, GuestInvite } from "../../models";
import { DataStore } from "aws-amplify";
import { useParams } from "react-router-dom";
import PublicHeader from "../../public/navigation/PublicHeader";
import { colors } from "../../theme/colors";
import Compressor from "compressorjs";
import { BeatLoader, ClipLoader } from "react-spinners";
import { Cloud } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function UploadPhotoID() {
  const { eventID, qrPrefix, noRedirect } = useParams();
  const [photoSavedToS3, setPhotoSavedToS3] = useState(false);
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [fileName, setFileName] = useState();
  const [guestInvite, setGuestInvite] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();
  const validQRPrefix = qrPrefix.length >= 60;

  useEffect(() => {
    (async () => {
      if (validQRPrefix) {
        const tempEvent = await DataStore.query(Event, eventID);
        console.log(tempEvent);
        setEvent(tempEvent);
        if (tempEvent?.id) {
          const matchingGuestInvite = await DataStore.query(GuestInvite, (g) =>
            g.and((g) => [g.eventID.eq(tempEvent.id), g.qrCode.eq(qrPrefix)])
          );
          if (matchingGuestInvite.length) {
            setGuestInvite(matchingGuestInvite[0]);
          } else setGuestInvite(undefined);
          if (
            !noRedirect &&
            matchingGuestInvite?.length &&
            matchingGuestInvite[0].photoID
          ) {
            navigate(`/ticket/${eventID}/${matchingGuestInvite[0].qrCode}`);
          }
        }
      } else setEvent(undefined);
    })();
  }, []);

  async function compressImage(file) {
    return new Promise((resolve) => {
      new Compressor(file, {
        quality: 0.15,
        maxWidth: 800,
        success(result) {
          resolve(result);
        },
        error(error) {
          console.error("Compression failed:", error.message);
          resolve(file);
        },
      });
    });
  }

  async function postToS3() {
    setPhotoSavedToS3("pending");

    if (guestInvite.photoID) {
      await axios.delete(
        `https://xwpg4g0xmj.execute-api.us-east-1.amazonaws.com/prod?id=${guestInvite.photoID}`
      );
    }

    const response = await axios.post(
      "https://xwpg4g0xmj.execute-api.us-east-1.amazonaws.com/prod",
      uploadedPhoto
    );

    if (response.status === 200) {
      saveGuestInvite(response);
    } else {
      console.error("Upload failed");
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      try {
        const file = acceptedFiles[0];
        setFileName(file.name);
        const compressedFile = await compressImage(file);
        console.log(compressedFile);
        const reader = new FileReader();

        reader.onload = async function (error) {
          const base64Data = error.target.result.split(",")[1];
          setUploadedPhoto(base64Data);
        };

        reader.readAsDataURL(compressedFile);
      } catch (error) {}
    },
    accept: "image/*", // Accept only image files
  });

  const saveGuestInvite = (response) => {
    DataStore.query(GuestInvite, (g) =>
      g.and((g) => [g.qrCode.eq(qrPrefix)])
    ).then((inv) => {
      if (inv.length) {
        let newMaxUses = inv.cost ? 0 : 1;
        let initialPhotoStatus = "PENDING";
        DataStore.save(
          GuestInvite.copyOf(inv[0], (updated) => {
            updated.maxUses = newMaxUses;
            updated.photoID = response.data.message.substring(
              0,
              response.data.message.indexOf(" ")
            );
            updated.photoStatus = initialPhotoStatus;
          })
        ).then((res) => navigate(`/ticket/${eventID}/${res.qrCode}`));
      } else {
        alert(
          "Oops, this link has exhausted all of its uses. Please contact the organization directly to receive a new link."
        );
      }
    });
  };

  if (!event || !guestInvite) {
    return (
      <div className="page-container">
        <PublicHeader />
        <Container
          maxWidth="md"
          sx={{
            height: "100dvh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {event === null || guestInvite === null ? (
            <>
              <ClipLoader color="gold" size="5em" speedMultiplier={0.5} />
              <p>Fetching event data...</p>
            </>
          ) : (
            <p>
              I'm sorry, but this link does not correspond to a valid invite
              instance. Please contact the organization to get a new link.
            </p>
          )}
        </Container>
      </div>
    );
  }

  return (
    <div className="page-container">
      <PublicHeader />
      <Container maxWidth="md" sx={{ pt: "7em" }}>
        <Paper
          elevation={3}
          style={{
            padding: theme.spacing(3),
            marginTop: theme.spacing(3),
            background: colors.defaultBackground,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant={isMobile ? "h4" : "h3"} align="center">
            <p style={{ fontWeight: 600, margin: 0 }}>Upload Your Photo ID</p>
          </Typography>
          <p
            style={{
              fontWeight: 500,
              opacity: 0.7,
              marginTop: 0,
              textAlign: "center",
            }}
          >
            In order to access your ticket to {event.name}, please upload a
            valid photo ID. Please be aware that upon scan at the door, your ID
            will be visible to the scanner along with your provided guest name:{" "}
            {guestInvite.label}
          </p>
          <div
            {...getRootProps()}
            style={{
              border: "1px dashed #ddd",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: theme.spacing(3),
              marginBottom: theme.spacing(2),
              borderRadius: "1.5em",
              minHeight: "12em",
              marginTop: "3em",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            {!uploadedPhoto ? (
              isDragActive ? (
                <p>Drop your file here...</p>
              ) : (
                <p>Drag and drop a file here, or click to select a file</p>
              )
            ) : (
              <p>Click to change file</p>
            )}
            {!uploadedPhoto ? (
              <Cloud sx={{ fontSize: "5em" }} color="primary" />
            ) : (
              <>
                <img
                  src={`data:image/jpeg;base64,${uploadedPhoto}`}
                  style={{ height: 150 }}
                />
                <p style={{ fontSize: "0.8em" }}>{fileName}</p>
              </>
            )}
          </div>
          {uploadedPhoto && photoSavedToS3 !== "pending" ? (
            <Button
              color="primary"
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                marginLeft: "auto",
              }}
              variant="contained"
              onClick={postToS3}
            >
              {!photoSavedToS3 ? "Upload" : "Success"}
            </Button>
          ) : photoSavedToS3 === "pending" ? (
            <BeatLoader
              color={colors.primaryColor}
              size="1em"
              style={{ marginLeft: "auto" }}
            />
          ) : null}
        </Paper>
      </Container>
    </div>
  );
}
