import React, { useEffect, useState } from "react";
import CircleProgress from "../../components/CircleProgress";
import { colors } from "../../theme/colors";
import { getCliqScore } from "../../methods/getCliqScore";
import { DataStore, Storage } from "aws-amplify";
import {
  Contact,
  Event,
  GuestInvite,
  Organization,
  Score,
  User,
} from "../../models";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
} from "@mui/material";
import { Edit, ExpandMore } from "@mui/icons-material";
import { useAuthContext } from "../../contexts/AuthContext";
import ContactStatisticModule from "./ContactStatisticModule";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { sum } from "../../methods/sum";
import ContactInsights from "./ContactInsights";
import ProfileImage from "../../components/ProfileImage";
import Compressor from "compressorjs";

const ContactItem = ({
  item,
  index,
  indexExpanded,
  setIndexExpanded,
  deleteContactsQueue,
  selecting,
  deleting,
  setDeleteContactsQueue,
  selectedSortOption,
  eventStatus,
  setSnackbarOpen,
  status
}) => {
  const { dbUser } = useAuthContext();

  const handleCheck = (id, isChecked) => {
    if (deleting) {
      setDeleteContactsQueue((prevDeleteContactsQueue) => {
        const newDeleteContactsQueue = new Set(prevDeleteContactsQueue);
        isChecked
          ? newDeleteContactsQueue.delete(id)
          : newDeleteContactsQueue.add(id);
        return newDeleteContactsQueue;
      });
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const [cliqScore, setCliqScore] = useState(null);
  const [eventScore, setEventScore] = useState(null);
  const [selectedContactEvents, setSelectedContactEvents] = useState(null);
  const [selectedContactScores, setSelectedContactScores] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [newScore, setNewScore] = useState(null);
  const [contactInsightsVisible, setContactInsightsVisible] = useState(false);
  const [uniqueEventIDs, setUniqueEventIDs] = useState(null);
  const [photoAnchorEl, setPhotoAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setPhotoAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setPhotoAnchorEl(null);
  };

  async function compressImage(file, maxWidth, quality) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: quality,
        maxWidth: maxWidth,
        success(result) {
          resolve(result);
        },
        error(err) {
          throw new Error("Error compressing file:", err.message);
        },
      });
    });
  }

  async function chooseImageAndUpload() {
    return new Promise((resolve, reject) => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
          reject("No file selected.");
        }
        try {
          const thumbnail = await compressImage(file, 250, 0.8);
          const fullSize = await compressImage(file, 700, 0.8);

          await Storage.put(`contact/150x150/${item.id}.jpg`, thumbnail);
          await Storage.put(`contact/700x700/${item.id}.jpg`, fullSize);
          resolve("SUCCESS");
        } catch (err) {
          reject("Error choosing and uploading file");
        }
      };
      fileInput.click();
    });
  }

  const handleAddPhoto = async (event) => {
    event.stopPropagation();
    try {
      const result = await chooseImageAndUpload();
      if (result === "SUCCESS") {
        const contactToCopy = await DataStore.query(Contact, item.id);
        await DataStore.save(
          Contact.copyOf(contactToCopy, (updated) => {
            updated.photo = `${item.id}.jpg`;
          })
        );
        setSnackbarOpen({
          type: "success",
          message: "Successfully added photo to contact.",
        });
      }
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error uploading photo. Please try again.",
      });
    } finally {
      handleMenuClose();
    }
  };

  const handleChangePhoto = async (event) => {
    event.stopPropagation();
    try {
      const result = await chooseImageAndUpload();
      if (result === "SUCCESS") {
        if (item.photo !== `${item.id}.jpg`) {
          const contactToCopy = await DataStore.query(Contact, item.id);
          await DataStore.save(
            Contact.copyOf(contactToCopy, (updated) => {
              updated.photo = `${item.id}.jpg`;
            })
          );
        }
        setSnackbarOpen({
          type: "success",
          message: "Successfully updated contact's photo.",
        });
      }
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error uploading photo. Please try again.",
      });
    } finally {
      handleMenuClose();
    }
  };

  const handleRemovePhoto = async (event) => {
    event.stopPropagation();
    try {
      const contactToCopy = await DataStore.query(Contact, item.id);
      await DataStore.save(
        Contact.copyOf(contactToCopy, (updated) => {
          updated.photo = null;
        })
      );
      for (const size of ["150x150", "700x700"]) {
        await Storage.remove(`contact/${size}/${item.id}.jpg`);
      }
      setSnackbarOpen({
        type: "success",
        message: "Contact photo deleted successfully.",
      });
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error while deleting contact photo. Please try again later.",
      });
    } finally {
      handleMenuClose();
    }
  };

  const handleClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setDeleteModalOpen(false);
    DataStore.delete(Contact, item.id);
  };
  useEffect(() => {
    setIsChecked(deleteContactsQueue.has(item.id));
  }, [deleteContactsQueue, item.id]);

  const fetchContactScores = async (contactID) => {
    const scores = await DataStore.query(Score, (s) =>
      s.contactID.eq(contactID)
    );
    const myScoreIndex = scores.findIndex(
      (score) => score.orgMemberID === dbUser.id
    );

    const myScore =
      myScoreIndex !== -1
        ? { ...scores.splice(myScoreIndex, 1)[0], orgMemberName: dbUser.name }
        : { score: null, orgMemberName: dbUser.name, orgMemberID: dbUser.id };

    const users = await Promise.all(
      scores.map((score) =>
        DataStore.query(User, score.orgMemberID).then((res) =>
          res ? res : DataStore.query(Organization, score.orgMemberID)
        )
      )
    );

    const tempScores = scores.map((score, index) => ({
      ...score,
      orgMemberName: users[index]?.name,
    }));

    setNewScore(myScore.score || 0);

    return [myScore, ...tempScores];
  };

  const fetchContactEvents = async (guestInvites, uniqueEventIDs) => {
    let invited = 0;
    let attended = 0;
    let invalid = 0;
    let used = 0;
    let scansGiven = 0;

    invited = guestInvites.length;
    scansGiven = sum(guestInvites, "maxUses");
    attended = guestInvites.filter((i) => i.numUsed).length;
    invalid = sum(guestInvites, "numInvalid");
    used = sum(guestInvites, "numUsed");

    return {
      invited: invited,
      attended: attended,
      invalid: invalid,
      used: used,
      attendedEventIDs: guestInvites
        .filter((g) => g.numUsed && uniqueEventIDs.includes(g.eventID))
        .map((g) => g.eventID),
      scansGiven: scansGiven,
    };
  };

  const handlePress = async () => {
    if (deleting || selecting) {
      setIsChecked(!isChecked);

      handleCheck(item.id, isChecked);
    } else if (
      !cliqScore ||
      !eventScore ||
      !selectedContactEvents ||
      !selectedContactScores
    ) {
      const guestInvites = await DataStore.query(GuestInvite, (g) =>
        g.contactID.eq(item.id)
      );
      const uniqueEventIDs = new Set(
        guestInvites.map((invite) => invite.eventID)
      );
      const uniqueExpiredEventIDs = (
        await Promise.all(
          Array.from(uniqueEventIDs).map(async (eventID) => {
            const event = await DataStore.query(Event, eventID);
            if (event.endDate < Date.now().toString()) return eventID;
          })
        )
      ).filter((eventID) => eventID !== undefined);
      setUniqueEventIDs(uniqueExpiredEventIDs);

      const contactScores = await fetchContactScores(item.id, dbUser.id);
      const contactEvents = await fetchContactEvents(
        guestInvites.filter((g) => uniqueExpiredEventIDs.includes(g.eventID)),
        uniqueExpiredEventIDs
      );
      setSelectedContactEvents(contactEvents);
      setSelectedContactScores(contactScores);

      let { cliqScore, eventScore } = getCliqScore(
        item.id,
        guestInvites,
        uniqueExpiredEventIDs
      );
      setCliqScore(cliqScore);
      setEventScore(eventScore);
    }
    if (!deleting && !selecting) {
      setIndexExpanded((old) => (old === index ? -1 : index));
    }
  };

  if (!dbUser?.id) {
    return <div />;
  }

  const shownScore =
    selectedSortOption === "cliqScore"
      ? item?.cliqScore || 0
      : selectedSortOption === "events"
      ? item?.eventScore || 0
      : item?.avgScore || 0;

  const shownScoreColor =
    shownScore < 50
      ? colors.errorColor
      : shownScore < 80
      ? colors.secondaryColor
      : colors.softGreen;

  let chipColor;

  switch (eventStatus) {
    case "invited":
      chipColor = "primary";
      break;
    case "attended":
      chipColor = "success";
      break;
    default:
      chipColor = "error";
      break;
  }

  return (
    <Accordion
      sx={{
        backgroundColor: colors.defaultBackground,
        mb: ".3em",
        transition: "all 1s",
      }}
      expanded={indexExpanded === index}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMore fontSize="large" sx={{ color: colors.secondaryText }} />
        }
        onClick={handlePress}
        style={{
          borderRadius: 10,
          borderBottomLeftRadius: indexExpanded === index ? 0 : 10,
          borderBottomRightRadius: indexExpanded === index ? 0 : 10,
        }}
        sx={{
          cursor: "pointer",
          backgroundColor: `${colors.secondaryBackground}`,
          padding: "0em 1em 0em .5em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ":hover": {
            backgroundColor: `${
              deleting ? colors.errorColor : colors.primaryColor
            }22`,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            padding: "0em .5em 0em 0em",
          }}
        >
          <Box
            style={{
              marginRight: 5,
              padding: 0,
              display: "flex",
              alignSelf: "center",
              alignItems: "center",
              transition: "opacity 100ms",
              position: "relative",
            }}
            sx={{
              ":hover": {
                opacity: !deleting ? 0.4 : 1,
              },
            }}
          >
            {!deleting ? (
              <>
                <IconButton onClick={handleMenuOpen}>
                  <ProfileImage
                    pic={item.photo || "default-profile.jpg"}
                    height={indexExpanded === index ? "60px" : "45px"}
                    contact={Boolean(item.photo)}
                    size="small"
                  />
                </IconButton>
                <Menu
                  id="photo-menu"
                  anchorEl={photoAnchorEl}
                  keepMounted
                  open={Boolean(photoAnchorEl)}
                  onClose={handleMenuClose}
                >
                  {!item.photo && (
                    <MenuItem onClick={handleAddPhoto}>Add Photo</MenuItem>
                  )}
                  {item.photo && [
                    <MenuItem key="change-photo" onClick={handleChangePhoto}>
                      Change Photo
                    </MenuItem>,
                    <MenuItem key="remove-photo" onClick={handleRemovePhoto}>
                      Remove Photo
                    </MenuItem>,
                  ]}
                </Menu>
              </>
            ) : (
              <Checkbox
                checked={isChecked}
                onChange={handlePress}
                color={deleting ? "error" : "primary"}
              />
            )}
          </Box>

          <div style={{}}>
            <h1 style={{ margin: 0, fontSize: "1.2em" }}>{item.name}</h1>
            <span style={{ display: "flex" }}>
              <h6
                style={{
                  margin: 0,

                  color: colors.secondaryText,
                  fontSize: ".8em",
                  fontWeight: 500,
                }}
              >
                {eventStatus ? (
                  <>
                    <Chip
                      label={eventStatus}
                      color={chipColor}
                      size="small"
                      sx={{ opacity: 0.6 }}
                    />
                    {" • "}
                  </>
                ) : (
                  ""
                )}
                {item.number}
                {item.notes ? ` • ${item.notes}` : ``}
              </h6>
            </span>
          </div>
        </div>
        <CircleProgress
          radius={20}
          bgColor={`${shownScoreColor}22`}
          score={shownScore}
          color={`${shownScoreColor}60`}
          strokeWidth={3}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: colors.secondaryBackground,
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <>
            <p
              style={{
                textAlign: "flex-start",
                width: "100%",
                marginBottom: 0,
                color: colors.secondaryText,
              }}
            >
              Member scores
            </p>
            <Box
              sx={{
                width: "100%",
                p: ".5em 0em 1em 0em",
                display: "flex",
                alignItems: "center",
              }}
            >
              {selectedContactScores?.map((item, index) => {
                return (
                  <div
                    key={item.orgMemberID || index.toString()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginRight: "1em",
                    }}
                  >
                    <CircleProgress
                      radius={24}
                      bgColor={`${colors.primaryColor}22`}
                      score={
                        item.orgMemberID === dbUser.id ? newScore : item?.score
                      }
                      color={colors.primaryColor}
                      strokeWidth={3}
                      scoreOverride={item.score === null ? "+" : undefined}
                      isPersonal={item.orgMemberID === dbUser.id}
                      setScore={setNewScore}
                      newScore={newScore}
                    />
                    <p style={{ margin: 0, textAlign: "center" }}>
                      {item?.orgMemberID === dbUser.id
                        ? "You"
                        : item?.orgMemberName?.indexOf(" ") !== -1
                        ? item?.orgMemberName?.substring(
                            0,
                            item?.orgMemberName?.indexOf(" ")
                          )
                        : item?.orgMemberName}
                    </p>
                  </div>
                );
              })}
            </Box>
            {!contactInsightsVisible ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      textAlign: "flex-start",
                      margin: 0,
                      color: colors.secondaryText,
                    }}
                  >
                    Engagement statistics{" "}
                  </p>
                  <b
                    className="link"
                    style={{
                      color: colors.secondaryColor,
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                    onClick={() => setContactInsightsVisible(true)}
                  >
                    see more
                  </b>
                </Box>
                <Box sx={{ width: "100%", mb: "2%", display: "flex" }}>
                  {selectedContactEvents?.invalid ? (
                    <ContactStatisticModule
                      key={`invalid-${selectedContactEvents?.invalid}`}
                      title="Invalid Scans"
                      statistic={selectedContactEvents?.invalid}
                      style="destructive"
                    />
                  ) : null}

                  <ContactStatisticModule
                    key={`cliqScore`}
                    title="CLIQ Score"
                    statistic={cliqScore || 0}
                  />
                  <ContactStatisticModule
                    key={`attended-${selectedContactEvents?.attended}`}
                    title="Attended"
                    statistic={selectedContactEvents?.attended || 0}
                  />
                  <ContactStatisticModule
                    key={`invited-${selectedContactEvents?.invited}`}
                    title="Invited To"
                    statistic={selectedContactEvents?.invited || 0}
                  />
                </Box>
              </>
            ) : (
              <ContactInsights
                contactEvents={selectedContactEvents}
                cliqScore={cliqScore}
                contactScore={item.avgScore}
                contactScores={selectedContactScores}
                uniqueEventIDs={uniqueEventIDs}
                close={() => setContactInsightsVisible(false)}
              />
            )}

            <TextField
              multiline
              variant="outlined"
              label="Notes"
              sx={{ width: "100%", mt: ".5em" }}
              defaultValue={item.notes}
              id={`notes-${item.id}`}
              name="notes"
              placeholder="Add Notes"
              InputProps={{
                style: {
                  color: "white",
                  fontFamily: "Poppins",
                  backgroundColor: colors.defaultBackground,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Edit />
                  </InputAdornment>
                ),
              }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                marginLeft: "auto",
                marginTop: ".5em",
              }}
            >
              {status === "OWNER" || status === "COOWNER" ? <Button
                sx={{
                  py: "1%",
                  px: "2%",
                  borderRadius: 2,
                  fontFamily: "Poppins",
                }}
                color="error"
                onClick={() => setDeleteModalOpen(true)}
              >
                Delete
              </Button> : null}
              <Button
                sx={{
                  py: "1%",
                  px: "2%",
                  borderRadius: 2,
                  fontFamily: "Poppins",
                }}
                color="primary"
                onClick={async () => {
                  const notes = document.getElementById(
                    `notes-${item.id}`
                  ).value;
                  const contact = await DataStore.query(Contact, item.id);

                  const isScoreDifferent =
                    newScore !== selectedContactScores?.[0]?.score;
                  const isScoreValid = !isNaN(newScore);

                  if (
                    (isScoreDifferent && isScoreValid) ||
                    item.notes !== notes
                  ) {
                    let newAvgScore = item.avgScore;
                    // Delete existing score
                    if (isScoreDifferent && isScoreValid) {
                      await DataStore.delete(Score, (s) =>
                        s.and((s) => [
                          s.contactID.eq(item.id),
                          s.orgMemberID.eq(dbUser.id),
                        ])
                      );

                      // Create a new Score
                      const scores = await DataStore.query(Score, (s) =>
                        s.contactID.eq(item.id)
                      );
                      const finalNewScore = await DataStore.save(
                        new Score({
                          orgMemberID: dbUser.id,
                          score: newScore,
                          contactID: item.id,
                        })
                      );

                      let totalScore = [...scores, finalNewScore].reduce(
                        (acc, score) => acc + score.score,
                        0
                      );

                      newAvgScore = Math.round(
                        totalScore / (scores.length + 1)
                      );
                    }

                    try {
                      // Update the avgScore of contact
                      await DataStore.save(
                        Contact.copyOf(contact, (updated) => {
                          updated.avgScore = newAvgScore;

                          updated.contactListID = contact.contactListID;
                          updated.notes = notes;
                        })
                      );

                      setIndexExpanded(-1);
                    } catch (err) {
                      console.error("Error updating contact avgScore:", err);
                    }
                  }
                }}

                // 2) get all scores, calculate average, update contact
              >
                Save
              </Button>
            </div>
            <Dialog open={deleteModalOpen} onClose={handleClose}>
              <DialogTitle sx={{ fontFamily: "Poppins" }}>
                Confirm Deletion
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ fontFamily: "Poppins" }}>
                  Are you sure you want to delete {item.name}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} sx={{ fontFamily: "Poppins" }}>
                  Cancel
                </Button>
                <Button
                  onClick={handleDelete}
                  color="error"
                  sx={{ fontFamily: "Poppins" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ContactItem;
