import { Insights, SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import InsightModule from "./InsightModule";
import { colors } from "../../theme/colors";
import MemberPerformanceGraph from "./MemberPerformanceGraph";
import { formatMoney } from "../../methods/calculateTakeHome";

const InsightsMemberHighlightDisplay = ({
  timeFrame,
  members,
  events,
  id,
  selectedMemberID,
}) => {
  const [highlightedMembers, setHighlightedMembers] = useState();
  const [selectedMember, setSelectedMember] = useState('');
  const [timeFrameEvents, setTimeFrameEvents] = useState(null);

  useEffect(() => {
    if (events) {
      setTimeFrameEvents(
        events.filter((e) => e.startDate > timeFrame.toString()).length
      );
    }
  }, [events, timeFrame]);

  useEffect(() => {
    if (members?.length) {
      setSelectedMember(
        selectedMemberID
          ? members?.filter((m) => m.id === selectedMemberID)?.[0]
          : members[0]
      );
    }
  }, [selectedMemberID, members]);

  useEffect(() => {
    if (members?.length) {
      // Initialize the highlighted members object
      const highlights = {
        mostInvitesMember: null,
        leastInvitesMember: null,
        mostQrScannedMember: null,
        worstQrScanRateMember: members[0], //above 0
        bestQrScanRateMember: null,
        mostInvalidScansMember: null,
        bestAvgInvitesMember: null,
        bestAvgScansMember: null,
        mostRevenueMember: null,
        mostTicketsSoldMember: null,
      };

      // Variable to check if any member has totalInvites > 0

      // Iterate through members and set the highlighted members based on their stats
      members.forEach((member) => {
        const {
          totalInvites,
          qrScanned,
          qrScanRate,
          invalidScans,
          avgInvites,
          avgValidScans,
          ticketsSold,
          revenue,
        } = member;

        const updateHighlight = (key, condition) => {
          if (condition) highlights[key] = member;
        };

        updateHighlight(
          "mostInvitesMember",
          !highlights.mostInvitesMember ||
            totalInvites > highlights.mostInvitesMember.totalInvites
        );
        updateHighlight(
          "leastInvitesMember",
          !highlights.leastInvitesMember ||
            totalInvites <= highlights.leastInvitesMember.totalInvites
        );
        updateHighlight(
          "bestAvgInvitesMember",
          !highlights.bestAvgInvitesMember ||
            avgInvites >= highlights.bestAvgInvitesMember.avgInvites
        );

        updateHighlight(
          "worstQrScanRateMember",
          (totalInvites > 0 && !highlights.worstQrScanRateMember) ||
            parseInt(qrScanRate) <=
              parseInt(highlights.worstQrScanRateMember.qrScanRate)
        );
        updateHighlight(
          "bestAvgScansMember",
          !highlights.bestAvgScansMember ||
            parseInt(avgValidScans) >=
              parseInt(highlights.bestAvgScansMember.avgValidScans)
        );
        updateHighlight(
          "mostQrScannedMember",
          !highlights.mostQrScannedMember ||
            qrScanned > highlights.mostQrScannedMember.qrScanned
        );

        updateHighlight(
          "mostRevenueMember",
          !highlights.mostRevenueMember ||
            parseInt(revenue) >= parseInt(highlights.mostRevenueMember.revenue)
        );
        updateHighlight(
          "mostTicketsSoldMember",
          !highlights.mostTicketsSoldMember ||
            ticketsSold > highlights.mostTicketsSoldMember.ticketsSold
        );

        if (totalInvites) {
          updateHighlight(
            "bestQrScanRateMember",
            !highlights.bestQrScanRateMember ||
              parseInt(qrScanRate) >
                parseInt(highlights.bestQrScanRateMember.qrScanRate)
          );
        }

        updateHighlight(
          "mostInvalidScansMember",
          !highlights.mostInvalidScansMember ||
            invalidScans > highlights.mostInvalidScansMember.invalidScans
        );
      });

      setHighlightedMembers(highlights);
    }
  }, [timeFrame, members]);

  const handleChange = (member) => {
    setSelectedMember(member);
  };

  if (timeFrameEvents === null)
    return (
      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
            <Skeleton height="2em" />
            <Skeleton height="10em" variant="rounded" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
            <Skeleton height="2em" />
            <Skeleton height="14em" variant="rounded" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
            <Skeleton height="2em" />
            <Skeleton height="50dvh" variant="rounded" />
          </Box>
        </Grid>
      </Grid>
    );

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={6}>
        <Box className="unstyled-card" sx={{ px: 2, py: 1 }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: "1.2em",
              mb: 2,
            }}
          >
            Member Highlights
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InsightModule
                header={"Most Invites"}
                statistic={highlightedMembers?.mostInvitesMember?.totalInvites}
                subheader={highlightedMembers?.mostInvitesMember?.name}
              />
            </Grid>
            <Grid item xs={8}>
              <InsightModule
                header={"Most Scanned"}
                statistic={highlightedMembers?.mostQrScannedMember?.qrScanned}
                subheader={highlightedMembers?.mostQrScannedMember?.name}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={"Best Scan Rate"}
                statistic={`${
                  highlightedMembers?.bestQrScanRateMember?.qrScanRate || 0
                }%`}
                subheader={highlightedMembers?.bestQrScanRateMember?.name}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={"Least Invites"}
                statistic={highlightedMembers?.leastInvitesMember?.totalInvites}
                subheader={highlightedMembers?.leastInvitesMember?.name}
              />
            </Grid>

            <Grid item xs={4}>
              <InsightModule
                header={"Most Invalid Scans"}
                statistic={
                  highlightedMembers?.mostInvalidScansMember?.invalidScans
                }
                subheader={highlightedMembers?.mostInvalidScansMember?.name}
              />
            </Grid>
            <Grid item xs={8}>
              <InsightModule
                header={"Most Revenue"}
                statistic={`$${formatMoney(
                  (highlightedMembers?.mostRevenueMember?.revenue * 100) || 0,
                  "balance"
                )}`}
                subheader={highlightedMembers?.mostRevenueMember?.name}
              />
            </Grid>

            <Grid item xs={4}>
              <InsightModule
                header={"Most Invalid Scans"}
                statistic={
                  highlightedMembers?.mostTicketsSoldMember?.ticketsSold
                }
                subheader={highlightedMembers?.mostTicketsSoldMember?.name}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className="unstyled-card" sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: "flex", gap: 3, mb: 1, width: "100%" }}>
            <Typography
              variant="h1"
              style={{
                fontSize: "1.2em",
              }}
            >
              Member Data
            </Typography>
            <Autocomplete
              id="select-member"
              options={members || []}
              disableClearable={true}
              getOptionLabel={(option) => option.name}
              value={selectedMember || {name: 'No Members'}}
              onChange={(event, newValue) => handleChange(newValue)}
              sx={{ flex: 1, color: "white" }}
              ListboxProps={{
                sx: {
                  bgcolor: colors.secondaryBackground,
                  border: "none",
                  outline: "none",
                  fontSize: ".8em",
                  color: colors.secondaryText,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Member"
                  size="small"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <SearchOutlined
                        sx={{ color: "white", fontSize: "1.2em", mr: 1 }}
                      />
                    ),
                    sx: { color: "white" },
                  }}
                  sx={{
                    bgcolor: colors.backgroundHighlight,
                    borderRadius: 1,
                    color: "white",
                  }}
                />
              )}
            />
          </Box>
          <Grid container spacing={1} id={id}>
            <Grid item xs={8}>
              <InsightModule
                header={"Total Invites"}
                statistic={selectedMember?.totalInvites}
                subheader={selectedMember?.name}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={"Avg Invites"}
                statistic={
                  timeFrameEvents === 0
                    ? "0"
                    : (selectedMember?.totalInvites / timeFrameEvents).toFixed(
                        1
                      )
                }
                subheader={selectedMember?.name}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={"Avg Scanned"}
                statistic={
                  timeFrameEvents === 0
                    ? "0"
                    : (selectedMember?.qrScanned / timeFrameEvents).toFixed(1)
                }
                subheader={selectedMember?.name}
              />
            </Grid>
            <Grid item xs={8}>
              <InsightModule
                header={"Total Scanned"}
                statistic={selectedMember?.qrScanned}
                subheader={selectedMember?.name}
              />
            </Grid>
            <Grid item xs={8}>
              <InsightModule
                header={"QR Scan Rate"}
                statistic={`${selectedMember?.qrScanRate || 0}%`}
                subheader={selectedMember?.name}
              />
            </Grid>

            <Grid item xs={4}>
              <InsightModule
                header={"Invalid Scans"}
                statistic={selectedMember?.invalidScans}
                subheader={selectedMember?.name}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header={`Ticket${
                  selectedMember?.ticketsSold !== 1 ? "s" : ""
                } Sold`}
                statistic={selectedMember?.ticketsSold}
                subheader={selectedMember?.name}
              />
            </Grid>
            <Grid item xs={8}>
              <InsightModule
                header={"Total Revenue"}
                statistic={`$${formatMoney(
                  selectedMember?.revenue || 0,
                  "dollars"
                )}`}
                subheader={selectedMember?.name}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className="unstyled-card" sx={{ px: 2, py: 1, mb: 3 }}>
          <Typography variant="h1" sx={{ fontSize: "1.2em" }}>
            Member Performance over Time
          </Typography>
          <Typography variant="body2" sx={{ fontSize: ".8em", mb: 2 }}>
            Hover over any data point to inspect it closer and click on an item
            in the legend to remove it from the graph
          </Typography>
          <MemberPerformanceGraph
            selectedMember={selectedMember}
            events={events}
            timeFrame={timeFrame}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default InsightsMemberHighlightDisplay;
