import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import { Add } from "@mui/icons-material";

const DefaultPaymentMethodModule = ({
  shouldDisplayPaymentMethod,
  paymentMethod,
  brandImage,
  handleAddPaymentMethod
}) => {
  const noDefaultPaymentMethod = shouldDisplayPaymentMethod && !paymentMethod;
  return (
    <Box
      sx={{
        bgcolor: colors.defaultBackground,
        flex: 1,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        filter: shouldDisplayPaymentMethod ? "none" : "blur(3px)",
        transition: "filter 0.5s ease-in-out",
      }}
      style={{ aspectRatio: 4 / 2 }}
    >
      <Box
        sx={{ height: "2em", bgcolor: `${colors.primaryColor}22`, mt: 2 }}
      ></Box>

      <Box
        sx={{
          p: 2,
          alignItems: "center",
        }}
      >
        {noDefaultPaymentMethod ? (
          <Typography variant="body2" sx={{ fontSize: "1em", flex: 1 }}>
            No default payment method
          </Typography>
        ) : (
          <>
            <Typography variant="body2" sx={{ fontSize: "1.3em", flex: 1 }}>
              Card ending in:{" "}
              <b style={{ color: colors.primaryText }}>
                {shouldDisplayPaymentMethod
                  ? paymentMethod?.lastFourDigits
                  : "1234"}
              </b>
            </Typography>
            <Typography variant="body2">
              Expiry:{" "}
              {shouldDisplayPaymentMethod ? paymentMethod?.expiry : "XX/XX"}
            </Typography>
          </>
        )}
      </Box>

      <Box
        sx={{
          p: 2,
          pb: 1.5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {noDefaultPaymentMethod ? (
          <Button
            size="small"
            sx={{ ml: "auto" }}
            onClick={handleAddPaymentMethod}
          >
            Add
          </Button>
        ) : (
          <>
            <img
              src={
                shouldDisplayPaymentMethod && brandImage
                  ? brandImage
                  : require("../../assets/CLIQLogoTransparent.png")
              }
              style={{
                width:
                  paymentMethod && paymentMethod?.brand === "amex"
                    ? "10%"
                    : "15%",
                backgroundColor:
                  paymentMethod && paymentMethod?.brand === "discover"
                    ? "silver"
                    : "transparent",

                borderRadius: 5,
              }}
            />
            <Typography variant="body1" sx={{ color: colors.secondaryColor }}>
              Default
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DefaultPaymentMethodModule;
