import { Add, LockClockOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";

const AnnouncementsHeader = ({ event, createAnnouncement, setJobsVisible, alreadyHasJobs }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h1" style={{ fontSize: "1.3em" }}>
          Event Announcements
        </Typography>
        <Typography variant="body2">for {event.name}</Typography>
      </Box>
      <Box sx={{ gap: 1, display: "flex" }}>
        {alreadyHasJobs ? null : (
          <Button
            variant="contained"
            startIcon={<LockClockOutlined sx={{ color: colors.primaryText }} />}
            sx={{
              bgcolor: colors.backgroundHighlight,
              ":hover": {
                bgcolor: `${colors.backgroundHighlight}D6`,
              },
            }}
            onClick={() => setJobsVisible(true)}
          >
            Add Jobs (beta)
          </Button>
        )}
        <Button
          variant="contained"
          startIcon={<Add sx={{ color: colors.primaryText }} />}
          onClick={createAnnouncement}
        >
          Create Announcement
        </Button>
      </Box>
    </Box>
  );
};

export default AnnouncementsHeader;
