import { Edit } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useState } from "react";

const CircleProgress = ({
  score,
  radius,
  strokeWidth,
  color,
  bgColor,
  scoreOverride,
  isPersonal,
  setScore,
  newScore,
}) => {
  const circumference = 2 * Math.PI * (radius - strokeWidth / 2);
  const progress = score / 100;
  const strokeDashoffset = circumference * (1 - progress);
  const [editing, setEditing] = useState(false);

  return (
    <div
      onClick={() => {
        if (isPersonal) {
          setEditing(true);
        }
      }}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: radius * 2,
      }}
    >
      <svg width={radius * 2} height={radius * 2}>
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="transparent"
          stroke={bgColor}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="transparent"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          transform={`rotate(-90, ${radius}, ${radius})`}
        />
        {isPersonal && !scoreOverride && (
          <foreignObject
            x={radius * 1.4}
            y={radius * 1.4}
            width={radius}
            height={radius}
          >
            <Edit style={{ fontSize: radius / 1.5, color: "gold" }} />
          </foreignObject>
        )}
      </svg>
      {!editing ? (
        <p
          className="score-text"
          style={{
            fontSize: radius / 1.5,
            position: "absolute",
          }}
        >
          {!scoreOverride ? (
            isNaN(score) ? (
              0
            ) : (
              score?.toFixed(0)
            )
          ) : (
            <Edit style={{ fontSize: radius, color: "gold" }} />
          )}
        </p>
      ) : (
        <TextField
          sx={{ position: "absolute", borderRadius: "100%" }}
          inputMode="numeric"
          value={newScore}
          inputProps={{ maxLength: 3 }}
          InputProps={{ style: { fontSize: radius / 1.5 } }}
          onChange={(e) => {
            let string = e.target.value;
            if (!string) {
              setScore("");
            }
            if (!isNaN(parseInt(string))) {
              let newScore = parseInt(string);
              if (newScore >= 100) setScore(100);
              else if (newScore <= 0) setScore(0);
              else setScore(newScore);
            }
          }}
        />
      )}
    </div>
  );
};

export default CircleProgress;
