import PublicHeader from "../../navigation/PublicHeader";
import Footer from "../../../components/Footer";
import PhilanthropyOverviewHeroSection from "./PhilanthropyOverviewHeroSection";
import PhilanthropyCapabilitiesSection from "./PhilanthropyCapabilitiesSection";
import PhilanthropyActionSection from "./PhilanthropyActionSection";
import PhilanthropyFAQSection from "./PhilanthropyFAQSection";

const PhilanthropyOverview = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        overflowX: "none",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
        }}
      >
        <PublicHeader />

        <PhilanthropyOverviewHeroSection />
        <PhilanthropyCapabilitiesSection />
        {/* <PhilanthropyTestimonialSection /> */}
        {/* <PhilanthropyStatisticsSection /> */}
        <PhilanthropyActionSection />
        <PhilanthropyFAQSection />
        <Footer paddingTop={"2em"} />
      </div>
    </div>
  );
};
export default PhilanthropyOverview;
