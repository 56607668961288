import { useEffect, useState } from "react";
import "./MemberDisplay.css";
import { DataStore, SortDirection } from "aws-amplify";
import { useAuthContext } from "../../contexts/AuthContext";
import { OrganizationMember } from "../../models";
import MemberModule from "./MemberModule";
import {
  Alert,
  Button,
  Grid,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { colors } from "../../theme/colors";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import MemberDisplayHeader from "./MemberDisplayHeader";
import { Helmet } from "react-helmet-async";
import GroupsDisplay from "./GroupsDisplay";
import MemberFiltersDisplay from "./MemberFiltersDisplay";
import { BeatLoader } from "react-spinners";

const MemberDisplay = () => {
  const { dbUser } = useAuthContext();
  const [members, setMembers] = useState();
  const [filteredMembers, setFilteredMembers] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [selectedMembersQueue, setSelectedMembersQueue] = useState(new Set([]));
  const [pendingMembers, setPendingMembers] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState("first");

  useEffect(() => {
    const fetchMembers = async () => {
      const orgMembers = await DataStore.query(
        OrganizationMember,
        (m) => m.organizationID.eq(dbUser?.id),
        { sort: (m) => m.role(SortDirection.ASCENDING) }
      );

      setMembers(orgMembers.filter((m) => m.isConfirmed));
      setPendingMembers(orgMembers.filter((m) => !m.isConfirmed));
      setReRender((old) => !old);
    };

    if (dbUser) {
      fetchMembers();

      const orgMemberSubscription = DataStore.observe(
        OrganizationMember,
        (m) => m.organizationID.eq(dbUser.id),
        { sort: (m) => m.role(SortDirection.ASCENDING) }
      ).subscribe((msg) => {
        if (
          msg.opType === "UPDATE" ||
          msg.opType === "INSERT" ||
          msg.opType === "DELETE"
        ) {
          fetchMembers();
        }
      });

      return () => orgMemberSubscription.unsubscribe();
    }
  }, [dbUser]);

  useEffect(() => {
    filterMembers();
  }, [members, selectedFilters, selectedSortOption]);

  function filterMembers() {
    // If there are no members, no need to continue
    if (!members) {
      return;
    }

    // Get role and other filters
    let roleFilters = selectedFilters.filter((f) => f.type === "ROLE");
    let otherFilters = selectedFilters.filter((f) => f.type !== "ROLE");

    let filteredMembers = members.filter((member) => {
      // Apply other filters
      for (const filter of otherFilters) {
        if (
          filter.type === "NAME" &&
          !member.memberInfo[0]
            .toLowerCase()
            .includes(filter.filter.toLowerCase())
        ) {
          return false;
        }
      }

      // Apply role filters, if any
      if (
        roleFilters.length &&
        !roleFilters.find((r) => r.filter === member.role)
      ) {
        return false;
      }

      return true;
    });

    filteredMembers = filteredMembers.sort((a, b) => {
      // Split the name into first name and last name
      const aNames = a.memberInfo[0].split(" ");
      const bNames = b.memberInfo[0].split(" ");

      if (selectedSortOption === "first") {
        // Check if both have a last name
        if (aNames.length < 2 && bNames.length >= 2) {
          return 1; // a comes after b
        } else if (aNames.length >= 2 && bNames.length < 2) {
          return -1; // a comes before b
        }

        // Compare first names
        return aNames[0].localeCompare(bNames[0]);
      } else if (selectedSortOption === "last") {
        // If first names are the same or both don't have last names, sort by last name
        if (aNames.length >= 2 && bNames.length >= 2) {
          return aNames[1].localeCompare(bNames[1]);
        }
      } else {
        // If first and last names are the same or both don't have last names, sort by role
        return a.role.localeCompare(b.role);
      }
    });

    setFilteredMembers(filteredMembers);
  }

  const handleAssignMembers = async (role) => {
    setSnackbarOpen(null);
    let errorCount = 0;
    let toBeAssigned = selectedMembersQueue.size;
    let newQueue = new Set(selectedMembersQueue);
    for (const member of selectedMembersQueue) {
      try {
        const memberToBeAssigned = await DataStore.query(
          OrganizationMember,
          member
        );
        await DataStore.save(
          OrganizationMember.copyOf(memberToBeAssigned, (updated) => {
            updated.role = role;
          })
        );
        newQueue.delete(member);
      } catch (err) {
        errorCount++;
      }
    }
    setSelectedMembersQueue(newQueue);
    if (errorCount > 0) {
      setSnackbarOpen({
        type: "error",
        message: `Failed to assign ${errorCount} members. Please try again.`,
        action: (
          <Button
            onClick={() => setSnackbarOpen(null)}
            size="small"
            color="inherit"
          >
            Ok
          </Button>
        ),
      });
    } else {
      setSelecting(false);

      setSnackbarOpen({
        type: "success",
        message: `Successfully assigned ${toBeAssigned} member${
          toBeAssigned === 1 ? "" : "s"
        } to group ${role}.`,
        action: (
          <Button
            onClick={() => {
              setSnackbarOpen(null);
            }}
            size="small"
            color="inherit"
          >
            Ok
          </Button>
        ),
      });
    }
  };

  const pageTitle = `${
    dbUser?.name ? dbUser.name : "Organization"
  } Members • CLIQInvite`;
  const pageDescription = `Harness complete control over your organization's members with CLIQInvite. Manage memberships, coordinate activities, and ensure secure communication. Empower your organization with our comprehensive member management tools.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/members`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <WebPortalNavigation activeScreen="/members" />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={30000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          severity={snackbarOpen?.type}
          action={snackbarOpen?.action}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>

      <Grid
        container
        className="page-size-large"
        sx={{ alignSelf: "center", marginTop: "2em" }}
        columnSpacing={1}
      >
        <Grid item xs={12}>
          <MemberDisplayHeader
            handleAssignMembers={handleAssignMembers}
            members={members}
            setSnackbarOpen={setSnackbarOpen}
            setSelecting={setSelecting}
            selecting={selecting}
            setSelectedMembersQueue={setSelectedMembersQueue}
            selectedMembersQueue={selectedMembersQueue}
            selectedSortOption={selectedSortOption}
            setSelectedSortOption={setSelectedSortOption}
          />
        </Grid>
        <Grid item xs={6.5} style={{ gap: ".5em" }}>
          {selecting ? null : pendingMembers?.length ? (
            <h3
              style={{
                margin: 0,
                color: colors.secondaryText,
                fontWeight: 500,
                fontStyle: "italic",
                marginBottom: ".5em",
              }}
            >
              Pending
            </h3>
          ) : null}
          {selecting
            ? null
            : pendingMembers?.map((pendingMember) => {
                return (
                  <Grid item xs={12} key={pendingMember.id}>
                    <MemberModule
                      member={pendingMember}
                      isPending
                      key={pendingMember.id}
                      selecting={selecting}
                      selectedMembersQueue={selectedMembersQueue}
                      setSelectedMembersQueue={setSelectedMembersQueue}
                    />
                  </Grid>
                );
              })}
          {selecting ? null : pendingMembers?.length && members?.length ? (
            <h3
              style={{
                color: colors.primaryText,
                color: colors.secondaryText,
                fontWeight: 500,
                marginBottom: ".5em",
                fontStyle: "italic",
              }}
            >
              Confirmed
            </h3>
          ) : null}
          {filteredMembers
            ? filteredMembers?.map((member) => {
                return (
                  <Grid item xs={12} key={member.id}>
                    <MemberModule
                      member={member}
                      role={member?.role}
                      key={member.id}
                      selecting={selecting}
                      selectedMembersQueue={selectedMembersQueue}
                      setSelectedMembersQueue={setSelectedMembersQueue}
                    />
                  </Grid>
                );
              })
            : members
            ? members?.map((member) => {
                return (
                  <Grid item xs={12} key={member.id}>
                    <MemberModule
                      member={member}
                      role={member?.role}
                      key={member.id}
                      selecting={selecting}
                      selectedMembersQueue={selectedMembersQueue}
                      setSelectedMembersQueue={setSelectedMembersQueue}
                    />
                  </Grid>
                );
              })
            : Array.from({ length: 20 }, (_, i) => (
                <Skeleton
                  key={i.toString()}
                  variant="rounded"
                  sx={{ height: "4em", mb: 1 }}
                />
              ))}
          {!filteredMembers?.length &&
          !members?.length &&
          !pendingMembers?.length ? (
            <Typography variant="body2">No members yet</Typography>
          ) : null}
        </Grid>
        <Grid
          item
          xs={5.5}
          style={{
            gap: ".5em",
          }}
        >
          <MemberFiltersDisplay
            filteredMembers={filteredMembers ? filteredMembers : members}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <GroupsDisplay
            setSelecting={setSelecting}
            setFilteredMembers={setFilteredMembers}
            members={members}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setSnackbarOpen={setSnackbarOpen}
            selecting={selecting}
            handleAssignMembers={handleAssignMembers}
            selectedMembersQueue={selectedMembersQueue}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MemberDisplay;
