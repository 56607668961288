export function cleanPhoneNumber(phoneNumber) {
  if (!phoneNumber) return "Loading...";
  // Remove non-numeric characters
  phoneNumber = phoneNumber.replace(/[^\d]/g, "");

  // Check for country code
  if (phoneNumber.length === 11 && phoneNumber.startsWith("1")) {
    phoneNumber = phoneNumber.slice(1);
  } else if (phoneNumber.length === 12 && phoneNumber.startsWith("01")) {
    phoneNumber = phoneNumber.slice(2);
  }

  // Format the phone number
  phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

  return phoneNumber;
}
