import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { colors } from "../../theme/colors";
import { Add } from "@mui/icons-material";
import { useAuthContext } from "../../contexts/AuthContext";
import VisaLogo from "../../assets/cardBrands/Visa.png";
import MasterCardLogo from "../../assets/cardBrands/MasterCard.png";
import AmericanExpressLogo from "../../assets/cardBrands/AmericanExpress.png";
import DiscoverLogo from "../../assets/cardBrands/DiscoverLogo.png";
import axios from "axios";
import ChangePaymentMethodDialog from "./ChangePaymentMethodDialog";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import NonDefaultPaymentMethodModule from "./NonDefaultPaymentMethodModule";
import DefaultPaymentMethodModule from "./DefaultPaymentMethodModule";

const stripePublishableKey =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_LIVE_KEY
    : process.env.REACT_APP_STRIPE_TEST_KEY;

const stripePromise = loadStripe(stripePublishableKey);

const PaymentMethodDisplay = ({ loading, plan, setSnackbarOpen }) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [nonDefaultPaymentMethods, setNonDefaultPaymentMethods] =
    useState(null);
  const [paymentMethodVisible, setPaymentMethodVisible] = useState(false);
  const [changePaymentMethodVisible, setChangePaymentMethodVisible] =
    useState(false);
  const { authUser } = useAuthContext();

  const retrievePaymentMethod = async () => {
    const token = authUser?.signInUserSession.idToken.jwtToken;

    try {
      setSnackbarOpen({
        type: "info",
        message: "Retrieving payment methods",
      });
      const res = await axios.get(
        process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
        {
          params: {
            subscriptionID: plan.stripeSubscriptionID,
            test: process.env.NODE_ENV !== "production",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSnackbarOpen({
        type: "success",
        message: "Successfully retrieved payment methods",
      });

      setPaymentMethodVisible(true);
      setPaymentMethod(res.data.defaultPaymentMethod);
      setNonDefaultPaymentMethods(res.data.nonDefaultPaymentMethods);
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message:
          "Failed to retrieve payment methods. Please try again, and if the issue persists, set up a new payment method.",
      });
    }
  };

  const shouldDisplayPaymentMethod =
    paymentMethodVisible && (paymentMethod || nonDefaultPaymentMethods);
  const brandImages = {
    visa: VisaLogo,
    mastercard: MasterCardLogo,
    amex: AmericanExpressLogo,
    discover: DiscoverLogo,
  };

  return (
    <Box
      className="unstyled-card"
      sx={{
        px: 2,
        py: 1,
        pb: 2,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {loading ? (
        <>
          <Skeleton sx={{ fontSize: "2.5em", mb: 2 }} />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h1" sx={{ mt: ".2em", fontSize: "1.2em" }}>
              Payment Methods
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: 2, fontSize: ".8em", mt: ".2em" }}
            >
              View and update your account's payment methods
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
            }}
            onClick={() => {
              shouldDisplayPaymentMethod
                ? setPaymentMethodVisible(false)
                : paymentMethod
                ? setPaymentMethodVisible(true)
                : retrievePaymentMethod();
            }}
          >
            {!shouldDisplayPaymentMethod ? "Reveal" : "Hide"}
          </Button>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={5}>
          {loading ? (
            <Skeleton variant="rounded" sx={{ fontSize: "10em" }} />
          ) : (
            <DefaultPaymentMethodModule
              shouldDisplayPaymentMethod={shouldDisplayPaymentMethod}
              paymentMethod={paymentMethod}
              brandImage={brandImages[paymentMethod?.brand]}
              handleAddPaymentMethod={() => setChangePaymentMethodVisible(true)}
            />
          )}
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              filter:
                shouldDisplayPaymentMethod || loading ? "none" : "blur(3px)",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              transition: "filter 0.5s ease-in-out",
            }}
          >
            {loading ? (
              <Skeleton variant="text" sx={{ fontSize: "1.2em" }} />
            ) : (
              <Typography variant="h3">Additional Payment Methods</Typography>
            )}
            {loading ? (
              <Skeleton variant="rounded" sx={{ fontSize: "7em" }} />
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    bgcolor: colors.backgroundHighlight,
                    borderRadius: 1,
                    my: 1,
                    mt: 2,
                  }}
                >
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", fontSize: ".8em" }}
                    >
                      Brand
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", fontSize: ".8em" }}
                    >
                      Last 4 Digits
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", fontSize: ".8em" }}
                    >
                      Expiry
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", fontSize: ".8em" }}
                    >
                      More
                    </Typography>
                  </Grid>
                </Grid>
                {shouldDisplayPaymentMethod &&
                nonDefaultPaymentMethods?.length ? (
                  <>
                    {nonDefaultPaymentMethods.map((nonDefaultPaymentMethod) => (
                      <NonDefaultPaymentMethodModule
                        key={nonDefaultPaymentMethod.id}
                        paymentMethod={nonDefaultPaymentMethod}
                        brandImage={brandImages[nonDefaultPaymentMethod.brand]}
                        setSnackbarOpen={setSnackbarOpen}
                        setNonDefaultPaymentMethods={
                          setNonDefaultPaymentMethods
                        }
                        setDefaultPaymentMethod={setPaymentMethod}
                        plan={plan}
                        defaultPaymentMethodID={paymentMethod.id}
                      />
                    ))}
                    <Box sx={{ height: "1em" }}></Box>
                  </>
                ) : (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    No additional payment methods available
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: "auto" }}
                  startIcon={<Add sx={{ color: "white" }} />}
                  onClick={() => setChangePaymentMethodVisible(true)}
                >
                  Add
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Elements
        stripe={stripePromise}
        options={{
          mode: "setup",
          currency: "usd",
          payment_method_types: ["card"],
        }}
      >
        <ChangePaymentMethodDialog
          isVisible={changePaymentMethodVisible}
          close={() => setChangePaymentMethodVisible(false)}
          setSnackbarOpen={setSnackbarOpen}
          plan={plan}
        />
      </Elements>
    </Box>
  );
};

export default PaymentMethodDisplay;
