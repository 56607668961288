import { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useAuthContext } from "../../contexts/AuthContext";

const CancelPlan = ({ plan, setSnackbarOpen, close }) => {
  const { authUser } = useAuthContext();

  const [comment, setComment] = useState("");
  const [feedback, setFeedback] = useState("");

  const feedbackOptions = {
    too_expensive: "It’s too expensive",
    missing_features: "Some features are missing",
    switched_service: "I’m switching to a different service",
    unused: "I don’t use the service enough",
    customer_service: "Customer service was less than expected",
    too_complex: "Ease of use was less than expected",
    low_quality: "Quality was less than expected",
    other: "Other reason",
  };

  const handleUpdateSubscription = async () => {
    const token = authUser?.signInUserSession.idToken.jwtToken;
    try {
      setSnackbarOpen({
        type: "error",
        message: "Cancelling subscription plan",
      });
      const res = await axios.delete(
        process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            type: "cancelSubscription",
            objectID: plan.stripeSubscriptionID,
            cancellationDetails: {
              comment,
              feedback: Object.keys(feedbackOptions).find(
                (key) => feedbackOptions[key] === feedback
              ),
            },
            test: process.env.NODE_ENV !== "production",
          },
        }
      );

      setSnackbarOpen({
        type: "success",
        message: "Successfully cancelled subscription plan",
      });
      close();
    } catch (err) {
      console.log(err);
      setSnackbarOpen({
        type: "error",
        message:
          "Failed to cancel subscription plan. Please try again, and if the issue persists, please contact support.",
      });
    }
  };

  const handleCancelPress = () => {
    setSnackbarOpen({
      type: "error",
      message:
        "Are you sure you want to cancel your organization's subscription plan?",
      action: (
        <Button size="small" color="inherit" onClick={handleUpdateSubscription}>
          Cancel
        </Button>
      ),
    });
  };

  return (
    <Box sx={{ pt: 1 }}>
      <Typography variant="body2" sx={{ fontSize: "1em" }}>
        Are you sure you want to cancel your plan?
      </Typography>

      <TextField
        select
        labelId="feedback-label"
        id="feedback"
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        SelectProps={{
          MenuProps: {
            PaperProps: { sx: { bgcolor: colors.secondaryBackground } },
          },
        }}
        sx={{ width: "100%", bgcolor: colors.backgroundHighlight, mt: 1 }}
        label="Feedback"
      >
        {Object.entries(feedbackOptions).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="comment"
        label="Additional comments"
        multiline
        rows={4}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        variant="outlined"
        sx={{
          width: "100%",
          mt: 1,
          bgcolor: colors.backgroundHighlight,
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button sx={{ color: colors.secondaryText }} onClick={close}>
          Close
        </Button>
        <Button color="error" onClick={handleCancelPress}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default CancelPlan;
