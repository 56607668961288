import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { ContactList } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";
import NewListModal from "./NewListModal";

import WebPortalNavigation from "../../components/WebPortalNavigation";
import { Helmet } from "react-helmet-async";
import ListDisplayHeader from "./ListDisplayHeader";
import RecentlyEditedListsDisplay from "./RecentlyEditedListsDisplay";
import ListEventsDisplay from "./ListEventsDisplay";
import HelpDialog from "../../pages/help/HelpDialog";
import AllListsDisplay from "./AllListsDisplay";
import { Alert, Grid, Snackbar } from "@mui/material";
import NewsDisplay from "../../components/NewsDisplay";
import { contactsNews } from "../../assets/contactsNews";

const ContactsListDisplay = () => {
  const { dbUser } = useAuthContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [contactLists, setContactLists] = useState(null);
  const [numTotalContacts, setNumTotalContacts] = useState(0);
  const [numUniqueEditors, setNumUniqueEditors] = useState(0);
  const [displayedLists, setDisplayedLists] = useState(null);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [allListsDialogOpen, setAllListsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(null);

  const loading =
    Boolean(!dbUser) || Boolean(!contactLists) || Boolean(!displayedLists);
  useEffect(() => {
    const fetchContactLists = async () => {
      const contactLists = await DataStore.query(
        ContactList,
        (c) =>
          c.or((c) => [
            c.creatorID.eq(dbUser.id),
            c.accessingUsers.contains(dbUser.id),
            c.coOwners.contains(dbUser.id),
          ]),
        { sort: (c) => c.updatedAt(SortDirection.DESCENDING) }
      );
      let tempEditors = new Set([]);
      let tempNumContacts = 0;
      let listUpdates = [];

      for (const list of contactLists) {
        let listContacts = await list.Contacts.toArray();

        // Set the most recent update to the list's updatedAt by default.
        let mostRecentUpdate =
          new Date(list.updatedAt) > new Date(list.createdAt)
            ? new Date(list.updatedAt)
            : new Date(list.createdAt);

        // Check each contact's updatedAt field.
        listContacts.forEach((contact) => {
          let contactUpdate =
            new Date(contact.updatedAt) > new Date(contact.createdAt)
              ? new Date(contact.updatedAt)
              : new Date(contact.createdAt);
          if (contactUpdate > mostRecentUpdate) {
            mostRecentUpdate = contactUpdate;
          }
        });

        // Store the list along with its most recent update.
        listUpdates.push({ ...list, updatedAt: mostRecentUpdate });

        tempNumContacts += listContacts.length;
        list.accessingUsers.forEach((editor) => {
          tempEditors.add(editor);
        });
      }

      // Sort the listUpdates array in descending order of updatedAt.
      listUpdates.sort((a, b) => b.updatedAt - a.updatedAt);

      // Take the top three lists.
      let topThreeUpdatedLists = listUpdates.slice(0, 3);
      setNumTotalContacts(tempNumContacts);
      setDisplayedLists(topThreeUpdatedLists);

      setContactLists(listUpdates);
      setNumUniqueEditors(tempEditors.size);
    };
    if (dbUser) {
      fetchContactLists();
      const contactListSubscription = DataStore.observe(ContactList, (c) =>
        c.or((c) => [
          c.creatorID.eq(dbUser.id),
          c.accessingUsers.contains(dbUser.id),
          c.coOwners.contains(dbUser.id),
        ])
      ).subscribe((msg) => {
        fetchContactLists();
      });

      return () => {
        contactListSubscription.unsubscribe();
      };
    }
  }, [dbUser?.id]);

  const handleNewListPress = async () => {
    setModalVisible(true);
  };

  const pageTitle = `${
    dbUser?.name ? `${dbUser.name} ` : ""
  }Contact Lists • CLIQInvite`;
  const pageDescription = `Welcome to your CLIQInvite Contacts dashboard - the one-stop solution for managing and organizing your fraternity's contact lists. Effortlessly view, create, and track your contacts during rush season. With user-friendly design and advanced features, fraternity recruitment has never been more efficient.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/lists`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={!snackbarOpen?.action ? () => setSnackbarOpen(false) : null}
          action={!snackbarOpen?.action ? null : snackbarOpen?.action}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <WebPortalNavigation activeScreen="/lists" shouldNotCollapse={true} />
      <div
        className="page-size-medium should-not-collapse"
        style={{
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          paddingTop: "2em",
        }}
      >
        <ListDisplayHeader
          handleNewListPress={handleNewListPress}
          numTotalContacts={numTotalContacts}
          numTotalLists={contactLists?.length || 0}
          setAllListsDialogOpen={setAllListsDialogOpen}
          numUniqueEditors={numUniqueEditors}
          loading={loading}
        />
        <NewListModal
          isVisible={modalVisible}
          allLists={contactLists}
          close={() => setModalVisible(false)}
        />
        <AllListsDisplay
          contactLists={contactLists}
          open={allListsDialogOpen}
          setOpen={setAllListsDialogOpen}
          loading={loading}
          setSnackbarOpen={setSnackbarOpen}
        />
        <Grid container sx={{ mt: "1em" }} columnSpacing={2} rowGap={2}>
          <Grid item xs={12} lg={6}>
            <RecentlyEditedListsDisplay
              setSnackbarOpen={setSnackbarOpen}
              displayedLists={displayedLists}
              setAllListsDialogOpen={setAllListsDialogOpen}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ListEventsDisplay contactLists={contactLists} loading={loading} />
          </Grid>
          <Grid item xs={12}>
            <NewsDisplay
              setHelpDialogOpen={setHelpDialogOpen}
              loading={loading}
              title={"What's new with Contacts"}
              description={
                "Check out our latest contacts updates, feature releases, and other news"
              }
              news={contactsNews}
              newsDisplayTitle="Contacts News"
            />
            <HelpDialog
              helpDialogOpen={helpDialogOpen}
              setHelpDialogOpen={setHelpDialogOpen}
              preferredActiveScreen={"Request New Feature"}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ContactsListDisplay;
