import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { colors } from "../../theme/colors";
import EventModule from "./EventModule";

const AllEventsDisplay = ({ events, open, setOpen }) => {
  const renderItem = (item) => (
    <EventModule event={item} key={item.id + "123"} />
  );

  if (!events) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{ maxHeight: "70%", marginTop: "auto", marginBottom: "auto" }}
    >
      <DialogTitle>
        All Events
        <br />
        <p
          style={{ fontSize: ".8rem", color: colors.secondaryText, margin: 0 }}
        >
          To view a field's full text, hover over it on the event's module
        </p>
      </DialogTitle>
      <DialogContent className="scroll-container">
        {events.map(renderItem)}
      </DialogContent>
    </Dialog>
  );
};

export default AllEventsDisplay;
