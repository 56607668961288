export const collegeList = [
  {
    school: "University of North Carolina",
    shortSchool: "UNC",
    primaryColor: "#89CFF0",
    secondaryColor: "#FFFFFF",
    schoolID: 1,
    coordinates: ["35.90811888889489", "-79.05252994795046"],
    maxCoordinates: ["35.917328509919834", "-79.03216828603637"],
    minCoordinates: ["35.8989092679", "-79.0728916099"],
  },

  {
    school: "North Carolina State University",
    shortSchool: "NCSU",
    primaryColor: "#FF0000",
    secondaryColor: "#FFFFFF",
    schoolID: 2,
    coordinates: ["35.78122534274777", "-78.6710760876807"],
  },

  {
    school: "Duke University",
    shortSchool: "Duke",
    primaryColor: "#0000CD",
    secondaryColor: "#FFFFFF",
    schoolID: 3,
    coordinates: ["36.002802986425074", "-78.9279409864924"],
  },

  {
    school: "Harvard University",
    shortSchool: "Harvard",
    primaryColor: "#A51C30",
    secondaryColor: "#000000",
    schoolID: 4,
    coordinates: ["42.37638469475585", "-71.118398171446"],
  },

  {
    school: "Boston College",
    shortSchool: "BC",
    primaryColor: "#800000",
    secondaryColor: "#FFD700",
    schoolID: 5,
    coordinates: ["42.334200511858405", "-71.16999653915657"],
  },

  {
    school: "Yale University",
    shortSchool: "Yale",
    primaryColor: "#0F4D92",
    secondaryColor: "#FFFFFF",
    schoolID: 6,
    coordinates: ["41.314873892217115", "-72.92564758364202"],
  },

  {
    school: "Columbia University",
    shortSchool: "Columbia",
    primaryColor: "#9BCBEB",
    secondaryColor: "#003865",
    schoolID: 7,
    coordinates: ["40.80747865471292", "-73.9624546828053"],
  },

  {
    school: "New York University",
    shortSchool: "NYU",
    primaryColor: "#4B0082",
    secondaryColor: "#FFFFFF",
    schoolID: 8,
    coordinates: ["40.729285751400326", "-73.99656818836367"],
  },

  {
    school: "Princeton University",
    shortSchool: "PU",
    primaryColor: "#FF6000",
    secondaryColor: "#000000",
    schoolID: 9,
    coordinates: ["40.34594800436323", "-74.65805651859458"],
  },

  {
    school: "Georgetown University",
    shortSchool: "G-Town",
    primaryColor: "#041E42",
    secondaryColor: "#8D817B",
    schoolID: 10,
    coordinates: ["38.90763392580929", "-77.07164695856486"],
  },

  {
    school: "University of Virginia",
    shortSchool: "UVA",
    primaryColor: "#FF8C00",
    secondaryColor: "#000080",
    schoolID: 11,
    coordinates: ["38.034330543336395", "-78.50599424682876"],
  },

  {
    school: "University of South Carolina",
    shortSchool: "USC",
    primaryColor: "#73000A",
    secondaryColor: "#000000",
    schoolID: 12,
    coordinates: ["33.99614137729603", "-81.0297898563317"],
  },

  {
    school: "University of Georgia",
    shortSchool: "UGA",
    primaryColor: "#FF0000",
    secondaryColor: "#000000",
    schoolID: 13,
    coordinates: ["33.947827279472854", "-83.37530508119013"],
  },

  {
    school: "University of Mississippi",
    shortSchool: "Ole Miss",
    primaryColor: "#C90E2A",
    secondaryColor: "#1C87EB",
    schoolID: 14,
    coordinates: ["34.364752593027355", "-89.53964780500887"],
  },

  {
    school: "The University of Alabama",
    shortSchool: "Bama",
    primaryColor: "#9E1B32",
    secondaryColor: "#FFFFFF",
    schoolID: 15,
    coordinates: ["33.214705459415576", "-87.5382834955025"],
  },

  {
    school: "Florida State University",
    shortSchool: "FSU",
    primaryColor: "#782F40",
    secondaryColor: "#CEB888",
    schoolID: 16,
    coordinates: ["30.441470789817426", "-84.29638605059465"],
  },

  {
    school: "University of Miami",
    shortSchool: "Miami",
    primaryColor: "#005030",
    secondaryColor: "#F47321",
    schoolID: 17,
    coordinates: ["25.72012049113102", "-80.2780403718798"],
  },

  {
    school: "Tulane University",
    shortSchool: "Tulane",
    primaryColor: "#005F40",
    secondaryColor: "#3A85DF",
    schoolID: 18,
    coordinates: ["29.938478303229182", "-90.12019868307485"],
  },

  {
    school: "Southern Methodist University",
    shortSchool: "SMU",
    primaryColor: "#CC0035",
    secondaryColor: "#354CA1",
    schoolID: 19,
    coordinates: ["32.841839752857204", "-96.78358945820085"],
  },

  {
    school: "University of Texas at Austin",
    shortSchool: "UT Austin",
    primaryColor: "#BFS700",
    secondaryColor: "#FFFFFF",
    schoolID: 20,
    coordinates: ["30.285216090046887", "-97.73352799990262"],
  },

  {
    school: "Arizona State University",
    shortSchool: "ASU",
    primaryColor: "#990033",
    secondaryColor: "#FFB310",
    schoolID: 21,
    coordinates: ["33.42237733588769", "-111.92868033940996"],
  },

  {
    school: "University of Southern California",
    shortSchool: "USC",
    primaryColor: "#990000",
    secondaryColor: "#FFC72C",
    schoolID: 22,
    coordinates: ["34.02256529465543", "-118.28754708372797"],
  },

  {
    school: "University of California, Los Angeles",
    shortSchool: "UCLA",
    primaryColor: "#BFS700",
    secondaryColor: "#F2A900",
    schoolID: 23,
    coordinates: ["34.06979193974206", "-118.44498261907214"],
  },

  {
    school: "Stanford University",
    shortSchool: "Stanford",
    primaryColor: "#8C1515",
    secondaryColor: "#FFFFFF",
    schoolID: 24,
    coordinates: ["37.42771303985792", "-122.17163409953272"],
  },

  {
    school: "Pepperdine University",
    shortSchool: "PU",
    primaryColor: "#00205C",
    secondaryColor: "#EE7624",
    schoolID: 25,
    coordinates: ["34.04055990063855", "-118.70999982710403"],
  },

  {
    school: "University of Oregon",
    shortSchool: "UO",
    primaryColor: "#154733",
    secondaryColor: "#FBE021",
    schoolID: 26,
    coordinates: ["44.04743672517519", "-123.08287299830967"],
  },

  {
    school: "University of Washington",
    shortSchool: "UW",
    primaryColor: "#330066",
    secondaryColor: "#E8D3A2",
    schoolID: 27,
    coordinates: ["47.656520264973906", "-122.30767196143006"],
  },

  {
    school: "University of Michigan",
    shortSchool: "UMich",
    primaryColor: "#FFCB05",
    secondaryColor: "#00274C",
    schoolID: 28,
    coordinates: ["42.27645592978078", "-83.73899657833242"],
  },

  {
    school: "Auburn University",
    shortSchool: "Auburn",
    primaryColor: "#0C2340",
    secondaryColor: "#E87722",
    schoolID: 29,
    coordinates: ["32.601365757783576", "-85.49272012776088"],
  },

  {
    school: "University of Colorado Boulder",
    shortSchool: "UC Boulder",
    primaryColor: "#CFB87C",
    secondaryColor: "#000000",
    schoolID: 30,
    coordinates: ["40.00659482998775", "-105.26881703022937"],
  },

  {
    school: "Colorado State University",
    shortSchool: "CSU",
    primaryColor: "#1E4D2B",
    secondaryColor: "#C8C372",
    schoolID: 31,
    coordinates: ["40.57263243851622", "-105.08491791916252"],
  },

  {
    school: "Ohio State University",
    shortSchool: "OSU",
    primaryColor: "#CE03F3",
    secondaryColor: "#B0B7BC",
    schoolID: 32,
    coordinates: ["40.00811862538651", "-83.02976795668982"],
  },

  {
    school: "University of Kansas",
    shortSchool: "KU",
    primaryColor: "#0051BA",
    secondaryColor: "#FFC82D",
    schoolID: 33,
    coordinates: ["38.9544106255228", "-95.25227704410791"],
  },

  {
    school: "University of Kentucky",
    shortSchool: "UK",
    primaryColor: "#0033A0",
    secondaryColor: "#FFFFFF",
    schoolID: 34,
    coordinates: ["38.032882084187115", "-84.50534299327506"],
  },

  {
    school: "Carnegie Mellon University",
    shortSchool: "CMU",
    primaryColor: "#C41230",
    secondaryColor: "#000000",
    schoolID: 35,
    coordinates: ["40.44323534004992", "-79.94458797361749"],
  },

  {
    school: "Massachusetts Institute of Technology",
    shortSchool: "MIT",
    primaryColor: "#A31F34",
    secondaryColor: "#8A8B8C",
    schoolID: 36,
    coordinates: ["42.3665597545138", "-71.10673419559303"],
  },

  {
    school: "Amherst College",
    shortSchool: "AC",
    primaryColor: "#470A77",
    secondaryColor: "#FFFFFF",
    schoolID: 37,
    coordinates: ["42.370823188306275", "-72.51700280214959"],
  },

  {
    school: "Wake Forest University",
    shortSchool: "WFU",
    primaryColor: "#CEB888",
    secondaryColor: "#000000",
    schoolID: 38,
    coordinates: ["36.13500344566136", "-80.27744334258281"],
  },

  {
    school: "College of Charleston",
    shortSchool: "CofC",
    primaryColor: "#800000",
    secondaryColor: "#9D8958",
    schoolID: 39,
    coordinates: ["32.783471140906315", "-79.93711981963403"],
  },

  {
    school: "East Carolina University",
    shortSchool: "ECU",
    primaryColor: "#582C83",
    secondaryColor: "#FDC82F",
    schoolID: 40,
    coordinates: ["35.606775925452474", "-77.36769511434031"],
  },

  {
    school: "Villanova University",
    shortSchool: "VU",
    primaryColor: "#00205B",
    secondaryColor: "#13B5EA",
    schoolID: 41,
    coordinates: ["40.037401865525034", "-75.34129104192638"],
  },

  {
    school: "Sewanee: The University of the South",
    shortSchool: "Sewanee",
    primaryColor: "#582C83",
    secondaryColor: "#CEB888",
    schoolID: 42,
    coordinates: ["35.20392969756934", "-85.9172375642827"],
  },

  {
    school: "University of Tennessee",
    shortSchool: "UT",
    primaryColor: "#FF8200",
    secondaryColor: "#FFFFFF",
    schoolID: 43,
    coordinates: ["35.954661844368275", "-83.92823331271535"],
  },

  {
    school: "DePaul University",
    shortSchool: "DePaul",
    primaryColor: "#0057B7",
    secondaryColor: "#AF272F",
    schoolID: 44,
    coordinates: ["41.924390334455396", "-87.65419232425398"],
  },

  {
    school: "Johns Hopkins University",
    shortSchool: "JHU",
    primaryColor: "#002D72",
    secondaryColor: "#FFFFFF",
    schoolID: 45,
    coordinates: ["39.32895520339944", "-76.62006709114354"],
  },

  {
    school: "Georgia Institute of Technology",
    shortSchool: "GT",
    primaryColor: "#003057",
    secondaryColor: "#B3A369",
    schoolID: 46,
    coordinates: ["33.77601017332408", "-84.39855951560881"],
  },

  {
    school: "Tufts University",
    shortSchool: "Tufts",
    primaryColor: "#3E8EDE",
    secondaryColor: "#512C1D",
    schoolID: 47,
    coordinates: ["42.408949005612584", "-71.11904537832807"],
  },

  {
    school: "University of Minnesota",
    shortSchool: "UMN",
    primaryColor: "#7A0019",
    secondaryColor: "#FFCC33",
    schoolID: 48,
    coordinates: ["44.97534096107589", "-93.23116172960113"],
  },

  {
    school: "University of Wisconsin-Madison",
    shortSchool: "UW-Madison",
    primaryColor: "#C5050C",
    secondaryColor: "#FFFFFF",
    schoolID: 49,
    coordinates: ["43.07532240672241", "-89.4170365286445"],
  },

  {
    school: "University of Rochester",
    shortSchool: "UR",
    primaryColor: "#003B71",
    secondaryColor: "#FFD100",
    schoolID: 50,
    coordinates: ["43.128712194922166", "-77.62709408092944"],
  },

  {
    school: "Purdue University",
    shortSchool: "Purdue",
    primaryColor: "#CEB888",
    secondaryColor: "#000000",
    schoolID: 51,
    coordinates: ["40.42677630505784", "-86.91879134293613"],
  },

  {
    school: "Howard University",
    shortSchool: "Howard",
    primaryColor: "#003A63",
    secondaryColor: "#E51937",
    schoolID: 52,
    coordinates: ["38.92133205673704", "-77.01948061760092"],
  },

  {
    school: "University of Pennsylvania",
    shortSchool: "UPenn",
    primaryColor: "#011F5B",
    secondaryColor: "#990000",
    schoolID: 53,
    coordinates: ["39.952078962562005", "-75.19352483847479"],
  },

  {
    school: "University of California, Santa Barbara",
    shortSchool: "UCSB",
    primaryColor: "#003660",
    secondaryColor: "#FEBC11",
    schoolID: 54,
    coordinates: ["34.414210708962486", "-119.85192961879015"],
  },

  {
    school: "Penn State University",
    shortSchool: "Penn State",
    primaryColor: "#001E44",
    secondaryColor: "#FFFFFF",
    schoolID: 55,
    coordinates: ["40.79873307852638", "-77.86407119052564"],
  },

  {
    school: "Emory University",
    shortSchool: "EU",
    primaryColor: "#002878",
    secondaryColor: "#D2B000",
    schoolID: 56,
    coordinates: ["33.79588856038563", "-84.32215417184602"],
  },

  {
    school: "University of Pittsburgh",
    shortSchool: "Pitt",
    primaryColor: "#003594",
    secondaryColor: "#FFB81C",
    schoolID: 57,
    coordinates: ["40.44285611124661", "-79.95882551467454"],
  },

  {
    school: "Vanderbilt University",
    shortSchool: "Vandy",
    primaryColor: "#CFAE70",
    secondaryColor: "#000000",
    schoolID: 58,
    coordinates: ["36.14464237430106", "-86.8046458384603"],
  },

  {
    school: "University of Notre Dame",
    shortSchool: "ND",
    primaryColor: "#0C2340",
    secondaryColor: "#C99700",
    schoolID: 59,
    coordinates: ["41.69993238720349", "-86.23607909199328"],
  },

  {
    school: "Yeshiva University",
    shortSchool: "YU",
    primaryColor: "#325A89",
    secondaryColor: "#85878A",
    schoolID: 60,
    coordinates: ["40.746711308809246", "-73.97928573085474"],
  },

  {
    school: "Tuskegee University",
    shortSchool: "TU",
    primaryColor: "#7B0707",
    secondaryColor: "#F2BD2C",
    schoolID: 61,
    coordinates: ["32.426434104581794", "-85.70792851728436"],
  },

  {
    school: "North Carolina Agricultural and Technical State University",
    shortSchool: "NC A & T",
    primaryColor: "#002D74",
    secondaryColor: "#FDB827",
    schoolID: 62,
    coordinates: ["36.074852861512596", "-79.77364380444678"],
  },

  {
    school: "Spelman College",
    shortSchool: "SC",
    primaryColor: "#2A3B86",
    secondaryColor: "#A4C4E9",
    schoolID: 63,
    coordinates: ["33.74520420189134, -84.41150187542222"],
  },

  {
    school: "Indiana University Bloomington",
    shortSchool: "IU",
    primaryColor: "#990000",
    secondaryColor: "#EDEBEB",
    schoolID: 64,
    coordinates: ["39.16809515522687", "-86.5203036355575"],
  },

  {
    school: "The George Washington University",
    shortSchool: "GW",
    primaryColor: "#033C5A",
    secondaryColor: "#AA9868",
    schoolID: 65,
    coordinates: ["38.90125080347249", "-77.0483417101547"],
  },

  {
    school: "James Madison University",
    shortSchool: "JMU",
    primaryColor: "#450084",
    secondaryColor: "#CBB677",
    schoolID: 66,
    coordinates: ["38.435932378304535", "-78.86943293717758"],
  },

  {
    school: "University of Hawaiʻi at Mānoa",
    shortSchool: "UH",
    primaryColor: "#024731",
    secondaryColor: "#FFFFFF",
    schoolID: 67,
    coordinates: ["21.301663233625998", "-157.81578839797558"],
  },

  {
    school: "The University of Utah",
    shortSchool: "UofU",
    primaryColor: "#CC0000",
    secondaryColor: "#000000",
    schoolID: 68,
    coordinates: ["40.763766630162394", "-111.8455353297745"],
  },

  {
    school: "California Institute of Technology",
    shortSchool: "Caltech",
    primaryColor: "#FF6C0C",
    secondaryColor: "#000000",
    schoolID: 69,
    coordinates: ["34.138456791981106", "-118.12653500503858"],
  },

  {
    school: "University of Central Florida",
    shortSchool: "UCF",
    primaryColor: "#BA9B37",
    secondaryColor: "#000000",
    schoolID: 70,
    coordinates: ["28.60222957237317", "-81.20019937741176"],
  },

  {
    school: "University of Alaska Anchorage",
    shortSchool: "UAA",
    primaryColor: "#00583D",
    secondaryColor: "#FFC425",
    schoolID: 71,
    coordinates: ["61.19089731851174", "-149.82003187029423"],
  },

  {
    school: "The University of Iowa",
    shortSchool: "UI",
    primaryColor: "#FFCD00",
    secondaryColor: "#000000",
    schoolID: 72,
    coordinates: ["41.660752040224885", "-91.54673735613537"],
  },

  {
    school: "University of Maryland",
    shortSchool: "UMD",
    primaryColor: "#E21833",
    secondaryColor: "#FFD200",
    schoolID: 73,
    coordinates: ["38.98720181902493", "-76.9421036911543"],
  },

  {
    school: "University of Missouri",
    shortSchool: "MU",
    primaryColor: "#000000",
    secondaryColor: "#F1B82D",
    schoolID: 74,
    coordinates: ["38.942030228619906", "-92.32521175227937"],
  },

  {
    school: "Texas Agricultural and Mechanical University",
    shortSchool: "Texas A&M",
    primaryColor: "#500000",
    secondaryColor: "#FFFFFF",
    schoolID: 75,
    coordinates: ["30.610333608938717", "-96.34496274056933"],
  },

  {
    school: "University of Delaware",
    shortSchool: "UD",
    primaryColor: "#00539F",
    secondaryColor: "#FFD200",
    schoolID: 76,
    coordinates: ["39.67887522241983", "-75.75211343707032"],
  },

  {
    school: "Brown University",
    shortSchool: "Brown",
    primaryColor: "#381C00",
    secondaryColor: "#E4002B",
    schoolID: 77,
    coordinates: ["41.827347398448254", "-71.40211904873449"],
  },

  {
    school: "Dartmouth College",
    shortSchool: "DC",
    primaryColor: "#00693E",
    secondaryColor: "#FFFFFF",
    schoolID: 78,
    coordinates: ["43.70440955503319", "-72.2887793327818"],
  },

  {
    school: "Rutgers University-New Brunswick",
    shortSchool: "RU",
    primaryColor: "#CC0033",
    secondaryColor: "#5F6A72",
    schoolID: 79,
    coordinates: ["40.50047911217008", "-74.44782595168247"],
  },

  {
    school: "Temple University",
    shortSchool: "TU",
    primaryColor: "#990033",
    secondaryColor: "#FFFFFF",
    schoolID: 80,
    coordinates: ["39.981223963541495", "-75.15546359058587"],
  },

  {
    school: "University of Vermont",
    shortSchool: "UVM",
    primaryColor: "#007155",
    secondaryColor: "#FFD416",
    schoolID: 81,
    coordinates: ["44.477975263024206", "-73.19678556715247"],
  },

  {
    school: "University of Maine",
    shortSchool: "UMaine",
    primaryColor: "#003263",
    secondaryColor: "#79BDE8",
    schoolID: 82,
    coordinates: ["44.899610937250195", "-68.66748021634307"],
  },

  {
    school: "Brigham Young University",
    shortSchool: "BYU",
    primaryColor: "#002E5D",
    secondaryColor: "#FFFFFF",
    schoolID: 83,
    coordinates: ["40.2544637080189", "-111.64914394085578"],
  },

  {
    school: "University of Rhode Island",
    shortSchool: "URI",
    primaryColor: "#002147",
    secondaryColor: "#75B2DD",
    schoolID: 84,
    coordinates: ["41.48629707627151", "-71.53112941332694"],
  },

  {
    school: "Old Dominion University",
    shortSchool: "ODU",
    primaryColor: "#98C5EA",
    secondaryColor: "#FFFFFF",
    schoolID: 85,
    coordinates: ["36.88527019190067", "-76.30800291185497"],
  },

  {
    school: "New Mexico State University",
    shortSchool: "NMSU",
    primaryColor: "#8C0B42",
    secondaryColor: "#FFFFFF",
    schoolID: 86,
    coordinates: ["32.27947009589595", "-106.7475083193734"],
  },

  {
    school: "Montana State University",
    shortSchool: "MSU",
    primaryColor: "#003F7F",
    secondaryColor: "#FFCB05",
    schoolID: 87,
    coordinates: ["45.66747233990248", "-111.0549644247014"],
  },

  {
    school: "Clemson University",
    shortSchool: "Clemson",
    primaryColor: "#F56600",
    secondaryColor: "#522D80",
    schoolID: 88,
    coordinates: ["34.67746568288243", "-82.83572484942805"],
  },

  {
    school: "University of Nevada, Las Vegas",
    shortSchool: "UNLV",
    primaryColor: "#B10202",
    secondaryColor: "#666666",
    schoolID: 89,
    coordinates: ["36.10785984107081", "-115.14213417104988"],
  },

  {
    school: "University of Arkansas",
    shortSchool: "U of A",
    primaryColor: "#9D2235",
    secondaryColor: "#FFFFFF",
    schoolID: 90,
    coordinates: ["36.06830782474222", "-94.1749617128803"],
  },

  {
    school: "San Diego State University",
    shortSchool: "SDSU",
    primaryColor: "#A6192E",
    secondaryColor: "#000000",
    schoolID: 91,
    coordinates: ["32.77577402689508", "-117.06958985436472"],
  },

  {
    school: "Baylor University",
    shortSchool: "BU",
    primaryColor: "#154734",
    secondaryColor: "#FFB81C",
    schoolID: 92,
    coordinates: ["31.550139638889025", "-97.11622221149719"],
  },

  {
    school: "The University of Tulsa",
    shortSchool: "TU",
    primaryColor: "#003366",
    secondaryColor: "#F5002E",
    schoolID: 93,
    coordinates: ["36.15220851476768", "-95.94598947161676"],
  },

  {
    school: "Syracuse University",
    shortSchool: "SU",
    primaryColor: "#F76900",
    secondaryColor: "#000E54",
    schoolID: 94,
    coordinates: ["43.03896518112422", "-76.1354591248754"],
  },

  {
    school: "Portland State University",
    shortSchool: "PSU",
    primaryColor: "#154734",
    secondaryColor: "#FFFFFF",
    schoolID: 95,
    coordinates: ["45.51110024218806", "-122.68338141738457"],
  },

  {
    school: "University of Idaho",
    shortSchool: "U of I",
    primaryColor: "#000000",
    secondaryColor: "#857550",
    schoolID: 96,
    coordinates: ["46.728091672021996", "-117.01275860570514"],
  },

  {
    school: "University of New Hampshire",
    shortSchool: "UNH",
    primaryColor: "#003591",
    secondaryColor: "#FFFFFF",
    schoolID: 97,
    coordinates: ["43.13738219786136", "-70.9369822867391"],
  },

  {
    school: "West Virginia University",
    shortSchool: "WVU",
    primaryColor: "#002855",
    secondaryColor: "#EAAA00",
    schoolID: 98,
    coordinates: ["39.6476228295516", "-79.96996146544372"],
  },

  {
    school: "University of Connecticut",
    shortSchool: "UConn",
    primaryColor: "#000E2F",
    secondaryColor: "#FFFFFF",
    schoolID: 99,
    coordinates: ["41.807106964148645", "-72.25595838676502"],
  },

  {
    school: "Appalachian State University",
    shortSchool: "App State",
    primaryColor: "#000000",
    secondaryColor: "#FFCC00",
    schoolID: 100,
    coordinates: ["36.21405618447804", "-81.68386046238743"],
  },
];
