import * as ExcelJS from "exceljs";
import { sum } from "./sum";

export const downloadGuestListData = async (
  event,
  orgMemberInvites,
  guestInviteCount,
  orgName
) => {
  const wb = new ExcelJS.Workbook();
  const guestListSheet = wb.addWorksheet("Guest List");

  guestListSheet.mergeCells("A1:B3");
  let cellA1 = guestListSheet.getCell("A1");
  cellA1.value = {
    richText: [
      { text: "CLI", font: { name: "Poppins", size: 25, bold: true } },
      {
        text: "Ω",
        font: {
          name: "Arial",
          size: 25,
          color: { argb: "FFA020F0" },
          bold: true,
        },
      },
    ],
  };
  cellA1.alignment = { vertical: "bottom", horizontal: "center" };

  guestListSheet.mergeCells("C1:E2");
  let cellC1 = guestListSheet.getCell("C1");
  cellC1.value = orgName;
  cellC1.font = { size: 20, bold: true };
  cellC1.alignment = { vertical: "middle", horizontal: "left" };

  guestListSheet.mergeCells("C3:E3");
  let cellC3 = guestListSheet.getCell("C3");
  cellC3.value = `Guest List for ${event.name}`;
  cellC3.font = { size: 16, italic: true };

  guestListSheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => (v ? v.toString().length : 0));

    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength;
  });
  const totalInvited = sum(event.analytics.categoryData, "invitesGiven");
  const validScans = sum(event.analytics.categoryData, "validScans");
  const invalidScans = sum(event.analytics.categoryData, "invalidScans");
  const unaccountedData = {
    member: "Data Inconsistencies",
    count: totalInvited - sum(guestInviteCount, "count"),
    category: "N/A",
    used: validScans - sum(guestInviteCount, "used"),
    numInvalid: invalidScans - sum(guestInviteCount, "numInvalid"),
  };

  const rows =
    unaccountedData.count || unaccountedData.used || unaccountedData.numInvalid
      ? [...guestInviteCount, orgMemberInvites, unaccountedData]
      : [...guestInviteCount, orgMemberInvites];

  guestListSheet.addTable({
    name: "GuestListTable",
    ref: "A5",
    headerRow: true,
    totalsRow: true,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: true,
    },
    columns: [
      { name: "Member" },
      { name: "Number Invited", totalsRowFunction: "sum" },
      { name: "Category" },
      { name: "Total Scans", totalsRowFunction: "sum" },
      { name: "Valid Scans", totalsRowFunction: "sum" },
      { name: "Invalid Scans", totalsRowFunction: "sum" },
      { name: "Valid Scan Rate", totalsRowFunction: "none" },
      { name: "Invalid Scan Rate", totalsRowFunction: "none" },
    ],
    rows: rows.map((guest) => [
      guest.member || guest.originMember || "you",
      guest.count,
      guest.category,
      guest.used + guest.numInvalid || 0,
      guest.used || 0,
      guest.numInvalid || 0,
      parseFloat(
        guest.count !== 0 ? (guest.used / guest.count).toFixed(3) : 0
      ) || 0,
      parseFloat(
        guest.count !== 0 ? (guest.numInvalid / guest.count).toFixed(3) : 0
      ) || 0,
    ]),
  });
  guestListSheet.views = [
    {
      showGridLines: false,
    },
  ];

  for (let i = 6; i < rows.length + 6; i++) {
    let rowG = guestListSheet.getCell(`G${i}`);
    rowG.numFmt = "0.0%";
    let rowH = guestListSheet.getCell(`H${i}`);
    rowH.numFmt = "0.0%";
  }

  const buffer = await wb.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${event.name}_Guest_List.xlsx`;
  link.click();
};
