import { API } from "aws-amplify";
import { listInvites } from "../../graphql/queries";

export const fetchOrgMemberInvites = async (eventID) => {
  let invites = [];
  let nextToken = null;

  do {
    const listInvitesResponse = await API.graphql({
      query: listInvites,
      variables: {
        filter: {
          eventID: {
            eq: eventID,
          },

          isOrg: {
            eq: false,
          },
          _deleted: {
            ne: true,
          },
        },
        nextToken: nextToken,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    invites = invites.concat(listInvitesResponse.data.listInvites.items);
    nextToken = listInvitesResponse.data.listInvites.nextToken;
  } while (nextToken !== null);

  invites = invites.filter((inv) => !inv.guestOrgID);

  return invites;
};
