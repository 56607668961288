import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { PaymentElement } from "@stripe/react-stripe-js";
import { BeatLoader } from "react-spinners";
import { colors } from "../../theme/colors";

const SubscriptionPaymentsForm = ({
  submitting,
  stripe,
  setPhase,
  setAnimationType,
}) => {
  return (
    <Box
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
        setAnimationType("FORWARD");
        setPhase("CONFIRM");
      }}
      className="unstyled-card"
      sx={{
        mt: 5,
        bgcolor: colors.secondaryBackground,
        display: "flex",
        flexDirection: "column",
        px: 5,
        py: 2,
        alignSelf: "center",
      }}
    >
      <h1 style={{ marginTop: 0, fontSize: "1.5em", fontWeight: 500 }}>
        Enter Payment Information
      </h1>
      <PaymentElement />
      {!submitting ? (
        <Box sx={{ display: "flex", gap: 2, mt: 5 }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!stripe || submitting}
            sx={{
              flex: 0.5,
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
            }}
            startIcon={<ArrowBackOutlined sx={{ color: "white" }} />}
            onClick={() => {
              setAnimationType("BACKWARD");
              setPhase("OPTIONS");
            }}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ flex: 1 }}
            startIcon={<ArrowForwardOutlined sx={{ color: "white" }} />}
            disabled={!stripe || submitting}
          >
            Next
          </Button>
        </Box>
      ) : (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <BeatLoader loading={true} color="gold" size={"1.5em"} />
        </div>
      )}
    </Box>
  );
};
export default SubscriptionPaymentsForm;
