import { API } from "aws-amplify";
import { listOrganizationMembers } from "../../graphql/queries";

export const fetchOrgMembers = async (organizationID) => {
  let orgMembers = [];
  let nextToken = null;
  let fetches = 0;
  do {
    const orgMemberResponse = await API.graphql({
      query: listOrganizationMembers,
      variables: {
        filter: {
          organizationID: {
            eq: organizationID,
          },
          isConfirmed: {
            eq: true,
          },
          _deleted: {
            ne: true,
          },
        },

        nextToken: nextToken,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    orgMembers = orgMembers.concat(
      orgMemberResponse.data.listOrganizationMembers.items
    );

    nextToken = orgMemberResponse.data.listOrganizationMembers.nextToken;
    fetches++;
  } while (nextToken !== null && fetches < 9999);

  orgMembers = orgMembers.sort((a, b) => a.role.localeCompare(b.role));
  return orgMembers;
};
