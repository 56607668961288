import { Box, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import AboutWhoWeAreSectionHeader from "./AboutWhoWeAreSectionHeader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AboutWhoWeAreSection = () => {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setUrl(
        "https://play.google.com/store/apps/details?id=com.skdraughn.CLIQ"
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setUrl("https://apps.apple.com/us/app/cli%CF%89/id1664767480");
    }
    // else {
    //   // for desktop browsers, redirect to signup page
    //   setUrl("https://www.cliqinvite.com/signup");
    // }
  }, []);
  return (
    <Box>
      <AboutWhoWeAreSectionHeader />
      <Box
        sx={{
          bgcolor: colors.eggshell,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { lg: "5rem", sm: "3rem", xs: "2rem" },
          pb: { lg: "8rem", sm: "5.6rem", xs: "4rem" },
        }}
      >
        <Box
          className="default-page-size"
          sx={{ display: "flex", gap: 6, flexWrap: "wrap" }}
        >
          <Box sx={{ width: { md: "50%", sm: "100%" } }}>
            <Typography
              variant="h1"
              sx={{
                color: colors.defaultBackground,
                fontSize: {
                  lg: "2.25rem",
                  md: "3vw",
                  sm: "2rem",
                  xs: "1.5rem",
                },
                mb: "2rem",
              }}
            >
              Making fraternity events easier for both you and your guests
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: colors.defaultBackground,
                fontSize: { lg: "1.05rem", xs: ".9rem" },
                mb: ".75rem",
              }}
            >
              CLIQInvite isn't about reinventing the wheel. We're about
              improving it. As Greek Life members ourselves, we know how
              difficult change can be—especially when your guests have to change
              too.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: colors.defaultBackground,
                fontSize: { lg: "1.05rem", xs: ".9rem" },
                mb: ".75rem",
              }}
            >
              We know and understand your problems. At CLIQInvite, we're
              dedicated to solving them without making your guests lives harder.
              While your brothers plan events, send invites, sell tickets, sign
              up for job shifts, and view analytics quicker than ever, your
              guests don't even have to download an app.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: colors.defaultBackground,
                fontSize: { lg: "1.05rem", xs: ".9rem" },
              }}
            >
              Let's solve your problems together.{" "}
              <span
                style={{
                  color: colors.primaryColor,
                  fontWeight: 600,
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
                onClick={() => window.open(url, "_blank", "noreferrer")}
              >
                Get Started →
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <img
              src={require("../../assets/aboutUs/PartyPhoto.jpg")}
              style={{ width: "100%", borderRadius: 16 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutWhoWeAreSection;
