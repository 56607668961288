// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PhotoStatus = {
  "APPROVED": "APPROVED",
  "PENDING": "PENDING",
  "FLAGGED": "FLAGGED",
  "REJECTED": "REJECTED"
};

const GuestInviteStatus = {
  "INSUFFICIENT_TICKETS": "INSUFFICIENT_TICKETS",
  "VALID": "VALID",
  "PENDING": "PENDING"
};

const AdminStatus = {
  "ADMIN": "ADMIN",
  "RUSH_ADMIN": "RUSH_ADMIN",
  "EVENT_ADMIN": "EVENT_ADMIN",
  "DEFAULT": "DEFAULT"
};

const { PayIn, Payout, Account, Score, Contact, ContactList, Announcement, Scan, Notification, GuestInvite, TicketLink, Invite, Event, User, OrganizationMember, Organization, SubscriptionPlan, Job, Version, TicketInformation, OrgMemberAnalytics, CategoryData, GraphData, Analytics, Limit, Category } = initSchema(schema);

export {
  PayIn,
  Payout,
  Account,
  Score,
  Contact,
  ContactList,
  Announcement,
  Scan,
  Notification,
  GuestInvite,
  TicketLink,
  Invite,
  Event,
  User,
  OrganizationMember,
  Organization,
  SubscriptionPlan,
  Job,
  Version,
  PhotoStatus,
  GuestInviteStatus,
  AdminStatus,
  TicketInformation,
  OrgMemberAnalytics,
  CategoryData,
  GraphData,
  Analytics,
  Limit,
  Category
};