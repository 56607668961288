import { Box, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";

const PlansHeader = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        background: "radial-gradient(ellipse at 0% 0%, #a020f030, black 80%)",
        pt: "6.5rem",
        pb: "3rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        className="default-page-size"
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2.75rem", lg: "3.25rem" },
            alignSelf: "flex-start",
            fontWeight: 500,
            letterSpacing: "-1px",
            width: { xs: "94%", lg: "60%" },
            marginBottom: ".25em",
          }}
        >
          Set up your organization, pick a plan later
        </Typography>
        <Typography
          style={{
            color: colors.secondaryText,
            alignSelf: "flex-start",
            fontSize: "1.4em",
            marginBottom: "1.5em",
          }}
          variant="body2"
        >
          Try CLIQInvite free for two weeks, no strings attached
        </Typography>
        <button
          className="slide-button"
          style={{ fontSize: "1.2rem", padding: ".4rem 2rem" }}
          onClick={() => navigate("/login?subscription=true")}
        >
          Sign up now
        </button>
      </Box>
    </Box>
  );
};

export default PlansHeader;
