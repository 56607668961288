/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePayIn = /* GraphQL */ `
  subscription OnCreatePayIn(
    $filter: ModelSubscriptionPayInFilterInput
    $organizationID: String
  ) {
    onCreatePayIn(filter: $filter, organizationID: $organizationID) {
      id
      amount
      accountID
      Event {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numTickets
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      payInEventId
    }
  }
`;
export const onUpdatePayIn = /* GraphQL */ `
  subscription OnUpdatePayIn(
    $filter: ModelSubscriptionPayInFilterInput
    $organizationID: String
  ) {
    onUpdatePayIn(filter: $filter, organizationID: $organizationID) {
      id
      amount
      accountID
      Event {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numTickets
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      payInEventId
    }
  }
`;
export const onDeletePayIn = /* GraphQL */ `
  subscription OnDeletePayIn(
    $filter: ModelSubscriptionPayInFilterInput
    $organizationID: String
  ) {
    onDeletePayIn(filter: $filter, organizationID: $organizationID) {
      id
      amount
      accountID
      Event {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numTickets
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      payInEventId
    }
  }
`;
export const onCreatePayout = /* GraphQL */ `
  subscription OnCreatePayout(
    $filter: ModelSubscriptionPayoutFilterInput
    $organizationID: String
  ) {
    onCreatePayout(filter: $filter, organizationID: $organizationID) {
      id
      isFulfilled
      amount
      accountID
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePayout = /* GraphQL */ `
  subscription OnUpdatePayout(
    $filter: ModelSubscriptionPayoutFilterInput
    $organizationID: String
  ) {
    onUpdatePayout(filter: $filter, organizationID: $organizationID) {
      id
      isFulfilled
      amount
      accountID
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePayout = /* GraphQL */ `
  subscription OnDeletePayout(
    $filter: ModelSubscriptionPayoutFilterInput
    $organizationID: String
  ) {
    onDeletePayout(filter: $filter, organizationID: $organizationID) {
      id
      isFulfilled
      amount
      accountID
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount(
    $filter: ModelSubscriptionAccountFilterInput
    $owner: String
  ) {
    onCreateAccount(filter: $filter, owner: $owner) {
      id
      Payouts {
        nextToken
        startedAt
      }
      venmo
      PayIns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount(
    $filter: ModelSubscriptionAccountFilterInput
    $owner: String
  ) {
    onUpdateAccount(filter: $filter, owner: $owner) {
      id
      Payouts {
        nextToken
        startedAt
      }
      venmo
      PayIns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount(
    $filter: ModelSubscriptionAccountFilterInput
    $owner: String
  ) {
    onDeleteAccount(filter: $filter, owner: $owner) {
      id
      Payouts {
        nextToken
        startedAt
      }
      venmo
      PayIns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreateScore = /* GraphQL */ `
  subscription OnCreateScore(
    $filter: ModelSubscriptionScoreFilterInput
    $orgMemberID: String
  ) {
    onCreateScore(filter: $filter, orgMemberID: $orgMemberID) {
      id
      score
      orgMemberID
      contactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateScore = /* GraphQL */ `
  subscription OnUpdateScore(
    $filter: ModelSubscriptionScoreFilterInput
    $orgMemberID: String
  ) {
    onUpdateScore(filter: $filter, orgMemberID: $orgMemberID) {
      id
      score
      orgMemberID
      contactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteScore = /* GraphQL */ `
  subscription OnDeleteScore(
    $filter: ModelSubscriptionScoreFilterInput
    $orgMemberID: String
  ) {
    onDeleteScore(filter: $filter, orgMemberID: $orgMemberID) {
      id
      score
      orgMemberID
      contactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact(
    $filter: ModelSubscriptionContactFilterInput
    $creatorID: String
  ) {
    onCreateContact(filter: $filter, creatorID: $creatorID) {
      id
      name
      number
      notes
      contactListID
      Scores {
        nextToken
        startedAt
      }
      avgScore
      creatorID
      photo
      accessingUsers
      coOwners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact(
    $filter: ModelSubscriptionContactFilterInput
    $creatorID: String
  ) {
    onUpdateContact(filter: $filter, creatorID: $creatorID) {
      id
      name
      number
      notes
      contactListID
      Scores {
        nextToken
        startedAt
      }
      avgScore
      creatorID
      photo
      accessingUsers
      coOwners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact(
    $filter: ModelSubscriptionContactFilterInput
    $creatorID: String
  ) {
    onDeleteContact(filter: $filter, creatorID: $creatorID) {
      id
      name
      number
      notes
      contactListID
      Scores {
        nextToken
        startedAt
      }
      avgScore
      creatorID
      photo
      accessingUsers
      coOwners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContactList = /* GraphQL */ `
  subscription OnCreateContactList(
    $filter: ModelSubscriptionContactListFilterInput
    $creatorID: String
  ) {
    onCreateContactList(filter: $filter, creatorID: $creatorID) {
      id
      creatorID
      accessingUsers
      coOwners
      name
      numContacts
      Contacts {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContactList = /* GraphQL */ `
  subscription OnUpdateContactList(
    $filter: ModelSubscriptionContactListFilterInput
    $creatorID: String
  ) {
    onUpdateContactList(filter: $filter, creatorID: $creatorID) {
      id
      creatorID
      accessingUsers
      coOwners
      name
      numContacts
      Contacts {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContactList = /* GraphQL */ `
  subscription OnDeleteContactList(
    $filter: ModelSubscriptionContactListFilterInput
    $creatorID: String
  ) {
    onDeleteContactList(filter: $filter, creatorID: $creatorID) {
      id
      creatorID
      accessingUsers
      coOwners
      name
      numContacts
      Contacts {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAnnouncement = /* GraphQL */ `
  subscription OnCreateAnnouncement(
    $filter: ModelSubscriptionAnnouncementFilterInput
  ) {
    onCreateAnnouncement(filter: $filter) {
      id
      isPinned
      announcement
      eventID
      title
      visibleRoles
      link
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAnnouncement = /* GraphQL */ `
  subscription OnUpdateAnnouncement(
    $filter: ModelSubscriptionAnnouncementFilterInput
  ) {
    onUpdateAnnouncement(filter: $filter) {
      id
      isPinned
      announcement
      eventID
      title
      visibleRoles
      link
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAnnouncement = /* GraphQL */ `
  subscription OnDeleteAnnouncement(
    $filter: ModelSubscriptionAnnouncementFilterInput
  ) {
    onDeleteAnnouncement(filter: $filter) {
      id
      isPinned
      announcement
      eventID
      title
      visibleRoles
      link
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateScan = /* GraphQL */ `
  subscription OnCreateScan($filter: ModelSubscriptionScanFilterInput) {
    onCreateScan(filter: $filter) {
      id
      date
      eventID
      isValid
      category
      isScanOut
      guestInviteID
      orgMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateScan = /* GraphQL */ `
  subscription OnUpdateScan($filter: ModelSubscriptionScanFilterInput) {
    onUpdateScan(filter: $filter) {
      id
      date
      eventID
      isValid
      category
      isScanOut
      guestInviteID
      orgMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteScan = /* GraphQL */ `
  subscription OnDeleteScan($filter: ModelSubscriptionScanFilterInput) {
    onDeleteScan(filter: $filter) {
      id
      date
      eventID
      isValid
      category
      isScanOut
      guestInviteID
      orgMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $senderID: String
    $userID: String
  ) {
    onCreateNotification(
      filter: $filter
      senderID: $senderID
      userID: $userID
    ) {
      id
      type
      createdAt
      objectID
      message
      eventName
      senderName
      image
      senderID
      senderType
      read
      userID
      organizationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $senderID: String
    $userID: String
  ) {
    onUpdateNotification(
      filter: $filter
      senderID: $senderID
      userID: $userID
    ) {
      id
      type
      createdAt
      objectID
      message
      eventName
      senderName
      image
      senderID
      senderType
      read
      userID
      organizationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $senderID: String
    $userID: String
  ) {
    onDeleteNotification(
      filter: $filter
      senderID: $senderID
      userID: $userID
    ) {
      id
      type
      createdAt
      objectID
      message
      eventName
      senderName
      image
      senderID
      senderType
      read
      userID
      organizationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGuestInvite = /* GraphQL */ `
  subscription OnCreateGuestInvite(
    $filter: ModelSubscriptionGuestInviteFilterInput
  ) {
    onCreateGuestInvite(filter: $filter) {
      id
      qrCode
      maxUses
      numUsed
      orgMemberID
      eventID
      category
      numInvalid
      label
      contactListID
      contactID
      cost
      paymentIntentID
      photoID
      photoStatus
      guestOrgID
      status
      ticketLinkID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGuestInvite = /* GraphQL */ `
  subscription OnUpdateGuestInvite(
    $filter: ModelSubscriptionGuestInviteFilterInput
  ) {
    onUpdateGuestInvite(filter: $filter) {
      id
      qrCode
      maxUses
      numUsed
      orgMemberID
      eventID
      category
      numInvalid
      label
      contactListID
      contactID
      cost
      paymentIntentID
      photoID
      photoStatus
      guestOrgID
      status
      ticketLinkID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGuestInvite = /* GraphQL */ `
  subscription OnDeleteGuestInvite(
    $filter: ModelSubscriptionGuestInviteFilterInput
  ) {
    onDeleteGuestInvite(filter: $filter) {
      id
      qrCode
      maxUses
      numUsed
      orgMemberID
      eventID
      category
      numInvalid
      label
      contactListID
      contactID
      cost
      paymentIntentID
      photoID
      photoStatus
      guestOrgID
      status
      ticketLinkID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTicketLink = /* GraphQL */ `
  subscription OnCreateTicketLink(
    $filter: ModelSubscriptionTicketLinkFilterInput
  ) {
    onCreateTicketLink(filter: $filter) {
      id
      creatorID
      eventID
      maxTickets
      ticketsSold
      category
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTicketLink = /* GraphQL */ `
  subscription OnUpdateTicketLink(
    $filter: ModelSubscriptionTicketLinkFilterInput
  ) {
    onUpdateTicketLink(filter: $filter) {
      id
      creatorID
      eventID
      maxTickets
      ticketsSold
      category
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTicketLink = /* GraphQL */ `
  subscription OnDeleteTicketLink(
    $filter: ModelSubscriptionTicketLinkFilterInput
  ) {
    onDeleteTicketLink(filter: $filter) {
      id
      creatorID
      eventID
      maxTickets
      ticketsSold
      category
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateInvite = /* GraphQL */ `
  subscription OnCreateInvite(
    $filter: ModelSubscriptionInviteFilterInput
    $userId: String
    $organizationID: String
    $guestOrgID: String
  ) {
    onCreateInvite(
      filter: $filter
      userId: $userId
      organizationID: $organizationID
      guestOrgID: $guestOrgID
    ) {
      id
      invitesGiven
      invitesUsed
      hasResponded
      response
      userId
      organizationInfo
      eventInfo
      qrCode
      isOrg
      organizationID
      eventID
      guestOrgID
      membersOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateInvite = /* GraphQL */ `
  subscription OnUpdateInvite(
    $filter: ModelSubscriptionInviteFilterInput
    $userId: String
    $organizationID: String
    $guestOrgID: String
  ) {
    onUpdateInvite(
      filter: $filter
      userId: $userId
      organizationID: $organizationID
      guestOrgID: $guestOrgID
    ) {
      id
      invitesGiven
      invitesUsed
      hasResponded
      response
      userId
      organizationInfo
      eventInfo
      qrCode
      isOrg
      organizationID
      eventID
      guestOrgID
      membersOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteInvite = /* GraphQL */ `
  subscription OnDeleteInvite(
    $filter: ModelSubscriptionInviteFilterInput
    $userId: String
    $organizationID: String
    $guestOrgID: String
  ) {
    onDeleteInvite(
      filter: $filter
      userId: $userId
      organizationID: $organizationID
      guestOrgID: $guestOrgID
    ) {
      id
      invitesGiven
      invitesUsed
      hasResponded
      response
      userId
      organizationInfo
      eventInfo
      qrCode
      isOrg
      organizationID
      eventID
      guestOrgID
      membersOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent(
    $filter: ModelSubscriptionEventFilterInput
    $organizationID: String
  ) {
    onCreateEvent(filter: $filter, organizationID: $organizationID) {
      id
      address
      name
      description
      startDate
      endDate
      organizationInfo
      organizationID
      Invites {
        nextToken
        startedAt
      }
      GuestInvites {
        nextToken
        startedAt
      }
      Scans {
        nextToken
        startedAt
      }
      Jobs {
        nextToken
        startedAt
      }
      isGuestListPublic
      categories {
        category
      }
      Announcements {
        nextToken
        startedAt
      }
      eventCode
      ticketInformation {
        price
      }
      maxTickets
      ticketsSold
      numGuests
      requirePhotoID
      jobsNotificationSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent(
    $filter: ModelSubscriptionEventFilterInput
    $organizationID: String
  ) {
    onUpdateEvent(filter: $filter, organizationID: $organizationID) {
      id
      address
      name
      description
      startDate
      endDate
      organizationInfo
      organizationID
      Invites {
        nextToken
        startedAt
      }
      GuestInvites {
        nextToken
        startedAt
      }
      Scans {
        nextToken
        startedAt
      }
      Jobs {
        nextToken
        startedAt
      }
      isGuestListPublic
      categories {
        category
      }
      Announcements {
        nextToken
        startedAt
      }
      eventCode
      ticketInformation {
        price
      }
      maxTickets
      ticketsSold
      numGuests
      requirePhotoID
      jobsNotificationSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent(
    $filter: ModelSubscriptionEventFilterInput
    $organizationID: String
  ) {
    onDeleteEvent(filter: $filter, organizationID: $organizationID) {
      id
      address
      name
      description
      startDate
      endDate
      organizationInfo
      organizationID
      Invites {
        nextToken
        startedAt
      }
      GuestInvites {
        nextToken
        startedAt
      }
      Scans {
        nextToken
        startedAt
      }
      Jobs {
        nextToken
        startedAt
      }
      isGuestListPublic
      categories {
        category
      }
      Announcements {
        nextToken
        startedAt
      }
      eventCode
      ticketInformation {
        price
      }
      maxTickets
      ticketsSold
      numGuests
      requirePhotoID
      jobsNotificationSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onCreateUser(filter: $filter, id: $id) {
      id
      profilePic
      name
      school
      shortSchool
      searchName
      pushTokens
      adminOrganizationIDs
      Notifications {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onUpdateUser(filter: $filter, id: $id) {
      id
      profilePic
      name
      school
      shortSchool
      searchName
      pushTokens
      adminOrganizationIDs
      Notifications {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onDeleteUser(filter: $filter, id: $id) {
      id
      profilePic
      name
      school
      shortSchool
      searchName
      pushTokens
      adminOrganizationIDs
      Notifications {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateOrganizationMember = /* GraphQL */ `
  subscription OnCreateOrganizationMember(
    $filter: ModelSubscriptionOrganizationMemberFilterInput
    $organizationID: String
    $userID: String
  ) {
    onCreateOrganizationMember(
      filter: $filter
      organizationID: $organizationID
      userID: $userID
    ) {
      id
      role
      memberInfo
      isConfirmed
      organizationID
      userID
      analytics {
        eventID
        invitesGiven
        validScans
        invalidScans
        revenue
        ticketsSold
      }
      analyticsUpdatedAt
      adminStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateOrganizationMember = /* GraphQL */ `
  subscription OnUpdateOrganizationMember(
    $filter: ModelSubscriptionOrganizationMemberFilterInput
    $organizationID: String
    $userID: String
  ) {
    onUpdateOrganizationMember(
      filter: $filter
      organizationID: $organizationID
      userID: $userID
    ) {
      id
      role
      memberInfo
      isConfirmed
      organizationID
      userID
      analytics {
        eventID
        invitesGiven
        validScans
        invalidScans
        revenue
        ticketsSold
      }
      analyticsUpdatedAt
      adminStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteOrganizationMember = /* GraphQL */ `
  subscription OnDeleteOrganizationMember(
    $filter: ModelSubscriptionOrganizationMemberFilterInput
    $organizationID: String
    $userID: String
  ) {
    onDeleteOrganizationMember(
      filter: $filter
      organizationID: $organizationID
      userID: $userID
    ) {
      id
      role
      memberInfo
      isConfirmed
      organizationID
      userID
      analytics {
        eventID
        invitesGiven
        validScans
        invalidScans
        revenue
        ticketsSold
      }
      analyticsUpdatedAt
      adminStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
    $id: String
  ) {
    onCreateOrganization(filter: $filter, id: $id) {
      id
      school
      profilePic
      name
      email
      bio
      createdRoles
      searchName
      bannerPic
      shortSchool
      pushTokens
      plan
      adminIDs
      SubscriptionPlans {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      Events {
        nextToken
        startedAt
      }
      Notifications {
        nextToken
        startedAt
      }
      Account {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationAccountId
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
    $id: String
  ) {
    onUpdateOrganization(filter: $filter, id: $id) {
      id
      school
      profilePic
      name
      email
      bio
      createdRoles
      searchName
      bannerPic
      shortSchool
      pushTokens
      plan
      adminIDs
      SubscriptionPlans {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      Events {
        nextToken
        startedAt
      }
      Notifications {
        nextToken
        startedAt
      }
      Account {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationAccountId
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
    $id: String
  ) {
    onDeleteOrganization(filter: $filter, id: $id) {
      id
      school
      profilePic
      name
      email
      bio
      createdRoles
      searchName
      bannerPic
      shortSchool
      pushTokens
      plan
      adminIDs
      SubscriptionPlans {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      Events {
        nextToken
        startedAt
      }
      Notifications {
        nextToken
        startedAt
      }
      Account {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationAccountId
    }
  }
`;
export const onCreateSubscriptionPlan = /* GraphQL */ `
  subscription OnCreateSubscriptionPlan(
    $filter: ModelSubscriptionSubscriptionPlanFilterInput
  ) {
    onCreateSubscriptionPlan(filter: $filter) {
      id
      planType
      isActive
      totalMembers
      cost
      billingCycle
      dateRenewalRequired
      stripeSubscriptionID
      promoFactor
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSubscriptionPlan = /* GraphQL */ `
  subscription OnUpdateSubscriptionPlan(
    $filter: ModelSubscriptionSubscriptionPlanFilterInput
  ) {
    onUpdateSubscriptionPlan(filter: $filter) {
      id
      planType
      isActive
      totalMembers
      cost
      billingCycle
      dateRenewalRequired
      stripeSubscriptionID
      promoFactor
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSubscriptionPlan = /* GraphQL */ `
  subscription OnDeleteSubscriptionPlan(
    $filter: ModelSubscriptionSubscriptionPlanFilterInput
  ) {
    onDeleteSubscriptionPlan(filter: $filter) {
      id
      planType
      isActive
      totalMembers
      cost
      billingCycle
      dateRenewalRequired
      stripeSubscriptionID
      promoFactor
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateJob = /* GraphQL */ `
  subscription OnCreateJob(
    $filter: ModelSubscriptionJobFilterInput
    $organizationID: String
  ) {
    onCreateJob(filter: $filter, organizationID: $organizationID) {
      id
      jobName
      memberName
      userID
      eventID
      startDate
      endDate
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateJob = /* GraphQL */ `
  subscription OnUpdateJob(
    $filter: ModelSubscriptionJobFilterInput
    $organizationID: String
  ) {
    onUpdateJob(filter: $filter, organizationID: $organizationID) {
      id
      jobName
      memberName
      userID
      eventID
      startDate
      endDate
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteJob = /* GraphQL */ `
  subscription OnDeleteJob(
    $filter: ModelSubscriptionJobFilterInput
    $organizationID: String
  ) {
    onDeleteJob(filter: $filter, organizationID: $organizationID) {
      id
      jobName
      memberName
      userID
      eventID
      startDate
      endDate
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateVersion = /* GraphQL */ `
  subscription OnCreateVersion($filter: ModelSubscriptionVersionFilterInput) {
    onCreateVersion(filter: $filter) {
      id
      version
      supported
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVersion = /* GraphQL */ `
  subscription OnUpdateVersion($filter: ModelSubscriptionVersionFilterInput) {
    onUpdateVersion(filter: $filter) {
      id
      version
      supported
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVersion = /* GraphQL */ `
  subscription OnDeleteVersion($filter: ModelSubscriptionVersionFilterInput) {
    onDeleteVersion(filter: $filter) {
      id
      version
      supported
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
