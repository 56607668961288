export const contactsNews = [
  {
    date: "July 10, 2023",
    header: "Edit list editor permissions",
    description:
      "Choose between trusting editors with sensitive operations, like removing contacts and sharing or deleting the list, or editing actions, like creating contacts and adding scores.",
  },
  {
    date: "July 6, 2023",
    header: "Create contacts presentations",
    description:
      "Automate your rush presentation creation with a customizable presentation tailored for you straight from your contacts list data, including engagement metrics and member opinions.",
  },
  {
    date: "July 2, 2023",
    header: "Our biggest web portal update to date",
    description:
      "Access powerful contacts features including filtering by metrics, event attendance/invitation, and determining who you forgot to invite.",
  },
  {
    date: "June 30, 2023",
    header: "Welcoming contacts",
    description:
      "Your organization's one stop solution for recruitment. Automate invitations, view engagement metrics, and streamline your entire workflow.",
  },
];
