import { colors } from "../../../theme/colors";

export const capabilitiesData = [
  {
    image: require("../../../assets/Landing/LandingSocialOverview.png"),
    title: "Text, Snap, or DM secure QR invites to guests",
    subTitle:
      "Say goodbye to the headache of manually distributing invites and tracking a guest list while making your guests' lives easier.",
    linkText: "Learn more about Social with CLIQInvite",
    linkRef: "#",
    color: colors.primaryColor,
  },
  {
    image: require("../../../assets/Landing/LandingRushOverview.png"),
    title: "Streamline mass invitation and track engagement",
    subTitle:
      "With our Rush Suite, you're equipped with personalized mass texting, engagement metrics, member opinion tracking, and automatic presentations.",
    linkText: "Learn more about CLIQInvite Rush Suite",
    linkRef: "https://www.cliqinvite.com/rush",
    color: colors.nemoBlue,
  },
  {
    image: require("../../../assets/Landing/LandingPhilanthropyOverview.png"),
    title: "Sell tickets with limited-use links",
    subTitle:
      "Make it easy for your guests to purchase tickets, access brother revenue breakdowns, and directly control ticket link limits.",
    linkText: "Learn more about selling tickets with CLIQInvite",
    linkRef: "https://www.cliqinvite.com/rush",
    color: colors.softGreen,
  },
];
