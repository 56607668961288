import React, { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import {
  ArrowDropDownOutlined,
  ArrowForwardOutlined,
  ChangeCircleOutlined,
} from "@mui/icons-material";
import { useAuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { calculateSubscriptionCosts } from "../../methods/calculateSubscriptionCosts";

const SubscriptionOptions = ({
  planType,
  setPlanType,
  setPhase,
  setTotalCost,
  billingCycle,
  setAnimationType,
  setBillingCycle,
  totalMembers,
  setTotalMembers,
  setSnackbarOpen,
  setClientSecret,
  memberCost,
  setMemberCost,
  chapterCost,
  setChapterCost,
}) => {
  const { dbUser, authUser } = useAuthContext();

  const [changePlanAnchorEl, setAnchorEl] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const { memberCost, chapterCost, totalCost } = calculateSubscriptionCosts({
      billingCycle: billingCycle,
      planType: planType,
      totalMembers: totalMembers,
    });

    setMemberCost(memberCost);
    setChapterCost(chapterCost);
    setTotalCost(totalCost);
  }, [planType, totalMembers, billingCycle]);

  const handleOpenPlanMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    if (value) {
      setPlanType(value);
    }
  };

  const handleNextPress = async () => {
    setSubmitting(true);
    const token = authUser?.signInUserSession.idToken.jwtToken;

    try {
      const res = await axios.post(
        process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
        {
          currency: "usd",
          numMembers: totalMembers,
          planType: planType,
          billingCycle: billingCycle,
          test: process.env.NODE_ENV !== "production",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const clientSecret = res.data.client_secret;
      setClientSecret(clientSecret);
      setSubmitting(false);
      setAnimationType("FORWARD");
      setPhase("INFORMATION");
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error intializing payment. Please try again.",
      });
      setSubmitting(false);
      console.error(err);
    }
  };

  return (
    <Box
      className="unstyled-card"
      sx={{
        mt: 5,
        bgcolor: colors.secondaryBackground,
        display: "flex",
        flexDirection: "column",
        px: 5,
        py: 2,
        alignSelf: "center",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={9} sx={{ mb: 1 }}>
          <h1 style={{ margin: 0, fontSize: "1.5em", fontWeight: 500 }}>
            Select Plan Options
          </h1>
          <p style={{ margin: 0, color: colors.secondaryText }}>
            For account {dbUser?.name}
          </p>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
            }}
            startIcon={<ChangeCircleOutlined sx={{ color: "white" }} />}
            fullWidth
            onClick={handleOpenPlanMenu}
          >
            Change Plan
          </Button>
          <Menu
            anchorEl={changePlanAnchorEl}
            open={Boolean(changePlanAnchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleClose("Pro")}>Pro</MenuItem>
            <MenuItem onClick={() => handleClose("Premium")}>Premium</MenuItem>
            <MenuItem onClick={() => handleClose("VIP")}>VIP</MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: colors.secondaryText, margin: 0 }}>
            Billing Period
          </p>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <p style={{}}>Billed</p>
            <Select
              onChange={(event) => {
                if (event.target.value === "Monthly") {
                  setBillingCycle("Month");
                } else {
                  setBillingCycle("Year");
                }
              }}
              variant="outlined"
              value={`${
                billingCycle === "Year" ? "Yearly (10% Off)" : "Monthly"
              }`}
              IconComponent={() => (
                <ArrowDropDownOutlined
                  sx={{
                    color: colors.secondaryText,
                    cursor: "pointer",

                    ":hover": {
                      opacity: 0.7,
                    },
                  }}
                />
              )}
              SelectDisplayProps={{
                style: {
                  padding: 0,
                  paddingLeft: ".5em",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: { backgroundColor: colors.secondaryBackground },
                },
              }}
              sx={{
                flex: 1,
                ml: 1,
                p: 0.5,
                bgcolor: colors.backgroundHighlight,
                color: colors.primaryText,
              }}
            >
              {["Monthly", "Yearly (10% Off)"].map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={9} xl={10.5}>
          <p
            style={{
              color: colors.secondaryText,
              margin: 0,
              marginBottom: ".5em",
            }}
          >
            Number of Members
          </p>
          <Slider
            value={typeof totalMembers === "number" ? totalMembers : 1}
            onChange={(event, newValue) => setTotalMembers(newValue)}
            valueLabelDisplay="auto"
            step={1}
            max={500}
            min={1}
            sx={{
              "& .MuiSlider-valueLabel": {
                fontSize: "1em",
                fontWeight: "normal",
                top: -6,
                backgroundColor: "unset",
                color: colors.primaryText,
                fontFamily: "Poppins",
                "&:before": {
                  display: "none",
                },
                "& *": {
                  background: "transparent",
                  color: colors.primaryText,
                },
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          xl={1.5}
          sx={{ display: "flex", alignItems: "flex-end" }}
        >
          <TextField
            value={totalMembers}
            size="small"
            onChange={(event) => setTotalMembers(parseInt(event.target.value))}
            onBlur={() => {
              if (totalMembers < 0) {
                setTotalMembers(0);
              }
            }}
            inputProps={{
              step: 1,
              min: 0,
              max: 10000,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
            sx={{
              bgcolor: colors.backgroundHighlight,
              borderRadius: 1,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <p style={{ margin: 0, color: "white", fontSize: "3em" }}>
                ${(memberCost / 100).toFixed(2)}
              </p>
              <Box>
                <p
                  style={{
                    margin: 0,
                    fontSize: ".9em",
                    color: colors.secondaryText,
                  }}
                >
                  / Member
                </p>
                <p
                  style={{
                    margin: 0,
                    color: "white",
                    fontSize: ".9em",
                    color: colors.secondaryText,
                  }}
                >
                  / {billingCycle}
                </p>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                mt: -1,
              }}
            >
              <p style={{ margin: 0, color: "white", fontSize: "1.5em" }}>
                + ${(chapterCost / 100).toFixed(2)}
              </p>
              <Box>
                <p
                  style={{
                    margin: 0,
                    color: "white",
                    fontSize: ".6em",
                    color: colors.secondaryText,
                  }}
                >
                  / {billingCycle}
                </p>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {!submitting ? (
            <Button
              variant="contained"
              fullWidth
              startIcon={<ArrowForwardOutlined sx={{ color: "white" }} />}
              onClick={handleNextPress}
            >
              Next
            </Button>
          ) : (
            <BeatLoader
              color={colors.primaryColor}
              speedMultiplier={0.7}
              size="1.5em"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubscriptionOptions;
