import {
  Alert,
  Box,
  Button,
  Grid,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import { useAuthContext } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { OrganizationMember, SubscriptionPlan } from "../../models";
import PlanModule from "./PlanModule";
import MemberUsageDisplay from "./MemberUsageDisplay";
import { Settings } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import PaymentMethodDisplay from "./PaymentMethodDisplay";
import SubscriptionSettingsDialog from "./SubscriptionSettingsDialog";
import { useNavigate, useParams } from "react-router-dom";

const Billing = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState(null);
  const { modalOptions } = useParams();
  const [organizationMembers, setOrganizationMembers] = useState(null);
  const [subscriptionSettingsDialogOpen, setSubscriptionSettingsDialogOpen] =
    useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const { dbUser, planLimits } = useAuthContext();

  if (planLimits && planLimits.planType === "Free") {
    navigate("/plans");
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("redirect_status") === "succeeded") {
      setSnackbarOpen({
        type: "success",
        message:
          "Successfully created payment method. You can now assign it as default if desired.",
        action: (
          <Button
            size="small"
            color="inherit"
            onClick={() => {
              window.history.replaceState(null, "", window.location.pathname);
              setSnackbarOpen(null);
            }}
          >
            Ok
          </Button>
        ),
      });
    }
    if (modalOptions) {
      setSubscriptionSettingsDialogOpen(true);
    }
    if (dbUser) {
      (async () => {
        const subscriptionPlans = await DataStore.query(SubscriptionPlan, (p) =>
          p.organizationID.eq(dbUser.id)
        );

        const members = await DataStore.query(OrganizationMember, (m) =>
          m.and((m) => [m.organizationID.eq(dbUser.id), m.isConfirmed.eq(true)])
        );

        setOrganizationMembers(members);
        setPlans(subscriptionPlans);
      })();
    }
  }, [dbUser?.id]);

  const handleGetMoreMembers = () => {
    setSubscriptionSettingsDialogOpen(true);
  };

  const pageTitle = `${dbUser?.name ? dbUser.name : ""} Billing • CLIQInvite`;
  const pageDescription = `Easily manage your subscription and billing on CLIQInvite. Adjust your plan to fit your needs with transparent pricing and secure transactions.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/dashboard/billing`;

  const loading = !dbUser || !plans || !organizationMembers;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Snackbar open={!!snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <WebPortalNavigation activeScreen="/dashboard" />
      {loading ? null : (
        <SubscriptionSettingsDialog
          subscriptionSettingsDialogOpen={subscriptionSettingsDialogOpen}
          setSubscriptionSettingsDialogOpen={setSubscriptionSettingsDialogOpen}
          plan={plans.find((p) => p.isActive)}
          setSnackbarOpen={setSnackbarOpen}
          modalOptions={modalOptions}
        />
      )}
      <Box
        className="page-size-medium"
        sx={{ alignSelf: "center", pt: "2em", pb: "2em" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              className="unstyled-card"
              sx={{
                px: 2,
                py: 1,
                pb: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {loading ? (
                <>
                  <Skeleton sx={{ fontSize: "2.5em", mb: 2 }} />
                </>
              ) : (
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h1"
                      sx={{ mt: ".2em", fontSize: "1.3em" }}
                    >
                      Subscription Plan
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 2, fontSize: ".8em", mt: ".2em" }}
                    >
                      View the details of your current plan
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colors.backgroundHighlight,
                      ":hover": {
                        backgroundColor: `${colors.backgroundHighlight}80`,
                      },
                    }}
                    onClick={() => setSubscriptionSettingsDialogOpen(true)}
                    startIcon={<Settings sx={{ color: "white" }} />}
                  >
                    Settings
                  </Button>
                </Box>
              )}

              {loading ? (
                <Skeleton variant="rounded" height="15em" />
              ) : (
                plans.map((plan, index) =>
                  plan.isActive ? (
                    <PlanModule plan={plan} key={index.toString()} />
                  ) : null
                )
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <MemberUsageDisplay
              loading={loading}
              organizationMembers={organizationMembers}
              handleGetMoreMembers={handleGetMoreMembers}
              plan={plans?.find((plan) => plan.isActive)}
            />
          </Grid>
          <Grid item xs={12}>
            <PaymentMethodDisplay
              loading={loading}
              plan={plans?.find((plan) => plan.isActive)}
              setSnackbarOpen={setSnackbarOpen}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Billing;
