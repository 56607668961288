import React from "react";
import "./ContactForm.css";

import emailjs from "@emailjs/browser";
import { Box, Button, TextField } from "@mui/material";
import { colors } from "../../theme/colors";

const ContactForm = ({ titleShown, buttonSize, name, email, close }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    emailjs
      .send(
        "service_q5jnjf9",
        "template_hiqke3s",
        {
          name: name || data.get("name"),
          email: email || data.get("email"),
          subject: data.get("subject"),
          message: data.get("message"),
        },
        "lkXy20z68OpeLFbkW"
      )
      .then(console.log);

    e.currentTarget.reset();
    if (close) {
      close();
    }
  };
  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {titleShown ? <h1 style={{ fontSize: "3em" }}>Contact Us</h1> : null}

      {!name ? (
        <TextField
          id="name"
          label="Name"
          name="name"
          variant="outlined"
          required
          InputLabelProps={{ style: { color: colors.primaryColor } }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: `${colors.primaryColor}20`,
            },
          }}
          sx={{ caretColor: "white", mb: "1em" }}
          fullWidth
        />
      ) : null}
      {!email ? (
        <TextField
          id="email"
          label="Email"
          name="email"
          variant="outlined"
          required
          InputLabelProps={{ style: { color: colors.primaryColor } }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: `${colors.primaryColor}20`,
            },
          }}
          sx={{ caretColor: "white", mb: "1em" }}
          fullWidth
        />
      ) : null}
      <TextField
        id="subject"
        label="Subject"
        name="subject"
        variant="outlined"
        required
        InputLabelProps={{ style: { color: colors.primaryColor } }}
        InputProps={{
          style: {
            color: "white",
            backgroundColor: `${colors.primaryColor}20`,
          },
        }}
        sx={{ caretColor: "white", mb: "1em" }}
        fullWidth
      />
      <TextField
        inputProps={{ borderColor: "white" }}
        id="message"
        label="Message"
        name="message"
        multiline
        variant="outlined"
        minRows={3}
        required
        InputLabelProps={{ style: { color: colors.primaryColor } }}
        InputProps={{
          style: {
            color: "white",
            backgroundColor: `${colors.primaryColor}20`,
          },
        }}
        sx={{ caretColor: "white", mb: "1em" }}
        fullWidth
      />
      <Button
        type="submit"
        sx={{
          fontSize: buttonSize === "small" ? "1em" : "1.2em",
          width: "80%",
          mt: 2,
        }}
        size={buttonSize === "small" ? "small" : "large"}
        variant="contained"
      >
        Send
      </Button>
    </Box>
  );
};

export default ContactForm;
