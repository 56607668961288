import { sum } from "./sum";

export const getCliqScore = (
  contactID,
  guestInvites,
  uniqueExpiredEventIDs
) => {
  let invited = 0;
  let attended = 0;
  let invalid = 0;
  let used = 0;

  const contactGuestInvites = guestInvites.filter(
    (g) =>
      g.contactID === contactID && uniqueExpiredEventIDs.includes(g.eventID)
  );

  const uniqueEventIDs = uniqueExpiredEventIDs;

  invited = sum(contactGuestInvites, "maxUses");
  // Filter contactGuestInvites to only include those with unique eventIDs and numUsed > 0
  attended = contactGuestInvites.filter((g) => g.numUsed > 0).length;

  invalid = sum(contactGuestInvites, "numInvalid");
  used = sum(contactGuestInvites, "numUsed");
  let engagementPresence = uniqueEventIDs.length
    ? (attended / uniqueEventIDs.length) * 100
    : 0;
  let engagementUsage = invited ? (used / invited) * 100 : 0;
  let penaltyInvalid = invited ? (invalid / invited) * 100 : 0;
  let intermediary =
    engagementPresence * 0.8 + engagementUsage * 0.15 - penaltyInvalid * 0.05;
  let medianIS = 50;
  let MADValue = 10;
  let cliqScore = invited === 0 ? 50 : 50 + 10 * ((intermediary - medianIS) / MADValue);

  let eventScore = Math.round((attended * 100) / uniqueEventIDs.length);


  return {
    cliqScore: Math.min(Math.round(cliqScore), 100),
    eventScore: eventScore,
    invitedTo: uniqueEventIDs.length,
    attended: attended,
    scansGiven: invited,
    validScans: used,
    invalidScans: invalid,
  };
};
