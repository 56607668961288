import { Box } from "@mui/material";
import "../Section.css";
import LandingCapabilitiesModule from "./LandingCapabilitiesModule";
import { capabilitiesData } from "./capabilitiesData";
import { colors } from "../../../theme/colors";

const LandingCapabilitiesOverviewSection = ({}) => {
  return (
    <Box
      sx={{
        py: { sm: 12, xs: 8 },
        gap: 8,
        bgcolor: colors.beige,
      }}
      width="100dvw"
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      {capabilitiesData.map(
        ({ title, image, subTitle, linkText, linkRef, color }, index) => {
          return (
            <Box key={index.toString()}>
              <LandingCapabilitiesModule
                inverted={index % 2}
                title={title}
                image={image}
                subTitle={subTitle}
                linkRef={linkRef}
                linkText={linkText}
                color={color}
              />
            </Box>
          );
        }
      )}
    </Box>
  );
};

export default LandingCapabilitiesOverviewSection;
