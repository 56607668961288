import { Box, Typography, Button, TextField } from "@mui/material";
import Logo from "../../components/Logo";
import { PhoneOutlined, VerifiedOutlined } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

const EnterCode = ({
  handleLogin,
  phone,
  loading,
  stage,
  code,
  setCode,
  handleResendCode,
}) => {
  const navigate = useNavigate();
  const [shouldRenderCode, setShouldRenderCode] = useState(false);
  const [selectedCodeInputIndex, setSelectedCodeInputIndex] = useState(0);

  const handleChangeCode = (text) => {
    if (text.length <= 6) {
      setCode(text);
    }
  };

  useEffect(() => {
    if (stage === "VERIFY") {
      setTimeout(() => {
        setShouldRenderCode(true);
      }, 500);
    } else if (stage === "CREATE") {
      setTimeout(() => {
        setShouldRenderCode(true);
      }, 500);
    }
  }, [stage]);
  let inputRef = useRef();

  useEffect(() => {
    setSelectedCodeInputIndex(code.length);
  }, [code]);

  if (!shouldRenderCode) {
    return null;
  }

  return (
    <Box>
      <Box component="form" onSubmit={handleLogin}>
        <Typography
          variant="h2"
          sx={{
            fontSize: "1.3rem",
            color: colors.defaultBackground,
            mb: "1rem",
          }}
        >
          We sent you a text
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: "1rem",
            color: colors.backgroundHighlight,
            fontSize: ".8rem",
          }}
        >
          To continue, please enter the code sent to{" "}
          <b>your phone ending in {phone.substring(phone.length - 4)}</b>.
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: "1.75rem",
            color: colors.backgroundHighlight,
            fontSize: ".8rem",
          }}
        >
          Didn't get a code?{" "}
          <b
            onClick={() => handleResendCode()}
            style={{ cursor: "pointer", color: colors.primaryColor }}
          >
            Resend
          </b>
          .
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: ".4rem",
            mb: "2.25rem",
          }}
        >
          <input
            ref={(input) => (inputRef = input)}
            style={{ position: "absolute", zIndex: -2, opacity: 0 }}
            value={code}
            onChange={(event) => handleChangeCode(event.target.value)}
            autoFocus={true}
          ></input>
          {Array.apply(null, Array(6)).map((value, index) => {
            return (
              <>
                <Box
                  key={index.toString()}
                  sx={{
                    background: colors.secondaryText,
                    padding: "2rem 0rem",
                    borderRadius: "1rem",
                    height: "2rem",
                    width: "4rem",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    outlineColor:
                      selectedCodeInputIndex === index
                        ? colors.primaryColor
                        : "transparent",
                    outlineWidth: 2,
                    outlineStyle: "solid",
                  }}
                  onClick={() => {
                    inputRef.focus();
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "2em", color: colors.defaultBackground }}
                  >
                    {code.charAt(index)}
                  </Typography>
                </Box>
              </>
            );
          })}
        </Box>
        <Button
          variant="contained"
          fullWidth
          id={"login"}
          type="submit"
          sx={{ mb: "2.25rem" }}
          startIcon={<VerifiedOutlined sx={{ color: "white" }} />}
        >
          {loading ? "Verifying..." : "Continue"}
        </Button>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="body2"
            style={{
              fontSize: ".8rem",
              color: colors.backgroundHighlight,
            }}
          >
            @ 2024 CLIQInvite
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: ".8rem",
              color: colors.backgroundHighlight,
              cursor: "pointer",
            }}
            onClick={() => navigate("/legal")}
          >
            Privacy
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EnterCode;
