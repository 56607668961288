import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { colors } from "../../theme/colors";
import AdjustNumMembers from "./AdjustNumMembers";
import ChangePlanType from "./ChangePlanType";
import ChangeBillingCycle from "./ChangeBillingCycle";
import CancelPlan from "./CancelPlan";

const SubscriptionSettingsDialog = ({
  subscriptionSettingsDialogOpen,
  setSubscriptionSettingsDialogOpen,
  plan,
  setSnackbarOpen,
  modalOptions,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    modalOptions === "upgrade_plan" ? "Plan Type" : "Members"
  );
  const buttons = ["Members", "Plan Type", "Billing Cycle", "Cancel Plan"];

  const handleHelpItemClick = (button) => {
    setSelectedOption(button);
  };

  return (
    <Dialog
      onClose={() => setSubscriptionSettingsDialogOpen(false)}
      open={subscriptionSettingsDialogOpen}
    >
      <DialogTitle>Plan Settings</DialogTitle>

      <DialogContent>
        <Grid container>
          {buttons.map((button) => (
            <Grid
              item
              xs={3}
              key={button}
              onClick={() => handleHelpItemClick(button)}
              sx={{
                backgroundColor:
                  selectedOption === button
                    ? `${colors.primaryColor}22`
                    : "transparent",
                mb: 1,
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5,
              }}
            >
              <ListItemText
                sx={{
                  fontFamily: "Poppins",
                  cursor: "pointer",
                  transition: "opacity 200ms ease-in-out",
                  ":hover": {
                    opacity: 0.4,
                  },
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: button === "Cancel" ? 500 : 400,
                    fontSize: "1em",
                    textAlign: "center",
                    color: colors.secondaryText,
                  }}
                >
                  {button}
                </p>
              </ListItemText>
            </Grid>
          ))}
          <Grid item xs={12}>
            {selectedOption === "Members" ? (
              <AdjustNumMembers
                initialMembers={plan?.totalMembers}
                cost={plan?.cost}
                plan={plan}
                setSnackbarOpen={setSnackbarOpen}
                close={() => setSubscriptionSettingsDialogOpen(false)}
              />
            ) : selectedOption === "Billing Cycle" ? (
              <ChangeBillingCycle
                cost={plan?.cost}
                plan={plan}
                setSnackbarOpen={setSnackbarOpen}
                close={() => setSubscriptionSettingsDialogOpen(false)}
              />
            ) : selectedOption === "Plan Type" ? (
              <ChangePlanType
                cost={plan?.cost}
                plan={plan}
                setSnackbarOpen={setSnackbarOpen}
                close={() => setSubscriptionSettingsDialogOpen(false)}
                modalOptions={modalOptions}              />
            ) : (
              <CancelPlan
                cost={plan?.cost}
                plan={plan}
                setSnackbarOpen={setSnackbarOpen}
                close={() => setSubscriptionSettingsDialogOpen(false)}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionSettingsDialog;
