import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import { ArrowBackOutlined, Casino } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  getModuleDate,
  getModuleTime,
} from "../../../methods/getFormattedTime";
import { useAuthContext } from "../../../contexts/AuthContext";
import { OrganizationMember } from "../../../models";
import { DataStore } from "aws-amplify";

const ReviewJobs = ({
  jobs,
  phase,
  animationType,
  setPhase,
  setAnimationType,
}) => {
  const { dbUser } = useAuthContext();
  const [groupsMemberQuantity, setGroupsMemberQuantity] = useState([]);

  useEffect(() => {
    if (dbUser) {
      (async () => {
        const orgMembers = await DataStore.query(OrganizationMember, (o) =>
          o.and((o) => [o.organizationID.eq(dbUser.id), o.isConfirmed.eq(true)])
        );

        let groupsMemberBreakdown = [...dbUser.createdRoles, "No Group"].map(
          (group) => {
            let groupOrgMembers = orgMembers.filter(
              (orgMember) => orgMember.role === group
            );
            return { group: group, members: groupOrgMembers.length };
          }
        );

        setGroupsMemberQuantity(groupsMemberBreakdown);
      })();
    }
  }, [dbUser]);

  const handleNextPress = () => {
    setAnimationType("FORWARD");

    setPhase("OUTPUT");
  };

  const handleBackPress = () => {
    setAnimationType("BACKWARD");

    setPhase("WEIGHTS");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 2,
        py: 2,
        borderRadius: 2,
        width: {
          sm: "60vw",
          xl: "45vw",
        },
        position: "absolute",
        alignSelf: "center",
        bgcolor: colors.secondaryBackground,
      }}
      className={
        phase === "REVIEW"
          ? animationType === "FORWARD"
            ? "subscription-slide-in"
            : "subscription-slide-back-in"
          : phase !== "WEIGHTS"
          ? animationType === "FORWARD"
            ? "subscription-slide-out"
            : "subscription-off-screen"
          : animationType !== "FORWARD"
          ? "subscription-slide-back-out"
          : "subscription-off-screen"
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h1"
          sx={{ fontWeight: 500, flex: 1, fontSize: "1.3em" }}
        >
          Review Configuration
        </Typography>
      </Box>

      <Box
        className="scroll-container"
        sx={{ mt: 2, maxHeight: "70dvh", overflow: "auto" }}
      >
        {jobs.map((job) => {
          return (
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" sx={{ fontSize: "1.1em", flex: 1 }}>
                  {job.name}
                </Typography>
                <Typography variant="body2">
                  {Math.ceil(
                    ((job.jobEnd - job.jobStart) *
                      job.quantity) /
                      job.interval
                  )}{" "}
                  jobs
                </Typography>
              </Box>
              <Typography variant="body2">
                {`${
                  getModuleDate(Math.floor(job.startDate)).split(",")[0]
                } ${getModuleTime(Math.floor(job.startDate))} → ${
                  getModuleDate(Math.floor(job.endDate)).split(",")[0]
                } ${getModuleTime(Math.floor(job.endDate))}`}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {job.interval} minute shift interval • {job.quantity}{" "}
                {job.quantity === "1" ? "person" : "people"} per shift
              </Typography>
              <Typography variant="body2">
                Probability of a member getting a shift
              </Typography>
              <Grid
                container
                sx={{ px: 1, py: 0.5, bgcolor: `${colors.primaryColor}22` }}
              >
                {["Group", "Weight", "Probability"].map((colTitle) => (
                  <Grid item xs={4}>
                    <Typography>{colTitle}</Typography>
                  </Grid>
                ))}
              </Grid>
              {groupsMemberQuantity.map((group, index) => {
                if (!job.weightValues) {
                  return <Box />;
                }
                const relevantWeightValue =
                  job.weightValues.find(
                    (weightValue) => weightValue.group === group.group
                  )?.weight || 0;
                const totalWeight = job.weightValues.reduce((prev, curr) => {
                  return (
                    prev +
                      parseFloat(curr.weight) *
                        groupsMemberQuantity.find(
                          (group) => group.group === curr.group
                        )?.members || 0
                  ); //depends
                }, 0);

                const weightProportion =
                  (relevantWeightValue * group.members) / totalWeight;

                return (
                  <Grid
                    container
                    sx={{
                      bgcolor:
                        index % 2
                          ? `${colors.backgroundHighlight}50`
                          : colors.backgroundHighlight,
                      px: 1,
                      py: 0.5,
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography>{group.group}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{relevantWeightValue}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {((weightProportion * 100) / group.members).toFixed(1)}%
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          );
        })}
      </Box>
      <Box sx={{ display: "flex", mt: 3, width: "100%", gap: 1 }}>
        <Button
          sx={{
            bgcolor: colors.backgroundHighlight,
            ":hover": {
              bgcolor: `${colors.backgroundHighlight}D6`,
            },
          }}
          variant={"contained"}
          startIcon={<ArrowBackOutlined sx={{ color: colors.primaryText }} />}
          onClick={handleBackPress}
        >
          {"Go Back"}
        </Button>
        <Button
          variant={"contained"}
          sx={{ flex: 1 }}
          color="primary"
          endIcon={
            <Casino
              sx={{
                color: colors.primaryText,
              }}
            />
          }
          onClick={handleNextPress}
        >
          {"Generate"}
        </Button>
      </Box>
    </Box>
  );
};

export default ReviewJobs;
