const darkMode = true;
export const colors = darkMode
  ? {
      sunsetOrange: "#Fb7328CC",
      darkPink: "#ad3b5bE6",
      highlightColor: "#b957cf",
      // highlightColor: "#f660fb",
      primaryColor: "#a020f0",
      defaultBackground: "#000000",
      backgroundHighlight: "#1e2124",
      secondaryBackground: "#141414",
      keyLime: "#edf67d",
      orangeHighlight: "#ed6c31",
      errorColor: "#ad2831",
      primaryHighlight: "#b957cf",
      softGreen: "#36a148",
      primaryText: "#ffffff",
      secondaryText: "#bbbbbb",
      secondaryColor: "#ffd700",
      tertiaryText: "#2e0548",
      eggshell: "#FAF9F6",
      nemoBlue: "#008CFF",
      beige: "#F4EDE4",
      darkenedNemoBlue: "#002441",
    }
  : {
      sunsetOrange: "#Fb7328CC",
      darkPink: "#ad3b5bE6",
      highlightColor: "#b957cf",
      // highlightColor: "#f660fb",
      primaryColor: "#a020f0",
      defaultBackground: "#282b30",
      backgroundHighlight: "#424549",
      secondaryBackground: "#1e2124",
      keyLime: "#edf67d",
      orangeHighlight: "#ed6c31",
      errorColor: "#ad2831",
      primaryHighlight: "#b957cf",
      softGreen: "#36a148",
      primaryText: "#ffffff",
    };
