import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import React from "react";
import RushFAQAccordion from "./RushFAQAccordion";

const FAQs = [
  {
    question: "Do automated texts come from my phone number?",
    answer:
      " All texts are sent directly from your personal number, meaning that any PNM who receives a text from you via Rush Suite will be able to respond directly to you. With just a few more taps, Rush Suite makes it possible for you to interface directly with your PNMs from your phone, cementing us as a leader in mass texting for rush. Additionally, you are given the opportunity to review and confirm each text before it is sent, ensuring that you don't accidentally text the wrong person the wrong message.",
    boldedAnswerText: "Yes.",
  },
  {
    question: "Can I import contacts from a spreadsheet?",
    boldedAnswerText: "Yes.",
    answer:
      " Rush Suite allows you to easily import contacts from an existing spreadsheet, including key information such as contact name, phone number, and notes. Additionally, you can export your contacts back to a spreadsheet at any time.",
  },
  {
    question: "Can I assign admin permissions to shared Contact Lists?",
    boldedAnswerText: "Yes.",
    answer:
      " Rush Suite's sharing capabilities allow you to assign specific permissions to collaborators, empowering your organization with the right tools to make data-driven decisions without sacrificing control.",
  },
  {
    question: "Will PNMs know I'm using automated software?",
    boldedAnswerText: "No.",
    answer:
      " Rush Suite's automated personalized texting feature appears identical to sending texts yourself. Automated texts appear in your messaging app just like any other text.",
  },
  {
    question: "How does CLIQInvite calculate PNM engagement?",
    answer:
      "Rush Suite calculates your PNMs' engagement scores with a simple equation that takes into account event attendance, events invited to, and total scan usage. This data empowers you to make quick, informed decisions throughout your recruitment process without relying on guesswork.",
  },
  {
    question:
      "Can I choose what metrics and data appear on our exported slideshows?",
    boldedAnswerText: "Yes.",
    answer:
      " Rush Suite enables you to carefully select which data points you want to display on your presentation, including name, contact, photo, evennt attendance breakdowns, and every other metric we track.",
  },
  {
    question: "How do I get started?",
    answer:
      "Download the app using the link above and sign up with your phone number. From there, our onboarding process will guide you through setting up your organization and onboarding your members. For any additional help, feel free to text or call our founders at 703-424-8009",
  },
];

const RushFAQSection = () => {
  return (
    <Box
      sx={{
        py: "8em",
        bgcolor: colors.eggshell,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: colors.defaultBackground,
          lineHeight: 1.12,
          letterSpacing: "-.8px",
          textAlign: "center",
          alignSelf: "center",
          fontSize: {
            lg: "2rem",
            md: "2rem",
            sm: "2rem",
            xs: "1.6rem",
          },
          width: {
            lg: "70%",
          },
          mb: "3rem",
        }}
      >
        Frequently asked questions
      </Typography>
      <Box className="default-page-size">
        {FAQs.map((FAQ, index) => {
          return <RushFAQAccordion FAQ={FAQ} index={index} />;
        })}
      </Box>
    </Box>
  );
};

export default RushFAQSection;
