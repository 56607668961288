import { Box, Button, Grid, Skeleton, Tooltip } from "@mui/material";
import { colors } from "../../theme/colors";
import { Help, QuestionAnswerOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { useAuthContext } from "../../contexts/AuthContext";
import { Event, GuestInvite } from "../../models";
import ListEventModule from "./ListEventModule";

const ListEventsDisplay = ({ contactLists, loading }) => {
  const [upcomingListEvents, setUpcomingListEvents] = useState([]);
  const { dbUser } = useAuthContext();
  useEffect(() => {
    const fetchUpcomingEvents = async () => {
      const events = await DataStore.query(Event, (e) =>
        e.and((e) => [
          e.organizationID.eq(dbUser?.id),
          e.endDate.gt(Date.now().toString()),
        ])
      );
      return events;
    };

    const fetchEventInvites = async (eventID) => {
      const invites = await DataStore.query(GuestInvite, (g) =>
        g.and((g) => [g.eventID.eq(eventID), g.contactListID.ne(null)])
      );
      return invites;
    };

    if (!loading) {
      (async () => {
        const upcomingEvents = await fetchUpcomingEvents();
        const promises = upcomingEvents.map(async (event) => {
          const contactListInvites = await fetchEventInvites(event.id);
          const uniqueContactListInviteIDs = new Set(
            contactListInvites.map((invite) => invite.contactListID)
          );
          let relevantLists = [...contactLists]
            .filter((list) => uniqueContactListInviteIDs.has(list.id))
            .map((item) => item.name);
          if (relevantLists.length) {
            let inviteCount = contactListInvites.filter((invite) =>
              relevantLists.includes(invite.contactListID)
            ).length;
            return {
              ...event,
              relevantLists: relevantLists,
              inviteCount: inviteCount,
            };
          }
        });

        const tempUpcomingListEvents = (await Promise.all(promises)).filter(
          Boolean
        ); // remove undefined values
        setUpcomingListEvents(tempUpcomingListEvents);
      })();
    }
  }, [contactLists, dbUser?.id]);

  const renderItem = (item) => <ListEventModule event={item} key={item.id} />;

  return (
    <Box className="unstyled-card" sx={{ p: ".5em 1em" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".5em",
          marginBottom: ".5em",
        }}
      >
        {loading ? (
          <Skeleton sx={{ fontSize: "1.2em", width: "100%" }} />
        ) : (
          <>
            <h1
              style={{
                margin: 0,
                fontSize: "1.2em",
              }}
            >
              Upcoming List Events
            </h1>
            <Tooltip
              title="Upcoming List Events are your upcoming or ongoing events where at least one invite has been sent to a contact using that list."
              sx={{ fontFamily: "Poppins" }}
            >
              <Help sx={{ color: colors.secondaryText }} fontSize="small" />
            </Tooltip>
          </>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading ? (
          <Skeleton variant="rounded" sx={{ fontSize: "1em", mb: 1 }} />
        ) : (
          <Grid
            container
            alignItems="center"
            columns={10}
            sx={{
              px: 1,
              py: 0.25,
              mb: 1,
              borderRadius: 1,
              backgroundColor: colors.backgroundHighlight,
            }}
          >
            <Grid item xs={4}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Event
              </p>
            </Grid>
            <Grid item xs={4}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                List
              </p>
            </Grid>
            <Grid item xs={2}>
              <p
                style={{
                  fontSize: ".85em",
                  color: colors.secondaryText,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Contacts
              </p>
            </Grid>
          </Grid>
        )}
        {loading ? (
          Array.from({ length: 3 }, (_, i) => (
            <Skeleton
              variant="rounded"
              sx={{ fontSize: "2.5em", mb: 1 }}
              key={i.toString()}
            />
          ))
        ) : upcomingListEvents?.length ? (
          upcomingListEvents.map(renderItem)
        ) : (
          <p
            style={{
              alignSelf: "flex-start",
              fontSize: ".8em",
              margin: 0,
              fontWeight: 500,
              textAlign: "left",
              color: colors.secondaryText,
            }}
          >
            You must have an event with invites sent to a specific list for you
            to view and interact with your Upcoming List Events.
          </p>
        )}
      </div>

      {loading ? null : !upcomingListEvents.length ? (
        <div style={{ display: "flex", marginTop: ".8em", gap: "1em" }}>
          <Button
            variant="contained"
            sx={{
              flex: 1,
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
            }}
            startIcon={<QuestionAnswerOutlined sx={{ color: "white" }} />}
            onClick={() =>
              window.open(
                "https://www.cliqinvite.com/tutorials",
                "_blank",
                "noreferrer"
              )
            }
          >
            Learn More
          </Button>
        </div>
      ) : null}
    </Box>
  );
};

export default ListEventsDisplay;
