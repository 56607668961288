import { Box, Button, Slider, TextField, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  getModuleDate,
  getModuleTime,
} from "../../../methods/getFormattedTime";
import { useAuthContext } from "../../../contexts/AuthContext";

const DefineJobWeights = ({
  jobs,
  setJobs,
  phase,
  animationType,
  setAnimationType,
  setPhase,
  setSnackbarOpen,
}) => {
  const { dbUser } = useAuthContext();
  const [activeJobIndex, setActiveJobIndex] = useState(0);
  const [weightValues, setWeightValues] = useState([]);

  useEffect(() => {
    if (dbUser) {
      if (jobs[activeJobIndex].weightValues) {
        setWeightValues(
          [...dbUser.createdRoles, "No Group"].map((group) => {
            return jobs[activeJobIndex].weightValues.find(
              (wv) => wv.group === group
            ).weight;
          })
        );
      } else {
        setWeightValues(
          //we add one for "No Group"
          Array.from({ length: dbUser.createdRoles.length + 1 }, (_, i) => 1)
        );
      }
    }
  }, [dbUser?.id, activeJobIndex]);

  const handleNextPress = () => {
    try {
      if (activeJobIndex === jobs.length - 1) {
        setJobs((old) => {
          let tempArray = [...old];
          tempArray[activeJobIndex] = {
            ...tempArray[activeJobIndex],
            weightValues: weightValues.map((weightValue, index) => {
              return {
                group: [...dbUser?.createdRoles, "No Group"][index],
                weight: weightValue,
              };
            }),
          };

          return tempArray;
        });
        setAnimationType("FORWARD");

        setPhase("REVIEW");
      } else {
        setJobs((old) => {
          let tempArray = [...old];
          tempArray[activeJobIndex] = {
            ...tempArray[activeJobIndex],
            weightValues: weightValues.map((weightValue, index) => {
              return {
                group: [...dbUser?.createdRoles, "No Group"][index],
                weight: weightValue,
              };
            }),
          };

          return tempArray;
        });

        setActiveJobIndex((old) => old + 1);
      }
    } catch (err) {
      setSnackbarOpen({ type: "error", message: err.message });
    }
  };

  const handleBackPress = () => {
    if (activeJobIndex === 0) {
      setAnimationType("BACKWARD");

      setPhase("TIMES");
    } else {
      setActiveJobIndex((old) => old - 1);
    }
  };

  const activeJob = jobs[activeJobIndex];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 2,
        py: 2,
        borderRadius: 2,
        width: {
          sm: "60vw",
          xl: "45vw",
        },
        position: "absolute",
        alignSelf: "center",
        bgcolor: colors.secondaryBackground,
      }}
      className={
        phase === "WEIGHTS"
          ? animationType === "FORWARD"
            ? "subscription-slide-in"
            : "subscription-slide-back-in"
          : phase !== "TIMES"
          ? animationType === "FORWARD"
            ? "subscription-slide-out"
            : "subscription-off-screen"
          : animationType !== "FORWARD"
          ? "subscription-slide-back-out"
          : "subscription-off-screen"
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h1"
          sx={{ fontWeight: 500, flex: 1, fontSize: "1.3em" }}
        >
          Define Job Weights
        </Typography>
      </Box>

      <Box
        className="scroll-container"
        sx={{ mt: 2, maxHeight: "70dvh", overflow: "auto" }}
      >
        <Typography variant="body1" sx={{ fontSize: "1.1em" }}>
          {activeJob.name}
        </Typography>
        <Typography variant="body2">
          {Math.ceil(
            ((activeJob.jobEnd - activeJob.jobStart) * activeJob.quantity) /
              activeJob.interval
          )}{" "}
          jobs over{" "}
          {Math.ceil(
            (activeJob.jobEnd - activeJob.jobStart) / activeJob.interval
          )}{" "}
          intervals
        </Typography>
        <Typography variant="body2" sx={{ fontSize: ".8em", mb: 1 }}>
          {`${
            getModuleDate(Math.floor(activeJob.startDate)).split(",")[0]
          } ${getModuleTime(Math.floor(activeJob.startDate))} → ${
            getModuleDate(Math.floor(activeJob.endDate)).split(",")[0]
          } ${getModuleTime(Math.floor(activeJob.endDate))} with ${
            activeJob.interval
          } minute intervals, staffing ${activeJob.quantity} ${
            activeJob.quantity === "1" ? "person" : "people"
          } per shift`}
        </Typography>
        {[...dbUser.createdRoles, "No Group"].map((group, groupIndex) => (
          <Box
            sx={{
              bgcolor: colors.backgroundHighlight,
              borderRadius: 1,
              px: 2,
              py: 1,
              mb: 0.5,
            }}
          >
            <Typography vairant="body2">{group}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Slider
                value={
                  typeof weightValues[groupIndex] === "number"
                    ? weightValues[groupIndex]
                    : 0
                }
                onChange={(event, newValue) =>
                  setWeightValues((old) => {
                    let tempArray = [...old];
                    tempArray[groupIndex] = newValue;
                    return tempArray;
                  })
                }
                valueLabelDisplay="auto"
                step={0.1}
                max={10}
                min={0}
                sx={{
                  "& .MuiSlider-valueLabel": {
                    fontSize: "1em",
                    fontWeight: "normal",
                    top: -6,
                    backgroundColor: "unset",
                    color: colors.primaryText,
                    fontFamily: "Poppins",
                    "&:before": {
                      display: "none",
                    },
                    "& *": {
                      background: "transparent",
                      color: colors.primaryText,
                    },
                  },
                }}
              />
              <TextField
                value={weightValues[groupIndex]}
                size="small"
                variant="outlined"
                type="number"
                sx={{
                  bgcolor: colors.secondaryBackground,
                  borderRadius: 1,
                  ml: 2,
                }}
                onChange={(event) => {
                  const newValue = event.target.value;

                  setWeightValues((old) => {
                    let tempArray = [...old];
                    if (!newValue) {
                      tempArray[groupIndex] = "";
                    } else if (newValue === ".") {
                      tempArray[groupIndex] = newValue;
                    } else if (isNaN(parseFloat(newValue))) {
                      return old;
                    } else {
                      let roundedValue =
                        Math.round(parseFloat(newValue) * 10) / 10;
                      tempArray[groupIndex] = roundedValue;
                    }
                    return tempArray;
                  });
                }}
                onBlur={() => {
                  if (weightValues[groupIndex] < 0) {
                    setWeightValues((old) => {
                      let tempArray = [...old];
                      tempArray[groupIndex] = 0;
                      return tempArray;
                    });
                  }
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", mt: 3, width: "100%", gap: 1 }}>
        <Button
          sx={{
            bgcolor: colors.backgroundHighlight,
            ":hover": {
              bgcolor: `${colors.backgroundHighlight}D6`,
            },
          }}
          variant={"contained"}
          startIcon={<ArrowBackOutlined sx={{ color: colors.primaryText }} />}
          onClick={handleBackPress}
        >
          {activeJobIndex === 0 ? "Go Back" : "Previous Job"}
        </Button>
        <Button
          variant={
            activeJobIndex === jobs.length - 1 ? "contained" : "outlined"
          }
          sx={{ flex: 1 }}
          color="primary"
          endIcon={
            <ArrowForwardOutlined
              sx={{
                color:
                  activeJobIndex === jobs.length - 1
                    ? colors.primaryText
                    : colors.primaryColor,
              }}
            />
          }
          onClick={handleNextPress}
        >
          {activeJobIndex === jobs.length - 1 ? "Next" : "Next Job"}
        </Button>
      </Box>
    </Box>
  );
};

export default DefineJobWeights;
