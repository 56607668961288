import { Auth, DataStore } from "aws-amplify";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { Organization, SubscriptionPlan } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";
import { Helmet } from "react-helmet-async";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import { useState } from "react";
import axios from "axios";
import {
  DeleteForeverOutlined,
  MailOutlineOutlined,
  PhoneOutlined,
} from "@mui/icons-material";

const DeleteAccount = () => {
  const { dbUser, authUser } = useAuthContext();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(null);

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    try {
      const token = authUser?.signInUserSession.idToken.jwtToken;

      setSnackbarOpen({
        type: "error",
        message: "Deleting account",
      });

      const subscriptionPlan = await DataStore.query(SubscriptionPlan, (sp) =>
        sp.and((sp) => [sp.organizationID.eq(dbUser.id), sp.isActive.eq(true)])
      );
      if (subscriptionPlan.length) {
        const plan = subscriptionPlan[0];

        const res = await axios.delete(
          process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              type: "cancelSubscription",
              objectID: plan.stripeSubscriptionID,
              cancellationDetails: {
                comment: "Deleted Account",
                feedbackOptions: [],
              },
              test: process.env.NODE_ENV !== "production",
            },
          }
        );
      }
      const formData = new FormData(e.target);
      Auth.signOut();

      const res = await axios.delete(process.env.REACT_APP_DELETE_USER_ENDPOINT, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          _version: dbUser._version,
        },
      });

      setSnackbarOpen({
        type: "success",
        message: "Successfully deleted account",
      });

      navigate("/");
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error deleting account. Please try again later",
      });
    }
  };
  const pageTitle = `Delete Account • CLIQInvite`;
  const pageDescription = `Access the revolution in event management. Log in to CLIQInvite to streamline your social events, philanthropy, and recruitment. Secure invites, real-time tracking, and detailed analytics await.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/dashboard/delete`;
  return (
    <Box
      style={{
        backgroundColor: colors.defaultBackground,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WebPortalNavigation activeScreen="/profile" shouldNotCollapse={true} />
      <Snackbar open={!!snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Box
        component="form"
        className="unstyled-card subscription-slide-in"
        sx={{
          alignSelf: "center",
          mt: 4,
          maxWidth: "800px",
          pb: 2,
          px: { xs: 2, md: 2.5, lg: 3, xl: 3 },
        }}
        onSubmit={handleDeleteAccount}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xl: "3em", xs: "2.5em" },
            marginTop: "1.5rem",
            textAlign: "center",
            lineHeight: "115%",
            textAlign: "center",
          }}
        >
          Delete Account
        </Typography>
        <Typography
          variant="body2"
          sx={{ marginTop: 0, textAlign: "center", mb: 3 }}
        >
          Your account {dbUser?.name} will be permanently deleted. This cannot
          be undone.
        </Typography>
        <TextField
          id="code"
          label="code"
          name="code"
          variant="outlined"
          required
          InputLabelProps={{ style: { color: colors.primaryColor } }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: `${colors.primaryColor}20`,
            },
          }}
          sx={{ caretColor: "white", mb: "1em" }}
          fullWidth
        />
        <Box display="flex" gap={1} mt={2}>
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.backgroundHighlight,
              flex: 1,
            }}
            id={"forgot"}
            type="reset"
            onClick={() => {
              Auth.verifyCurrentUserAttribute("email").then(() => {
                setSnackbarOpen({
                  type: "success",
                  message: `Successfully sent a code to ${dbUser.email}`,
                });
              });
            }}
            startIcon={<MailOutlineOutlined sx={{ color: "white" }} />}
          >
            Resend Code
          </Button>
          <Button
            variant="contained"
            color="error"
            style={{ flex: 1 }}
            id={"reset"}
            type="submit"
            startIcon={<DeleteForeverOutlined sx={{ color: "white" }} />}
          >
            Delete Account
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteAccount;
