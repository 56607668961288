import { colors } from "../../theme/colors";
import { useEffect, useState } from "react";
import { DataStore, SortDirection } from "aws-amplify";
import {
  Contact,
  ContactList,
  Event,
  GuestInvite,
  Organization,
  User,
} from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";
import { Person, Settings } from "@mui/icons-material";
import { getContactListSheet } from "../../methods/downloadInsightsData";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { RingLoader } from "react-spinners";
import ExcelDropzone from "./ExcelDropzone";
import ManuallyAddContacts from "./ManuallyAddContacts";
import { useNavigate } from "react-router-dom";
import ShareModal from "./ShareModal";
import * as ExcelJS from "exceljs";
import { getTimeBetween } from "../../methods/getFormattedTime";

const ContactListModule = ({ list, setSnackbarOpen }) => {
  const navigate = useNavigate();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dropZoneOpen, setDropZoneOpen] = useState(false);
  const [addContactOpen, setAddContactOpen] = useState(false);
  const [listOwner, setListOwner] = useState("");
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [exporting, setExporting] = useState(false);

  const { dbUser } = useAuthContext();
  const status =
    dbUser.id === list.creatorID
      ? "OWNER"
      : list.coOwners.includes(dbUser.id)
      ? "COOWNER"
      : "EDITOR";

  useEffect(() => {
    const contactsSubscription = DataStore.observeQuery(
      Contact,
      (c) => c.contactListID.eq(list.id),
      { sort: (s) => s.updatedAt(SortDirection.DESCENDING) }
    ).subscribe((c) => {
      setSelectedContacts(c.items);
    });

    const fetchListOwner = async () => {
      let owner = await DataStore.query(User, list.creatorID);
      if (!owner) {
        owner = await DataStore.query(Organization, list.creatorID);
        if (!owner) {
          setListOwner("Unknown");
          return;
        }
      }
      setListOwner(owner);
    };

    if (dbUser.id === list.creatorID) {
      setListOwner(dbUser.name);
    } else {
      fetchListOwner();
    }

    return () => {
      contactsSubscription.unsubscribe();
    };
  }, [list.id]);

  const handleDuplicateList = async () => {
    const contactList = await DataStore.query(ContactList, list.id);
    const newList = await DataStore.save(
      new ContactList({
        name: `Copy of ${contactList.name}`,
        creatorID: dbUser.id,
        accessingUsers: [],
        coOwners: [],
      })
    );
    await Promise.all(
      selectedContacts.map(async (c) => {
        await DataStore.save(
          new Contact({
            name: c.name,
            number: c.number,
            notes: c.notes,
            contactListID: newList.id,
            avgScore: 0,
            creatorID: newList.creatorID,
            accessingUsers: [],
            coOwners: [],
          })
        );
      })
    );
  };

  const handleListItemClick = async (buttonName) => {
    const buttonIndex = listOptions.indexOf(buttonName) + 1;
    if (buttonIndex === 0) {
      //Cancel DO NOTHING
    } else if (buttonIndex === 1) {
      //Add Contacts
      setAddContactOpen(true);
    } else if (buttonIndex === 2) {
      //View Contacts
      navigate(`/lists/edit/${list.id}`, {
        contactList: list,
        contactListLength: selectedContacts.length,
      });
    } else if (buttonIndex === 3) {
      //Duplicate list
      handleDuplicateList();
    } //End of non OWNER and COOWNER options, we break if they aren't
    else if (status !== "OWNER" && status !== "COOWNER") {
      setDialogOpen(false);
    } else if (buttonIndex === 4) {
      setShareModalOpen(true);
    } else if (buttonIndex === 6) {
      setExporting(true);

      const wb = new ExcelJS.Workbook();

      let guestInvites = await DataStore.query(GuestInvite, (g) =>
        g.contactListID.eq(list.id)
      );

      const uniqueEventIDs = new Set(
        guestInvites.map((invite) => invite.eventID)
      );

      const uniqueExpiredEventIDs = (
        await Promise.all(
          Array.from(uniqueEventIDs).map(async (eventID) => {
            const event = await DataStore.query(Event, eventID);
            if (event.endDate < Date.now().toString()) return eventID;
          })
        )
      ).filter((eventID) => eventID !== undefined);

      getContactListSheet(
        wb,
        list.name,
        guestInvites,
        selectedContacts,
        uniqueExpiredEventIDs,
        dbUser.name
      );

      const buffer = await wb.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a download link
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Report.xlsx`;
      link.click();

      setExporting(false);
    } else if (buttonIndex === 5) {
      setDropZoneOpen(true);
    } else if (buttonIndex === 7) {
      setSnackbarOpen({
        type: "error",
        message: `Are you sure you want to delete list ${list.name} and all contacts associated with it?`,
        action: (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              setSnackbarOpen(null);
              DataStore.delete(ContactList, list.id);
            }}
          >
            Confirm
          </Button>
        ),
      });
    }

    setDialogOpen(false);
  };

  let listOptions = ["Add Contacts", "View Contacts", "Duplicate List"];

  if (status === "OWNER" || status === "COOWNER") {
    listOptions.push(
      ...["Share List", "Import Contacts", "Export List", "Delete List"]
    );
  }

  listOptions.push("Cancel");

  return (
    <Box
      style={{
        backgroundColor: colors.backgroundHighlight,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 6,
        marginBottom: ".3em",
        padding: ".25em 1em",
        flex: 1,
        cursor: "pointer",
      }}
      sx={{
        ":hover": {
          opacity: 0.6,
        },
      }}
      onClick={() => handleListItemClick("View Contacts")}
    >
      <ExcelDropzone
        open={dropZoneOpen}
        close={(e) => {
          e.stopPropagation();
          setDropZoneOpen(false);
        }}
        selectedContacts={selectedContacts}
        list={list}
      />
      <ManuallyAddContacts
        open={addContactOpen}
        close={(e) => {
          e.stopPropagation();
          setAddContactOpen(false);
        }}
        list={list}
        contacts={selectedContacts}
      />
      <ShareModal
        setSnackbarOpen={setSnackbarOpen}
        shareModalOpen={shareModalOpen}
        close={(e) => {
          if (e) {
            e.stopPropagation();
          }
          setShareModalOpen(false);
        }}
        listID={list.id}
        listName={list.name}
      />

      <div>
        <h1 style={{ fontSize: "1.1em", color: colors.primaryText, margin: 0 }}>
          {list?.name}
        </h1>
        <p
          style={{
            fontSize: ".8em",
            color: colors.secondaryText,
            margin: 0,
            fontWeight: 500,
          }}
        >
          <b style={{ color: colors.secondaryColor, fontWeight: 600 }}>
            {selectedContacts.length} contacts
          </b>{" "}
          • Last updated{" "}
          {getTimeBetween(Math.floor(new Date(list?.updatedAt))).toLowerCase()}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {!exporting ? (
          <span>
            <Dialog
              onClose={(e) => {
                e.stopPropagation();
                setDialogOpen(false);
              }}
              open={dialogOpen}
            >
              <DialogTitle>{list.name}</DialogTitle>
              <List sx={{ pt: 0 }} dense>
                {listOptions.map((button, index) => (
                  <ListItem
                    key={index}
                    disableGutters
                    sx={{
                      margin: 0,
                      padding: 0,
                      mt: button === "Cancel" ? 1 : 0,
                    }}
                  >
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleListItemClick(button);
                      }}
                      key={button}
                      sx={{
                        px: 3,
                        ":hover": {
                          backgroundColor: "#030303",
                        },
                      }}
                    >
                      <ListItemText>
                        <p
                          style={{
                            margin: 0,
                            color:
                              button === "Delete List"
                                ? colors.errorColor
                                : colors.primaryText,
                            fontWeight: button === "Cancel" ? 500 : 400,
                          }}
                        >
                          {button}
                        </p>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Dialog>
            {list.creatorID !== dbUser.id ? (
              <IconButton onClick={(e) => e.stopPropagation()} sx={{ mr: -1 }}>
                <Tooltip
                  title={`Owner: ${listOwner.name}. Status: ${
                    status === "COOWNER" ? "Co-Owner" : "Editor"
                  }`}
                >
                  <Person sx={{ fontSize: "1em" }} color="primary" />
                </Tooltip>
              </IconButton>
            ) : null}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setDialogOpen(true);
              }}
            >
              <Settings sx={{ fontSize: "1em", color: colors.secondaryText }} />
            </IconButton>
          </span>
        ) : (
          <RingLoader size={"1.5em"} color={colors.primaryColor} />
        )}
      </div>
    </Box>
  );
};

export default ContactListModule;
