import { Badge, Box, Chip, Grid, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import { getModuleDate } from "../../methods/getFormattedTime";

const PlanModule = ({ plan }) => {
  const renewDifference =
    plan.billingCycle === "month" ? 2629800000 : 31557600000;
  return (
    <Box
      sx={{
        bgcolor: colors.backgroundHighlight,
        px: 1.2,
        py: 1,
        borderRadius: 2,
        position: "relative",
      }}
    >
      <Chip
        label={plan.isActive ? "Active" : "Inactive"}
        color={plan.isActive ? "primary" : "error"}
        sx={{ fontSize: "1em", position: "absolute", right: "1em" }}
        size="small"
      ></Chip>
      <Typography variant="h3" sx={{ mt: ".1em", mb: ".5em" }}>
        CLIQInvite {plan.planType} Plan
      </Typography>
      <Box display="flex" alignItems={"flex-end"} gap=".3em" mb={1}>
        <Typography variant="body1" sx={{ fontSize: "2.5em" }}>
          ${plan.cost / 100}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: "1em", marginBottom: ".5em" }}
        >
          /{plan.billingCycle}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ fontSize: ".8em" }}>
        Started on {getModuleDate(Math.floor(new Date(plan.createdAt)))}
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontSize: ".8em", marginBottom: ".6em" }}
      >
        Renews on {getModuleDate(plan.dateRenewalRequired)}
      </Typography>
    </Box>
  );
};

export default PlanModule;
