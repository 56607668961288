import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { MoreHoriz } from "@mui/icons-material";
import { useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import axios from "axios";

const NonDefaultPaymentMethodModule = ({
  paymentMethod,
  brandImage,
  setSnackbarOpen,
  plan,
  setNonDefaultPaymentMethods,
  setDefaultPaymentMethod,
  defaultPaymentMethodID,
}) => {
  const { authUser } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMakeDefault = async () => {
    const token = authUser?.signInUserSession.idToken.jwtToken;

    try {
      setSnackbarOpen({
        type: "info",
        message: "Updating default payment method",
      });
      const res = await axios.put(
        process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
        {
          type: "updateDefaultMethod",
          paymentMethodID: paymentMethod.id,
          subscriptionID: plan.stripeSubscriptionID,
          test: process.env.NODE_ENV !== "production",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDefaultPaymentMethod(res.data.newDefaultPaymentMethod);
      setNonDefaultPaymentMethods(res.data.newNonDefaultPaymentMethods);

      setSnackbarOpen({
        type: "success",
        message: "Successfully updated default payment method",
      });
    } catch (err) {
      console.log(err);
      setSnackbarOpen({
        type: "error",
        message:
          "Failed to make payment method default. Please try again later.",
      });
    }

    handleClose();
  };

  const handleDeletePaymentMethod = async () => {
    const token = authUser?.signInUserSession.idToken.jwtToken;

    try {
      setSnackbarOpen({ type: "info", message: "Deleting payment method" });
      const res = await axios.delete(
        process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            type: "paymentMethod",
            test: process.env.NODE_ENV !== "production",
            objectID: paymentMethod.id,
          },
        }
      );

      if (res.status === 200) {
        setSnackbarOpen({
          type: "success",
          message: "Successfully deleted payment method.",
        });
        setNonDefaultPaymentMethods(
          res.data.newPaymentMethods.filter(
            (newPaymentMethod) => newPaymentMethod.id !== defaultPaymentMethodID
          )
        );
      } else throw new Error();
      handleClose();
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error deleting payment method. Please try again.",
      });
      console.error(err);
    }
  };

  const handleRemove = () => {
    setSnackbarOpen({
      type: "error",
      message: "Are you sure you want to delete this payment method?",
      action: (
        <Button
          size="small"
          color="inherit"
          onClick={handleDeletePaymentMethod}
        >
          Delete
        </Button>
      ),
    });
  };

  return (
    <Box
      sx={{
        bgcolor: colors.backgroundHighlight,
        borderRadius: 2,
        py: 1,
        mb: 0.5,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            px: 2,
          }}
        >
          <img
            src={brandImage || require("../../assets/CLIQLogoTransparent.png")}
            style={{
              backgroundColor:
                paymentMethod.brand === "discover" ? "silver" : "transparent",
              borderRadius: 5,
              height: "1em",
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ textAlign: "center", fontSize: "1em", fontWeight: 500 }}
          >
            {paymentMethod.lastFourDigits}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ textAlign: "center", fontSize: "1em", margin: 0 }}
          >
            {paymentMethod.expiry}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton onClick={handleClick} size="small">
            <MoreHoriz sx={{ color: colors.secondaryText }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleMakeDefault}>Make Default</MenuItem>
            <MenuItem onClick={handleRemove}>Remove</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NonDefaultPaymentMethodModule;
