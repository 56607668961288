import { Box, Card, Grid, Typography } from "@mui/material";
import PublicHeader from "../public/navigation/PublicHeader";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      className="page-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "100dvh",
      }}
    >
      <PublicHeader />
      <Box
        className="page-size-medium public-container"
        sx={{ pt: { xs: "9em", lg: "7em" } }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xl={5}
            lg={6}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", lg: "flex-start" },
            }}
          >
            <Typography variant="h1" style={{ fontSize: "10em" }}>
              404
            </Typography>
          </Grid>
          <Grid
            item
            xl={7}
            lg={6}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", lg: "flex-start" },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: "4.5em",
                lineHeight: "110%",
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              We couldn't find this page.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", lg: "flex-start" },
              mt: { xs: 6, lg: 8 },
              mb: 8,
            }}
          >
            <Typography variant="h3" sx={{ fontSize: "2em" }}>
              Looking for...
            </Typography>
            <Grid container sx={{ mt: 3 }} spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Box
                  className="unstyled-card"
                  sx={{
                    boxShadow: "none",
                    cursor: "pointer",
                    ":hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0px 5px 5px rgba(255, 255, 255, 0.05)",
                    },
                    transition: "all 500ms ease-in-out",
                  }}
                >
                  <Box sx={{ height: "20vh", overflow: "hidden" }}>
                    <img
                      src={require("../assets/Web_Portal_CRM.png")}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "all 500ms ease-in-out",
                      }}
                      className="unstyled-card-img"
                      onClick={() => navigate("/login")}
                    ></img>
                  </Box>
                  <Box sx={{ pb: 2, pt: 1, px: 2 }}>
                    <Typography
                      variant="h3"
                      sx={{ textAlign: "center", fontSize: "1.3em", mb: 1 }}
                    >
                      Web Portal
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        fontSize: ".8em",
                      }}
                    >
                      Manage your organization's account and maximize your
                      CLIQInvite toolset
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box
                  className="unstyled-card"
                  sx={{
                    boxShadow: "none",
                    cursor: "pointer",
                    ":hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0px 5px 5px rgba(255, 255, 255, 0.05)",
                    },
                    transition: "all 500ms ease-in-out",
                  }}
                  onClick={() => navigate("/")}
                >
                  <Box sx={{ height: "20vh", overflow: "hidden" }}>
                    <img
                      src={require("../assets/Philanthropy_Flow.png")}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "all 500ms ease-in-out",
                      }}
                      className="unstyled-card-img"
                    ></img>
                  </Box>

                  <Box sx={{ pb: 2, pt: 1, px: 2 }}>
                    <Typography
                      variant="h3"
                      sx={{ textAlign: "center", fontSize: "1.3em", mb: 1 }}
                    >
                      Home Page
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        fontSize: ".8em",
                      }}
                    >
                      Learn all about our company, our products, and our
                      capabilities
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box
                  className="unstyled-card"
                  sx={{
                    boxShadow: "none",
                    cursor: "pointer",
                    ":hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0px 5px 5px rgba(255, 255, 255, 0.05)",
                    },
                    transition: "all 500ms ease-in-out",
                  }}
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/cliqinvite/id1664767480",
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  <Box sx={{ height: "20vh", overflow: "hidden" }}>
                    <img
                      src={require("../assets/OG_Image.png")}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "all 500ms ease-in-out",
                      }}
                      className="unstyled-card-img"
                    ></img>
                  </Box>
                  <Box sx={{ pb: 2, pt: 1, px: 2 }}>
                    <Typography
                      variant="h3"
                      sx={{ textAlign: "center", fontSize: "1.3em", mb: 1 }}
                    >
                      App Store
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        fontSize: ".8em",
                      }}
                    >
                      View our page on the App Store. On android?{" "}
                      <b
                        className="link"
                        style={{ margin: 0 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            "https://play.google.com/store/apps/details?id=com.skdraughn.CLIQ&pli=1",
                            "_blank",
                            "noreferrer"
                          );
                        }}
                      >
                        View here.
                      </b>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <style jsx>{`
              .unstyled-card:hover .unstyled-card-img {
                transform: scale(1.1);
              }
            `}</style>{" "}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default PageNotFound;
