import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Grid,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { ArrowDropDownOutlined } from "@mui/icons-material";

const GraphSettingsDialog = ({
  open,
  setOpen,
  selectedFilters,
  setSelectedFilters,
}) => {
  const [timeFrameSelectOpen, setTimeFrameSelectOpen] = useState(false);

  const timeFrameOptions = ["past week", "past month", "past year", "all time"];
  const eventTypes = ["Standard", "Paid", "ID-Verified"];

  const handleCheckboxChange = (event) => {
    let isNowChecked = event.target.checked;
    setSelectedFilters((old) => {
      let oldTypeFilters = selectedFilters.typeFilters;
      isNowChecked
        ? oldTypeFilters.add(event.target.name)
        : oldTypeFilters.delete(event.target.name);
      return { ...old, typeFilters: oldTypeFilters };
    });
  };

  const timeFrame = selectedFilters.timeFrame;
  const typeFilters = selectedFilters.typeFilters;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Box
        onClick={() => {
          if (timeFrameSelectOpen) {
            setTimeFrameSelectOpen(false);
          }
        }}
      >
        <DialogTitle id="form-dialog-title">Customize Graph</DialogTitle>
        <DialogContent>
          <h3
            style={{
              color: colors.secondaryText,
              margin: 0,
              fontWeight: 500,
              fontSize: "1em",
            }}
          >
            Time Range
          </h3>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <p style={{ margin: 0 }}>
              All events {timeFrame === "all time" ? "of" : "in the"}
            </p>
            <Select
              open={timeFrameSelectOpen}
              onClick={() => setTimeFrameSelectOpen(true)}
              value={timeFrame}
              onChange={(event) => {
                setSelectedFilters((old) => {
                  return { ...old, timeFrame: event.target.value };
                });
              }}
              IconComponent={() => (
                <ArrowDropDownOutlined
                  sx={{
                    color: colors.secondaryText,
                    cursor: "pointer",
                    ":hover": {
                      opacity: 0.7,
                    },
                  }}
                  onClick={() => setTimeFrameSelectOpen(true)}
                />
              )}
              SelectDisplayProps={{
                style: {
                  padding: 0,
                  paddingLeft: ".5em",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: { backgroundColor: colors.secondaryBackground },
                },
              }}
              sx={{ ml: 1 }}
            >
              {timeFrameOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <h3
            style={{
              color: colors.secondaryText,
              margin: 0,
              fontWeight: 500,
              fontSize: "1em",
              marginTop: "1em",
            }}
          >
            Event Type
          </h3>
          <Grid container spacing={0}>
            {eventTypes.map((option, index) => (
              <Grid item xs={4} key={index}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        onChange={handleCheckboxChange}
                        checked={typeFilters.has(option)}
                        name={option}
                      />
                    }
                    sx={{ color: colors.secondaryText, fontFamily: "Poppins" }}
                    label={option}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default GraphSettingsDialog;
