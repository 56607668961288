import { Box, Grid } from "@mui/material";
import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import {
  Event,
  GuestInvite,
  Invite,
  Organization,
  OrganizationMember,
  Scan,
  User,
} from "../../models";
import PublicHeader from "../../public/navigation/PublicHeader";
import { ClipLoader } from "react-spinners";
import { calculateFees } from "../../methods/calculateTakeHome";

import { calculateEventData } from "../../methods/calculateEventData";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import GuestListHeader from "./GuestListHeader";
import GuestListGuestDisplay from "./GuestListGuestDisplay";
import GuestListFilterDisplay from "./GuestListFilterDisplay";
import { sum } from "../../methods/sum";
import { Helmet } from "react-helmet-async";

const GuestList = () => {
  const { eventID } = useParams();
  const { dbUser } = useAuthContext();
  function hash(input) {
    let hash = 0,
      i,
      chr;
    if (input.length === 0) return hash;
    for (i = 0; i < input.length; i++) {
      chr = input.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  function getColor(id) {
    if (!id || id === "owner" || dbUser.id === id) return null;
    const index = Math.abs(hash(id)) % 300; // adjust modulo as needed
    const hue = (index + 27) % 360; // start from 270 to match the purple color, adjust as needed

    // Avoid clashing with gold color by skipping 40-60 degrees
    if (hue >= 40 && hue <= 60) {
      hue = (hue + 20) % 360;
    }

    const saturation = 70;
    const lightness = 40;

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }
  const [guests, setGuests] = useState([]);
  const [orgMemberInvites, setOrgMemberInvites] = useState([]);
  const [inviteCount, setInviteCount] = useState([]);
  const [isPostEvent, setIsPostEvent] = useState(false); //revert back
  const [guestInviteCount, setGuestInviteCount] = useState([]);
  const [invitesAllocatedCount, setInvitesAllocatedCount] = useState(0); //ONLY for guest orgs that are given invites that aren't membersOnly
  const [timer, setTimer] = useState();
  const [event, setEvent] = useState(null);

  const [sortConfig, setSortConfig] = useState({
    order: "ASCENDING",
    option: "Alphabetical",
  });
  const [selectedFilters, setSelectedFilters] = useState([
    { type: "Member", filter: [] },
    { type: "Category", filter: [] },
    { type: "Organization", filter: [] },
  ]);
  const [organizationMembers, setOrganizationMembers] = useState(null);
  const [categoriesWithStatistics, setCategoriesWithStatistics] = useState([]);

  const [displayedGuests, setDisplayedGuests] = useState([]); //where all the magic happens

  useEffect(() => {
    //fetch our organization members for finding names and searching
    if (dbUser) {
      (async () => {
        const organizationMembers = await DataStore.query(
          OrganizationMember,
          (m) =>
            m.and((m) => [
              m.organizationID.eq(dbUser.id),
              m.isConfirmed.eq(true),
            ])
        );
        setOrganizationMembers(organizationMembers);
      })();
    }
  }, [dbUser]);

  useEffect(() => {
    //listen to changes in selectedFilters and sort/filter

    if (guests && guestInviteCount && orgMemberInvites) {
      filterAndSortGuestList();
    }
  }, [selectedFilters, guests, orgMemberInvites, guestInviteCount, sortConfig]);

  async function filterAndSortGuestList() {
    let newFilteredAndSortedGuests = [
      ...orgMemberInvites,
      ...guests,
      ...guestInviteCount,
    ];
    //filter before sorting
    selectedFilters.forEach((filter) => {
      //apply member filters
      if (filter.type === "Member") {
        if (filter.filter.length) {
          //if member filters are currently applied
          newFilteredAndSortedGuests = newFilteredAndSortedGuests.filter(
            (guest) =>
              guest.id
                ? filter.filter.includes(guest.id)
                : guest.orgID.includes(guest.id)
          );
        }
      } else if (filter.type === "Category") {
        if (filter.filter.length) {
          //if category filters are currently applied
          newFilteredAndSortedGuests = newFilteredAndSortedGuests.filter(
            (guest) => filter.filter.includes(guest.category)
          );
        }
      } else if (filter.type === "Organization") {
        if (filter.filter.length) {
          //if category filters are currently applied
          newFilteredAndSortedGuests = newFilteredAndSortedGuests.filter(
            (guest) => filter.filter.includes(guest.guestOrgID)
          );
        }
      }
    });
    //sort after filtering
    newFilteredAndSortedGuests.sort((a, b) => {
      if (sortConfig.option === "Alphabetical") {
        if (sortConfig.order === "ASCENDING") {
          return (a.member || "").localeCompare(b.member || "");
        } else return (b.member || "").localeCompare(a.member || "");
      } else if (sortConfig.option === "Invites Given") {
        if (sortConfig.order === "ASCENDING") {
          return a.count - b.count;
        } else return b.count - a.count;
      } else if (sortConfig.option === "Revenue") {
        if (sortConfig.order === "ASCENDING") {
          return (a.amount || -1) - (b.amount || -1);
        } else return (b.amount || -1) - (a.amount || -1);
      } else if (sortConfig.option === "Valid Scans") {
        if (sortConfig.order === "ASCENDING") {
          return (a.used || -1) - (b.used || -1);
        } else return (b.used || -1) - (a.used || -1);
      } else if (sortConfig.option === "Invalid Scans") {
        if (sortConfig.order === "ASCENDING") {
          return (a.numInvalid || -1) - (b.numInvalid || -1);
        } else return (b.numInvalid || -1) - (a.numInvalid || -1);
      } else {
        return b.count - a.count;
      }
    });

    setDisplayedGuests(newFilteredAndSortedGuests);
  }

  const fetchUserNames = async (ids) => {
    const users = await Promise.all(
      Array.from(ids).map(async (id) => {
        if (
          organizationMembers &&
          organizationMembers.find((m) => m.userID === id)
        ) {
          return {
            id: id,
            name: organizationMembers.find((m) => m.userID === id)
              .memberInfo[0],
          };
        }
        const user = await DataStore.query(User, id);
        if (!user) {
          const org = await DataStore.query(Organization, id);
          if (!org) {
            const orgMember = await DataStore.query(OrganizationMember, (m) =>
              m.userID.eq(id)
            );
            if (orgMember.length) {
              return {
                ...orgMember[0],
                id: id,
                name: orgMember[0].memberInfo[0],
              };
            } else return { id: id, name: "Unknown Member" };
          }
          return org || {};
        }
        return user;
      })
    );
    const userMap = new Map();
    users.forEach((user) => userMap.set(user.id, user.name));
    return userMap;
  };

  const updateExistingMembers = (existingMembers, item, memberName) => {
    const { orgMemberID, category, maxUses, cost } = item;
    const key = `${orgMemberID}/${category}`;

    const existingMember = existingMembers.get(key) || {
      count: 0,
      amount: 0,
      member: memberName,
      category,
      used: 0,
      numInvalid: 0,
      id: orgMemberID,
      guestOrgID: item.guestOrgID,
      isGuestOrg: !!item.guestOrgID,
      color: getColor(item.guestOrgID),
      invitesLength: 0,
      invites: [], // new property to hold individual invites
      numFlagged: 0,
      numRejected: 0,
    };

    existingMember.count += maxUses;
    existingMember.amount += calculateFees(cost);
    existingMember.used += parseInt(item.numUsed);
    existingMember.numInvalid += item.numInvalid;
    existingMember.invitesLength += 1;
    existingMember.invites.push(item); // push the current invite to the array
    existingMember.numFlagged += item.photoStatus === "FLAGGED" ? 1 : 0;
    existingMember.numRejected += item.photoStatus === "REJECTED" ? 1 : 0;
    existingMembers.set(key, existingMember);
  };

  const processGuestInvites = async (result) => {
    let tempGuests = [];
    let existingMembers = new Map();

    // if (isGuestOrg) {
    //   const userIds = new Set(
    //     result.items.filter((r) => r.isGuestOrg).map((item) => item.orgMemberID)
    //   );
    //   const userNames = await fetchUserNames(userIds);

    //   for (let i = 0; i < result.items.length; i++) {
    //     if (result.items[i].orgMemberID) {
    //       let memberName =
    //         result.items[i].orgMemberID === dbUser.id
    //           ? "You"
    //           : userNames.get(result.items[i].orgMemberID);

    //       updateExistingMembers(existingMembers, result.items[i], memberName);
    //     }
    //   }
    //   tempGuests = [...existingMembers.values()];
    //   tempGuests.sort((a, b) => a.member.localeCompare(b.member));
    //   guestInviteCount = tempGuests;

    //   const invites = await DataStore.query(Invite, (i) =>
    //     i.and((i) => [i.eventID.eq(eventId), i.guestOrgID.eq(dbUser.id)])
    //   );

    //   let decrementOrgMembers = invites.map((invite) => {
    //     return invite.userId;
    //   });

    //   let tempEditableGuests = result.items;
    //   const tempFinalLimits = [];

    //   tempInitialLimits.forEach((limit) => {
    //     tempFinalLimits.push({
    //       category: limit.category,
    //       limit:
    //         limit.limit !== "-1"
    //           ? limit.limit -
    //             sum(
    //               tempEditableGuests.filter(
    //                 (guest) =>
    //                   guest.category === limit.category &&
    //                   decrementOrgMembers.includes(guest.orgMemberID)
    //               ),
    //               "maxUses"
    //             )
    //           : "-1",
    //     });
    //   });

    //   limits = tempFinalLimits;
    //   return { limits, guestInviteCount };
    // } else {
    const userIds = new Set(result.items.map((item) => item.orgMemberID));
    const categories = new Set(result.items.map((item) => item.category));

    const tempCategoriesWithStatistics = Array.from(categories).map(
      (category) => {
        const filteredCategoryGuestInvites = result.items.filter(
          (guestInvite) => guestInvite.category === category
        );
        const { numInvites, used, numInvalid } =
          filteredCategoryGuestInvites.reduce(
            (acc, curr) => {
              acc.numInvites += curr.maxUses;
              acc.used += curr.numUsed;
              acc.numInvalid += curr.numInvalid;
              return acc;
            },
            {
              numInvites: 0,
              used: 0,
              numInvalid: 0,
            }
          );

        return { category, numInvites, used, numInvalid };
      }
    );
    const userNames = await fetchUserNames(userIds);

    for (let i = 0; i < result.items.length; i++) {
      if (result.items[i].orgMemberID) {
        let memberName =
          result.items[i].orgMemberID === dbUser.id
            ? "You"
            : userNames.get(result.items[i].orgMemberID);
        updateExistingMembers(existingMembers, result.items[i], memberName);
      }
    }
    tempGuests = [...existingMembers.values()];
    tempGuests.sort((a, b) => a.member.localeCompare(b.member));
    setCategoriesWithStatistics(tempCategoriesWithStatistics);
    setGuestInviteCount(tempGuests);
    // }
  };

  const processInvites = async (result) => {
    let tempGuests = [];
    let tempInviteCount = 0;
    let tempOrgMemberCount = new Map();
    let tempInvitesAllocatedCount = 0; //only guest orgs that are not membersOnly

    let isGuestOrg = "NOT YET IMPLEMENTED";
    //reduce result to only invites that were sent by org members of viewing org
    if (isGuestOrg !== "NOT YET IMPLEMENTED") {
      result.items.map((invite) => {
        if (invite.guestOrgID === dbUser.id) {
          tempInvitesAllocatedCount += invite.invitesGiven;

          tempInviteCount++;
          if (invite.response)
            tempOrgMemberCount.has(dbUser.id)
              ? tempOrgMemberCount.set(
                  dbUser.id,
                  tempOrgMemberCount.get(dbUser.id) + 1
                )
              : tempOrgMemberCount.set(dbUser.id, 1);
        }
      });
    } else {
      const tempEventScans = await DataStore.query(Scan, (s) =>
        s.eventID.eq(eventID)
      );
      for (let i = 0; i < result.items.length; i++) {
        //either invite is public guest list or org is viewing or its sent by you
        tempInviteCount++;

        if (result.items[i].response && result.items[i].isOrg) {
          //get the recipient of invite, put in tempGuests with origin member name
          const guestOrgMemberInvites = result.items.filter(
            (guestInvite) =>
              guestInvite.guestOrgID === result.items[i].userId &&
              guestInvite.response
          );
          const guestOrgMemberInvitesWithStatistics = guestOrgMemberInvites.map(
            (guestOrgMemberInvite) => {
              const relevantScans = tempEventScans.filter(
                (eventScan) =>
                  eventScan.guestInviteID === guestOrgMemberInvite.id
              );
              return {
                ...guestOrgMemberInvite,
                invalidScans: relevantScans.filter((s) => !s.isValid).length,
                validScans: relevantScans.filter((s) => s.isValid).length,
              };
            }
          );

          const receivingOrganization = await DataStore.query(
            Organization,
            result.items[i].userId
          );
          tempGuests.push({
            ...receivingOrganization,
            originMember: "You",
            color: getColor(result.items[i].userId),
            category: receivingOrganization.name,
            count: guestOrgMemberInvitesWithStatistics.length,
            used: sum(guestOrgMemberInvitesWithStatistics, "validScans"),
            numInvalid: sum(
              guestOrgMemberInvitesWithStatistics,
              "invalidScans"
            ),
            membersOnly: result.items[i].membersOnly,
            isOrganizationMembers: true,
          });
        } else if (result.items[i].response && !result.items[i].guestOrgID) {
          const organizationID =
            result.items[i].guestOrgID || result.items[i].organizationID;
          const allScans = tempEventScans.filter(
            (s) => s.guestInviteID === result.items[i].id
          );
          tempOrgMemberCount.has(organizationID)
            ? tempOrgMemberCount.set(organizationID, {
                count: tempOrgMemberCount.get(organizationID).count + 1,
                valid:
                  tempOrgMemberCount.get(organizationID).valid +
                  allScans.filter((s) => s.isValid).length,
                invalid:
                  tempOrgMemberCount.get(organizationID).invalid +
                  allScans.filter((s) => !s.isValid).length,
              })
            : tempOrgMemberCount.set(organizationID, {
                count: 1,
                valid: allScans.filter((s) => s.isValid).length,
                invalid: allScans.filter((s) => !s.isValid).length,
              });
        }
      }
    }
    setInvitesAllocatedCount(tempInvitesAllocatedCount);
    setGuests(tempGuests);
    if (dbUser.createdRoles) {
      const tempOrgMemberInvites = [];
      for (let [key, value] of tempOrgMemberCount.entries()) {
        tempOrgMemberInvites.push({
          orgID: key,
          count: value.count,
          used: value.valid,
          numInvalid: value.invalid,
          isOrgMembers: true,
          category: "Organization Members",
          noUpload: true,
          color: key === dbUser.id ? null : getColor(key),
          isOrganizationMembers: true,
        });
      }
      setOrgMemberInvites(tempOrgMemberInvites);
    }
    setInviteCount(tempInviteCount);
  };

  useEffect(() => {
    if (dbUser && organizationMembers) {
      let tempInitialLimits = [];
      DataStore.query(Event, eventID).then(async (event) => {
        if (!event.analytics && event.endDate <= Date.now().toString()) {
          const newEvent = await calculateEventData(event);
          setEvent(newEvent);
        } else {
          setEvent(event);
        }
        if (event.endDate <= Date.now().toString()) setIsPostEvent(true);
        event.categories.forEach((category) => {
          category.limits.forEach((limit) => {
            if (limit.role === dbUser.id) {
              //if the limit corresponds with the role
              tempInitialLimits.push({
                category: category.category,
                limit: limit.limit,
              }); //initialize based on the already existing limits
            }
          });
        });
      });

      const subscription = DataStore.observeQuery(
        Invite,
        (i) => i.eventID.eq(eventID),

        { sort: (i) => i.userId(SortDirection.ASCENDING) } //todo when sending org 2 org set orgMemberID
      ).subscribe(async (result) => {
        await processInvites(result);
      });

      //get guest invites
      const guestSubscription = DataStore.observeQuery(GuestInvite, (g) =>
        g.and((g) => [g.eventID.eq(eventID)])
      ).subscribe(async (result) => {
        processGuestInvites(result);
      });

      return () => {
        subscription.unsubscribe();
        guestSubscription.unsubscribe();
        clearInterval(timer);
      };
    }
  }, [dbUser, eventID, timer, organizationMembers]);

  const loading = !event || !guests || !guestInviteCount || !orgMemberInvites;

  const pageTitle = `${event ? `${event.name} ` : ""}Guest List • CLIQInvite`;
  const pageDescription = `Revolutionize event management with CLIQInvite. Streamline social events, philanthropy, and recruitment. Secure invites, real-time tracking, and detailed analytics. Elevate your event experience today.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com`;
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <WebPortalNavigation activeScreen={"/dashboard"} />
      <Box
        className="page-size-extra-large"
        sx={{
          py: 4,
          alignSelf: "center",
        }}
      >
        <GuestListHeader
          loading={loading}
          event={event}
          isPostEvent={isPostEvent}
          guestInviteCount={guestInviteCount}
          orgMemberInvites={orgMemberInvites}
          inviteCount={inviteCount}
        />
        <Grid container sx={{ mt: 2 }} columnSpacing={2}>
          <Grid item xs={7.5}>
            <GuestListGuestDisplay
              displayedGuests={displayedGuests}
              event={event}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4.5}>
            <GuestListFilterDisplay
              loading={loading}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              organizationMembers={organizationMembers}
              categoriesWithStatistics={categoriesWithStatistics}
              organizationsWithStatistics={guests || []}
              guestInviteCount={guestInviteCount}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              isPaid={!!event?.ticketInformation}
              isPostEvent={!!event?.analytics}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GuestList;

// useEffect(() => {
//   (async () => {
//     for (let i = 0; i < 20; i++) {
//       const randomNumber = Math.random();
//       const maxUses = Math.floor(Math.random() * 3 + 1);
//       const isPaid = randomNumber > 0.1 ? "Paid" : "Free";
//       const orgMemberIDs = [
//         "7404c6bc-2425-481e-8fc8-ce6e3b0bbddd",
//         "f26c89ec-3999-4b91-b1c1-f22df72b4671",
//         "42a76207-f547-49c1-a797-fb1fd2969c6c",
//         "bbe281bf-f8a7-4579-a189-073a6d9fe957",
//         "da6ba875-af60-469f-967c-4d1b4af906dc",
//       ];

//       await DataStore.save(
//         new GuestInvite({
//           eventID,
//           guestOrgID: "c4089498-5071-703e-0730-3c215abdc455",
//           orgMemberID:
//             orgMemberIDs[
//               Math.min(
//                 orgMemberIDs.length,
//                 Math.floor(Math.random() * orgMemberIDs.length)
//               )
//             ],
//           qrCode: "THISISATESTDATASTOREENTRY",
//           maxUses,
//           category: isPaid,
//           numInvalid: Math.floor(Math.random() + 0.2),
//           numUsed: Math.min(maxUses, Math.floor(Math.random() * 4)),
//           label: "RANDOMDUDENAME",
//           cost: isPaid === "Paid" ? maxUses * 100 : 0,
//           paymentIntentID: isPaid === "Paid" ? "RANDOMPAYMENTID" : null,
//         })
//       );
//     }
//   })();
// }, []);
