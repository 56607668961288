import { Box, Typography } from "@mui/material";
import { shortenNumberForModule } from "../../../methods/formatNumbers";
import "./EventSummaryModule.css";

const EventSummaryModule = ({ num, description, icon }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      {icon}
      <Typography variant="body1" sx={{ fontSize: ".8em" }}>
        {!isNaN(num) ? shortenNumberForModule(num) : num} {description}
      </Typography>
    </Box>
  );
};

export default EventSummaryModule;
