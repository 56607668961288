import { Box, Grid, Typography } from "@mui/material";
import "./HeroSection.css";
import ReactPlayer from "react-player";
import { colors } from "../../theme/colors";

const HeroSection = ({ url }) => {
  return (
    <Box
      sx={{
        background: "radial-gradient(ellipse at 0% 0%, #a020f030, black 80%)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "6.5rem",
        paddingBottom: "5rem",
      }}
    >
      <Grid
        container
        className="default-page-size"
        sx={{
          position: "relative",
        }}
      >
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            pt: { md: 5 },
            pb: { xs: 6, md: 5 },
            display: "flex",
            flexDirection: "column",
            pr: { md: 5 },
          }}
        >
          <Box
            sx={{
              width: {
                md: "100%",
                sm: "100%",
                xs: "90%",
              },
              height: "100%",
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                lineHeight: 1.0625,
                letterSpacing: "-.03rem",
                textAlign: { xs: "center", md: "left" },
                fontSize: {
                  xs: "2.75rem",
                  sm: "5.4vw",
                  md: "4.6vw",
                  lg: "4rem",
                  xl: "4rem",
                },
                mb: { xs: 1, md: 0 },
                maxWidth: { sm: "90%" },
                fontWeight: 700,
                alignSelf: { xs: "center", md: "flex-start" },
              }}
            >
              For Greek Life.{" "}
              <span style={{ color: colors.primaryColor }}>By Greek Life</span>
            </Typography>

            <Typography
              variant="body1"
              sx={{
                lineHeight: "130%",
                letterSpacing: "-.03rem",
                textAlign: { xs: "center", md: "left" },
                fontSize: {
                  xs: "1.15rem",
                  sm: "2.5vw",
                  md: "1.4vw",
                  lg: "1.25rem",
                  xl: "1.25rem",
                },
                maxWidth: { lg: "35rem", sm: "45rem" },
                mt: { md: 1.4 },
                mb: { xs: 5, md: "auto" },
                alignSelf: { xs: "center", md: "flex-start" },
                fontWeight: 500,
                lineHeight: 1.33,
                mb: 4,
              }}
            >
              Automate your social, rush, and philanthropy workflows. All
              without a single guest needing to download an app.
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: { xs: "center", md: "space-between" },
                alignItems: "center",
                fontSize: { xs: ".8rem", md: "1rem" },
              }}
            >
              <button
                style={{ fontFamily: "Poppins", fontSize: "1.35em" }}
                className="slide-button"
                onClick={() => window.open(url, "_blank", "noreferrer")}
              >
                Get CLIQInvite
              </button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: "flex",
          }}
        >
          <Box className="hero-section-video-container">
            <ReactPlayer
              playing
              url={process.env.REACT_APP_LANDING_HERO_VIDEO_URL}
              autoPlay={false}
              muted={true}
              controls={true}
              loop={true}
              height={"100%"}
              width={"100%"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroSection;
