import React, { useState } from "react";
import { Box, Typography, Link, Button, ButtonGroup } from "@mui/material";
import { Alert } from "@mui/material";
import { getTimeBetween } from "../../../methods/getFormattedTime";
import { colors } from "../../../theme/colors";
import {
  EditOutlined,
  PushPinOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import LocalAnnouncementModule from "./LocalAnnouncementModule";
import { DataStore } from "aws-amplify";
import { Announcement, Event, OrganizationMember, User } from "../../../models";
import { useAuthContext } from "../../../contexts/AuthContext";

const AnnouncementModule = ({
  announcement,
  createAnnouncement,
  setSnackbarOpen,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { createNotif, dbUser } = useAuthContext();

  const handleLinkClick = (url) => {
    try {
      window.open(`https://${url}`, "_blank", "noreferrer");
    } catch (err) {
      Alert("Invalid URL");
    }
  };

  const handleEditAnnouncementClick = () => {
    if (announcement.isSample) {
      createAnnouncement();
    } else {
      setIsEditing(true);
    }
  };

  const sendNotifs = async (localAnnouncement) => {
    const currentEvent = await DataStore.query(Event, announcement.eventID);

    const allOrgMembers = await DataStore.query(OrganizationMember, (m) =>
      m.and((m) => [m.organizationID.eq(dbUser.id), m.isConfirmed.eq(true)])
    );

    allOrgMembers.map((member) => {
      if (localAnnouncement.rolesVisible.includes(member.role)) {
        DataStore.query(User, member.userID).then((user) => {
          createNotif(
            User,
            "announcement",
            user,
            "Updated Announcement",
            localAnnouncement.announcement || localAnnouncement.title,
            currentEvent.name,
            currentEvent.id,
            dbUser.profilePic
          );
        });
      }
    });
  };

  const updateAnnouncement = async (localAnnouncement) => {
    const tempAnnouncement = await DataStore.query(
      Announcement,
      announcement.id
    );
    await DataStore.save(
      Announcement.copyOf(tempAnnouncement, (updated) => {
        updated.title = localAnnouncement.title;
        updated.announcement = localAnnouncement.announcement;
        updated.link = localAnnouncement.link;
        updated.isPinned = localAnnouncement.isPinned;
        updated.visibleRoles = localAnnouncement.rolesVisible;
      })
    );
  };

  const submitAnnouncementEdits = async (localAnnouncement) => {
    const hasAnyPublicInformationChanged =
      localAnnouncement.title !== announcement.title ||
      localAnnouncement.announcement !== announcement.announcement ||
      localAnnouncement.link !== announcement.link ||
      localAnnouncement.isPinned !== announcement.isPinned;
    if (hasAnyPublicInformationChanged) {
      setSnackbarOpen({
        type: "info",
        message:
          "Would you like to send update notifications to all members in accessing groups?",
        action: (
          <ButtonGroup>
            <Button
              color="inherit"
              size="small"
              onClick={async () => {
                setSnackbarOpen(null);
                await updateAnnouncement(localAnnouncement);
                setIsEditing(false);
              }}
            >
              No
            </Button>
            <Button
              color="inherit"
              size="small"
              onClick={async () => {
                setSnackbarOpen(null);
                await updateAnnouncement(localAnnouncement);
                await sendNotifs(localAnnouncement);
                setIsEditing(false);
              }}
            >
              Yes
            </Button>
          </ButtonGroup>
        ),
      });
    } else {
      await updateAnnouncement(localAnnouncement);
      setIsEditing(false);
      setSnackbarOpen({
        type: "success",
        message: "Successfully updated announcement visibility",
      });
    }
  };

  const handleDeleteAnnouncement = async () => {
    setSnackbarOpen({
      type: "error",
      message: "Are you sure you want to delete this announcement?",
      action: (
        <Button
          color="inherit"
          size="small"
          onClick={async () => {
            setSnackbarOpen(null);
            await DataStore.delete(Announcement, announcement.id);
            setIsEditing(false);
            setSnackbarOpen({
              type: "success",
              message: "Successfully deleted announcement",
            });
          }}
        >
          Confirm
        </Button>
      ),
    });
  };

  if (isEditing) {
    return (
      <LocalAnnouncementModule
        announcement={{
          ...announcement,
          rolesVisible: announcement.visibleRoles,
        }}
        clearLocalAnnouncement={() => setIsEditing(false)}
        setSnackbarOpen={setSnackbarOpen}
        submitAnnouncementEdits={submitAnnouncementEdits}
        handleDeleteAnnouncement={handleDeleteAnnouncement}
      />
    );
  }

  return (
    <Box
      className="unstyled-card"
      sx={{
        borderRadius: 2,
        px: 2,
        py: 1,
        mb: 2,
        mt: 1,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h1" sx={{ fontSize: "1.2em", fontWeight: 500 }}>
          {announcement.title}
        </Typography>
        {announcement.isPinned && (
          <PushPinOutlined
            sx={{ color: colors.primaryColor, transform: "rotateZ(45deg)" }}
          />
        )}
      </Box>

      {announcement.link ? (
        <Typography variant="body2" sx={{ mb: 1.5 }}>
          <Link
            onClick={() => handleLinkClick(announcement.link)}
            color="primary"
            sx={{
              cursor: "pointer",
              transition: "opacity 150ms ease-in-out",
              ":hover": {
                opacity: 0.7,
              },
            }}
          >
            {announcement.link}
          </Link>
        </Typography>
      ) : null}

      {announcement.announcement
        ? announcement.announcement
            .split("\n")
            .map((announcementParagraph, index) => (
              <Typography variant="body2" key={index.toString()}>
                {announcementParagraph}
                <br />
              </Typography>
            ))
        : null}

      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        pt={2}
      >
        <Typography variant="body2" sx={{ fontSize: ".8em" }}>
          {getTimeBetween(Math.floor(new Date(announcement.updatedAt)))}
        </Typography>
        <Box display="flex" gap={1}>
          <Button
            sx={{
              bgcolor: colors.backgroundHighlight,
              ":hover": {
                bgcolor: `${colors.backgroundHighlight}D6`,
              },
            }}
            variant="contained"
            startIcon={<EditOutlined sx={{ color: colors.primaryText }} />}
            size="small"
            onClick={handleEditAnnouncementClick}
          >
            Edit Announcement
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={
              <VisibilityOutlined sx={{ color: colors.primaryText }} />
            }
            size="small"
          >
            Adjust Visibility
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AnnouncementModule;
