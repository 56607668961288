import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { colors } from "../theme/colors";
import NewsDisplayModule from "./NewsDisplayModule";

const AllNewsDisplay = ({ news, open, setOpen, title }) => {
  const renderItem = (item) => (
    <Box sx={{ mb: 1 }} key={item.id + "123"}>
      <NewsDisplayModule news={item} />
    </Box>
  );

  if (!news) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{ maxHeight: "70%", marginTop: "auto", marginBottom: "auto" }}
    >
      <DialogTitle>
        {title}
        <br />
        <p
          style={{ fontSize: ".8rem", color: colors.secondaryText, margin: 0 }}
        >
          Here are the latest updates, features, and news.
        </p>
      </DialogTitle>
      <DialogContent className="scroll-container">
        {news.map(renderItem)}
      </DialogContent>
    </Dialog>
  );
};

export default AllNewsDisplay;
