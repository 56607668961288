const plans = {
  Pro: {
    Chapter: {
      Month: 15,
      Year: 13.5,
    },
    Member: {
      Month: 2.5,
      Year: 2.25,
    },
  },
  Premium: {
    Chapter: {
      Month: 20,
      Year: 18,
    },
    Member: {
      Month: 4,
      Year: 3.6,
    },
  },
  VIP: {
    Chapter: {
      Month: 30,
      Year: 27,
    },
    Member: {
      Month: 8,
      Year: 7.2,
    },
  },
};

export const calculateSubscriptionCosts = ({
  billingCycle,
  planType,
  totalMembers,
}) => {
  const multiplier = 100 * 12;

  const billingMultiplier = billingCycle === "Year" ? multiplier : 100;
  const memberCost = plans[planType]["Member"][billingCycle];
  const chapterCost = plans[planType]["Chapter"][billingCycle];
  let discountMultiplier = 1;

  if (totalMembers > 30 && totalMembers < 200) {
    // Linear decrease from 1 to 0.75 as totalMembers goes from 30 to 200
    discountMultiplier = -(0.25 / 170) * totalMembers + 1.044117647;
  } else if (totalMembers <= 30 && totalMembers > 10) {
    // Linear decrease from 1.2 to 1 as totalMembers goes from 10 to 30
    discountMultiplier = -0.0 * totalMembers + 1;
  } else if (totalMembers <= 10) {
    discountMultiplier = 1.0;
  } else if (totalMembers >= 200) {
    discountMultiplier = 0.75;
  }

  const totalMemberCost = billingMultiplier * memberCost * discountMultiplier;
  const totalChapterCost = billingMultiplier * chapterCost * discountMultiplier;
  const totalCost =
    billingMultiplier *
    (memberCost * discountMultiplier * totalMembers +
      chapterCost * discountMultiplier);

  return {
    memberCost: totalMemberCost,
    chapterCost: totalChapterCost,
    totalCost,
  };
};
