/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPayIn = /* GraphQL */ `
  mutation CreatePayIn(
    $input: CreatePayInInput!
    $condition: ModelPayInConditionInput
  ) {
    createPayIn(input: $input, condition: $condition) {
      id
      amount
      accountID
      Event {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numTickets
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      payInEventId
    }
  }
`;
export const updatePayIn = /* GraphQL */ `
  mutation UpdatePayIn(
    $input: UpdatePayInInput!
    $condition: ModelPayInConditionInput
  ) {
    updatePayIn(input: $input, condition: $condition) {
      id
      amount
      accountID
      Event {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numTickets
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      payInEventId
    }
  }
`;
export const deletePayIn = /* GraphQL */ `
  mutation DeletePayIn(
    $input: DeletePayInInput!
    $condition: ModelPayInConditionInput
  ) {
    deletePayIn(input: $input, condition: $condition) {
      id
      amount
      accountID
      Event {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numTickets
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      payInEventId
    }
  }
`;
export const createPayout = /* GraphQL */ `
  mutation CreatePayout(
    $input: CreatePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    createPayout(input: $input, condition: $condition) {
      id
      isFulfilled
      amount
      accountID
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePayout = /* GraphQL */ `
  mutation UpdatePayout(
    $input: UpdatePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    updatePayout(input: $input, condition: $condition) {
      id
      isFulfilled
      amount
      accountID
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePayout = /* GraphQL */ `
  mutation DeletePayout(
    $input: DeletePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    deletePayout(input: $input, condition: $condition) {
      id
      isFulfilled
      amount
      accountID
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      Payouts {
        nextToken
        startedAt
      }
      venmo
      PayIns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      Payouts {
        nextToken
        startedAt
      }
      venmo
      PayIns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      Payouts {
        nextToken
        startedAt
      }
      venmo
      PayIns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createScore = /* GraphQL */ `
  mutation CreateScore(
    $input: CreateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    createScore(input: $input, condition: $condition) {
      id
      score
      orgMemberID
      contactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateScore = /* GraphQL */ `
  mutation UpdateScore(
    $input: UpdateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    updateScore(input: $input, condition: $condition) {
      id
      score
      orgMemberID
      contactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteScore = /* GraphQL */ `
  mutation DeleteScore(
    $input: DeleteScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    deleteScore(input: $input, condition: $condition) {
      id
      score
      orgMemberID
      contactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      name
      number
      notes
      contactListID
      Scores {
        nextToken
        startedAt
      }
      avgScore
      creatorID
      photo
      accessingUsers
      coOwners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      name
      number
      notes
      contactListID
      Scores {
        nextToken
        startedAt
      }
      avgScore
      creatorID
      photo
      accessingUsers
      coOwners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      name
      number
      notes
      contactListID
      Scores {
        nextToken
        startedAt
      }
      avgScore
      creatorID
      photo
      accessingUsers
      coOwners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createContactList = /* GraphQL */ `
  mutation CreateContactList(
    $input: CreateContactListInput!
    $condition: ModelContactListConditionInput
  ) {
    createContactList(input: $input, condition: $condition) {
      id
      creatorID
      accessingUsers
      coOwners
      name
      numContacts
      Contacts {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateContactList = /* GraphQL */ `
  mutation UpdateContactList(
    $input: UpdateContactListInput!
    $condition: ModelContactListConditionInput
  ) {
    updateContactList(input: $input, condition: $condition) {
      id
      creatorID
      accessingUsers
      coOwners
      name
      numContacts
      Contacts {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteContactList = /* GraphQL */ `
  mutation DeleteContactList(
    $input: DeleteContactListInput!
    $condition: ModelContactListConditionInput
  ) {
    deleteContactList(input: $input, condition: $condition) {
      id
      creatorID
      accessingUsers
      coOwners
      name
      numContacts
      Contacts {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      isPinned
      announcement
      eventID
      title
      visibleRoles
      link
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      isPinned
      announcement
      eventID
      title
      visibleRoles
      link
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      isPinned
      announcement
      eventID
      title
      visibleRoles
      link
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createScan = /* GraphQL */ `
  mutation CreateScan(
    $input: CreateScanInput!
    $condition: ModelScanConditionInput
  ) {
    createScan(input: $input, condition: $condition) {
      id
      date
      eventID
      isValid
      category
      isScanOut
      guestInviteID
      orgMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateScan = /* GraphQL */ `
  mutation UpdateScan(
    $input: UpdateScanInput!
    $condition: ModelScanConditionInput
  ) {
    updateScan(input: $input, condition: $condition) {
      id
      date
      eventID
      isValid
      category
      isScanOut
      guestInviteID
      orgMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteScan = /* GraphQL */ `
  mutation DeleteScan(
    $input: DeleteScanInput!
    $condition: ModelScanConditionInput
  ) {
    deleteScan(input: $input, condition: $condition) {
      id
      date
      eventID
      isValid
      category
      isScanOut
      guestInviteID
      orgMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      createdAt
      objectID
      message
      eventName
      senderName
      image
      senderID
      senderType
      read
      userID
      organizationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      createdAt
      objectID
      message
      eventName
      senderName
      image
      senderID
      senderType
      read
      userID
      organizationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      createdAt
      objectID
      message
      eventName
      senderName
      image
      senderID
      senderType
      read
      userID
      organizationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGuestInvite = /* GraphQL */ `
  mutation CreateGuestInvite(
    $input: CreateGuestInviteInput!
    $condition: ModelGuestInviteConditionInput
  ) {
    createGuestInvite(input: $input, condition: $condition) {
      id
      qrCode
      maxUses
      numUsed
      orgMemberID
      eventID
      category
      numInvalid
      label
      contactListID
      contactID
      cost
      paymentIntentID
      photoID
      photoStatus
      guestOrgID
      status
      ticketLinkID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGuestInvite = /* GraphQL */ `
  mutation UpdateGuestInvite(
    $input: UpdateGuestInviteInput!
    $condition: ModelGuestInviteConditionInput
  ) {
    updateGuestInvite(input: $input, condition: $condition) {
      id
      qrCode
      maxUses
      numUsed
      orgMemberID
      eventID
      category
      numInvalid
      label
      contactListID
      contactID
      cost
      paymentIntentID
      photoID
      photoStatus
      guestOrgID
      status
      ticketLinkID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGuestInvite = /* GraphQL */ `
  mutation DeleteGuestInvite(
    $input: DeleteGuestInviteInput!
    $condition: ModelGuestInviteConditionInput
  ) {
    deleteGuestInvite(input: $input, condition: $condition) {
      id
      qrCode
      maxUses
      numUsed
      orgMemberID
      eventID
      category
      numInvalid
      label
      contactListID
      contactID
      cost
      paymentIntentID
      photoID
      photoStatus
      guestOrgID
      status
      ticketLinkID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTicketLink = /* GraphQL */ `
  mutation CreateTicketLink(
    $input: CreateTicketLinkInput!
    $condition: ModelTicketLinkConditionInput
  ) {
    createTicketLink(input: $input, condition: $condition) {
      id
      creatorID
      eventID
      maxTickets
      ticketsSold
      category
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTicketLink = /* GraphQL */ `
  mutation UpdateTicketLink(
    $input: UpdateTicketLinkInput!
    $condition: ModelTicketLinkConditionInput
  ) {
    updateTicketLink(input: $input, condition: $condition) {
      id
      creatorID
      eventID
      maxTickets
      ticketsSold
      category
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTicketLink = /* GraphQL */ `
  mutation DeleteTicketLink(
    $input: DeleteTicketLinkInput!
    $condition: ModelTicketLinkConditionInput
  ) {
    deleteTicketLink(input: $input, condition: $condition) {
      id
      creatorID
      eventID
      maxTickets
      ticketsSold
      category
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInvite = /* GraphQL */ `
  mutation CreateInvite(
    $input: CreateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    createInvite(input: $input, condition: $condition) {
      id
      invitesGiven
      invitesUsed
      hasResponded
      response
      userId
      organizationInfo
      eventInfo
      qrCode
      isOrg
      organizationID
      eventID
      guestOrgID
      membersOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      id
      invitesGiven
      invitesUsed
      hasResponded
      response
      userId
      organizationInfo
      eventInfo
      qrCode
      isOrg
      organizationID
      eventID
      guestOrgID
      membersOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInvite = /* GraphQL */ `
  mutation DeleteInvite(
    $input: DeleteInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    deleteInvite(input: $input, condition: $condition) {
      id
      invitesGiven
      invitesUsed
      hasResponded
      response
      userId
      organizationInfo
      eventInfo
      qrCode
      isOrg
      organizationID
      eventID
      guestOrgID
      membersOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      address
      name
      description
      startDate
      endDate
      organizationInfo
      organizationID
      Invites {
        nextToken
        startedAt
      }
      GuestInvites {
        nextToken
        startedAt
      }
      Scans {
        nextToken
        startedAt
      }
      Jobs {
        nextToken
        startedAt
      }
      isGuestListPublic
      categories {
        category
      }
      Announcements {
        nextToken
        startedAt
      }
      eventCode
      ticketInformation {
        price
      }
      maxTickets
      ticketsSold
      numGuests
      requirePhotoID
      jobsNotificationSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      address
      name
      description
      startDate
      endDate
      organizationInfo
      organizationID
      Invites {
        nextToken
        startedAt
      }
      GuestInvites {
        nextToken
        startedAt
      }
      Scans {
        nextToken
        startedAt
      }
      Jobs {
        nextToken
        startedAt
      }
      isGuestListPublic
      categories {
        category
      }
      Announcements {
        nextToken
        startedAt
      }
      eventCode
      ticketInformation {
        price
      }
      maxTickets
      ticketsSold
      numGuests
      requirePhotoID
      jobsNotificationSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      address
      name
      description
      startDate
      endDate
      organizationInfo
      organizationID
      Invites {
        nextToken
        startedAt
      }
      GuestInvites {
        nextToken
        startedAt
      }
      Scans {
        nextToken
        startedAt
      }
      Jobs {
        nextToken
        startedAt
      }
      isGuestListPublic
      categories {
        category
      }
      Announcements {
        nextToken
        startedAt
      }
      eventCode
      ticketInformation {
        price
      }
      maxTickets
      ticketsSold
      numGuests
      requirePhotoID
      jobsNotificationSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      profilePic
      name
      school
      shortSchool
      searchName
      pushTokens
      adminOrganizationIDs
      Notifications {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      profilePic
      name
      school
      shortSchool
      searchName
      pushTokens
      adminOrganizationIDs
      Notifications {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      profilePic
      name
      school
      shortSchool
      searchName
      pushTokens
      adminOrganizationIDs
      Notifications {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOrganizationMember = /* GraphQL */ `
  mutation CreateOrganizationMember(
    $input: CreateOrganizationMemberInput!
    $condition: ModelOrganizationMemberConditionInput
  ) {
    createOrganizationMember(input: $input, condition: $condition) {
      id
      role
      memberInfo
      isConfirmed
      organizationID
      userID
      analytics {
        eventID
        invitesGiven
        validScans
        invalidScans
        revenue
        ticketsSold
      }
      analyticsUpdatedAt
      adminStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOrganizationMember = /* GraphQL */ `
  mutation UpdateOrganizationMember(
    $input: UpdateOrganizationMemberInput!
    $condition: ModelOrganizationMemberConditionInput
  ) {
    updateOrganizationMember(input: $input, condition: $condition) {
      id
      role
      memberInfo
      isConfirmed
      organizationID
      userID
      analytics {
        eventID
        invitesGiven
        validScans
        invalidScans
        revenue
        ticketsSold
      }
      analyticsUpdatedAt
      adminStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOrganizationMember = /* GraphQL */ `
  mutation DeleteOrganizationMember(
    $input: DeleteOrganizationMemberInput!
    $condition: ModelOrganizationMemberConditionInput
  ) {
    deleteOrganizationMember(input: $input, condition: $condition) {
      id
      role
      memberInfo
      isConfirmed
      organizationID
      userID
      analytics {
        eventID
        invitesGiven
        validScans
        invalidScans
        revenue
        ticketsSold
      }
      analyticsUpdatedAt
      adminStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      school
      profilePic
      name
      email
      bio
      createdRoles
      searchName
      bannerPic
      shortSchool
      pushTokens
      plan
      adminIDs
      SubscriptionPlans {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      Events {
        nextToken
        startedAt
      }
      Notifications {
        nextToken
        startedAt
      }
      Account {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationAccountId
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      school
      profilePic
      name
      email
      bio
      createdRoles
      searchName
      bannerPic
      shortSchool
      pushTokens
      plan
      adminIDs
      SubscriptionPlans {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      Events {
        nextToken
        startedAt
      }
      Notifications {
        nextToken
        startedAt
      }
      Account {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationAccountId
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      school
      profilePic
      name
      email
      bio
      createdRoles
      searchName
      bannerPic
      shortSchool
      pushTokens
      plan
      adminIDs
      SubscriptionPlans {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      Events {
        nextToken
        startedAt
      }
      Notifications {
        nextToken
        startedAt
      }
      Account {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationAccountId
    }
  }
`;
export const createSubscriptionPlan = /* GraphQL */ `
  mutation CreateSubscriptionPlan(
    $input: CreateSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    createSubscriptionPlan(input: $input, condition: $condition) {
      id
      planType
      isActive
      totalMembers
      cost
      billingCycle
      dateRenewalRequired
      stripeSubscriptionID
      promoFactor
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSubscriptionPlan = /* GraphQL */ `
  mutation UpdateSubscriptionPlan(
    $input: UpdateSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    updateSubscriptionPlan(input: $input, condition: $condition) {
      id
      planType
      isActive
      totalMembers
      cost
      billingCycle
      dateRenewalRequired
      stripeSubscriptionID
      promoFactor
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSubscriptionPlan = /* GraphQL */ `
  mutation DeleteSubscriptionPlan(
    $input: DeleteSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    deleteSubscriptionPlan(input: $input, condition: $condition) {
      id
      planType
      isActive
      totalMembers
      cost
      billingCycle
      dateRenewalRequired
      stripeSubscriptionID
      promoFactor
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      jobName
      memberName
      userID
      eventID
      startDate
      endDate
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      jobName
      memberName
      userID
      eventID
      startDate
      endDate
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      jobName
      memberName
      userID
      eventID
      startDate
      endDate
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVersion = /* GraphQL */ `
  mutation CreateVersion(
    $input: CreateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    createVersion(input: $input, condition: $condition) {
      id
      version
      supported
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVersion = /* GraphQL */ `
  mutation UpdateVersion(
    $input: UpdateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    updateVersion(input: $input, condition: $condition) {
      id
      version
      supported
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVersion = /* GraphQL */ `
  mutation DeleteVersion(
    $input: DeleteVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    deleteVersion(input: $input, condition: $condition) {
      id
      version
      supported
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
