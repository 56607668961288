import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../contexts/AuthContext";
import {
  AccountBalanceOutlined,
  BadgeOutlined,
  CancelOutlined,
  CelebrationOutlined,
  Download,
  EditOutlined,
  GroupOutlined,
  MailOutlined,
} from "@mui/icons-material";
import { sum } from "../../methods/sum";
import { downloadGuestListData } from "../../methods/downloadGuestListData";
import { useNavigate } from "react-router-dom";
import { colors } from "../../theme/colors";
import { useState } from "react";

const GuestListHeader = ({
  loading,
  event,
  guestInviteCount,
  isPostEvent,
  orgMemberInvites,
  inviteCount,
}) => {
  const navigate = useNavigate();
  const { dbUser } = useAuthContext();
  const [inviteOptionsAnchorEl, setInviteOptionsAnchorEl] = useState(null);
  const handleInviteClick = (event) => {
    setInviteOptionsAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setInviteOptionsAnchorEl(null);
  };
  const handleExportList = async () => {
    downloadGuestListData(
      event,
      orgMemberInvites,
      guestInviteCount,
      dbUser.name
    );
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={8}>
        <Box
          className="unstyled-card"
          sx={{
            px: 2,
            py: 1,
            height: "calc(100% - 1em)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <Skeleton height="2em" sx={{ mb: "auto" }} />
          ) : (
            <Box sx={{ display: "flex", alignItems: "flex-start", mb: "auto" }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h1" sx={{ fontSize: "1.4em" }}>
                  Guest List
                </Typography>
                <Typography variant="body2" sx={{ fontSize: ".9em" }}>
                  for {event.name}, hosted by{" "}
                  {event.organizationID === dbUser.id
                    ? "you"
                    : dbUser.organizationInfo[0]}
                </Typography>
              </Box>
              {Math.floor(new Date()).toString() < event.endDate ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Download sx={{ color: "white" }} />}
                  onClick={handleExportList}
                >
                  Download list
                </Button>
              ) : null}
            </Box>
          )}
          {loading ? (
            <Skeleton variant="rounded" height="3em" sx={{ mt: 2 }} />
          ) : (
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button
                variant="contained"
                sx={{ flex: 1, fontSize: { xs: "12px", lg: ".875rem" } }}
                startIcon={
                  <MailOutlined
                    sx={{
                      color: "white",
                      fontSize: { xs: "12px", lg: ".875rem" },
                    }}
                  />
                }
                color="primary"
                onClick={handleInviteClick}
              >
                Send Invites
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={inviteOptionsAnchorEl}
                keepMounted
                open={Boolean(inviteOptionsAnchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => navigate(`/event/invite_members/${event.id}`)}
                >
                  Invite Members
                </MenuItem>
                <MenuItem onClick={() => navigate(`/event/invite/${event.id}`)}>
                  Distribute Guest Invites
                </MenuItem>
                <MenuItem
                  onClick={() => navigate(`/event/invite_orgs/${event.id}`)}
                >
                  Invite Organizations
                </MenuItem>
              </Menu>
              <Button
                variant="contained"
                sx={{
                  flex: { xs: 1, lg: 0.5 },
                  fontSize: { xs: "12px", lg: ".875rem" },
                  backgroundColor: colors.backgroundHighlight,
                  ":hover": {
                    backgroundColor: `${colors.backgroundHighlight}80`,
                  },
                }}
                startIcon={
                  <EditOutlined
                    sx={{
                      color: "white",
                      fontSize: { xs: "12px", lg: ".875rem" },
                    }}
                  />
                }
                onClick={() => navigate(`/event/edit/${event.id}`)}
              >
                Edit Event
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          className="unstyled-card"
          sx={{
            px: 2,
            py: 1,
            height: "calc(100% - 1em)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <Skeleton height="1em"  sx={{mb: 'auto'}}/>
          ) : (
            <Typography variant="h2" sx={{ fontSize: "1em", mb: "auto" }}>
              Guest List Statistics
            </Typography>
          )}
          {loading ? (
            <Skeleton height="4em" variant="rounded" />
          ) : (
            <>
              <Box sx={{ mt: "auto", mb: "auto" }}>
                {event.requirePhotoID ? (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <BadgeOutlined color="primary" />
                    <Typography variant="body1" sx={{ fontSize: ".8em" }}>
                      {sum(guestInviteCount, "count")} IDs uploaded
                    </Typography>
                  </Box>
                ) : null}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <GroupOutlined color="primary" />
                  <Typography variant="body1" sx={{ fontSize: ".8em" }}>
                    {inviteCount} members invited
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <MailOutlined color="primary" />
                  <Typography variant="body1" sx={{ fontSize: ".8em" }}>
                    {event.requirePhotoID
                      ? sum(guestInviteCount, "invitesLength")
                      : sum(guestInviteCount, "count")}{" "}
                    guests invited
                  </Typography>
                </Box>
                {event.ticketInformation ? (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <AccountBalanceOutlined color="primary" />
                    <Typography variant="body1" sx={{ fontSize: ".8em" }}>
                      {sum(guestInviteCount, "amount") > 0
                        ? `$${sum(guestInviteCount, "amount").toFixed(2)}`
                        : "$0"}{" "}
                      total income
                    </Typography>
                  </Box>
                ) : null}
                {isPostEvent ? (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CelebrationOutlined color="primary" />
                      <Typography variant="body1" sx={{ fontSize: ".8em" }}>
                        {sum(event?.analytics?.categoryData, "validScans")}{" "}
                        guests showed up
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CancelOutlined color="error" />
                      <Typography variant="body1" sx={{ fontSize: ".8em" }}>
                        {sum(event?.analytics?.categoryData, "invalidScans")}{" "}
                        gatecrashers rejected
                      </Typography>
                    </Box>
                  </>
                ) : null}
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default GuestListHeader;
