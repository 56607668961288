import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Typography,
  Link,
  Button,
} from "@mui/material";
import { AccountCircle, MenuOutlined } from "@mui/icons-material";
import Logo from "../../components/Logo";
import ProfileImage from "../../components/ProfileImage";
import { useAuthContext } from "../../contexts/AuthContext";
import "./PublicHeader.css";
import ElevationScroll from "./ElevationScroll";
import NavButtonExpandMenu from "./NavButtonExpandMenu";
import NavigationDrawer from "./NavigationDrawer";

const PublicHeader = (props) => {
  const { dbUser } = useAuthContext();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setUrl(
        "https://play.google.com/store/apps/details?id=com.skdraughn.CLIQ"
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setUrl("https://apps.apple.com/us/app/cli%CF%89/id1664767480");
    }
    // else {
    //   // for desktop browsers, redirect to signup page
    //   setUrl("https://www.cliqinvite.com/signup");
    // }
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const pages = [
    { route: "/about", text: "About" },
    {
      text: "Features",
      onClick: (e) => {
        setMenuAnchorEl(e.currentTarget);
      },
    },
    {
      text: "Tutorials",
      route: "/tutorials",
    },
    // {
    //   text: "Pricing",
    //   route: "/plans",
    // },
  ];

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <ElevationScroll {...props}>
        <Box sx={{ position: "fixed", zIndex: 3 }}>
          <Toolbar
            className="default-page-size"
            sx={{
              height: 80,
              px: "2vw",
            }}
            disableGutters={true}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <Logo
                height="2rem"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              />
              <Box
                sx={{
                  display: { lg: "flex", xs: "none" },
                  alignItems: "center",
                  gap: 3,
                }}
              >
                {pages.map(({ text, route, onClick }) => (
                  <>
                    <Typography
                      variant="body1"
                      onClick={(e) => {
                        onClick ? onClick(e) : navigate(route);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {text}
                    </Typography>
                    <NavButtonExpandMenu
                      menuAnchorEl={menuAnchorEl}
                      setMenuAnchorEl={setMenuAnchorEl}
                    />
                  </>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  gap: 2,
                  display: { lg: "flex", xs: "none" },
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    window.open(
                      "https://calendly.com/cliqinvite/intro",
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  Request a Demo
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => window.open(url, "_blank", "noreferrer")}
                >
                  Get CLIQInvite
                </Button>
              </Box>

              {/* {!dbUser ? (
                <IconButton
                  color="inherit"
                  sx={{ p: 0 }}
                  onClick={() => navigate("/login")}
                >
                  <AccountCircle
                    color="primary"
                    style={{ fontSize: "1.5rem" }}
                  />
                </IconButton>
              ) : (
                <ProfileImage height="1.5rem" pic={dbUser.profilePic} />
              )} */}
              <IconButton
                sx={{ display: { lg: "none" }, p: 0 }}
                onClick={toggleDrawer}
                edge="start"
                color="primary"
                aria-label="menu"
              >
                <MenuOutlined
                  color="primary"
                  style={{ fontSize: "1.5rem", color: "white" }}
                />
              </IconButton>
            </Box>
          </Toolbar>
        </Box>
      </ElevationScroll>
      <NavigationDrawer
        url={url}
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        pages={pages}
      />
    </Box>
  );
};

export default PublicHeader;
