import { colors } from "../../theme/colors";
import InsightsEventModule from "./InsightsEventModule";
import { useEffect, useState } from "react";
import { sum } from "../../methods/sum";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useAuthContext } from "../../contexts/AuthContext";

const HotEventDisplay = ({ events, timeFrame, setSnackbarOpen }) => {
  const navigate = useNavigate();
  const [timeFrameEvents, setTimeFrameEvents] = useState(null);
  const { monthEvents, planLimits } = useAuthContext();

  useEffect(() => {
    if (events) {
      setTimeFrameEvents(
        events
          .filter((e) => e.startDate > timeFrame.toString())
          .sort(
            (a, b) =>
              sum(b.analytics.categoryData, "validScans") -
              sum(a.analytics.categoryData, "validScans")
          )
          .slice(0, 3) // get only the top three sorted elements
      );
    }
  }, [timeFrame, events]);

  const reallyLoading = !timeFrameEvents;

  if (reallyLoading) {
    return (
      <Box className="unstyled-card" sx={{ px: 2, py: 1 }}>
        <Skeleton height="2em" />
        <Skeleton variant="rounded" height="8em" />
      </Box>
    );
  }

  return (
    <Box className="unstyled-card" sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.2em",
            }}
          >
            Your Hottest Events
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: ".8em",
            }}
          >
            Click on any event to view its full analytics page
          </Typography>
        </Box>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            if (monthEvents >= planLimits.maxEvents) {
              setSnackbarOpen({
                type: "error",
                message:
                  "You have exceeded your alloted monthly maximimum events for your current subscription plan.",
                action: (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => navigate("/dashboard/billing/upgrade_plan")}
                  >
                    Upgrade Now
                  </Button>
                ),
              });
            } else {
              navigate("/create");
            }
          }}
          startIcon={<Add sx={{ color: colors.primaryText }} />}
        >
          Create Event
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        {timeFrameEvents?.length ? (
          timeFrameEvents.map((event) => (
            <InsightsEventModule event={event} key={event.id} />
          ))
        ) : (
          <Box>
            <Typography variant="body2">
              No events hosted during selected time frame
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HotEventDisplay;
