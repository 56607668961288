import {
  ArrowDropDownOutlined,
  Check,
  CheckBox,
  Close,
} from "@mui/icons-material";
import ProfileImage from "../../components/ProfileImage";
import {
  Button,
  IconButton,
  LinearProgress,
  MenuItem,
  Box,
  Select,
  Checkbox,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { OrganizationMember } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";

const MemberModule = ({
  member,
  role,
  isPending,
  selecting,
  selectedMembersQueue,
  setSelectedMembersQueue,
}) => {
  const [status, setStatus] = useState(isPending ? "Pending" : "Confirmed");
  const [timer, setTimer] = useState();
  const { dbUser } = useAuthContext();
  const [isChecked, setIsChecked] = useState(false);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setIsChecked(selectedMembersQueue.has(member.id));
  }, [selectedMembersQueue, member.id]);

  useEffect(() => {
    return () => clearInterval(timer);
  }, [timer]);

  const handleDecline = async () => {
    setProgress(0);
    setStatus("Declining");
    const tempTimer = setInterval(() => {
      setProgress((old) => {
        if (old === 100) {
          DataStore.delete(OrganizationMember, member.id);
          clearInterval(tempTimer);
          return 0;
        } else return old + 10;
      });
    }, 500);
    setTimer(tempTimer);
  };

  const handleAccept = async () => {
    await DataStore.save(
      OrganizationMember.copyOf(member, (updated) => {
        updated.isConfirmed = true;
      })
    );
  };

  const handleChange = async (e) => {
    await DataStore.query(OrganizationMember, member.id).then(
      async (member) => {
        await DataStore.save(
          OrganizationMember.copyOf(member, (updated) => {
            updated.role = e.target.value;
          })
        );
      }
    );
  };

  const handleUndo = () => {
    clearInterval(timer);

    setStatus("Pending");
  };

  if (isPending) {
    return (
      <div
        style={{
          border: `1.5px solid ${colors.errorColor}`,
          backgroundColor: colors.secondaryBackground,
          display: "flex",
          padding: ".25em .5em",
          borderRadius: 10,
          marginBottom: ".3em",
        }}
      >
        <div
          style={{ alignSelf: "center", display: "flex", alignItems: "center" }}
        >
          <ProfileImage pic={member.memberInfo[1]} height={"3em"} />
        </div>
        <div
          style={{
            marginLeft: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h2
            style={{ color: colors.primaryText, margin: 0, fontSize: "1.2em" }}
          >
            {member.memberInfo[0]}
          </h2>
          <p style={{ margin: 0, opacity: 0.8, fontSize: ".8em" }}>
            {status === "Declining" ? (
              <LinearProgress variant="determinate" value={progress} />
            ) : (
              <i>Awaiting Action</i>
            )}
          </p>
        </div>

        {status !== "Declining" ? (
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
              display: "flex",
            }}
          >
            <IconButton onClick={handleDecline}>
              <Close style={{ fontSize: "1em" }} color="error" />
            </IconButton>
            <IconButton onClick={handleAccept}>
              <Check style={{ fontSize: "1em" }} />
            </IconButton>
          </div>
        ) : (
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
              display: "flex",
            }}
          >
            <Button sx={{ fontFamily: "Poppins" }} onClick={handleUndo}>
              UNDO
            </Button>
          </div>
        )}
      </div>
    );
  }
  return (
    <Box
      sx={{
        borderRadius: 2,
        mb: ".3em",
        backgroundColor: `${colors.secondaryBackground}`,
        padding: ".5em 1em",
        display: "flex",
        alignItems: "center",
      }}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ProfileImage pic={member.memberInfo[1]} height={"3em"} />
      </div>
      <div
        style={{
          marginLeft: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h2
          style={{
            color: colors.primaryText,
            margin: 0,
            fontSize: "1.2em",
          }}
        >
          {member.memberInfo[0]}
        </h2>
        <Select
          value={role}
          onChange={handleChange} // You need to create this function
          style={{ margin: 0, opacity: 0.8, fontSize: ".8em", padding: 0 }}
          IconComponent={() => (
            <ArrowDropDownOutlined
              sx={{
                color: colors.secondaryText,
                cursor: "pointer",

                ":hover": {
                  opacity: 0.7,
                },
              }}
            />
          )}
          SelectDisplayProps={{
            style: {
              padding: 0,
              paddingLeft: ".5em",
            },
          }}
          MenuProps={{
            PaperProps: { sx: { backgroundColor: colors.secondaryBackground } },
          }}
        >
          {[...dbUser?.createdRoles, "No Group"].map((role, index) => (
            <MenuItem key={index} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </div>
      {selecting ? (
        <Checkbox
          checked={isChecked}
          onClick={() => {
            setIsChecked((old) => !old);
            setSelectedMembersQueue((old) => {
              let newQueue = new Set(old);
              if (old.has(member.id)) {
                newQueue.delete(member.id);
              } else newQueue.add(member.id);
              return newQueue;
            });
          }}
          sx={{ marginLeft: "auto" }}
        />
      ) : null}
    </Box>
  );
};

export default MemberModule;
