import {
  Add,
  ListAltOutlined,
  ViewAgendaOutlined,
  PhoneOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import { Box, Button, Skeleton } from "@mui/material";
import { colors } from "../../theme/colors";

const ListDisplayHeader = ({
  handleNewListPress,
  numTotalLists,
  numTotalContacts,
  numUniqueEditors,
  setAllListsDialogOpen,
  loading,
}) => {
  return (
    <Box style={{ display: "flex", gap: "1em" }}>
      <div
        className="unstyled-card"
        style={{
          flex: 1.5,
          padding: ".5em 1em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", gap: "1em", marginBottom: ".8em" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {!loading ? (
              <h1 style={{ margin: 0, fontSize: "1.2em" }}>
                Your Contacts Lists
              </h1>
            ) : (
              <Skeleton sx={{ fontSize: "1.2em" }} />
            )}
            {!loading ? (
              <p
                style={{
                  color: colors.secondaryText,
                  fontSize: ".8em",
                  margin: 0,
                  fontWeight: 500,
                }}
              >
                Create and manage contacts lists to automate communications,
                easily evaluate PNMs, and streamline your rush workflow.
              </p>
            ) : (
              <Skeleton sx={{ fontSize: ".8em" }} />
            )}
          </div>
        </div>
        {!loading ? (
          <Box sx={{ display: "flex", gap: "1em" }}>
            <Button
              variant="contained"
              sx={{ flex: 1 }}
              startIcon={<Add sx={{ color: "white" }} />}
              color="primary"
              onClick={handleNewListPress}
            >
              Create List
            </Button>
            <Button
              variant="contained"
              sx={{
                flex: 0.5,
                backgroundColor: colors.backgroundHighlight,
                ":hover": {
                  backgroundColor: `${colors.backgroundHighlight}80`,
                },
              }}
              startIcon={<ViewAgendaOutlined sx={{ color: "white" }} />}
              onClick={() => setAllListsDialogOpen(true)}
            >
              View All Lists
            </Button>
          </Box>
        ) : (
          <Skeleton variant="rounded" sx={{height: '2em'}} />
        )}
      </div>
      <div
        className="unstyled-card"
        style={{
          flex: 0.8,
          padding: ".5em 1em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!loading ? (
          <h1
            style={{ margin: 0, fontSize: "1em", color: colors.secondaryText }}
          >
            Lists All-Time Statistics
          </h1>
        ) : (
          <Skeleton sx={{ fontSize: "1em" }} />
        )}
        {!loading ? (
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <p
              style={{
                color: colors.primaryText,
                fontSize: ".8em",
                margin: 0,
                fontWeight: 500,
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <ListAltOutlined color="primary" />
                <span style={{ marginLeft: "0.5em" }}>
                  {numTotalLists} list{numTotalLists === 1 ? "" : "s"}
                </span>
              </span>
            </p>
            <p
              style={{
                color: colors.primaryText,
                fontSize: ".8em",
                margin: 0,
                fontWeight: 500,
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <PhoneOutlined color="primary" />
                <span style={{ marginLeft: "0.5em" }}>
                  {numTotalContacts} contact{numTotalContacts === 1 ? "" : "s"}
                </span>
              </span>
            </p>
            <p
              style={{
                color: colors.primaryText,
                fontSize: ".8em",
                margin: 0,
                fontWeight: 500,
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <SupervisorAccountOutlined color="primary" />
                <span style={{ marginLeft: "0.5em" }}>
                  {numUniqueEditors} unique editor
                  {numUniqueEditors === 1 ? "" : "s"}
                </span>
              </span>
            </p>
          </div>
        ) : (
          <Skeleton variant="rounded" sx={{ mt: "auto", mb: "auto", height: "3.5em" }} />
        )}
      </div>
    </Box>
  );
};

export default ListDisplayHeader;
