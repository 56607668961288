import {
  Box,
  Button,
  Grid,
  Radio,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../../theme/colors";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getModuleTime } from "../../../methods/getFormattedTime";
import dayjs from "dayjs";

const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

const DefineJobTimes = ({
  jobs,
  setJobs,
  phase,
  animationType,
  setAnimationType,
  setPhase,
  setSnackbarOpen,
  event,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(parseInt(event.startDate))
  );
  const [endDate, setEndDate] = useState(new Date(parseInt(event.endDate)));
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [interval, setInterval] = useState(60);

  const handleIntervalChange = (newValue) => {
    if (selectedIndex === -1) {
      setInterval(newValue);
    } else {
      setJobs((oldJobs) => {
        let newJobs = oldJobs.map((oldJob, oldIndex) => {
          if (oldIndex !== selectedIndex) {
            return oldJob;
          } else {
            if (!oldJob.isOverriden) {
              return {
                ...oldJob,
                isOverriden: true,
                interval: newValue,
                startDate,
                endDate,
              };
            } else return { ...oldJob, interval: newValue };
          }
        });
        return newJobs;
      });
    }
  };
  const displayedIntervalValue =
    selectedIndex === -1 || !jobs[selectedIndex].isOverriden
      ? interval
      : jobs[selectedIndex].interval;

  const handleStartDateChange = (newStartDate) => {
    const newDate = new Date(Math.floor(newStartDate));
    if (selectedIndex === -1) {
      setStartDate(newDate);
    } else {
      setJobs((oldJobs) => {
        let newJobs = oldJobs.map((oldJob, oldIndex) => {
          if (oldIndex !== selectedIndex) {
            return oldJob;
          } else {
            if (!oldJob.isOverriden) {
              return {
                ...oldJob,
                isOverriden: true,
                startDate: newDate,
                interval,
                endDate,
              };
            } else return { ...oldJob, startDate: newDate };
          }
        });
        return newJobs;
      });
    }
  };
  const displayedStartDate =
    selectedIndex === -1 || !jobs[selectedIndex].isOverriden
      ? new Date(startDate.getTime() - tzoffset)
      : new Date(jobs[selectedIndex].startDate.getTime() - tzoffset);

  const handleEndDateChange = (newEndDate) => {
    const newDate = new Date(Math.floor(newEndDate));

    if (selectedIndex === -1) {
      setEndDate(newDate);
    } else {
      setJobs((oldJobs) => {
        let newJobs = oldJobs.map((oldJob, oldIndex) => {
          if (oldIndex !== selectedIndex) {
            return oldJob;
          } else {
            if (!oldJob.isOverriden) {
              return {
                ...oldJob,
                isOverriden: true,
                startDate,
                interval,
                endDate: newDate,
              };
            } else return { ...oldJob, endDate: newDate };
          }
        });
        return newJobs;
      });
    }
  };
  const displayedEndDate =
    selectedIndex === -1 || !jobs[selectedIndex].isOverriden
      ? new Date(endDate.getTime() - tzoffset)
      : new Date(jobs[selectedIndex].endDate.getTime() - tzoffset);

  const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;

  function checkDates(
    savedStartDate,
    savedEndDate,
    eventStartDate,
    eventEndDate,
    jobName = "General settings"
  ) {
    if (savedStartDate < eventStartDate - twelveHoursInMilliseconds) {
      throw new Error(
        `Start date for ${jobName} cannot be more than 12 hours before the event's start date`
      );
    } else if (savedEndDate < savedStartDate) {
      throw new Error(`Start date for ${jobName} cannot be after the end date`);
    } else if (savedEndDate > eventEndDate + twelveHoursInMilliseconds) {
      throw new Error(
        `End date for ${jobName} cannot be more than 12 hours after the event's end date`
      );
    }
  }

  const handleBackPress = () => {
    setAnimationType("BACKWARD");
    setPhase("NAMES");
  };

  const handleNextPress = () => {
    setAnimationType("FORWARD");

    try {
      const finalJobs = jobs.map((oldJob) => {
        const savedStartDate = oldJob.isOverriden
          ? oldJob.startDate.getTime()
          : startDate.getTime();
        const savedEndDate = oldJob.isOverriden
          ? oldJob.endDate.getTime()
          : endDate.getTime();

        checkDates(
          savedStartDate,
          savedEndDate,
          Math.floor(event.startDate),
          Math.floor(event.endDate),
          oldJob.name
        );

        return {
          ...oldJob,
          startDate: oldJob.startDate
            ? new Date(oldJob.startDate.getTime())
            : new Date(startDate.getTime()),
          endDate: oldJob.endDate
            ? new Date(oldJob.endDate.getTime())
            : new Date(endDate.getTime()),
          jobStart:
            Math.floor(savedStartDate / 60000) -
            Math.floor(Math.floor(event.startDate) / 60000),
          jobEnd:
            Math.floor(savedEndDate / 60000) -
            Math.floor(Math.floor(event.startDate) / 60000),
          interval: oldJob.isOverriden ? oldJob.interval : interval,
        };
      });

      setJobs(finalJobs);
      setAnimationType("FORWARD");
      setPhase("WEIGHTS");
    } catch (err) {
      setSnackbarOpen({ type: "error", message: err.message });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 2,
        py: 2,
        borderRadius: 2,
        width: {
          sm: "60vw",
          xl: "45vw",
        },
        position: "absolute",
        alignSelf: "center",
        bgcolor: colors.secondaryBackground,
      }}
      className={
        phase === "TIMES"
          ? animationType === "FORWARD"
            ? "subscription-slide-in"
            : "subscription-slide-back-in"
          : phase !== "NAMES"
          ? animationType === "FORWARD"
            ? "subscription-slide-out"
            : "subscription-off-screen"
          : animationType !== "FORWARD"
          ? "subscription-slide-back-out"
          : "subscription-off-screen"
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h1"
          sx={{ fontWeight: 500, flex: 1, fontSize: "1.3em" }}
        >
          Define Job Times
        </Typography>
      </Box>
      <Box
        className="scroll-container"
        sx={{ mt: 2, maxHeight: "70dvh", overflow: "auto" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b style={{ color: colors.primaryText }}>
              {selectedIndex === -1 ? "All Jobs" : jobs[selectedIndex].name}
            </b>{" "}
            Run{selectedIndex === -1 ? "" : "s"} From
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
            <DateTimeField
              value={dayjs(displayedStartDate.toISOString().slice(0, 16))}
              name="startDate"
              label="Start Date"
              sx={{
                flex: 1,
                border: "none",
                color: "white",
                borderRadius: 1,
                bgcolor: colors.backgroundHighlight,
                caretColor: colors.primaryColor,
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
              onChange={handleStartDateChange}
            ></DateTimeField>
            <Typography variant="body2">to</Typography>
            <DateTimeField
              value={dayjs(displayedEndDate.toISOString().slice(0, 16))}
              name="endDate"
              label="End Date"
              sx={{
                flex: 1,
                border: "none",
                color: "white",
                borderRadius: 1,
                bgcolor: colors.backgroundHighlight,
                caretColor: colors.primaryColor,
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
              onChange={handleEndDateChange}
            ></DateTimeField>
          </Box>
        </LocalizationProvider>
        <Typography variant="body2">With an interval of</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
          <Slider
            value={
              typeof displayedIntervalValue === "number"
                ? displayedIntervalValue
                : 1
            }
            onChange={(event, newValue) => handleIntervalChange(newValue)}
            valueLabelDisplay="auto"
            step={5}
            max={120}
            min={10}
            sx={{
              "& .MuiSlider-valueLabel": {
                fontSize: "1em",
                fontWeight: "normal",
                top: -6,
                backgroundColor: "unset",
                color: colors.primaryText,
                fontFamily: "Poppins",
                "&:before": {
                  display: "none",
                },
                "& *": {
                  background: "transparent",
                  color: colors.primaryText,
                },
              },
            }}
          />
          <TextField
            value={displayedIntervalValue}
            size="small"
            onChange={(event) =>
              handleIntervalChange(parseInt(event.target.value))
            }
            onBlur={() => {
              if (displayedIntervalValue < 0) {
                handleIntervalChange(0);
              }
            }}
            inputProps={{
              step: 1,
              min: 0,
              max: 999,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
            sx={{
              bgcolor: colors.backgroundHighlight,
              borderRadius: 1,
              ml: 1,
            }}
          ></TextField>
          <Typography variant="body2">minutes</Typography>
        </Box>
        <Typography variant="body2" sx={{ mb: 0.5 }}>
          Override by Job
        </Typography>
        <Grid
          container
          sx={{
            bgcolor: colors.backgroundHighlight,
            borderRadius: 1,
            px: 1,
            py: 0.5,
            mb: 0.75,
          }}
        >
          <Grid item xs={5.5}>
            <Typography variant="body2" sx={{ fontSize: ".8em" }}>
              Job Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" sx={{ fontSize: ".8em" }}>
              Start
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" sx={{ fontSize: ".8em" }}>
              End
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="body2" sx={{ fontSize: ".8em" }}>
              Interval
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2" sx={{ fontSize: ".8em" }}>
              Edit
            </Typography>
          </Grid>
        </Grid>
        {[
          ...jobs,
          {
            name: "Overall Settings",
            startDate: startDate,
            endDate: endDate,
            interval: interval,
          },
        ].map((job, index) => {
          return (
            <Grid
              container
              key={index.toString()}
              sx={{
                bgcolor: colors.backgroundHighlight,
                borderRadius: 1,
                px: 1,
                py: 0.5,
                mb: 0.5,
                mt: index === jobs.length ? 1 : 0,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  justifyContent: "center",
                }}
                xs={5.5}
              >
                <Typography variant={index === jobs.length ? "body2" : "body1"}>
                  {job.name}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  justifyContent: "center",
                }}
                xs={2}
              >
                <Typography variant={index === jobs.length ? "body2" : "body1"}>
                  {job.isOverriden
                    ? getModuleTime(Math.floor(job.startDate))
                    : getModuleTime(Math.floor(startDate))}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  justifyContent: "center",
                }}
                xs={2}
              >
                <Typography variant={index === jobs.length ? "body2" : "body1"}>
                  {job.isOverriden
                    ? getModuleTime(Math.floor(job.endDate))
                    : getModuleTime(Math.floor(endDate))}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  justifyContent: "center",
                }}
                xs={1.5}
              >
                <Typography variant={index === jobs.length ? "body2" : "body1"}>
                  {job.isOverriden ? job.interval : interval}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  justifyContent: "center",
                }}
                xs={1}
              >
                <Radio
                  checked={
                    selectedIndex === -1
                      ? index === jobs.length
                      : index === selectedIndex
                  }
                  onClick={() =>
                    setSelectedIndex(index === jobs.length ? -1 : index)
                  }
                  sx={{ color: colors.primaryColor, pl: 0, py: 0 }}
                  color="primary"
                  size="small"
                />
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
          mt: 3,
        }}
      >
        <Button
          sx={{
            bgcolor: colors.backgroundHighlight,
            ":hover": {
              bgcolor: `${colors.backgroundHighlight}D6`,
            },
          }}
          variant={"contained"}
          startIcon={<ArrowBackOutlined sx={{ color: colors.primaryText }} />}
          onClick={handleBackPress}
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ flex: 1 }}
          startIcon={
            <ArrowForwardOutlined sx={{ color: colors.primaryText }} />
          }
          onClick={handleNextPress}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default DefineJobTimes;
