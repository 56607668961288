import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PublicHeader from "../../public/navigation/PublicHeader";
import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { API, DataStore } from "aws-amplify";
import { GuestInvite } from "../../models";
import "./Payments.css";
import { BeatLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";
import { colors } from "../../theme/colors";
import PaymentsDisplay from "./PaymentsDisplay";
import axios from "axios";
import {
  onCreateGuestInvite,
  onUpdateGuestInvite,
} from "../../graphql/subscriptions";
import { guestInviteByPaymentIntentID } from "../../graphql/queries";

const stripePublishableKey =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_LIVE_KEY
    : process.env.REACT_APP_STRIPE_TEST_KEY;

const stripePromise = loadStripe(stripePublishableKey);

const PaymentsWithStripe = () => {
  const { ticketLinkID } = useParams();
  const [event, setEvent] = useState(null);
  const [guestInvite, setGuestInvite] = useState(null);
  const [paymentIntentID, setPaymentIntentID] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [ticketLink, setTicketLink] = useState(null);

  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search);
    const paymentIntent = URLParams.get("payment_intent");
    const redirect_status = URLParams.get("redirect_status");

    if (paymentIntent && redirect_status === "succeeded") {
      setPaymentIntentID(paymentIntent);
    }

    (async () => {
      const fetchTicketLinkResponse = await axios.get(
        process.env.REACT_APP_TICKET_LINK_ENDPOINT,
        {
          params: {
            ticketLinkID: ticketLinkID,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { ticketLink, event } = fetchTicketLinkResponse.data;

      setTicketLink(ticketLink);
      setEvent(event);
    })();
  }, [ticketLinkID]);
  const fetchGuestInvite = async () => {
    let nextToken = null;
    let guestInvites = [];
    do {
      const unconfirmedGuestInviteResponse = await API.graphql({
        query: guestInviteByPaymentIntentID,
        variables: {
          paymentIntentID: paymentIntentID,
          filter: {
            eventID: {
              eq: event.id,
            },
            _deleted: {
              ne: true,
            },
            status: {
              eq: "VALID",
            },
          },
          nextToken,
        },
      });
      console.log(unconfirmedGuestInviteResponse);
      guestInvites = guestInvites.concat(
        unconfirmedGuestInviteResponse.data.guestInviteByPaymentIntentID.items
      );
      nextToken =
        unconfirmedGuestInviteResponse.data.guestInviteByPaymentIntentID
          .nextToken;
    } while (nextToken && !guestInvites.length);

    if (guestInvites.length) {
      setGuestInvite(guestInvites[0]);
    }
  };
  useEffect(() => {
    console.log(event, paymentIntentID);
    if (!event || !paymentIntentID) return;
    fetchGuestInvite();

    const onCreateGuestInviteSubscription = API.graphql({
      query: onCreateGuestInvite,
      variables: {
        filter: {
          paymentIntentID: {
            eq: paymentIntentID,
          },
        },
      },
    }).subscribe({ next: (eventData) => fetchGuestInvite() });
    const onUpdateGuestInviteSubscription = API.graphql({
      query: onUpdateGuestInvite,
      variables: {
        filter: {
          paymentIntentID: {
            eq: paymentIntentID,
          },
        },
      },
    }).subscribe({
      next: (eventData) => {
        console.log("SUBSCRIPTION FIRED");
        fetchGuestInvite();
      },
    });
    return () => {
      onCreateGuestInviteSubscription.unsubscribe();
      onUpdateGuestInviteSubscription.unsubscribe();
    };
  }, [event, paymentIntentID]);

  const pageTitle = `Purchase Event Ticket${event ? ` for ${event.name}` : ""}`;
  const pageDescription = `CLIQInvite's secure payment portal allows you to seamlessly purchase tickets for events.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/payments`;

  if (
    (!event?.ticketInformation?.price ||
      (event.maxTickets <= event.ticketsSold && event.maxTickets !== -1) ||
      !ticketLink ||
      (ticketLink.maxTickets <= ticketLink.ticketsSold && !paymentIntentID)) &&
    !guestInvite
  ) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={pageImageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <div
          className="page-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PublicHeader />
          <div
            className="profile-container"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {event === null ||
            ticketLink === null ||
            (!guestInvite && paymentIntentID) ? (
              <>
                <Typography variant="h2" sx={{ my: 3 }}>
                  Retrieving event data...
                </Typography>
                <BeatLoader
                  loading={true}
                  color={colors.primaryColor}
                  size={"1.5em"}
                />
              </>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    sx={{ textAlign: "center", fontSize: "2.5em" }}
                  >
                    {event === undefined
                      ? "Event Not Found"
                      : event?.ticketInformation?.price || ticketLink
                      ? "Sold Out"
                      : "Free Event"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      alignSelf: "center",
                      textAlign: "center",
                      my: 2,
                      fontSize: "1.3em",
                    }}
                  >
                    {event === undefined
                      ? "The event you're looking for cannot be found in our database. Please ensure your link matches the one sent to you, and if it does, contact the sender for more information."
                      : !event?.ticketInformation?.price || ticketLink
                      ? "Unfortunately, this event has sold out. Please contact the organization owner if you believe this is incorrect."
                      : "The event you're looking for does not require payments. Please contact the sender for more information."}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        currency: "usd",
        amount: event.ticketInformation.price * 100,
        fields: {
          billingDetails: { email: "auto", name: "auto" },
        },
      }}
    >
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <PaymentsDisplay
        event={event}
        guestInvite={guestInvite}
        paymentIntentID={paymentIntentID}
        setSnackbarOpen={setSnackbarOpen}
        ticketLink={ticketLink}
      />
    </Elements>
  );
};

export default PaymentsWithStripe;
