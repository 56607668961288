import { useAuthContext } from "../../contexts/AuthContext";
import { Account, ContactList } from "../../models";
import { DataStore } from "aws-amplify";
import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { colors } from "../../theme/colors";

const ChangeVenmo = ({ isVisible, close, account, setSnackbarOpen }) => {
  const { dbUser } = useAuthContext();

  const onConfirm = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let venmo = formData.get("venmo");
    // remove @ if it exists at the start
    venmo = venmo.startsWith("@") ? venmo.slice(1) : venmo;
    // RegEx pattern to check valid venmo account (5-16 alphanumeric characters, periods, underscores)
    const pattern = /^[a-zA-Z0-9_.]{5,30}$/;

    if (!venmo || !pattern.test(venmo)) {
      setSnackbarOpen({
        type: "error",
        message: "Please enter a valid Venmo username.",
      });
      return;
    }

    venmo = "@" + venmo; // append @ at the start

    const accountToBeUpdated = await DataStore.query(
      Account,
      dbUser.organizationAccountId
    );
    await DataStore.save(
      Account.copyOf(accountToBeUpdated, (updated) => {
        updated.venmo = venmo;
      })
    );
    close();
  };

  return (
    <Dialog open={isVisible} onClose={close}>
      <form onSubmit={onConfirm}>
        <DialogTitle>Account Venmo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a valid venmo account name
          </DialogContentText>
          {account && account.venmo ? (
            <DialogContentText sx={{ fontWeight: 600 }}>
              Current account: {account.venmo}
            </DialogContentText>
          ) : null}

          <TextField
            autoFocus
            margin="dense"
            id="venmo"
            name="venmo"
            label="Venmo"
            fullWidth
            variant="standard"
            sx={{ mt: 3 }}
            inputProps={{ maxLength: 20 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} sx={{ color: colors.secondaryText }}>
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeVenmo;
