import {
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { Cancel, Search } from "@mui/icons-material";
import { useState } from "react";
import useDynamicDelay from "../../hooks/useDynamicDelay";
import { useAuthContext } from "../../contexts/AuthContext";

const MemberFiltersDisplay = ({
  selectedFilters,
  setSelectedFilters,
  filteredMembers,
}) => {
  const [searchText, setSearchText] = useState("");
  const [addLabelAnchorEl, setAddLabelAnchorEl] = useState(null);

  const { dbUser } = useAuthContext();

  useDynamicDelay(
    () => {
      setSelectedFilters((old) => {
        let tempFilters = [];
        for (const filter of old) {
          if (filter.type !== "NAME") {
            tempFilters.push(filter);
          }
        }
        if (searchText) {
          tempFilters.push({ type: "NAME", filter: searchText.toLowerCase() });
        }
        return tempFilters;
      });
    },
    300,
    [searchText]
  );

  let displayedRoleFilters = [];
  let unusedRoles = new Set([]);
  if (dbUser?.createdRoles) {
    unusedRoles = new Set([...dbUser?.createdRoles, "No Group"] || []);
    for (const selectedFilter of selectedFilters) {
      if (selectedFilter.type === "ROLE") {
        unusedRoles.delete(selectedFilter.filter);
        displayedRoleFilters.push({
          displayText: selectedFilter.filter,
          onDelete: () => {
            setSelectedFilters((old) => {
              return old.filter(
                (oldFilter) =>
                  oldFilter.type !== selectedFilter.type ||
                  oldFilter.filter !== selectedFilter.filter
              );
            });
          },
        });
      }
    }
  }

  const handleClick = (event) => {
    setAddLabelAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAddLabelAnchorEl(null);
  };

  const loading = Boolean(!filteredMembers);

  return (
    <Box
      style={{ flex: 1, backgroundColor: colors.secondaryBackground }}
      sx={{
        p: 1,
        pb: 2,
        borderRadius: 2,
        mb: ".5em",
      }}
      className="unstyled-card"
    >
      {loading ? (
        <Skeleton
          variant="rounded"
          sx={{ fontSize: "1.4em", marginBottom: "8px" }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <h1 style={{ margin: 0, fontSize: "1.4em", textAlign: "left" }}>
            Filters
          </h1>
          <Button
            color="error"
            onClick={() => {
              setSelectedFilters([]);
            }}
          >
            Clear
          </Button>
        </div>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          sx={{ fontSize: "2em", marginBottom: "8px" }}
        />
      ) : (
        <div style={{ marginBottom: 8 }}>
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: "0.85em",
            }}
          >
            Groups
          </p>
          <div style={{ display: "flex", gap: ".25em" }}>
            {displayedRoleFilters.map((filter) => (
              <Chip
                key={filter.displayText}
                label={filter.displayText}
                variant="contained"
                sx={{
                  color: colors.secondaryText,
                  backgroundColor: colors.backgroundHighlight,
                }}
                size="medium"
                onDelete={filter.onDelete}
                deleteIcon={<Cancel color="primary" />}
              ></Chip>
            ))}
            <Button size="small" onClick={handleClick}>
              Add Group Filter
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={addLabelAnchorEl}
              keepMounted
              open={Boolean(addLabelAnchorEl)}
              onClose={handleClose}
            >
              {Array.from(unusedRoles).map((role) => {
                return (
                  <MenuItem
                    onClick={() => {
                      setSelectedFilters((old) => {
                        if (
                          old.some(
                            (o) => o.filter === role && o.type === "ROLE"
                          )
                        ) {
                          return old;
                        } else {
                          return [...old, { type: "ROLE", filter: role }];
                        }
                      });
                      handleClose();
                    }}
                    key={role}
                    sx={{
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "0.85em",
                      ":hover": {
                        backgroundColor: "#030303",
                      },
                    }}
                  >
                    {role}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          sx={{ fontSize: "2em", marginBottom: "6px" }}
        />
      ) : (
        <div style={{ marginBottom: 8 }}>
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: "0.85em",
            }}
          >
            Contact name
          </p>
          <Box
            sx={{
              p: 0.5,
              display: "flex",
              alignItems: "center",
              backgroundColor: colors.defaultBackground,
              borderRadius: 1,
            }}
          >
            <Search
              sx={{
                fontSize: "1.3em",
                marginRight: 1,
                color: colors.secondaryText,
              }}
            />
            <TextField
              value={searchText}
              onChange={(e) => {
                if (e.target.value !== searchText) {
                  setSearchText(e.target.value);
                }
              }}
              placeholder="Search..."
              fullWidth
              variant="standard"
            />
          </Box>
        </div>
      )}
      {loading ? (
        <Skeleton sx={{ width: "50%", fontSize: ".8em" }} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: ".8em",
              textAlign: "right",
            }}
          >
            <b style={{ color: colors.primaryColor }}>
              {filteredMembers.length}
            </b>{" "}
            members match your filters
          </p>
        </div>
      )}
    </Box>
  );
};

export default MemberFiltersDisplay;
