import { API } from "aws-amplify";

const updateInvite = /* GraphQL */ `
  mutation UpdateInvite($id: ID!, $invitesGiven: Int!, $_version: Int!) {
    updateInvite(
      input: { id: $id, invitesGiven: $invitesGiven, _version: $_version }
    ) {
      id
    }
  }
`;

export const distributeMemberGuestInvites = async ({
  MemberModules,
  invites,
  setSnackbarOpen,
  navigate,
  setSent,
}) => {
  let shouldSetSnackbar = false;
  let updatePromises = MemberModules.map((MemberModule) => {
    const matchingInvite = invites.find(
      (invite) => invite.userId === MemberModule.id
    );

    if (matchingInvite) {
      let finalInvitesGiven;
      if (matchingInvite.invitesGiven === parseInt(MemberModule.num)) {
        //invite already has the appropriate number of guest invites
        return null;
      } else if (matchingInvite.invitesGiven !== parseInt(MemberModule.num)) {
        //reduce extra invites to the specified amount on the module
        finalInvitesGiven = parseInt(MemberModule.num);
        if (matchingInvite.invitesUsed > parseInt(MemberModules.num)) {
          //invite has already distributed more guest invites than organization is attempting to reduce their guest invites to. Reduce extra invites to number already distributed.
          finalInvitesGiven = matchingInvite.invitesUsed;
        }
      }
      shouldSetSnackbar = true;
      return API.graphql({
        query: updateInvite, //custom updateInvite that allows us to only pass these inputs
        variables: {
          id: matchingInvite.id,
          invitesGiven: finalInvitesGiven,
          _version: matchingInvite._version,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } else {
      return null;
    }
  });

  updatePromises = updatePromises.filter(Boolean);
  try {
    await Promise.all(updatePromises);
    shouldSetSnackbar &&
      setSnackbarOpen({ message: "Successfully sent invites" });

    setSent(true);
    navigate("/dashboard");
  } catch (err) {
    setSent(true);
    setSnackbarOpen({
      message:
        "Unanticipated error while updating invites. Please try again later",
    });
  }
};
