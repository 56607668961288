import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../methods/calculateTakeHome";
import { getModuleDate, getModuleTime } from "../../methods/getFormattedTime";
import { colors } from "../../theme/colors";
import { Grid } from "@mui/material";

const EventPaymentModule = ({ event }) => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      columns={10}
      alignItems="center"
      sx={{
        backgroundColor: colors.backgroundHighlight,
        p: 1,
        borderRadius: 1,
        mb: ".3em",
        cursor: "pointer",

        transition: "opacity 500ms ease-in-out",
        ":hover": {
          opacity: 0.5,
        },
      }}
      onClick={() => navigate(`/insights/event/${event.id}`)}
    >
      <Grid item xs={4}>
        <h4
          style={{
            margin: 0,
            color: colors.primaryText,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {event.name}
        </h4>
        <p
          style={{ margin: 0, color: colors.secondaryText, fontSize: ".85em" }}
        >
          {getModuleDate(event.startDate).substring(
            0,
            getModuleDate(event.startDate).indexOf(",")
          )}
          , {getModuleTime(event.startDate)}
        </p>
      </Grid>
      <Grid item xs={3}>
        <h3
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: "1em",
            textAlign: "center",
          }}
        >
          ${formatMoney(event.revenue * 100)}
        </h3>
      </Grid>
      <Grid item xs={3}>
        <h3
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: "1em",
            textAlign: "center",
          }}
        >
          {event.ticketsSold}
        </h3>
      </Grid>
    </Grid>
  );
};

export default EventPaymentModule;
