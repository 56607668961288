import { useNavigate } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import "./Auth.css";
import { collegeList } from "../../collegeList";
import {
  Alert,
  Box,
  Button,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import PublicHeader from "../../public/navigation/PublicHeader";
import * as mutations from "../../graphql/mutations";
import { useAuthContext } from "../../contexts/AuthContext";
import { Helmet } from "react-helmet-async";
import {
  CelebrationOutlined,
  EmailOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import axios from "axios";

function generatePassword() {
  // Define the characters to choose from
  var letters = "abcdefghijklmnopqrstuvwxyz";
  var numbers = "0123456789";
  var specialChars = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

  // Initialize the password with one character of each type
  var password = [
    getRandomCharacter(letters),
    getRandomCharacter(numbers),
    getRandomCharacter(letters.toUpperCase()),
    getRandomCharacter(specialChars),
  ];

  // Generate the remaining characters
  for (var i = 0; i < 12; i++) {
    // Choose a random type of character
    var charType = getRandomItem([
      letters,
      numbers,
      letters.toUpperCase(),
      specialChars,
    ]);
    // Add a random character of the chosen type
    password.push(getRandomCharacter(charType));
  }

  // Shuffle the characters to make the password more random
  password.sort(function () {
    return 0.5 - Math.random();
  });

  // Convert the array to a string
  password = password.join("");

  return password;
}

// Helper function to get a random character from a given string
function getRandomCharacter(characters) {
  return characters.charAt(Math.floor(Math.random() * characters.length));
}

// Helper function to get a random item from an array
function getRandomItem(array) {
  return array[Math.floor(Math.random() * array.length)];
}

const SignUp = () => {
  const navigate = useNavigate();

  const [school, setSchool] = useState(collegeList[0]);
  const [stage, setStage] = useState("EMAIL"); //EMAIL, VERIFY, INFO, DONE
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const { authUser, dbUser } = useAuthContext();

  const handleSignUp = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (loading) {
      return;
    } else if (stage === "EMAIL") {
      try {
        setLoading(true);
        const password = generatePassword();
        await Auth.signUp({
          username: data.get("email"),
          password,
          attributes: {
            "custom:type": "organization" /* Similar to user type */,
            email: data.get("email"),
            name: "Unauthed User",
          },
        });
        const tempCognitoUser = await Auth.signIn(data.get("email"));
        setCognitoUser(tempCognitoUser);

        setLoading(false);
        setStage("VERIFY");
      } catch (err) {
        setLoading(false);
        const displayedErrorMessage = err.message || err;
        setSnackbarOpen({
          type: "error",
          message: displayedErrorMessage,
          action: displayedErrorMessage.includes("already exists") ? (
            <Button
              size="small"
              color="inherit"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          ) : null,
        });
      }
    } else if (stage === "VERIFY") {
      try {
        setLoading(true);
        const challengeResult = await Auth.sendCustomChallengeAnswer(
          cognitoUser,
          data.get("code").replace(/[^0-9]/g, "")
        );
        setLoading(false);
        if (challengeResult.challengeName) {
          let attemptsLeft = parseInt(
            challengeResult.challengeParam.attemptsLeft
          );

          setSnackbarOpen({
            type: "error",
            message: `The code you entered is incorrect. ${attemptsLeft} attempts remaining.`,
          });
        }
      } catch (error) {
        setLoading(false);
        const displayedErrorMessage = error.message || error;
        setSnackbarOpen({
          type: "error",
          message: displayedErrorMessage,
        });
      }
    } else if (stage === "INFO") {
      try {
        setLoading(true);
        const authUser = await Auth.currentAuthenticatedUser();
        const name = data.get("orgname");
        await Auth.updateUserAttributes(authUser, {
          name,
        });
        const attributes = authUser.attributes;
        const token = authUser?.signInUserSession.idToken.jwtToken;
        const url = process.env.REACT_APP_MANIPULATE_ORGANIZATION_ENDPOINT;
        const body = {
          isLiveMode: true,
          attributes: {
            ...attributes,
            name,
            school: school.school,
            shortSchool: school.shortSchool,
            id: attributes.sub,
          },
        };
        await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        setLoading(false);
        window.location.reload();
      } catch (error) {
        setLoading(false);

        setSnackbarOpen({
          type: "error",
          message: "Error saving account information. Please try again later.",
        });
      }
    }
  };

  useEffect(() => {
    if (authUser && !dbUser) {
      setStage("INFO");
    } else if (authUser) {
      // navigate("/dashboard");
    }
  }, [authUser, dbUser]);

  const pageTitle = `Sign Up • CLIQInvite`;
  const pageDescription = `Join the CLIQInvite community today. Sign up to revolutionize your event management experience. Streamline your social events, philanthropy, and recruitment with our secure invites, real-time tracking, and detailed analytics.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/signup`;

  return (
    <div
      className="page-container container"
      style={{ backgroundColor: colors.defaultBackground }}
    >
      <PublicHeader />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <Box
        className={`card ${
          stage === "VERIFY" || stage === "INFO" ? "subscription-slide-out" : ""
        }`}
        sx={{
          fontFamily: "Poppins",
          fontWeight: 600,
          pb: 2,
          px: { xs: 2, md: 2.5, lg: 3, xl: 3 },
          width: {
            xs: "calc(94dvw - 32px)",
            sm: "70dvw",
            md: "60dvw",
            lg: "45dvw",
            xl: "35dvw",
          },
          position: "absolute",
        }}
        component="form"
        onSubmit={handleSignUp}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xl: "3em", xs: "2.5em" },
            marginTop: "1.5rem",
            marginBottom: ".5rem",
            textAlign: "center",
            lineHeight: "115%",
          }}
        >
          Organization Sign Up
        </Typography>
        <Typography variant="body2" sx={{ mb: "1.5em", textAlign: "center" }}>
          Not an organization?{" "}
          <b
            className="link"
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/cli%CF%89/id1664767480",
                "_blank",
                "noreferrer"
              )
            }
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            Download the CLIQInvite app.
          </b>
        </Typography>

        <TextField
          id="email"
          label="email"
          name="email"
          variant="outlined"
          required
          InputLabelProps={{ style: { color: colors.primaryColor } }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: `${colors.primaryColor}20`,
            },
          }}
          sx={{ caretColor: "white", mb: "1em", fontSize: "14px" }}
          fullWidth
        />

        <Button
          variant="contained"
          fullWidth
          id={"login"}
          type="submit"
          sx={{ my: 1 }}
          startIcon={<EmailOutlined sx={{ color: "white" }} />}
        >
          {loading ? "Loading..." : "Get OTP"}
        </Button>

        <Typography
          variant="body2"
          className="link"
          id={"navLogin"}
          onClick={() => navigate("/login")}
        >
          Back to Sign In
        </Typography>
      </Box>
      {stage === "VERIFY" ? (
        <Box
          component="form"
          onSubmit={handleSignUp}
          className={`card slide-in`}
          sx={{
            fontFamily: "Poppins",
            fontWeight: 600,
            pb: 2,
            px: { xs: 2, md: 2.5, lg: 3, xl: 3 },
            width: {
              xs: "calc(94dvw - 32px)",
              sm: "70dvw",
              md: "60dvw",
              lg: "45dvw",
              xl: "35dvw",
            },
            position: "absolute",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xl: "3em", xs: "2.5em" },
              marginTop: "1.5rem",
              marginBottom: ".5rem",
              textAlign: "center",
              lineHeight: "115%",
            }}
          >
            Verify Email
          </Typography>
          <Typography variant="body2" sx={{ mb: "1.5em", textAlign: "center" }}>
            Enter the verification code sent to your email
          </Typography>
          <TextField
            id="password"
            type="code"
            label="code"
            name="code"
            variant="outlined"
            required
            InputLabelProps={{ style: { color: colors.primaryColor } }}
            InputProps={{
              style: {
                color: "white",
                backgroundColor: `${colors.primaryColor}20`,
              },
            }}
            sx={{ caretColor: "white", mb: "1em", fontSize: "14px" }}
            fullWidth
          />
          <Button
            variant="contained"
            fullWidth
            id={"login"}
            type="submit"
            sx={{ my: 1 }}
            startIcon={<VerifiedOutlined sx={{ color: "white" }} />}
          >
            {loading ? "Loading..." : "Verify"}
          </Button>

          <Typography variant="body2">
            Don't see your code?{" "}
            <b
              className="link"
              style={{ margin: 0 }}
              onClick={async (e) => {
                e.preventDefault();
                const tempCognitoUser = await Auth.signIn(cognitoUser.username);
                setCognitoUser(tempCognitoUser);
              }}
            >
              Resend code.
            </b>
          </Typography>
        </Box>
      ) : null}
      {stage === "INFO" ? (
        <Box
          component="form"
          onSubmit={handleSignUp}
          className={`card slide-in`}
          sx={{
            fontFamily: "Poppins",
            fontWeight: 600,
            pb: 2,
            px: { xs: 2, md: 2.5, lg: 3, xl: 3 },
            width: {
              xs: "calc(94dvw - 32px)",
              sm: "70dvw",
              md: "60dvw",
              lg: "45dvw",
              xl: "35dvw",
            },
            position: "absolute",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xl: "3em", xs: "2.5em" },
              marginTop: "1.5rem",
              marginBottom: ".5rem",
              textAlign: "center",
              lineHeight: "115%",
            }}
          >
            Finish Setup
          </Typography>
          <Typography variant="body2" sx={{ mb: "1.5em", textAlign: "center" }}>
            Enter your organization's information to complete account setup
          </Typography>
          <TextField
            id="name"
            type="name"
            label="organization name"
            name="orgname"
            variant="outlined"
            required
            InputLabelProps={{ style: { color: colors.primaryColor } }}
            InputProps={{
              style: {
                color: "white",
                backgroundColor: `${colors.primaryColor}20`,
              },
            }}
            sx={{ caretColor: "white", mb: "1em", fontSize: "14px" }}
            fullWidth
          />
          <TextField
            select
            required
            label="School Name"
            style={{ width: "100%" }}
            InputLabelProps={{ style: { color: colors.primaryColor } }}
            InputProps={{
              style: {
                color: "white",
                backgroundColor: `${colors.primaryColor}20`,
              },
            }}
            sx={{ caretColor: "white", mb: "1em", fontSize: "14px" }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    bgcolor: colors.secondaryBackground,
                  },
                },
              },
            }}
            value={school}
          >
            {collegeList
              .sort((a, b) => a.school.localeCompare(b.school))
              .map((college) => {
                return (
                  <MenuItem
                    dense
                    onClick={() => setSchool(college)}
                    key={college.schoolID}
                    value={college}
                    sx={{
                      fontFamily: "Poppins",
                      color:
                        school?.schoolID === college.schoolID
                          ? colors.primaryColor
                          : "black",
                    }}
                  >
                    {college.school}
                  </MenuItem>
                );
              })}
          </TextField>
          <Button
            variant="contained"
            fullWidth
            id={"login"}
            type="submit"
            sx={{ my: 1 }}
            startIcon={<CelebrationOutlined sx={{ color: "white" }} />}
          >
            {loading ? "Loading..." : "Create Account"}
          </Button>
        </Box>
      ) : null}
    </div>
  );
};

export default SignUp;
