import { Box, Typography, Button, TextField } from "@mui/material";
import Logo from "../../components/Logo";
import { PhoneOutlined } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const EnterPhoneNumber = ({
  handleLogin,
  phone,
  handleChangePhone,
  loading,
  stage,
}) => {
  const navigate = useNavigate();
  const [shouldRenderPhone, setShouldRenderPhone] = useState(true);

  useEffect(() => {
    if (stage === "VERIFY") {
      setTimeout(() => {
        setShouldRenderPhone(false);
      }, 500);
    }
  }, [stage]);

  if (!shouldRenderPhone) {
    return null;
  }
  return (
    <Box>
      <Logo
        height={"1.2rem"}
        darkMode={true}
        style={{ marginBottom: "1.5rem", cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      <Box component="form" onSubmit={handleLogin}>
        <Typography
          variant="h2"
          sx={{
            fontSize: "1.3rem",
            color: colors.defaultBackground,
          }}
        >
          Enter phone number
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: "1.75rem",
            color: colors.backgroundHighlight,
            fontSize: ".8rem",
          }}
        >
          Enter your phone number to login or create your CLIQInvite account
        </Typography>

        <TextField
          id="phone"
          label="phone"
          name="phone"
          variant="filled"
          value={phone}
          onChange={(event) => handleChangePhone(event.target.value)}
          required
          InputLabelProps={{
            style: { color: colors.backgroundHighlight },
          }}
          InputProps={{
            style: {
              color: `${colors.defaultBackground} !important`,
              fontSize: "1rem",
            },
          }}
          sx={{
            caretColor: colors.primaryColor,
            mb: "2.75rem",
            fontSize: "14px",
          }}
          fullWidth
        />

        <Button
          variant="contained"
          fullWidth
          id={"login"}
          type="submit"
          sx={{ mb: "2.25rem" }}
          startIcon={<PhoneOutlined sx={{ color: "white" }} />}
        >
          {loading ? "Loading..." : stage === "PHONE" ? "Get OTP" : "Login"}
        </Button>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="body2"
            style={{
              fontSize: ".8rem",
              color: colors.backgroundHighlight,
            }}
          >
            @ 2024 CLIQInvite
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: ".8rem",
              color: colors.backgroundHighlight,
              cursor: "pointer",
            }}
            onClick={() => navigate("/legal")}
          >
            Privacy
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EnterPhoneNumber;
