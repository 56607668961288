export const subscriptionPlans = [
  {planType: 'Free',
  maxEvents: 1,
  maxContactLists: 1,
  memberAnalytics: false,
  customReports: false,
  contactsPresentations: false,
  IDVerifiedEvents: false,
  customColors: false,
  searchPrioritization: false,
  VIPVerifiedBadge: false,
  downloadableEventReports: false,
  
},
  {
    planType: "Pro",
    maxEvents: 0,
    maxContactLists: 3,
    memberAnalytics: false,
    memberAnalytics: false,
    customReports: false,
    contactsPresentations: false,
    IDVerifiedEvents: false,
    customColors: false,
    searchPrioritization: false,
    VIPVerifiedBadge: false,
    downloadableEventReports: false,
  },
  {
    planType: "Premium",
    maxEvents: Infinity,
    maxContactLists: Infinity,
    memberAnalytics: true,
    customReports: true,
    contactsPresentations: true,
    IDVerifiedEvents: true,
    customColors: false,
    searchPrioritization: false,
    VIPVerifiedBadge: false,
    downloadableEventReports: false,
  },
  {
    planType: "VIP",
    maxEvents: Infinity,
    maxContactLists: Infinity,
    memberAnalytics: true,
    customReports: true,
    contactsPresentations: true,
    IDVerifiedEvents: true,
    customColors: true,
    searchPrioritization: true,
    VIPVerifiedBadge: true,
    downloadableEventReports: true,
  },
];
