import { Box, Button, Skeleton } from "@mui/material";
import { colors } from "../../theme/colors";
import {
  ArrowForwardOutlined,
  QuestionAnswerOutlined,
} from "@mui/icons-material";
import ContactListModule from "./ContactListModule";

const RecentlyEditedListsDisplay = ({
  displayedLists,
  setAllListsDialogOpen,
  loading,
  setSnackbarOpen,
}) => {
  const renderItem = (item) => (
    <ContactListModule
      setSnackbarOpen={setSnackbarOpen}
      list={item}
      key={item.id}
    />
  );

  return (
    <Box className="unstyled-card" sx={{ p: ".5em 1em" }}>
      {loading ? (
        <Skeleton sx={{ fontSize: "1.2em" }} />
      ) : (
        <h1
          style={{
            margin: 0,
            fontSize: "1.2em",
          }}
        >
          Recently Edited
        </h1>
      )}
      {loading ? (
        <Skeleton sx={{ fontSize: ".8em", mb: ".8em" }} />
      ) : (
        <p
          style={{
            fontSize: ".8rem",
            color: colors.secondaryText,
            margin: 0,
            marginBottom: ".8em",
          }}
        >
          Lists marked with an icon are owned by another user. Hover over the
          icon to determine who owns it and your permissions status.
        </p>
      )}
      {loading ? (
        <>
          {Array.from({ length: 3 }, (_, i) => (
            <Skeleton
              variant="rounded"
              sx={{ fontSize: "2.5em", mb: 1 }}
              key={i.toString()}
            />
          ))}
          <Skeleton variant="rounded" sx={{ fontSize: "1.5em" }} />
        </>
      ) : displayedLists.length ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {displayedLists.map(renderItem)}
          <Button
            variant="contained"
            sx={{
              flex: 1,
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
              mt: 1,
            }}
            startIcon={<ArrowForwardOutlined sx={{ color: "white" }} />}
            onClick={() => setAllListsDialogOpen(true)}
          >
            See All Lists
          </Button>
        </div>
      ) : (
        <p
          style={{
            alignSelf: "flex-start",
            fontSize: ".8em",
            margin: 0,
            textAlign: "left",
            color: colors.secondaryText,
          }}
        >
          You must first create a list or have a list shared with you to view
          and interact with your recently edited.
        </p>
      )}
      {loading ? null : !displayedLists.length ? (
        <div style={{ display: "flex", marginTop: ".8em", gap: "1em" }}>
          <Button
            variant="contained"
            sx={{
              flex: 1,
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
            }}
            startIcon={<QuestionAnswerOutlined sx={{ color: "white" }} />}
            onClick={() =>
              window.open(
                "https://www.cliqinvite.com/tutorials",
                "_blank",
                "noreferrer"
              )
            }
          >
            Learn More
          </Button>
        </div>
      ) : null}
    </Box>
  );
};

export default RecentlyEditedListsDisplay;
