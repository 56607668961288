import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import PublicHeader from "../../public/navigation/PublicHeader";
import { Box, Skeleton } from "@mui/material";
import SubscriptionOptions from "./SubscriptionOptions";
import PaymentsProgressBar from "./PaymentsProgressBar";
import SubscriptionPaymentsForm from "./SubscriptionPaymentsForm";
import SubscriptionConfirmPayment from "./SubscriptionConfirmPayment";

const SubscriptionPaymentsModule = ({
  initialPlanType,
  setSnackbarOpen,
  loading,
}) => {
  const [phase, setPhase] = useState("OPTIONS"); //"OPTIONS", "INFORMATION", "CONFIRM"
  const [planType, setPlanType] = useState(initialPlanType);
  const [totalCost, setTotalCost] = useState(0);
  const [billingCycle, setBillingCycle] = useState("Month");
  const [animationType, setAnimationType] = useState("FORWARD"); //"FORWARD", "BACKWARD"
  const [totalMembers, setTotalMembers] = useState(50);
  const [clientSecret, setClientSecret] = useState(null);
  const [memberCost, setMemberCost] = useState(0);
  const [chapterCost, setChapterCost] = useState(0);

  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState(false);

  return (
    <div
      className="page-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "3em",
      }}
    >
      <PublicHeader />
      <div
        className="page-size-medium"
        style={{
          paddingTop: "7em",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "5em",
        }}
      >
        {loading ? (
          <Skeleton sx={{ fontSize: "2.4em", mb: ".5em" }} />
        ) : (
          <h1
            style={{
              margin: 0,
              fontSize: "2.4em",
              textAlign: "center",
              marginBottom: ".5em",
            }}
          >
            Subscribe to a {planType} Plan
          </h1>
        )}
        {loading ? (
          <Skeleton variant="rounded" sx={{ fontSize: "5em" }} />
        ) : (
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
                lg: "65%",
                xl: "50%",
              },
              alignSelf: "center",
            }}
          >
            <PaymentsProgressBar
              currentStage={
                phase === "OPTIONS" ? 0 : phase === "INFORMATION" ? 1 : 2
              }
            />
          </Box>
        )}
        {loading ? (
          <Skeleton variant="rounded" height={"40dvh"} sx={{ mt: "5em" }} />
        ) : null}
        <Box
          sx={{
            position: "relative",
            alignSelf: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {(!loading && phase === "OPTIONS") ||
          (!loading &&
            phase === "INFORMATION" &&
            animationType === "FORWARD") ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: {
                  sm: "60vw",
                  xl: "45vw",
                },
                alignSelf: "center",
                position: "absolute",
              }}
              className={
                phase === "OPTIONS"
                  ? animationType === "FORWARD"
                    ? "subscription-slide-in"
                    : "subscription-slide-back-in"
                  : "subscription-slide-out"
              }
            >
              <SubscriptionOptions
                planType={planType}
                setPlanType={setPlanType}
                setPhase={setPhase}
                setTotalCost={setTotalCost}
                billingCycle={billingCycle}
                setBillingCycle={setBillingCycle}
                setAnimationType={setAnimationType}
                totalMembers={totalMembers}
                setTotalMembers={setTotalMembers}
                setSnackbarOpen={setSnackbarOpen}
                setClientSecret={setClientSecret}
                memberCost={memberCost}
                chapterCost={chapterCost}
                setMemberCost={setMemberCost}
                setChapterCost={setChapterCost}
              />
            </Box>
          ) : null}
          {loading ? null : (
            <Box
              sx={{
                width: {
                  sm: "60vw",
                  xl: "45vw",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignSelf: "center",
                position: "absolute",
              }}
              className={
                phase === "INFORMATION"
                  ? animationType === "FORWARD"
                    ? "subscription-slide-in"
                    : "subscription-slide-back-in"
                  : animationType === "FORWARD"
                  ? "subscription-slide-out"
                  : "subscription-slide-back-out"
              }
            >
              <SubscriptionPaymentsForm
                setAnimationType={setAnimationType}
                submitting={submitting}
                stripe={stripe}
                setPhase={setPhase}
              />
            </Box>
          )}
          {(!loading &&
            phase === "INFORMATION" &&
            animationType === "BACKWARD") ||
          (!loading && phase === "CONFIRM") ? (
            <Box
              sx={{
                width: {
                  sm: "60vw",
                  xl: "45vw",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignSelf: "center",
                position: "absolute",
              }}
              className={
                phase === "CONFIRM"
                  ? animationType === "FORWARD"
                    ? "subscription-slide-in"
                    : "subscription-slide-back-in"
                  : animationType === "FORWARD"
                  ? "subscription-slide-out"
                  : "subscription-slide-back-out"
              }
            >
              <SubscriptionConfirmPayment
                setAnimationType={setAnimationType}
                submitting={submitting}
                stripe={stripe}
                setPhase={setPhase}
                planType={planType}
                billingCycle={billingCycle}
                totalCost={totalCost}
                totalMembers={totalMembers}
                elements={elements}
                setSnackbarOpen={setSnackbarOpen}
                clientSecret={clientSecret}
                chapterCost={chapterCost}
                memberCost={memberCost}
              />
            </Box>
          ) : null}
        </Box>
      </div>
    </div>
  );
};

export default SubscriptionPaymentsModule;
