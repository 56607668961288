import { Box, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";

const RushTestimonialCard = ({ testimonial }) => {
  return (
    <Box
      className="default-page-size"
      sx={{
        pb: 8,
        borderRadius: 8,
        display: "flex",
        flexDirection: { sm: "row", xs: "column" },
        position: "relative",
        alignItems: { sm: "center", xs: "flex-start" },
        gap: 3,
      }}
    >
      <Box
        component={"img"}
        src={require("../../../assets/Rush_Testimonial_Photo.png")}
        sx={{
          width: 150,
          objectFit: "contain",
          borderRadius: 150,
          zIndex: 1,
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
        }}
      />

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          zIndex: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: colors.backgroundHighlight,
            fontSize: {
              xs: "1.1rem",
              sm: "1.25rem",
              lg: "1.35rem",
              xl: "1.35rem",
            },
            letterSpacing: "-.2px",
            fontStyle: "italic",
            mb: 3,
          }}
        >
          "{testimonial.quote}"
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: colors.defaultBackground,
            fontSize: "1rem",
            fontWeight: 600,
          }}
        >
          {testimonial.author}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: colors.backgroundHighlight,
            fontSize: ".8rem",
          }}
        >
          {testimonial.authorDescription}
        </Typography>
      </Box>
    </Box>
  );
};

export default RushTestimonialCard;
