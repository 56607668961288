import {
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { Cancel, Help, Search } from "@mui/icons-material";
import { useState } from "react";
import useDynamicDelay from "../../hooks/useDynamicDelay";
import GroupSelectModal from "./GroupSelectModal";
import pptxgen from "pptxgenjs";
import { getCliqScore } from "../../methods/getCliqScore";
import { sum } from "../../methods/sum";
import { DataStore, Storage } from "aws-amplify";
import { Event, Score } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";

function getCurrentDate() {
  const date = new Date();
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${month}/${day}`;
}

const ContactFiltersDisplay = ({
  eventFilters,
  setSelectedFilters,
  selectedEvent,
  setSelectedEvent,
  filteredContacts,
  listEvents,
  metricFilters,
  contactList,
  listInvites,
  setSnackbarOpen,
  customizeOptions,
  loading,
}) => {
  const [searchText, setSearchText] = useState("");
  const [addLabelAnchorEl, setAddLabelAnchorEl] = useState(null);
  const [metricFilterModalVisible, setMetricFilterModalVisible] =
    useState(false);
  const { dbUser } = useAuthContext();

  const status =
    dbUser?.id === contactList?.creatorID
      ? "OWNER"
      : contactList?.coOwners?.includes(dbUser.id)
      ? "COOWNER"
      : "EDITOR";

  const downloadPowerPoint = async () => {
    if (filteredContacts.size > 100) {
      setSnackbarOpen({
        type: "error",
        message:
          "Presentations allow for maximum 100 contacts. Please adjust your filters to reduce the number of filtered contacts to below 100.",
      });
      return;
    }
    if (status !== "OWNER" && status !== "COOWNER") {
      setSnackbarOpen({
        type: "error",
        message:
          "List editors cannot download presentations. Please contact an owner or co-owner to perform this operation.",
      });
      return;
    }
    let presentation = new pptxgen();
    presentation.author = "CLIQInvite, LLC";
    presentation.company = "CLIQInvite, LLC";
    presentation.subject = `Presentation for contact list ${contactList.name}`;
    presentation.title = `${contactList} Contacts Presentation`;
    presentation.theme = { headFontFace: "Poppins", bodyFontFace: "Poppins" };

    async function getBase64(key) {
      const signedUrl = await Storage.get(key, { expires: 60 }); // Get the signed URL
      const response = await fetch(signedUrl); // Use this URL to get the blob
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result); // Convert blob to base64
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    const logoImage = await getBase64("CLIQLogoTransparent.png");

    presentation.defineSlideMaster({
      title: "MASTER_SLIDE",
      objects: [
        {
          image: {
            x: "85%",
            y: "85%",
            w: "10%",
            h: "7%",
            data: logoImage,
          },
        },
        {
          text: {
            text: getCurrentDate(),
            options: {
              x: 0.5, // Adjust as needed
              y: "90%", // Adjust as needed
              fontSize: 12,
              color: colors.secondaryText, // Adjust color as needed
            },
          },
        },
      ],
      background: { color: colors.secondaryBackground },
    });

    let slide = presentation.addSlide({ masterName: "MASTER_SLIDE" });
    slide.background = { color: colors.defaultBackground };
    slide.addText(
      [
        {
          text: `${contactList.name} Presentation`,
          options: {
            align: "center",
            fontSize: 32,
            breakLine: true,
            color: colors.primaryText,
          },
        },
        {
          text: "Powered by CLIQInvite",
          options: {
            color: colors.secondaryText,
            fontSize: 16,
            align: "center",
          },
        },
      ],
      {
        x: 0.5,
        y: "45%",
        w: 9,
      }
    );

    // Create a slide for each contact
    for (let contact of Array.from(filteredContacts.values())) {
      let slide = presentation.addSlide({ masterName: "MASTER_SLIDE" });
      slide.background = { color: colors.secondaryBackground };

      let currentY = 0.75;
      let stats = [];
      let header = [];
      let headerOptions = {
        fontSize: 26,
        color: colors.primaryText,
        breakLine:
          Boolean(customizeOptions.number) && Boolean(customizeOptions.name),
      };
      let subHeaderOptions = {
        fontSize: 16,
        color: colors.secondaryText,
      };
      if (customizeOptions.name || customizeOptions.number) {
        if (customizeOptions.name) {
          header.push({
            text: contact.name,
            options: headerOptions,
          });
          currentY += 0.4;
        }
        if (customizeOptions.number) {
          header.push({
            text: contact.number,
            options: customizeOptions.name ? subHeaderOptions : headerOptions,
          });
          currentY += customizeOptions.name ? 0.3 : 0.4;
        }
        slide.addText(header, {
          x: 0.5,
          y: 0.75,
          w: 6.75,
          fontSize: 26,
          color: colors.primaryText,
        });
      }
      if (
        customizeOptions.cliqScore ||
        customizeOptions.numInvited ||
        customizeOptions.numAttended ||
        customizeOptions.eventAttendance ||
        customizeOptions.scansGiven ||
        customizeOptions.validScans ||
        customizeOptions.invalidScans
      ) {
        const uniqueExpiredEventIDs = listEvents
          .filter((e) => parseInt(e.endDate) <= Date.now())
          .map((e) => e.id);
        const {
          cliqScore,
          invitedTo,
          attended,
          scansGiven,
          validScans,
          invalidScans,
        } = getCliqScore(contact.id, listInvites, uniqueExpiredEventIDs);
        if (customizeOptions.numInvited) {
          stats.push(`Invited to ${invitedTo} events`);
        }
        if (customizeOptions.numAttended) {
          stats.push(`Attended ${attended} events`);
        }
        if (customizeOptions.scansGiven) {
          stats.push(`Given ${scansGiven} scans`);
        }
        if (customizeOptions.validScans) {
          stats.push(`Scanned ${validScans} valid`);
        }
        if (customizeOptions.invalidScans) {
          stats.push(`Scanned ${invalidScans} invalid`);
        }
        if (customizeOptions.cliqScore) {
          stats.push(`CLIQInvite Score: ${cliqScore}`);
        }
      }

      stats.forEach((stat, index) => {
        slide.addText(stat, {
          x: 0.5,
          y: currentY,
          color: colors.secondaryText,
          bullet: true,
          w: 5.5,
          fontSize: stats.length < 3 ? 18 : stats.length < 5 ? 16 : 15,
        });
        currentY += stats.length < 3 ? 0.4 : stats.length < 5 ? 0.35 : 0.325;
      });

      if (customizeOptions.avgScore) {
        const memberContactScores = await DataStore.query(Score, (s) =>
          s.contactID.eq(contact.id)
        );

        const avgScore = memberContactScores.length
          ? (
              sum(memberContactScores, "score") / memberContactScores.length
            ).toFixed(1)
          : 0;

        slide.addText(
          [
            {
              text: avgScore,
              options: {
                align: "right",
                fontSize: 26,
                bold: true,
                breakLine: true,
                color:
                  contact.avgScore > 80
                    ? colors.softGreen
                    : contact.avgScore > 50
                    ? colors.secondaryColor
                    : colors.errorColor,
              },
            },
            {
              text: `Member score (${memberContactScores.length})`,
              options: {
                align: "right",
                fontSize: 10,
                color: colors.secondaryText,
              },
            },
          ],
          {
            x: 7.5,
            y: 0.75,
            w: 2,
          }
        );
      }

      if (customizeOptions.notes) {
        slide.addText(`Notes: ${contact.notes || "No Notes"}`, {
          x: 0.5,
          y: currentY,
          fontSize: 16,
          w: 5.5,
          color: colors.secondaryText,
          valign: "top",
        });
      }

      // Add their photo if it exists
      if (customizeOptions.photo) {
        let data;
        if (contact.photo) {
          data = await getBase64(`contact/700x700/${contact.photo}`);
        } else {
          data = await getBase64(`350x350/default-profile.jpg`);
        }
        slide.addImage({
          data: data,
          x: 6.5,
          y: 1.25,
          w: 3,
          h: 3,
        });
      }

      if (customizeOptions.eventAttendance) {
        let contactInvites = listInvites.filter(
          (i) => i.contactID === contact.id
        );
        let eventsToBeDisplayed = listEvents.map((event) => {
          const relevantInvites = contactInvites.filter(
            (invite) => invite.eventID === event.id
          );
          return {
            name: event.name,
            invited: relevantInvites?.length,
            attended: relevantInvites?.some((i) => i.numUsed),
          };
        });
        do {
          let currentY = 0.75; //where we start the text
          let slide = presentation.addSlide({ masterName: "MASTER_SLIDE" });
          // Create a table with headers

          slide.addText([header[0]], {
            x: 0.5,
            y: 0.75,
            w: 6.75,
            fontSize: 26,
            color: colors.primaryText,
          });
          let currentIndex = 0;
          let rows = eventsToBeDisplayed
            .map((event, index) => {
              if (currentY > 9) return;

              currentY += 0.5; // increment by row size
              currentIndex = index;

              return [
                {
                  text: event.name,
                  options: {
                    fontFace: "Poppins",
                    bold: true,
                    color: colors.primaryText,
                    fill:
                      index % 2 === 0
                        ? colors.secondaryBackground
                        : colors.defaultBackground,
                    valign: "center",
                  },
                },
                {
                  text: event.invited ? "✔" : "X",
                  options: {
                    color: event.invited ? colors.softGreen : colors.errorColor,
                    align: "center",
                    fill:
                      index % 2 === 0
                        ? colors.secondaryBackground
                        : colors.defaultBackground,
                    valign: "center",
                  },
                },
                {
                  options: {
                    color: event.attended
                      ? colors.softGreen
                      : colors.errorColor,
                    align: "center",
                    fill:
                      index % 2 === 0
                        ? colors.secondaryBackground
                        : colors.defaultBackground,
                    valign: "center",
                  },
                  text: event.attended ? "✔" : "X",
                },
              ];
            })
            .filter(Boolean); // filter out undefined rows
          eventsToBeDisplayed = eventsToBeDisplayed.filter(
            (event, index) => index > currentIndex
          );

          rows.unshift([
            {
              text: "Event Name",
              options: {
                fontFace: "Poppins",
                bold: true,
                color: colors.secondaryText,
                fill: colors.defaultBackground,
                valign: "center",
              },
            },
            {
              text: "Invited",
              options: {
                fontFace: "Poppins",

                color: colors.secondaryText,
                align: "center",
                fill: colors.defaultBackground,
                valign: "center",
              },
            },
            {
              text: "Attended",
              options: {
                fontFace: "Poppins",

                color: colors.secondaryText,
                align: "center",
                fill: colors.defaultBackground,
                valign: "center",
              },
            },
          ]);

          slide.addTable(rows, {
            w: 9,
            y: 1.15,
            rowH: 0.5,
            colW: 3,
            align: "left",
            fontFace: "Arial",
          });
        } while (eventsToBeDisplayed.length);
      }
    }

    // Save presentation
    presentation
      .writeFile(`${contactList.name}_Contacts.pptx`)
      .then((fileName) => {
        setSnackbarOpen({
          type: "success",
          message: "Successfully generated presentation.",
        });
      })
      .catch((err) => {
        setSnackbarOpen({
          type: "error",
          message:
            "Unknown error generating presentation. Please try again later.",
        });
      });
  };

  useDynamicDelay(
    () => {
      if (searchText) {
        setSelectedFilters((old) => {
          let tempFilters = [];
          for (const filter of old) {
            if (filter.type !== "NAME") {
              tempFilters.push(filter);
            }
          }
          tempFilters.push({ type: "NAME", filter: searchText.toLowerCase() });
          return tempFilters;
        });
      }
    },
    300,
    [searchText]
  );

  let displayedEventFilters = [];
  let displayedMetricFilters = [];
  if (eventFilters.length) {
    for (const eventFilter of eventFilters) {
      displayedEventFilters.push({
        displayText: `${
          eventFilter.type === "ATTENDED"
            ? "attended"
            : eventFilter.type === "INVITED"
            ? "invited to"
            : "not invited to"
        } ${eventFilter.eventName}`,
        onDelete: () => {
          setSelectedFilters((old) => {
            return old.filter(
              (oldFilter) =>
                oldFilter.type !== eventFilter.type ||
                oldFilter.filter !== eventFilter.filter
            );
          });
        },
      });
    }
  }
  if (metricFilters.length) {
    for (const metricFilter of metricFilters) {
      const displayedSelectionCategory =
        metricFilter.type === "avgScore"
          ? "Member score"
          : metricFilter.type === "cliqScore"
          ? "CLIQInvite score"
          : "Events attended";
      displayedMetricFilters.push({
        displayText: `${displayedSelectionCategory}: ${metricFilter.filter}`,
        onDelete: () =>
          setSelectedFilters((old) => {
            return old.filter(
              (oldFilter) =>
                oldFilter.type !== metricFilter.type ||
                oldFilter.filter !== metricFilter.filter
            );
          }),
      });
    }
  }

  const handleClick = (event) => {
    setAddLabelAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAddLabelAnchorEl(null);
  };

  return (
    <Box
      style={{ flex: 1, backgroundColor: colors.secondaryBackground }}
      sx={{
        p: 1,
        pb: 2,
        borderRadius: 2,
        mb: ".5em",
      }}
    >
      {loading ? (
        <Skeleton sx={{ fontSize: "1.4em", mb: "8px" }} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <h1 style={{ margin: 0, fontSize: "1.4em", textAlign: "left" }}>
            Filters
          </h1>
          <Button
            color="error"
            onClick={() => {
              setSelectedEvent(null);
              setSelectedFilters([]);
            }}
          >
            Clear
          </Button>
        </div>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "2.5em", mb: "8px" }} />
      ) : (
        <div style={{ marginBottom: 8 }}>
          <div style={{ display: "flex", gap: "0.3em", alignItems: "center" }}>
            <p
              style={{
                margin: 0,
                color: colors.secondaryText,
                fontSize: "0.85em",
              }}
            >
              Active labels
            </p>
            <Tooltip
              title="Choose a label to show each contact's invitation status for a specific event."
              sx={{ fontFamily: "Poppins" }}
            >
              <Help
                sx={{ color: colors.secondaryText, fontSize: "1em" }}
                fontSize="small"
              />
            </Tooltip>
          </div>
          {selectedEvent ? (
            <Chip
              key={selectedEvent.id}
              label={`invite status for ${selectedEvent.name}`}
              variant="contained"
              sx={{
                color: colors.secondaryText,
                backgroundColor: colors.backgroundHighlight,
              }}
              size="medium"
              onDelete={() => setSelectedEvent(null)}
              deleteIcon={<Cancel color="primary" />}
            ></Chip>
          ) : (
            <>
              <Button size="small" onClick={handleClick}>
                Add Label
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={addLabelAnchorEl}
                keepMounted
                open={Boolean(addLabelAnchorEl)}
                onClose={handleClose}
              >
                {listEvents.map((event) => (
                  <MenuItem
                    onClick={() => {
                      setSelectedEvent(event);
                      handleClose();
                    }}
                    key={event.id}
                    sx={{
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "0.85em",
                      ":hover": {
                        backgroundColor: "#030303",
                      },
                    }}
                  >
                    {event.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </div>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "2.5em", mb: "8px" }} />
      ) : (
        <div style={{ marginBottom: 8 }}>
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: "0.85em",
            }}
          >
            Contact name
          </p>
          <Box
            sx={{
              p: 0.5,
              display: "flex",
              alignItems: "center",
              backgroundColor: colors.defaultBackground,
              borderRadius: 1,
            }}
          >
            <Search
              sx={{
                fontSize: "1.3em",
                marginRight: 1,
                color: colors.secondaryText,
              }}
            />
            <TextField
              value={searchText}
              onChange={(e) => {
                if (e.target.value !== searchText) {
                  setSearchText(e.target.value);
                }
              }}
              placeholder="Search..."
              fullWidth
              variant="standard"
            />
          </Box>
        </div>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "7.5em" }} />
      ) : (
        <div style={{ marginBottom: 8 }}>
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: "0.85em",
            }}
          >
            Event engagement
          </p>
          {displayedEventFilters.length ? (
            displayedEventFilters.map((filter, index) => (
              <Chip
                key={index.toString}
                label={filter.displayText}
                variant="contained"
                sx={{
                  color: colors.secondaryText,
                  backgroundColor: colors.backgroundHighlight,
                }}
                size="medium"
                onDelete={() => filter.onDelete()}
                deleteIcon={<Cancel color="primary" />}
              />
            ))
          ) : (
            <p
              style={{ margin: 0, color: colors.primaryText, fontSize: ".8em" }}
            >
              Select an event below to filter the list by those invited or its
              attendees
            </p>
          )}
        </div>
      )}
      {loading ? null : (
        <div style={{ marginBottom: 8 }}>
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: "0.85em",
            }}
          >
            Metrics
          </p>
          {displayedMetricFilters.length ? (
            displayedMetricFilters.map((filter, index) => (
              <Chip
                key={index.toString}
                label={filter.displayText}
                variant="contained"
                sx={{
                  color: colors.secondaryText,
                  backgroundColor: colors.backgroundHighlight,
                }}
                size="medium"
                onDelete={() => filter.onDelete()}
                deleteIcon={<Cancel color="primary" />}
              />
            ))
          ) : (
            <>
              <Button
                size="small"
                onClick={() => setMetricFilterModalVisible(true)}
              >
                Add Metric Filter
              </Button>
              <GroupSelectModal
                isVisible={metricFilterModalVisible}
                close={() => setMetricFilterModalVisible(false)}
                filtering
                handleFilter={(selectionCategory, criteria, threshold) => {
                  setSelectedFilters((old) => {
                    const filterExists = old.find(
                      (filter) => filter.type === selectionCategory
                    );

                    if (filterExists) {
                      return old.map((filter) =>
                        filter.type === selectionCategory
                          ? {
                              type: selectionCategory,
                              filter: `${criteria}${threshold}`,
                            }
                          : filter
                      );
                    } else {
                      return [
                        ...old,
                        {
                          type: selectionCategory,
                          filter: `${criteria}${threshold}`,
                        },
                      ];
                    }
                  });
                }}
                contacts={filteredContacts}
              />
            </>
          )}
        </div>
      )}
      {loading ? null : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              color: colors.secondaryText,
              fontSize: ".8em",
              textAlign: "right",
            }}
          >
            <b style={{ color: colors.primaryColor }}>
              {filteredContacts.size}
            </b>{" "}
            contacts match your filters
          </p>
          <Tooltip
            title="You are able to download a presentation for up to 100 contacts. To customize the presentation format, click the ... in the top right."
            arrow
          >
            <Button onClick={downloadPowerPoint} color="secondary">
              Download Presentation
            </Button>
          </Tooltip>
        </div>
      )}
    </Box>
  );
};

export default ContactFiltersDisplay;
