import { useAuthContext } from "../../contexts/AuthContext";
import { ContactList } from "../../models";
import { DataStore } from "aws-amplify";
import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { colors } from "../../theme/colors";

const NewListModal = ({ isVisible, close, allLists }) => {
  const { dbUser } = useAuthContext();

  const onConfirm = async (e) => {
    let listName = document.getElementById("name").value;

    if (allLists.find((a) => a.name === listName)) {
      alert("Invalid name", "List names must be unique");
      return;
    }

    if (!listName.length) {
      alert("Invalid name", "List name cannot be empty");
      return;
    }

    DataStore.save(
      new ContactList({
        name: listName,
        creatorID: dbUser.id,
        accessingUsers: [],
        coOwners: [],
      })
    );
    close();
  };

  return (
    <Dialog open={isVisible} onClose={close}>
      <DialogTitle>New List</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a name for your new contact list
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label="List Name"
          fullWidth
          variant="standard"
          sx={{ mt: 3 }}
          inputProps={{ maxLength: 20 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} sx={{ color: colors.secondaryText }}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewListModal;
