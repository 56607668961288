export const formatPhoneNumber = (input) => {
  // Remove all non-digit characters from the input string
  let finalInput = input;
  if (input.includes("+")) {
    finalInput = input.slice(2);
  }

  const phoneNumber = finalInput.replace(/\D/g, "");

  // Format the phone number based on your desired format
  let formattedPhoneNumber = "";
  if (phoneNumber.length > 3) {
    formattedPhoneNumber = `(${phoneNumber.slice(0, 3)})`;

    if (phoneNumber.length > 6) {
      formattedPhoneNumber += ` ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
        6
      )}`;
    } else {
      formattedPhoneNumber += ` ${phoneNumber.slice(3)}`;
    }
  } else {
    formattedPhoneNumber = phoneNumber;
  }

  return formattedPhoneNumber;
};

export function cleanPhoneNumber(phoneNumber) {
  if (!phoneNumber) return "Loading...";
  // Remove non-numeric characters
  phoneNumber = phoneNumber.replace(/[^\d]/g, "");

  // Check for country code
  if (phoneNumber.length === 11 && phoneNumber.startsWith("1")) {
    phoneNumber = phoneNumber.slice(1);
  } else if (phoneNumber.length === 12 && phoneNumber.startsWith("01")) {
    phoneNumber = phoneNumber.slice(2);
  }

  // Format the phone number
  phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3");

  return phoneNumber;
}
