import React from "react";
import { Snackbar, Alert, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { DataStore, SortDirection } from "aws-amplify";
import { Payout, Account, Event, PayIn } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";
import { sum } from "../../methods/sum";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import WalletHeader from "./WalletHeader";
import BalanceModule from "./BalanceModule";
import PayInsModule from "./PayInsModule";
import PayoutsModule from "./PayoutsModule";
import ChangeVenmo from "./ChangeVenmo";
import { Helmet } from "react-helmet-async";

const Wallet = () => {
  const [balance, setBalance] = useState();
  const [events, setEvents] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [account, setAccount] = useState();
  const [venmoDialogVisible, setVenmoDialogVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const { dbUser } = useAuthContext();

  useEffect(() => {
    const fetchAccount = async () => {
      const userAccount = await DataStore.query(
        Account,
        dbUser.organizationAccountId
      );
      setAccount(userAccount);
    };
    if (dbUser && !events?.length) {
      (async () => {
        await fetchAccount();

        DataStore.observe(Account, dbUser.organizationAccountId).subscribe(
          (msg) => {
            fetchAccount();
          }
        );
      })();
    }
  }, [dbUser]);

  useEffect(() => {
    const fetchAllPayInsAndPayOuts = async () => {
      let tempBalance = 0;

      await DataStore.query(
        Event,
        (e) =>
          e.and((e) => [
            e.organizationID.eq(dbUser.id),
            e.endDate.lt(Date.now().toString()),
            e.ticketInformation.ne(null),
          ]),
        { sort: (e) => e.startDate(SortDirection.DESCENDING) }
      ).then(async (events) => {
        let newEvents = await Promise.all(
          events.map(async (event) => {
            let eventPayIn = await DataStore.query(PayIn, (p) =>
              p.payInEventId.eq(event.id)
            );
            if (!eventPayIn) {
              return null;
            }
            tempBalance += eventPayIn[0].amount;
            return {
              ...event,
              revenue: eventPayIn[0].amount,
              ticketsSold: eventPayIn[0].numTickets,
            };
          })
        );
        newEvents = newEvents.filter(Boolean);
        setEvents(newEvents);
      });

      await DataStore.query(Payout, (p) => p.accountID.eq(account.id), {
        sort: (p) => p.createdAt(SortDirection.DESCENDING),
      }).then((payouts) => {
        setPayouts(payouts);
        tempBalance -= sum(payouts, "amount");
      });

      setBalance(tempBalance);
    };
    if (account) {
      if (!account.venmo) {
        setVenmoDialogVisible(true);
      } else {
        fetchAllPayInsAndPayOuts();
      }
    }
  }, [account]);

  const loading = !account && isNaN(balance);

  const pageTitle = `${
    dbUser?.name ? dbUser.name : "Account"
  } Wallet • CLIQInvite`;
  const pageDescription = `Welcome to your CLIQInvite wallet - the easiest way to view your event revenues, easily request payouts, manage your payment options, and learn more about your balance over time. With exported reports straight from this page and options to inspect events even closer, your paid event management has never been easier.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/wallet`;

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <WebPortalNavigation activeScreen="/wallet" />
      <ChangeVenmo
        isVisible={venmoDialogVisible}
        close={() => setVenmoDialogVisible(false)}
        account={account}
        setSnackbarOpen={setSnackbarOpen}
      />
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          paddingTop: "2em",
          position: "relative",
        }}
        className="page-size-medium"
      >
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarOpen?.type}
            sx={{ width: "100%", fontFamily: "Poppins" }}
          >
            {snackbarOpen?.message}
          </Alert>
        </Snackbar>
        <WalletHeader
          loading={loading}
          payouts={payouts}
          balance={balance}
          setSnackbarOpen={setSnackbarOpen}
          payIns={events}
          account={account}
        />
        <Grid container rowSpacing={1} columnSpacing={2} sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={12}>
            <BalanceModule
              payIns={events}
              payouts={payouts}
              loading={loading}
              account={account}
              balance={balance}
              setVenmoDialogVisible={setVenmoDialogVisible}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <PayInsModule
              payIns={events}
              payouts={payouts}
              loading={loading}
              account={account}
              balance={balance}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <PayoutsModule payouts={payouts} loading={loading} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Wallet;
