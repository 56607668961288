import "./App.css";

import { Amplify, AuthModeStrategyType, DataStore, Hub } from "aws-amplify";

import config from "./aws-exports";

import AuthContextProvider from "./contexts/AuthContext";
import Navigation from "./pages/navigation/Navigation";
import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { colors } from "./theme/colors";
import { Helmet, HelmetProvider } from "react-helmet-async";

const helmetContext = {};
const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#a020f0",
      },
      secondary: {
        main: "#ffd700",
      },
    },
    typography: {
      h1: {
        fontSize: "1.5em",
        fontFamily: "Poppins",
        fontWeight: 700,
        color: colors.primaryText,
      },
      h2: {
        fontSize: "1.3em",
        fontFamily: "Poppins",
        fontWeight: 600,
        color: colors.secondaryText,
      },
      h3: {
        fontSize: "1.2em",
        fontFamily: "Poppins",
        fontWeight: 500,
        color: colors.primaryText,
      },
      body1: {
        fontSize: "1em",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: colors.primaryText,
      },
      body2: {
        fontSize: "1em",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: colors.secondaryText,
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          InputProps: {
            style: { color: colors.primaryText, fontFamily: "Poppins" },
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === "standard" && {
              color: colors.primaryText,
              "& .MuiInput-underline:before": {
                borderBottomColor: colors.secondaryText,
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: colors.primaryColor,
              },
            }),
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: colors.secondaryText, // When it's not active
            fontFamily: "Poppins",
          }),
        },
      },
      MuiDialog: {
        defaultProps: {
          PaperProps: {
            style: {
              backgroundColor: colors.secondaryBackground,
              minWidth: "35%",
            },
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          style: { color: colors.primaryText, fontFamily: "Poppins" },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: () => ({
            fontSize: { xs: "12px", lg: ".875rem" },
            fontFamily: "Poppins",
            textTransform: "none",
          }),
        },
      },
      MuiDialogContentText: {
        defaultProps: {
          style: { fontFamily: "Poppins", color: colors.secondaryText },
        },
      },
      MuiMenu: {
        defaultProps: {
          PaperProps: {
            style: {
              backgroundColor: colors.secondaryBackground,
            },
          },
        },
      },
      MuiMenuItem: {
        defaultProps: {
          style: {
            color: colors.secondaryText,
            fontFamily: "Poppins",
            fontSize: ".85rem",
          },
          sx: {
            ":hover": {
              backgroundColor: "#030303",
            },
          },
        },
      },
      MuiListItemText: {
        defaultProps: {
          style: {
            fontFamily: "Poppins",
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          style: {
            backgroundColor: colors.backgroundHighlight,
          },
        },
      },
      MuiChip: {
        defaultProps: {
          style: {
            fontFamily: "Poppins",
          },
        },
      },
      MuiDivider: {
        defaultProps: {
          sx: {
            bgcolor: colors.secondaryText,
          },
        },
      },
    },
  });

  Amplify.configure({
    ...config,
    DataStore: {
      authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    },
  });

  const [synced, setSynced] = useState(false);
  useEffect(() => {
    DataStore.start();

    const listener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData?.payload;
      if (event === "ready") {
        setSynced(true);
      }
    });

    return () => listener();
  }, []);

  if (!synced) {
    <div style={{ backgroundColor: "black" }}></div>;
  }

  const pageTitle = `CLIQInvite`;
  const pageDescription = `Revolutionize event management with CLIQInvite. Streamline social events, philanthropy, and recruitment. Secure invites, real-time tracking, and detailed analytics. Elevate your event experience today.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com`;
  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <Helmet prioritizeSeoTags>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:image" content={pageImageUrl} />
            <meta property="og:url" content={pageUrl} />
            <meta
              name="keywords"
              content="Event Management, Fraternity Management"
            />
          </Helmet>
          <Navigation className="App" />
        </AuthContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};
export default App;
