import React from "react";
import { Grid } from "@mui/material";
import PlanSummaryModule from "./PlanSummaryModule";
import PlanFeaturesModule from "./PlanFeaturesModule";

const SubscriptionPlans = ({
  planType,
  memberQuantity,
  setPlanType,
  setMemberQuantity,
}) => {
  return (
    <Grid container mb={2}>
      <Grid
        item
        lg={6}
        md={7}
        sm={12}
        sx={{ pr: { md: ".375rem" }, mb: { xs: ".75rem" } }}
      >
        <PlanSummaryModule
          planType={planType}
          setPlanType={setPlanType}
          setMemberQuantity={setMemberQuantity}
          memberQuantity={memberQuantity}
        />
      </Grid>
      <Grid
        item
        lg={6}
        md={5}
        sm={12}
        sx={{ pl: { md: ".375rem" }, mt: { md: "-6em" } }}
      >
        <PlanFeaturesModule />
      </Grid>
    </Grid>
  );
};

export default SubscriptionPlans;
