export function shortenNumberForModule(number) {
  const units = ["", "k", "M", "B", "T"];
  const delimiter = 3; // Number of digits before applying higher unit

  const absNumber = Math.abs(number);
  const sign = Math.sign(number) === -1 ? "-" : "";

  if (absNumber < 1000) {
    return sign + absNumber.toString();
  }

  let unitIndex = Math.floor(Math.log10(absNumber) / delimiter);
  let formattedNumber = absNumber / Math.pow(10, unitIndex * delimiter);

  // If the number is less than 10 and is not an integer, display 1 decimal place.
  // Otherwise, floor it.
  if (formattedNumber < 10 && !Number.isInteger(formattedNumber)) {
    formattedNumber = Math.floor(formattedNumber * 10) / 10;
    formattedNumber =
      formattedNumber % 1 !== 0 ? formattedNumber.toFixed(1) : formattedNumber;
  } else {
    formattedNumber = Math.floor(formattedNumber);
  }

  if (formattedNumber >= 1000) {
    formattedNumber = 1;
    unitIndex++;
  }

  return sign + formattedNumber + units[unitIndex];
}

export function addCommasToNumbers(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
