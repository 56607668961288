import { DataStore } from "aws-amplify";
import { Job } from "../../../models";
import axios from "axios";
import * as XLSX from "xlsx";
import jaroWinkler from "jaro-winkler";

export const deleteOldJobs = async (currentJobs) => {
  try {
    await Promise.all(
      currentJobs.map(async (currentJob) => {
        await DataStore.delete(Job, currentJob.id);
      })
    );
  } catch (err) {
    throw new Error();
  }
};

export const saveNewNotificationSchedules = async (
  event,
  token,
  displayedMemberJobs,
  orgID
) => {
  if (!event.jobsNotificationSettings?.length) {
    return;
  }
  const url = process.env.REACT_APP_JOB_NOTIFICATIONS_ENDPOINT;

  const body = {
    notificationTimes: event.jobsNotificationSettings || [],
    eventID: event.id,
    eventStartDate: event.startDate,
    jobs: displayedMemberJobs.map((memberJob) => {
      return {
        event: event.id,
        jobName: memberJob.jobName,
        startDate: memberJob.jobStart.toString(),
        endDate: memberJob.jobEnd.toString(),
        memberName: memberJob.name,
        organizationID: orgID,
        userID: memberJob.userID,
      };
    }),
  };

  try {
    await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    throw new Error();
  }
};

export const importAndInterpretJobsSpreadsheet = (
  file,
  eventStartDate,
  organizationMembers
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      let jobs = [];
      let jobType = "";
      let times = [];
      let interval = 0;

      const excelTimeToMinutesAfterStart = (
        excelTime,
        eventStartDate,
        initialDaysIncreased
      ) => {
        let jobDate = new Date(new Date(parseInt(eventStartDate)).getTime());
        jobDate.setDate(jobDate.getDate() + initialDaysIncreased);

        if (typeof excelTime === "number") {
          jobDate.setHours(Math.floor(excelTime * 24));
          jobDate.setMinutes(
            Math.floor((excelTime * 24 - Math.floor(excelTime * 24)) * 60)
          );
        } else {
          // Handle the string case
          const timeParts = excelTime.trim().split(" ");
          const [hours, minutes] = timeParts[0].split(":").map(Number);
          let period = timeParts[1];

          if (!period) {
            period = timeParts[0].slice(-2);
          }

          let hourToSet = hours;

          // Convert 12-hour time to 24-hour time
          if (period.toUpperCase() === "PM" && hours < 12) {
            hourToSet += 12;
          } else if (period.toUpperCase() === "AM" && hours === 12) {
            hourToSet = 0;
          }

          jobDate.setHours(hourToSet);
          jobDate.setMinutes(minutes);
        }

        let diff = Math.abs(jobDate - eventStartDate);
        let timeInMinutes = Math.floor(diff / 60000); // Convert milliseconds to minutes

        return timeInMinutes;
      };

      const findBestMatchMemberId = (name) => {
        let maxSimilarity = 0;
        let bestMatchUserId = null;
        let bestMatchUserName = null;

        organizationMembers.forEach((member) => {
          const similarity = jaroWinkler(name, member.memberName);
          if (similarity > maxSimilarity) {
            maxSimilarity = similarity;
            bestMatchUserId = member.id;
            bestMatchUserName = member.memberName;
          }
        });

        return { userID: bestMatchUserId, memberName: bestMatchUserName };
      };

      rows.forEach((row, i) => {
        // Skip empty rows
        if (row.length === 0) {
          return;
        }

        // If first element is "", this is a time row
        if (!row[0]) {
          let initialDaysIncreased = 0;
          let dayIncreaseIndices = row.slice(1).map((time, index) => {
            if (index === 0) {
              return;
            }
            if (typeof time === "number") {
              if (time === 0) {
                return index;
              }
            } else {
              const timeParts = time.trim().split(" ");
              const [hours, minutes] = timeParts[0].split(":").map(Number);
              let period = timeParts[1];

              if (!period) {
                period = timeParts[0].slice(-2);
              }
              if (hours === 12 && period.toUpperCase() === "AM") {
                const lastTime = row.slice(1)[index - 1];
                const lastTimeTimeParts = lastTime.trim().split(" ");
                const [lastTimeHours, lastTimeMinutes] = lastTimeTimeParts[0]
                  .split(":")
                  .map(Number);
                let lastTimePeriod = lastTimeTimeParts[1];
                if (
                  lastTimeHours === 12 &&
                  lastTimePeriod.toUpperCase() === "AM"
                ) {
                  return null;
                } else return index;
              }
            }
          });
          dayIncreaseIndices = dayIncreaseIndices.filter(Boolean);

          times = [];
          for (let i = 1; i < row.length; i++) {
            if (dayIncreaseIndices.includes(i - 1)) {
              initialDaysIncreased++;
            }
            const timeInMinutes = excelTimeToMinutesAfterStart(
              row[i],
              eventStartDate,
              initialDaysIncreased
            );

            times.push(timeInMinutes);
          }
        } else {
          jobType = row[0];
          row.slice(1).forEach((person, j) => {
            let endDate = times[j + 1];
            // If this is the first person in the row, calculate the interval
            if (j === 0) {
              interval = endDate - times[j];
            }
            // If endDate is undefined, use the interval to calculate it
            if (typeof endDate === "undefined") {
              endDate = times[j] + interval;
            }
            const { userID, memberName } = findBestMatchMemberId(person);
            jobs.push({
              jobName: jobType,
              jobStart: times[j],
              jobEnd: endDate,
              name: memberName,
              userID: userID,
            });
          });
        }
      });

      resolve(jobs);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsArrayBuffer(file);
  });
};
