import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../theme/colors";

const FooterLinkText = ({ text, navigationScreen }) => {
  const navigate = useNavigate();
  return (
    <Typography
      variant="body2"
      sx={{
        cursor: "pointer",
        ":hover": { color: colors.primaryText },
        transition: "color 200ms ease-in-out",
        fontSize: '1rem'
      }}
      onClick={() => navigate(navigationScreen)}
    >
      {text}
    </Typography>
  );
};

export default FooterLinkText;
