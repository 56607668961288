import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  IconButton,
  Skeleton,
} from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos, Info } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import GuestListModule from "./GuestListModule";
import { sum } from "../../methods/sum";

const GuestListGuestDisplay = ({ displayedGuests, event, loading }) => {
  // Pagination State
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredGuests, setFilteredGuests] = useState(displayedGuests);

  // Change event to useEffect when guests or guestInviteCount is updated
  useEffect(() => {
    setFilteredGuests(displayedGuests);
  }, [displayedGuests]);

  // Pagination Logic
  const itemsPerPage = 30;
  const pages = Math.ceil(filteredGuests.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredGuests.slice(startIndex, endIndex);
  //base module format
  let moduleFormat = [
    { size: 5, text: "Inviter" },
    { size: 4, text: "Category" },
    { size: 2, text: "# Given" },
  ];
  if (!!event?.ticketInformation) {
    //if the event is paid
    moduleFormat.push({ size: 3, text: "Revenue" });
  }
  if (event?.requirePhotoID) {
    //if we require photoID
    moduleFormat.push(
      ...[
        { size: 2, text: "Uploaded" },
        { size: 2, text: "Flagged" },
        { size: 2, text: "Rejected" },
      ]
    );
  }
  if (!!event?.analytics) {
    //proxy for determining whether the event is over... in this case, we are better using it to avoid errors/blank sections
    moduleFormat.push(
      ...[
        { size: 2, text: "# Valid" },
        { size: 2, text: "# Invalid" },
      ]
    );
  }

  return (
    <Box className="unstyled-card" sx={{ py: 1, px: 2 }}>
      {loading ? (
        <Skeleton sx={{ height: "2em" }} />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h3" sx={{ fontSize: "1.2em" }}>
              Invites Sent
            </Typography>
            <Box
              sx={{ mb: 1, display: "flex", alignItems: "center", gap: 0.5 }}
            >
              {!!event.ticketInformation || event.requirePhotoID ? (
                <>
                  <Typography variant="body2" sx={{ fontSize: ".8em" }}>
                    Tap any module to inspect individual invites
                  </Typography>
                  <Tooltip title="Each module represents a summary of all the invites a member sent in that category. You will be able to expand each module to view each individual invite sent in that member-category grouping, but only for paid or ID-verified events.">
                    <Info
                      sx={{ color: colors.secondaryText, fontSize: ".8em" }}
                    />
                  </Tooltip>
                </>
              ) : null}
            </Box>
          </Box>
          {loading
            ? null
            : pages > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <IconButton
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    size="small"
                  >
                    <ArrowBackIosNew
                      sx={{ color: colors.secondaryText, fontSize: "1em" }}
                    />
                  </IconButton>
                  <p
                    onClick={() => setCurrentPage(0)}
                    style={{
                      margin: 0,
                      marginLeft: 4,
                      marginRight: 4,
                      cursor: "pointer",
                      fontWeight: currentPage === 0 ? 700 : 400,
                      opacity: currentPage === 0 ? 1 : 0.6,
                    }}
                  >
                    1
                  </p>
                  {currentPage > 2 && <p>...</p>}
                  {Array.from({ length: pages }).map((_, index) => {
                    if (
                      index > 0 &&
                      index < pages - 1 &&
                      Math.abs(currentPage - index) <= 2
                    ) {
                      return (
                        <p
                          onClick={() => setCurrentPage(index)}
                          style={{
                            margin: 0,
                            marginLeft: 4,
                            marginRight: 4,
                            cursor: "pointer",
                            fontWeight: index === currentPage ? 700 : 400,
                            opacity: index === currentPage ? 1 : 0.6,
                          }}
                          key={index.toString()}
                        >
                          {index + 1}
                        </p>
                      );
                    }
                    return null;
                  })}
                  {currentPage < pages - 3 && <p>...</p>}
                  <p
                    onClick={() => setCurrentPage(pages - 1)}
                    style={{
                      margin: 0,
                      marginLeft: 4,
                      marginRight: 4,
                      cursor: "pointer",
                      fontWeight: currentPage === pages - 1 ? 700 : 400,
                      opacity: currentPage === pages - 1 ? 1 : 0.6,
                    }}
                  >
                    {pages}
                  </p>
                  <IconButton
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        Math.min(
                          prevPage + 1,
                          Math.floor(filteredGuests.length / itemsPerPage)
                        )
                      )
                    }
                    size="small"
                  >
                    <ArrowForwardIos
                      sx={{ color: colors.secondaryText, fontSize: "1em" }}
                    />
                  </IconButton>
                </Box>
              )}
        </Box>
      )}
      {loading ? (
        <Skeleton variant="rounded" height="50dvh" />
      ) : (
        <>
          <Grid
            container
            sx={{
              bgcolor: colors.backgroundHighlight,
              px: 1,
              py: 0.5,
              borderRadius: 1,
            }}
            columns={sum(moduleFormat, "size")}
          >
            {moduleFormat.map((format, index) => (
              <Grid key={index.toString()} item xs={format.size}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: ".8em",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                >
                  {format.text}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {[...currentItems].length ? (
            [...currentItems].map((item, index) => {
              const orgWithPhotoID = event.requirePhotoID;
              return item?.name ? (
                item.createdRoles ? (
                  <GuestListModule
                    guest={item}
                    key={index.toString()}
                    isPaid={!!event.ticketInformation}
                    isPostEvent={!!event.analytics}
                    format={moduleFormat}
                    isGuestOrg={true}
                  />
                ) : (
                  <GuestListModule
                    guest={item}
                    key={index.toString()}
                    isPaid={!!event.ticketInformation}
                    isPostEvent={!!event.analytics}
                    format={moduleFormat}
                  />
                )
              ) : item.count ? (
                <GuestListModule
                  guest={item}
                  isGuestOrg={item.isGuestOrg}
                  key={index.toString()}
                  isPaid={!!event.ticketInformation}
                  isPostEvent={!!event.analytics}
                  orgWithPhotoID={orgWithPhotoID}
                  noUpload={item.noUpload}
                  format={moduleFormat}
                />
              ) : null;
            })
          ) : (
            <p
              style={{
                color: colors.secondaryText,
                fontSize: "1em",
                marginBottom: 0
              }}
            >
              No guest invites sent yet
            </p>
          )}
        </>
      )}
    </Box>
  );
};

export default GuestListGuestDisplay;
