import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import React from "react";
import PhilanthropyFAQAccordion from "./PhilanthropyFAQAccordion";
const FAQs = [
  {
    question: "Do our guests have to download an app?",
    answer:
      " Brothers send guests all their ticket links externally via other applications, like text, Snapchat, GroupMe, and more. When the guest presses the ticket link, they're redirected to a secure payment portal on our website",
    boldedAnswerText: "No.",
  },
  {
    question: "How do I receive my money after the event is over?",
    answer:
      "At the conclusion of the event, your gains are sent directly to your organization's Wallet. At this point, you can request a payout via Venmo",
  },
  {
    question: "What's CLIQInvite's fee for purchases?",
    answer:
      "CLIQInvite's fee includes Stripe, our payment provider's, base fee of 2.9% + 30 cents per ticket. After Stripe's fees are deducted, CLIQInvite takes 10%, to make a total fee of around 13%*",
    asteriskText: "Fee percentage varies on the ticket price",
  },
  {
    question: "Can I figure out how much money each of my brothers raised?",
    boldedAnswerText: "Yes.",
    answer:
      " CLIQInvite allows you to break down your event revenue brother-by-brother, so you can easily view which brothers raised the most money versus which ones slacked off",
  },
  {
    question: "Can I access my guest list, before, during, and after my event?",
    boldedAnswerText: "Yes.",
    answer:
      " Your guest list is always available to you, even after your event has ended. After the event, you can also see who showed up, who didn't, and much more",
  },
  {
    question: "Can I still use private security with CLIQInvite?",
    boldedAnswerText: "Yes.",
    answer:
      " With an event code, or your event's unique scanner ID, your private security can scan in guests at your events without having to be a member of your organization in CLIQInvite",
  },
  {
    question: "How do I get started?",
    answer:
      "Download the app using the link above and sign up with your phone number. From there, our onboarding process will guide you through setting up your organization and onboarding your members. For any additional help, feel free to text or call our founders at 703-424-8009",
  },
];

const PhilanthropyFAQSection = () => {
  return (
    <Box
      sx={{
        py: "8em",
        bgcolor: colors.eggshell,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: colors.defaultBackground,
          lineHeight: 1.12,
          letterSpacing: "-.8px",
          textAlign: "center",
          alignSelf: "center",
          fontSize: {
            lg: "2rem",
            md: "2rem",
            sm: "2rem",
            xs: "1.6rem",
          },
          width: {
            lg: "70%",
          },
          mb: "3rem",
        }}
      >
        Frequently asked questions
      </Typography>
      <Box className="default-page-size">
        {FAQs.map((FAQ, index) => {
          return <PhilanthropyFAQAccordion FAQ={FAQ} index={index} />;
        })}
      </Box>
    </Box>
  );
};

export default PhilanthropyFAQSection;
