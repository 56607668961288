import { Box, Typography } from "@mui/material";

const AboutWhoWeAreSectionHeader = () => {
  return (
    <Box
      className="aboutImage"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        container
        className="default-page-size"
        sx={{
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "100%",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              lg: "4rem",
              md: "clamp(3rem, 5.5vw, 4rem)",
              sm: "2.5rem",
              xs: "2rem",
            },
            mb: { sm: "2rem", xs: "1rem" },
          }}
        >
          About Us
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutWhoWeAreSectionHeader;
