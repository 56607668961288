import {
  PaymentOutlined,
  DownloadOutlined,
  PointOfSaleOutlined,
  AttachMoney,
  ConfirmationNumberOutlined,
} from "@mui/icons-material";
import { Box, Button, Skeleton } from "@mui/material";
import { colors } from "../../theme/colors";
import { sum } from "../../methods/sum";
import { formatMoney } from "../../methods/calculateTakeHome";
import { downloadPaymentsSheet } from "../../methods/downloadInsightsData";
import { useAuthContext } from "../../contexts/AuthContext";
import axios from "axios";

const WalletHeader = ({
  loading,
  payouts,
  balance,
  setSnackbarOpen,
  payIns,
  account,
}) => {
  const { dbUser, authUser } = useAuthContext();
  const handleRequestPayout = async () => {
    if (loading) {
      return;
    }
    if (
      payouts.some(
        (p) =>
          Math.floor(new Date(p.createdAt)) > Math.floor(new Date()) - 259200000
      )
    ) {
      setSnackbarOpen({
        type: "error",
        message:
          "You must wait at least three days after your last payout to request a new one",
      });

      return;
    }
    if (balance < 10) {
      setSnackbarOpen({
        type: "error",
        message:
          "Your balance is not large enough to request a payout (minimum $10)",
      });
    } else {
      const token = authUser?.signInUserSession.idToken.jwtToken;
      const url = process.env.REACT_APP_MUTATE_PAYOUT_ENDPOINT;
      const body = {
        accountID: account.id,
        organizationID: dbUser.id,
      };
      try {
        setSnackbarOpen({
          type: "info",
          message: "Requesting payout...",
        });
        await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setSnackbarOpen({
          type: "success",
          message: "Successfully requested payout.",
        });
        window.location.reload();
      } catch (err) {
        setSnackbarOpen({
          type: "error",
          message: "Unknown error while requesting payout. Please try again.",
        });
      }
    }
  };

  const downloadExcelReport = async () => {
    await downloadPaymentsSheet(
      dbUser.id,
      dbUser.name,
      dbUser,
      { startDate: "0", endDate: Date.now().toString() },
      setSnackbarOpen
    );
  };

  return (
    <Box style={{ display: "flex", gap: "1em" }}>
      <div
        className="unstyled-card"
        style={{
          flex: 1.5,
          padding: ".5em 1em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", gap: "1em", marginBottom: ".8em" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {!loading ? (
              <h1 style={{ margin: 0, fontSize: "1.2em" }}>Wallet</h1>
            ) : (
              <Skeleton sx={{ fontSize: "1.2em" }} />
            )}
            {!loading ? (
              <p
                style={{
                  color: colors.secondaryText,
                  fontSize: ".8em",
                  margin: 0,
                  fontWeight: 500,
                }}
              >
                View your CLIQInvite account balance, review your paid event
                outcomes, and request payouts. Payouts are processed within 3-5
                business days.
                <b
                  className="link"
                  style={{ color: colors.primaryText, fontWeight: 600 }}
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/watch?v=nxqoSCgaoNs&list=PLCoNOX4-Q2eWCZLF666Z6vgFZ_dMtAGIA&index=12",
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  Learn More
                </b>
              </p>
            ) : (
              <Skeleton sx={{ fontSize: ".8em" }} />
            )}
          </div>
        </div>
        {!loading ? (
          <Box sx={{ display: "flex", gap: "1em" }}>
            <Button
              variant="contained"
              sx={{ flex: 1 }}
              startIcon={<PaymentOutlined sx={{ color: "white" }} />}
              color="primary"
              onClick={handleRequestPayout}
            >
              Request Payout
            </Button>
            <Button
              variant="contained"
              sx={{
                flex: 0.5,
                backgroundColor: colors.backgroundHighlight,
                ":hover": {
                  backgroundColor: `${colors.backgroundHighlight}80`,
                },
              }}
              startIcon={<DownloadOutlined sx={{ color: "white" }} />}
              onClick={downloadExcelReport}
            >
              Export
            </Button>
          </Box>
        ) : (
          <Skeleton variant="rounded" sx={{ height: "2em" }} />
        )}
      </div>
      <div
        className="unstyled-card"
        style={{
          flex: 0.8,
          padding: ".5em 1em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!loading ? (
          <h1
            style={{ margin: 0, fontSize: "1em", color: colors.secondaryText }}
          >
            Paid Events Statistics
          </h1>
        ) : (
          <Skeleton sx={{ fontSize: "1em" }} />
        )}
        {!loading ? (
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <p
              style={{
                color: colors.primaryText,
                fontSize: ".8em",
                margin: 0,
                fontWeight: 500,
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <AttachMoney color="primary" />
                <span style={{ marginLeft: "0.5em" }}>
                  {payIns.length || 0} paid event
                  {payIns.length === 1 ? "" : "s"}
                </span>
              </span>
            </p>
            <p
              style={{
                color: colors.primaryText,
                fontSize: ".8em",
                margin: 0,
                fontWeight: 500,
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <PointOfSaleOutlined color="primary" />
                <span style={{ marginLeft: "0.5em" }}>
                  ${formatMoney(sum(payIns, "revenue") * 100)} revenue
                </span>
              </span>
            </p>
            <p
              style={{
                color: colors.primaryText,
                fontSize: ".8em",
                margin: 0,
                fontWeight: 500,
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <ConfirmationNumberOutlined color="primary" />
                <span style={{ marginLeft: "0.5em" }}>
                  {sum(payIns, "ticketsSold")} ticket
                  {sum(payIns, "ticketsSold") === 1 ? "" : "s"} sold
                </span>
              </span>
            </p>
          </div>
        ) : (
          <Skeleton
            variant="rounded"
            sx={{ mt: "auto", mb: "auto", height: "3.5em" }}
          />
        )}
      </div>
    </Box>
  );
};

export default WalletHeader;
