import { Box, Grid, Typography } from "@mui/material";
import PlansSettingsDisplay from "./PlansSettingsDisplay";
import { colors } from "../../theme/colors";
import { calculateSubscriptionCosts } from "./calculateSubscriptionCost";

const PlanSummaryModule = ({
  planType,
  setPlanType,
  setMemberQuantity,
  memberQuantity,
}) => {
  const { individualMemberCost, chapterCost } = calculateSubscriptionCosts({
    planType,
    totalMembers: memberQuantity,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".75rem" }}>
      <Grid
        xs={12}
        container
        sx={{
          bgcolor: colors.eggshell,
          borderRadius: 3,
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
        }}
      >
        <Grid
          item
          sm={7}
          sx={{ pl: { sm: "2rem", xs: "1.2rem" }, pr: "1rem", py: "2rem" }}
        >
          <Typography
            variant="h2"
            sx={{
              color: colors.defaultBackground,
              flex: 1,
              fontSize: "1.5rem",
              mb: "1rem",
            }}
          >
            Monthly Subscription
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: colors.defaultBackground,
              fontSize: ".9rem",
              fontWeight: 500,
              color: colors.backgroundHighlight,
              mb: "1.5rem",
            }}
          >
            Due to multiple organization members needing to access CLIQInvite's
            features, you pay on a per-member basis. But don't worry if you're
            representing a larger organization. We got you covered with
            significant discounts.
          </Typography>
          <Box>
            <button
              className="slide-button"
              style={{ padding: ".25rem .75rem", fontSize: ".8rem" }}
            >
              Get Started →
            </button>
          </Box>
        </Grid>
        <Grid
          item
          sm={5}
          xs={12}
          sx={{
            pl: { sm: "1rem", xs: ".25rem" },
            py: ".25rem",
            pr: ".25rem",
            display: "flex",
            flexDirection: "column",
            gap: 0.25,
          }}
        >
          <Box
            sx={{
              bgcolor: `${colors.primaryColor}22`,
              borderTopRightRadius: { xs: 0, sm: 10 },
              py: { xs: "1.5rem", sm: 0 },
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h1"
              style={{
                fontWeight: 500,
                fontSize: "2rem",
                display: "flex",
                alignItems: "center", // Changed from "flex-end" to "center" for vertical alignment
                color: colors.defaultBackground,
                lineHeight: 1,
                letterSpacing: "-1px",
              }}
            >
              ${chapterCost}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: colors.defaultBackground, fontSize: ".8rem" }}
            >
              chapter subscription
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: `${colors.primaryColor}22`,
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: { xs: 10, sm: 0 },
              py: { xs: "1.5rem", sm: 0 },
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h1"
              style={{
                fontWeight: 500,
                fontSize: "2rem",
                display: "flex",
                alignItems: "center", // Changed from "flex-end" to "center" for vertical alignment
                color: colors.defaultBackground,
                lineHeight: 1,
                letterSpacing: "-1px",
              }}
            >
              $
              {planType === "yearly"
                ? (individualMemberCost * 0.85).toFixed(2)
                : individualMemberCost}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: colors.defaultBackground, fontSize: ".8rem" }}
            >
              per member
            </Typography>
          </Box>
          {/* <p style={{ fontSize: "1.1em", fontWeight: 600 }}>
            What's included in {data.name}
          </p>
          {data.features.map((text) => (
            <ListItem item={{ text: text, checkColor: data.checkColor }} />
          ))}
          <Divider sx={{ my: 2 }} />
          <Button
            sx={{
              mt: "auto",
              borderRadius: 8,
              mb: 1,
              fontSize: "1.2em",
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              if (isSignedIn) {
                navigate(`/subscribe/${data.name}`);
              } else {
                navigate("/login");
              }
            }}
          >
            Buy Now
          </Button> */}
        </Grid>
      </Grid>
      <Box
        sx={{
          bgcolor: colors.eggshell,
          pl: { xs: "1.2rem", sm: "2rem" },
          pr: ".25rem",
          py: ".25rem",
          borderRadius: 3,
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
        }}
      >
        <PlansSettingsDisplay
          planType={planType}
          setPlanType={setPlanType}
          memberQuantity={memberQuantity}
          setMemberQuantity={setMemberQuantity}
        />
      </Box>
    </Box>
  );
};

export default PlanSummaryModule;
