import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const SubscriptionConfirmationDialog = ({
  isVisible,
  close,
  setHelpDialogOpen,
}) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={isVisible}
      onClose={() => {
        window.history.replaceState(null, "", window.location.pathname);
        close();
      }}
    >
      <DialogTitle>Subscription Confirmed!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We look forward to powering your events. If you'd like to learn more
          about what your new subscription entails, you can{" "}
          <b
            className="link"
            onClick={() => navigate("/plans")}
            style={{ margin: 0 }}
          >
            view our breakdown
          </b>{" "}
          or{" "}
          <b
            className="link"
            style={{ margin: 0 }}
            onClick={() => setHelpDialogOpen(true)}
          >
            contact us
          </b>{" "}
          directly.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionConfirmationDialog;
