import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Event, GuestInvite } from "../../models";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
} from "@mui/material";
import { DataStore } from "aws-amplify";
import { getModuleDate } from "../../methods/getFormattedTime";
import InsightModule from "../insights/InsightModule";
import CircleProgress from "../../components/CircleProgress";
import { colors } from "../../theme/colors";
import { Check, Close, Info } from "@mui/icons-material";
import { CarouselProvider, Slider } from "pure-react-carousel";

const ContactInsights = ({
  contactEvents,
  cliqScore,
  contactScore,
  uniqueEventIDs,
  close,
}) => {
  const [eventAttendanceData, setEventAttendanceData] = useState([]);
  const [informationDialog, setInformationDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let sortedEvents = await Promise.all(
        uniqueEventIDs.map(async (eventID) => {
          const event = await DataStore.query(Event, eventID);
          return event;
        })
      );

      sortedEvents.sort(
        (a, b) => parseFloat(b.startDate) - parseFloat(a.startDate)
      );

      const eventData = sortedEvents.map((event) => {
        const attended = contactEvents.attendedEventIDs.includes(event.id);
        return {
          eventName: event.name,
          attended,
          eventDate: getModuleDate(event.startDate),
        };
      });

      setEventAttendanceData(eventData);
    };

    fetchData();
  }, []);

  return (
    <Box style={{ width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <h5
          style={{
            color: "white",
            fontSize: "1.2em",
            marginTop: 0,
            marginBottom: "1em",
          }}
        >
          Contact Insights
        </h5>
        <b
          style={{
            color: "gold",
            fontSize: "1em",
            marginLeft: "auto",
            cursor: "pointer",
          }}
          onClick={close}
        >
          see less
        </b>
      </Box>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Dialog
          open={!!informationDialog}
          onClose={() => setInformationDialog(false)}
        >
          <DialogTitle sx={{ fontFamily: "Poppins" }}>More Info</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontFamily: "Poppins" }}>
              {informationDialog}:{" "}
              {informationDialog === "CLIQInvite Score"
                ? "This score is aggregated using attendance and scan data."
                : "The average score inputted for this contact."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setInformationDialog(false)}
              sx={{ fontFamily: "Poppins" }}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={8}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={1}
            sx={{ fontSize: "12px" }}
          >
            <Grid item xs={4}>
              <InsightModule
                header="Events Invited To"
                statistic={contactEvents.invited}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header="Events Attended"
                statistic={contactEvents.attended}
              />
            </Grid>

            <Grid item xs={4}>
              <InsightModule
                header="Scans Given"
                statistic={contactEvents.scansGiven}
              />
            </Grid>

            <Grid item xs={4}>
              <InsightModule
                header="Valid Scans"
                statistic={contactEvents.used}
              />
            </Grid>

            <Grid item xs={4}>
              <InsightModule
                header="Invalid Scans"
                statistic={contactEvents.invalid}
              />
            </Grid>
            <Grid item xs={4}>
              <InsightModule
                header="Avg Valid Scans"
                statistic={
                  contactEvents.invited
                    ? `${contactEvents.used / contactEvents.invited}`
                    : `0`
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <p style={{ margin: 0 }}>CLIQInvite Score</p>

              <CircleProgress
                score={cliqScore}
                radius={45}
                strokeWidth={8}
                color={
                  cliqScore < 50
                    ? colors.errorColor
                    : cliqScore < 80
                    ? "#FFD700"
                    : colors.softGreen
                }
                bgColor={
                  cliqScore < 50
                    ? `${colors.errorColor}22`
                    : cliqScore < 80
                    ? "#FFD70022"
                    : `${colors.softGreen}22`
                }
              />
              <IconButton
                sx={{ position: "absolute", top: "1em", right: ".5em" }}
                onClick={() => setInformationDialog("CliqInvite Score")}
              >
                <Info fontSize="small" color="secondary" />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <p style={{ margin: 0 }}>List Score</p>
              <CircleProgress
                score={contactScore}
                radius={45}
                strokeWidth={8}
                color={
                  contactScore < 50
                    ? colors.errorColor
                    : contactScore < 80
                    ? "#FFD70022"
                    : colors.softGreen
                }
                bgColor={
                  contactScore < 50
                    ? `${colors.errorColor}22`
                    : contactScore < 80
                    ? "#FFD70022"
                    : `${colors.softGreen}22`
                }
              />

              <IconButton
                sx={{ position: "absolute", top: "1em", right: ".5em" }}
                onClick={() => setInformationDialog("List Score")}
              >
                <Info fontSize="small" color="secondary" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "1em" }}>
          <p style={{ color: "white", fontSize: "1em", marginBottom: 0 }}>
            Event Attendance
          </p>
          {eventAttendanceData.length ? (
            <CarouselProvider
              totalSlides={eventAttendanceData.length}
              naturalSlideHeight={120}
              visibleSlides={3} // Set the number of visible slides to 3
              step={1} // Set the step to 1 to show one slide at a time
              isIntrinsicHeight // Ensure the slides have the same height
              infinite
            >
              <Slider
                style={{
                  paddingRight: "5em",
                  paddingLeft: "2px",
                  paddingTop: "1em",
                  paddingBottom: "1em",
                  width: "100%",
                }}
              >
                {eventAttendanceData.map((event, index) => {
                  return (
                    <Slide index={index} key={index.toString()}>
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: colors.defaultBackground,
                            elevation: 1,
                            borderRadius: 2,
                            boxShadow: "0px 1px 2px 0px gold",
                            padding: ".5em 1.5em",
                            marginRight: "1em",
                          }}
                        >
                          <h3
                            style={{
                              margin: 0,
                              color: "white",
                              fontSize: "1.2em",
                              fontWeight: 600,
                            }}
                          >
                            {event.eventName}
                          </h3>
                          <p
                            style={{
                              margin: 0,
                              color: "white",
                              opacity: 0.7,
                              marginBottom: ".8em",
                              fontSize: ".8em",
                            }}
                          >
                            {event.eventDate}
                          </p>
                          <div style={{ display: "flex" }}>
                            {event.attended ? (
                              <Check color="medium" />
                            ) : (
                              <Close color="error" />
                            )}
                            <p style={{ margin: 0 }}>
                              {!event.attended && "Not "}Attended
                            </p>
                          </div>
                        </Box>
                      </>
                    </Slide>
                  );
                })}
              </Slider>
            </CarouselProvider>
          ) : (
            <p style={{ margin: 0, color: colors.errorColor, marginBottom: '1em' }}>No event attendance data yet</p>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactInsights;
