import {
  Button,
  Box,
  TextField,
  List,
  ListItem,
  IconButton,
  Modal,
  ListItemButton,
} from "@mui/material";
import "./ShareModal.css";
import { DataStore } from "aws-amplify";
import {
  Organization,
  User,
  OrganizationMember,
  ContactList,
  Contact,
} from "../../models";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import useDynamicDelay from "../../hooks/useDynamicDelay";
import { colors } from "../../theme/colors";
import ProfileImage from "../../components/ProfileImage";
import { Close } from "@mui/icons-material";
import axios from "axios";
import { RingLoader } from "react-spinners";
import ShareModalSearchResultModule from "./ShareModalSearchResultModule";
import AccessingUserModule from "./AccessingUserModule";

const ShareModal = ({
  shareModalOpen,
  close,
  listID,
  listName,
  setSnackbarOpen,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { dbUser, authUser } = useAuthContext();

  const [selectedUsers, setSelectedUsers] = useState(new Set([]));
  const [coOwners, setCoOwners] = useState(new Set([]));
  const [accessingUsers, setAccessingUsers] = useState([]);

  const [searchResults, setSearchResults] = useState([]);
  const [sharing, setSharing] = useState(false);

  const [reRender, setReRender] = useState(false);

  const shouldUpdate = (list, accessingUsers, coOwners) => {
    if (
      list.accessingUsers.length !== accessingUsers.size ||
      list.coOwners.length !== coOwners.size
    ) {
      return true;
    }

    // Check each element in the list against the set
    for (let i = 0; i < list.accessingUsers.length; i++) {
      if (!accessingUsers.has(list.accessingUsers[i])) {
        return true;
      }
    }

    // Check each element in the set against the list
    for (let user of accessingUsers) {
      if (!list.accessingUsers.includes(user)) {
        return true;
      }
    }

    // Repeat the same process for coOwners
    for (let i = 0; i < list.coOwners.length; i++) {
      if (!coOwners.has(list.coOwners[i])) {
        return true;
      }
    }

    for (let coOwner of coOwners) {
      if (!list.coOwners.includes(coOwner)) {
        return true;
      }
    }

    // If no mismatches were found, the list and set are identical
    return false;
  };

  const onShare = async () => {
    const token = authUser?.signInUserSession.idToken.jwtToken;
    const list = await DataStore.query(ContactList, listID);
    const shouldCallAPI = shouldUpdate(list, selectedUsers, coOwners);
    if (shouldCallAPI) {
      const url = process.env.REACT_APP_CONTACT_LIST_ENDPOINT;
      const body = {
        contactListID: list.id, // Replace with the desired event ID
        accessingUsers: Array.from(selectedUsers), // Replace with the desired UNIX timestamp or generate dynamically
        contactListVersion: list._version,
        coOwners: Array.from(coOwners),
      };
      try {
        setSharing(true);
        setSnackbarOpen({
          type: "info",
          message:
            "Sharing lists can take up to a minute if the number of contacts is large.",
        });
        await axios.put(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setSharing(false);
        setSnackbarOpen({
          type: "success",
          message: "Successfully updated share permissions.",
        });
        close();
      } catch (err) {
        setSnackbarOpen({
          type: "error",
          message: "An error occured during sharing. Please try again.",
        });
        return;
      }
    } else {
      close();
    }
  };

  useEffect(() => {
    handleSearch("");

    DataStore.query(ContactList, listID).then(async (list) => {
      setSelectedUsers(new Set(list.accessingUsers));
      setCoOwners(new Set(list.coOwners));
      const fetchUserOrOrganization = async (userID) => {
        const res = await DataStore.query(User, userID);
        if (!res) {
          const org = await DataStore.query(Organization, userID);
          if (!org) {
            return { id: userID };
          }
          return org;
        }
        return res;
      };

      const fetchAccessingUsers = async () => {
        const editorPromises = list.accessingUsers.map((userID) =>
          fetchUserOrOrganization(userID)
        );
        const tempAccessingUsers = await Promise.all(editorPromises);
        const coOwnerPromises = list.coOwners.map((userID) =>
          fetchUserOrOrganization(userID)
        );
        const tempCoOwners = await Promise.all(coOwnerPromises);
        setAccessingUsers([...tempCoOwners, ...tempAccessingUsers]);
      };

      fetchAccessingUsers();
    });
  }, [listID, shareModalOpen]);

  useDynamicDelay(
    () => {
      handleSearch(searchQuery);
    },
    300,
    [searchQuery]
  );

  const handleSearch = (search) => {
    if (!search) setSearchResults([]);
    else {
      let searches = [",", ",", ",", ",", ","];
      const tempArray = search.split(" ");
      for (let i = 0; i < tempArray.length; i++) {
        searches[i] = tempArray[i];
      }

      DataStore.query(
        Organization,
        (s) =>
          s.and((s) => [
            s.searchName.contains(searches[0].toLowerCase()),
            s.searchName.contains(searches[1].toLowerCase()),
            s.searchName.contains(searches[2].toLowerCase()),
            s.searchName.contains(searches[3].toLowerCase()),
            s.searchName.contains(searches[4].toLowerCase()),
            s.id.ne(dbUser.id),
            s.shortSchool.contains(dbUser.shortSchool),
          ]),
        {
          page: 0,
          limit: 10,
        }
      ).then((orgs) =>
        DataStore.query(
          User,
          (s) =>
            s.and((s) => [
              s.searchName.contains(searches[0].toLowerCase()),
              s.searchName.contains(searches[1].toLowerCase()),
              s.searchName.contains(searches[2].toLowerCase()),
              s.searchName.contains(searches[3].toLowerCase()),
              s.searchName.contains(searches[4].toLowerCase()),
              s.id.ne(dbUser.id),
              s.shortSchool.contains(dbUser.shortSchool),
            ]),
          {
            page: 0,
            limit: 10,
          }
        ).then((users) => {
          let tempRes = [...orgs, ...users];

          setSearchResults(tempRes.filter((user) => user.id !== dbUser.id));
        })
      );
    }
  };

  const shareWithAllMembers = async () => {
    let tempSelectedUsers = selectedUsers;
    let tempSharedUsers = accessingUsers;

    const organizationMembers = await DataStore.query(OrganizationMember, (o) =>
      o.and((o) => [o.organizationID.eq(dbUser.id), o.isConfirmed.eq(true)])
    );

    organizationMembers.forEach((member) => {
      if (!tempSelectedUsers.has(member.userID)) {
        tempSelectedUsers.add(member.userID);
      }
    });

    let usersFromMembers = await Promise.all(
      organizationMembers.map(async (member) => {
        if (!tempSharedUsers.find((u) => u.id === member.userID)) {
          const user = await DataStore.query(User, member.userID);
          return user;
        } else return null;
      })
    );

    usersFromMembers = usersFromMembers.filter(Boolean);

    setSelectedUsers(tempSelectedUsers);
    setAccessingUsers([
      ...tempSharedUsers,
      ...usersFromMembers.filter((u) => u),
    ]);
    setReRender((old) => !old);
  };

  return (
    <Modal
      open={shareModalOpen}
      onClose={close}
      onClick={(e) => e.stopPropagation()}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.secondaryBackground,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "15px",
          minWidth: 500,
          maxWidth: 700,
          padding: "1.5em",
          position: "relative",
        }}
      >
        <h1
          style={{
            margin: 0,
            marginBottom: ".6em",
            color: colors.primaryText,
            fontSize: "1.3em",
            fontWeight: 600,
            alignSelf: "flex-start",
          }}
        >
          Share {listName}
        </h1>

        <TextField
          name="search"
          placeholder={`Search`}
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          fullWidth
          variant="outlined"
          InputProps={{
            style: { backgroundColor: colors.backgroundHighlight },
          }}
          margin="none"
        />

        <Box
          sx={{
            position: "relative", // Add this line
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          {!searchResults.length ? null : (
            <List
              sx={{
                width: "100%",
                backgroundColor: colors.defaultBackground,
                position: "absolute",
                zIndex: 1,
              }}
            >
              {searchResults.map((result, index) => (
                <ShareModalSearchResultModule
                  result={result}
                  key={index.toString()}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  setSearchQuery={setSearchQuery}
                  setAccessingUsers={setAccessingUsers}
                />
              ))}
            </List>
          )}

          <p
            style={{
              color: "white",
              alignSelf: "flex-start",
              fontSize: "1.1em",
              marginBottom: 0,
            }}
          >
            Accessing users
          </p>
          <div
            className="scroll-container"
            style={{
              maxHeight: "40dvh",
              overflowY: "scroll",
              width: "100%",
            }}
          >
            {[dbUser, ...accessingUsers].map((user, index) => (
              <AccessingUserModule
                key={index.toString()}
                user={user}
                selectedUsers={selectedUsers}
                coOwners={coOwners}
                setCoOwners={setCoOwners}
                setSelectedUsers={setSelectedUsers}
                setAccessingUsers={setAccessingUsers}
                setReRender={setReRender}
                shareWithAllMembers={shareWithAllMembers}
              />
            ))}
          </div>
        </Box>
        {!sharing ? (
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontFamily: "Poppins",
              marginLeft: "auto",
              fontSize: "1em",
              borderRadius: 8,
              mt: "1em",
              textTransform: "none",
            }}
            onClick={onShare}
          >
            Share
          </Button>
        ) : (
          <RingLoader color={colors.primaryColor} size="2em" />
        )}
      </Box>
    </Modal>
  );
};

export default ShareModal;
