import {
  CardGiftcard,
  CardGiftcardOutlined,
  GroupsOutlined,
  RocketLaunchOutlined,
} from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";

const NavButtonExpandMenu = ({ menuAnchorEl, setMenuAnchorEl }) => {
  const open = Boolean(menuAnchorEl);
  const navigate = useNavigate();

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };
  return (
    <Menu anchorEl={menuAnchorEl} open={open} onClose={closeMenu}>
      <MenuItem
        onClick={() => {
          navigate("/social");
        }}
      >
        <ListItemIcon>
          <GroupsOutlined sx={{ color: colors.primaryText }} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body1">Social</Typography>
        </ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/rush");
        }}
      >
        <ListItemIcon>
          <RocketLaunchOutlined sx={{ color: colors.primaryText }} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body1">Rush</Typography>
        </ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/philanthropy");
        }}
      >
        <ListItemIcon>
          <CardGiftcardOutlined sx={{ color: colors.primaryText }} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body1">Philanthropy</Typography>
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default NavButtonExpandMenu;
