import PublicHeader from "../../navigation/PublicHeader";
import RushOverviewHeroSection from "./RushOverviewHeroSection";
import RushCapabilitiesSection from "./RushCapabilitiesSection";
import RushTestimonialSection from "./RushTestimonialSection";
import RushStatisticsSection from "./RushStatisticsSection";
import RushActionSection from "./RushActionSection";
import RushFAQSection from "./RushFAQSection";
import Footer from "../../../components/Footer";
import { colors } from "../../../theme/colors";
import { Box } from "@mui/material";

const RushOverview = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        overflowX: "none",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
        }}
      >
        <PublicHeader />
        <RushOverviewHeroSection />
        <Box sx={{ bgcolor: colors.eggshell }}>
          <RushCapabilitiesSection />
          <RushStatisticsSection />
          <RushTestimonialSection />
        </Box>
        <RushActionSection />
        <RushFAQSection />
        <Footer paddingTop={"2em"} />
      </div>
    </div>
  );
};
export default RushOverview;
