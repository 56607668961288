import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import React from "react";
import SocialFAQAccordion from "./SocialFAQAccordion";

const FAQs = [
  {
    question: "Do our guests have to download an app?",
    answer:
      " Brothers send guests all their invites externally via other applications, like text, Snapchat, GroupMe, and more.",
    boldedAnswerText: "No.",
  },
  {
    question: "Can I access my guest list, before, during, and after my event?",
    boldedAnswerText: "Yes.",
    answer:
      " Your guest list is always available to you, even after your event has ended. After the event, you can also see who showed up, who didn't, and much more",
  },
  {
    question: "Can I still use private security with CLIQInvite?",
    boldedAnswerText: "Yes.",
    answer:
      " With an event code, or your event's unique scanner ID, your private security can scan in guests at your events without having to be a member of your organization in CLIQInvite",
  },
  {
    question: "Can I customize when Shifts notifications are sent?",
    boldedAnswerText: "Yes.",
    answer:
      " After setting up your Shifts, you can easily customize when the notifications are sent, whether its 5 minutes before, 15 minutes before, when the shift starts, or all of the above",
  },
  // {
  //   question: "Is this okay with my national organization?",
  //   boldedAnswerText: "Yes.",
  //   answer:
  //     " We have worked with many national organizations to ensure that our platform is compliant with their policies and procedures.",
  // },
  {
    question: "How do I get started?",
    answer:
      "Download the app using the link above and sign up with your phone number. From there, our onboarding process will guide you through setting up your organization and onboarding your members. For any additional help, feel free to text or call our founders at 703-424-8009",
  },
];

const SocialFAQSection = () => {
  return (
    <Box
      sx={{
        py: "8em",
        bgcolor: colors.eggshell,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: colors.defaultBackground,
          lineHeight: 1.12,
          letterSpacing: "-.8px",
          textAlign: "center",
          alignSelf: "center",
          fontSize: {
            lg: "2rem",
            md: "2rem",
            sm: "2rem",
            xs: "1.6rem",
          },
          width: {
            lg: "70%",
          },
          mb: "3rem",
        }}
      >
        Frequently asked questions
      </Typography>
      <Box className="default-page-size">
        {FAQs.map((FAQ, index) => {
          return <SocialFAQAccordion FAQ={FAQ} index={index} />;
        })}
      </Box>
    </Box>
  );
};

export default SocialFAQSection;
