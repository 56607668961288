import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import ProfileImage from "../../components/ProfileImage";
import { colors } from "../../theme/colors";
import { useAuthContext } from "../../contexts/AuthContext";
import { ArrowDropDownOutlined, Close } from "@mui/icons-material";

const AccessingUserModule = ({
  user,
  shareWithAllMembers,
  selectedUsers,
  setSelectedUsers,
  setAccessingUsers,
  setReRender,
  coOwners,
  setCoOwners,
}) => {
  const { dbUser } = useAuthContext();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        gap: 1.5,
      }}
    >
      <ProfileImage pic={user?.profilePic} height="2.5em" />
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginLeft: ".5em",
          marginTop: "1em",
          marginBottom: ".7em",
        }}
      >
        <p
          style={{
            color: colors.primaryText,
            fontWeight: 600,
            margin: 0,
          }}
        >
          {user.name}
          <b style={{ color: colors.secondaryText }}>
            {dbUser.id === user.id && " (you)"}
          </b>
        </p>
        <p
          style={{
            color: colors.secondaryText,
            margin: 0,
            fontWeight: 500,
            fontSize: ".8em",
          }}
        >
          @ {user?.shortSchool}
        </p>
      </div>
      {dbUser.id === user.id ? (
        <Button
          sx={{
            marginLeft: "auto",
            fontFamily: "Poppins",
            fontSize: ".9em",
            mr: 1,
          }}
          onClick={shareWithAllMembers}
        >
          Add Members
        </Button>
      ) : (
        <Select
          value={coOwners.has(user.id) ? "Co-Owner" : "Editor"}
          onChange={(event) => {
            if (event.target.value === "Remove Access") {
              if (selectedUsers.has(user.id)) {
                let tempSelectedUsers = selectedUsers;

                tempSelectedUsers.delete(user.id);
                setSelectedUsers(tempSelectedUsers);
              } else {
                let tempCoOwners = coOwners;
                tempCoOwners.delete(user.id);
                setCoOwners(tempCoOwners);
              }
              setAccessingUsers((old) => {
                let tempUsers = old;
                tempUsers.splice(
                  old.findIndex((o) => o.id === user.id),
                  1
                );
                return tempUsers;
              });
              setReRender((old) => !old);
            } else {
              if (event.target.value === "Co-Owner") {
                let tempCoOwners = coOwners;
                tempCoOwners.add(user.id);
                let tempSelectedUsers = selectedUsers;
                tempSelectedUsers.delete(user.id);
                setSelectedUsers(tempSelectedUsers);
                setCoOwners(tempCoOwners);
              } else {
                let tempCoOwners = coOwners;
                tempCoOwners.delete(user.id);
                let tempSelectedUsers = selectedUsers;
                tempSelectedUsers.add(user.id);
                setSelectedUsers(tempSelectedUsers);
                setCoOwners(tempCoOwners);
              }
              setReRender((old) => !old);
            }
          }}
          IconComponent={() => (
            <ArrowDropDownOutlined
              sx={{
                color: colors.secondaryText,
                cursor: "pointer",

                ":hover": {
                  opacity: 0.7,
                },
              }}
            />
          )}
          SelectDisplayProps={{
            style: {
              padding: 0,
              paddingLeft: ".5em",
            },
          }}
          MenuProps={{
            PaperProps: { sx: { backgroundColor: colors.secondaryBackground } },
          }}
          sx={{ marginLeft: "auto", mr: 1 }}
        >
          <MenuItem value={"Editor"}>Editor</MenuItem>
          <MenuItem value={"Co-Owner"}>Co-Owner</MenuItem>
          <Divider />
          <MenuItem value={"Remove Access"}>Remove Access</MenuItem>
        </Select>
      )}
    </Box>
  );
};

export default AccessingUserModule;
