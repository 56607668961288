import { Box, Button, Skeleton } from "@mui/material";
import { colors } from "../../theme/colors";
import { useState } from "react";
import { API, DataStore } from "aws-amplify";
import { Event } from "../../models";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Remove, Save } from "@mui/icons-material";
import { deleteEvent } from "../../graphql/mutations";
import { getEvent } from "../../graphql/queries";

const EditEventSubmitSection = ({
  loading,
  setSnackbarOpen,
  eventID,
  handleConfirmChanges,
  handleMakeChanges,
}) => {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();

  const handleConfirmPress = () => {
    if (saving || deleting) {
      return;
    }
    const status = handleConfirmChanges();
    if (status === "SUCCESS")
      setSnackbarOpen({
        type: "success",
        message:
          "Press confirm to save changes and modify all existing invites and notify your members of the changes",
        action: (
          <Button
            size="small"
            color="inherit"
            onClick={async () => {
              setSnackbarOpen(null);
              setSaving(true);
              await handleMakeChanges(true);
              setSaving(false);
            }}
          >
            Confirm
          </Button>
        ),
      });
  };

  const handleDeletePress = () => {
    setSnackbarOpen({
      type: "error",
      message: "Please press confirm to finish deleting your event",
      action: (
        <Button
          size="small"
          color="inherit"
          onClick={async () => {
            if (deleting || saving) {
              return;
            }
            setSnackbarOpen(null);
            setDeleting(true);
            await handleDeleteEvent();
          }}
        >
          Confirm
        </Button>
      ),
    });
  };

  const handleDeleteEvent = async () => {
    setDeleting(true);

    try {
      await DataStore.delete(Event, eventID);

      setDeleting(false);
      navigate("/dashboard");
    } catch (err) {
      setDeleting(false);
      console.error(err);
      setSnackbarOpen({
        type: "error",
        message: "Error deleting event. Please try again later",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="rounded" sx={{ mt: 4 }} height="2em" />
      ) : (
        <Box sx={{ display: "flex", width: "100%", gap: 1 }}>
          <Button
            variant="contained"
            color="error"
            sx={{
              mt: 4,
              flex: deleting ? 1 : saving ? 0 : 0.5,
              transition: "all 300ms ease-in-out",
            }}
            type="submit"
            startIcon={
              deleting ? (
                <BeatLoader color="white" />
              ) : (
                <Remove sx={{ color: colors.primaryText }} />
              )
            }
            onClick={handleDeletePress}
          >
            {saving || deleting ? "" : "Delete Event"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 4,
              flex: deleting ? 0 : 1,
              transition: "all 300ms ease-in-out",
            }}
            type="submit"
            startIcon={
              saving ? (
                <BeatLoader color="white" />
              ) : (
                <Save sx={{ color: colors.primaryText }} />
              )
            }
            onClick={handleConfirmPress}
          >
            {saving || deleting ? "" : "Save Changes"}
          </Button>
        </Box>
      )}
    </>
  );
};

export default EditEventSubmitSection;
