import { Box, Grid, Link, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";

const LandingCapabilitiesModuleTextSection = ({
  inverted,
  title,
  subTitle,
  linkText,
  linkRef,
  color,
}) => {
  return (
    <Grid
      item
      sm={10}
      md={4.6}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        gap: "1rem",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: colors.defaultBackground,
          marginTop: !inverted ? 0 : undefined,
          lineHeight: 1.12,
          letterSpacing: "-.8px",
          fontSize: {
            xl: "3.125rem",
            lg: "3rem",
            md: "4vw",
            sm: "3.5vw",
            xs: "1.6rem",
          },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: colors.defaultBackground,
          lineHeight: 1.4444,
          fontSize: {
            lg: "1.125rem",
            md: "clamp(16px, 1.6vw, 18px)",
            sm: "clamp(15px, 2vw, 17px)",
            xs: "1rem"
          },
          letterSpacing: "-.2px",
        }}
      >
        {subTitle}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-start",
          position: "relative",
          overflow: "hidden",
          "&:before": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "2px",
            bottom: 0,
            left: 0,
            backgroundColor: color,
            visibility: "hidden",
            transform: "scaleX(0)",
            transformOrigin: "left",
            transition: "all 0.3s ease-in-out",
          },
          "&:hover:before": {
            visibility: "visible",
            transform: "scaleX(1)",
          },
        }}
      >
        <Link
          href={linkRef}
          sx={{ color: color, fontSize: { sm: "clamp(15px, 2vw, 17px)", xs:'1rem' } }}
          underline="none"
        >
          {linkText} →
        </Link>
      </Box>
    </Grid>
  );
};

export default LandingCapabilitiesModuleTextSection;
