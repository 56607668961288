import { CopyAll, Info } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { colors } from "../../theme/colors";

const NonEditableInformationSection = ({
  eventCode,
  loading,
  setSnackbarOpen,
}) => {
  return loading ? null : (
    <>
      <Box sx={{ display: "flex", gap: 0.75, alignItems: "center" }}>
        <Typography variant="body2">Event Code</Typography>
        <Tooltip title="This code allows non-members to scan guests in. For example, event security can enter the code and scan guest from the homepage of the app, even if they're not members of your organization.">
          <Info sx={{ color: colors.secondaryText, fontSize: "1em" }} />
        </Tooltip>
      </Box>

      <Box
        sx={{
          cursor: "pointer",
          marginTop: -0.5,
          mb: 1,
          display: "flex",
          alignItems: "center",
          transition: "all 300ms ease-in-out",
          ":hover": {
            opacity: 0.6,
          },
        }}
        onClick={() => {
          navigator.clipboard.writeText(eventCode);
          setSnackbarOpen({
            type: "success",
            message: "Successfully copied event code to clipboard",
          });
        }}
      >
        <Typography variant="body1">{eventCode} </Typography>
        <IconButton>
          <CopyAll color="primary" fontSize="small" />
        </IconButton>
      </Box>
    </>
  );
};

export default NonEditableInformationSection;
