import PublicHeader from "../../public/navigation/PublicHeader";
import { colors } from "../../theme/colors";

import SubscriptionPlans from "./SubscriptionPlans";
import "./Plans.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import FeatureModule from "./FeatureModule";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGood";
import SecurityUpdateGoodRoundedIcon from "@mui/icons-material/SecurityUpdateGoodRounded";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { Helmet } from "react-helmet-async";
import PlansHeader from "./PlansHeader";
import PlansDisplaySection from "./PlansDisplaySection";
import PlansFeaturesSection from "./PlansFeaturesSection";

const Plans = () => {
  const pageTitle = `Payment Plans • CLIQInvite`;

  const pageDescription = `View all of CLIQInvite's pricing plans and determine which plan will best level up your organization's events.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/plans`;
  return (
    <div
      style={{
        backgroundColor: colors.defaultBackground,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden"
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta
          name="keywords"
          content="Event Management, Fraternity Management"
        />
      </Helmet>

      <PublicHeader />
      <Box sx={{ width: "100dvw" }}>
        <PlansHeader />
        <PlansDisplaySection />
        <PlansFeaturesSection />
      </Box>

      <Footer />
    </div>
  );
};

export default Plans;
