import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import React, { useState } from "react";
import PlansAdjustMemberModalVisible from "./PlansAdjustMemberModalVisible";

const PlansSettingsDisplay = ({
  memberQuantity,
  setMemberQuantity,
  planType,
  setPlanType,
}) => {
  const [adjustMemberModalVisible, setAdjustMemberModalVisible] =
    useState(false);

  return (
    <React.Fragment>
      <PlansAdjustMemberModalVisible
        adjustMemberModalVisible={adjustMemberModalVisible}
        setAdjustMemberModalVisible={setAdjustMemberModalVisible}
        memberQuantity={memberQuantity}
        setMemberQuantity={setMemberQuantity}
        planType={planType}
        setPlanType={setPlanType}
      />
      <Box display="flex" width="100%" alignItems="center" gap={2}>
        <Typography
          variant="body1"
          sx={{ color: colors.backgroundHighlight, fontSize: ".9rem" }}
        >
          Prices assume you're paying for {memberQuantity} members {planType}
        </Typography>
        <Button
          onClick={() => setAdjustMemberModalVisible(true)}
          sx={{ ml: "auto", borderRadius: 3 }}
          variant="contained"
        >
          Adjust
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default PlansSettingsDisplay;
