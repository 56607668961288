import { List, ListItem, ListItemText } from "@mui/material";
import AnnouncementModule from "./AnnouncementModule";
import LocalAnnouncementModule from "./LocalAnnouncementModule";

const AnnouncementsDisplay = ({
  localAnnouncement,
  setLocalAnnouncement,
  createAnnouncement,
  announcements,
  event,
  setSnackbarOpen,
}) => {
  const sampleAnnouncement = {
    title: "No Announcements Yet!",
    isPinned: true,
    isSample: true,
    announcement:
      "When you post an announcement, this is what it'll look like. You have the option to add a link, allow only certain groups to view an announcement, or pin it to push it to the top of the list.",
    rolesVisible: [],
    link: "www.cliqinvite.com",
    eventID: "sampleEventID",
    createdAt: event.createdAt,
    visibleRoles: [],
  };
  const dataList = localAnnouncement
    ? [localAnnouncement, ...announcements]
    : announcements;

  if (!dataList?.length) {
    return (
      <AnnouncementModule
        announcement={sampleAnnouncement}
        createAnnouncement={createAnnouncement}
        eventID={event.id}
        setSnackbarOpen={setSnackbarOpen}
      ></AnnouncementModule>
    );
  }

  return (
    <List sx={{ width: "100%" }}>
      {localAnnouncement ? (
        <LocalAnnouncementModule
          announcement={localAnnouncement}
          deleteLocalAnnouncement={() => setLocalAnnouncement(null)}
          clearLocalAnnouncement={() => setLocalAnnouncement(null)}
          setSnackbarOpen={setSnackbarOpen}
        />
      ) : null}
      {announcements?.length
        ? announcements.map((item, index) => (
            <AnnouncementModule
              key={index.toString()}
              announcement={item}
              setSnackbarOpen={setSnackbarOpen}
            />
          ))
        : null}
    </List>
  );
};

export default AnnouncementsDisplay;
