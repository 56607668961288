import { colors } from "../../theme/colors";

import { useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { useEffect } from "react";
import { Event } from "../../models";
import { DataStore } from "aws-amplify";
import { Box, IconButton, Radio, TextField, Typography } from "@mui/material";
import {  LockOutlined } from "@mui/icons-material";
import { CgInfinity } from "react-icons/cg";
import CategoryLimitModal from "./CategoryLimitModal";

//use objects, change to invite module
const InviteRoleModule = ({
  RoleModule,
  updateModules,
  event,
  role,
  radio,
}) => {
  const { dbUser } = useAuthContext();
  const numInvites = RoleModule.numInvites;

  const [modalVisible, setModalVisible] = useState(false);
  const [hasLimits, setHasLimits] = useState(false);

  const onUnlimitedPress = () => {
    RoleModule.num === "1000"
      ? (RoleModule.num = "0")
      : (RoleModule.num = "1000");
    updateModules(RoleModule);
  };

  useEffect(() => {
    setHasLimits(false);
    DataStore.query(Event, event.id).then((event) => {
      //make sure we are getting the most recently updated version
      event.categories.forEach((category) => {
        category.limits.forEach((limit) => {
          if (limit.role === role && limit.limit !== "-1") {
            //if the limit corresponds with the role and the limit is not infinite
            setHasLimits(true);
          }
        });
      });
    });
  }, [modalVisible, event.id, role]); //checks whenever the roles are changed

  return (
    <Box
      sx={{
        bgcolor: colors.backgroundHighlight,
        display: "flex",
        alignItems: "center",
        borderRadius: 2,
        mt: 0.5,
        pl: 1.5,
      }}
    >
      <Typography variant="h1" sx={{ fontSize: "1em", fontWeight: 500 }}>
        {role}
      </Typography>
      <CategoryLimitModal
        event={event}
        open={modalVisible}
        close={() => setModalVisible(false)}
        role={role}
      />
      <Box
        sx={{
          marginLeft: "auto",

          display: "flex",
          flexDirection: "row",

          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
        }}
      >
        {dbUser.id === event.organizationID && !radio ? (
          <IconButton
            style={{
              borderWidth: 2,
              borderColor: colors.primaryColor,
              borderStyle: "solid",
              borderRadius: 100,
              backgroundColor:
                RoleModule.num === "1000" ? colors.primaryColor : "transparent",
            }}
            size="small"
            onClick={onUnlimitedPress}
          >
            <CgInfinity fontSize="medium" style={{ color: "white" }} />
          </IconButton>
        ) : null}
        {!radio ? (
          <TextField
            name="numInvites"
            size="small"
            label="# invites"
            inputProps={{ maxLength: 3 }}
            inputMode="numeric"
            onChange={(event) => {
              RoleModule.num = event.target.value;
              updateModules(RoleModule);
            }}
            defaultValue={
              numInvites &&
              (numInvites !== "1000" && numInvites !== "0" ? numInvites : "")
            }
            InputProps={{
              style: {
                color: "white",
                fontFamily: "Poppins",
              },
            }}
            sx={{ caretColor: "white", maxWidth: "6em", bgcolor: colors.secondaryBackground, my: .5, borderRadius: 1, mr: -.75 }}
            disabled={RoleModule.num === "1000"}
          ></TextField>
        ) : (
          <Radio
            checked={RoleModule.num === "INVITED"}
            color="secondary"
            onClick={() => {
              if (RoleModule.num === "INVITED") {
                RoleModule.num = "0";
                updateModules(RoleModule);
              } else {
                RoleModule.num = "INVITED";
                updateModules(RoleModule);
              }
            }}
          />
        )}
        {dbUser.id === event.organizationID && !radio ? (
          <IconButton
            onClick={() => {
              setModalVisible(true);
            }}
            size="small"
          >
            <LockOutlined
              color={hasLimits ? "secondary" : "primary"}
              fontSize="medium"
            />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default InviteRoleModule;
