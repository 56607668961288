import { Box, Button, Divider, Skeleton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

const MemberUsageDisplay = ({
  loading,
  organizationMembers,
  plan,
  handleGetMoreMembers,
}) => {
  return (
    <Box className="unstyled-card" sx={{ px: 2, py: 1 }}>
      {loading ? (
        <>
          <Skeleton sx={{ fontSize: "2.5em", mb: 2 }} />
        </>
      ) : (
        <>
          <Typography variant="h1" sx={{ mt: ".2em", fontSize: "1.3em" }}>
            Member Subscriptions
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 2, fontSize: ".8em", mt: ".2em" }}
          >
            View how many member subscriptions you have used
          </Typography>
        </>
      )}
      {loading ? (
        <>
          <Skeleton variant="rounded" sx={{ fontSize: "5em" }} />
        </>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body2" sx={{ fontSize: "1.1em", flex: 1 }}>
              Member subscriptions alloted:
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1.1em" }}>
              {plan?.totalMembers}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body2" sx={{ fontSize: "1.1em", flex: 1 }}>
              Member subscriptions used:
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1.1em" }}>
              {organizationMembers.length}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", mt: 1 }}>
            <Typography variant="body2" sx={{ fontSize: "1.1em", flex: 1 }}>
              Member subscriptions remaining:
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1.1em" }}>
              {plan?.totalMembers - organizationMembers.length}
            </Typography>
          </Box>
        </Box>
      )}
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "2em" }} />
      ) : (
        <Box display="flex" sx={{ pt: 3, pb: 1, gap: 2 }}>
          <Button
            color="primary"
            variant="contained"
            sx={{ flex: 1 }}
            startIcon={<Add sx={{ color: "white" }} />}
            onClick={handleGetMoreMembers}
          >
            Get More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MemberUsageDisplay;
