import React, { useEffect, useState } from "react";
import { DataStore, SortDirection } from "aws-amplify";
import { Contact, ContactList, Event, GuestInvite } from "../../models";
import ManuallyAddContacts from "./ManuallyAddContacts";
import ContactItem from "./ContactItem";
import { getCliqScore } from "../../methods/getCliqScore";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { BeatLoader } from "react-spinners";
import EditContactsHeader from "./EditContactsHeader";
import ContactEventsDisplay from "./ContactEventsDisplay";
import ContactFiltersDisplay from "./ContactFiltersDisplay";
import { sum } from "../../methods/sum";
import { useAuthContext } from "../../contexts/AuthContext";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import { Helmet } from "react-helmet-async";
import { colors } from "../../theme/colors";

const EditContacts = () => {
  let { listID } = useParams();
  const { dbUser } = useAuthContext();

  const [indexExpanded, setIndexExpanded] = useState(-1);
  const [contactList, setContactList] = useState();
  const [contacts, setContacts] = useState(new Map());
  const [filteredContacts, setFilteredContacts] = useState(new Map());
  const [manuallyAddContactModalVisible, setManuallyAddContactModalVisible] =
    useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("avgScore");
  const [deleting, setDeleting] = useState(false);
  const [deleteContactsQueue, setDeleteContactsQueue] = useState(new Set([]));
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listEvents, setListEvents] = useState(null);
  const [listInvites, setListInvites] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [customizeOptions, setCustomizeOptions] = useState({
    name: true,
    photo: true,
    notes: true,
    avgScore: true,
    numInvited: true,
    numAttended: true,
    number: false,
    cliqScore: false,
    eventAttendance: false,
    memberScores: false,
    scansGiven: false,
    validScans: false,
    invalidScans: false,
  });

  const renderItem = (item, index) => {
    let eventStatus = undefined;
    if (selectedEvent) {
      let eventContactInvites = listInvites.filter(
        (i) => i.contactID === item.id && i.eventID === selectedEvent.id
      );
      if (eventContactInvites?.length) {
        if (sum(eventContactInvites, "numUsed") > 0) {
          eventStatus = "attended";
        } else {
          eventStatus = "invited";
        }
      } else {
        eventStatus = "not invited";
      }
    }
    const status =
      dbUser.id === contactList.creatorID
        ? "OWNER"
        : contactList.coOwners.includes(contactList.creatorID)
        ? "COOWNER"
        : "EDITOR";
    return (
      <ContactItem
        indexExpanded={indexExpanded}
        setIndexExpanded={setIndexExpanded}
        key={item.id}
        index={index}
        item={item}
        deleteContactsQueue={deleteContactsQueue}
        deleting={deleting}
        setDeleteContactsQueue={setDeleteContactsQueue}
        selectedSortOption={selectedSortOption}
        eventStatus={eventStatus}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        status={status}
      />
    );
  };

  async function fetchCliqScores(newContacts) {
    let scoredContacts = newContacts;
    const guestInvites = await DataStore.query(GuestInvite, (g) =>
      g.contactListID.eq(contactList.id)
    );
    const uniqueEventIDs = new Set(
      guestInvites.map((invite) => invite.eventID)
    );
    const uniqueExpiredEventIDs = (
      await Promise.all(
        Array.from(uniqueEventIDs).map(async (eventID) => {
          const event = await DataStore.query(Event, eventID);
          if (event.endDate < Date.now().toString()) return eventID;
        })
      )
    ).filter((eventID) => eventID !== undefined);

    scoredContacts = newContacts.map((contact) => {
      let { cliqScore, eventScore } = getCliqScore(
        contact.id,
        guestInvites,
        uniqueExpiredEventIDs
      );
      return { ...contact, cliqScore: cliqScore, eventScore: eventScore };
    });

    return scoredContacts;
  }

  const calculateQualifyingContacts = (
    scoredContacts,
    selectionCategory,
    criteria,
    threshold
  ) => {
    let qualifyingContacts = scoredContacts.filter((s) => {
      if (selectionCategory === "cliqScore") {
        return criteria === ">"
          ? s.cliqScore > (parseInt(threshold) || 0)
          : criteria === "<"
          ? s.cliqScore < (parseInt(threshold) || 0)
          : s.cliqScore === (parseInt(threshold) || 0);
      } else if (selectionCategory === "avgScore") {
        return criteria === ">"
          ? s.avgScore > (parseInt(threshold) || 0)
          : criteria === "<"
          ? s.avgScore < (parseInt(threshold) || 0)
          : s.avgScore === (parseInt(threshold) || 0);
      } else {
        return criteria === ">"
          ? s.eventScore > (parseInt(threshold) || 0)
          : criteria === "<"
          ? s.eventScore < (parseInt(threshold) || 0)
          : s.eventScore === (parseInt(threshold) || 0);
      }
    });
    return qualifyingContacts;
  };

  async function sortContacts() {
    if (selectedSortOption === "cliqScore" || selectedSortOption === "events") {
      let newContacts = Array.from(filteredContacts.values());
      let scoredContacts = newContacts;

      setLoading(true);
      if (!Array.from(contacts.values())?.[0]?.cliqScore) {
        scoredContacts = await fetchCliqScores(newContacts);
      }

      if (selectedSortOption === "cliqScore") {
        scoredContacts.sort((a, b) => b.cliqScore - a.cliqScore);
      } else {
        scoredContacts.sort((a, b) => b.eventScore - a.eventScore);
      }

      newContacts = scoredContacts;
      let newContactsMap = new Map(
        newContacts.map((contact) => [contact.id, contact])
      );

      setLoading(false);
      setFilteredContacts(newContactsMap);
    }
  }

  async function filterContacts() {
    let newContacts = new Map(contacts); // create a copy of contacts map

    for (const selectedFilter of selectedFilters) {
      if (selectedFilter?.type === "INVITED") {
        const invitedContactIDs = [
          ...new Set(
            listInvites
              .filter((i) => i.eventID === selectedFilter.filter)
              .map((invite) => invite.contactID)
          ),
        ];

        newContacts = new Map(
          Array.from(newContacts).filter(([id]) =>
            invitedContactIDs.includes(id)
          )
        );
      } else if (selectedFilter?.type === "ATTENDED") {
        const attendingContacts = [
          ...new Set(
            listInvites
              .filter(
                (i) => i.eventID === selectedFilter.filter && Boolean(i.numUsed)
              )
              .map((invite) => invite.contactID)
          ),
        ];

        newContacts = new Map(
          Array.from(newContacts).filter(([id]) =>
            attendingContacts.includes(id)
          )
        );
      } else if (selectedFilter?.type === "NOTINVITED") {
        const invitedContactIDs = [
          ...new Set(
            listInvites
              .filter((i) => i.eventID === selectedFilter.filter)
              .map((invite) => invite.contactID)
          ),
        ];

        newContacts = new Map(
          Array.from(newContacts).filter(
            ([id]) => !invitedContactIDs.includes(id)
          )
        );
      } else if (selectedFilter?.type === "NAME") {
        newContacts = new Map(
          Array.from(newContacts.values())
            .filter((contact) =>
              contact.name.toLowerCase().includes(selectedFilter.filter)
            )
            .map((contact) => [contact.id, contact])
        );
      } else if (
        selectedFilter?.type === "avgScore" ||
        selectedFilter?.type === "cliqScore" ||
        selectedFilter?.type === "events"
      ) {
        let scoredContacts = Array.from(newContacts.values());
        if (!Array.from(contacts.values())?.[0]?.cliqScore) {
          scoredContacts = await fetchCliqScores(scoredContacts);
        }
        let qualifyingContacts = calculateQualifyingContacts(
          scoredContacts,
          selectedFilter?.type,
          selectedFilter.filter.charAt(0),
          selectedFilter.filter.substring(1)
        );
        newContacts = new Map(
          qualifyingContacts.map((contact) => [contact.id, contact])
        );
      }
    }

    setFilteredContacts(newContacts);
  }

  useEffect(() => {
    if (contacts.size) {
      console.log(selectedFilters);
      filterContacts();
    }
  }, [selectedFilters, contacts]);

  useEffect(() => {
    if (selectedSortOption && filteredContacts.size) {
      sortContacts();
    }
  }, [selectedSortOption]);

  useEffect(() => {
    const fetchListInvites = async () => {
      const invites = await DataStore.query(GuestInvite, (g) =>
        g.contactListID.eq(listID)
      );
      return invites;
    };

    const fetchListEvents = async (invites) => {
      const eventIDs = [...new Set(invites.map((invite) => invite.eventID))];
      let events = await Promise.all(
        Array.from(eventIDs).map(async (eventID) => {
          const event = await DataStore.query(Event, eventID);
          if (event.organizationID === dbUser.id) {
            return event;
          } else return undefined;
        })
      );
      events = events.filter(Boolean);
      events = events.sort(
        (a, b) => parseFloat(b.startDate) - parseFloat(a.startDate)
      );
      return events;
    };
    const fetchContactList = async () => {
      DataStore.query(ContactList, listID).then(setContactList);
    };
    (async () => {
      if (dbUser) {
        await fetchContactList();
        const invites = await fetchListInvites();
        const events = await fetchListEvents(invites);
        setListInvites(invites);
        setListEvents(events);
      }
    })();
  }, [listID, dbUser?.id]);

  useEffect(() => {
    const fetchContacts = async () => {
      const result = await DataStore.query(
        Contact,
        (c) => c.contactListID.eq(listID),
        { sort: (s) => s.avgScore(SortDirection.DESCENDING) }
      );
      const contactsMap = new Map(
        result.map((contact) => [contact.id, contact])
      );
      setContacts(contactsMap);
    };

    fetchContacts();

    const contactSubscription = DataStore.observe(Contact, (c) =>
      c.contactListID.eq(listID)
    ).subscribe((msg) => {
      if (
        msg.opType === "UPDATE" ||
        msg.opType === "INSERT" ||
        msg.opType === "DELETE"
      ) {
        fetchContacts();
      }
    });

    return () => contactSubscription.unsubscribe();
  }, [listID]);

  const loadingIn = !contactList || !listEvents || !listInvites;

  const pageTitle = `${contactList ? `${contactList.name} ` : ""} • CLIQInvite`;
  const pageDescription = `Welcome to your CLIQInvite Contacts page, where you can easily manage and organize your contacts. Effortlessly view, create, and track your contacts during rush season. With user-friendly design and advanced features, fraternity recruitment has never been more efficient.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/lists/edit`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <WebPortalNavigation activeScreen="/lists" />
      <div
        className="page-size-large"
        style={{
          alignSelf: "center",
          paddingTop: "2em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarOpen?.type}
            sx={{ width: "100%", fontFamily: "Poppins" }}
          >
            {snackbarOpen?.message}
          </Alert>
        </Snackbar>
        <ManuallyAddContacts
          loading={loadingIn}
          open={manuallyAddContactModalVisible}
          close={() => setManuallyAddContactModalVisible(false)}
          list={contactList}
          contacts={Array.from(contacts.values())}
        />

        <EditContactsHeader
          deleting={deleting}
          setDeleting={setDeleting}
          contacts={contacts}
          setFilteredContacts={setFilteredContacts}
          contactList={contactList}
          deleteContactsQueue={deleteContactsQueue}
          setDeleteContactsQueue={setDeleteContactsQueue}
          filteredContacts={filteredContacts}
          setSelectedSortOption={setSelectedSortOption}
          selectedSortOption={selectedSortOption}
          setManuallyAddContactModalVisible={setManuallyAddContactModalVisible}
          customizeOptions={customizeOptions}
          setCustomizeOptions={setCustomizeOptions}
          loading={loadingIn}
        />

        <Grid container style={{ flex: 1, width: "100%" }} spacing={1}>
          <Grid item xs={6.5}>
            <div style={{ flex: 1, width: "100%" }}>
              {loadingIn ? (
                Array.from({ length: 20 }, (_, i) => (
                  <Skeleton
                    variant="rounded"
                    sx={{ fontSize: "4em", mb: 1 }}
                    key={i.toString()}
                  />
                ))
              ) : !loading ? (
                !filteredContacts.size ? (
                  <Box>
                    <Typography variant="body2">
                      Add contacts to view them here
                    </Typography>
                  </Box>
                ) : (
                  Array.from(filteredContacts.values())
                    .slice(30 * currentPage, 30 * (currentPage + 1))
                    .map((item, index) => renderItem(item, index))
                )
              ) : (
                <div style={{ paddingTop: "50%", alignItems: "center" }}>
                  <p style={{ marginBottom: "3%" }}>
                    Calculating{" "}
                    {selectedSortOption === "cliqScore"
                      ? "CliqInvite scores"
                      : "event attendance"}
                  </p>
                  <BeatLoader color={colors.primaryColor} size={20} />
                </div>
              )}
            </div>
            {loadingIn
              ? null
              : filteredContacts.size > 30 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 2,
                      marginBottom: 2,
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
                      }
                    >
                      <ArrowBackIosNew />
                    </IconButton>
                    {Array.from({
                      length: Math.ceil(filteredContacts.size / 30),
                    }).map((_, index) => (
                      <p
                        onClick={() => setCurrentPage(index)}
                        style={{
                          margin: 0,
                          marginLeft: 4,
                          marginRight: 4,
                          cursor: "pointer",
                          fontWeight: index === currentPage ? 700 : 400,
                          opacity: index === currentPage ? 1 : 0.6,
                        }}
                        key={index.toString()}
                      >
                        {index + 1}
                      </p>
                    ))}
                    <IconButton
                      onClick={() =>
                        setCurrentPage((prevPage) =>
                          Math.min(
                            prevPage + 1,
                            Math.floor(filteredContacts.size / 30)
                          )
                        )
                      }
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </Box>
                )}
          </Grid>
          <Grid item xs={5.5}>
            <ContactFiltersDisplay
              eventFilters={
                selectedFilters.filter(
                  (f) =>
                    f.type === "INVITED" ||
                    f.type === "ATTENDED" ||
                    f.type === "NOTINVITED"
                ).length
                  ? selectedFilters
                      .filter(
                        (f) =>
                          f.type === "INVITED" ||
                          f.type === "ATTENDED" ||
                          f.type === "NOTINVITED"
                      )
                      .map((selectedFilter) => {
                        return {
                          ...selectedFilter,
                          eventName: listEvents.find(
                            (e) => e.id === selectedFilter.filter
                          )?.name,
                        };
                      })
                  : []
              }
              metricFilters={selectedFilters.filter(
                (f) =>
                  f.type === "avgScore" ||
                  f.type === "cliqScore" ||
                  f.type === "events"
              )}
              setSelectedFilters={setSelectedFilters}
              contacts={contacts}
              setFilteredContacts={setFilteredContacts}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              listEvents={listEvents}
              filteredContacts={filteredContacts}
              listInvites={listInvites}
              contactList={contactList}
              setSnackbarOpen={setSnackbarOpen}
              customizeOptions={customizeOptions}
              loading={loadingIn}
            />
            <ContactEventsDisplay
              loading={loadingIn}
              listEvents={listEvents}
              listInvites={listInvites}
              setSelectedFilters={setSelectedFilters}
              setSelectedEvent={setSelectedEvent}
              selectedEvent={selectedEvent}
              contacts={contacts}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EditContacts;
