import "./Footer.css";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../theme/colors";
import FooterLinkText from "./FooterLinkText";
import { Instagram, LinkedIn, YouTube } from "@mui/icons-material";

const Footer = () => {
  const footerLinkSections = [
    {
      title: "PRODUCT",
      sections: [
        { text: "Social", navigationScreen: "/social" },
        { text: "Rush", navigationScreen: "/rush" },
        { text: "Philanthropy", navigationScreen: "/philanthropy" },
      ],
    },
    {
      title: "CLIQ",
      sections: [
        { text: "About Us", navigationScreen: "/about" },
        { text: "Contact Us", navigationScreen: "/contact" },
      ],
    },
    {
      title: "Resources",
      sections: [
        // { text: "Pricing", navigationScreen: "/plans" },
        { text: "Tutorials", navigationScreen: "/tutorials" },
        { text: "Legal Stuff", navigationScreen: "/legal" },
      ],
    },
  ];

  const socialMedias = [
    {
      socialIcon: <Instagram sx={{ color: colors.primaryText }} />,
      link: "https://www.instagram.com/cliqinvite?igsh=MWFxM240a2d0bnN5aw%3D%3D&utm_source=qr",
    },
    {
      socialIcon: <LinkedIn sx={{ color: colors.primaryText }} />,
      link: "https://www.linkedin.com/company/89928141",
    },
    {
      socialIcon: <YouTube sx={{ color: colors.primaryText }} />,
      link: "https://www.youtube.com/@CliqInvite",
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        py: 4,
        background:
          "radial-gradient(ellipse at 100% 100%, #a020f030, black 80%)",
      }}
    >
      <Box
        className="default-page-size"
        sx={{ display: "flex", flexDirection: "column", gap: 3 }}
      >
        <Box sx={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          <Box
            sx={{
              flex: { xs: "none", md: 1 },
              pr: "1.5rem",
              width: { xs: "100vw", md: "auto" },
            }}
          >
            <img
              src={require("../assets/CLIQLogo.png")}
              style={{ height: "6rem", borderRadius: 8, marginBottom: "1rem" }}
            ></img>
            <Typography
              variant="h3"
              sx={{
                mb: "2.5rem",
                fontSize: { xs: "clamp(1rem, 6vw, 2.5rem)", md: "1.5rem" },
              }}
            >
              For Greek Life.{" "}
              <span style={{ color: colors.primaryColor }}>By Greek Life.</span>
            </Typography>
          </Box>
          {footerLinkSections.map(({ title, sections }) => (
            <Box
              key={title}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                pr: "3rem",
                width: { xs: "100vw", sm: "auto" },
                mb: { xs: "2em", sm: 0 },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "1rem" }}
              >
                {title}
              </Typography>
              {sections.map(({ text, navigationScreen }) => (
                <FooterLinkText
                  text={text}
                  navigationScreen={navigationScreen}
                  key={text}
                />
              ))}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", sm: "row" },
            alignItems: "center",
            mt: { xs: "2rem" },
            gap: {xs: 1, sm: 0}
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 200, fontSize: ".75rem" }}
          >
            @ 2023 CliqInvite. All Rights Reserved.
          </Typography>

          <Box
            sx={{
              display: "flex",
              marginLeft: { xs: 0, sm: "auto" } ,
              alignItems: "center",
              gap: 1,
            }}
          >
            {socialMedias.map(({ socialIcon, link }) => (
              <Box
                sx={{ cursor: "pointer" }}
                key={link}
                onClick={() => window.open(link, "_blank", "noreferrer")}
              >
                {socialIcon}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
