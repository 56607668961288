const memberPrices = [
  { members: 40, price: 3.0 },
  { members: 40, price: 2.7 },
  { members: 70, price: 2.4 },
  { members: 70, price: 2.0 },
  { members: 180, price: 1 },
];

export const calculateSubscriptionCosts = ({
  totalMembers, //10 20 30 ... 400
  planType,
}) => {
  const MEMBER_COST = 3;
  const CHAPTER_COST = 19.99;

  let monthlyPrice = CHAPTER_COST;
  let memberCounter = totalMembers;

  memberPrices.forEach(({ members, price }) => {
    if (memberCounter) {
      let numPriceBracketMembers = Math.min(members, memberCounter);
      memberCounter -= numPriceBracketMembers;
      monthlyPrice += numPriceBracketMembers * price;
    } else {
      return;
    }
  });

  const undiscountedMonthlyCost = CHAPTER_COST + MEMBER_COST * totalMembers;

  const percentageOfPriceWithoutDiscount = Math.round(
    (monthlyPrice * 100) / undiscountedMonthlyCost
  );
  const discountPercentage = 100 - percentageOfPriceWithoutDiscount;
  const memberCost = monthlyPrice - CHAPTER_COST;
  const individualMemberCost = (memberCost / totalMembers).toFixed(2);

  return {
    memberCost,
    individualMemberCost,
    chapterCost: CHAPTER_COST,
    discountPercentage,
    totalCost: monthlyPrice,
  };
};
