import React from "react";
import "./FadeIn.css";
export default function FadeIn(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();

  React.useEffect(() => {
    if (!isVisible) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) =>
          setVisible((old) => {
            if (!old) return entry.isIntersecting;
            else return true;
          })
        );
      });
      let tempRef = domRef.current;
      observer.observe(tempRef);
      return () => observer.unobserve(tempRef);
    }
  }, [isVisible]);
  return (
    <div
      className={`to-fade-in ${isVisible ? "is-visible" : ""}`}
      style={{ width: "100%", overflow: "none" }}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
