import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import PublicHeader from "../../public/navigation/PublicHeader";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import "./Payments.css";
import { BeatLoader, ClipLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";
import { colors } from "../../theme/colors";
import { getModuleDate, getModuleTime } from "../../methods/getFormattedTime";
import Footer from "../../components/Footer";
import axios from "axios";
import { API, DataStore } from "aws-amplify";
import { GuestInvite } from "../../models";
import {
  onCreateGuestInvite,
  onUpdateGuestInvite,
} from "../../graphql/subscriptions";
import {
  guestInviteByPaymentIntentID,
  listGuestInvites,
} from "../../graphql/queries";

const PaymentsDisplay = ({
  event,
  guestInvite,
  paymentIntentID,
  setSnackbarOpen,
  ticketLink,
}) => {
  const paymentStatus = paymentIntentID
    ? guestInvite
      ? "CONFIRMED"
      : "CONFIRMING"
    : "PAYMENT";
  const stripe = useStripe();
  const elements = useElements();
  const [selectedNum, setSelectedNum] = useState(1);
  const [clientSecret, setClientSecret] = useState("");
  const [unconfirmedGuestInvite, setUnconfirmedGuestInvite] = useState(null);
  const [currentPaymentIntentID, setCurrentPaymentIntentID] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ticketDestination, setTicketDestination] = useState("PHONE");
  const [name, setName] = useState("");
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const { orgMemberID, guestOrgID } = useParams();

  const [submitting, setSubmitting] = useState(false);

  const fetchUnconfirmedGuestInvite = async () => {
    let nextToken = null;
    let guestInvites = [];
    do {
      const unconfirmedGuestInviteResponse = await API.graphql({
        query: guestInviteByPaymentIntentID,
        variables: {
          paymentIntentID: currentPaymentIntentID,
          filter: {
            eventID: {
              eq: event.id,
            },
            _deleted: {
              ne: true,
            },
          },
          nextToken,
        },
      });
      console.log(unconfirmedGuestInviteResponse)
      guestInvites = guestInvites.concat(
        unconfirmedGuestInviteResponse.data.guestInviteByPaymentIntentID.items
      );
      nextToken =
        unconfirmedGuestInviteResponse.data.guestInviteByPaymentIntentID.nextToken;
    } while (nextToken && !guestInvites.length);

    if (
      guestInvites.length &&
      (guestInvites[0].status === "PENDING" ||
        guestInvites[0].status === "INSUFFICIENT_TICKETS")
    ) {
      setUnconfirmedGuestInvite(guestInvites[0]);
    }
  };

  useEffect(() => {
    if (currentPaymentIntentID && event) {
      fetchUnconfirmedGuestInvite();
      const createSubscription = API.graphql({
        query: onCreateGuestInvite,
        variables: {
          filter: {
            paymentIntentID: {
              eq: currentPaymentIntentID,
            },
            eventID: {
              eq: event.id,
            },
            _deleted: {
              ne: true,
            },
          },
        },
      }).subscribe({ next: (eventData) => fetchUnconfirmedGuestInvite() });
      const updateSubscription = API.graphql({
        query: onUpdateGuestInvite,
        variables: {
          filter: {
            paymentIntentID: {
              eq: currentPaymentIntentID,
            },
            eventID: {
              eq: event.id,
            },
            _deleted: {
              ne: true,
            },
          },
        },
      }).subscribe({ next: (eventData) => fetchUnconfirmedGuestInvite() });

      return () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
      };
    }
  }, [currentPaymentIntentID, event]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  useEffect(() => {
    (async () => {
      if (!unconfirmedGuestInvite || !clientSecret) {
        return;
      }
      if (unconfirmedGuestInvite.status === "INSUFFICIENT_TICKETS") {
        const fetchTicketLinkResponse = await axios.get(
          process.env.REACT_APP_TICKET_LINK_ENDPOINT,
          {
            params: {
              ticketLinkID: ticketLink.id,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const { ticketLink: fetchedTicketLink, event } =
          fetchTicketLinkResponse.data;

        let message;
        if (selectedNum > event.maxTickets - event.ticketsSold) {
          if (event.maxTickets <= event.ticketsSold) {
            message =
              "Sorry, tickets for this event have already sold out. If you suspect an error, please get in touch with the hosting organization for further details.";
          } else {
            message = `Sorry, there are only ${
              event.maxTickets - event.ticketsSold
            } tickets remaining for this event. Please note that these figures are subject to rapid change if we are experiencing high purchase volume.`;
          }
        } else if (
          selectedNum >
          fetchedTicketLink.maxTickets - fetchedTicketLink.ticketsSold
        ) {
          if (fetchedTicketLink.maxTickets <= fetchedTicketLink.ticketsSold) {
            message =
              "Sorry, tickets allocated for this link have sold out. If you suspect an error, please get in touch with the organization member who sent you the link.";
          } else {
            message = `Sorry, there are only ${
              fetchedTicketLink.maxTickets - fetchedTicketLink.ticketsSold
            } tickets remaining for this link. Please note that these figures are subject to rapid change if we are experiencing high purchase volume.`;
          }
        }
        setSnackbarOpen({
          type: "error",
          message,
        });

        setSubmitting(false);
        return;
      }

      let return_url = "";
      if (process.env.NODE_ENV === "production") {
        return_url += `https://www.cliqinvite.com/`;
      } else {
        return_url += `http://localhost:3000/`;
      }
      return_url += `payments/${ticketLink.id}`;

      const result = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: return_url,
        },
      });

      if (result.error) {
        setSnackbarOpen({ type: "error", message: result.error.message });
        setSubmitting(false);
      }
    })();
  }, [clientSecret, unconfirmedGuestInvite]);

  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters except '+' from the input string
    let cleanedInput = input.replace(/[^\d+]/g, "");

    let formattedPhoneNumber = "";

    // Check if the input starts with '+' (indicating a country code is present)
    if (cleanedInput.startsWith("+")) {
      // Extract the country code
      let countryCodeEndIndex = cleanedInput.indexOf("1") === 1 ? 2 : 1;
      let countryCode = cleanedInput.slice(0, countryCodeEndIndex);
      let phoneNumber = cleanedInput.slice(countryCodeEndIndex);

      // Format the phone number based on your desired format
      if (phoneNumber.length > 3) {
        formattedPhoneNumber = `${countryCode} (${phoneNumber.slice(0, 3)})`;

        if (phoneNumber.length > 6) {
          formattedPhoneNumber += ` ${phoneNumber.slice(
            3,
            6
          )}-${phoneNumber.slice(6)}`;
        } else {
          formattedPhoneNumber += ` ${phoneNumber.slice(3)}`;
        }
      } else {
        formattedPhoneNumber = cleanedInput;
      }
    } else {
      // Format the phone number without the country code
      if (cleanedInput.length > 3) {
        formattedPhoneNumber = `(${cleanedInput.slice(0, 3)})`;

        if (cleanedInput.length > 6) {
          formattedPhoneNumber += ` ${cleanedInput.slice(
            3,
            6
          )}-${cleanedInput.slice(6)}`;
        } else {
          formattedPhoneNumber += ` ${cleanedInput.slice(3)}`;
        }
      } else {
        formattedPhoneNumber = cleanedInput;
      }
    }

    return formattedPhoneNumber;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) {
      return;
    }
    setSubmitting(true);

    if (!stripe) {
      return;
    }
    let finalPhoneNum = phone;

    if (ticketDestination === "EMAIL") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(email)) {
        setSnackbarOpen({
          type: "error",
          message: "Please enter a valid email",
        });
        setSubmitting(false);
        return;
      }
    } else {
      finalPhoneNum = finalPhoneNum.replace(/[\(\)-\s]/g, "");
      if (!finalPhoneNum.includes("+")) {
        finalPhoneNum = `+1${finalPhoneNum}`;
      }
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      if (!phoneRegex.test(finalPhoneNum)) {
        setSnackbarOpen({
          type: "error",
          message: "Please enter a valid phone number",
        });
        setSubmitting(false);
        return;
      }
    }
    if (name.trim().length < 1) {
      setSnackbarOpen({
        type: "error",
        message: "Please enter your full name",
      });
      setSubmitting(false);

      return;
    }

    if (isNaN(selectedNum) || selectedNum < 1) {
      setSnackbarOpen({
        type: "error",
        message: "Please enter a valid number of ticket scans to purchase",
      });
      setSubmitting(false);

      return;
    }

    // Trigger form validation and wallet collection
    let isError = false;
    let alertString = "";

    const { error: submitError } = await elements.submit();
    if (submitError) {
      alertString += submitError.message;
      isError = true;
    }

    if (isError) {
      setSnackbarOpen({ type: "error", message: alertString });
      setSubmitting(false);
      return;
    }

    try {
      setSnackbarOpen({ type: "info", message: "Processing payment..." });
      const res = await axios.post(
        process.env.REACT_APP_CREATE_PAYMENT_INTENT_API_ENDPOINT,
        {
          currency: "usd",
          numPurchased: selectedNum,
          eventID: event.id,
          name,
          phone: finalPhoneNum,
          ticketDestination,
          email,
          test: process.env.NODE_ENV !== "production",
          orgMemberID: ticketLink.creatorID,
          ticketLinkID: ticketLink.id,
          guestOrgID: guestOrgID || null,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      setClientSecret(res.data.client_secret);
      setCurrentPaymentIntentID(res.data.paymentIntentID);
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Error processing payment. Please try again later.",
      });
      setSubmitting(false);
      console.error(err);
    }
  };

  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/payments/${event.id}/${orgMemberID}`;
  const pageDescription = `Securely purchase your ticket for ${
    event?.name
  } on ${getModuleDate(event.startDate)} at ${getModuleTime(
    event.startDate
  )}, hosted by ${event.organizationInfo[0]}`;

  if (paymentStatus === "CONFIRMED" || paymentStatus === "CONFIRMING") {
    const paymentStatusPageTitle = `Payment Confirm${
      paymentStatus === "CONFIRMED" ? "ed" : "ing..."
    }`;
    return (
      <>
        <Helmet>
          <title>{paymentStatusPageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={paymentStatusPageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={pageImageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <div
          className="page-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PublicHeader />

          <Box
            className="profile-container"
            style={{
              height: "calc(100svh - 10em)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "10em",
            }}
          >
            <Typography variant="h1" style={{ margin: 0, fontSize: "2.5em" }}>
              Payment confirm{paymentStatus === "CONFIRMING" ? "ing..." : "ed!"}
            </Typography>
            <Box sx={{ mb: "auto" }}>
              <p
                style={{ textAlign: "center", fontSize: "1.2em", opacity: 0.7 }}
              >
                {paymentStatus === "CONFIRMING"
                  ? "Confirming your payment. We will automatically send a link to your ticket to the email or phone number you provided during your purchase."
                  : "We have automatically sent a link to your ticket to the email or phone number you provided during your purchase."}
              </p>
              {paymentStatus === "CONFIRMED" && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "2em",
                    justifyContent: "center",
                    marginTop: "2em",
                    fontSize: "1.65em",
                  }}
                >
                  <b
                    className="link"
                    style={{ cursor: "pointer", color: colors.primaryColor }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://www.cliqinvite.com/ticket/${event.id}/${guestInvite.qrCode}`
                      );
                      setSnackbarOpen({
                        type: "success",
                        message: "Copied ticket link to clipboard",
                      });
                    }}
                  >
                    Copy link
                  </b>
                  <b
                    className="link"
                    style={{
                      cursor: "pointer",

                      color: colors.primaryColor,
                    }}
                    onClick={() =>
                      window.open(
                        `https://www.cliqinvite.com/ticket/${event.id}/${guestInvite.qrCode}`,
                        `_blank`,
                        `noreferrer`
                      )
                    }
                  >
                    <b style={{ margin: 0 }}>View QR</b>
                  </b>
                </Box>
              )}
            </Box>
            <Footer />
          </Box>
        </div>
      </>
    );
  }
  const pageTitle = `Purchase Event Ticket for ${event?.name}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <div
        className="page-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "0em",
        }}
      >
        <PublicHeader />
        <div className="profile-container payments-container">
          <Grid
            container
            sx={{
              minHeight: "65svh",
              mb: 8,
            }}
          >
            <Grid
              item
              xs={12}
              lg={4.5}
              sx={{
                px: 3,
                py: 2,
                bgcolor: colors.secondaryBackground,
                borderTopLeftRadius: { xs: 16 },
                borderTopRightRadius: { xs: 16, lg: 0 },
                borderBottomLeftRadius: { xs: 0, lg: 16 },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.75,
                    mb: "15%",
                    flexWrap: "wrap-reverse",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "1.3em", marginRight: "auto" }}
                  >
                    Secure Checkout
                  </Typography>
                  <img
                    style={{ objectFit: "contain", height: "1.8em" }}
                    src={require("../../assets/Stripe-Badge.png")}
                  />
                </Box>
                <Typography variant="body2" sx={{ fontSize: "1.1em" }}>
                  Purchase a ticket to
                </Typography>
                <Typography variant="h3" sx={{ fontSize: "1.6em" }}>
                  {event.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 2,
                  }}
                >
                  <b
                    style={{
                      color: colors.secondaryText,
                      fontStyle: "normal",
                      fontWeight: 300,
                    }}
                  >
                    hosted by
                  </b>{" "}
                  {event?.organizationInfo[0]}
                </Typography>
                <Typography variant="body1">
                  <b
                    style={{
                      color: colors.secondaryText,
                      fontStyle: "normal",
                      fontWeight: 300,
                    }}
                  >
                    on
                  </b>{" "}
                  {getModuleDate(event.startDate)}{" "}
                  <b
                    style={{
                      color: colors.secondaryText,
                      fontStyle: "normal",
                      fontWeight: 300,
                    }}
                  >
                    at
                  </b>{" "}
                  {getModuleTime(event.startDate)}
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: { xs: "end", md: "start" },
                  mt: { xs: "2.5em", lg: "auto" },
                  display: { xs: "flex", lg: "block" },
                  alignItems: "center",
                  gap: 2,
                  mb: { xs: 0, lg: 2 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "flex-end", lg: "flex-start" },
                    fontSize: "1.5em",
                    gap: 1,
                    alignSelf: "flex-end",
                  }}
                >
                  <TextField
                    id="numTickets"
                    type="number"
                    InputProps={{
                      sx: {
                        color: colors.primaryText,
                        height: "1.6em",
                      },
                    }}
                    value={selectedNum}
                    onChange={(e) => {
                      let finalNum;
                      if (!e.target.value) {
                        finalNum = "";
                      } else if (e.target.value < 1) {
                        finalNum = 1;
                      } else if (e.target.value > 999) {
                        finalNum = 999;
                      } else {
                        finalNum = parseInt(e.target.value);
                      }
                      setSelectedNum(finalNum);
                      elements.update({
                        mode: "payment",
                        currency: "usd",
                        amount:
                          event.ticketInformation.price * 100 * finalNum ||
                          event.ticketInformation.price * 100,
                      });
                    }}
                    onBlur={() => {
                      if (isNaN(selectedNum) || selectedNum < 1) {
                        setSelectedNum(1);
                      }
                    }}
                    size="small"
                    sx={{
                      bgcolor: colors.backgroundHighlight,
                      borderRadius: 1,
                      fontFamily: "Poppins",
                      fontSize: "1em",
                      margin: 0,
                      width: "3em",
                      color: "white",
                      padding: 0,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ fontSize: ".8em", alignSelf: "flex-end" }}
                  >
                    ticket scan{selectedNum !== 1 && "s"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: { xs: "flex-end", lg: "flex-start" },
                    gap: 1,
                    ml: { xs: "auto", lg: 0 },
                    mt: 2,
                    borderBottom: "1px grey solid",
                  }}
                >
                  <Typography variant="h3" style={{ fontSize: "2em" }}>
                    $
                    {
                      `${(
                        event?.ticketInformation?.price * selectedNum || 0
                      ).toFixed(2)}`.split(".")[0]
                    }
                    .
                    {
                      `${(
                        event?.ticketInformation?.price * selectedNum || 0
                      ).toFixed(2)}`.split(".")[1]
                    }{" "}
                    total
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={7.5} className="payment-stripe-container">
              <Box
                component={"form"}
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                    textAlign: "center",
                    marginBottom: "1%",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      background: "black",
                      zIndex: 1,
                      padding: "0 10px",
                      position: "relative",
                      color: "white",
                    }}
                  >
                    Ticket e-delivery
                  </p>
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      height: "1px",
                      background: "grey",
                      top: "50%",
                    }}
                  ></div>
                </div>

                <div style={{ display: "block", marginBottom: "30px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 0.2,
                    }}
                  >
                    <label className="stripe-label">
                      {ticketDestination === "PHONE" ? "Phone" : "Email"}
                    </label>
                    <Typography
                      variant="body1"
                      onClick={() =>
                        setTicketDestination((old) =>
                          old === "PHONE" ? "EMAIL" : "PHONE"
                        )
                      }
                      sx={{
                        fontSize: "12px",
                        transition: "all 200ms ease-in-out",
                        cursor: "pointer",
                        ":hover": {
                          opacity: 0.6,
                        },
                      }}
                    >
                      Use {ticketDestination === "PHONE" ? "Email" : "Phone"}
                    </Typography>
                  </Box>
                  <input
                    style={{ marginBottom: "10px" }}
                    className="stripe-input"
                    name={ticketDestination === "PHONE" ? "phone" : "email"}
                    id={ticketDestination === "PHONE" ? "phone" : "email"}
                    type={ticketDestination === "PHONE" ? "tel" : "email"}
                    placeholder={
                      ticketDestination === "PHONE"
                        ? "enter number including country code"
                        : "example@gmail.com"
                    }
                    value={ticketDestination === "PHONE" ? phone : email}
                    onChange={(e) => {
                      ticketDestination === "PHONE"
                        ? setPhone(formatPhoneNumber(e.target.value))
                        : setEmail(e.target.value);
                    }}
                  ></input>
                  <label className="stripe-label">Name</label>
                  <input
                    className="stripe-input"
                    name="name"
                    id="name"
                    placeholder="name displays upon ticket scan"
                    maxLength={45}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                      textAlign: "center",
                      marginBottom: "5%",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        background: "black",
                        zIndex: 1,
                        padding: "0 10px",
                        position: "relative",
                        color: "white",
                      }}
                    >
                      Payment information
                    </p>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        height: "1px",
                        background: "grey",
                        top: "50%",
                      }}
                    ></div>
                  </div>
                  <PaymentElement
                    options={{
                      wallets: { applePay: "auto", googlePay: "auto" },
                    }}
                  />
                </div>
                {!submitting ? (
                  <Button
                    sx={{
                      mt: 3,
                    }}
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={(!stripe || submitting) && shouldSubmit}
                  >
                    Pay
                  </Button>
                ) : (
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <BeatLoader
                      loading={true}
                      color={colors.primaryColor}
                      size={"1.2em"}
                    />
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PaymentsDisplay;
