/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPayIn = /* GraphQL */ `
  query GetPayIn($id: ID!) {
    getPayIn(id: $id) {
      id
      amount
      accountID
      Event {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numTickets
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      payInEventId
    }
  }
`;
export const listPayIns = /* GraphQL */ `
  query ListPayIns(
    $filter: ModelPayInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        accountID
        numTickets
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        payInEventId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayIns = /* GraphQL */ `
  query SyncPayIns(
    $filter: ModelPayInFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayIns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amount
        accountID
        numTickets
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        payInEventId
      }
      nextToken
      startedAt
    }
  }
`;
export const payInsByAccount = /* GraphQL */ `
  query PayInsByAccount(
    $accountID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPayInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payInsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        accountID
        numTickets
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        payInEventId
      }
      nextToken
      startedAt
    }
  }
`;
export const getPayout = /* GraphQL */ `
  query GetPayout($id: ID!) {
    getPayout(id: $id) {
      id
      isFulfilled
      amount
      accountID
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPayouts = /* GraphQL */ `
  query ListPayouts(
    $filter: ModelPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isFulfilled
        amount
        accountID
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayouts = /* GraphQL */ `
  query SyncPayouts(
    $filter: ModelPayoutFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayouts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        isFulfilled
        amount
        accountID
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const payoutsByAccount = /* GraphQL */ `
  query PayoutsByAccount(
    $accountID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayoutsByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isFulfilled
        amount
        accountID
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      Payouts {
        nextToken
        startedAt
      }
      venmo
      PayIns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getScore = /* GraphQL */ `
  query GetScore($id: ID!) {
    getScore(id: $id) {
      id
      score
      orgMemberID
      contactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listScores = /* GraphQL */ `
  query ListScores(
    $filter: ModelScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        score
        orgMemberID
        contactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncScores = /* GraphQL */ `
  query SyncScores(
    $filter: ModelScoreFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        score
        orgMemberID
        contactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const scoresByContact = /* GraphQL */ `
  query ScoresByContact(
    $contactID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scoresByContact(
      contactID: $contactID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        score
        orgMemberID
        contactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      number
      notes
      contactListID
      Scores {
        nextToken
        startedAt
      }
      avgScore
      creatorID
      photo
      accessingUsers
      coOwners
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        number
        notes
        contactListID
        avgScore
        creatorID
        photo
        accessingUsers
        coOwners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        number
        notes
        contactListID
        avgScore
        creatorID
        photo
        accessingUsers
        coOwners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const contactsByContactList = /* GraphQL */ `
  query ContactsByContactList(
    $contactListID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByContactList(
      contactListID: $contactListID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        number
        notes
        contactListID
        avgScore
        creatorID
        photo
        accessingUsers
        coOwners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContactList = /* GraphQL */ `
  query GetContactList($id: ID!) {
    getContactList(id: $id) {
      id
      creatorID
      accessingUsers
      coOwners
      name
      numContacts
      Contacts {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContactLists = /* GraphQL */ `
  query ListContactLists(
    $filter: ModelContactListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creatorID
        accessingUsers
        coOwners
        name
        numContacts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContactLists = /* GraphQL */ `
  query SyncContactLists(
    $filter: ModelContactListFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContactLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        creatorID
        accessingUsers
        coOwners
        name
        numContacts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const contactListsByCreatorID = /* GraphQL */ `
  query ContactListsByCreatorID(
    $creatorID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContactListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactListsByCreatorID(
      creatorID: $creatorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creatorID
        accessingUsers
        coOwners
        name
        numContacts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      id
      isPinned
      announcement
      eventID
      title
      visibleRoles
      link
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isPinned
        announcement
        eventID
        title
        visibleRoles
        link
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAnnouncements = /* GraphQL */ `
  query SyncAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnnouncements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        isPinned
        announcement
        eventID
        title
        visibleRoles
        link
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getScan = /* GraphQL */ `
  query GetScan($id: ID!) {
    getScan(id: $id) {
      id
      date
      eventID
      isValid
      category
      isScanOut
      guestInviteID
      orgMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listScans = /* GraphQL */ `
  query ListScans(
    $filter: ModelScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        eventID
        isValid
        category
        isScanOut
        guestInviteID
        orgMemberID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncScans = /* GraphQL */ `
  query SyncScans(
    $filter: ModelScanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        eventID
        isValid
        category
        isScanOut
        guestInviteID
        orgMemberID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      createdAt
      objectID
      message
      eventName
      senderName
      image
      senderID
      senderType
      read
      userID
      organizationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        objectID
        message
        eventName
        senderName
        image
        senderID
        senderType
        read
        userID
        organizationID
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        createdAt
        objectID
        message
        eventName
        senderName
        image
        senderID
        senderType
        read
        userID
        organizationID
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationsByUser = /* GraphQL */ `
  query NotificationsByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        objectID
        message
        eventName
        senderName
        image
        senderID
        senderType
        read
        userID
        organizationID
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationsByOrganization = /* GraphQL */ `
  query NotificationsByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        objectID
        message
        eventName
        senderName
        image
        senderID
        senderType
        read
        userID
        organizationID
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGuestInvite = /* GraphQL */ `
  query GetGuestInvite($id: ID!) {
    getGuestInvite(id: $id) {
      id
      qrCode
      maxUses
      numUsed
      orgMemberID
      eventID
      category
      numInvalid
      label
      contactListID
      contactID
      cost
      paymentIntentID
      photoID
      photoStatus
      guestOrgID
      status
      ticketLinkID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGuestInvites = /* GraphQL */ `
  query ListGuestInvites(
    $filter: ModelGuestInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuestInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        qrCode
        maxUses
        numUsed
        orgMemberID
        eventID
        category
        numInvalid
        label
        contactListID
        contactID
        cost
        paymentIntentID
        photoID
        photoStatus
        guestOrgID
        status
        ticketLinkID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGuestInvites = /* GraphQL */ `
  query SyncGuestInvites(
    $filter: ModelGuestInviteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGuestInvites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        qrCode
        maxUses
        numUsed
        orgMemberID
        eventID
        category
        numInvalid
        label
        contactListID
        contactID
        cost
        paymentIntentID
        photoID
        photoStatus
        guestOrgID
        status
        ticketLinkID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const guestInvitesByEvent = /* GraphQL */ `
  query GuestInvitesByEvent(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGuestInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    guestInvitesByEvent(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        qrCode
        maxUses
        numUsed
        orgMemberID
        eventID
        category
        numInvalid
        label
        contactListID
        contactID
        cost
        paymentIntentID
        photoID
        photoStatus
        guestOrgID
        status
        ticketLinkID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const guestInvitesByContactList = /* GraphQL */ `
  query GuestInvitesByContactList(
    $contactListID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGuestInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    guestInvitesByContactList(
      contactListID: $contactListID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        qrCode
        maxUses
        numUsed
        orgMemberID
        eventID
        category
        numInvalid
        label
        contactListID
        contactID
        cost
        paymentIntentID
        photoID
        photoStatus
        guestOrgID
        status
        ticketLinkID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const guestInvitesByContactID = /* GraphQL */ `
  query GuestInvitesByContactID(
    $contactID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGuestInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    guestInvitesByContactID(
      contactID: $contactID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        qrCode
        maxUses
        numUsed
        orgMemberID
        eventID
        category
        numInvalid
        label
        contactListID
        contactID
        cost
        paymentIntentID
        photoID
        photoStatus
        guestOrgID
        status
        ticketLinkID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const guestInviteByPaymentIntentID = /* GraphQL */ `
  query GuestInviteByPaymentIntentID(
    $paymentIntentID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGuestInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    guestInviteByPaymentIntentID(
      paymentIntentID: $paymentIntentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        qrCode
        maxUses
        numUsed
        orgMemberID
        eventID
        category
        numInvalid
        label
        contactListID
        contactID
        cost
        paymentIntentID
        photoID
        photoStatus
        guestOrgID
        status
        ticketLinkID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTicketLink = /* GraphQL */ `
  query GetTicketLink($id: ID!) {
    getTicketLink(id: $id) {
      id
      creatorID
      eventID
      maxTickets
      ticketsSold
      category
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTicketLinks = /* GraphQL */ `
  query ListTicketLinks(
    $filter: ModelTicketLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creatorID
        eventID
        maxTickets
        ticketsSold
        category
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTicketLinks = /* GraphQL */ `
  query SyncTicketLinks(
    $filter: ModelTicketLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        creatorID
        eventID
        maxTickets
        ticketsSold
        category
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      invitesGiven
      invitesUsed
      hasResponded
      response
      userId
      organizationInfo
      eventInfo
      qrCode
      isOrg
      organizationID
      eventID
      guestOrgID
      membersOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInvites = /* GraphQL */ `
  query ListInvites(
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invitesGiven
        invitesUsed
        hasResponded
        response
        userId
        organizationInfo
        eventInfo
        qrCode
        isOrg
        organizationID
        eventID
        guestOrgID
        membersOnly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInvites = /* GraphQL */ `
  query SyncInvites(
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        invitesGiven
        invitesUsed
        hasResponded
        response
        userId
        organizationInfo
        eventInfo
        qrCode
        isOrg
        organizationID
        eventID
        guestOrgID
        membersOnly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const invitesByUser = /* GraphQL */ `
  query InvitesByUser(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitesByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitesGiven
        invitesUsed
        hasResponded
        response
        userId
        organizationInfo
        eventInfo
        qrCode
        isOrg
        organizationID
        eventID
        guestOrgID
        membersOnly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const invitesByEvent = /* GraphQL */ `
  query InvitesByEvent(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitesByEvent(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitesGiven
        invitesUsed
        hasResponded
        response
        userId
        organizationInfo
        eventInfo
        qrCode
        isOrg
        organizationID
        eventID
        guestOrgID
        membersOnly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      address
      name
      description
      startDate
      endDate
      organizationInfo
      organizationID
      Invites {
        nextToken
        startedAt
      }
      GuestInvites {
        nextToken
        startedAt
      }
      Scans {
        nextToken
        startedAt
      }
      Jobs {
        nextToken
        startedAt
      }
      isGuestListPublic
      categories {
        category
      }
      Announcements {
        nextToken
        startedAt
      }
      eventCode
      ticketInformation {
        price
      }
      maxTickets
      ticketsSold
      numGuests
      requirePhotoID
      jobsNotificationSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const eventByOrganization = /* GraphQL */ `
  query EventByOrganization(
    $organizationID: ID!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByOrganization(
      organizationID: $organizationID
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        address
        name
        description
        startDate
        endDate
        organizationInfo
        organizationID
        isGuestListPublic
        eventCode
        maxTickets
        ticketsSold
        numGuests
        requirePhotoID
        jobsNotificationSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      profilePic
      name
      school
      shortSchool
      searchName
      pushTokens
      adminOrganizationIDs
      Notifications {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profilePic
        name
        school
        shortSchool
        searchName
        pushTokens
        adminOrganizationIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        profilePic
        name
        school
        shortSchool
        searchName
        pushTokens
        adminOrganizationIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganizationMember = /* GraphQL */ `
  query GetOrganizationMember($id: ID!) {
    getOrganizationMember(id: $id) {
      id
      role
      memberInfo
      isConfirmed
      organizationID
      userID
      analytics {
        eventID
        invitesGiven
        validScans
        invalidScans
        revenue
        ticketsSold
      }
      analyticsUpdatedAt
      adminStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrganizationMembers = /* GraphQL */ `
  query ListOrganizationMembers(
    $filter: ModelOrganizationMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        memberInfo
        isConfirmed
        organizationID
        userID
        analyticsUpdatedAt
        adminStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizationMembers = /* GraphQL */ `
  query SyncOrganizationMembers(
    $filter: ModelOrganizationMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizationMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        role
        memberInfo
        isConfirmed
        organizationID
        userID
        analyticsUpdatedAt
        adminStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const organizationMembersByOrganization = /* GraphQL */ `
  query OrganizationMembersByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationMembersByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        memberInfo
        isConfirmed
        organizationID
        userID
        analyticsUpdatedAt
        adminStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const organizationMemberByUser = /* GraphQL */ `
  query OrganizationMemberByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationMemberByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        memberInfo
        isConfirmed
        organizationID
        userID
        analyticsUpdatedAt
        adminStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      school
      profilePic
      name
      email
      bio
      createdRoles
      searchName
      bannerPic
      shortSchool
      pushTokens
      plan
      adminIDs
      SubscriptionPlans {
        nextToken
        startedAt
      }
      OrganizationMembers {
        nextToken
        startedAt
      }
      Events {
        nextToken
        startedAt
      }
      Notifications {
        nextToken
        startedAt
      }
      Account {
        id
        venmo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationAccountId
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school
        profilePic
        name
        email
        bio
        createdRoles
        searchName
        bannerPic
        shortSchool
        pushTokens
        plan
        adminIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationAccountId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        school
        profilePic
        name
        email
        bio
        createdRoles
        searchName
        bannerPic
        shortSchool
        pushTokens
        plan
        adminIDs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationAccountId
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriptionPlan = /* GraphQL */ `
  query GetSubscriptionPlan($id: ID!) {
    getSubscriptionPlan(id: $id) {
      id
      planType
      isActive
      totalMembers
      cost
      billingCycle
      dateRenewalRequired
      stripeSubscriptionID
      promoFactor
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubscriptionPlans = /* GraphQL */ `
  query ListSubscriptionPlans(
    $filter: ModelSubscriptionPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        planType
        isActive
        totalMembers
        cost
        billingCycle
        dateRenewalRequired
        stripeSubscriptionID
        promoFactor
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubscriptionPlans = /* GraphQL */ `
  query SyncSubscriptionPlans(
    $filter: ModelSubscriptionPlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubscriptionPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        planType
        isActive
        totalMembers
        cost
        billingCycle
        dateRenewalRequired
        stripeSubscriptionID
        promoFactor
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const subscriptionPlanByOrganization = /* GraphQL */ `
  query SubscriptionPlanByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionPlanByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        planType
        isActive
        totalMembers
        cost
        billingCycle
        dateRenewalRequired
        stripeSubscriptionID
        promoFactor
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      jobName
      memberName
      userID
      eventID
      startDate
      endDate
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobName
        memberName
        userID
        eventID
        startDate
        endDate
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncJobs = /* GraphQL */ `
  query SyncJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        jobName
        memberName
        userID
        eventID
        startDate
        endDate
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const jobsByEvent = /* GraphQL */ `
  query JobsByEvent(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByEvent(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobName
        memberName
        userID
        eventID
        startDate
        endDate
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVersion = /* GraphQL */ `
  query GetVersion($id: ID!) {
    getVersion(id: $id) {
      id
      version
      supported
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVersions = /* GraphQL */ `
  query ListVersions(
    $filter: ModelVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        version
        supported
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVersions = /* GraphQL */ `
  query SyncVersions(
    $filter: ModelVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        version
        supported
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
