import { DataStore, Hub, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { Event, Invite, OrganizationMember } from "../../models";
import { colors } from "../../theme/colors";
import InviteRoleModule from "./InviteRoleModule";
import InviteMemberModule from "./InviteMemberModule";
import { SearchOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import "./SendInvites.css";
import { BeatLoader } from "react-spinners";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import useDynamicDelay from "../../hooks/useDynamicDelay";
import { Helmet } from "react-helmet-async";
import { initializeOrgMemberInvites } from "./initializeOrgMemberInvites";
import { fetchOrgMemberInvites } from "./fetchOrgMemberInvites";
import { fetchOrgMembers } from "./fetchOrgMembers";

function RoleModule(role, num) {
  this.role = role;
  this.num = num;
  this.type = "RoleModule";
}

function MemberModule(name, id, num, role, profilePic, changed) {
  this.name = name;
  this.id = id;
  this.num = num;
  this.role = role;
  this.type = "MemberModule";
  this.profilePic = profilePic;
  this.changed = changed;
}

const OrgMemberInvites = () => {
  const navigate = useNavigate();
  const { dbUser, sendRoleInvites } = useAuthContext();
  const [sent, setSent] = useState(true);
  const [modules, setModules] = useState(null);
  const [reRender, setReRender] = useState(false);
  const [event, setEvent] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [searchText, setSearchText] = useState("");
  let { eventID } = useParams();

  useEffect(() => {
    (async () => {
      await DataStore.query(Event, eventID).then(setEvent);
    })();
    const listener = Hub.listen("datastore", async (hubData) => {
      const { event, data } = hubData?.payload;

      if (event === "outboxMutationEnqueued" && data.model === Invite) {
        setSent(false);
      }
      if (event === "outboxStatus" && data.isEmpty === true) {
        setSent(true);
      }
    });
    return () => listener();
  }, []);

  useEffect(() => {
    if (dbUser && event) {
      (async () => {
        const orgMembers = await fetchOrgMembers(dbUser.id);

        let tempArray = orgMembers.length
          ? ["By Group"]
          : ["Add members to share invites"];

        //add RoleModules for each created role in the organization

        if (orgMembers.length > 0) {
          for (let i = 0; i < dbUser.createdRoles.length; i++) {
            tempArray.push(new RoleModule(dbUser.createdRoles[i], "0"));
          }
          tempArray.push(new RoleModule("No Group", "0"));
          tempArray.push("By Member");
        }

        //If the DbUser has orgMembers, we push "By Member"... ["By Role", dbUser.createdRoles, "By Member"]
        const invites = await fetchOrgMemberInvites(event.id);

        for (let i = 0; i < orgMembers.length; i++) {
          tempArray.push(
            //We create a tempArray entry for each orgMember, whether or not they have an invite
            new MemberModule(
              orgMembers[i].memberInfo[0],
              orgMembers[i].userID,
              "0",
              orgMembers[i].role,
              orgMembers[i].memberInfo[1],
              false
            )
          );

          const relevantInvite = invites.find(
            (invite) => invite.userId === orgMembers[i].userID
          );

          if (relevantInvite) {
            let addDbUserRoles = dbUser.createdRoles.length + 2; // +1 is for the header "By Role", +1 is for the role "No Role"
            tempArray[i + addDbUserRoles + 1].num = "INVITED";
          }
        }

        setModules(tempArray);
      })();
    }
  }, [dbUser, event]);

  const updateModules = (Module) => {
    let tempArray = modules;
    let tempNumUsed = 0;
    //updating individual member modules, find the module by name and set to passed module
    if (Module.type === "MemberModule") {
      for (let i = 0; i < modules.length; i++) {
        if (modules[i].name === Module.name) {
          //change the total invite limit on the module
          tempArray[i].num = Module.num;
          tempArray[i].changed = true;
          tempArray[i].categories = Module.categories;
        }
        tempNumUsed = parseInt(modules[i].num)
          ? parseInt(modules[i].num) + tempNumUsed
          : 0 + tempNumUsed;
      }
    } else {
      //updating all role modules
      for (let i = 0; i < modules.length; i++) {
        if (
          modules[i].type === "MemberModule" &&
          modules[i].role === Module.role //for each MemberModule that reflects the role that you just updated
        ) {
          tempArray[i].num = Module.num;
          tempArray[i].changed = true;
          tempArray[i].categories = Module.categories;
        } else if (
          modules[i].type === "RoleModule" &&
          modules[i].role === Module.role
        ) {
          tempArray[i].num = Module.num;
          tempArray[i].changed = true;
          tempArray[i].categories = Module.categories;
        }
        if (modules[i].type === "MemberModule") {
          tempNumUsed = parseInt(modules[i].num)
            ? parseInt(modules[i].num) + tempNumUsed
            : 0 + tempNumUsed;
        }
      }
    }
    let newModules = sortModules(tempArray);
    setModules(newModules);
    setReRender((old) => old + 1);
  };

  function sortModules(tempModules) {
    let memberModules = tempModules.filter((m) => m.type === "MemberModule");
    let otherModules = tempModules.filter((m) => m.type !== "MemberModule");
    memberModules.sort((a, b) => {
      const aIndex = a.name.toLowerCase().indexOf(searchText.toLowerCase());
      const bIndex = b.name.toLowerCase().indexOf(searchText.toLowerCase());

      if (aIndex === -1) return 1; // If a does not contain searchText, put it to the end
      if (bIndex === -1) return -1; // If b does not contain searchText, put it to the start

      // If both contains searchText, sort according to position of searchText
      return aIndex - bIndex;
    });
    return [...otherModules, ...memberModules];
  }

  useDynamicDelay(
    () => {
      if (modules) {
        setModules((old) => {
          let tempModules = old;
          let newModules = sortModules(tempModules);
          return newModules;
        });
      }
    },
    300,
    [searchText]
  );

  const onSharePress = async () => {
    setSent(false);
    let tempArray = [];
    for (let i = 0; i < modules.length; i++) {
      if (modules[i].type === "MemberModule" && modules[i].changed === true) {
        tempArray.push(modules[i]);
      }
    }

    const invites = await fetchOrgMemberInvites(event.id);

    await initializeOrgMemberInvites({
      setSnackbarOpen: setSnackbarOpen,
      orgID: dbUser.id,
      eventInfo: [
        event.id,
        event.name,
        event.startDate,
        event.endDate,
        event.address,
        event.description,
      ],
      eventID: event.id,
      MemberModules: tempArray,
      navigate: navigate,
      setSent,
      invites,
      event,
      orgInfo: [dbUser.name, dbUser.profilePic],
    });
  };

  const loading = !event || !modules;

  const pageTitle = `Invite Members • CLIQInvite`;
  const pageDescription = `Invite all of your members, groups of members, or individual members to your organization's event with just a few clicks.`;
  const pageImageUrl =
    "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
  const pageUrl = `https://www.cliqinvite.com/event/invite_members`;

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <WebPortalNavigation activeScreen="/dashboard" />
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta
          name="description"
          property="og:description"
          content={pageDescription}
        />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <Box
        className="page-size-large"
        sx={{ paddingTop: 4, alignSelf: "center" }}
      >
        <Box className="unstyled-card" sx={{ px: 3, py: 2 }}>
          {loading ? (
            <Skeleton variant="rounded" height="4em" sx={{ mb: 2 }} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h1" style={{ fontSize: "1.5em" }}>
                  Share Member Invites
                </Typography>
                <Typography variant="body2">for {event.name}</Typography>
              </Box>

              {sent ? (
                <Button
                  onClick={() => onSharePress()}
                  variant="contained"
                  sx={{
                    transition: "all 300ms ease-in-out",
                  }}
                >
                  {modules.find((m) => m.changed) ? "Send Invites" : "Done"}
                </Button>
              ) : (
                <BeatLoader
                  color={colors.primaryColor}
                  style={{ fontSize: "1.2em" }}
                />
              )}
            </Box>
          )}

          <Box>
            {!loading ? (
              modules.map((item, index) => {
                return item.type === "RoleModule" ? (
                  <InviteRoleModule
                    radio
                    RoleModule={item}
                    updateModules={(Module) => updateModules(Module)}
                    event={event}
                    role={item.role}
                    key={item.id || index.toString()}
                  />
                ) : item.type === "MemberModule" ? (
                  <InviteMemberModule
                    radio
                    MemberModule={item}
                    updateModules={(Module) => updateModules(Module)}
                    categories={event.categories}
                    event={event}
                    key={item.id || index.toString()}
                  ></InviteMemberModule>
                ) : item === "Add members to share invites" ? (
                  <Box style={{ width: "100%", alignItems: "center" }}>
                    <p
                      style={{
                        ...styles.labels,
                        marginBottom: 5,
                        fontSize: "1em",
                        color: colors.secondaryText,
                      }}
                    >
                      Add members to share invites
                    </p>
                  </Box>
                ) : item === "By Member" ? (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "10%",
                      mt: 2,
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography variant="body2" sx={{}}>
                      {item}
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      sx={{
                        bgcolor: colors.backgroundHighlight,
                        borderRadius: 1,
                        flex: 1,
                        fontSize: ".8em",
                      }}
                      placeholder="Search members"
                      InputProps={{
                        startAdornment: (
                          <SearchOutlined
                            sx={{ color: "white", fontSize: "1.2em", mr: 1 }}
                          />
                        ),
                        sx: { color: "white" },
                      }}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Box>
                ) : (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {item}
                  </Typography>
                );
              })
            ) : (
              <Skeleton variant="rounded" height="40em"></Skeleton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  title: {
    color: "white",
  },
  container: {
    display: "flex",
    alignItems: "flex-start",
  },
  labels: {},

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  button: {
    alignSelf: "flex-start",
    width: "50%",
    padding: 10,
    marginTop: "5%",
  },
};

export default OrgMemberInvites;
