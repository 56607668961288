import { Box, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";

const PlanFeaturesModule = () => {
  return (
    <Box
      sx={{
        boxShadow: `${colors.tertiaryText}22 0px 5px 15px`,
        bgcolor: colors.tertiaryText,
        height: "calc(100% - 4rem)",
        borderRadius: 3,
        pl: { xs: "1.2rem", sm: "2rem" },
        pr: { xs: "1.2rem", sm: "1rem" },
        py: "2rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: colors.primaryText,
          fontSize: "1.5rem",
          mb: "1rem",
        }}
      >
        What you get
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: ".9rem",
          fontWeight: 500,
          mb: "1.5rem",
        }}
      >
        As soon as you sign up for your CLIQInvite subscription, you get
        immediate access to all of our features, including:
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", mb: "2rem" }}>
        <Typography variant="body1" sx={{ fontSize: "1rem" }}>
          • All social features
          <br />
          • All rush features
          <br />• All philanthropy features
          <br />• Downloadable semesterly reports
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="body1" fontSize={".8rem"}>
          See features:
        </Typography>
        <Typography variant="body1" ml="auto" fontSize=".8rem">
          <span
            onClick={() =>
              window.open(
                "https://www.cliqinvite.com/social",
                "_blank",
                "noreferrer"
              )
            }
            style={{ cursor: "pointer", fontStyle: "italic" }}
          >
            Social
          </span>
          {" • "}
          <span
            onClick={() =>
              window.open(
                "https://www.cliqinvite.com/rush",
                "_blank",
                "noreferrer"
              )
            }
            style={{ cursor: "pointer", fontStyle: "italic" }}
          >
            Rush
          </span>
          {" • "}
          <span
            onClick={() =>
              window.open(
                "https://www.cliqinvite.com/philanthropy",
                "_blank",
                "noreferrer"
              )
            }
            style={{ cursor: "pointer", fontStyle: "italic" }}
          >
            Philanthropy
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default PlanFeaturesModule;
