import { Skeleton, TextField, Typography } from "@mui/material";
import { colors } from "../../theme/colors";

const EditEventDetailsSection = ({
  loading,
  description,
  setDescription,
  location,
  setLocation,
}) => {
  return (
    <>
      {loading ? null : <Typography variant="body2">Event Details</Typography>}
      {loading ? (
        <Skeleton variant="rounded" height="12em" sx={{ my: 1 }} />
      ) : (
        <>
          <TextField
            label="Description"
            name="description"
            fullWidth
            multiline
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            size="small"
            sx={{
              caretColor: colors.primaryColor,
              mb: 1.5,
              mt: 1,
              backgroundColor: `${colors.backgroundHighlight}`,
            }}
            inputProps={{
              maxLength: 100,
            }}
          />
          <TextField
            label="Location"
            name="location"
            onChange={(e) => setLocation(e.target.value)}
            value={location}
            fullWidth
            size="small"
            sx={{
              caretColor: colors.primaryColor,
              mb: 2,
              backgroundColor: `${colors.backgroundHighlight}`,
            }}
            inputProps={{
              maxLength: 44,
            }}
          />
        </>
      )}
    </>
  );
};

export default EditEventDetailsSection;
