import { colors } from "./colors";

export const gstyles = {
  input: {
  width: '100%',
  padding: '12px 20px',
  margin: '6px 0',
  boxSizing: 'border-box',
  border: 'none',
  borderRadius: '4px',
  color: '#1f1f1f',
  fontSize: '16px',
},
  button: {
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.primaryColor,
    fontFamily: "Poppins",
    fontWeight: "500",
    borderWidth: 0,
    fontSize: 18,
    padding: 6,
    alignSelf: 'center',
    borderRadius: 12
  },
};
