import { Storage } from "aws-amplify";
import { memo, useEffect, useState } from "react";
import { useAuthContext } from "../contexts/AuthContext";

import "./ProfileImage.css";

const ProfileImage = memo(({ bannerPic, size, height, pic, contact }) => {
  const { profilePic } = useAuthContext();
  const [image, setImage] = useState();

  useEffect(() => {
    let prefix = "";
    if (contact) {
      prefix = "contact/";
    }
    let sizeToBeAdded = size === "small" ? "150x150/" : "350x350/";
    prefix += sizeToBeAdded;
    if (pic) {
      Storage.get(prefix + pic).then(setImage);
    }
  }, [pic]);

  return (
    <img
      alt={`profile for CLIQInvite users`}
      className="profile-pic"
      style={{
        height: height,
        objectFit: "cover",
        aspectRatio: bannerPic ? 5 / 2 : 1,
        width: bannerPic ? "100%" : undefined,
        borderRadius: bannerPic ? 0 : "100%",
      }}
      src={pic?.includes("picsum") ? pic : pic ? image : profilePic}
      loading="lazy"
    ></img>
  );
});

export default ProfileImage;
