import { useEffect, useRef } from "react";

const useDynamicDelay = (callback, delay, dependencies) => {
  const timeout = useRef(null);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      await callback();
    }, delay);

    return () => clearTimeout(timeout.current);
  }, [...dependencies]);

  return () => clearTimeout(timeout.current);
};

export default useDynamicDelay;
