import { Box, Button, TextField, Typography } from "@mui/material";
import { colors } from "../../theme/colors";

const LimitModule = ({ setRenderedCategories, item, index, setReRender }) => {
  return (
    <Box
      sx={{
        bgcolor: colors.backgroundHighlight,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",

        borderRadius: 1,

        marginBottom: ".5em",
        py: 1,
        px: 2,
      }}
    >
      <Typography
        variant="body1"
        style={{
          fontSize: "1.1em",
        }}
      >
        {item.category}
      </Typography>
      {item.limit === "-1" ? (
        <Button
          onClick={() => {
            setRenderedCategories((old) => {
              let tempArray = old;
              let tempIndex = old.findIndex(
                (limit) => limit.category === item.category
              );
              tempArray[tempIndex] = { ...tempArray[tempIndex], limit: "0" };

              return tempArray;
            });
            setReRender((old) => !old);
          }}
          color="primary"
          variant="outlined"
          size="small"
        >
          Add Limit
        </Button>
      ) : (
        <TextField
          inputProps={{ maxLength: 3 }}
          value={item.limit}
          label={`${!item.limit ? "No " : ""}Limit`}
          inputMode={"numeric"}
          size="small"
          InputProps={{
            style: {
              color: "white",
              fontFamily: "Poppins",
            },
          }}
          sx={{
            caretColor: "white",
            maxWidth: "6em",
            bgcolor: colors.secondaryBackground,
            borderRadius: 1,
          }}
          onChange={(e) => {
            let text = e.target.value;
            setRenderedCategories((old) => {
              let tempArray = old;
              let tempIndex = old.findIndex(
                (limit) => limit.category === item.category
              );

              tempArray[tempIndex] = { ...tempArray[tempIndex], limit: text };
              return tempArray;
            });
            setReRender((old) => !old);
          }}
        ></TextField>
      )}
    </Box>
  );
};

export default LimitModule;
