import { Box, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";

const PhilanthropyActionCard = ({ content }) => {
  const { icon, header, subHeader, buttonText, onClick } = content;

  return (
    <Box
      sx={{
        bgcolor: colors.eggshell,
        p: 4,
        boxShadow: "rgba(0, 0, 0, 0.4) 0px 5px 15px",
        display: "flex",
        flexDirection: "column",
        borderRadius: 4,
        flex: 1,
        position: "relative",
        overflow: "hidden",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          background: `radial-gradient(${colors.softGreen}40, #ffffff)`,
          height: "3em",
          width: "3em",
          borderRadius: "1.5em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        {icon}
      </Box>
      <Typography
        variant="h1"
        sx={{
          color: colors.defaultBackground,
          letterSpacing: "-.05em",
          fontSize: "1.5em",
          fontWeight: 600,
          zIndex: 1,
          textAlign: "left",
          mb: 1,
        }}
      >
        {header}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: colors.defaultBackground,
          fontSize: "1em",
          letterSpacing: "-.05em",
          lineHeight: "1.5em",
          zIndex: 1,
          textAlign: "left",
          mb: 5,
        }}
      >
        {subHeader}
      </Typography>
      <Box
        sx={{
          borderRadius: "50px",
          py: ".5em",
          px: "1em",
          cursor: "pointer",
        }}
        onClick={onClick}
        className="slide-button soft-green"
      >
        <Typography sx={{ color: colors.primaryText, fontSize: "1em" }}>
          {buttonText}
        </Typography>
      </Box>
    </Box>
  );
};

export default PhilanthropyActionCard;
