import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { colors } from "../../../theme/colors";

const ReportInfoModule = ({ info }) => {
  const { title, bullets, description } = info;
  return (
    <Accordion sx={{ width: "100%", bgcolor: colors.backgroundHighlight}}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <h1 style={{ margin: 0, fontSize: "1.2em", fontWeight: 500 }}>{title}</h1>
      </AccordionSummary>
      <AccordionDetails>
        <p
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: ".9em",
          }}
        >
          {description}
        </p>
        <p style={{ margin: 0, fontSize: ".8em", color: colors.secondaryText }}>
          {bullets.map((bullet, index) => (
            <li key={index}>{bullet}</li>
          ))}
        </p>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReportInfoModule;
