import { YouTube } from "@mui/icons-material";
import { Box, Button, Chip, Grid } from "@mui/material";

const TutorialModule = ({ video }) => {
  return (
    <Box
      key={video.id}
      sx={{
        backgroundColor: "#090909",
        boxShadow: "0px 0px 3px #090909",
        mb: 2,
        color: "#fff",
        p: "1em 1.5em",
        borderRadius: 5,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={8.5}>
          <h1 style={{ margin: 0, fontSize: "1.3em" }}>{video.title}</h1>

          <p style={{ opacity: 0.6, margin: 0 }}>Length: {video.length}</p>
          <Grid item xs={12}>
            {video.tags.map((tag) => (
              <Chip
                label={tag}
                sx={{
                  marginRight: "0.5em",
                  marginBottom: "0.5em",
                  backgroundColor: "#777777",
                  fontFamily: "Poppins",
                  color: "black",
                  fontSize: "0.7em",
                }}
                size="small"
              />
            ))}
          </Grid>
          <p>{video.description}</p>
          <Button
            aria-label="watch on YouTube"
            onClick={() =>
              window.open(
                `https://www.youtube.com/watch?v=${video.id}&list=${
                  video.tags.includes("Organization Owner")
                    ? "PLCoNOX4-Q2eWCZLF666Z6vgFZ_dMtAGIA"
                    : "PLCoNOX4-Q2eWNYvNRgjVmSRcx_PP-6DFs"
                }`,
                "_blank"
              )
            }
            color="secondary"
            sx={{
              textTransform: "none",
              color: "white",
              fontFamily: "Poppins",
            }}
          >
            <YouTube sx={{ color: "#FF0000", mr: ".2em" }} />
            Watch on YouTube
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          xl={3.5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <iframe
            style={{
              flexShrink: 1,
              aspectRatio: 16 / 9,
              width: "100%",
            }}
            src={`https://www.youtube.com/embed/${video.id}?list=${
              video.tags.includes("Organization Owner")
                ? "PLCoNOX4-Q2eWCZLF666Z6vgFZ_dMtAGIA"
                : "PLCoNOX4-Q2eWNYvNRgjVmSRcx_PP-6DFs"
            }`}
            title={video.title}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default TutorialModule;
