import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import React from "react";
import RushStatisticsCard from "./RushStatisticsCard";

const statistics = [
  {
    statistic: 96,
    tag: "invite time reduction",
    description:
      "Rush Suite decreases time-to-invite with 100+ PNMs by 96% compared to non-automated processes",
    image: require("../../../assets/Rush_Statistic_Banner_0.png"),
  },
  {
    statistic: 73,
    tag: "faster slideshow creation",
    description:
      "Rush Suite automates slideshow creation for rush meetings, saving 73% of presentation creation time",
    image: require("../../../assets/Rush_Statistic_Banner_1.png"),
  },
  {
    statistic: 0,
    tag: "PNMs left behind",
    description:
      "Rush Suite alerts chapters when PNMs aren't invited, ensuring that every uninvited PNM is accounted for",
    image: require("../../../assets/Rush_Statistic_Banner_2.png"),
  },
];

const RushStatisticsSection = () => {
  return (
    <Box
      sx={{
        py: { sm: 12, xs: 8 },
        pb: { sm: 8, xs: 5 },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: colors.defaultBackground,
          lineHeight: 1.12,
          letterSpacing: "-.8px",
          textAlign: "center",
          alignSelf: "center",
          fontSize: {
            lg: "2rem",
            md: "2rem",
            sm: "2rem",
            xs: "1.6rem",
          },
          width: {
            md: "70%",
          },
          mb: "3rem",
        }}
      >
        Here's what sets Rush Suite apart.
      </Typography>
      <Grid
        className="default-page-size"
        container
        rowSpacing={{ md: 0, xs: 2 }}
      >
        {statistics.map((statistic, index) => (
          <Grid
            item
            xs={12}
            sm={index !== 2 ? 6 : 12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              pl: { md: index === 0 ? 0 : 2, sm: index === 1 ? 1 : 0, xs: 0 },
              pr: {
                md: index === statistics.length - 1 ? 0 : 2,
                sm: index === 0 ? 1 : 0,
                xs: 0,
              },
            }}
          >
            <RushStatisticsCard content={statistic} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RushStatisticsSection;
