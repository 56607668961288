import { Box } from "@mui/material";
import { colors } from "../theme/colors";

const NewsDisplayModule = ({ news }) => {
  return (
    <Box
      sx={{
        p: ".5em 1em",
        bgcolor: colors.backgroundHighlight,
        borderRadius: 2,
        height: "calc(100% - 1em)",
        overflow: 'auto'
      }}
    >
      <h1 style={{ margin: 0, fontSize: "1em" }}>{news.header}</h1>
      <p
        style={{
          margin: 0,
          fontSize: ".8em",
          marginBottom: ".5em",
          color: colors.secondaryText,
        }}
      >
        {news.date}
      </p>
      <p
        style={{
          margin: 0,
          fontSize: ".8em",
          marginBottom: ".5em",
          color: colors.primaryText,
        }}
      >
        {news.description}
      </p>
    </Box>
  );
};

export default NewsDisplayModule;
