import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { AttachMoney, Info, Remove } from "@mui/icons-material";
import { calculateTakeHome } from "../../methods/calculateTakeHome";

const EventSettingsDialog = ({
  dialogOpen,
  setDialogOpen,
  ticketInformation,
  setTicketInformation,
  requireID,
  setRequireID,
  alreadyHasPayments,
  editingEvent,
}) => {
  const togglePayments = () => {
    if (requireID && !ticketInformation) {
      setRequireID(false);
    }
    setTicketInformation((old) =>
      old ? null : { maxTickets: 1000, price: 15 }
    );
  };
  const changeTicketPrice = (price) => {
    if (parseFloat(price) < 1) {
      setTicketInformation((old) => {
        return { ...old, price: 1 };
      });
    } else if (parseFloat(price) > 99999) {
      setTicketInformation((old) => {
        return { ...old, price: 99999 };
      });
    } else {
      setTicketInformation((old) => {
        return { ...old, price: price ? parseFloat(price) : "" };
      });
    }
  };

  const changeMaxTickets = (maxTickets) => {
    if (parseFloat(maxTickets) < 1) {
      setTicketInformation((old) => {
        return { ...old, maxTickets: 1 };
      });
    } else if (parseFloat(maxTickets) > 99999) {
      setTicketInformation((old) => {
        return { ...old, maxTickets: 99999 };
      });
    } else {
      setTicketInformation((old) => {
        return { ...old, maxTickets: maxTickets ? parseFloat(maxTickets) : "" };
      });
    }
  };

  const handleClose = () => {
    if (ticketInformation && !ticketInformation.price) {
      setTicketInformation(null);
    }
    setDialogOpen(false);
  };
  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <DialogTitle style={{ fontFamily: "Poppins", color: "white" }}>
        {"Event Settings"}
      </DialogTitle>
      <DialogContent className="scroll-container">
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "3%" }}>
          <Box sx={{ flex: 1 }}>
            <DialogContentText
              style={{ fontFamily: "Poppins", color: colors.primaryText }}
            >
              Payments
            </DialogContentText>
            <DialogContentText
              style={{
                fontFamily: "Poppins",
                color: colors.secondaryText,
                fontSize: ".8em",
              }}
            >
              Guests pay a specified price to access their ticket.{" "}
              <b
                className="link"
                style={{
                  margin: 0,
                  transition: "all 300ms ease-in-out",
                  color: colors.secondaryText,
                }}
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=nxqoSCgaoNs&list=PLCoNOX4-Q2eWCZLF666Z6vgFZ_dMtAGIA",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Learn more
              </b>
            </DialogContentText>
          </Box>
          {ticketInformation && !alreadyHasPayments ? (
            <Button
              color={"error"}
              size="small"
              variant="contained"
              startIcon={<Remove sx={{ color: colors.primaryText }} />}
              sx={{ mt: 1, mb: 2 }}
              onClick={togglePayments}
            >
              {"Remove"}
            </Button>
          ) : null}
        </Box>
        {ticketInformation ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Ticket Price</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "5%" }}>
              <Slider
                value={
                  typeof ticketInformation.price === "number"
                    ? ticketInformation.price
                    : 1
                }
                onChange={(event, newValue) => changeTicketPrice(newValue)}
                valueLabelDisplay="auto"
                step={1}
                max={100}
                min={1}
                sx={{
                  flex: 1,
                  "& .MuiSlider-valueLabel": {
                    fontSize: "1em",
                    fontWeight: "normal",
                    top: -6,
                    backgroundColor: "unset",
                    color: colors.primaryText,
                    fontFamily: "Poppins",
                    "&:before": {
                      display: "none",
                    },
                    "& *": {
                      background: "transparent",
                      color: colors.primaryText,
                    },
                  },
                }}
              />
              <Box>
                <TextField
                  value={`$${ticketInformation.price}`}
                  size="small"
                  onChange={(event) => {
                    changeTicketPrice(
                      parseFloat(event.target.value.replace("$", ""))
                    );
                  }}
                  onBlur={() => {
                    if (
                      !ticketInformation.price ||
                      ticketInformation.price < 0
                    ) {
                      changeTicketPrice(0);
                    }
                  }}
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 99999,
                    "aria-labelledby": "input-slider",
                  }}
                  sx={{
                    borderRadius: 1,
                    flex: 0.4,
                    bgcolor: colors.backgroundHighlight,
                  }}
                  fullWidth
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: ".75em" }}>
                    {`Take home: ${calculateTakeHome(
                      ticketInformation.price * 100
                    )}`}{" "}
                  </Typography>
                  <Tooltip title="Your take home price is calculated by subtracting Stripe fees (2.9% + $0.30 flat) and CLIQInvite fees (10%). These fees are non-binding and subject to change at our disclosure.">
                    <Info
                      sx={{ color: colors.secondaryText, fontSize: "1em" }}
                    ></Info>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Typography variant="body2">Max Tickets</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "5%" }}>
              <Slider
                value={
                  typeof ticketInformation.maxTickets === "number"
                    ? ticketInformation.maxTickets
                    : 1
                }
                onChange={(event, newValue) => changeMaxTickets(newValue)}
                valueLabelDisplay="auto"
                step={1}
                max={100}
                min={1}
                sx={{
                  flex: 1,
                  "& .MuiSlider-valueLabel": {
                    fontSize: "1em",
                    fontWeight: "normal",
                    top: -6,
                    backgroundColor: "unset",
                    color: colors.primaryText,
                    fontFamily: "Poppins",
                    "&:before": {
                      display: "none",
                    },
                    "& *": {
                      background: "transparent",
                      color: colors.primaryText,
                    },
                  },
                }}
              />
              <TextField
                value={ticketInformation.maxTickets}
                size="small"
                onChange={(event) => changeMaxTickets(event.target.value)}
                onBlur={() => {
                  if (
                    !ticketInformation.maxTickets ||
                    ticketInformation.maxTickets < 0
                  ) {
                    changeTicketPrice(0);
                  }
                }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 999999,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
                sx={{
                  borderRadius: 1,
                  flex: 0.4,
                  bgcolor: colors.backgroundHighlight,
                }}
                fullWidth
              />
            </Box>
          </Box>
        ) : null}
        {!ticketInformation ? (
          <Button
            color={"primary"}
            size="small"
            variant="contained"
            startIcon={<AttachMoney sx={{ color: colors.primaryText }} />}
            sx={{ mt: 1, mb: 2 }}
            onClick={togglePayments}
          >
            {"Add"} Payments
          </Button>
        ) : null}
        {!editingEvent ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 3,
              }}
            >
              <DialogContentText
                style={{ fontFamily: "Poppins", color: colors.primaryText }}
              >
                Require Photo ID
              </DialogContentText>
              <DialogContentText
                style={{
                  fontFamily: "Poppins",
                  color: colors.secondaryText,
                  fontSize: ".8em",
                }}
              >
                Guests upload a photo ID to access their ticket.{" "}
                <b
                  className="link"
                  style={{
                    margin: 0,
                    transition: "all 300ms ease-in-out",
                    color: colors.secondaryText,
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/watch?v=vd-Qv8rwsyM&list=PLCoNOX4-Q2eWCZLF666Z6vgFZ_dMtAGIA",
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  Learn more
                </b>
              </DialogContentText>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                sx={{
                  color: requireID ? colors.primaryText : colors.secondaryText,
                }}
              >
                {!requireID ? "Disabled" : "Enabled"}
              </Typography>
              <Switch
                checked={requireID}
                sx={{
                  fontSize: "2em",
                  "& .MuiSwitch-track": {
                    backgroundColor: `${colors.primaryColor}80`,
                  },
                }}
                color="primary"
                onClick={() => {
                  if (!requireID && ticketInformation) {
                    togglePayments();
                  }
                  setRequireID((old) => !old);
                }}
              />
            </Box>
          </>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button style={{ fontFamily: "Poppins" }} onClick={handleClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EventSettingsDialog;
