import React, { useState, useEffect } from "react";
import { DataStore } from "aws-amplify";
import { Event, GuestInvite } from "../../models";
import { getCliqScore } from "../../methods/getCliqScore";
import {
  Button,
  Dialog,
  TextField,
  Select,
  MenuItem,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { colors } from "../../theme/colors";
import { ArrowDropDown } from "@mui/icons-material";

const GroupSelectModal = ({
  isVisible,
  close,
  contacts,
  deleting,
  setDeleteContactsQueue,
  setSelectedContacts,
  setSelectedSortOption,
  contactListID,
  filtering,
  handleFilter,
}) => {
  const [selectionCategory, setSelectionCategory] = useState("avgScore");
  const [criteria, setCriteria] = useState(">");
  const [threshold, setThreshold] = useState("0");
  const [qualifyingContacts, setQualifyingContacts] = useState([]);

  const calculateQualifyingContacts = (scoredContacts) => {
    setQualifyingContacts(
      scoredContacts.filter((s) => {
        if (selectionCategory === "cliqScore") {
          return criteria === ">"
            ? s.cliqScore > (parseInt(threshold) || 0)
            : criteria === "<"
            ? s.cliqScore < (parseInt(threshold) || 0)
            : s.cliqScore === (parseInt(threshold) || 0);
        } else if (selectionCategory === "avgScore") {
          return criteria === ">"
            ? s.avgScore > (parseInt(threshold) || 0)
            : criteria === "<"
            ? s.avgScore < (parseInt(threshold) || 0)
            : s.avgScore === (parseInt(threshold) || 0);
        } else {
          return criteria === ">"
            ? s.eventScore > (parseInt(threshold) || 0)
            : criteria === "<"
            ? s.eventScore < (parseInt(threshold) || 0)
            : s.eventScore === (parseInt(threshold) || 0);
        }
      })
    );
  };

  useEffect(() => {
    (async () => {
      let newContacts = Array.from(contacts.values());
      let scoredContacts = newContacts;
      if (selectionCategory === "cliqScore" || selectionCategory === "events") {
        if (!Array.from(contacts.values())?.[0]?.cliqScore) {
          const guestInvites = await DataStore.query(GuestInvite, (g) =>
            g.contactListID.eq(contactListID)
          );
          const uniqueEventIDs = new Set(
            guestInvites.map((invite) => invite.eventID)
          );
          const uniqueExpiredEventIDs = (
            await Promise.all(
              Array.from(uniqueEventIDs).map(async (eventID) => {
                const event = await DataStore.query(Event, eventID);
                if (event.endDate < Date.now().toString()) return eventID;
              })
            )
          ).filter((eventID) => eventID !== undefined);

          scoredContacts = newContacts.map((contact) => {
            let { cliqScore, eventScore } = getCliqScore(
              contact.id,
              guestInvites,
              uniqueExpiredEventIDs
            );
            return { ...contact, cliqScore: cliqScore, eventScore: eventScore };
          });
        }
      }
      calculateQualifyingContacts(scoredContacts);
    })();
  }, [selectionCategory, criteria, threshold, contactListID, contacts]);

  const selectContacts = () => {
    if (filtering) {
      handleFilter(selectionCategory, criteria, threshold);
      close()
      return;
    }
    let tempContactsQueue = new Set(qualifyingContacts.map((c) => c.id));

    setSelectedSortOption(selectionCategory);
    if (deleting) {
      setDeleteContactsQueue(tempContactsQueue);
    } else {
      setSelectedContacts(tempContactsQueue);
    }

    close();
  };

  return (
    <Dialog open={isVisible} onClose={close}>
      <Box sx={{ borderRadius: 5 }}>
        <DialogTitle>
          {filtering ? "Metric Filter" : "Selection Tool"}
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              color: colors.secondaryText,
              fontSize: ".85em",
              fontWeight: 500,
            }}
          >
            {filtering ? "Metric" : "Selection Category"}
          </p>
          <Select
            value={selectionCategory}
            onChange={(e) => setSelectionCategory(e.target.value)}
            sx={{
              color: colors.primaryText,
              "& .MuiMenuItem-root": { color: colors.primaryText },
              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                { color: `${colors.primaryText} !important` },
            }}
            SelectDisplayProps={{
              style: {
                backgroundColor: colors.defaultBackground,
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: colors.secondaryBackground,
                },
              },
            }}
          >
            <MenuItem
              style={{
                fontFamily: "Poppins",
                color: colors.secondaryText,
                fontSize: ".85em",
              }}
              value="avgScore"
            >
              Member scores
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Poppins",
                color: colors.secondaryText,
                fontSize: ".85em",
              }}
              value="cliqScore"
            >
              CLIQInvite score
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Poppins",
                color: colors.secondaryText,
                fontSize: ".85em",
              }}
              value="events"
            >
              % events attended
            </MenuItem>
          </Select>
          <p
            style={{
              color: colors.secondaryText,
              fontSize: ".85em",
              fontWeight: 500,
            }}
          >
            {filtering ? "Criteria" : "Selection Criteria"}
          </p>
          <div style={{ display: "flex", width: "100%", gap: ".5em" }}>
            <Select
              value={criteria}
              onChange={(e) => setCriteria(e.target.value)}
              sx={{
                color: colors.primaryText,
                "& .MuiMenuItem-root": { color: colors.primaryText },
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  { color: `${colors.primaryText} !important` },
                flex: 1.5,
              }}
              SelectDisplayProps={{
                style: {
                  backgroundColor: colors.defaultBackground,
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: colors.secondaryBackground,
                  },
                },
              }}
            >
              <MenuItem
                style={{
                  fontFamily: "Poppins",
                  color: colors.secondaryText,
                  fontSize: ".85em",
                }}
                value=">"
              >
                Greater than
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: "Poppins",
                  color: colors.secondaryText,
                  fontSize: ".85em",
                }}
                value="<"
              >
                Less than
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: "Poppins",
                  color: colors.secondaryText,
                  fontSize: ".85em",
                }}
                value="="
              >
                Equal to
              </MenuItem>
            </Select>
            <TextField
              name="threshold"
              placeholder="0"
              defaultValue={threshold}
              type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 2,
              }}
              sx={{
                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: `${colors.primaryText} !important`,
                },
                borderRadius: 1,
                flex: 0.5,
                backgroundColor: colors.defaultBackground,
              }}
              onChange={(e) => setThreshold(e.target.value)}
            />
          </div>
          <p style={{ color: colors.primaryText }}>
            <b>{qualifyingContacts.length}</b>
            {filtering
              ? ` contacts match this and currently active filters`
              : ` will be selected`}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} sx={{ color: colors.secondaryText }}>
            Close
          </Button>
          <Button color="primary" onClick={selectContacts}>
            {filtering ? "Filter" : "Select"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default GroupSelectModal;
