import { Alert, Box, Snackbar } from "@mui/material";
import { API, DataStore } from "aws-amplify";
import { useAuthContext } from "../../contexts/AuthContext";
import { colors } from "../../theme/colors";
import React, { useEffect, useState } from "react";
import { Event, Invite, Organization, User } from "../../models";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import WebPortalNavigation from "../../components/WebPortalNavigation";
import { Helmet } from "react-helmet-async";
import EditEventBasicsSection from "./EditEventBasicsSection";
import EditEventDetailsSection from "./EditEventDetailsSection";
import EditEventSubmitSection from "./EditEventSubmitSection";
import EditEventHeader from "./EditEventHeader";
import NonEditableInformationSection from "./NonEditableInformationSection";
import { updateEvent } from "../../graphql/mutations";
import { getEvent } from "../../graphql/queries";

const pageTitle = `Edit Event • CLIQInvite`;
const pageDescription = `Edit your organization's event details, adjust ticket pricing, and much more, all with automatic notifications pushed to your members.`;
const pageImageUrl =
  "https://cliq-multimedia.s3.amazonaws.com/public/OG_Image.png";
const pageUrl = `https://www.cliqinvite.com/event/edit`;

const EditEvent = () => {
  const { dbUser, createNotif } = useAuthContext();
  const { eventID } = useParams();
  const [event, setEvent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [ticketInformation, setTicketInformation] = useState(null);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  useEffect(() => {
    DataStore.query(Event, eventID).then(setEvent);
  }, [eventID]);

  useEffect(() => {
    if (event) {
      setTitle(event.name);
      setDescription(event.description);
      setStartDate(dayjs(new Date(parseFloat(event.startDate))));
      setEndDate(dayjs(new Date(parseFloat(event.endDate))));
      setLocation(event.address);
      if (event.ticketInformation) {
        setTicketInformation({
          price: event.ticketInformation.price,
          maxTickets: event.maxTickets || 1000,
        });
      }
    }
  }, [event]);

  const navigate = useNavigate();

  const handleConfirmChanges = () => {
    let finalStartDate = Math.floor(new Date(startDate)).toString();
    let finalEndDate = Math.floor(new Date(endDate)).toString();
    const infoChanged = !(
      title === event.name &&
      description === event.description &&
      finalStartDate === event.startDate &&
      finalEndDate === event.endDate &&
      location === event.address
    );
    if (
      !infoChanged &&
      ticketInformation === event.ticketInformation &&
      ticketInformation?.price === event.ticketInformation?.price
    ) {
      //no changes were made
      navigate("/dashboard");
      return;
    }

    if (!title || !description || !location || !startDate || !endDate) {
      //empty fields
      setSnackbarOpen({
        type: "error",
        message: "Please fill out all fields to save your changes",
      });
      return;
    } else if (
      Math.floor(new Date(startDate)) > Math.floor(new Date(endDate))
    ) {
      setSnackbarOpen({
        type: "error",
        message: "End date cannot be before start date",
      });
      return;
    } else if (Math.floor(new Date(startDate)) < Date.now()) {
      setSnackbarOpen({
        type: "error",
        message: "Event cannot be in the past",
      });
      return;
    } else {
      return "SUCCESS";
    }
  };

  const handleMakeChanges = async (infoChanged) => {
    let finalStartDate = Math.floor(new Date(startDate));
    let finalEndDate = Math.floor(new Date(endDate));

    try {
      const eventToBeCopied = await API.graphql({
        query: getEvent,
        variables: {
          id: eventID,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const updatedEvent = await API.graphql({
        query: updateEvent,
        variables: {
          input: {
            id: eventToBeCopied.data.getEvent.id,
            description: description,
            name: title,
            address: location,
            startDate: finalStartDate.toString(),
            endDate: finalEndDate.toString(),
            ticketInformation: ticketInformation
              ? { price: ticketInformation.price }
              : null,
            maxTickets: ticketInformation.maxTickets
              ? ticketInformation.maxTickets
              : -1,
            _version: eventToBeCopied.data.getEvent._version,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      //update all invites that haven't been declined
      const invites = await DataStore.query(Invite, (i) =>
        i.and((i) => [
          i.eventID.eq(updatedEvent.id),
          i.or((i) => [i.hasResponded.eq(false), i.response.eq(true)]),
        ])
      );
      await Promise.all(
        invites.map(async (invite) => {
          const res = await DataStore.save(
            Invite.copyOf(invite, (updated) => {
              updated.eventInfo = [
                updatedEvent.id,
                updatedEvent.name,
                updatedEvent.startDate,
                updatedEvent.endDate,
                updatedEvent.address,
                updatedEvent.description,
              ];
            })
          );
          let receivingModel = !res.isOrg ? User : Organization;
          DataStore.query(receivingModel, invite.userId).then((model) => {
            createNotif(
              receivingModel,
              "inviteUpdated",
              model,
              dbUser.name,
              infoChanged
                ? ""
                : `Updated ticket ${
                    ticketInformation?.price !== event?.ticketInformation?.price
                      ? "price"
                      : "requirements"
                  } to event ${updatedEvent.name}`,
              updatedEvent.name,
              res.id,
              dbUser.profilePic
            );
          });
        })
      );

      navigate("/dashboard");
    } catch (err) {
      setSnackbarOpen({
        type: "error",
        message: "Unknown error while updating your event. Please try again.",
      });
      console.error(err);
    }
  };

  const loading = !event;

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <WebPortalNavigation activeScreen={"/dashboard"} />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="title" property="og:title" content={pageTitle} />
        <meta
          name="description"
          property="og:description"
          content={pageDescription}
        />
        <meta property="og:image" content={pageImageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta
          name="keywords"
          content="Event Management, Fraternity Management"
        />
      </Helmet>

      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert
          onClose={snackbarOpen?.action ? null : () => setSnackbarOpen(false)}
          action={snackbarOpen?.action ? snackbarOpen?.action : null}
          severity={snackbarOpen?.type}
          sx={{ width: "100%", fontFamily: "Poppins" }}
        >
          {snackbarOpen?.message}
        </Alert>
      </Snackbar>
      <Box
        className="page-size-large"
        sx={{ alignSelf: "center", pt: 2, position: "relative" }}
      >
        <Box
          className="unstyled-card"
          sx={{ bgcolor: colors.secondaryBackground, px: 3, py: 2 }}
        >
          <EditEventHeader
            loading={loading}
            event={event}
            ticketInformation={ticketInformation}
            setTicketInformation={setTicketInformation}
          />
          <NonEditableInformationSection
            eventCode={event?.eventCode}
            loading={loading}
            setSnackbarOpen={setSnackbarOpen}
          />
          <EditEventBasicsSection
            loading={loading}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            title={title}
            setTitle={setTitle}
          />
          <EditEventDetailsSection
            loading={loading}
            description={description}
            setDescription={setDescription}
            location={location}
            setLocation={setLocation}
          />
          <EditEventSubmitSection
            loading={loading}
            setSnackbarOpen={setSnackbarOpen}
            eventID={eventID}
            handleConfirmChanges={handleConfirmChanges}
            handleMakeChanges={handleMakeChanges}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EditEvent;
