import { Add, Remove } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { colors } from "../../../theme/colors";
import { useState } from "react";

const SocialFAQAccordion = ({ FAQ, index }) => {
  const { question, answer, boldedAnswerText } = FAQ;
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      key={index.toString()}
      sx={{
        bgcolor: "transparent",
        boxShadow: "none",
        width: "100%",
        py: 2,
      }}
      onChange={() => setExpanded((old) => !old)}
    >
      <AccordionSummary
        expandIcon={
          <Box
            sx={{
              bgcolor: `${colors.primaryColor}40`,
              height: "2em",
              width: "2em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "1em",
            }}
          >
            {expanded ? <Remove color="primary" /> : <Add color="primary" />}
          </Box>
        }
        aria-controls={`panel${index}a-content`}
        id={`panel${index}a-header`}
      >
        <Typography
          variant="h2"
          sx={{
            color: colors.defaultBackground,
            fontSize: { xl: "1.2rem" },
            letterSpacing: "-.03em",
            textAlign: 'left'
          }}
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{ textAlign: "left", color: colors.backgroundHighlight }}
          variant="body2"
        >
          <span style={{ fontWeight: 700 }}>{boldedAnswerText}</span>
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default SocialFAQAccordion;
