import { Box, Button, MenuItem, Select, Skeleton } from "@mui/material";
import { colors } from "../../theme/colors";
import {
  ArrowDropDownOutlined,
  EditOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import EventsTimeLine from "./EventsTimeLine";
import { useNavigate } from "react-router-dom";
import GraphSettingsDialog from "./GraphSettingsDialog";

const EventsGraphModule = ({ loading, events }) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    timeFrame: "past year",
    typeFilters: new Set(["Standard", "Paid", "ID-Verified"]),
  });
  const [graphSettingsVisible, setGraphSettingsVisible] = useState(false);

  useEffect(() => {
    if (events) {
      let graphedEvents = events.filter((event) => {
        const { timeFrame, typeFilters } = selectedFilters;
        const timeDifference =
          timeFrame === "past year"
            ? 31556952000
            : timeFrame === "past month"
            ? 2629746000
            : timeFrame === "past week"
            ? 604800000
            : 0;
        const eventType = event.requirePhotoID
          ? "ID-Verified"
          : event.ticketInformation
          ? "Paid"
          : "Standard";
        if (
          parseInt(event.startDate) < Date.now() - timeDifference &&
          timeDifference !== 0
        ) {
          return false;
        } else if (!typeFilters.has(eventType)) {
          return false;
        } else return true;
      });
      setFilteredEvents(graphedEvents);
    }
  }, [events, selectedFilters]);

  const eventCountOptions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 50, 100, 150, 250, 500, 750,
    1000,
  ];

  return (
    <Box
      className="unstyled-card"
      style={{ flex: 1, backgroundColor: colors.secondaryBackground }}
      sx={{
        borderRadius: 2,
        mb: ".5em",
        p: ".5em 1em",
      }}
      onClick={() => {
        if (selectOpen) {
          setSelectOpen(false);
        }
      }}
    >
      <GraphSettingsDialog
        open={graphSettingsVisible}
        setOpen={setGraphSettingsVisible}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <Box sx={{ display: "flex", flex: 1, alignItems: "flex-start", gap: 2 }}>
        <Box sx={{ flex: 1 }}>
          {loading ? (
            <Box>
              <Skeleton sx={{ fontSize: "1.4em" }} />
              <Skeleton sx={{ fontSize: ".85em" }} />
            </Box>
          ) : (
            <Box>
              <h1 style={{ fontSize: "1.4em", margin: 0 }}>Events</h1>
              <p
                style={{
                  fontSize: ".85em",
                  margin: 0,
                  color: colors.secondaryText,
                }}
              >
                Click any of the items on the legend to filter that line out of
                the graph or change the number of events displayed with the
                dropdown on the right.
              </p>
            </Box>
          )}
        </Box>
        {!loading ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.backgroundHighlight,
              ":hover": {
                backgroundColor: `${colors.backgroundHighlight}80`,
              },
              fontSize: { xs: "12px", lg: ".875rem" },
            }}
            startIcon={
              <SettingsOutlined
                sx={{
                  color: colors.primaryText,
                  fontSize: { xs: "12px", lg: ".875rem" },
                }}
              />
            }
            onClick={() => setGraphSettingsVisible(true)}
          >
            Graph Settings
          </Button>
        ) : //   <Box sx={{ display: "flex", alignItems: "center" }}>

        //     <p>event{numEvents === 1 ? "" : "s"} displayed</p>
        //   </Box>
        null}
      </Box>
      {loading ? (
        <Skeleton variant="rounded" sx={{ fontSize: "20em", mt: 2 }} />
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}
        >
          <EventsTimeLine filteredEvents={filteredEvents} />
        </Box>
      )}
    </Box>
  );
};

export default EventsGraphModule;
