import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { colors } from "../../theme/colors";
import { useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import axios from "axios";

const ChangePaymentMethodDialog = ({
  isVisible,
  close,
  setSnackbarOpen,
  plan,
}) => {
  const [changing, setChanging] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { authUser } = useAuthContext();
  const handleChangeMethod = async (e) => {
    e.preventDefault();
    if (!stripe) {
      return;
    }

    const token = authUser?.signInUserSession.idToken.jwtToken;
    try {
      elements.submit();
      setSnackbarOpen({
        type: "info",
        message: "Creating payment method",
      });
      const res = await axios.put(
        process.env.REACT_APP_SUBSCRIPTION_PAYMENTS_ENDPOINT,
        {
          type: "createSetupIntent",
          subscriptionID: plan.stripeSubscriptionID,
          test: process.env.NODE_ENV !== "production",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const clientSecret = res.data.client_secret;

      let return_url = "";
      if (process.env.NODE_ENV === "production") {
        return_url += `https://www.cliqinvite.com/`;
      } else {
        return_url += `http://localhost:3000/`;
      }
      return_url += `profile/billing`;

      await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: return_url,
        },
      });
      setSnackbarOpen({
        type: "success",
        message: "Successfully created payment method",
      });
    } catch (err) {
      console.log(err);
      setSnackbarOpen({
        type: "error",
        message:
          "Failed to create payment method. Please try again, and if the issue persists, please contact support.",
      });
    }
  };

  return (
    <Dialog open={isVisible} onClose={close}>
      <Box component="form" onSubmit={handleChangeMethod}>
        <DialogTitle>Add Payment Method</DialogTitle>
        <DialogContent>
          <PaymentElement options={{ wallets: "never" }} />
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: colors.secondaryText }} onClick={close}>
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ChangePaymentMethodDialog;
