import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { colors } from "../../theme/colors";
import { Box } from "@mui/material";

const MemberPerformanceGraph = ({ selectedMember }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Event #",
          font: {
            family: "Poppins",
          },
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
        maxRotation: 90,
        minRotation: 90,
      },
      y: {
        title: {
          display: true,

          text: "Statistic",
          font: {
            family: "Poppins",
          },
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },

    plugins: {
      tooltip: {
        bodyFont: {
          family: "Poppins",
        },
        titleFont: {
          family: "Poppins",
        },
      },
      legend: {
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
  };

  let labels;
  let validScans;
  let invalidScans;
  let invitesGiven;

  if (selectedMember?.graphData) {
    validScans = selectedMember.graphData.validScans;
    invalidScans = selectedMember.graphData.invalidScans;
    invitesGiven = selectedMember.graphData.invitesGiven;
    labels = selectedMember.graphData.labels;
  } else {
    labels = Array.from({ length: 10 }, (_, i) => `Event ${i + 1}`);
    invitesGiven = Array.from(
      { length: 10 },
      () => Math.floor(Math.random() * 400) + 300
    );
    validScans = invitesGiven.map((inviteCount) =>
      Math.floor(inviteCount * (Math.random() * 0.4 + 0.5))
    );
    invalidScans = validScans.map((validScansCount) =>
      Math.floor(validScansCount * (Math.random() * 0.1 + 0.1))
    );
  }

  return (
    <Box sx={{position: 'relative'}}>
      <Line
        style={{ maxHeight: "50dvh" }}
        options={options}
        data={{
          labels: labels,
          datasets: [
            {
              label: "Valid scans",
              data: validScans,
              borderColor: "gold",
              backgroundColor: `#ffd70022`,
              lineTension: 0.2,
              fill: true,
            },
            {
              label: "Denied scans",
              data: invalidScans,
              borderColor: colors.errorColor,
              backgroundColor: `${colors.errorColor}22`,
              lineTension: 0.2,
              fill: true,
            },

            {
              label: "Invites given",
              data: invitesGiven,
              borderColor: colors.primaryColor,
              backgroundColor: `${colors.primaryColor}22`,
              lineTension: 0.2,
              fill: true,
            },
          ],
        }}
      />
      {!selectedMember?.graphData && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: `${colors.defaultBackground}BB`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "1.5em",
            zIndex: 3
          }}
        >
          No member-event pairs
        </div>
      )}
    </Box>
  );
};

export default MemberPerformanceGraph;
