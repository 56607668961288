import { Box, Grid, Typography } from "@mui/material";
import { shortenNumberForModule } from "../../methods/formatNumbers";
import { colors } from "../../theme/colors";
import "./EventHighlightModule.css";

const EventHighlightModule = ({ num, name, description}) => {
  return (
    <Box sx={{ bgcolor: colors.backgroundHighlight, borderRadius: 1, mb: 0.5 }}>
      <Grid container sx={{ px: 1, py: 0.5 }}>
        <Grid item xs={5}>
          <Typography variant="body1" sx={{ fontSize: ".9em" }}>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="body1"
            sx={{ fontSize: ".9em", textAlign: "center" }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="body1"
            sx={{ fontSize: ".9em", textAlign: "center" }}
          >
            {`${
              description === "Best Accept Rate"
                ? parseInt(num * 100) + "%"
                : shortenNumberForModule(num)
            }`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventHighlightModule;
