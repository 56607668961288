import { formatMoney } from "../../methods/calculateTakeHome";
import { getModuleDate, getModuleTime } from "../../methods/getFormattedTime";
import { colors } from "../../theme/colors";
import { Grid } from "@mui/material";

const PayoutModule = ({ payout }) => {
  return (
    <Grid
      container
      columns={10}
      alignItems="center"
      sx={{
        backgroundColor: colors.backgroundHighlight,
        p: 1,
        borderRadius: 1,
        mb: ".3em",
      }}
    >
      <Grid item xs={4}>
        <h4
          style={{
            margin: 0,
            color: colors.primaryText,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {getModuleDate(Math.floor(new Date(payout.createdAt))).substring(
            0,
            getModuleDate(Math.floor(new Date(payout.createdAt))).indexOf(",")
          )}
        </h4>
        <p
          style={{ margin: 0, color: colors.secondaryText, fontSize: ".85em" }}
        >
          {getModuleTime(Math.floor(new Date(payout.createdAt)))}
        </p>
      </Grid>
      <Grid item xs={3}>
        <h3
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: "1em",
            textAlign: "center",
          }}
        >
          ${formatMoney(payout.amount * 100)}
        </h3>
      </Grid>
      <Grid item xs={3}>
        <h3
          style={{
            margin: 0,
            color: colors.primaryText,
            fontSize: "1em",
            textAlign: "center",
          }}
        >
          {payout.isFulfilled ? "Yes" : "No"}
        </h3>
      </Grid>
    </Grid>
  );
};

export default PayoutModule;
