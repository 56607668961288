import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { colors } from "../../theme/colors";

const ScanTimeGraph = ({ data, xAxisLabel, maxHeight, isSpoofed }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel || "Minutes since start",
          font: {
            family: "Poppins",
          },
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
        maxRotation: 90,
        minRotation: 90,
      },
      y: {
        title: {
          display: true,

          text: "Statistic",
          font: {
            family: "Poppins",
          },
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
      tooltip: {
        bodyFont: {
          family: "Poppins",
        },
        titleFont: {
          family: "Poppins",
        },
      },
    },
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column", position: 'relative' }}
    >
      <Line
        options={options}
        data={data}
        style={{ width: "100%", maxHeight: maxHeight || "45vh" }}
      />
      {isSpoofed && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: `${colors.defaultBackground}BB`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "1.5em",
          }}
        >
          No events yet
        </div>
      )}
    </div>
  );
};

export default ScanTimeGraph;
