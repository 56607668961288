import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicHeader from "../../public/navigation/PublicHeader";
import { ClipLoader } from "react-spinners";
import { DataStore } from "aws-amplify";
import { Event, GuestInvite, User } from "../../models";
import { useAuthContext } from "../../contexts/AuthContext";
import { Flag } from "@mui/icons-material";
import { Button } from "@mui/material";

const ViewPhotoID = () => {
  const { guestInviteID } = useParams();
  const [photoSignedURL, setPhotoSignedURL] = useState(null);
  const { createNotif, dbUser } = useAuthContext();
  const [guestInvite, setGuestInvite] = useState(null);
  const [flagged, setFlagged] = useState(false);

  useEffect(() => {
    (async () => {
      const guest = await DataStore.query(GuestInvite, guestInviteID);
      setFlagged(guest.photoStatus === "FLAGGED")
      setGuestInvite(guest);
      const response = await axios.get(
        `https://xwpg4g0xmj.execute-api.us-east-1.amazonaws.com/prod?id=${guest.photoID}`
      );
      const photoURL = response.data.url;
      setPhotoSignedURL(photoURL);
    })();
  }, []);

  const handleFlagClick = async () => {
    const recipient = await DataStore.query(User, guestInvite.orgMemberID);
    const event = await DataStore.query(Event, guestInvite.eventID);
    const tempGuestInvite = await DataStore.query(GuestInvite, guestInviteID);
    DataStore.save(
      GuestInvite.copyOf(tempGuestInvite, (updated) => {
        updated.photoStatus = "FLAGGED";
      })
    );
    createNotif(
      User,
      "flaggedInvite",
      recipient,
      dbUser.name,
      guestInvite.label,
      event.name,
      guestInvite.eventID,
      dbUser.profilePic
    );
    setFlagged(true);
  };

  if (!photoSignedURL || !guestInvite) {
    return (
      <div
        className="page-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <PublicHeader />
        <div
          className="profile-container"
          style={{
            height: "100dvh",
            padding: 0,
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!photoSignedURL || guestInvite === null ? (
            <>
              <p style={{ fontSize: "1.5em" }}>Loading ID</p>
              <ClipLoader
                color="gold"
                size="5em"
                speedMultiplier={0.5}
              ></ClipLoader>
            </>
          ) : guestInvite === undefined ? (
            <p style={{ fontSize: "1.5em" }}>
              Not a valid invite instance. Please try again or contact the
              originating member.
            </p>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="page-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <PublicHeader />
        <div
          className="profile-container"
          style={{
            height: "100dvh",
            padding: 0,
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={photoSignedURL}
            style={{
              height: "70dvh",
              maxHeight: "calc(80dvh - 7em)",
              marginBottom: "1em",
            }}
          />
          <Button
            variant="outlined"
            color="error"
            sx={{ textTransform: "none", fontFamily: "Poppins" }}
            onClick={() => (!flagged ? handleFlagClick() : null)}
          >
            {!flagged && <Flag color="error" />}
            {!flagged ? "Flag for Review" : "Guest Flagged"}
          </Button>
        </div>
      </div>
    );
  }
};

export default ViewPhotoID;
